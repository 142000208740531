export const DISTRICTS_TG = [
  {
    label: 'Adilabad',
    value: 'ADILABAD'
  },
  {
    label: 'Bhadradri',
    value: 'BHADRADRI'
  },
  {
    label: 'Kothagudem',
    value: 'KOTHAGUDEM'
  },
  {
    label: 'Hyderabad',
    value: 'HYDERABAD'
  },
  {
    label: 'Jagtial',
    value: 'JAGTIAL'
  },
  {
    label: 'Jangaon',
    value: 'JANGOAN'
  },
  {
    label: 'Jayashankar Bhoopalpally',
    value: 'JAYASHANKAR_BHOOPALPALLY'
  },
  {
    label: 'Jogulamba Gadwal',
    value: 'JOGULAMBA_GADWAL'
  },
  {
    label: 'Kamareddy',
    value: 'KAMAREDDY'
  },
  {
    label: 'Karimnagar',
    value: 'KARIMNAGAR'
  },
  {
    label: 'Khammam',
    value: 'KHAMMAN'
  },
  {
    label: 'Komaram Bheem Asifabad',
    value: 'KOMARAM_BHEEM_ASIFABAD'
  },
  {
    label: 'Mahabubabad',
    value: 'MAHABUBABAD'
  },
  {
    label: 'Mahabubnagar',
    value: 'MAHABUBNAGAR'
  },
  {
    label: 'Mancherial',
    value: 'MANCHERIAL'
  },
  {
    label: 'Medak',
    value: 'MEDAK'
  },
  {
    label: 'Medchal',
    value: 'MEDCHAL'
  },
  {
    label: 'Nagarkurnool',
    value: 'NAGARKURNOOL'
  },
  {
    label: 'Nalgonda',
    value: 'NALGONDA'
  },
  {
    label: 'Nirmal',
    value: 'NIRMAL'
  },
  {
    label: 'Nizamabad',
    value: 'NIZAMABAD'
  },
  {
    label: 'Peddapalli',
    value: 'PEDDAPALLI'
  },
  {
    label: 'Rajanna Sircilla',
    value: 'RAJANNA_SIRCILLA'
  },
  {
    label: 'Rangareddy',
    value: 'RANGAREDDY'
  },
  {
    label: 'Sangareddy',
    value: 'SANGAREDDY'
  },
  {
    label: 'Siddipet',
    value: 'SIDDIPET'
  },
  {
    label: 'Suryapet',
    value: 'SURYAPET'
  },
  {
    label: 'Vikarabad',
    value: 'VIKARABAD'
  },
  {
    label: 'Wanaparthy',
    value: 'WANAPARTHY'
  },
  {
    label: 'Warangal (Rural)',
    value: 'WARANGAL_RURAL'
  },
  {
    label: 'Warangal (Urban)',
    value: 'WARANGAL_URBAN'
  },
  {
    label: 'Yadadri Bhuvanagiri',
    value: 'YADADRI_BHUVANAGIRI'
  }
];
