import React, { Component } from 'react';
import { VALIDATION } from '../constants';

const DEFAULT_VALID = true;

function withValidation(WrappedComponent) {
  class ComponentWithValidation extends Component {
    constructor(props) {
      super(props);
      this.state = {
        validation: VALIDATION.OFF,
      };
      this._validate = this._validate.bind(this);
      this._onValidation = this._onValidation.bind(this);
    }

    // Dont delete this, components don't rerender
    // after validation if this is not present
    validationCount = 0;

    valid = DEFAULT_VALID;

    render() {
      const { validation } = this.state;
      return (
        <>
          <WrappedComponent
            validation={validation}
            validate={this._validate}
            onValidation={this._onValidation}
            validationCount={this.validationCount}
            {...this.props}
          />
        </>
      );
    }

    _onValidation(valid) {
      this.valid = this.valid && valid;
    }

    _validate(validation = VALIDATION.ALL, cb = () => 'validateSTUB', sticky = true) {
      this.validationCount += 1;
      this.valid = true;
      this.setState({ validation }, () => {
        cb(this.valid);
        if (!sticky) {
          this.setState({ validation: VALIDATION.OFF });
        }
      });
    }
  }

  return ComponentWithValidation;
}
export default withValidation;
