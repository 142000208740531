export const DISTRICTS_OR = [
  {
    label: 'Angul',
    value: 'ANGUL'
  },
  {
    label: 'Balangir',
    value: 'BALANGIR'
  },
  {
    label: 'Balasore',
    value: 'BALASORE'
  },
  {
    label: 'Bargarh',
    value: 'BARGARH'
  },
  {
    label: 'Bhadrak',
    value: 'BHADRAK'
  },
  {
    label: 'Boudh',
    value: 'BOUDH'
  },
  {
    label: 'Cuttack',
    value: 'CUTTACK'
  },
  {
    label: 'Deogarh',
    value: 'DEOGARH'
  },
  {
    label: 'Dhenkanal',
    value: 'DHENKANAL'
  },
  {
    label: 'Gajapati',
    value: 'GAJAPATI'
  },
  {
    label: 'Ganjam',
    value: 'GANJAM'
  },
  {
    label: 'Jagatsinghapur',
    value: 'JAGATSINGHAPUR'
  },
  {
    label: 'Jajpur',
    value: 'JAJPUR'
  },
  {
    label: 'Jharsuguda',
    value: 'JHARSUGUDA'
  },
  {
    label: 'Kalahandi',
    value: 'KALAHANDI'
  },
  {
    label: 'Kandhamal',
    value: 'KANDHAMAL'
  },
  {
    label: 'Kendrapara',
    value: 'KENDRAPARA'
  },
  {
    label: 'Kendujhar (Keonjhar)',
    value: 'KENDUJHAR'
  },
  {
    label: 'Khordha',
    value: 'KHORDHA'
  },
  {
    label: 'Koraput',
    value: 'KORAPUT'
  },
  {
    label: 'Malkangiri',
    value: 'MALKANGIRI'
  },
  {
    label: 'Mayurbhanj',
    value: 'MAYURBHANJ'
  },
  {
    label: 'Nabarangpur',
    value: 'NABARANGPUR'
  },
  {
    label: 'Nayagarh',
    value: 'NAYAGARH'
  },
  {
    label: 'Nuapada',
    value: 'NUAPADA'
  },
  {
    label: 'Puri',
    value: 'PURI'
  },
  {
    label: 'Rayagada',
    value: 'RAYAGADA'
  },
  {
    label: 'Sambalpur',
    value: 'SAMBALPUR'
  },
  {
    label: 'Sonepur',
    value: 'SONEPUR'
  },
  {
    label: 'Sundargarh',
    value: 'SUNDARGARH'
  }
];
