export const DISTRICTS_DD = [
  {
    label: 'Daman',
    value: 'DAMAN'
  },
  {
    label: 'Diu',
    value: 'DIU'
  }
];
