import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorArticle from '../ErrorArticle';
import getPlatformInfo from '../../../platformInfo';
import { submitReactFeedback } from '../../../preloadScripts';

class UnroutedErrorBoundary extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  render() {
    const { hasError } = this.state;
    const { children, ...otherProps } = this.props;
    if (hasError) {
      // Error path
      return (
        <ErrorArticle mailSubj="MahaAyudaan Profile Error">
          <React.Fragment>
            Please {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href=""
              onClick={() => {
                window.location.reload();
              }}
            >
              <b>Refresh</b>
            </a>
          </React.Fragment>
        </ErrorArticle>
      );
    }

    // Normally, just render children
    return React.Children.map(children, child => React.cloneElement(child, { ...otherProps }));
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    const info = `${error}\n${errorInfo.componentStack}`;
    const build = process.env.REACT_APP_GIT_SHA;
    const platformInfo = getPlatformInfo();

    const errorType = 'Outer Page Crash';
    const postBody = {
      info,
      build,
      platformInfo,
      errorType
    };

    submitReactFeedback(postBody);

    // You can also log error messages to an error reporting service here
  }
}
UnroutedErrorBoundary.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired
};

export default UnroutedErrorBoundary;
