import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Joi from 'joi';
import { withValidation } from '../../../hoc';
import { useCancellableApiCall } from '../../../hooks';
import { DMER as EMPTY_DMER } from '../../../emptyModels';
import { PATH_EXP_REGISTERREQUESTDMER } from '../../../appConstants';
import ValidatedInput from '../../../components/Custom/ValidatedInput';
import ValidatedSelect from '../../../components/Custom/ValidatedSelect';
import {
  REGEXP_MOBILE,
  OPTIONS_HOSPITALSTATUS,
  OPTIONS_REQUESTHOSPITALSTATUS,
  VALIDATION,
  USERTYPE_DMER,
  REGEXP_ONLYCHARS,
} from '../../../constants';
import useStyles from '../../../styles/styles';
import GreenTagSectionHeader from '../../../components/common/GreenTagSectionHeader';
import LoadableButton from '../../../components/Custom/LoadableButton';
import StyledDialogTitle from '../../../components/common/StyledDialogTitle';

function NewDmerRequestUi(props) {
  const [dmerRequestDetails, setDmerRequestDetails] = useState(
    cloneDeep(EMPTY_DMER)
  );
  const [requestSubmittedDialogOpenStatus, setRequestSubmittedDialogOpenStatus] = useState(false);
  const { users } = dmerRequestDetails;

  const { validation, onValidation } = props;
  const { apiGet, apiPost, apiCallStatuses } = useCancellableApiCall();

  useEffect(() => {
  }, [apiGet]);

  const classes = useStyles();

  const _renderRequestSubmittedDialog = () => {
    return (
      <Dialog
        open={requestSubmittedDialogOpenStatus}
        onClose={() => {
          setRequestSubmittedDialogOpenStatus(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle>
          <Grid container justify="space-between" alignItems="center">
            Thank you!
          </Grid>
        </StyledDialogTitle>{' '}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Typography
                variant="body1"
                style={{ fontWeight: 600, fontSize: '16px', color: '#34495E', marginTop: '5px' }}
              >
                Request Successfully Submitted.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setRequestSubmittedDialogOpenStatus(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  
  const [submitDmerRequestApiId, setSubmitDmerRequestApiId] = useState('APIID');
  const submitRequestDmer = () => {
    const { validate } = props;
    validate(VALIDATION.ALL, (valid) => {
      if (!valid) {
        return;
      }
      users.forEach((user) => {
        delete user.seedWords;
        delete user.ethAddress;
        user.institute = dmerRequestDetails.institute
      });
      apiPost(
        PATH_EXP_REGISTERREQUESTDMER,
        {
          dmer: dmerRequestDetails,
        },
        {
          apiIdCb: (apiId) => {
            setSubmitDmerRequestApiId(apiId);
          },
          unAuthenticated: true,
        }
      )
        .then(() => {
          validate(VALIDATION.OFF, () => {
            setRequestSubmittedDialogOpenStatus(true);
            setDmerRequestDetails(cloneDeep(EMPTY_DMER));
          });
        })
        .catch(() => {});
    });
  };

  const submitApproval = () => {
    // const { resolveWithCancellable, regulatorEthAddress } = props;
    // resolveWithCancellable(submitApproval(requestStatus, rottoSotto, regulatorEthAddress))
    //   .then((updatedRottoSotto) => {
    //     this.setState({
    //       rottoSotto: updatedRottoSotto,
    //     });
    //   })
    //   .catch(() => {});
  };

  

  const _renderFooterButtons = () => {
    const { status, requestStatus } = dmerRequestDetails;
    const { isLoggedIn } = props;
    if (requestStatus === OPTIONS_REQUESTHOSPITALSTATUS.REQUESTED && isLoggedIn) {
      return (
        <>
          <Grid item sm={4}>
            <Button
              block
              color="success"
              id="submitApproval"
              onClick={() => submitApproval(OPTIONS_REQUESTHOSPITALSTATUS.APPROVED)}
            >
              Approve
            </Button>
          </Grid>
          <Grid item sm={4}>
            <Button
              block
              color="danger"
              id="submitReject"
              // onClick={() => _toggleHospitalRejectCommentModal(true)}
            >
              Reject
            </Button>
          </Grid>
        </>
      );
    }
    if (status === OPTIONS_HOSPITALSTATUS.NEW && !isLoggedIn) {
      return (
        <Grid>
          <LoadableButton
            apiCallId={submitDmerRequestApiId}
            apiCallStatuses={apiCallStatuses}
            loadingText="Submitting..."
            variant="contained"
            color="primary"
            onClick={() => submitRequestDmer()}
            style={{
              fontWeight: 600,
              fontSize: '16px',
            }}
          >
            Submit
          </LoadableButton>
        </Grid>
      );
    }
    return null;
  };

  const _renderUsers = () => {
    return (
      <Grid container spacing={3}>
        {users.map((i, idx) => {
          return (
            <>
              <Grid item sm={3}>
                <ValidatedInput
                  required
                  value={i.name}
                  label="Name"
                  onChange={(e) => {
                    const updatedIncharges = [];
                    users.forEach((incharge, index) => {
                      if (index === idx) {
                        updatedIncharges.push({ ...incharge, name: e.target.value });
                      } else {
                        updatedIncharges.push(incharge);
                      }
                    });
                    setDmerRequestDetails({
                      ...dmerRequestDetails,
                      users: [...updatedIncharges],
                    });
                  }}
                  schema={Joi.string()
                    .pattern(REGEXP_ONLYCHARS)
                    .message({ 'string.pattern.base': 'Cannot contain numbers' })}
                  fullWidth
                  validation={validation}
                  onValidation={onValidation}
                />
              </Grid>
              <Grid item sm={3}>
                <ValidatedInput
                  required
                  value={i.email}
                  label="E-mail id"
                  onChange={(e) => {
                    const updatedIncharges = [];
                    users.forEach((incharge, index) => {
                      if (index === idx) {
                        updatedIncharges.push({ ...incharge, email: e.target.value });
                      } else {
                        updatedIncharges.push(incharge);
                      }
                    });
                    setDmerRequestDetails({
                      ...dmerRequestDetails,
                      users: [...updatedIncharges],
                    });
                  }}
                  schema={Joi.string().email({ tlds: { allow: false } })}
                  fullWidth
                  validation={validation}
                  onValidation={onValidation}
                />
              </Grid>
              <Grid item sm={3}>
                <ValidatedSelect
                  label="UserType"
                  value={i.userType}
                  options={Object.values(USERTYPE_DMER)}
                  onChange={(e) => {
                    const updatedIncharges = [];
                    users.forEach((incharge, index) => {
                      if (index === idx) {
                        updatedIncharges.push({ ...incharge, userType: e.value });
                      } else {
                        updatedIncharges.push(incharge);
                      }
                    });
                    setDmerRequestDetails({
                      ...dmerRequestDetails,
                      users: [...updatedIncharges],
                    });
                  }}
                  validation={validation}
                  onValidation={onValidation}
                  schema={Joi.string()}
                  fullWidth
                />
              </Grid>
              <Grid item sm={3}>
                <ValidatedInput
                  required
                  id="Contact"
                  name="Contact"
                  label="Contact"
                  value={i.contact}
                  fullWidth
                  schema={Joi.string().regex(REGEXP_MOBILE)}
                  onChange={(e) => {
                    const updatedIncharges = [];
                    users.forEach((incharge, index) => {
                      if (index === idx) {
                        updatedIncharges.push({ ...incharge, contact: e.target.value });
                      } else {
                        updatedIncharges.push(incharge);
                      }
                    });
                    setDmerRequestDetails({
                      ...dmerRequestDetails,
                      users: [...updatedIncharges],
                    });
                  }}
                  onValidation={onValidation}
                  validation={validation}
                />
              </Grid>
            </>
          );
        })}
      </Grid>
    );
  };

  return (
    <Box style={{ marginTop: 20, marginLeft: '15px', marginRight: '15px' }}>
      {dmerRequestDetails.status === 'NEW' ? (
        <Paper className={classes.paperTransplantInfo}>
          <Box justify="space-between" display="flex" flexDirection="row">
            <Grid container style={{ padding: 15, flexDirection: 'row' }}>
              <Grid item>
                <Typography
                  variant="h3"
                  style={{ fontSize: '16px', marginBottom: '10px', padding: 10 }}
                >
                  DMER Registration
                </Typography>
                <Typography
                  style={{
                    fontWeight: 300,
                    fontSize: '14px',
                    lineHeight: '15px',
                  }}
                >
                  Application for DMER users Registration.
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ padding: 15, flexDirection: 'row-reverse' }} spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    height: '36px',
                  }}
                  startIcon={<KeyboardBackspaceIcon />}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      ) : null}
      <Paper>
        <Grid container style={{ padding: 20 }}>
          <Grid container className={classes.gridDetails} spacing={5}>
            <Grid container justify="space-between">
              <Grid item xs={10}>
                <GreenTagSectionHeader headerText="REQUEST FOR LOGIN ACCESS CREDENTIALS" />
              </Grid>

              <Grid item sm={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container justify="space-between">
              <Grid item xs={10}>
                <GreenTagSectionHeader headerText="DMER Details" />
              </Grid>
            </Grid>

            <Grid container spacing={5} style={{ marginBottom: 20, marginTop: 10 }}>
              <Grid item sm={3}>
                <ValidatedInput
                  id="institute"
                  name="institute"
                  label="Institute"
                  value={'DMER'}
                  placeholder="institute"
                  disabled={true}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Grid>
            </Grid>
            {_renderUsers()}
          </Grid>
          <Grid container justify="center" xs={12} style={{ padding: 20 }}>
            {_renderFooterButtons()}
          </Grid>
        </Grid>
      </Paper>
      {_renderRequestSubmittedDialog()}
    </Box>
  );
}

NewDmerRequestUi.propTypes = {
  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
};

export default withValidation(NewDmerRequestUi);
