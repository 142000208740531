import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

export default function GreenTagSectionHeader({ headerText }) {
  return (
    <Grid
      item
      style={{ borderLeft: '5px solid #4EA95E', marginBottom: 30, marginTop: 20, width: '100%' }}
    >
      <Typography
        variant="body1"
        style={{
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '20px',
        }}
      >
        {headerText || 'Header'}
      </Typography>
    </Grid>
  );
}
GreenTagSectionHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
};
