import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Box,
  Button,
  Typography,
  Grid,
  FormLabel,
  DialogActions,
  DialogContent,
  IconButton,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import moment from 'moment';
import Joi from 'joi';
import PropTypes from 'prop-types';
import util from 'util';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import CloseIcon from 'mdi-react/CloseIcon';
import { ValidatedDate } from '../Custom';
import ValidatedInput from '../Custom/ValidatedInput';
import ValidatedSelect from '../Custom/ValidatedSelect';
import ValidatedAttachment, { FILETYPES } from '../Custom/ValidatedAttachment';
import { useCancellableApiCall } from '../../hooks';
import {
  FORMAT_DATE_UNIXTS,
  isDhsUserType,
  OPTIONS_APPROVALSTATUS,
  OPTIONS_BSDMEMBERTYPE,
  OPTIONS_SPECIALISATION,
  OPTIONS_SPECIALIST_ATTESTEDBY,
  OPTIONS_SPECIALIST_DOCTYPE,
  REGEXP_MOBILE,
  VALIDATION,
} from '../../constants';
import GreenTagSectionHeader from '../common/GreenTagSectionHeader';
import CustomNote from '../common/CustomNote';
import { PATH_EXP_APPROVESPECIALIST, PATH_EXP_GETSPECIALIST } from '../../appConstants';
import { SPECIALIST as EMPTY_SPECIALIST } from '../../emptyModels';
import StyledDialogTitle from '../common/StyledDialogTitle';
import { withValidation } from '../../newHoc';
import DataLoadingScreen from '../Custom/DataLoadingScreen';

function SpecialistPreview({
  specialistUserId,
  specialistHospitalId,
  validation,
  onValidation,
  validate,
}) {
  const [specialist, setSpecialist] = useState(cloneDeep(EMPTY_SPECIALIST));
  const [specialistApproval, setSpecialistApproval] = useState(
    cloneDeep(EMPTY_SPECIALIST.approval)
  );
  const [openApprovalDialog, setOpenApprovalDialog] = useState(false);
  const [noting, setNoting] = useState({
    name: '',
    key: '',
    url: '',
  });

  const { formStatus, documents, specialisation, approval } = specialist;

  const { apiGet, apiPost, apiCallStatuses } = useCancellableApiCall();
  const app = useSelector((state) => state);
  const {
    user: { userType, userId: loggedInUserId },
  } = app;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    apiGet(util.format(PATH_EXP_GETSPECIALIST, specialistUserId), {
      hospitalId: specialistHospitalId,
      userType,
    })
      .then((specialistRecord) => {
        setSpecialist({ ...specialistRecord });
        setIsLoading(false);
      })
      .catch(() => {});
  }, []);

  const useStyles = makeStyles(() => ({
    paperSpecialistInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginBottom: 10,
    },
    paperSpecialist: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 10,
      marginBottom: 10,
    },
    gridSpecialistDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    specialistDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#34495E',
      marginTop: '20px',
    },
    specialistBoxStyle: {
      direction: 'row',
    },
  }));

  const classes = useStyles();

  const _isDisabled = () => {
    if (formStatus === 'SUBMITTED' || formStatus === 'PROCESSED') {
      return true;
    }
    return false;
  };

  const [changeSpecialistApprovalApiId, setChangeSpecialistApprovalApiID] = useState('APIID');
  const _changeSpecialistApproval = async () => {
    let approvalObject;
    if (specialistApproval.status === OPTIONS_APPROVALSTATUS.APPROVED) {
      try {
        approvalObject = {
          submitterId: loggedInUserId,
          txHash: '0x0000000000',
          status: OPTIONS_APPROVALSTATUS.APPROVED,
          approved: true,
          // comment: specialistApproval.rejectionReasons,
          noting,
          approvalDates: [],
        };
        approvalObject.approvalDates.push(Math.floor(new Date().getTime() / 1000));
      } catch (err) {
        // logger.error(err);
        throw new Error('Error approving in blockchain');
      }
    } else {
      const approvalRejectionReason = specialistApproval.rejectionReasons
        .map((r) => r.reasons)
        .join(', ');
      const rejectedReasonsObject = {
        reasons: approvalRejectionReason,
        rejectionDate: Math.floor(new Date().getTime() / 1000),
      };
      approvalObject = {
        submitterId: loggedInUserId,
        status: OPTIONS_APPROVALSTATUS.REJECTED,
        rejectionReasons: [rejectedReasonsObject],
        approved: false,
      };
    }
    const { hospitalId: specialistHospitalId } = specialist;
    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (valid) => {
      if (!valid) {
        return;
      }
      apiPost(
        PATH_EXP_APPROVESPECIALIST,
        {
          approval: approvalObject,
          hospitalId: specialistHospitalId,
          userId: specialist.userId,
        },
        {
          apiIdCb: (apiId) => {
            setChangeSpecialistApprovalApiID(apiId);
          },
        }
      )
        .then((updatedSpecialist) => {
          setSpecialist(updatedSpecialist);
          setOpenApprovalDialog(false);
        })
        .catch(() => {});
    });
  };

  const _renderApprovalDialog = () => {
    return (
      <Dialog
        open={openApprovalDialog}
        onClose={() => {
          setOpenApprovalDialog(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        {specialistApproval.status === 'APPROVED' ? (
          <StyledDialogTitle>
            <Grid container justify="space-between" alignItems="center">
              Specialist Approval Details
              <IconButton
                edge="start"
                color="#F0FEF9"
                onClick={() => {
                  setOpenApprovalDialog(false);
                }}
                aria-label="close"
              >
                <CloseIcon
                  style={{
                    color: '#F0FEF9',
                  }}
                />
              </IconButton>
            </Grid>
          </StyledDialogTitle>
        ) : null}
        {specialistApproval.status !== 'APPROVED' ? (
          <DialogTitle style={{ backgroundColor: 'red', color: '#F0FEF9' }}>
            <Grid container justify="space-between" alignItems="center">
              Reject Specialist Details
              <IconButton
                edge="start"
                color="#F0FEF9"
                onClick={() => {
                  setOpenApprovalDialog(false);
                }}
                aria-label="close"
              >
                <CloseIcon
                  style={{
                    color: '#F0FEF9',
                  }}
                />
              </IconButton>
            </Grid>
          </DialogTitle>
        ) : null}

        <DialogContent style={{ margin: 20 }}>
          <Grid container spacing={5}>
            {specialistApproval.status === 'APPROVED' ? (
              <Grid item sm={6}>
                <ValidatedAttachment
                  required
                  id="noting"
                  name="noting"
                  label="noting"
                  acceptFileTypes={[FILETYPES.PNG, FILETYPES.PDF]}
                  value={noting}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setNoting({
                      ...noting,
                      name: file.name,
                      file,
                    });
                  }}
                  schema={Joi.string()}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Grid>
            ) : null}
            {specialistApproval.status !== 'APPROVED' ? (
              <Grid item sm={12}>
                <ValidatedSelect
                  required
                  label="Reason"
                  value={specialistApproval.rejectionReasons.map((r) => r.reasons)}
                  multiple={true}
                  options={[
                    {
                      label: 'MBBS Degree Certificate is missing',
                      value: 'MBBS Degree Certificate is missing',
                    },
                    {
                      label: 'PG Degree Certificate is missing',
                      value: 'PG Degree Certificate is missing',
                    },
                    {
                      label: 'MMC Certificate of Registration is invalid',
                      value: 'MMC Certificate of Registration is invalid',
                    },
                    {
                      label: 'MMC Additional Med Qualification Registration is invalid',
                      value: 'MMC Additional Med Qualification Registration is invalid',
                    },
                    {
                      label: 'MMC Registration Renewal Certificate is invalid',
                      value: 'MMC Registration Renewal Certificate is invalid',
                    },
                    {
                      label: 'Experience Certificate is missing',
                      value: 'Experience Certificate is missing',
                    },
                    {
                      label: 'Experience Certificate is invalid',
                      value: 'Experience Certificate is invalid',
                    },
                    {
                      label: 'MMC Registration Renewal is not correct/attached properly',
                      value: 'MMC Registration Renewal is not correct/attached properly',
                    },
                    {
                      label: 'MMC Registration Renewal is not readable.',
                      value: 'MMC Registration Renewal is not readable.',
                    },
                    {
                      label:
                        'MMC Additional Med Qualification Certificate is not correct/attached properly.',
                      value:
                        'MMC Additional Med Qualification Certificate is not correct/attached properly.',
                    },
                    {
                      label: 'MMC Additional Med Qualification Certificate is not readable.',
                      value: 'MMC Additional Med Qualification Certificate is not readable.',
                    },
                    {
                      label: 'MBBS Degree Certificate is not correct/attached properly.',
                      value: 'MBBS Degree Certificate is not correct/attached properly.',
                    },
                    {
                      label: 'MBBS Degree Certificate is not readable.',
                      value: 'MBBS Degree Certificate is not readable.',
                    },
                    {
                      label: 'PG Degree Certificate is not correct/attached properly.',
                      value: 'PG Degree Certificate is not correct/attached properly.',
                    },
                    {
                      label: 'PG Degree Certificate is not readable.',
                      value: 'PG Degree Certificate is not readable.',
                    },
                    {
                      label: 'MMC Registration is not correct/attached properly.',
                      value: 'MMC Registration is not correct/attached properly.',
                    },
                    {
                      label: 'MMC Registration is not readable.',
                      value: 'MMC Registration is not readable.',
                    },
                    {
                      label: 'Experience Certificate is not readable/attached properly.',
                      value: 'Experience Certificate is not readable/attached properly.',
                    },
                  ]}
                  onChange={(v) => {
                    const newCommments = [];
                    v.forEach((o) => {
                      newCommments.push({
                        reasons: o.value,
                        rejectionDate: new Date().getTime() / 1000,
                      });
                    });

                    setSpecialistApproval({
                      ...specialistApproval,
                      rejectionReasons: [...newCommments],
                    });
                  }}
                  disabled={false}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            apiCallId={changeSpecialistApprovalApiId}
            apiCallStatuses={apiCallStatuses}
            loadingText="Submitting..."
            variant="contained"
            color="primary"
            onClick={() => {
              _changeSpecialistApproval();
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenApprovalDialog(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const _renderSpecialistDocument = (d) => {
    const { attestation, documentType, documentNo, validTill, issuedDate, attachment } = d;
    return (
      <Grid container xs={12} sm={12} style={{ marginBottom: 15, marginTop: 15 }} spacing={5}>
        {documentType === 'PG' ? (
          <Grid item sm={12}>
            <CustomNote noteText="Note: If There are multiple PG certificates, attach them as single bunch of PDF and Submit." />
          </Grid>
        ) : null}
        {documentType === 'MMCREGN' ? (
          <Grid item sm={12}>
            <CustomNote noteText="Note: *Please ensure that MMC registration number is valid and current" />
          </Grid>
        ) : null}
        <Grid item sm={4} xs={12}>
          <ValidatedSelect
            required
            id="documentType"
            name="documentType"
            label="Document Type"
            value={documentType}
            disabled={true}
            options={OPTIONS_SPECIALIST_DOCTYPE}
            fullWidth
          />
        </Grid>
        {['MMC', 'MMCREGN', 'MBBS', 'ADDNLQUALIFICATION'].includes(documentType) ? (
          <Grid item sm={4} xs={12}>
            <ValidatedInput
              label="Document No."
              value={documentNo}
              disabled={documentType === 'MMC' || _isDisabled()}
              schema={Joi.string()}
              fullWidth
            />
          </Grid>
        ) : null}
        {['MMC', 'MMCREGN', 'MBBS', 'ADDNLQUALIFICATION'].includes(documentType) ? (
          <Grid item sm={4} xs={12}>
            <ValidatedDate
              required
              label="Issued Date"
              value={moment(issuedDate, FORMAT_DATE_UNIXTS)}
              disabled={documentType === 'MMC' || _isDisabled()}
              schema={Joi.date().max('now')}
              fullWidth
            />
          </Grid>
        ) : null}
        {['MMC', 'MMCREGN', 'ADDNLQUALIFICATION'].includes(documentType) ? (
          <Grid item sm={4} xs={12}>
            <ValidatedDate
              required
              label="Valid Till"
              value={moment(validTill, FORMAT_DATE_UNIXTS)}
              disabled={documentType === 'MMC' || _isDisabled()}
              schema={Joi.date()}
              fullWidth
            />
          </Grid>
        ) : null}

        <Grid item sm={4} xs={12}>
          <ValidatedAttachment
            required
            id="attachment"
            name="attachment"
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.PDF]}
            value={attachment}
            schema={Joi.string()}
            disabled={_isDisabled()}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedSelect
            required
            id="attestedBy"
            name="attestedBy"
            value={attestation?.attestedBy}
            label="Attached By"
            disabled={_isDisabled()}
            options={OPTIONS_SPECIALIST_ATTESTEDBY}
            fullwidth
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedInput
            required
            label="Attestor Name"
            value={attestation?.attestorName}
            schema={Joi.string()}
            disabled={_isDisabled() || attestation?.attestedBy === 'SELF'}
            fullWidth
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedInput
            required
            label="Attestor Designation"
            value={attestation?.attestorDesignation}
            schema={Joi.string()}
            disabled={_isDisabled() || attestation?.attestedBy === 'SELF'}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  const selectedSpecialisations = [];
  specialisation.forEach((s) => {
    if (!selectedSpecialisations.includes(s)) {
      selectedSpecialisations.push(s);
    }
  });

  const _approveSpecialist = (status) => {
    setSpecialistApproval({
      ...specialistApproval,
      status,
    });
    setOpenApprovalDialog(true);
  };

  return (
    <Box
      className={classes.specialistBoxStyle}
      style={{ marginTop: 10, marginLeft: 15, marginRight: 15 }}
    >
      {formStatus !== 'NEW' && approval?.status !== 'APPROVED' ? (
        <Paper className={classes.paperSpecialistInfo}>
          <Grid
            container
            justify="space-between"
            alignContent="center"
            style={{ marginTop: 20, padding: 20 }}
          >
            <Grid item>
              <Typography variant="h3" style={{ fontSize: '18px', marginBottom: '10px' }}>
                Specialist Profile Requests Status
              </Typography>
              {approval?.status === 'APPROVED' ? (
                <Typography variant="h5" style={{ fontSize: '16px', marginBottom: '10px' }}>
                  Approved
                </Typography>
              ) : (
                <Typography variant="h5" style={{ fontSize: '16px', marginBottom: '10px' }}>
                  {approval?.rejectionReasons?.map((r) => r.reasons)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      ) : null}
      <Paper>
        <DataLoadingScreen isLoading={isLoading}>
          <Grid container>
            <Grid container direction="column" className={classes.gridSpecialistDetails}>
              <Grid container justify="space-between">
                <GreenTagSectionHeader headerText="Specialist Profile Details" />
              </Grid>
              <Grid container spacing={5}>
                <Grid item sm={4}>
                  <ValidatedInput
                    required
                    label="Name"
                    value={specialist.name}
                    schema={Joi.string()}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4}>
                  <ValidatedInput
                    required
                    label="Email Id"
                    value={specialist.email}
                    schema={Joi.string()}
                    disabled={_isDisabled()}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4}>
                  <ValidatedSelect
                    required
                    label="Specialisation"
                    value={specialist.specialisation}
                    options={OPTIONS_SPECIALISATION}
                    disabled={_isDisabled()}
                    multiple={true}
                    fullwidth
                  />
                </Grid>
                <Grid item sm={4}>
                  <ValidatedInput
                    required
                    id="contact"
                    name="contact"
                    label="Contact No."
                    value={specialist.contact}
                    disabled={_isDisabled()}
                    fullWidth
                    schema={Joi.string().regex(REGEXP_MOBILE)}
                  />
                </Grid>
                <Grid container sm={4} style={{ padding: 15 }}>
                  <Grid item>
                    <FormLabel>BSD Member</FormLabel>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        variant={specialist.bsd.isBsdMember ? 'contained' : 'outlined'}
                        color="primary"
                        style={{ borderRadius: '20px' }}
                        disabled={_isDisabled()}
                      >
                        Yes
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={specialist.bsd.isBsdMember ? 'outlined' : 'contained'}
                        color="primary"
                        style={{ borderRadius: '20px' }}
                        disabled={_isDisabled()}
                      >
                        No
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {specialist.bsd.isBsdMember ? (
                  <Grid item sm={4}>
                    <ValidatedSelect
                      required
                      label="MedicalPractioner or Surgeon"
                      disabled={_isDisabled()}
                      value={specialist.bsd.memberType}
                      options={Object.values(OPTIONS_BSDMEMBERTYPE)}
                      fullwidth
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Grid item style={{ marginTop: 30, marginBottom: 20 }}>
                <hr />
              </Grid>

              <Grid container justify="space-between" spacing={2}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E', marginBottom: 20 }}>
                  <Typography variant="body1" className={classes.specialistDetailsHeading}>
                    Specialist Documents
                  </Typography>
                </Grid>
                {documents.map((d) => _renderSpecialistDocument(d))}
              </Grid>
              {isDhsUserType(userType) && approval?.status === 'PENDING' ? (
                <Grid
                  container
                  justify="center"
                  style={{ paddingBottom: 20, marginTop: 30 }}
                  alignContent="center"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        // disableValidation();
                        _approveSpecialist('APPROVED');
                      }}
                      size="large"
                    >
                      Approve Specialist
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        // disableValidation();
                        _approveSpecialist('REJECTCED');
                      }}
                      size="large"
                    >
                      Reject Specialist
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
              {_renderApprovalDialog()}
            </Grid>
          </Grid>
        </DataLoadingScreen>
      </Paper>
    </Box>
  );
}

SpecialistPreview.propTypes = {
  specialistUserId: PropTypes.string.isRequired,
  specialistHospitalId: PropTypes.string.isRequired,
};
SpecialistPreview.defaultProps = {};

export default withValidation(SpecialistPreview);
