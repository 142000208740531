import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ErrorArticle from '../ErrorArticle';
import getPlatformInfo from '../../../platformInfo';
import { submitReactFeedback } from '../../../preloadScripts';

class RoutedErrorBoundary extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  render() {
    const { hasError } = this.state;
    const { children, ...otherProps } = this.props;
    if (hasError) {
      // Error path
      return (
        <ErrorArticle mailSubj="MahaAyudaan App Error">
          <Typography variant="h6">
            Please{' '}
            <Link to="/">
              <b>Go Home</b>
            </Link>{' '}
            or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href=""
              onClick={() => {
                window.location.reload();
              }}
            >
              <b>Refresh</b>
            </a>
          </Typography>
        </ErrorArticle>
      );
    }

    // Normally, just render children
    return React.Children.map(children, (child) => React.cloneElement(child, { ...otherProps }));
  }

  _registerRouterChangeListener() {
    const { history } = this.props;
    const { hasError } = this.state;

    history.listen(() => {
      if (hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    const info = `${error}\n${errorInfo.componentStack}`;
    const build = process.env.REACT_APP_GIT_SHA;
    const platformInfo = getPlatformInfo();

    const errorType = 'Inner Page Crash';
    const postBody = {
      info,
      build,
      platformInfo,
      errorType,
    };

    submitReactFeedback(postBody);

    this._registerRouterChangeListener();
    // You can also log error messages to an error reporting service here
  }
}
RoutedErrorBoundary.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(RoutedErrorBoundary);
