import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  Button,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { handleSpecialistsChange } from './newOtherSupportiveSpecialistsModalScripts';
import { ValidatedSelect, ReactTable } from '../../Custom';
import { withCancellableResolver } from '../../../hoc';
import { OPTIONS_SPECIALISATION } from '../../../constants';

class NewOtherSupportiveSpecialistsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialists: [],
    };
  }

  _selectSpecialistFormatter(specialist) {
    const { trainedPersons } = this.props;
    const selected = trainedPersons.filter((s) => s.userId === specialist.userId).length > 0;
    return (
      <>
        {selected ? (
          <Button
            color="secondary"
            variant="contained"
            id={`removeSpecialist_${specialist.userId}`}
            name={`removeSpecialist_${specialist.userId}`}
            onClick={() => handleSpecialistsChange(specialist, false, this)}
          >
            Remove
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            id={`addSpecialist_${specialist.userId}`}
            name={`addSpecialist_${specialist.userId}`}
            onClick={() => handleSpecialistsChange(specialist, true, this)}
          >
            Add
          </Button>
        )}
      </>
    );
  }

  specialistsColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      key: 'name',
    },
    {
      Header: 'Specialisation',
      accessor: 'specialisation',
      key: 'specialisation',
      disableFilters: true,
      Cell: ({ value }) => this._formatSpecialisation(value),
    },
    {
      Header: 'Select',
      accessor: 'userId',
      key: 'selectUser',
      disableFilters: true,
      Cell: ({ row }) => this._selectSpecialistFormatter(row.original),
    },
  ];

  render() {
    const { specialists } = this.state;
    const { isOpen, toggle } = this.props;
    return (
      <Dialog
        open={isOpen}
        onClose={() => {
          toggle(false);
        }}
        onEnter={() => {
          this._getFilteredSpecialists();
        }}
        maxWidth="sm"
        fullScreen
      >
        <DialogTitle>
          {' '}
          <Grid container justify="space-between" alignItems="center">
            Add Specialists
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                toggle(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: 'primary',
                }}
              />
            </IconButton>
          </Grid>
        </DialogTitle>{' '}
        <DialogContent style={{ margin: 20 }}>
          <Grid container>
            <ReactTable columns={this.specialistsColumns} data={specialists} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              toggle(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _formatSpecialisation = (specialisation) => {
    return specialisation.join(' , ');
  };

  _getFilteredSpecialists() {
    const { trainedPersons } = this.props;
    const { specialists } = this.props;
    const filteredSpecialists = specialists.filter((f) => {
      return f?.approval?.status !== "REJECTED";
    });
    // const filteredSpecialists = specialists.filter(
    //   (s) => trainedPersons.filter((t) => s.userId === t.userId).length === 0
    // );
    this.setState({
      specialists: filteredSpecialists
    });
  }

  componentDidMount() {
    this._getFilteredSpecialists();
  }
}
NewOtherSupportiveSpecialistsModal.propTypes = {
  trainedPersons: PropTypes.arrayOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  resolveWithCancellable: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  hospitalId: PropTypes.string.isRequired,
  // this is required, but used only in the js file
  // eslint-disable-next-line react/no-unused-prop-types
  setOtherSupportiveSpecialists: PropTypes.func.isRequired,
  specialists: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  userType: state.user.userType,
  hospitalId: state.hospital.hospitalId,
  isDemo: state.isDemo,
});

export default compose(
  withRouter,
  withCancellableResolver,
  connect(mapStateToProps)
)(NewOtherSupportiveSpecialistsModal);
