import React from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { VALIDATION } from '../../constants';

export default function ValidatedSelect({
  value,
  onChange,
  onValidation,
  showValid,
  validationDisabled,
  defaultValue,
  multiple,
  required,
  label,
  textFieldParams,
  ...other
}) {
  let isDirty = false;

  // eslint-disable-next-line prefer-const
  let { validation, options, schema, ...otherProps } = other;
  let props = otherProps;
  if (options === undefined) {
    options = [];
  }
  let defaultOption;
  if (multiple && Array.isArray(defaultValue)) {
    defaultOption = options.filter((o) => defaultValue.includes(o.value));
    props = defaultOption ? { ...props, defaultValue: defaultOption } : props;
  } else if (multiple && defaultValue) {
    defaultOption = options.filter((o) => o.value === defaultValue);
    props = defaultOption ? { ...props, defaultValue: defaultOption } : props;
  } else if (defaultValue) {
    [defaultOption] = options.filter((o) => o.value === defaultValue);
    props = defaultOption ? { ...props, defaultValue: defaultOption } : props;
  }

  let valueOption;
  if (multiple && Array.isArray(value)) {
    valueOption = options.filter((o) => value.includes(o.value));
    props = valueOption ? { ...props, value: valueOption } : { ...props, value: [] };
  } else if (multiple && value) {
    valueOption = options.filter((o) => o.value === value);
    props = valueOption ? { ...props, value: valueOption } : { ...props, value: [] };
  } else if (multiple && !value) {
    props = { ...props, value: [] };
  } else if (value || value === false) {
    [valueOption] = options.filter((o) => o.value === value);
    props = valueOption ? { ...props, value: valueOption } : { ...props, value: null };
  } else if (!value && !defaultValue) {
    props = { ...props, value: null };
  }

  let valid = true;
  let messages = [];
  let joiError = null;
  if (validation === true) {
    validation = VALIDATION.ALL;
  } else if (validation === false) {
    validation = VALIDATION.OFF;
  }
  if (
    !validationDisabled &&
    (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && isDirty))
  ) {
    if (!schema || !Joi.isSchema(schema)) {
      if (!multiple) {
        schema = Joi.any()
          .valid(...options.map((o) => o.value))
          .required();
      } else {
        schema = Joi.array()
          .items(Joi.any().valid(...options.map((o) => o.value)))
          .min(1)
          .unique()
          .required();
      }
    }
    schema = schema.messages({
      'alternatives.any': 'Please check',
      'any.required': 'Cannot be empty',
      'any.invalid': 'Unknown options: {#invalids}',
      'any.only': 'Should be one of options',
      'array.unique': 'Duplicate option: {#dupeValue}',
      'array.min': 'Should be one of options',
      'array.base': 'Should be one of options',
      'string.empty': 'Cannot be empty',
      'string.base': 'Should be a valid string',
      'string.email': 'Should be a valid email',
      'string.pattern.base': 'Cannot contain illegal characters',
      'string.pattern.invert.base': 'Cannot contain illegal characters',
      'string.lowercase': 'Cannot contain uppercase letters',
      'string.uri': 'Should be a valid URI',
      'string.length': 'Should be of length {#limit}',
      'string.max': 'Cannot exceed a length of {#limit}',
      'string.min': 'Cannot have length smaller than {#limit}',
      'string.domain': 'Should be a valid domain',
      'string.hostname': 'Should be a valid hostname',
      'string.ip': 'Should be a valid IP address',
      'string.hex': 'Should be a valid hex number',
      'boolean.base': 'Should be valid selection',
      'number.base': 'Should be a number',
      'number.integer': 'Should be an integer',
      'number.greater': 'Cannot be greater than {#limit}',
      'number.less': 'Cannot be less than {#limit}',
      'number.max': 'Cannot be greater than {#limit}',
      'number.min': 'Cannot be less than {#limit}',
      'number.positive': 'Should be a positive number',
      'number.negative': 'Should be a negative number',
    });
    if (required) {
      schema = schema.required();
    }
    if (validation & VALIDATION.INVALIDATE_EMPTY) {
      schema = schema.required();
    }
    const { error } = schema.validate(value);
    if (error) {
      valid = false;
      messages = error.details.map((d) => d.message);
      joiError = error;
    }
    onValidation(valid, messages, joiError);
  } else {
    valid = true;
    messages = [];
  }

  return (
    <Autocomplete
      fullWidth
      {...props}
      multiple={multiple}
      size="small"
      getOptionSelected={(option, val) => option.value === val.value}
      onChange={(e, val) => {
        if (!isDirty) {
          isDirty = true;
        }
        const emptyValue = multiple ? [] : {};
        onChange(val || emptyValue);
      }}
      options={options}
      getOptionLabel={(option) => option.label || ''}
      renderInput={(params) => (
        <TextField
          error={!!validation && !valid}
          helperText={messages[0]}
          required={required}
          {...params}
          {...textFieldParams}
          label={label}
          variant="outlined"
          size="small"
        />
      )}
    />
  );
}
ValidatedSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  schema: PropTypes.instanceOf(Joi.constructor),
  onValidation: PropTypes.func,
  validationDisabled: PropTypes.bool,
  showValid: PropTypes.bool,
  multiple: PropTypes.bool,
  textFieldParams: PropTypes.objectOf(PropTypes.any),
};
ValidatedSelect.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  validation: false,
  label: '',
  onChange: () => 'onChange STUB',
  required: false,
  schema: undefined,
  onValidation: () => 'onValidation STUB',
  validationDisabled: false,
  showValid: false,
  multiple: false,
  textFieldParams: {},
};
