import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { SHA3_NULL } from 'ethereumjs-util';
import { ATTACHMENT } from '../emptyModels';
import {
  FORMAT_DATE_UNIXTS,
  SEX,
  OPTIONS_GVTPVT,
  OPTIONS_TEACHING,
  OPTIONS_YESNO,
} from '../constants';
import {
  OPTIONS_EYEDONORSTATUS,
  OPTIONS_EYERECIPIENTSTATUS,
  MATCHING_FORMSTATUS,
  OPTS_DONOR_MEDICOLEGAL,
  OPTS_DONOR_MOTIVATEDBY,
  OPTS_DONOR_REMOVALPLACE,
  OPTS_DONOR_TISSUETYPE,
  OPTS_DONOR_TYPEOFPRESERVATION,
  OPTS_DONOR_TISSUENUMBER,
  OPTS_RECIPIENT_INDICOFKRTPLSTY,
  OPTS_RECIPIENT_PRIORITIZINGCATG,
  OPTS_RECIPIENT_TISSUETYPE,
  OPTS_RECIPIENT_IDPROOF,
  OPTIONS_DONOREVALUATIONFORM_STATUS,
  OPTIONS_KERATOPLASTYREPORTFORM_STATUS,
  OPTS_DONOREVAL_CRNASTATUS,
  OPTS_DONOREVAL_CONTRAINDICATION,
  OPTS_DONOREVAL_NONVIABLEUSAGE,
  OPTIONS_MATCHINGSTATUS,
  OPTS_DONOREVAL_SEROLOGY,
  OPTS_DONOREVAL_EPTHLM_HAZE,
  OPTS_DONOREVAL_EPTHLM_EXPKRTSDEGREE,
  OPTS_DONOREVAL_EPTHLM_EXPKRTSLOC,
  OPTS_DONOREVAL_EPTHLM_SLGHLOC,
  OPTS_DONOREVAL_STRMA_ARCS,
  OPTS_DONOREVAL_HBSAG_HCV,
  OPTS_KRTPLSTYRPT_SURGICALTECHQ,
  OPTS_KRTPLSTYRPT_CATARACT,
  OPTS_KRTPLSTYRPT_IOL,
  OPTS_DONOREVAL_BLOODCOLLECTED_ADEQUACY,
  OPTS_KRTPLSTYRPT_VITRECTOMY,
  OPTS_KRTPLSTYRPT_SUTURESIZETYPE,
  OPTS_KRTPLSTYRPT_SUTURETECHQ,
  OPTS_KRTPLSTYRPT_OPERATEDEYES,
  OPTS_KRTPLSTYRPT_PREOPDIAG,
  OPTS_DONOREVAL_STRMA_CLRCMPCT,
  OPTS_DONOREVAL_STRMA_EDEMA,
  OPTS_DONOREVAL_STRMA_LOC,
  OPTS_DONOREVAL_DESCMEM_FLDSRELNUM,
  OPTS_DONOREVAL_DESCMEM_DFCTSTYPE,
  OPTS_DONOREVAL_DESCMEM_FLDSAMNT,
  OPTS_DONOREVAL_ENDLM_STRSLOC,
  OPTS_DONOREVAL_ENDLM_STRSLNRELNUM,
  OPTS_DONOREVAL_ENDLM_CLDPUTAMNT,
  OPTS_DONOREVAL_ENDLM_CLDPUTLOC,
  OPTS_DONOREVAL_ENDLM_PLYMGTSM,
  OPTS_DONOREVAL_ENDLM_PLEOMRPSHM,
  OPTS_DONOREVAL_ADNLINFO_JAUNDICE,
  OPTS_DONOREVAL_ADNLINFO_LENS,
  OPTS_DONOREVAL_ADNLINFO_SCLRLRMEVAL,
  OPTS_DONOREVAL_STRMA_OPCTTYPE,
  OPTS_DONOREVAL_STRMA_OPCTREF,
  OPTS_DONOREVAL_STRMA_STRIAELOC,
  OPTS_DONOREVAL_STRMA_STRIAERELNUM,
  OPTS_DONOREVAL_APRVL_NTSTBLSTRMA,
  OPTIONS_FORM15CTCSTATUS,
  OPTIONS_FORM15EBSTATUS,
  OPTIONS_FORM15ERCSTATUS,
} from '../appConstants/eyebank';
import { OPTIONS_LEGACYFORM15CTCSTATUS } from '../appConstants/legacyForm15Ctc';
import { OPTIONS_LEGACYFORM15EBSTATUS } from '../appConstants/legacyForm15Eb';
import { OPTIONS_LEGACYFORM15ERCSTATUS } from '../appConstants/legacyForm15Erc';
import { OPTIONS_NATIONALITY } from '../nationalities';
import { STATE_IN } from '../statesIndia';
import { DISTRICTS_MH } from '../districts/districtsMh';
import { number } from 'joi';

export const EYEBANK_FORM15EB = {
  centerDetails: {
    name: '',
    address: '',
    gvtPvtVoltry: OPTIONS_GVTPVT[1].value,
    teachingNonTeaching: OPTIONS_TEACHING[0].value,
    iec: '',
  },
  verification: {},
  inspection: {
    report: {
      name: '',
      url: '',
    },
  },
  approval: {
    certificate: {
      hash: '',
      attachment: {
        name: '',
        url: '',
        key: '',
      },
    },
  },
  eyeBallRemoval: {
    trainedPersonnel: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },

    designatedStaff: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    tissueRequestRegister: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    telephone: {
      available: null,
      number: '',
    },
    transportFacility: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    corneaRemovalInstruments: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    corneaPreservationBottles: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    preservationMedia: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },

    bioMedicalRegnCert: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    powerSupply: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
  },
  manpower: {
    incharge: [
      {
        name: '',
        contactNo: '',
        contactEmailId: '',
        qualification: '',
        attachment: {
          name: '',
          url: '',
          key: '',
        },
      },
    ],
    technicians: [
      {
        name: '',
        contactNo: '',
        contactEmailId: '',
        qualification: '',
        attachment: {
          name: '',
          url: '',
          key: '',
        },
      },
      {
        name: '',
        contactNo: '',
        contactEmailId: '',
        qualification: '',
        attachment: {
          name: '',
          url: '',
          key: '',
        },
      },
    ],
    eyeDonationCounselors: [
      {
        name: '',
        contactNo: '',
        contactEmailId: '',
        qualification: '',
        attachment: {
          name: '',
          url: '',
          key: '',
        },
      },
      {
        name: '',
        contactNo: '',
        contactEmailId: '',
        qualification: '',
        attachment: {
          name: '',
          url: '',
          key: '',
        },
      },
    ],
    multiTaskStaff: [
      {
        name: '',
        contactNo: '',
        contactEmailId: '',
        qualification: '',
        attachment: {
          name: '',
          url: '',
          key: '',
        },
      },
      {
        name: '',
        contactNo: '',
        contactEmailId: '',
        qualification: '',
        attachment: {
          name: '',
          url: '',
          key: '',
        },
      },
    ],
  },
  spaceRequirement: {
    value: null,
    attachment: cloneDeep(ATTACHMENT),
  },
  records: {
    maintenanceArrangement: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    pledgeRegnArrangement: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    computer: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
  },
  equipment: {
    biomicroscope: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    specularMicroscope: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    laminarFlow: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    sterilizationFacility: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    refrigerator: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
  },
  labFacilities: {
    hivHeptFacility: {
      available: null,
      availingInst: {
        name: '',
        address: '',
      },
    },
    crneosclrlRngCltrSnstFacility: null,
  },
  regnRenewal: {
    fiveYearPeriod: {
      name: '',
      url: '',
      key: '',
    },
    fiveHundredCorneas: {
      name: '',
      url: '',
      key: '',
    },
    eyebankStandards: {
      name: '',
      url: '',
      key: '',
    },
  },
  formStatus: OPTIONS_FORM15EBSTATUS.NEW,
};

export const EYEBANK_FORM15ERC = {
  centerDetails: {
    name: '',
    address: '',
    gvtPvt: OPTIONS_GVTPVT[1].value,
    teachingNonTeaching: OPTIONS_TEACHING[0].value,
    mou: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
      hospitals: [],
    },
  },
  enucleationDetails: {
    name: '',
    address: '',
    qualification: '',
    attachment: cloneDeep(ATTACHMENT),
  },
  eyeBallRemoval: {
    manpower: {
      incharge: [
        {
          name: '',
          contactNo: '',
          contactEmailId: '',
          qualification: '',
          attachment: {
            name: '',
            url: '',
            key: '',
          },
        },
      ],
      technicians: [
        {
          name: '',
          contactNo: '',
          contactEmailId: '',
          qualification: '',
          attachment: {
            name: '',
            url: '',
            key: '',
          },
        },
      ],

      multiTaskStaff: [
        {
          name: '',
          contactNo: '',
          contactEmailId: '',
          qualification: '',
          attachment: {
            name: '',
            url: '',
            key: '',
          },
        },
      ],
    },
    transportFacility: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
  },
  telephone: {
    available: null,
    number: '',
  },
  ambulanceForCollectingEyeballs: {
    value: null,
    attachment: cloneDeep(ATTACHMENT),
  },
  corneaRemovalInstruments: {
    value: null,
    attachment: cloneDeep(ATTACHMENT),
  },
  corneaPreservationBottles: {
    value: null,
    attachment: cloneDeep(ATTACHMENT),
  },
  preservationMedia: {
    value: null,
    attachment: cloneDeep(ATTACHMENT),
  },
  bioMedicalRegnCert: {
    value: null,
    attachment: cloneDeep(ATTACHMENT),
  },
  spaceRequirement: {
    value: null,
    attachment: cloneDeep(ATTACHMENT),
  },
  records: {
    maintenanceArrangement: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
  },
  equipment: {
    sterilizationFacility: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    refrigerator: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
    eyebankAffiliation: null,
  },
  verification: {},
  inspection: {
    report: {
      name: '',
      url: '',
    },
  },
  approval: {
    certificate: {
      hash: '',
      attachment: {
        name: '',
        url: '',
        key: '',
      },
    },
  },
  formStatus: OPTIONS_FORM15ERCSTATUS.NEW,
};

export const EYEBANK_FORM15CTC = {
  centerDetails: {
    name: '',
    address: '',
    gvtPvtVoltry: OPTIONS_GVTPVT[1].value,
    teachingNonTeaching: OPTIONS_TEACHING[0].value,
    iec: '',
    registeredEyeBank: '',
  },
  staffDetails: {
    noOfPermanentStaff: {
      name: '',
      key: '',
      url: '',
    },
    noOfTemporaryStaff: {
      name: '',
      key: '',
      url: '',
    },
    trainedPersonnel: [],
  },
  equipment: {
    equipments: {
      value: null,
      attachment: cloneDeep(ATTACHMENT),
    },
  },
  otFacilities: {
    value: null,
    attachment: cloneDeep(ATTACHMENT),
  },
  safeStorageFacilities: {
    value: null,
    attachment: cloneDeep(ATTACHMENT),
  },
  recordsRegistration: {
    value: null,
    attachment: cloneDeep(ATTACHMENT),
  },
  otherInfo: '',

  verification: {},
  inspection: {
    report: {
      url: '',
      name: '',
      key: '',
    },
  },
  approval: {
    certificate: {
      hash: '',
      attachment: {
        name: '',
        url: '',
        key: '',
      },
    },
  },
  formStatus: OPTIONS_FORM15CTCSTATUS.NEW,
};

export const MATCHING = {
  matchingId: '',
  donorCorneaId: '',
  recipientCorneaId: '',
  eyeRecepientId: '',
  eyeDonorHospitalId: '',
  eyeRecepientHospitalId: '',
  comment: '',
  created: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  matchingStatus: OPTIONS_MATCHINGSTATUS.ADDED,
  formStatus: MATCHING_FORMSTATUS.NEW,
};

export const EYE_DONOR = {
  firstName: '',
  lastName: '',
  fatherName: '',
  hospitalName: '',
  sex: '',
  weight: Number,
  height: Number,
  corneas: [],
  age: Number,
  nationality: '',
  address: '',
  state: '',
  district: '',
  pinCode: Number,
  religion: '',
  uhid: '',
  eyeBank: '',
  deathCertifyingPhysician: '',
  drRegnNo: '',
  deathCertificatNo: '',
  medicoLegal: '',
  harvestingPerson: '',
  motivatedBy: '',
  removalPlace: '',
  deathDateTime: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  retrievalDateTime: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  excisionDateTime: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  tissueType: '',
  preservationType: '',
  preservationMedia: '',
  preservationMediaDeath: '',
  deathPreservationTime: '',
  corneaPreservationDateTime: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  tissueNumber: '',
  lotNoRe: '',
  lotNoLe: '',
  causeOfDeath: '',
  caseHistory: '',
  bloodCollected: '',
  personEnteringData: {
    name: '',
    designation: '',
    contact: '',
  },
  deathCertificate: {
    name: '',
    url: '',
    key: '',
  },
  consent: {
    name: '',
    url: '',
    key: '',
  },
  formStatus: OPTIONS_EYEDONORSTATUS.NEW,
};

export const EYE_RECIPIENT = {
  firstName: '',
  lastName: '',
  fatherName: '',
  sex: SEX.MALE.value,
  cornea: {},
  age: 50,
  nationality: OPTIONS_NATIONALITY[100].value,
  address: '',
  state: STATE_IN[20].value,
  district: DISTRICTS_MH[16].value,
  pinCode: '',
  contactOne: '',
  landline: '',
  contactTwo: '',
  idProof: OPTS_RECIPIENT_IDPROOF[0].value,
  idNumber: '',
  opd_mrdId: '',
  surgeonId: '',
  keratoplastyCenter: '',
  hospitalDetails: {
    hospitalId: '',
  },
  recommendingSurgeon: '',
  personEnteringData: {
    name: '',
    designation: '',
    contact: '',
  },
  keratoplasty: {
    value: OPTS_RECIPIENT_INDICOFKRTPLSTY[0].value,
    other: '',
  },
  keratoplastyPrtzngCatg: OPTS_RECIPIENT_PRIORITIZINGCATG[0].value,
  tissueType: OPTS_RECIPIENT_TISSUETYPE[0].value,
  tissueNumber: '',
  rightEyeDiagnosis: '',
  leftEyeDiagnosis: '',
  imageFile: '',
  fullFacePhoto: {
    name: '',
    url: '',
    key: '',
  },
  photoId: {
    name: '',
    url: '',
    key: '',
  },
  affectedEyePhoto: {
    name: '',
    url: '',
    key: '',
  },
  // patientCasePaper: {
  //   name: '',
  //   url: '',
  //   key: ''
  // },
  formStatus: OPTIONS_EYERECIPIENTSTATUS.NEW,
  remarks: '',
};

export const DONOR_EVALUATION = {
  donorEvaluationId: '',
  eyeDonorId: '',
  hospitalId: '',
  hospitalName: '',
  bloodCollected: {
    status: true,
    adequancy: OPTS_DONOREVAL_BLOODCOLLECTED_ADEQUACY[0].value,
  },
  corneaStatus: OPTS_DONOREVAL_CRNASTATUS[1].value,
  nonViableUsage: OPTS_DONOREVAL_NONVIABLEUSAGE[0].value,
  contraindication: OPTS_DONOREVAL_CONTRAINDICATION[0].value,
  serology: {
    status: false,
    hiv: OPTS_DONOREVAL_SEROLOGY[0].value,
    hbsag: OPTS_DONOREVAL_HBSAG_HCV[0].value,
    hcv: OPTS_DONOREVAL_HBSAG_HCV[0].value,
    vdrl: OPTS_DONOREVAL_SEROLOGY[0].value,
  },
  epithelium: {
    intactSurface: false,
    haze: OPTS_DONOREVAL_EPTHLM_HAZE[0].value,
    comments: '',
    exposure: {
      degree: OPTS_DONOREVAL_EPTHLM_EXPKRTSDEGREE[0].value,
      location: OPTS_DONOREVAL_EPTHLM_EXPKRTSLOC[0].value,
    },
    sloughing: {
      location: OPTS_DONOREVAL_EPTHLM_SLGHLOC[0].value,
      area: '',
    },
    debris: {
      present: true,
      details: '',
    },
  },
  stroma: {
    clearAndCompact: OPTS_DONOREVAL_STRMA_CLRCMPCT[0].value,
    edema: OPTS_DONOREVAL_STRMA_EDEMA[0].value,
    location: OPTS_DONOREVAL_STRMA_LOC[0].value,
    arcus: OPTS_DONOREVAL_STRMA_ARCS[0].value,
    clearZone: '',
    opacities1: {
      opacitiesType: OPTS_DONOREVAL_STRMA_OPCTTYPE[0].value,
      diameter: '',
      depth: '0',
      location: {
        distance: '0',
        reference: OPTS_DONOREVAL_STRMA_OPCTREF[0].value,
      },
    },
    opacities2: {
      opacitiesType: OPTS_DONOREVAL_STRMA_OPCTTYPE[0].value,
      diameter: '',
      depth: '0',
      location: {
        distance: '0',
        reference: OPTS_DONOREVAL_STRMA_OPCTREF[0].value,
      },
    },
    infiltrate: OPTIONS_YESNO[0].value,
    striae: {
      present: OPTIONS_YESNO[0].value,
      location: OPTS_DONOREVAL_STRMA_STRIAELOC[0].value,
      relativeNumber: OPTS_DONOREVAL_STRMA_STRIAERELNUM[0].value,
    },
    comments: '',
  },
  descemetsMembrane: {
    comments: '',
    defects: {
      defectType: OPTS_DONOREVAL_DESCMEM_DFCTSTYPE[0].value,
      details: '',
    },
    folds: {
      amount: OPTS_DONOREVAL_DESCMEM_FLDSAMNT[0].value,
      relativeNumber: OPTS_DONOREVAL_DESCMEM_FLDSRELNUM[0].value,
    },
  },
  endothelium: {
    stresslines: {
      present: OPTIONS_YESNO[0].value,
      location: OPTS_DONOREVAL_ENDLM_STRSLOC[0].value,
      relativeNumber: OPTS_DONOREVAL_ENDLM_STRSLNRELNUM[0].value,
    },
    defects: {
      present: false,
      details: '',
    },
    cellDropout: {
      amount: OPTS_DONOREVAL_ENDLM_CLDPUTAMNT[0].value,
      location: OPTS_DONOREVAL_ENDLM_CLDPUTLOC[0].value,
    },
    polymegathism: OPTS_DONOREVAL_ENDLM_PLYMGTSM[0].value,
    pleomorphism: OPTS_DONOREVAL_ENDLM_PLEOMRPSHM[0].value,
    comments: '',
    cellsPerMm: '',
    photo: {
      name: '',
      url: '',
      key: '',
    },
  },
  addnlInfo: {
    jaundice: OPTS_DONOREVAL_ADNLINFO_JAUNDICE[0].value,
    lens: OPTS_DONOREVAL_ADNLINFO_LENS[0].value,
    scleralRimEvaluation: OPTS_DONOREVAL_ADNLINFO_SCLRLRMEVAL[0].value,
    scleralRimGreatThan2mm: {
      status: OPTIONS_YESNO[0].value,
      details: '',
    },
  },
  approval: {
    corneaTxSuitability: {
      status: OPTIONS_YESNO[0].value,
      suitableOnlyFor: {
        pk: false,
        ek: false,
        alk: false,
        kla: false,
        kPro: false,
        therapeutic: false,
      },
      notSuitableDueTo: {
        epithelium: false,
        stroma: {
          status: false,
          reason: OPTS_DONOREVAL_APRVL_NTSTBLSTRMA,
        },
        descementsMembrane: false,
        endothelium: false,
      },
    },
    checkedBy: '',
    checkedDateTime: moment().format(FORMAT_DATE_UNIXTS),
    evaluationComments: '',
  },
  formStatus: OPTIONS_DONOREVALUATIONFORM_STATUS.NEW,
};

export const KERATOPLASTY_REPORT = {
  eyeRecepientId: '',
  corneaId: '',
  hospitalId: '',
  hospitalName: '',
  created: '',
  surgeonName: '',
  surgicalTechnique: {
    value: OPTS_KRTPLSTYRPT_SURGICALTECHQ[0].value,
    other: '',
  },
  donorGraftSize: '',
  recipientGraftSize: '',
  cataract: {
    value: OPTS_KRTPLSTYRPT_CATARACT[0].value,
    other: '',
  },
  iol: {
    value: OPTS_KRTPLSTYRPT_IOL[0].value,
    other: '',
  },
  vitrectomy: {
    value: OPTS_KRTPLSTYRPT_VITRECTOMY[0].value,
    other: '',
  },
  sutureSizeType: {
    value: OPTS_KRTPLSTYRPT_SUTURESIZETYPE[0].value,
    other: '',
  },
  sutureTechnique: {
    value: OPTS_KRTPLSTYRPT_SUTURETECHQ[0].value,
    other: '',
  },
  surgeryComplications: {
    status: false,
    nature: '',
  },
  comments: '',
  surgeryDate: '',
  operatedEyes: OPTS_KRTPLSTYRPT_OPERATEDEYES[0],
  preOpDiagnosis: {
    value: OPTS_KRTPLSTYRPT_PREOPDIAG[0].value,
    other: '',
  },
  formStatus: OPTIONS_KERATOPLASTYREPORTFORM_STATUS.NEW,
};

export const LEGACYFORM15EB = {
  form15Eb: {
    hash: '',
    name: '',
    url: '',
    key: '',
  },
  issuedOn: null,
  verification: {},
  approval: {
    certificate: {
      hash: '',
      attachment: {
        name: '',
        url: '',
        key: '',
      },
    },
  },
  formStatus: OPTIONS_LEGACYFORM15EBSTATUS.NEW,
};

export const LEGACYFORM15ERC = {
  form15Erc: {
    hash: '',
    name: '',
    url: '',
    key: '',
  },
  issuedOn: null,
  verification: {},
  approval: {
    certificate: {
      hash: '',
      attachment: {
        name: '',
        url: '',
        key: '',
      },
    },
  },
  formStatus: OPTIONS_LEGACYFORM15ERCSTATUS.NEW,
};

export const LEGACYFORM15CTC = {
  form15Ctc: {
    hash: '',
    name: '',
    url: '',
    key: '',
  },
  issuedOn: null,
  trainedPersons: [],
  verification: {},
  approval: {
    certificate: {
      hash: '',
      attachment: {
        name: '',
        url: '',
        key: '',
      },
    },
  },
  formStatus: OPTIONS_LEGACYFORM15CTCSTATUS.NEW,
};
