const CONTEXT_DHS = '/dhs';
const CONTEXT_HOSPITAL = '/hospital';

const CONTEXT_ORGAN = '/eyebank';

const CONTEXT_REGISTRATION = `${CONTEXT_ORGAN}/regn`;

const CONTEXT_LEGACYFORM15CTC = `${CONTEXT_REGISTRATION}/legacyForm15Ctc`;

export const PATH_LEGACYFORM15CTC_GETISSUES = `${CONTEXT_LEGACYFORM15CTC}/%s/issues`;
export const PATH_LEGACYFORM15CTC_DHS_UPDATEISSUESTATUS = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_DHS}/issue`;
export const PATH_LEGACYFORM15CTC_DHS_ADDISSUE = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_DHS}/issue`;


export const PATH_LEGACYFORM15CTC_DHS_GETSUMMARIES = `${CONTEXT_LEGACYFORM15CTC + CONTEXT_DHS}/legacyForm15Ctcsummaries`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_GETLEGACYFORM15CTCSUMMARIES = `${CONTEXT_LEGACYFORM15CTC + CONTEXT_HOSPITAL}/%s/legacyForm15Ctcsummaries`;

export const PATH_LEGACYFORM15CTC_DHS_GETLEGACYFORM15CTC = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_DHS}`;
export const PATH_LEGACYFORM15CTC_DHS_SIGNLEGACYFORM15CTC = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_DHS}/signature`;
export const PATH_LEGACYFORM15CTC_DHS_APPROVELEGACYFORM15CTC = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_DHS}/approval`;
export const PATH_LEGACYFORM15CTC_DHS_ADDCERTIFICATE = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_DHS}/certificate`;
export const PATH_LEGACYFORM15CTC_DHS_GETAPPROVAL = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_DHS}/approval`;

export const PATH_LEGACYFORM15CTC_HOSPITAL_SUBMITDRAFTLEGACYFORM15CTC = `${CONTEXT_LEGACYFORM15CTC + CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_SUBMITLEGACYFORM15CTCEDITS = `${CONTEXT_LEGACYFORM15CTC + CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_UPDATELEGACYFORM15CTCSTATUS = `${CONTEXT_LEGACYFORM15CTC + CONTEXT_HOSPITAL}/%s/status`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_SUBMITUNDERTAKING = `${CONTEXT_LEGACYFORM15CTC + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_UPDATEUNDERTAKING = `${CONTEXT_LEGACYFORM15CTC + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_GETDRAFTLEGACYFORM15CTC = `${CONTEXT_LEGACYFORM15CTC + CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_GETLEGACYFORM15CTC = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_GETAPPROVAL = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_HOSPITAL}/%s/approval`;
export const PATH_LEGACYFORM15CTC_DHS_REVERTFORM15CTC = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_DHS}/revert`;

export const PATH_LEGACYFORM15CTC_DHS_GETSPECIALISTCHANGEREQUESTS = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_DHS}/specialistChangeRequests`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_GETSPECIALISTCHANGEREQUESTS = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_HOSPITAL}/specialistChangeRequests`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_SUBMITSPECIALISTCHANGEREQUESTS = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_HOSPITAL}/specialistChange`;
export const PATH_LEGACYFORM15CTC_DHS_UPDATESPECIALISTCHANGEREQUEST = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_DHS}/specialistUpdate/%s`;
export const PATH_LEGACYFORM15CTC_HOSPITAL_UPDATESPECIALISTCHANGEREQUEST = `${CONTEXT_LEGACYFORM15CTC}/%s${CONTEXT_HOSPITAL}/specialistUpdate/%s`;

export const OPTIONS_LEGACYFORM15CTCSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  REVERTED: 'REVERTED',
  ATTESTED: 'ATTESTED',
  REATTESTED: 'REATTESTED',
  INITIATED: 'INITIATED',
  APPROVED: 'APPROVED'
};
