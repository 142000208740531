import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import winston from '../winston';
import LoadingScreen from '../components/Custom/LoadingScreen';
import { useCancellableApiCall } from '../hooks';
import { PATH_EXP_GETINDEXHOSPITAL, PATH_EXP_GETUSERHOSPITALS } from '../appConstants';
import { SET_HOSPITAL_AND_USER, SET_USERATTR } from '../constants';

const ComponentWithUserContext = ({ wrappedComponent: WrappedComponent, ...props }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [userHospitals, setUserHospitals] = useState([]);

  const { apiGet } = useCancellableApiCall();

  const getHospitalAndUser = (hospitalId, userType) => {
    apiGet(PATH_EXP_GETINDEXHOSPITAL, { hospitalId })
      .then((hospital) => {
        dispatch({
          type: SET_HOSPITAL_AND_USER,
          hospitalAndUserType: { hospital, userType },
        });
      })
      .catch((err) => {
        setLoading(false);
        winston.error('Error getting hospital', err);
        throw err;
      });
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        const {
          // eslint-disable-next-line camelcase
          attributes: { email, name, phone_number },
          signInUserSession: {
            idToken: {
              // eslint-disable-next-line camelcase
              payload: { lastlogin_ip, lastlogin_time, lastlogin_browser },
            },
          },
        } = cognitoUser;
        dispatch({
          type: SET_USERATTR,
          userAttr: { email, name, phone_number },
        });
      })
      .catch(() => {});

    apiGet(PATH_EXP_GETUSERHOSPITALS)
      .then((hospitalUsers) => {
        if (hospitalUsers?.length === 1) {
          const [userHospital] = hospitalUsers;
          getHospitalAndUser(userHospital?.hospital?.hospitalId, userHospital.userType);
        } else {
          setUserHospitals(hospitalUsers);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        winston.error('Error getting usercontext', err);
        throw err;
      });
  }, [apiGet, dispatch]);

  const app = useSelector((state) => state);
  if (app.isLoggedIn) {
    return <WrappedComponent {...props} />;
  }
  if (loading) {
    return <LoadingScreen loadingText="Loading Profile..." />;
  }

  if (Array.isArray(userHospitals) && userHospitals.length > 0) {
    return (
      <Grid container style={{ padding: 20 }}>
        <Grid item xs={12}>
          <Typography variant="h2">Select hospital</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            Multiple hospitals/roles found for account. Please choose one
          </Typography>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 20 }}>
          {userHospitals.map((userHospital) => {
            return (
              <Grid item key={userHospital?._id} xs={12} sm={3}>
                <Button
                  onClick={() => {
                    getHospitalAndUser(userHospital?.hospital?.hospitalId, userHospital?.userType);
                  }}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {userHospital?.hospital?.name.toUpperCase()} : {userHospital?.userType}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }
  return null;
};
ComponentWithUserContext.propTypes = {
  wrappedComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

export default function withUserContext(WrappedComponent) {
  function AuthenticatedComponentWithUserContext(props) {
    const { authState } = props;
    if (authState !== 'signedIn') {
      return null;
    }

    return <ComponentWithUserContext wrappedComponent={WrappedComponent} {...props} />;
  }
  AuthenticatedComponentWithUserContext.propTypes = {
    authState: PropTypes.string,
  };
  AuthenticatedComponentWithUserContext.defaultProps = {
    authState: undefined,
  };

  return AuthenticatedComponentWithUserContext;
}
