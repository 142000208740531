export const DISTRICTS_BR = [
  {
    label: 'Araria',
    value: 'ARARIA'
  },
  {
    label: 'Arwal',
    value: 'ARWAL'
  },
  {
    label: 'Aurangabad',
    value: 'AURANGABAD'
  },
  {
    label: 'Banka',
    value: 'BANKA'
  },
  {
    label: 'Begusarai',
    value: 'BEGUSARAI'
  },
  {
    label: 'Bhagalpur',
    value: 'BHAGALPUR'
  },
  {
    label: 'Bhojpur',
    value: 'BHOJPUR'
  },
  {
    label: 'Buxar',
    value: 'BUXAR'
  },
  {
    label: 'Darbhanga',
    value: 'DARBHANGA'
  },
  {
    label: 'East Champaran (Motihari)',
    value: 'EAST_CHAMPARAM'
  },
  {
    label: 'Gaya',
    value: 'GAYA'
  },
  {
    label: 'Gopalganj',
    value: 'GOPALGANJ'
  },
  {
    label: 'Jamui',
    value: 'JAMUI'
  },
  {
    label: 'Kaimur (Bhabua)',
    value: 'KAIMUR'
  },
  {
    label: 'Katihar',
    value: 'KATIHAR'
  },
  {
    label: 'Jehanabad',
    value: 'JEHANABAD'
  },
  {
    label: 'Khagaria',
    value: 'KHAGARIA'
  },
  {
    label: 'Kishanganj',
    value: 'KISHANGANJ'
  },
  {
    label: 'Lakhisarai',
    value: 'LAKHISARAI'
  },
  {
    label: 'Madhepura',
    value: 'MADHEPURA'
  },
  {
    label: 'Madhubani',
    value: 'MADHUBANI'
  },
  {
    label: 'Munger (Monghyr)',
    value: 'MUNGER'
  },
  {
    label: 'Muzaffarpur',
    value: 'MUZAFFARPUR'
  },
  {
    label: 'Nalanda',
    value: 'NALANDA'
  },
  {
    label: 'Nawada',
    value: 'NAWADA'
  },
  {
    label: 'Patna',
    value: 'PATNA'
  },
  {
    label: 'Purnia (Purnea)',
    value: 'PURNIA'
  },
  {
    label: 'Rohtas',
    value: 'ROHTAS'
  },
  {
    label: 'Saharsa',
    value: 'SAHARSA'
  },
  {
    label: 'Samastipur',
    value: 'SAMASTIPUR'
  },
  {
    label: 'Saran',
    value: 'SARAN'
  },
  {
    label: 'Sheikhpura',
    value: 'SHEIKHPURA'
  },
  {
    label: 'Sheohar',
    value: 'SHEOHAR'
  },
  {
    label: 'Sitamarhi',
    value: 'SITAMARHI'
  },
  {
    label: 'Siwan',
    value: 'SIWAN'
  },
  {
    label: 'Supaul',
    value: 'SUPAUL'
  },
  {
    label: 'Vaishali',
    value: 'VAISHALI'
  },
  {
    label: 'West Champaran',
    value: 'WEST_CHAMPARAN'
  }
];
