import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  handleSpecialistsChange,
  getSpecialistById,
} from './newOtherSupportiveSpecialistsModalScripts';
import { ValidatedSelect, ReactTable, ValidatedInput } from '../../Custom';
import { withCancellableResolver } from '../../../hoc';
import { OPTIONS_SPECIALISATION } from '../../../constants';

class NewOtherSupportiveSpecialistsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialists: [],
      otherHospitalSpecialist: [],
      activeTab: this.tabs.INHOUSE.value,
      searchText: '',
    };
  }

  TABS = {
    INHOUSE: { label: 'Inhouse', value: 'INHOUSE' },
    DONORHOSPITAL: { label: 'Other Hospital', value: 'DONORHOSPITAL' },
  };

  tabs = this.TABS;

  _toggleSpecialistList(tabValue) {
    const { activeTab } = this.state;
    if (activeTab !== tabValue) {
      this.setState({
        activeTab: tabValue,
      });
    }
  }

  _selectSpecialistFormatter(specialist) {
    const { trainedPersons } = this.props;
    const selected = trainedPersons.filter((s) => s.userId === specialist.userId).length > 0;
    return (
      <>
        {selected ? (
          <Button
            color="secondary"
            variant="contained"
            id={`removeSpecialist_${specialist.userId}`}
            name={`removeSpecialist_${specialist.userId}`}
            onClick={() => handleSpecialistsChange(specialist, false, this)}
          >
            Remove
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            id={`addSpecialist_${specialist.userId}`}
            name={`addSpecialist_${specialist.userId}`}
            onClick={() => handleSpecialistsChange(specialist, true, this)}
          >
            Add
          </Button>
        )}
      </>
    );
  }

  static _specialisationFormatter(specialisation) {
    return (
      <ValidatedSelect
        id="specialisation"
        name="specialisation"
        value={specialisation}
        options={OPTIONS_SPECIALISATION}
        disabled={true}
        multiple={true}
        placeholder="Specialisation"
        autosize
      />
    );
  }

  specialistsColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      key: 'name',
    },
    {
      Header: 'Specialisation',
      accessor: 'specialisation',
      key: 'specialisation',
      disableFilters: true,
      Cell: ({ value }) => this._formatSpecialisation(value),
    },
    {
      Header: 'Select',
      accessor: 'userId',
      key: 'userId',
      disableFilters: true,
      Cell: ({ row }) => this._selectSpecialistFormatter(row.original),
    },
  ];

  render() {
    const { specialists, activeTab, searchText, otherHospitalSpecialist } = this.state;
    const { isOpen, toggle } = this.props;
    return (
      <Dialog
        open={isOpen}
        onClose={() => {
          toggle(false);
        }}
        onEnter={() => {
          this._getFilteredSpecialists();
        }}
        fullScreen
      >
        <DialogTitle>
          {' '}
          <Grid container justify="space-between" alignItems="center">
            Add Specialists
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                toggle(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: 'primary',
                }}
              />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ margin: 20 }}>
          <Grid container>
            <Grid item sm={6}>
              <Button
                variant="outlined"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  border: '1px solid #25B67D',
                  borderBottom: 'none',
                  borderRadius: '2px',
                  fontSize: '16px',
                  height: '52px',
                  backgroundColor: activeTab === 'INHOUSE' ? '#25B67D' : '',
                  color: activeTab === 'INHOUSE' ? '#F0FEF9' : '',
                }}
                onClick={() => {
                  // setActiveTab('INHOUSE');
                }}
              >
                Inhouse Specialist
              </Button>
              <Button
                variant="outlined"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  border: '1px solid #25B67D',
                  borderBottom: 'none',
                  borderRadius: '2px',
                  fontSize: '16px',
                  height: '52px',
                  backgroundColor: activeTab === 'OTHERHOSPITAL' ? '#25B67D' : '',
                  color: activeTab === 'OTHERHOSPITAL' ? '#F0FEF9' : '',
                }}
                onClick={() => {
                  // setActiveTab('OTHERHOSPITAL');
                }}
              >
                Other Hospital Specialist
              </Button>
            </Grid>
            {activeTab === 'OTHERHOSPITAL' ? (
              <Grid item sm={6}>
                <ValidatedInput
                  required
                  id="specialistId"
                  name="specialistId"
                  label="Specialist ID"
                  value={searchText}
                  onChange={(e) => {
                    // setSearchText(e.target.value);
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    fontWeight: 600,
                    width: '64px',
                    height: 45,
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                  // component={SearchIcon}
                  onClick={() => {
                    // _getSpecialistById();
                  }}
                />
              </Grid>
            ) : null}

            <Grid sm={12}>
              <ReactTable
                columns={this.specialistsColumns}
                data={activeTab === 'INHOUSE' ? specialists : otherHospitalSpecialist}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              toggle(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      // <div className="animated fadeIn">
      //   <Modal
      //     onOpened={() => this._getFilteredSpecialists()}
      //     isOpen={isOpen}
      //     style={{
      //       width: '50%',
      //       maxWidth: 'none',
      //       marginTop: '100px',
      //     }}
      //     toggle={() => toggle(false)}
      //     className="modal-sm-specialist"
      //     zIndex="500"
      //   >
      //     <ModalHeader toggle={() => toggle(false)}>Add Specialists</ModalHeader>
      //     <ModalBody>
      //       <Row>
      //         <Col lg="12">
      //           <Nav tabs>
      //             {Object.values(this.tabs).map((tab) => (
      //               <NavItem key={tab.value}>
      //                 <NavLink
      //                   className={classnames({
      //                     active: activeTab === tab.value,
      //                   })}
      //                   onClick={() => {
      //                     this._toggleSpecialistList(tab.value);
      //                   }}
      //                 >
      //                   {tab.label}
      //                 </NavLink>
      //               </NavItem>
      //             ))}
      //             {activeTab === 'DONORHOSPITAL' ? (
      //               <Col lg="5" className="offset-md-3">
      //                 <InputGroup>
      //                   <Input
      //                     innerRef={(node) => {
      //                       this.searchInput = node;
      //                     }}
      //                     bsSize="sm"
      //                     placeholder="Search"
      //                     value={searchText}
      //                     onChange={(e) => handleSearchText(e.target.name, e.target.value, this)}
      //                     onKeyDown={(e) => {
      //                       if (e.which === 13 || e.keyCode === 13) {
      //                         this._getSpecialistById();
      //                       }
      //                     }}
      //                   />
      //                   <InputGroupAddon addonType="append">
      //                     <Button
      //                       variant="contained"
      //                       size="sm"
      //                       color="secondary"
      //                       // onClick={() => this._handleReset(clearFilters)}
      //                     >
      //                       <i className="fa fa-times" />
      //                     </Button>
      //                   </InputGroupAddon>
      //                 </InputGroup>
      //               </Col>
      //             ) : null}
      //           </Nav>
      //         </Col>
      //         {activeTab === 'INHOUSE' ? (
      //           <Col lg="12">
      //             <Row>
      //               <Col lg="12">
      //                 <Table
      //                   rowKey="userId"
      //                   columns={this.specialistsColumns}
      //                   dataSource={specialists}
      //                   bordered
      //                   size="small"
      //                 />
      //               </Col>
      //             </Row>
      //           </Col>
      //         ) : (
      //           <Col lg="12">
      //             <Row>
      //               <Col lg="12">
      //                 <Table
      //                   rowKey="userId"
      //                   columns={this.specialistsColumns}
      //                   dataSource={otherHospitalSpecialist}
      //                   bordered
      //                   size="small"
      //                 />
      //               </Col>
      //             </Row>
      //           </Col>
      //         )}
      //       </Row>
      //     </ModalBody>
      //     <ModalFooter>
      //       <Col lg="2">
      //         <Button
      //           id="closeButton"
      //           color="primary"
      //           variant="contained"
      //           onClick={() => toggle(false)}
      //         >
      //           Close
      //         </Button>
      //       </Col>
      //     </ModalFooter>
      //   </Modal>
      // </div>
    );
  }

  _formatSpecialisation = (specialisation) => {
    return specialisation.join(' , ');
  };

  _getFilteredSpecialists() {
    const { trainedPersons } = this.props;
    const { specialists } = this.props;
    // const filteredSpecialists = specialists.filter(
    //   (s) => trainedPersons.filter((t) => s.userId === t.userId).length === 0
    // );
    const filteredSpecialists = specialists.filter((f) => {
      return f?.approval?.status !== "REJECTED";
    });
    
    this.setState({
      specialists: filteredSpecialists,
    });
  }

  _getSpecialistById() {
    const { searchText } = this.state;
    const { resolveWithCancellable, userType, hospitalId } = this.props;
    resolveWithCancellable(getSpecialistById(hospitalId, userType, 'SURGEON', searchText))
      .then((otherHospitalSpecialist) => {
        this.setState({ otherHospitalSpecialist });
      })
      .catch(() => {});
  }

  componentDidMount() {}
}
NewOtherSupportiveSpecialistsModal.propTypes = {
  trainedPersons: PropTypes.arrayOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  resolveWithCancellable: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  hospitalId: PropTypes.string.isRequired,
  // this is required, but used only in the js file
  // eslint-disable-next-line react/no-unused-prop-types
  setOtherSupportiveSpecialists: PropTypes.func.isRequired,
  specialists: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  userType: state.user.userType,
  hospitalId: state.hospital.hospitalId,
  isDemo: state.isDemo,
});

export default compose(
  withRouter,
  withCancellableResolver,
  connect(mapStateToProps)
)(NewOtherSupportiveSpecialistsModal);
