import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Fade,
  IconButton,
  makeStyles,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { useFilters, usePagination, useTable } from 'react-table';
import set from 'lodash.set';

const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: 12,
    textAlign: 'center',
  },
  head: {
    backgroundColor: '#F7FFFC',
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
    },
    height: '50px',
    textAlign: 'center',
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const NUM_LOADINGROWS = 5;
export default function ReactTable({
  columns,
  data,
  apiCallStatuses,
  apiCallId,
  rowsInSinglePage,
}) {
  const [anchorEl, setAnchorEl] = useState({});

  useEffect(() => {}, []);

  const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => (
    <TextField
      placeholder="Search..."
      id="filter"
      margin="none"
      size="small"
      variant="outlined"
      autoFocus={true}
      value={filterValue || ''}
      // style={{ fontSize: 14 }}
      // InputProps={{
      //   style: {
      //     fontSize: 14,
      //   },
      // }}
      onChange={(e) => {
        setFilter(e.target.value.trimLeft() || undefined);
      }}
    />
  );

  const tableColumns = useMemo(() => columns.filter((c) => !c.hideInTable), [columns]);
  let tableData = useMemo(() => (data || []).filter((d) => !d.hideInTable), [data]);

  let isLoading;
  if (apiCallId && apiCallStatuses) {
    isLoading = apiCallStatuses[apiCallId] === 'LOADING';
  } else if (apiCallStatuses) {
    isLoading = Object.values(apiCallStatuses).reduce(
      (loading, apiCallStatus) => loading || apiCallStatus === 'LOADING',
      false
    );
  }

  const placeholderValue = columns.reduce((v, c) => {
    set(v, c.accessor, '');
    return v;
  }, {});

  const sampleData = useMemo(
    () => [...new Array(NUM_LOADINGROWS)].map(() => placeholderValue),
    [tableData]
  );

  if (isLoading) {
    tableData = sampleData;
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      defaultColumn: {
        Filter: DefaultColumnFilter,
      },
      initialState: { pageSize: rowsInSinglePage || 5 },
    },
    useFilters,
    usePagination
  );

  const classes = useStyles();

  // Render the UI for your table
  return (
    <Paper style={{ width: '100%' }}>
      <TableContainer
        style={{
          border: 'solid',
          borderWidth: 1,
          borderColor: '#B1E7D3',
        }}
      >
        <Table {...getTableProps()} className={classes.table} size="small">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => (
                  <StyledTableCell {...column.getHeaderProps()}>
                    {column.render('Header')}
                    {column.canFilter ? (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          const colAnchor = anchorEl[idx] ? null : e.currentTarget;
                          setAnchorEl({ ...anchorEl, [idx]: colAnchor });
                        }}
                      >
                        <MagnifyIcon size={18} aria-describedby={idx} />
                        <Popper
                          id={idx}
                          open={!!anchorEl[idx]}
                          anchorEl={anchorEl[idx]}
                          transition
                          placement="bottom-end"
                          disablePortal={false}
                          modifiers={{
                            flip: {
                              enabled: true,
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: 'scrollParent',
                            },
                          }}
                          style={{ zIndex: 5000 }}
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                              <ClickAwayListener
                                onClickAway={() => {
                                  setAnchorEl({ ...anchorEl, [idx]: undefined });
                                }}
                              >
                                <Paper>{column.render('Filter')}</Paper>
                              </ClickAwayListener>
                            </Fade>
                          )}
                        </Popper>
                      </IconButton>
                    ) : null}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page ? (
              page.map((row) => {
                prepareRow(row);
                if (isLoading) {
                  return (
                    <StyledTableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <StyledTableCell {...cell.getCellProps()}>
                            <Skeleton animation="wave" width={cell.column.width} height={20} />
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                }
                return (
                  <StyledTableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <StyledTableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <Paper
                  style={{
                    width: '100%',
                    height: '300px',
                    backgroundColor: '#808080',
                    marginBottom: '10px',
                  }}
                />
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        onChangePage={(e, newPage) => gotoPage(newPage)}
        onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
      />
    </Paper>
  );
}
ReactTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  apiCallId: PropTypes.string,
  apiCallStatuses: PropTypes.objectOf(PropTypes.any),
  rowsInSinglePage: PropTypes.number,
};
ReactTable.defaultProps = {
  apiCallId: undefined,
  apiCallStatuses: undefined,
  rowsInSinglePage: 5,
};
