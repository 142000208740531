import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Typography,
  Grid,
  makeStyles,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatedAttachment, ValidatedInput, ValidatedRadio } from '../../Custom';
import { FILETYPES } from '../../Custom/ValidatedAttachment';

function MedicalReportsHemodynamics({ donorMedicalReportsDetails, setDonorMedicalReportsDetails }) {
  const app = useSelector((state) => state);
  const {
    user: { userType, userId },
  } = app;

  const useStyles = makeStyles(() => ({
    paperTransplantInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 20,
      marginBottom: 10,
    },
    gridTransplantDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperTransplantSections: {
      height: '100%',
      marginTop: 5,
    },
    transplantSectionsStyle: {
      fontWeight: 300,
      lineHeight: '15px',
      marginTop: 20,
      marginLeft: 20,
    },
    transplantSectionsHeadingStyle: {
      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    transplantDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#34495E',
      marginTop: '5px',
    },
    form12BoxStyle: {
      direction: 'row',
      // marginTop: 90,
      // ...(_isDisabled() ? { marginTop: 120 } : {}),
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Hematology
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">CBC Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.latestInvestigations?.cbcReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  cbcReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">WBC Count</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.wbc}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  wbc: e.target.value,
                },
              });
            }}
          />
        </Grid>
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Differential Count</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.differential}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  differential: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Platelet Count</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.platelet}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  platelet: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Hemoglobin</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.hemoglobin}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  hemoglobin: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            ABG
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">ABG Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.latestInvestigations?.abgReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  abgReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">HbA1c Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.latestInvestigations?.hba1cReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  hba1cReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">ABG pH</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.ph}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  ph: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">ABG pCO2</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.pco2}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  pco2: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">ABG pO2</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.po2}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  po2: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">ABG HCO3</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.hco3}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  hco3: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">ABG SaO2</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.sao2}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  sao2: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Blood Sugar</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.bloodSugarLevel}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  bloodSugarLevel: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">HbA1c</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.latestInvestigations?.hba1c}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,
                  hba1c: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Urine Routine
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Urine Routine Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.latestInvestigations?.urineRoutineReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                latestInvestigations: {
                  ...donorMedicalReportsDetails.latestInvestigations,

                  urineRoutineReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Physical Examination
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Quantity(in ml)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.physical?.quantity}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    physical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.physical,
                      quantity: e.target.value,
                    },
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Color</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.physical?.color}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    physical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.physical,
                      color: e.target.value,
                    },
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Appearance</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.physical?.appearance}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    physical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.physical,
                      appearance: e.target.value,
                    },
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Deposit"
            name="deposit"
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.physical?.deposit}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    physical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.physical,
                      deposit: e.target.value,
                    },
                  },
                },
              })
            }
            options={[
              {
                label: 'Absent',
                value: 'ABSENT',
              },
              {
                label: 'Present',
                value: 'PRESENT',
              },
            ]}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Reaction</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.physical?.reaction}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    physical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.physical,
                      reaction: e.target.value,
                    },
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Specific Gravity</Typography>
          <ValidatedInput
            required
            fullWidth
            value={
              donorMedicalReportsDetails?.renalFunction?.urineRoutine?.physical?.specificGravity
            }
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    physical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.physical,
                      specificGravity: e.target.value,
                    },
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Chemical Examination
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Protein"
            name="protein"
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.chemical?.protein}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    chemical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.chemical,
                      protein: e.target.value,
                    },
                  },
                },
              })
            }
            options={[
              {
                label: 'Absent',
                value: 'ABSENT',
              },
              {
                label: 'Present',
                value: 'PRESENT',
              },
            ]}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Glucose"
            name="glucose"
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.chemical?.glucose}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    chemical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.chemical,
                      glucose: e.target.value,
                    },
                  },
                },
              })
            }
            options={[
              {
                label: 'Absent',
                value: 'ABSENT',
              },
              {
                label: 'Present',
                value: 'PRESENT',
              },
            ]}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Occult Blood"
            name="occultBlood"
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.chemical?.occultBlood}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    chemical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.chemical,
                      occultBlood: e.target.value,
                    },
                  },
                },
              })
            }
            options={[
              {
                label: 'Absent',
                value: 'ABSENT',
              },
              {
                label: 'Present',
                value: 'PRESENT',
              },
            ]}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Ketones"
            name="ketones"
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.chemical?.ketones}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    chemical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.chemical,
                      ketones: e.target.value,
                    },
                  },
                },
              })
            }
            options={[
              {
                label: 'Absent',
                value: 'ABSENT',
              },
              {
                label: 'Present',
                value: 'PRESENT',
              },
            ]}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Bile Salt"
            name="bileSalt"
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.chemical?.bileSalt}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    chemical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.chemical,
                      bileSalt: e.target.value,
                    },
                  },
                },
              })
            }
            options={[
              {
                label: 'Absent',
                value: 'ABSENT',
              },
              {
                label: 'Present',
                value: 'PRESENT',
              },
            ]}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Pigments"
            name="pigments"
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.chemical?.pigments}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    chemical: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.chemical,
                      pigments: e.target.value,
                    },
                  },
                },
              })
            }
            options={[
              {
                label: 'Absent',
                value: 'ABSENT',
              },
              {
                label: 'Present',
                value: 'PRESENT',
              },
            ]}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Microscopic Examination
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Pus Cells</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.microscopic?.pusCells}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    microscopic: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.microscopic,
                      pusCells: e.target.value,
                    },
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Red Blood Cells</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.microscopic?.rbc}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    microscopic: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.microscopic,
                      rbc: e.target.value,
                    },
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Epithelial Cells</Typography>
          <ValidatedInput
            required
            fullWidth
            value={
              donorMedicalReportsDetails?.renalFunction?.urineRoutine?.microscopic?.epithelialCells
            }
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    microscopic: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.microscopic,
                      epithelialCells: e.target.value,
                    },
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Casts"
            name="casts"
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.microscopic?.casts}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    microscopic: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.microscopic,
                      casts: e.target.value,
                    },
                  },
                },
              })
            }
            options={[
              {
                label: 'Absent',
                value: 'ABSENT',
              },
              {
                label: 'Present',
                value: 'PRESENT',
              },
            ]}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Crystals"
            name="crystals"
            value={donorMedicalReportsDetails?.renalFunction?.urineRoutine?.microscopic?.crystals}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  urineRoutine: {
                    ...donorMedicalReportsDetails.renalFunction.urineRoutine,
                    microscopic: {
                      ...donorMedicalReportsDetails.renalFunction.urineRoutine.microscopic,
                      crystals: e.target.value,
                    },
                  },
                },
              })
            }
            options={[
              {
                label: 'Absent',
                value: 'ABSENT',
              },
              {
                label: 'Present',
                value: 'PRESENT',
              },
            ]}
          />
        </Grid>
        {/* <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Renal Function Test (RFT)
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Creatinine (During Admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.serumCreatinineAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  serumCreatinineAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Creatinine (At Present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.serumCreatinine}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  serumCreatinine: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Serum Electrolytes</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.electrolytes}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  electrolytes: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Sodium (Na)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.na}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  na: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Potassium (K)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.k}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  k: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Chloride (Cl)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.cl}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  cl: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">BUN (Blood Urea Nitrogen)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.bun}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  bun: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        {/* <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Liver Fuction Test (LFT)
          </Typography>
        </Grid> */}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Total Bilirubin</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.total}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  total: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Direct Bilirubin</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.direct}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  direct: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Indirect Bilirubin</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.indirect}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  indirect: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">SGOT</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.sgot}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  sgot: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">SGPT</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.spgt}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  spgt: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">S Alkaline Phosphatase</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.alkalinePhosphatase}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  alkalinePhosphatase: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Total Protein</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.totalProtein}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  totalProtein: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Serum Albumin</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.albumin}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  albumin: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
      </Grid>
    </>
  );
}

MedicalReportsHemodynamics.propTypes = {
  donorMedicalReportsDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  setDonorMedicalReportsDetails: PropTypes.func.isRequired,
};
MedicalReportsHemodynamics.defaultProps = {};

export default MedicalReportsHemodynamics;
