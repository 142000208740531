export const DISTRICTS_UP = [
  {
    label: 'Agra',
    value: 'AGRA'
  },
  {
    label: 'Aligarh',
    value: 'ALIGARH'
  },
  {
    label: 'Allahabad',
    value: 'ALLAHABAD'
  },
  {
    label: 'Ambedkar Nagar',
    value: 'AMBEDKAR_NAGAR'
  },
  {
    label: 'Amethi (Chatrapati Sahuji Mahraj Nagar)',
    value: 'AMETHI'
  },
  {
    label: 'Amroha (J.P. Nagar)',
    value: 'AMROHA'
  },
  {
    label: 'Auraiya',
    value: 'AURAIYA'
  },
  {
    label: 'Azamgarh',
    value: 'AZAMGARH'
  },
  {
    label: 'Baghpat',
    value: 'BAGHPAT'
  },
  {
    label: 'Bahraich',
    value: 'BAHRAICH'
  },
  {
    label: 'Ballia',
    value: 'BALLIA'
  },
  {
    label: 'Balrampur',
    value: 'BALRAMPUR'
  },
  {
    label: 'Banda',
    value: 'BANDA'
  },
  {
    label: 'Barabanki',
    value: 'BARABANKI'
  },
  {
    label: 'Bareilly',
    value: 'BAREILLY'
  },
  {
    label: 'Basti',
    value: 'BASTI'
  },
  {
    label: 'Bhadohi',
    value: 'BHADOHI'
  },
  {
    label: 'Bijnor',
    value: 'BIJNOR'
  },
  {
    label: 'Budaun',
    value: 'BUDAUN'
  },
  {
    label: 'Bulandshahr',
    value: 'BULANDSHAHR'
  },
  {
    label: 'Chandauli',
    value: 'CHANDAULI'
  },
  {
    label: 'Chitrakoot',
    value: 'CHITRAKOOT'
  },
  {
    label: 'Deoria',
    value: 'DEORIA'
  },
  {
    label: 'Etah',
    value: 'ETAH'
  },
  {
    label: 'Etawah',
    value: 'ETAWAH'
  },
  {
    label: 'Faizabad',
    value: 'FAIZABAD'
  },
  {
    label: 'Farrukhabad',
    value: 'FARRUKHABAD'
  },
  {
    label: 'Fatehpur',
    value: 'FATEHPUR'
  },
  {
    label: 'Firozabad',
    value: 'FIROZABAD'
  },
  {
    label: 'Gautam Buddha Nagar',
    value: 'GAUTAM_BUDDHA_NAGAR'
  },
  {
    label: 'Ghaziabad',
    value: 'GHAZIABAD'
  },
  {
    label: 'Ghazipur',
    value: 'GHAZIPUR'
  },
  {
    label: 'Gonda',
    value: 'GONDA'
  },
  {
    label: 'Gorakhpur',
    value: 'GORAKHPUR'
  },
  {
    label: 'Hamirpur',
    value: 'HAMIRPUR'
  },
  {
    label: 'Hapur (Panchsheel Nagar)',
    value: 'HAPUR'
  },
  {
    label: 'Hardoi',
    value: 'HARDOI'
  },
  {
    label: 'Hathras',
    value: 'HATHRAS'
  },
  {
    label: 'Jalaun',
    value: 'JALAUN'
  },
  {
    label: 'Jaunpur',
    value: 'JAUNPUR'
  },
  {
    label: 'Jhansi',
    value: 'JHANSI'
  },
  {
    label: 'Kannauj',
    value: 'KANNAUJ'
  },
  {
    label: 'Kanpur Dehat',
    value: 'KANPUR_DEHAT'
  },
  {
    label: 'Kanpur Nagar',
    value: 'KANPUR_NAGAR'
  },
  {
    label: 'Kanshiram Nagar (Kasganj)',
    value: 'KANSHIRAM_NAGAR'
  },
  {
    label: 'Kaushambi',
    value: 'KAUSHAMBI'
  },
  {
    label: 'Kushinagar (Padrauna)',
    value: 'KUSHINAGAR'
  },
  {
    label: 'Lakhimpur - Kheri',
    value: 'LAKHIMPUR'
  },
  {
    label: 'Lalitpur',
    value: 'LALITPUR'
  },
  {
    label: 'Lucknow',
    value: 'LUCKNOW'
  },
  {
    label: 'Maharajganj',
    value: 'MAHARAJGANJ'
  },
  {
    label: 'Mahoba',
    value: 'MAHOBA'
  },
  {
    label: 'Mainpuri',
    value: 'MAINPURI'
  },
  {
    label: 'Mathura',
    value: 'MATHURA'
  },
  {
    label: 'Mau',
    value: 'MAU'
  },
  {
    label: 'Meerut',
    value: 'MEERUT'
  },
  {
    label: 'Mirzapur',
    value: 'MIRZAPUR'
  },
  {
    label: 'Moradabad',
    value: 'MORADABAD'
  },
  {
    label: 'Muzaffarnagar',
    value: 'MUZAFFARNAGAR'
  },
  {
    label: 'Pilibhit',
    value: 'PILIBHIT'
  },
  {
    label: 'Pratapgarh',
    value: 'PRATAPGARH'
  },
  {
    label: 'RaeBareli',
    value: 'RAEBARELI'
  },
  {
    label: 'Rampur',
    value: 'RAMPUR'
  },
  {
    label: 'Saharanpur',
    value: 'SAHARANPUR'
  },
  {
    label: 'Sambhal (Bhim Nagar)',
    value: 'SAMBHAL'
  },
  {
    label: 'Sant Kabir Nagar',
    value: 'SANT_KABIR_NAGAR'
  },
  {
    label: 'Shahjahanpur',
    value: 'SHAHJAHANPUR'
  },
  {
    label: 'Shamali (Prabuddh Nagar)',
    value: 'SHAMALI'
  },
  {
    label: 'Shravasti',
    value: 'SHRAVASTI'
  },
  {
    label: 'Siddharth Nagar',
    value: 'SIDDHARTH_NAGAR'
  },
  {
    label: 'Sitapur',
    value: 'SITAPUR'
  },
  {
    label: 'Sonbhadra',
    value: 'SONBHADRA'
  },
  {
    label: 'Sultanpur',
    value: 'SULTANPUR'
  },
  {
    label: 'Unnao',
    value: 'UNNAO'
  },
  {
    label: 'Varanasi',
    value: 'VARANASI'
  }
];
