const aws = require('./aws/index');

const UPLOAD_FILE = {
  AWS: (fileInput, fileName) => aws.uploadFile(fileInput, fileName)
};

const GET_FILEURL = {
  AWS: fileName => aws.getFileUrl(fileName)
};

const GET_FILE = {
  AWS: key => aws.getFile(key)
};

const storage = 'AWS';

export const uploadFile = UPLOAD_FILE[storage];
export const getFileUrl = GET_FILEURL[storage];
export const getFile = GET_FILE[storage];
