import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { BLOODGROUP, FORMAT_DATE_UNIXTS } from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class HandRegistrationFormPdf {
  formData = {};

  constructor(formData) {
    const { handDetails, basicDetails, contactNos, contactEmailIds, hospitalName, ztccZone } =
      formData;

    const _yesNoAndEmptyFormatter = (value) => {
      if (value === 'true') {
        return 'YES';
      }
      if (value === 'false') {
        return 'NO';
      }
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };

    const _yesNoAndEmptyBooleanFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };
    const handTransplantFormatter = (value) => {
      if (value === 'WRISTANDDISTAL') {
        return 'Wrist and Distal to Wrist';
      }
      if (value === 'MIDFOREARMANDDISTAL') {
        return 'Mid-Forearm upto Wrist';
      }
      if (value === 'ELBOWANDDISTAL') {
        return 'Elbow upto Mid-Forearm';
      }
      if (value === 'MIDARMANDDISTAL') {
        return 'Mid-Arm upto Elbow';
      }
      if (value === 'SHOULDER') {
        return 'Shoulder Level upto Mid-Arm';
      }

      return '-';
    };
    const _getZtccZoneLabelById = (zone) => {
      if (zone === '7') {
        return 'ZTCC MUMBAI';
      }
      if (zone === '8') {
        return 'ZTCC PUNE';
      }
      if (zone === '9') {
        return 'ZTCC NAGPUR';
      }
      if (zone === '10') {
        return 'ZTCC AURANGABAD';
      }
      if (zone) {
        return zone;
      }

      return '';
    };

    const calculateCurrentAge = () => {
      if (basicDetails.dateOfBirth) {
        const duration = moment.duration(
          moment().diff(moment(basicDetails.dateOfBirth, FORMAT_DATE_UNIXTS))
        );
        return `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`;
      }
      if (basicDetails.currentAge) {
        return basicDetails.currentAge;
      }
      return '-';
    };

    const _nationalityFormatter = (value) => {
      if (value === 'INDIAN_NATIONAL') {
        return 'Indian';
      }
      if (value === 'OCI_CARDHOLDER') {
        return 'Overseas Citizen of India (OCI) card holder';
      }
      if (value === 'FOREIGN_NATIONAL') {
        return 'Foreign national';
      }
      return '-';
    };
    const _viralMrakerRemarkAndDateFormatter = (remark, date) => {
      // if (remark && date) {
      if (remark === 'NOTDONE') {
        return 'NOT DONE';
      }
      if (remark && date) {
        return `${remark} - ${date ? moment(date, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY') : '-'}`;
      }
      // }
      return '-';
    };
    this.formData = formData;
    const reportDocDefinition = {
      content: [
        {
          text: '',
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  fontSize: 12,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'State Appropriate Authority of Maharashtra',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: 'HAND REGISTRATION',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],

              [
                {
                  text: `ZTCC Zone: ${_getZtccZoneLabelById(ztccZone)}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date of Registration : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.dateOfRegistration
                      ? moment(basicDetails?.dateOfRegistration, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Name:`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.name ? basicDetails?.name : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `NOTTO ID : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.nottoId}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Birth: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.dateOfBirth
                      ? moment(basicDetails?.dateOfBirth, FORMAT_DATE_UNIXTS).format('DD MMMM YYYY')
                      : '-'
                  } `,

                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Age: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${calculateCurrentAge()}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Gender: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.sex ? basicDetails?.sex : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Group: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Weight (Kg): `,
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.weightKg}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in cm):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightCm}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in inches):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightIn ? basicDetails?.heightIn : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${hospitalName || '-'}`, colSpan: 2 },
                {},
              ],

              [
                {
                  text: `Address & Telephone No: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.presentAddress ? basicDetails?.presentAddress : '-'} - ${
                    contactNos.length > 0 ? contactNos[0] : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Patient contact emails:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${contactEmailIds.length > 0 ? contactEmailIds[0] : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Economic Status: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.economicStatus ? basicDetails?.economicStatus : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Occupation',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.occupation}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_nationalityFormatter(basicDetails?.nationality)}`,
                  colSpan: 2,
                },
                {},
              ],
              ...(basicDetails?.nationality !== 'INDIAN_NATIONAL'
                ? [
                    [
                      {
                        text: `Indian Contact Number : `,
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${
                          basicDetails?.indianContactNumber
                            ? basicDetails?.indianContactNumber
                            : '-'
                        }`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              [
                {
                  text: `Country of Residence: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.countryOfResidence ? basicDetails?.countryOfResidence : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              // [
              //   {
              //     text: 'Date of RT PCR',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${
              //       basicDetails.rtpcrdate
              //         ? moment(basicDetails.rtpcrdate, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
              //         : '-'
              //     }`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],
              // [
              //   {
              //     text: 'Is Covid Vaccination Done?',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${_covidVaccinationFormatter(basicDetails?.covidVaccination?.dosesDone)}`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],

              [
                {
                  text: 'UHID',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.uhid}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Present Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.presentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Permanent Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.permanentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Date of Admission / OPD',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.dateOfAdmission
                      ? moment(basicDetails?.dateOfAdmission, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Primary physician Name:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.primaryPhysicianName}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Primary physician contact numbers:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.primaryPhysicianContactNo
                      ? basicDetails?.primaryPhysicianContactNo
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary physician contact email:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.primaryPhysicianEmail ? basicDetails?.primaryPhysicianEmail : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary Questions Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient have active / metastatic malignancy?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMalignancy
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active MDR infection with sepsis or septic shock?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMdrInfection
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have severe irreversible systemic disease (cardiac, pulmonary, neurological or others with very short longevity)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noSevereIrreversibleSystemicDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active or recent major unresolved psychiatric illness?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noMajorUnresolvedPsychiatricIllness
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have high risk of non-compliance with long-term immunosuppression?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noHighRiskOfNonCompliance
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have adequate social or family support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(basicDetails?.prerequisites?.familySupport)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Identity Proof Document Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Document Type',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentType}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identify Proof Document Number',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentNumber}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identity Proof Document Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              // [
              //   {
              //     pageBreak: 'before',
              //     text: 'Family Member Details',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 3,
              //     bold: true,
              //   },

              //   {
              //     text: ``,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
            ],
          },
        },
        {
          text: 'Family Member Details',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Family Members Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Relation With Patient', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Number', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Email', style: 'tableHeader', alignment: 'left' },
              ],
              ...basicDetails.familyMembers.map((a) => [
                { text: `${a.name ? a.name : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.relation ? a.relation : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.contact ? a.contact : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.email ? a.email : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
          // layout: 'lightHorizontalLines',
        },
        {
          text: 'General Information',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'General Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'Is Patient Willing to Accept ECD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'ECD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Is Patient Willing to Accept DCD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'DCD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Has the patient been registered in Maharashtra in Past:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.pastZtccListingInfo?.pastZtccListing
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Status',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastListingStatus}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Further Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastZtccDetails}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'HAND FORM DETAILS ',
                  style: 'tableHeader',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient need a bilateral or unilateral hand transplant?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${handDetails?.handAmputationOptions?.amputation || '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              ...(handDetails?.handAmputationOptions?.amputation === 'UNILATERAL'
                ? [
                    [
                      {
                        text: 'Select Hand for transplant',
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${handDetails?.handAmputationOptions?.unliateralLeftOrRight || '-'}`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              ...(handDetails?.handAmputationOptions?.unliateralLeftOrRight === 'RIGHT' ||
              handDetails?.handAmputationOptions?.unliateralLeftOrRight === 'LEFT'
                ? [
                    [
                      {
                        text: 'Select Level (Unilateral)',
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${handTransplantFormatter(
                          handDetails?.handAmputationOptions?.unilateralLevelOptions || '-'
                        )}`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              ...(handDetails?.handAmputationOptions?.amputation === 'BILATERAL'
                ? [
                    [
                      {
                        text: 'Select Level for Right',
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${handTransplantFormatter(
                          handDetails?.handAmputationOptions?.bilateralRightLevel || '-'
                        )}`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              ...(handDetails?.handAmputationOptions?.amputation === 'BILATERAL'
                ? [
                    [
                      {
                        text: 'Select Level for Left',
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${handTransplantFormatter(
                          handDetails?.handAmputationOptions?.bilateralLeftLevel || '-'
                        )}`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              [
                {
                  text: 'Does the patient have the significant functional limitation despite using prosthesis',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    handDetails?.siginificantFunctionalLimitationDespiteUsingProsthesis
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Loss of hand or part of upper limb not suitable to any other surgical procedure for near complete hand function for activities of daily living.',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    handDetails?.lossOfHandOrPartOfUpperLimb
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have the congenital absence of limb (relative contraindication)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.congenitalAbsenceOfLimb)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have the severe cachexia: Nephropathy, Neuropathy etc. ?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.severeCachexia)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have any diabetes with end organ diseases?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    handDetails?.diabetesWithEndOrganDiseases
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have the severe cerebrovascular disease?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    handDetails?.severeCerebrovascularDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have the Malignancies with expected life span less than 10 yrs',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.malignancies)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have the severe primary pulmonary disease',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    handDetails?.severePrimaryPulmonaryDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Pulmonary hypertension PASP > 50 mmHg unresponsive to vasodilator challenge',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.pulmonaryHypertension)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have any past history of hematological events?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.hematologicalEvents)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Hematology Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.hematologyAttachment?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Pulmonary Fitness Certificate',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.pulmonaryCertificate?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Psychiatry Fitness Certificate',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.psychiatryCertificate?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Nephrology Fitness Certificate',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.nephrologyCertificate?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Cardiology Fitness Certificate',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.cardiologyCertificate?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'X-Ray Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.xRayAttachment?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Colour Dopplers Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.colourDopplersAttachment?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Angiography Attachment (NC)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.angiographyAttachment?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'EMG/MCV (NC)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.emgMcv?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Photograph of Amputated Limb',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.photoOfAmputatedLimb?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'MRI Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.mriAttachment?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Viral Marker Status ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `HBsAg`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMrakerRemarkAndDateFormatter(
                    handDetails?.viralMarkerStatus?.hbsagRemark,
                    handDetails?.viralMarkerStatus?.hbsagReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Anti HCV Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMrakerRemarkAndDateFormatter(
                    handDetails?.viralMarkerStatus?.antiHcvRemark,
                    handDetails?.viralMarkerStatus?.antiHcvReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMrakerRemarkAndDateFormatter(
                    handDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark,
                    handDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMrakerRemarkAndDateFormatter(
                    handDetails?.viralMarkerStatus?.hcvDnaRemark,
                    handDetails?.viralMarkerStatus?.hcvDnaReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HIV Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMrakerRemarkAndDateFormatter(
                    handDetails?.viralMarkerStatus?.hivRemark,
                    handDetails?.viralMarkerStatus?.hivReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Etiology', style: 'tableHeader', alignment: 'left' },
                { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...handDetails.etiologies.map((e) => [
                {
                  text: `${e.etiology ? e.etiology : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: ['auto', 'auto', 'auto'],
            body: [
              [
                {
                  text: 'Etiology Details ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handDetails?.etiologyDetails ? handDetails?.etiologyDetails : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },
        {
          text: 'Any Other Attachments',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...handDetails?.otherAttachments?.map((e) => [
                {
                  text: `${e.attachmentName ? e.attachmentName : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*'],
            body: [
              [{ text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' }],
              [
                (handDetails?.transplantSurgeon || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
              ],
            ],
          },
        },

        // {
        //   style: 'table',
        //   table: {
        //     widths: [250, 100, 150, 'auto', 'auto'],
        //     heights: [
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       20,
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //       'auto',
        //     ],
        //     body: [
        //       [
        //         {
        //           text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
        //           style: 'tableHeader',
        //           colSpan: 3,
        //           alignment: 'center',
        //           bold: true,
        //         },
        //         {},
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'State Appropriate Authority of Maharashtra',
        //           style: 'textStyle',
        //           alignment: 'center',
        //           colSpan: 3,
        //         },
        //         {},
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'HAND REGISTRATION',
        //           style: 'textStyle',
        //           alignment: 'center',
        //           colSpan: 3,
        //         },
        //         {},
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Date: ${moment().format('DD MMMM YYYY')}`,
        //           style: 'textStyle',
        //           alignment: 'left',
        //           colSpan: 3,
        //         },
        //         {
        //           text: `Registration Date:${moment(
        //             basicDetails?.dateOfRegistration,
        //             FORMAT_DATE_UNIXTS
        //           ).format('DD MMMM YYYY')} `,
        //           style: 'textStyle',
        //           alignment: 'left',
        //           colSpan: 3,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `ZTCC Zone: : ${_getZtccZoneLabelById(ztccZone)}`,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 3,
        //         },
        //         {},
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Name:${basicDetails?.name ? basicDetails?.name : '-'} `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 3,
        //         },
        //         {},
        //         {},
        //       ],

        //       [
        //         {
        //           text: `NOTTO ID : `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },
        //         { text: `${basicDetails?.nottoId ? basicDetails?.nottoId : '-'}`, colSpan: 2 },
        //         { text: '' },
        //         { text: '' },
        //       ],
        //       [
        //         {
        //           text: `Age:${basicDetails?.currentAge ? basicDetails?.currentAge : '-'} `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },
        //         {
        //           text: `Sex:${basicDetails?.sex ? basicDetails?.sex : '-'} `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Weight (kg):${basicDetails?.weightKg ? basicDetails?.weightKg : '-'} `,
        //           colSpan: 1,
        //         },

        //         {
        //           text: `Height (cm):  ${basicDetails?.heightCm ? basicDetails?.heightCm : '-'}`,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 2,
        //         },
        //         {},
        //       ],

        //       [
        //         {
        //           text: `Address & Telephone No: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${basicDetails?.presentAddress ? basicDetails?.presentAddress : '-'} - ${
        //             contactNos.length > 0 ? contactNos[0] : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Date of Birth: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             basicDetails?.dateOfBirth
        //               ? moment(basicDetails?.dateOfBirth, FORMAT_DATE_UNIXTS).format('DD MMMM YYYY')
        //               : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Nationality: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${basicDetails?.nationality ? basicDetails?.nationality : '-'}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Hospital Name: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         { text: `${hospitalName || '-'}`, colSpan: 2 },
        //         {},
        //       ],

        //       [
        //         {
        //           text: `Economic Status: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${basicDetails?.economicStatus ? basicDetails?.economicStatus : '-'}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Occupation: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${basicDetails?.occupation ? basicDetails?.occupation : '-'}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Present Address: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${basicDetails?.presentAddress ? basicDetails?.presentAddress : '-'}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Permanent Address: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${basicDetails?.permanentAddress ? basicDetails?.permanentAddress : '-'}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Indian Contact Number: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${basicDetails?.indianContactNo ? basicDetails?.indianContactNo : '-'}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Date of Admission: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             basicDetails?.dateOfAdmission
        //               ? moment(basicDetails?.dateOfAdmission, FORMAT_DATE_UNIXTS).format(
        //                   'DD MMMM YYYY'
        //                 )
        //               : '-'
        //           }`,

        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `UHID: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${basicDetails?.uhid ? basicDetails?.uhid : '-'}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Primary Physician Name: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             basicDetails?.primaryPhysicianName ? basicDetails?.primaryPhysicianName : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],

        //       [
        //         {
        //           text: `Primary Physician Contact Number: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             basicDetails?.primaryPhysicianContactNo
        //               ? basicDetails?.primaryPhysicianContactNo
        //               : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Primary Physician Contact Email: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             basicDetails?.primaryPhysicianEmail ? basicDetails?.primaryPhysicianEmail : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Etiology: \n (Diagnosis)',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         // { text: `${handDetails?.etiologies[0].etiology ? handDetails?.etiologies[0].etiology : '-'}`, colSpan: 2 },

        //         handDetails.etiologies.map((a) => [
        //           {
        //             text: `${a.etiology ? a.etiology : '-'}`,
        //             colSpan: 2,
        //           },
        //         ]),
        //         {},
        //       ],

        //       [
        //         {
        //           text: `Blood Group: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Patient's Contact Numbers: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${contactNos.length > 0 ? contactNos[0] : '-'}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Patient's Contact Emails: `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: ``,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Family Members :',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },
        //         basicDetails.familyMembers.map((a) => [
        //           { text: `Name :` + `${a.name ? a.name : '-'}` },
        //           { text: `Relation with patient :` + `${a.relation ? a.relation : '-'}` },
        //           { text: `Contact :` + `${a.contact ? a.contact : '-'}` },
        //           { text: `Email :` + `${a.email ? a.email : '-'}` },
        //         ]),
        //         {},
        //       ],

        //       [
        //         {
        //           text: 'Does the patient have active / metastatic malignancy?',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             basicDetails?.prerequisites?.noActiveMalignancy
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Does the patient have active MDR infection with sepsis or septic shock?',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             basicDetails?.prerequisites?.noActiveMdrInfection
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Does the patient have severe irreversible systemic disease (cardiac, pulmonary, neurological or others with very short longevity)?',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             basicDetails?.prerequisites?.noSevereIrreversibleSystemicDisease
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Does the patient have active or recent major unresolved psychiatric illness?',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             basicDetails?.prerequisites?.noMajorUnresolvedPsychiatricIllness
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Does the patient have high risk of non-compliance with long-term immunosuppression?',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             basicDetails?.prerequisites?.noHighRiskOfNonCompliance
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],

        //       [
        //         {
        //           text: 'Does the patient have adequate social or family support?',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             basicDetails?.prerequisites?.familySupport
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Identity Proof Document Details : `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 3,
        //         },
        //         {},
        //         {},
        //       ],
        //       [
        //         {
        //           text: `Document Type: ${
        //             basicDetails?.identityProof?.documentType
        //               ? basicDetails?.identityProof?.documentType
        //               : '-'
        //           }`,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },
        //         {
        //           text: `Identity Proof Document Number: ${
        //             basicDetails?.identityProof?.documentNumber
        //               ? basicDetails?.identityProof?.documentNumber
        //               : '-'
        //           }`,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `HBs AG Report Date \n (Positive/Negative) `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             handDetails?.viralMarkerStatus?.hbsagRemark
        //               ? handDetails?.viralMarkerStatus?.hbsagRemark
        //               : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],

        //       [
        //         {
        //           text: `Anti HCV Report Date \n (Positive/Negative) `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             handDetails?.viralMarkerStatus?.antiHcvRemark
        //               ? handDetails.viralMarkerStatus?.antiHcvRemark
        //               : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `HCV RNA Quantitative Report Date \n (Positive/Negative) `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             handDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark
        //               ? handDetails?.viralMarkerStatus.hcvRnaQuantitativeRemark
        //               : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             handDetails?.viralMarkerStatus?.hcvDnaRemark
        //               ? handDetails?.viralMarkerStatus.hcvDnaRemark
        //               : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: `HIV Report Date \n (Positive/Negative) `,
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${
        //             handDetails?.viralMarkerStatus?.hivRemark
        //               ? handDetails?.viralMarkerStatus?.hivRemark
        //               : '-'
        //           }`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],

        //       [
        //         {
        //           text: 'Does the patient have with amputated upper limb unilateral or bilateral at levels distal to mid- arm',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.amputatedUpperLimb)}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Does the patient have the significant functional limitation despite using prosthesis',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             handDetails?.siginificantFunctionalLimitationDespiteUsingProsthesis
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Loss of hand or part of upper limb not suitable to any other surgical procedure for near complete hand function for activities of daily living.',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             handDetails?.lossOfHandOrPartOfUpperLimb
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Does the pattient have the congenital absence of limb (relative contraindication)?',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.congenitalAbsenceOfLimb)}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Does the patient have the severe cachexia: Nephropathy, Neuropathy etc. ?',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.severeCachexia)}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Does the patient have any diabetes with end organ diseases?',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             handDetails?.diabetesWithEndOrganDiseases
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       // [
        //       //   {
        //       //     text: 'Baseline GFR (mL/min)',
        //       //     style: 'tableHeader',
        //       //     alignment: 'left',
        //       //     colSpan: 1,
        //       //   },

        //       //   {
        //       //     text: `${handDetails?.baselineGfr ? handDetails?.baselineGfr : '-'}`,
        //       //     colSpan: 2,
        //       //   },
        //       //   {},
        //       // ],
        //       [
        //         {
        //           text: 'Does the patient have the severe cerebrovascular disease?',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             handDetails?.severeCerebrovascularDisease
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //       [
        //         {
        //           text: 'Does the patient have the Malignancies with expected life span < 10 yrs',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.malignancies)}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],

        //       // [
        //       //   {
        //       //     text: 'BMI (Kg/m^2)',
        //       //     style: 'tableHeader',
        //       //     alignment: 'left',
        //       //     colSpan: 1,
        //       //   },

        //       //   { text: `${handDetails?.bmi ? handDetails?.bmi : '-'}`, colSpan: 2 },
        //       //   {},
        //       // ],

        //       [
        //         {
        //           text: 'Does the patient have the severe primary pulmonary disease',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(
        //             handDetails?.severePrimaryPulmonaryDisease
        //           )}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],

        //       [
        //         {
        //           text: 'Pulmonary hypertension PASP > 50 mmHg unresponsive to vasodilator challenge',
        //           style: 'tableHeader',
        //           alignment: 'left',
        //           colSpan: 1,
        //         },

        //         {
        //           text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.pulmonaryHypertension)}`,
        //           colSpan: 2,
        //         },
        //         {},
        //       ],
        //     ],
        //   },
        // },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto'],
            heights: [0, 50, 50, 50, 50, 50],
            body: [
              [
                {
                  text: 'All verification of the clinical details / reports / criteria eligibility to justify above criteria is the responsibility of the transplant team signing this form. \n1. All the reports (Lab reports + imaging report) should be within 1 month at the time of registration. The patients with older reports should not be considered for registration.\n2. All lab reports should be within 1 month and imaging should be within 3 months for allocation, patients with older reports should not be considered for allocation.',
                  style: 'tableHeader',
                  colSpan: 3,
                  // alignment: 'center',
                  // bold: true,
                  fontSize: 11,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Patient Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Surgeon Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Physician Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Transplant Coordinator Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Signature of Hospital Head:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Hand Registration Form`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default HandRegistrationFormPdf;
