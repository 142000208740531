import { Auth } from 'aws-amplify';
// import MemoryStorage from './authStorage';

const awsExports = {
  Auth: {
    identityPoolId: 'ap-south-1:2f0bfe69-b21c-42a9-b07f-fcd4dc89aad2',
    region: 'ap-south-1',
    userPoolId: 'ap-south-1_lWrdFcprg',
    userPoolWebClientId: '36gabq1so8dptbp1sa253gfba6',
    // storage: MemoryStorage
  },
  Storage: {
    AWSS3: {
      bucket: 'ayudaan-uploads-dev',
      region: 'ap-south-1',
    },
  },
  API: {
    endpoints: [
      {
        name: 'AyudaanExpress',
        endpoint: 'https://localhost:10443',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
      {
        name: 'AyudaanCore',
        endpoint: 'https://localhost:3000',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
      {
        name: 'AdnOrgan',
        endpoint: 'https://localhost:3001',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
      {
        name: 'AdnEyeBank',
        endpoint: 'https://localhost:3002',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
      {
        name: 'AdnTissue',
        endpoint: 'https://localhost:3003',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
      {
        name: 'AdnResources',
        endpoint: 'https://localhost:3004',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
    ],
  },
};

export default awsExports;
