export const DISTRICTS_PB = [
  {
    label: 'Amritsar',
    value: 'AMRITSAR'
  },
  {
    label: 'Barnala',
    value: 'BARNALA'
  },
  {
    label: 'Bathinda',
    value: 'BATHINDA'
  },
  {
    label: 'Faridkot',
    value: 'FARIDKOT'
  },
  {
    label: 'Fatehgarh Sahib',
    value: 'FATEHGARH_SAHIB'
  },
  {
    label: 'Fazilka',
    value: 'FAZILKA'
  },
  {
    label: 'Ferozepur',
    value: 'FEROZEPUR'
  },
  {
    label: 'Gurdaspur',
    value: 'GURDASPUR'
  },
  {
    label: 'Hoshiarpur',
    value: 'HASHIARPUR'
  },
  {
    label: 'Jalandhar',
    value: 'JALANDHAR'
  },
  {
    label: 'Kapurthala',
    value: 'KAPURTHALA'
  },
  {
    label: 'Ludhiana',
    value: 'LUDHIANA'
  },
  {
    label: 'Mansa',
    value: 'MANSA'
  },
  {
    label: 'Moga',
    value: 'MOGA'
  },
  {
    label: 'Muktsar',
    value: 'MUKTSAR'
  },
  {
    label: 'Nawanshahr (Shahid Bhagat Singh Nagar)',
    value: 'NAWANSHAHR'
  },
  {
    label: 'Pathankot',
    value: 'PATHANKOT'
  },
  {
    label: 'Patiala',
    value: 'PATIALA'
  },
  {
    label: 'Rupnagar',
    value: 'RUPNAGAR'
  },
  {
    label: 'Sahibzada Ajit Singh Nagar (Mohali)',
    value: 'MOHOLI'
  },
  {
    label: 'Sangrur',
    value: 'SANGRUR'
  },
  {
    label: 'Tarn Taran',
    value: 'TARN_TARAN'
  }
];
