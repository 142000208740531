import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import get from 'lodash.get';
import { ValidatedAttachment, ValidatedDate, ValidatedInput, ValidatedSelect } from '../Custom';
import { FILETYPES } from '../Custom/ValidatedAttachment';

export const FIELDTYPES = {
  SELECT: 'select',
  RADIO: 'radio',
  DATE: 'date',
  NUMBER: 'number',
  TEXTAREA: 'textarea',
  TEXT: 'text',
  ATTACHMENT: 'attachment',
  DATETIME: 'dateTime',
  BUTTON: 'button',
};

export default function FieldsToInput({
  fieldGridProps = {},
  fields = {},
  disabled,
  state,
  onChange,
  validation,
}) {
  function fieldsToInput([fieldName, field = {}], idx) {
    let fieldProps = field.props || {};
    if (validation) {
      fieldProps = { ...fieldProps, validation };
    }
    if (field.type === FIELDTYPES.SELECT && !field.hidden) {
      return (
        <>
          {field?.textLabel && (
            <Typography variant="body1">
              {field?.textLabel}
              {!field?.isNotRequired ? <span style={{ color: 'red' }}>*</span> : ''}
            </Typography>
          )}
          <ValidatedSelect
            required
            fullWidth
            label={field.label || ''}
            value={get(state, fieldName)}
            options={field.options || []}
            onChange={(e) => onChange(fieldName, e.value)}
            disabled={field.disabled || disabled}
            key={idx}
            {...fieldProps}
          />
        </>
      );
    }
    if (field.type === FIELDTYPES.RADIO && !field.hidden) {
      return (
        <>
          {field?.textLabel && (
            <Typography variant="body1">
              {field?.textLabel}
              {!field?.isNotRequired ? <span style={{ color: 'red' }}>*</span> : ''}
            </Typography>
          )}
          <RadioGroup
            row={!field.column}
            aria-label={field.textLabel}
            name={fieldName}
            value={get(state, fieldName)}
            onChange={(e) => onChange(fieldName, e.target.value)}
          >
            {(field.options || []).map((o) => (
              <FormControlLabel
                value={`${o.value}`}
                control={<Radio />}
                label={o.label}
                disabled={field.disabled || disabled}
              />
            ))}
          </RadioGroup>
        </>
      );
    }
    if (field.type === FIELDTYPES.DATE && !field.hidden) {
      return (
        <>
          {field?.textLabel && (
            <Typography variant="body1">
              {field?.textLabel}
              {!field?.isNotRequired ? <span style={{ color: 'red' }}>*</span> : ''}
            </Typography>
          )}
          <ValidatedDate
            required
            fullWidth
            label={field.label || ''}
            value={get(state, fieldName)}
            onChange={(v) => onChange(fieldName, v)}
            disabled={field.disabled || disabled}
            key={idx}
            {...fieldProps}
          />
        </>
      );
    }
    if (field.type === FIELDTYPES.DATETIME && !field.hidden) {
      return (
        <>
          {field?.textLabel && (
            <Typography variant="body1">
              {field?.textLabel}
              {!field?.isNotRequired ? <span style={{ color: 'red' }}>*</span> : ''}
            </Typography>
          )}
          <ValidatedDate
            required
            fullWidth
            label={field.label || ''}
            value={get(state, fieldName)}
            onChange={(v) => onChange(fieldName, v)}
            disabled={field.disabled || disabled}
            key={idx}
            type="datetime-local"
            {...fieldProps}
          />
        </>
      );
    }
    if (field.type === FIELDTYPES.ATTACHMENT && !field.hidden) {
      return (
        <>
          <ValidatedAttachment
            required={!field?.isNotRequired}
            label={field?.textLabel || 'Attachment'}
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={get(state, fieldName) || {}}
            disabled={field.disabled || disabled}
            onChange={(e) =>
              onChange(fieldName, {
                ...(get(state, fieldName) || {}),
                name: e.target.files[0]?.name,
                file: e.target.files[0],
              })
            }
            {...fieldProps}
          />
        </>
      );
    }
    if (!field.hidden) {
      return (
        <>
          {field?.textLabel && (
            <>
              <Typography variant="body1">
                {field?.textLabel}
                {!field?.isNotRequired ? <span style={{ color: 'red' }}>*</span> : ''}
              </Typography>
            </>
          )}
          <ValidatedInput
            required
            fullWidth
            value={get(state, fieldName)}
            label={field.label || ''}
            onChange={(e) => onChange(fieldName, e.target.value)}
            disabled={field.disabled || disabled}
            key={idx}
            type={field.type}
            placeholder=""
            {...fieldProps}
          />
        </>
      );
    }
    return null;
  }
  const fieldsAsArray = Object.entries(fields);

  return (
    <>
      {fieldsAsArray.map(([fieldName, field], idx) => (
        <Grid item sm={4} xs={12} {...fieldGridProps} {...(field.gridProps || {})} key={idx}>
          {fieldsToInput([fieldName, field], idx)}
        </Grid>
      ))}
    </>
  );
}
FieldsToInput.propTypes = {
  fieldGridProps: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  state: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
};
FieldsToInput.defaultProps = {
  fieldGridProps: {},
  fields: {},
  disabled: false,
  state: {},
  onChange: () => 'onChange STUB',
};
