import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Row, Col, Button, Input, InputGroup,
  // InputGroupAddon 
} from 'reactstrap';
import { Table } from 'antd';
//import '../../../../../scss/antd.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { getHospitalsList } from './donorHospitalPickerScripts';
import { withCancellableResolver } from '../../../../../hoc';

class RecipientHospitalPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitals: [],
      selectedHospital: ''
    };
  }

  _handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  _handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  _getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <InputGroup>
          <Input
            innerRef={node => {
              this.searchInput = node;
            }}
            bsSize="sm"
            placeholder="Search"
            value={selectedKeys[0] || ''}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onKeyDown={e => {
              if (e.which === 13 || e.keyCode === 13) {
                this._handleSearch(selectedKeys, confirm);
              }
            }}
          />
          <div className="input-group-append">
            <Button
              type="button"
              size="sm"
              color="danger"
              onClick={() => this._handleReset(clearFilters)}
            >
              <i className="fa fa-times" />
            </Button>
          </div>
        </InputGroup>
      </div>
    ),
    filterIcon: filtered => (
      <div className="d-flex justify-content-center align-items-center">
        <i className="fa fa-search fa-sm" style={{ color: filtered ? '#1890ff' : undefined }} />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.focus());
      }
    },
    render: text => {
      const { searchText } = this.state;
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={(text || '').toString()}
        />
      );
    }
  });

  _selectHospitalFormatter(hospital) {
    const { selectedHospital } = this.state;
    const { selectHospital } = this.props;
    return selectedHospital && selectedHospital === hospital.hospitalId ? (
      <Button
        onClick={() => {
          this.setState({ selectedHospital: '' });
          selectHospital({});
        }}
        color="danger"
      >
        Remove
      </Button>
    ) : (
      <Button
        onClick={() => {
          this.setState({ selectedHospital: hospital.hospitalId });
          selectHospital(hospital);
        }}
        color="primary"
      >
        Select
      </Button>
    );
  }

  hospitalsColumns = [
    {
      title: 'Hospital Name',
      dataIndex: 'name',
      key: 'name',
      ...this._getColumnSearchProps('name')
    },
    {
      title: 'HospitalId',
      dataIndex: 'hospitalId',
      key: 'hospitalId'
    },
    {
      title: 'Select',
      dataIndex: 'hospitalId',
      key: 'selectUser',
      render: (hospitalId, hospital) => this._selectHospitalFormatter(hospital)
    }
  ];

  render() {
    const { hospitals } = this.state;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col lg="12">
            <Table
              rowKey="_id"
              columns={this.hospitalsColumns}
              dataSource={hospitals}
              bordered
              size="middle"
            />
          </Col>
        </Row>
      </div>
    );
  }

  _getHospitalsList() {
    const { resolveWithCancellable, userType, hospitalId } = this.props;
    resolveWithCancellable(getHospitalsList(hospitalId, userType))
      .then(hospitals => {
        this.setState({ hospitals });
      })
      .catch(() => { });
  }

  componentDidMount() {
    const { selectedHospital } = this.props;

    this.setState({ selectedHospital });
    this._getHospitalsList();
  }
}
RecipientHospitalPicker.propTypes = {
  resolveWithCancellable: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  hospitalId: PropTypes.string.isRequired,
  selectedHospital: PropTypes.string,
  // this is required, but used only in the js file
  // eslint-disable-next-line react/no-unused-prop-types
  selectHospital: PropTypes.func.isRequired
};
RecipientHospitalPicker.defaultProps = {
  selectedHospital: undefined
};

const mapStateToProps = state => ({
  userType: state.user.userType,
  hospitalId: state.hospital.hospitalId,
  isDemo: state.isDemo
});

export default compose(
  withRouter,
  withCancellableResolver,
  connect(mapStateToProps)
)(RecipientHospitalPicker);
