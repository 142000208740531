export const DISTRICTS_MP = [
  {
    label: 'Agar Malwa',
    value: 'AGAR_MALWA'
  },
  {
    label: 'Alirajpur',
    value: 'ALIRAJPUR'
  },
  {
    label: 'Anuppur',
    value: 'ANUPPUR'
  },
  {
    label: 'Ashoknagar',
    value: 'ASHOKNAGAR'
  },
  {
    label: 'Balaghat',
    value: 'BALAGHAT'
  },
  {
    label: 'Barwani',
    value: 'BARWANI'
  },
  {
    label: 'Betul',
    value: 'BETUL'
  },
  {
    label: 'Bhind',
    value: 'BHIND'
  },
  {
    label: 'Bhopal',
    value: 'BHOPAL'
  },
  {
    label: 'Burhanpur',
    value: 'BURHANPUR'
  },
  {
    label: 'Chhatarpur',
    value: 'CHHATARPUR'
  },
  {
    label: 'Chhindwara',
    value: 'CHHINDWARA'
  },
  {
    label: 'Damoh',
    value: 'DAMOH'
  },
  {
    label: 'Datia',
    value: 'DATIA'
  },
  {
    label: 'Dewas',
    value: 'DEWAS'
  },
  {
    label: 'Dhar',
    value: 'DHAR'
  },
  {
    label: 'Dindori',
    value: 'DINDORI'
  },
  {
    label: 'Guna',
    value: 'GUNA'
  },
  {
    label: 'Gwalior',
    value: 'GWALIOR'
  },
  {
    label: 'Harda',
    value: 'HARDA'
  },
  {
    label: 'Hoshangabad',
    value: 'HOSHANGABAD'
  },
  {
    label: 'Indore',
    value: 'INDORE'
  },
  {
    label: 'Jabalpur',
    value: 'JABALPUR'
  },
  {
    label: 'Jhabua',
    value: 'JHABUA'
  },
  {
    label: 'Katni',
    value: 'KATNI'
  },
  {
    label: 'Khandwa',
    value: 'KHANDWA'
  },
  {
    label: 'Khargone',
    value: 'KHARGONE'
  },
  {
    label: 'Mandla',
    value: 'MANDLA'
  },
  {
    label: 'Mandsaur',
    value: 'MANDSAUR'
  },
  {
    label: 'Morena',
    value: 'MORENA'
  },
  {
    label: 'Narsinghpur',
    value: 'NARSINGHPUR'
  },
  {
    label: 'Neemuch',
    value: 'NEEMUCH'
  },
  {
    label: 'Panna',
    value: 'PANNA'
  },
  {
    label: 'Raisen',
    value: 'RAISEN'
  },
  {
    label: 'Rajgarh',
    value: 'RAJGARH'
  },
  {
    label: 'Ratlam',
    value: 'PATLAM'
  },
  {
    label: 'Rewa',
    value: 'REWA'
  },
  {
    label: 'Sagar',
    value: 'SAGAR'
  },
  {
    label: 'Satna',
    value: 'SATNA'
  },
  {
    label: 'Sehore',
    value: 'SEHORE'
  },
  {
    label: 'Seoni',
    value: 'SEONI'
  },
  {
    label: 'Shahdol',
    value: 'SHAHDOL'
  },
  {
    label: 'Shajapur',
    value: 'SHAJAPUR'
  },
  {
    label: 'Sheopur',
    value: 'SHEOPUR'
  },
  {
    label: 'Shivpuri',
    value: 'SHIVPURI'
  },
  {
    label: 'Sidhi',
    value: 'SIDHI'
  },
  {
    label: 'Singrauli',
    value: 'SINGRAULI'
  },
  {
    label: 'Tikamgarh',
    value: 'TIKAMGARH'
  },
  {
    label: 'Ujjain',
    value: 'UJJAIN'
  },
  {
    label: 'Umaria',
    value: 'UMARIA'
  },
  {
    label: 'Vidisha',
    value: 'VIDISHA'
  }
];
