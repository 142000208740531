import set from 'lodash.set';
import moment from 'moment';
import { FORMAT_DATE_UNIXTS } from '../../constants';

const _strToBool = (strVal) => {
  return String(strVal).toLowerCase() === 'true';
};

const _getLabel = (routineFormDetails) => {
  if (
    routineFormDetails?.childTurcottePughScore?.childTurcottePughScore === 5 ||
    routineFormDetails?.childTurcottePughScore?.childTurcottePughScore === 6
  ) {
    return 'A';
  }
  if (
    routineFormDetails?.childTurcottePughScore?.childTurcottePughScore === 7 ||
    routineFormDetails?.childTurcottePughScore?.childTurcottePughScore === 8 ||
    routineFormDetails?.childTurcottePughScore?.childTurcottePughScore === 9
  ) {
    return 'B';
  }
  if (
    routineFormDetails?.childTurcottePughScore?.childTurcottePughScore >= 10 &&
    routineFormDetails?.childTurcottePughScore?.childTurcottePughScore <= 15
  ) {
    return 'C';
  }
  // if (
  //   routineFormDetails?.childTurcottePughScore?.childTurcottePughScore < 5 ||
  //   routineFormDetails?.childTurcottePughScore?.childTurcottePughScore > 15
  // ) {
  //   return 'Enter Value Between 5-15';
  // }
  return '';
};

export default (
  mlasDetails,
  fieldName,
  value,
  recipientDemographicDetails,
  meldExcepOptions,
  compliactionScoreOptions,
  setMlasDetailsFields,
  routineForm
) => {
  // if (recipientDemographicDetails?.dateOfBirth) {
  //   const duration = moment.duration(
  //     moment().diff(moment(recipientDemographicDetails?.dateOfBirth, FORMAT_DATE_UNIXTS))
  //   );
  // }

  // Done
  if (
    fieldName === 'mlas.listingBilirubin' ||
    fieldName === 'mlas.listingInr' ||
    fieldName === 'mlas.listingAlbumin'
  ) {
    set(mlasDetails, fieldName, value);
    let totalPoints = 0;
    if (routineForm?.ascites?.ascites) {
      if (routineForm?.ascites?.ascites === 'NONE') {
        totalPoints += 1;
      }
      if (routineForm?.ascites?.ascites === 'MILD') {
        totalPoints += 2;
      }
      if (routineForm?.ascites?.ascites === 'MODTOSEVERE') {
        totalPoints += 3;
      }
    }

    if (routineForm?.encephalopathy?.encephalopathy) {
      if (routineForm?.encephalopathy?.encephalopathy === 'NONE') {
        totalPoints += 1;
      }
      if (routineForm?.encephalopathy?.encephalopathy === 'GRADEONETWO') {
        totalPoints += 2;
      }
      if (routineForm?.encephalopathy?.encephalopathy === 'GRADETHREEFOUR') {
        totalPoints += 3;
      }
    }

    if (mlasDetails?.mlas?.listingBilirubin) {
      if (mlasDetails?.mlas?.listingBilirubin < 2) {
        totalPoints += 1;
      }
      if (mlasDetails?.mlas?.listingBilirubin >= 2 && mlasDetails?.mlas?.listingBilirubin <= 3) {
        totalPoints += 2;
      }
      if (mlasDetails?.mlas?.listingBilirubin > 3) {
        totalPoints += 3;
      }
    }

    if (mlasDetails?.mlas?.listingInr) {
      if (mlasDetails?.mlas?.listingInr < 1.7) {
        totalPoints += 1;
      }
      if (mlasDetails?.mlas?.listingInr >= 1.7 && mlasDetails?.mlas?.listingInr <= 2.3) {
        totalPoints += 2;
      }
      if (mlasDetails?.mlas?.listingInr > 2.3) {
        totalPoints += 3;
      }
    }

    if (mlasDetails?.mlas?.listingAlbumin) {
      if (mlasDetails?.mlas?.listingAlbumin > 3.5) {
        totalPoints += 1;
      }
      if (mlasDetails?.mlas?.listingAlbumin >= 2.8 && mlasDetails?.mlas?.listingAlbumin <= 3.5) {
        totalPoints += 2;
      }
      if (mlasDetails?.mlas?.listingAlbumin < 2.8) {
        totalPoints += 3;
      }
    }

    set(routineForm, 'childTurcottePughScore.childTurcottePughScore', totalPoints);
    set(routineForm, 'childTurcottePughScore.childTurcottePughClass', _getLabel(routineForm));
  }
  if (fieldName === 'mlas.dateOfBirth' && value) {
    const duration = moment.duration(moment().diff(moment(value, FORMAT_DATE_UNIXTS)));
    set(
      mlasDetails,
      'mlas.currentAge',
      `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`
    );
  }

  if (fieldName === 'mlas.dateOfListing' && value) {
    const duration = moment.duration(
      moment(value, FORMAT_DATE_UNIXTS).diff(
        moment(mlasDetails?.mlas?.dateOfBirth, FORMAT_DATE_UNIXTS)
      )
    );
    set(
      mlasDetails,
      'mlas.listingAge',
      `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`
    );
  }

  if (fieldName === 'mlas.lastUpdateDate' && value) {
    const duration = moment.duration(moment().diff(moment(value, FORMAT_DATE_UNIXTS)));
    set(
      mlasDetails,
      'mlas.durationFromUpdate',
      `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`
    );
  }

  // Done
  if (fieldName === 'complicationScore.sbp.sbpAnc' && value) {
    set(mlasDetails, 'complicationScore.sbp.spbPointsEligible', value > 250 ? 'YES' : 'NO');
    if (value > 250) {
      set(mlasDetails, 'complicationScore.sbp.spbPoints', 2);
    } else {
      set(mlasDetails, 'complicationScore.sbp.spbPoints', 0);
    }
  }

  // Done
  if (
    (fieldName ===
      'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxModToSevereYesNo' ||
      fieldName ===
        'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxTherapeuticTaps' ||
      fieldName === 'complicationScore.hepaticHydrothoraxModToSevere.refractoryAscites' ||
      fieldName === 'complicationScore.hepaticHydrothoraxModToSevere.refractoryAscitesDiuretic' ||
      fieldName ===
        'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxModerateOnHrctChestXrayUsgThorax' ||
      fieldName ===
        'complicationScore.hepaticHydrothoraxModToSevere.refractoryAscitesNoradrenaline') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      (mlasDetails.complicationScore.hepaticHydrothoraxModToSevere
        .hepaticHydrothoraxModerateOnHrctChestXrayUsgThorax ===
        'true') |
        (mlasDetails.complicationScore.hepaticHydrothoraxModToSevere
          .hepaticHydrothoraxTherapeuticTaps ===
          'true') ||
      mlasDetails.complicationScore.hepaticHydrothoraxModToSevere.refractoryAscites === 'true' ||
      mlasDetails.complicationScore.hepaticHydrothoraxModToSevere.refractoryAscitesDiuretic ===
        'true' ||
      mlasDetails.complicationScore.hepaticHydrothoraxModToSevere.refractoryAscitesNoradrenaline ===
        'true'
    ) {
      set(
        mlasDetails,
        'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxEligible',
        'YES'
      );
    } else {
      set(
        mlasDetails,
        'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxEligible',
        'NO'
      );
    }
    if (
      mlasDetails.complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxEligible ===
      'YES'
    ) {
      set(
        mlasDetails,
        'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxPoints',
        2
      );
    } else if (
      mlasDetails.complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxEligible ===
      'NO'
    ) {
      set(
        mlasDetails,
        'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxPoints',
        0
      );
    }
  }

  // Done
  if (
    (fieldName === 'complicationScore.heNeedingHIcu.heNeedingGreaterThanEquals3' ||
      fieldName === 'complicationScore.heNeedingHIcu.structuralNeurologicalDisease') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      mlasDetails.complicationScore.heNeedingHIcu.heNeedingGreaterThanEquals3 === 'true' &&
      mlasDetails.complicationScore.heNeedingHIcu.structuralNeurologicalDisease === 'false'
    ) {
      set(mlasDetails, 'complicationScore.heNeedingHIcu.hePointsEligible', 'YES');
      set(mlasDetails, 'complicationScore.heNeedingHIcu.hePoints', 2);
    } else {
      set(mlasDetails, 'complicationScore.heNeedingHIcu.hePointsEligible', 'NO');
      set(mlasDetails, 'complicationScore.heNeedingHIcu.hePoints', 0);
    }
  }

  // Done
  if (compliactionScoreOptions?.varicealBleedNoEndoYesNo) {
    set(
      mlasDetails,
      'complicationScore.varicealBleedNoEndo.recurrentVaricealEligible',
      _strToBool(value) ? 'YES' : 'NO'
    );
    if (_strToBool(value)) {
      set(mlasDetails, 'complicationScore.varicealBleedNoEndo.recurrentVaricealPoints', 2);
    } else {
      set(mlasDetails, 'complicationScore.varicealBleedNoEndo.recurrentVaricealPoints', 0);
    }
  }

  if (fieldName === 'mlas.dateOfListing' && value) {
    const duration = moment.duration(moment().diff(moment(value, FORMAT_DATE_UNIXTS)));
    set(
      mlasDetails,
      'mlas.waitingTime',
      `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`
    );
    if (duration?._data?.years > 0) {
      set(mlasDetails, 'mlas.waitingTimeCategory', '>12');
      set(mlasDetails, 'mlas.eligibleForWaitingTimePoints', 'YES');
      set(mlasDetails, 'mlas.waitingTimePoints', 8);
    } else if (duration?._data?.months >= 3 && duration?._data?.months < 6) {
      set(mlasDetails, 'mlas.waitingTimeCategory', '3-6');
      set(mlasDetails, 'mlas.eligibleForWaitingTimePoints', 'YES');
      set(mlasDetails, 'mlas.waitingTimePoints', 6);
    } else if (duration?._data?.months >= 6 && duration?._data?.months < 9) {
      set(mlasDetails, 'mlas.waitingTimeCategory', '6-9');
      set(mlasDetails, 'mlas.eligibleForWaitingTimePoints', 'YES');
      set(mlasDetails, 'mlas.waitingTimePoints', 4);
    } else if (duration?._data?.months >= 9 && duration?._data?.months < 12) {
      set(mlasDetails, 'mlas.waitingTimeCategory', '9-12');
      set(mlasDetails, 'mlas.eligibleForWaitingTimePoints', 'YES');
      set(mlasDetails, 'mlas.waitingTimePoints', 2);
    }
  }

  // Done
  if (
    (fieldName === 'meldExceptionCriteria.mldOtw.mldOtwYesNo' ||
      fieldName === 'meldExceptionCriteria.mldOtw.mldOtwFamilialHypercholesterolemia' ||
      fieldName === 'meldExceptionCriteria.mldOtw.mldOtwSickleCellHepatopathy' ||
      fieldName === 'meldExceptionCriteria.mldOtw.mldOtwPrimaryHyperoxaluria' ||
      fieldName === 'meldExceptionCriteria.mldOtw.mldOtwPolycysticLiverDisease') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      meldExcepOptions?.mldOtwYesNo &&
      (mlasDetails.meldExceptionCriteria.mldOtw?.mldOtwFamilialHypercholesterolemia === 'true' ||
        mlasDetails.meldExceptionCriteria.mldOtw?.mldOtwSickleCellHepatopathy === 'true' ||
        mlasDetails.meldExceptionCriteria.mldOtw?.mldOtwPrimaryHyperoxaluria === 'true' ||
        mlasDetails.meldExceptionCriteria.mldOtw?.mldOtwPolycysticLiverDisease === 'true')
    ) {
      set(mlasDetails, 'meldExceptionCriteria.mldOtw.eligibleForMldOtwExceptionPoints', 'YES');
      set(mlasDetails, 'meldExceptionCriteria.mldOtw.mldOtwExceptionPoints', 18);
    } else {
      set(mlasDetails, 'meldExceptionCriteria.mldOtw.eligibleForMldOtwExceptionPoints', 'NO');
      set(mlasDetails, 'meldExceptionCriteria.mldOtw.mldOtwExceptionPoints', 0);
    }
  }

  if (
    (fieldName === 'mlas.slktYesNo' ||
      fieldName === 'mlas.slktLiverCirrhoticAndKidneyCkd' ||
      fieldName === 'mlas.slktMultiVisceralTransplant') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      mlasDetails.mlas.slktYesNo &&
      (mlasDetails.mlas.slktLiverCirrhoticAndKidneyCkd ||
        mlasDetails.mlas.slktMultiVisceralTransplant)
    ) {
      set(mlasDetails, 'mlas.eligibleForSlktExceptionPoints', 'YES');
      set(mlasDetails, 'mlas.slktExceptionPoints', 22);
    } else {
      set(mlasDetails, 'mlas.eligibleForSlktExceptionPoints', 'NO');
      set(mlasDetails, 'mlas.slktExceptionPoints', 0);
    }
  }

  // Done
  if (
    (fieldName === 'meldExceptionCriteria.nonHcc.nonHccYesNo' ||
      fieldName === 'meldExceptionCriteria.nonHcc.nonHccHepaticEpithelioid' ||
      fieldName === 'meldExceptionCriteria.nonHcc.nonHccBilobarNetSixMonths' ||
      fieldName === 'meldExceptionCriteria.nonHcc.nonHccBilobarNetSpecialScans') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      meldExcepOptions?.nonHccYesNo &&
      (mlasDetails.meldExceptionCriteria.nonHcc?.nonHccHepaticEpithelioid === 'true' ||
        (mlasDetails.meldExceptionCriteria.nonHcc?.nonHccBilobarNetSixMonths === 'true' &&
          mlasDetails.meldExceptionCriteria.nonHcc?.nonHccBilobarNetSpecialScans === 'true'))
    ) {
      set(mlasDetails, 'meldExceptionCriteria.nonHcc.eligibleForNonHccExceptionPoints', 'YES');
      set(mlasDetails, 'meldExceptionCriteria.nonHcc.nonHccExceptionPoints', 15);
    } else {
      set(mlasDetails, 'meldExceptionCriteria.nonHcc.eligibleForNonHccExceptionPoints', 'NO');
      set(mlasDetails, 'meldExceptionCriteria.nonHcc.nonHccExceptionPoints', 0);
    }
  }

  // Done
  if (
    (fieldName === 'meldExceptionCriteria.mld.mldYesNo' ||
      fieldName === 'meldExceptionCriteria.mld.mldFamilialAmyloidPolyneurpathy' ||
      fieldName === 'meldExceptionCriteria.mld.mldMapleSyrupUrineDisease') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      meldExcepOptions?.mldYesNo &&
      (mlasDetails.meldExceptionCriteria.mld?.mldFamilialAmyloidPolyneurpathy === 'true' ||
        mlasDetails.meldExceptionCriteria.mld?.mldMapleSyrupUrineDisease === 'true')
    ) {
      set(mlasDetails, 'meldExceptionCriteria.mld.eligibleForMldExceptionPoints', 'YES');
      set(mlasDetails, 'meldExceptionCriteria.mld.mldExceptionPoints', 22);
    } else {
      set(mlasDetails, 'meldExceptionCriteria.mld.eligibleForMldExceptionPoints', 'NO');
      set(mlasDetails, 'meldExceptionCriteria.mld.mldExceptionPoints', 0);
    }
  }

  // Done
  if (
    (fieldName ===
      'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.postTransplantPatientRequiringRetransplantYesNo' ||
      fieldName ===
        'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.postTransplantPatientRequiringRetransplantEligibleForSuperUrgentListing') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      meldExcepOptions?.retransplantYesNo &&
      mlasDetails?.meldExceptionCriteria?.postTransplantPatientRequiringRetransplant
        ?.postTransplantPatientRequiringRetransplantEligibleForSuperUrgentListing === 'false'
    ) {
      set(
        mlasDetails,
        'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.eligibleForPostTransplantPatientRequiringRetransplantExceptionPoints',
        'YES'
      );
      set(
        mlasDetails,
        'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.postTransplantPatientRequiringRetransplantExceptionPoints',
        18
      );
    } else {
      set(
        mlasDetails,
        'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.eligibleForPostTransplantPatientRequiringRetransplantExceptionPoints',
        'NO'
      );
      set(
        mlasDetails,
        'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.postTransplantPatientRequiringRetransplantExceptionPoints',
        0
      );
    }
  }

  // Done
  if (
    (fieldName === 'meldExceptionCriteria.pah.pahYesNo' ||
      fieldName === 'meldExceptionCriteria.pah.pahPostTreatmentMeanPulmonaryArterialPressure' ||
      fieldName === 'meldExceptionCriteria.pah.pahPostTreatmentPulmonaryVascularResistance' ||
      fieldName === 'meldExceptionCriteria.pah.pahPulmonaryCapillaryWedgePressure') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      meldExcepOptions?.pahYesNo &&
      mlasDetails?.meldExceptionCriteria?.pah?.pahPostTreatmentMeanPulmonaryArterialPressure ===
        'true' &&
      mlasDetails?.meldExceptionCriteria?.pah?.pahPostTreatmentPulmonaryVascularResistance ===
        'true' &&
      mlasDetails?.meldExceptionCriteria?.pah?.pahPulmonaryCapillaryWedgePressure === 'true'
    ) {
      set(mlasDetails, 'meldExceptionCriteria.pah.eligibleForPahExceptionPoints', 'YES');
      set(mlasDetails, 'meldExceptionCriteria.pah.pahExceptionPoints', 22);
    } else {
      set(mlasDetails, 'meldExceptionCriteria.pah.eligibleForPahExceptionPoints', 'NO');
      set(mlasDetails, 'meldExceptionCriteria.pah.pahExceptionPoints', 0);
    }
  }

  // Done
  if (
    (fieldName === 'meldExceptionCriteria.hps.hpsYesNo' ||
      fieldName === 'meldExceptionCriteria.hps.hpsPao2' ||
      fieldName === 'meldExceptionCriteria.hps.hpsAlveolarGradient' ||
      fieldName === 'meldExceptionCriteria.hps.hpsShunt' ||
      fieldName === 'meldExceptionCriteria.hps.hpsLiverDiseaseAndHyperTension' ||
      fieldName === 'meldExceptionCriteria.hps.hpsPulmonaryDisease') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      meldExcepOptions?.hpsYesNo &&
      mlasDetails?.meldExceptionCriteria?.hps?.hpsPao2 === 'true' &&
      mlasDetails?.meldExceptionCriteria?.hps?.hpsAlveolarGradient === 'true' &&
      mlasDetails?.meldExceptionCriteria?.hps?.hpsShunt === 'true' &&
      mlasDetails?.meldExceptionCriteria?.hps?.hpsLiverDiseaseAndHyperTension === 'true' &&
      mlasDetails?.meldExceptionCriteria?.hps?.hpsPulmonaryDisease === 'false'
    ) {
      set(mlasDetails, 'meldExceptionCriteria.hps.eligibleForHpsExceptionPoints', 'YES');
      set(mlasDetails, 'meldExceptionCriteria.hps.hpsExceptionPoints', 22);
    } else {
      set(mlasDetails, 'meldExceptionCriteria.hps.eligibleForHpsExceptionPoints', 'NO');
      set(mlasDetails, 'meldExceptionCriteria.hps.hpsExceptionPoints', 0);
    }
  }

// Done
  if (
    (fieldName === 'meldExceptionCriteria.psc.pscYesNo' ||
      fieldName === 'meldExceptionCriteria.psc.pscProvenOnMrcp' ||
      fieldName === 'meldExceptionCriteria.psc.pscSevereIntractableItchingAfterTherapy' ||
      fieldName === 'meldExceptionCriteria.psc.pscRecurrentCholangitisLessThan4Episodes' ||
      fieldName === 'meldExceptionCriteria.psc.pscRefactoryCholangitis') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      meldExcepOptions?.pscYesNo &&
      mlasDetails?.meldExceptionCriteria?.psc?.pscProvenOnMrcp === 'true' &&
      (mlasDetails?.meldExceptionCriteria?.psc?.pscSevereIntractableItchingAfterTherapy ===
        'true' ||
        mlasDetails?.meldExceptionCriteria?.psc?.pscRecurrentCholangitisLessThan4Episodes ===
          'true' ||
        mlasDetails?.meldExceptionCriteria?.psc?.pscRefactoryCholangitis === 'true')
    ) {
      set(mlasDetails, 'meldExceptionCriteria.psc.eligibleForPscExceptionPoints', 'YES');
      set(mlasDetails, 'meldExceptionCriteria.psc.pscExceptionPoints', 18);
    } else {
      set(mlasDetails, 'meldExceptionCriteria.psc.eligibleForPscExceptionPoints', 'NO');
      set(mlasDetails, 'meldExceptionCriteria.psc.pscExceptionPoints', 0);
    }
  }

  // Done
  if (
    (fieldName === 'meldExceptionCriteria.pbc.pbcYesNo' ||
      fieldName === 'meldExceptionCriteria.pbc.pbcAmaPositive' ||
      fieldName === 'meldExceptionCriteria.pbc.pbcSevereIntractablePruritusAfterTreatment' ||
      fieldName ===
        'meldExceptionCriteria.pbc.pbcSevereMetabolicBoneDiseaseNonTraumaticFractures' ||
      fieldName === 'meldExceptionCriteria.pbc.pbcSevereMetabolicBoneDiseaseZScore') &&
    value
  ) {
    set(mlasDetails, fieldName, value);
    if (
      meldExcepOptions?.pbcYesNo &&
      mlasDetails?.meldExceptionCriteria?.pbc?.pbcAmaPositive === 'true' &&
      (mlasDetails?.meldExceptionCriteria?.pbc?.pbcSevereIntractablePruritusAfterTreatment ===
        'true' ||
        mlasDetails?.meldExceptionCriteria?.pbc
          ?.pbcSevereMetabolicBoneDiseaseNonTraumaticFractures === 'true' ||
        mlasDetails?.meldExceptionCriteria?.pbc?.pbcSevereMetabolicBoneDiseaseZScore === 'true')
    ) {
      set(mlasDetails, 'meldExceptionCriteria.pbc.eligibleForPbcExceptionPoints', 'YES');
      set(mlasDetails, 'meldExceptionCriteria.pbc.pbcExceptionPoints', 18);
    } else {
      set(mlasDetails, 'meldExceptionCriteria.pbc.eligibleForPbcExceptionPoints', 'NO');
      set(mlasDetails, 'meldExceptionCriteria.pbc.pbcExceptionPoints', 0);
    }
  }

  // Done
  if (
    fieldName === 'meldExceptionCriteria.pbc.pbcYesNo' ||
    fieldName === 'meldExceptionCriteria.pbc.pbcAmaPositive' ||
    fieldName === 'meldExceptionCriteria.pbc.pbcSevereIntractablePruritusAfterTreatment' ||
    fieldName === 'meldExceptionCriteria.pbc.pbcSevereMetabolicBoneDiseaseNonTraumaticFractures' ||
    fieldName === 'meldExceptionCriteria.pbc.pbcSevereMetabolicBoneDiseaseZScore' ||
    fieldName === 'meldExceptionCriteria.psc.pscYesNo' ||
    fieldName === 'meldExceptionCriteria.psc.pscProvenOnMrcp' ||
    fieldName === 'meldExceptionCriteria.psc.pscSevereIntractableItchingAfterTherapy' ||
    fieldName === 'meldExceptionCriteria.psc.pscRecurrentCholangitisLessThan4Episodes' ||
    fieldName === 'meldExceptionCriteria.psc.pscRefactoryCholangitis' ||
    fieldName === 'meldExceptionCriteria.hps.hpsYesNo' ||
    fieldName === 'meldExceptionCriteria.hps.hpsPao2' ||
    fieldName === 'meldExceptionCriteria.hps.hpsAlveolarGradient' ||
    fieldName === 'meldExceptionCriteria.hps.hpsShunt' ||
    fieldName === 'meldExceptionCriteria.hps.hpsLiverDiseaseAndHyperTension' ||
    fieldName === 'meldExceptionCriteria.hps.hpsPulmonaryDisease' ||
    fieldName === 'meldExceptionCriteria.pah.pahYesNo' ||
    fieldName === 'meldExceptionCriteria.pah.pahPostTreatmentMeanPulmonaryArterialPressure' ||
    fieldName === 'meldExceptionCriteria.pah.pahPostTreatmentPulmonaryVascularResistance' ||
    fieldName === 'meldExceptionCriteria.pah.pahPulmonaryCapillaryWedgePressure' ||
    fieldName ===
      'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.postTransplantPatientRequiringRetransplantYesNo' ||
    fieldName ===
      'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.postTransplantPatientRequiringRetransplantEligibleForSuperUrgentListing' ||
    fieldName === 'meldExceptionCriteria.mld.mldYesNo' ||
    fieldName === 'meldExceptionCriteria.mld.mldFamilialAmyloidPolyneurpathy' ||
    fieldName === 'meldExceptionCriteria.mld.mldMapleSyrupUrineDisease' ||
    fieldName === 'meldExceptionCriteria.nonHcc.nonHccYesNo' ||
    fieldName === 'meldExceptionCriteria.nonHcc.nonHccHepaticEpithelioid' ||
    fieldName === 'meldExceptionCriteria.nonHcc.nonHccBilobarNetSixMonths' ||
    fieldName === 'meldExceptionCriteria.nonHcc.nonHccBilobarNetSpecialScans' ||
    fieldName === 'meldExceptionCriteria.mldOtw.mldOtwYesNo' ||
    fieldName === 'meldExceptionCriteria.mldOtw.mldOtwFamilialHypercholesterolemia' ||
    fieldName === 'meldExceptionCriteria.mldOtw.mldOtwSickleCellHepatopathy' ||
    fieldName === 'meldExceptionCriteria.mldOtw.mldOtwPrimaryHyperoxaluria' ||
    fieldName === 'meldExceptionCriteria.mldOtw.mldOtwPolycysticLiverDisease' ||
    fieldName === 'meldExceptionCriteria.hcc.hccYesNo' ||
    fieldName === 'meldExceptionCriteria.hcc.hccSingleTumorLessThan6AndHalfCm' ||
    fieldName === 'meldExceptionCriteria.hcc.hccLessThan3TumorWithLargestLessThan4AndHalfcm' ||
    fieldName === 'meldExceptionCriteria.hcc.hccLessThan3TumorDiameterLessThan8cm' ||
    fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastTriphasicCt' ||
    fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastBoneScan' ||
    fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastHrctChest' ||
    fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastPetCt' ||
    fieldName === 'meldExceptionCriteria.hcc.hccActivePvtt' ||
    fieldName === 'meldExceptionCriteria.hcc.hccExtrahepaticDisease' ||
    fieldName === 'meldExceptionCriteria.hcc.hccAtleastOneLesionGreaterThan2cm' ||
    fieldName === 'meldExceptionCriteria.hcc.hccAfp' ||
    fieldName === 'meldExceptionCriteria.hcc.hccLargestTumorSize'
  ) {
    set(mlasDetails, fieldName, value);
    const maxPoints = Math.max(
      mlasDetails?.meldExceptionCriteria?.hps?.hpsExceptionPoints || 0,
      mlasDetails?.meldExceptionCriteria?.pah?.pahExceptionPoints || 0,
      mlasDetails?.meldExceptionCriteria?.hcc?.hccExceptionPoints || 0,
      mlasDetails?.meldExceptionCriteria?.psc?.pscExceptionPoints || 0,
      mlasDetails?.meldExceptionCriteria?.pbc?.pbcExceptionPoints || 0,
      mlasDetails?.meldExceptionCriteria?.postTransplantPatientRequiringRetransplant
        ?.postTransplantPatientRequiringRetransplantExceptionPoints || 0,
      mlasDetails?.meldExceptionCriteria?.mld?.mldExceptionPoints || 0,
      mlasDetails?.meldExceptionCriteria?.mldOtw?.mldOtwExceptionPoints || 0,
      mlasDetails?.meldExceptionCriteria?.nonHcc?.nonHccExceptionPoints || 0,
      mlasDetails?.mlas?.slktExceptionPoints || 0
    );
    // set(mlasDetails, 'mlas.meldExceptionPoints', maxPoints);
    if (maxPoints !== 0) {
      set(mlasDetails, 'meldExceptionCriteria.result.meldExceptionCriteriaPointsEligible', 'YES');
      set(mlasDetails, 'meldExceptionCriteria.result.meldExceptionCriteriaPoints', maxPoints);
    } else {
      set(mlasDetails, 'meldExceptionCriteria.result.meldExceptionCriteriaPointsEligible', 'NO');
      set(mlasDetails, 'meldExceptionCriteria.result.meldExceptionCriteriaPoints', 0);
    }
  }

  if (
    mlasDetails?.mlas?.meld &&
    mlasDetails?.complicationScore?.complicationPoints &&
    mlasDetails?.waitingTimePoints
  ) {
    set(
      mlasDetails,
      'mlas.mlasAbc',
      parseInt(mlasDetails?.mlas.meld, 10) +
        parseInt(mlasDetails?.complicationScore?.complicationPoints, 10) +
        parseInt(mlasDetails?.waitingTimePoints, 10)
    );
  }

  if (mlasDetails?.mlas?.meldExceptionPoints && mlasDetails?.mlas?.waitingTimePoints) {
    set(
      mlasDetails,
      'mlas.mlasDc',
      parseInt(mlasDetails?.mlas?.meldExceptionPoints, 10) +
        parseInt(mlasDetails?.mlas?.waitingTimePoints, 10)
    );
  }

  if (mlasDetails?.mlas?.mlasAbc && mlasDetails?.mlas?.mlasDc) {
    set(
      mlasDetails,
      'mlas.finalMlas',
      Math.max(mlasDetails?.mlas?.mlasAbc, mlasDetails?.mlas?.mlasDc)
    );
  }

  // Done
  if (
    fieldName ===
      'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxModerateOnHrctChestXrayUsgThorax' ||
    fieldName ===
      'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxTherapeuticTaps' ||
    fieldName === 'complicationScore.hepaticHydrothoraxModToSevere.refractoryAscites' ||
    fieldName === 'complicationScore.hepaticHydrothoraxModToSevere.refractoryAscitesDiuretic' ||
    fieldName ===
      'complicationScore.hepaticHydrothoraxModToSevere.refractoryAscitesNoradrenaline' ||
    fieldName === 'complicationScore.sbp.sbpAnc' ||
    fieldName === 'complicationScore.heNeedingHIcu.heNeedingGreaterThanEquals3' ||
    fieldName === 'complicationScore.heNeedingHIcu.structuralNeurologicalDisease' ||
    fieldName === 'complicationScore.varicealBleedNoEndo.recurrentVaricealBleedNotAmenable'
  ) {
    set(mlasDetails, fieldName, value);
    if (
      mlasDetails?.mlas?.meld <= 30
      // &&
      // mlasDetails?.complicationScore?.sbp?.spbPoints &&
      // mlasDetails?.complicationScore?.hepaticHydrothoraxModToSevere?.hepaticHydrothoraxPoints &&
      // mlasDetails?.complicationScore?.heNeedingHIcu?.hePoints &&
      // mlasDetails?.complicationScore?.varicealBleedNoEndo?.recurrentVaricealPoints
    ) {
      let sum = 0;

      if (
        mlasDetails?.complicationScore?.sbp?.spbPoints ||
        mlasDetails?.complicationScore?.sbp?.spbPoints === 0
      ) {
        sum += mlasDetails?.complicationScore?.sbp?.spbPoints;
      }
      if (
        mlasDetails?.complicationScore?.hepaticHydrothoraxModToSevere?.hepaticHydrothoraxPoints ||
        mlasDetails?.complicationScore?.hepaticHydrothoraxModToSevere?.hepaticHydrothoraxPoints ===
          0
      ) {
        sum +=
          mlasDetails?.complicationScore?.hepaticHydrothoraxModToSevere?.hepaticHydrothoraxPoints;
      }
      if (
        mlasDetails?.complicationScore?.heNeedingHIcu?.hePoints ||
        mlasDetails?.complicationScore?.heNeedingHIcu?.hePoints === 0
      ) {
        sum += mlasDetails?.complicationScore?.heNeedingHIcu?.hePoints;
      }
      if (
        mlasDetails?.complicationScore?.varicealBleedNoEndo?.recurrentVaricealPoints ||
        mlasDetails?.complicationScore?.varicealBleedNoEndo?.recurrentVaricealPoints === 0
      ) {
        sum += mlasDetails?.complicationScore?.varicealBleedNoEndo?.recurrentVaricealPoints;
      }
      set(mlasDetails, 'complicationScore.result.complicationPointsEligible', 'YES');
      set(mlasDetails, 'complicationScore.result.complicationPoints', sum > 6 ? 6 : sum);
    } else if (
      mlasDetails?.mlas?.meld > 30
      // &&
      // mlasDetails?.complicationScore?.sbp?.spbPoints &&
      // mlasDetails?.complicationScore?.hepaticHydrothoraxModToSevere?.hepaticHydrothoraxPoints &&
      // mlasDetails?.complicationScore?.heNeedingHIcu?.hePoints &&
      // mlasDetails?.complicationScore?.varicealBleedNoEndo?.recurrentVaricealPoints
    ) {
      set(mlasDetails, 'complicationScore.result.complicationPointsEligible', 'NO');
      set(mlasDetails, 'complicationScore.result.complicationPoints', 0);
    }
  }

  // Done
  if (
    fieldName === 'mlas.listingBilirubin' ||
    fieldName === 'mlas.listingCreatinine' ||
    fieldName === 'mlas.listingInr' ||
    fieldName === 'mlas.listingAlbumin'
  ) {
    set(mlasDetails, fieldName, value);
    if (
      mlasDetails?.mlas?.listingBilirubin &&
      mlasDetails?.mlas?.listingCreatinine &&
      mlasDetails?.mlas?.listingInr
    ) {
      const duration = moment.duration(
        moment().diff(moment(recipientDemographicDetails?.dateOfBirth, FORMAT_DATE_UNIXTS))
      );
      if (duration?._data?.years > 12 || recipientDemographicDetails?.currentAge > 12) {
        const meldCalculated =
          9.57 * Math.log(mlasDetails?.mlas?.listingCreatinine) +
          3.78 * Math.log(mlasDetails?.mlas?.listingBilirubin) +
          11.2 * Math.log(mlasDetails?.mlas?.listingInr);

        const maxMeld = meldCalculated > 40 ? 40 : Math.round(meldCalculated);

        set(mlasDetails, 'mlas.listingOptnMeld', maxMeld);
        set(mlasDetails, 'mlas.listingOptnPeld', '');
        set(mlasDetails, 'mlas.meld', maxMeld);
      } else {
        const peldCalculated =
          10 * (0.48 * Math.log(mlasDetails?.mlas?.listingBilirubin)) +
          1.857 * Math.log(mlasDetails?.mlas?.listingInr) -
          0.687 * Math.log(mlasDetails?.mlas?.listingAlbumin || 0) +
          0.436 +
          0.667;

        const maxPeld = peldCalculated > 50 ? 50 : Math.round(peldCalculated);

        set(mlasDetails, 'mlas.listingOptnPeld', maxPeld);
        set(mlasDetails, 'mlas.listingOptnMeld', '');
        set(mlasDetails, 'mlas.meld', maxPeld);
      }
    }
  }

  if (
    fieldName === 'mlas.currentBilirubin' ||
    fieldName === 'mlas.currentCreatinine' ||
    fieldName === 'mlas.currentInr' ||
    fieldName === 'mlas.currentAlbumin'
  ) {
    set(mlasDetails, fieldName, value);
    if (
      mlasDetails?.mlas?.currentBilirubin &&
      mlasDetails?.mlas?.currentCreatinine &&
      mlasDetails?.mlas?.currentInr
    ) {
      const duration = moment.duration(
        moment().diff(moment(mlasDetails.mlas.dateOfBirth, FORMAT_DATE_UNIXTS))
      );

      if (duration?._data?.years > 12) {
        const meldCalculated =
          9.57 * Math.log(mlasDetails?.mlas?.currentCreatinine) +
          3.78 * Math.log(mlasDetails?.mlas?.currentBilirubin) +
          11.2 * Math.log(mlasDetails?.mlas?.currentInr);

        const maxMeld = meldCalculated > 40 ? 40 : Math.round(meldCalculated);

        set(mlasDetails, 'mlas.currentOptnMeld', maxMeld);
        set(mlasDetails, 'mlas.meld', maxMeld);
      } else {
        const peldCalculated =
          10 * (0.48 * Math.log(mlasDetails?.mlas?.currentBilirubin)) +
          1.857 * Math.log(mlasDetails?.mlas?.currentInr) -
          0.687 * Math.log(mlasDetails?.mlas?.currentAlbumin || 0) +
          0.436 +
          0.667;

        const maxPeld = peldCalculated > 50 ? 50 : Math.round(peldCalculated);

        set(mlasDetails, 'mlas.currentOptnPeld', maxPeld);
        set(mlasDetails, 'meld', maxPeld);
      }
    }
  }

  // Done
  if (
    fieldName === 'meldExceptionCriteria.hcc.hccSingleTumorLessThan6AndHalfCm' ||
    fieldName === 'meldExceptionCriteria.hcc.hccLessThan3TumorWithLargestLessThan4AndHalfcm' ||
    fieldName === 'meldExceptionCriteria.hcc.hccLessThan3TumorDiameterLessThan8cm'
  ) {
    set(mlasDetails, fieldName, value);
    if (mlasDetails?.meldExceptionCriteria?.hcc?.hccSingleTumorLessThan6AndHalfCm === 'true') {
      set(mlasDetails, 'meldExceptionCriteria.hcc.hccWithinUcsfCriteria', 'YES');
    } else if (
      mlasDetails?.meldExceptionCriteria?.hcc?.hccLessThan3TumorWithLargestLessThan4AndHalfcm ===
        'true' &&
      mlasDetails?.meldExceptionCriteria?.hcc?.hccLessThan3TumorDiameterLessThan8cm === 'true'
    ) {
      set(mlasDetails, 'meldExceptionCriteria.hcc.hccWithinUcsfCriteria', 'YES');
    } else {
      set(mlasDetails, 'meldExceptionCriteria.hcc.hccWithinUcsfCriteria', 'NO');
    }
  }

  // Done
  if (fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastTriphasicCt') {
    const duration = moment.duration(moment().diff(moment(value, FORMAT_DATE_UNIXTS)));
    set(
      mlasDetails,
      'meldExceptionCriteria.hcc.hccDurationFromLastTriphasicCt',
      `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`
    );
  }

  // Done
  if (fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastBoneScan') {
    const duration = moment.duration(moment().diff(moment(value, FORMAT_DATE_UNIXTS)));
    set(
      mlasDetails,
      'meldExceptionCriteria.hcc.hccDurationFromlastBoneScan',
      `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`
    );
  }

  // Done
  if (fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastHrctChest') {
    const duration = moment.duration(moment().diff(moment(value, FORMAT_DATE_UNIXTS)));
    set(
      mlasDetails,
      'meldExceptionCriteria.hcc.hccDurationFromLastHrctChest',
      `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`
    );
  }

  // Done
  if (fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastPetCt') {
    const duration = moment.duration(moment().diff(moment(value, FORMAT_DATE_UNIXTS)));
    set(
      mlasDetails,
      'meldExceptionCriteria.hcc.hccDurationFromLastPetCt',
      `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`
    );
  }

  if (
    fieldName === 'meldExceptionCriteria.hcc.hccYesNo' ||
    fieldName === 'meldExceptionCriteria.hcc.hccSingleTumorLessThan6AndHalfCm' ||
    fieldName === 'meldExceptionCriteria.hcc.hccLessThan3TumorWithLargestLessThan4AndHalfcm' ||
    fieldName === 'meldExceptionCriteria.hcc.hccLessThan3TumorDiameterLessThan8cm' ||
    fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastTriphasicCt' ||
    fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastBoneScan' ||
    fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastHrctChest' ||
    fieldName === 'meldExceptionCriteria.hcc.hccDateOfLastPetCt' ||
    fieldName === 'meldExceptionCriteria.hcc.hccActivePvtt' ||
    fieldName === 'meldExceptionCriteria.hcc.hccExtrahepaticDisease' ||
    fieldName === 'meldExceptionCriteria.hcc.hccAtleastOneLesionGreaterThan2cm' ||
    fieldName === 'meldExceptionCriteria.hcc.hccAfp' ||
    fieldName === 'meldExceptionCriteria.hcc.hccLargestTumorSize'
  ) {
    set(mlasDetails, fieldName, value);
    if (
      mlasDetails?.meldExceptionCriteria?.hcc?.hccWithinUcsfCriteria === 'YES' &&
      mlasDetails?.meldExceptionCriteria?.hcc?.hccActivePvtt === 'false' &&
      mlasDetails?.meldExceptionCriteria?.hcc?.hccExtrahepaticDisease === 'false' &&
      mlasDetails?.meldExceptionCriteria?.hcc?.hccAtleastOneLesionGreaterThan2cm === 'true' &&
      mlasDetails?.meldExceptionCriteria?.hcc?.hccAfp < 1000
    ) {
      const durationForTriphasicCt = moment.duration(
        moment().diff(
          moment(
            mlasDetails?.meldExceptionCriteria?.hcc?.hccDateOfLastTriphasicCt || 0,
            FORMAT_DATE_UNIXTS
          )
        )
      );
      const durationForBoneScan = moment.duration(
        moment().diff(
          moment(
            mlasDetails?.meldExceptionCriteria?.hcc?.hccDateOfLastBoneScan || 0,
            FORMAT_DATE_UNIXTS
          )
        )
      );
      const durationForHrctChest = moment.duration(
        moment().diff(
          moment(
            mlasDetails?.meldExceptionCriteria?.hcc?.hccDateOfLastHrctChest || 0,
            FORMAT_DATE_UNIXTS
          )
        )
      );
      const durationForPetCt = moment.duration(
        moment().diff(
          moment(
            mlasDetails?.meldExceptionCriteria?.hcc?.hccDateOfLastPetCt || 0,
            FORMAT_DATE_UNIXTS
          )
        )
      );

      if (
        durationForTriphasicCt?._data?.years <= 0 &&
        durationForTriphasicCt?._data?.months < 1 &&
        ((durationForBoneScan?._data?.years <= 0 &&
          durationForBoneScan?._data?.months < 6 &&
          durationForHrctChest?._data?.years <= 0 &&
          durationForHrctChest?._data?.months < 6) ||
          (durationForPetCt?._data?.years <= 0 && durationForPetCt?._data?.months < 6))
      ) {
        set(mlasDetails, 'meldExceptionCriteria.hcc.eligibleForHccExceptionPoints', 'YES');
        set(
          mlasDetails,
          'meldExceptionCriteria.hcc.hccExceptionPoints',
          mlasDetails?.meldExceptionCriteria?.hcc?.hccLargestTumorSize <= 4 ? 22 : 25
        );
      } else {
        set(mlasDetails, 'meldExceptionCriteria.hcc.eligibleForHccExceptionPoints', 'NO');
        set(mlasDetails, 'meldExceptionCriteria.hcc.hccExceptionPoints', 0);
      }
    } else {
      set(mlasDetails, 'meldExceptionCriteria.hcc.eligibleForHccExceptionPoints', 'NO');
      set(mlasDetails, 'meldExceptionCriteria.hcc.hccExceptionPoints', 0);
    }
  }

  return set(mlasDetails, fieldName, value);
};
