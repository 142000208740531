import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { FormGroup } from 'reactstrap';

class RequiredFormGroup extends Component {
  render() {
    return null;
    // const { required, children, ...otherProps } = this.props;

    // if (!required) {
    //   return <FormGroup {...otherProps}>{children}</FormGroup>;
    // }

    // const modifiedChildren = React.Children.map(children, child => {
    //   if (child.props && child.props.tag === 'label') {
    //     return (
    //       <React.Fragment>
    //         {child}
    //         <span style={{ color: 'red' }}>*</span>
    //       </React.Fragment>
    //     );
    //   }
    //   return child;
    // });

    // return <FormGroup {...otherProps}>{modifiedChildren}</FormGroup>;
  }
}
RequiredFormGroup.propTypes = {
  required: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
};
RequiredFormGroup.defaultProps = {
  required: false,
};

export default RequiredFormGroup;
