import React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Card,
  CardContent,
  CircularProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Joi from 'joi';
import { withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { ForgotPassword } from 'aws-amplify-react';
import moment from 'moment';
import winston from '../../winston';

const styles = (theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this.state = {
      cancellablePromises: [],
      spinner: false,
      delivery: null,
      resendCodeDisabled: false,
      counter: 0,
      showErrors: false,
      email: '',
      code: '',
      password: '',
      confirmPassword: '',
    };
  }

  _validateCode() {
    const { email } = this.state;

    const emailSchema = Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'any.required': 'Cannot be empty',
        'string.empty': 'Cannot be empty',
        'string.base': 'Should be a valid string',
        'string.email': 'Should be a valid email',
      });
    const { error: emailError } = emailSchema.validate(email);
    let isEmailValid = true;
    let emailErrorMsg = 'Please check';
    if (emailError) {
      isEmailValid = false;
      emailErrorMsg = emailError.details[0].message;
    }

    return {
      isEmailValid,
      emailErrorMsg,
    };
  }

  _validatePwdReset() {
    const { code, password, confirmPassword } = this.state;

    const codeSchema = Joi.string().alphanum().required().messages({
      'any.required': 'Cannot be empty',
      'string.empty': 'Cannot be empty',
      'string.base': 'Should be a valid string',
      'string.email': 'Should be a valid email',
    });

    const { error: codeError } = codeSchema.validate(code);
    let isCodeValid = true;
    let codeErrorMsg = 'Please check';
    if (codeError) {
      isCodeValid = false;
      codeErrorMsg = codeError.details[0].message;
    }

    const pwdSchema = Joi.string().required().messages({
      'any.required': 'Cannot be empty',
      'string.empty': 'Cannot be empty',
      'string.base': 'Should be a valid string',
      'string.email': 'Should be a valid email',
    });
    let isPwdValid = true;
    let pwdErrorMsg = 'Please check';
    let isConfirmPwdValid = true;
    let confirmPwdErrorMsg = 'Please check';

    if (password !== confirmPassword) {
      isConfirmPwdValid = false;
      confirmPwdErrorMsg = 'Passwords should match';
      isPwdValid = false;
      pwdErrorMsg = 'Passwords should match';
    }

    const { error: pwdError } = pwdSchema.validate(password);
    if (pwdError) {
      isPwdValid = false;
      pwdErrorMsg = pwdError.details[0].message;
    }

    return {
      isCodeValid,
      codeErrorMsg,
      isPwdValid,
      pwdErrorMsg,
      isConfirmPwdValid,
      confirmPwdErrorMsg,
    };
  }

  sendView() {
    const { isEmailValid, emailErrorMsg } = this._validateCode();
    const { showErrors } = this.state;

    return (
      <Grid>
        <Typography style={{ marginTop: '10px', marginBottom: '10px' }}>
          Please email your login email address, and we&apos;ll send a verification code
        </Typography>
        <TextField
          onChange={(e) => {
            this.handleInputChange(e);
            this.setState({
              email: e.target.value,
            });
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="Email"
          autoFocus
          onKeyDown={(e) => this._onKeyPressSend(e)}
          error={showErrors && !isEmailValid}
          helperText={showErrors && !isEmailValid && emailErrorMsg}
        />
      </Grid>
    );
  }

  submitView() {
    const {
      isCodeValid,
      codeErrorMsg,
      isPwdValid,
      pwdErrorMsg,
      isConfirmPwdValid,
      confirmPwdErrorMsg,
    } = this._validatePwdReset();
    const { showErrors } = this.state;

    return (
      <Grid>
        <Typography style={{ marginTop: '10px', marginBottom: '10px' }}>
          Please input the code mailed to you below and provide your new password
        </Typography>
        <TextField
          onChange={(e) => {
            this.handleInputChange(e);
            this.setState({
              code: e.target.value,
            });
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="code"
          label="Code"
          key="code"
          id="code"
          autoComplete="off"
          value={this.state.code}
          error={showErrors && !isCodeValid}
          helperText={showErrors && !isCodeValid && codeErrorMsg}
        />
        <TextField
          onChange={(e) => {
            this.handleInputChange(e);
            this.setState({
              password: e.target.value,
            });
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="New Password"
          type="password"
          key="password"
          id="password"
          value={this.state.password}
          autoComplete="off"
          onKeyDown={(e) => this._onKeyPressSubmit(e)}
          error={showErrors && !isPwdValid}
          helperText={showErrors && !isPwdValid && pwdErrorMsg}
        />
        <TextField
          onChange={(e) => {
            this.setState({
              confirmPassword: e.target.value,
            });
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirm-password"
          label="Confirm Password"
          type="password"
          key="confirm-password"
          id="confirm-password"
          value={this.state.confirmPassword}
          autoComplete="off"
          onKeyDown={(e) => this._onKeyPressSubmit(e)}
          error={showErrors && !isConfirmPwdValid}
          helperText={showErrors && !isConfirmPwdValid && confirmPwdErrorMsg}
        />
      </Grid>
    );
  }

  showComponent() {
    const { classes, authData = {}, onStateChange } = this.props;
    const { spinner } = this.state;
    return (
      <>
        <Grid
          className={classes.container}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <CssBaseline />
          <Grid
            style={{
              paddingRight: '4vw',
            }}
            item
            xs={5}
          >
            <Card
              style={{
                borderRadius: '10px',
                marginLeft: '40px',
                marginRight: '40px',
              }}
            >
              <CardContent>
                <div className={classes.paper}>
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Forgot Password
                  </Typography>
                  <form className={classes.form} noValidate>
                    {this.state.delivery || authData.username ? this.submitView() : this.sendView()}
                    {this.state.delivery || authData.username ? (
                      <Button
                        // type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => this._toggleSpinnerAndSubmit()}
                      >
                        Submit
                        {spinner ? (
                          <CircularProgress size="20px" style={{ color: '#FFFFFF' }} />
                        ) : null}
                      </Button>
                    ) : (
                      <Button
                        // type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => this._toggleSpinnerAndSendCode()}
                      >
                        Send Code
                        {spinner ? (
                          <CircularProgress size="20px" style={{ color: '#FFFFFF' }} />
                        ) : null}
                      </Button>
                    )}
                    <Grid container>
                      <Grid item xs>
                        {this.state.delivery || authData.username ? (
                          <Grid item xs>
                            {this.state.resendCodeDisabled ? (
                              `${moment.utc(this.state.counter).format('mm:ss')} to Resend Code`
                            ) : (
                              <Button
                                color="primary"
                                onClick={() => this._toggleSpinnerAndSendCode()}
                                style={{
                                  textTransform: 'none',
                                }}
                              >
                                Resend Code
                              </Button>
                            )}
                          </Grid>
                        ) : (
                          <Button
                            color="primary"
                            onClick={() => onStateChange('signIn')}
                            style={{
                              textTransform: 'none',
                            }}
                          >
                            Back to Sign In
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }

  _toggleSpinnerAndSendCode() {
    const { isEmailValid } = this._validateCode();
    if (!isEmailValid) {
      this.setState({
        showErrors: true,
      });
      return;
    }

    this.setState(
      {
        spinner: true,
      },
      () => {
        this._sendCode();
      }
    );
  }

  _toggleSpinnerAndSubmit() {
    const { isCodeValid, isPwdValid, isConfirmPwdValid } = this._validatePwdReset();
    if (!isCodeValid || !isPwdValid || !isConfirmPwdValid) {
      this.setState({
        showErrors: true,
      });
      return;
    }

    this.setState(
      {
        spinner: true,
      },
      () => {
        this._submit();
      }
    );
  }

  _onKeyPressSend(event) {
    if (event.keyCode === 13) {
      event.stopPropagation();
      this._toggleSpinnerAndSendCode();
    }
  }

  _onKeyPressSubmit(event) {
    if (event.keyCode === 13) {
      event.stopPropagation();
      this._toggleSpinnerAndSubmit();
    }
  }

  _sendCode() {
    const { authData = {} } = this.props;
    const username = this.state.email || authData.username;
    let timerId;

    if (!Auth || typeof Auth.forgotPassword !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.forgotPassword(username)
      .then((data) => {
        this.setState({
          resendCodeDisabled: true,
          counter: 90000,
          spinner: false,
          delivery: data.CodeDeliveryDetails,
          showErrors: false,
        });

        setInterval(() => {
          if (this.state.resendCodeDisabled && this.state.counter !== 0) {
            timerId = this.setState({ counter: this.state.counter - 1000 });
          }
          if (this.state.counter === 0) {
            clearInterval(timerId);
            this.setState({ resendCodeDisabled: false });
          }
        }, 1000);

        winston.debug(data);
      })
      .catch((err) => {
        this.setState({ spinner: false, showErrors: false });
        this.error(err);
      });
  }

  _submit() {
    const { authData = {}, onStateChange } = this.props;
    const { code, password } = this.inputs;
    const username = this.getUsernameFromInput() || authData.username || this.state.email;

    if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.forgotPasswordSubmit(username, code, password)
      .then((data) => {
        winston.debug(data);
        onStateChange('signIn');
        this.setState({ spinner: false, delivery: null, showErrors: false });
      })
      .catch((err) => {
        this.setState({ spinner: false });
        this.error(err);
      });
  }
}

export default withStyles(styles)(CustomForgotPassword);
