import React from 'react';
import { Grid } from '@material-ui/core';

const Spinner = () => {
  return (
    <Grid
      container
      style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}
    >
      <Grid sm={12}>
        <svg
          width="200px"
          height="200px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          className="lds-infinity"
          style={{ background: 'none' }}
        >
          <path
            fill="none"
            ng-attr-stroke="{{config.stroke}}"
            ng-attr-stroke-width="{{config.width}}"
            ng-attr-stroke-dasharray="{{config.dasharray}}"
            d="M24.3,30C11.4,30,5,43.3,5,50s6.4,20,19.3,20c19.3,0,32.1-40,51.4-40 C88.6,30,95,43.3,95,50s-6.4,20-19.3,20C56.4,70,43.6,30,24.3,30z"
            stroke="#20a8d8"
            strokeWidth="3"
            strokeDasharray="169.34869262695312 87.24023559570313"
          >
            <animate
              attributeName="stroke-dashoffset"
              calcMode="linear"
              values="0;256.58892822265625"
              keyTimes="0;1"
              dur="1.5"
              begin="0s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </Grid>
      <Grid sm={12}>
        <h3>
          <strong>Loading...</strong>
        </h3>
      </Grid>
    </Grid>
  );
};

export default Spinner;
