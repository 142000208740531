import React, { Component } from 'react';
import {
  InputGroup,
  //InputGroupAddon,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { VALIDATION } from '../../../../../constants';
import { ValidatedInput } from '../../../../Custom';
import DonorHospitalPicker from './donorHospitalPicker';

class ValidatedHospitalInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectHospitalModal: false
    };
  }

  _toggleSelectHospitalModal(show) {
    this.setState({
      selectHospitalModal: show
    });
  }

  render() {
    return (
      <React.Fragment>
        {this._renderSpecialistInput()}
        {this._renderSelectHospitalModal()}
      </React.Fragment>
    );
  }

  _renderSpecialistInput() {
    const { hospitalDetails, disabled, showValid, onValidation } = this.props;
    let { validation } = this.props;
    let valid = true;
    let messages = [];
    let joiError = null;
    const style = {};
    if (validation === true) {
      validation = VALIDATION.ALL;
    } else if (validation === false) {
      validation = VALIDATION.OFF;
    }
    if (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && this.isDirty)) {
      if (!hospitalDetails || !hospitalDetails.hospitalId) {
        valid = false;
        messages = ['Cannot be empty'];
        joiError = new Error('Cannot be empty');
        style.borderColor = 'red';
      } else if (showValid) {
        style.borderColor = 'green';
      }
      onValidation(valid, messages, joiError);
    } else {
      valid = true;
      messages = [];
    }

    return (
      <React.Fragment>
        {hospitalDetails && hospitalDetails.hospitalId ? (
          <InputGroup>
            <ValidatedInput
              type="text"
              value={hospitalDetails.name}
              disabled={disabled}
              placeholder="hospital"
              validation={VALIDATION.OFF}
            />
            {!disabled ? (
              <div className="input-group-append">
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    this._toggleSelectHospitalModal(true);
                  }}
                >
                  Change
                </Button>
              </div>
            ) : null}
          </InputGroup>
        ) : (
          <React.Fragment>
            {!disabled ? (
              <React.Fragment>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    this._toggleSelectHospitalModal(true);
                  }}
                  style={style}
                >
                  Select
                </Button>
                <p
                  className={classnames({
                    'invalid-feedback': validation && !valid,
                    'valid-feedback': validation && showValid && valid
                  })}
                  style={
                    (validation && !valid) || (validation && showValid && valid)
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                >
                  {messages[0]}
                </p>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  _renderSelectHospitalModal() {
    const { selectHospitalModal } = this.state;
    const { hospital, onChange } = this.props;
    return (
      <Modal
        isOpen={selectHospitalModal}
        style={{
          width: '50%',
          maxWidth: 'none',
          marginTop: '100px'
        }}
        toggle={() => this._toggleSelectHospitalModal(false)}
        className="modal-primary"
        zIndex="500"
      >
        <ModalHeader toggle={() => this._toggleSelectHospitalModal(false)}>
          {`Add Hospital`}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <DonorHospitalPicker selectedHospital={hospital} selectHospital={onChange} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col lg="2">
            <Button color="primary" onClick={() => this._toggleSelectHospitalModal(false)}>
              Close
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
    );
  }
}
ValidatedHospitalInput.propTypes = {
  hospitalDetails: PropTypes.objectOf(PropTypes.any),
  hospital: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onValidation: PropTypes.func,
  showValid: PropTypes.bool,
  disabled: PropTypes.bool.isRequired
};
ValidatedHospitalInput.defaultProps = {
  hospitalDetails: undefined,
  hospital: undefined,
  validation: false,
  onValidation: () => 'onValidation STUB',
  showValid: false
};

export default ValidatedHospitalInput;
