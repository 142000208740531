import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import Joi from 'joi';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withValidation } from '../../../hoc';
import { useCancellableApiCall } from '../../../hooks';
import { HOSPITAL as EMPTY_HOSPITAL, HOSPITALINCHARGE } from '../../../emptyModels';
import { DISTRICTS_MH } from '../../../districts/districtsMh';
import { PATH_REGISTERREQUESTEDHOSPITAL } from '../../../appConstants';
import ValidatedInput from '../../../components/Custom/ValidatedInput';
import ValidatedSelect from '../../../components/Custom/ValidatedSelect';
import StyledDialogTitle from '../../../components/common/StyledDialogTitle';
import { ReactComponent as GovernmentSvg } from '../../../components/svgs/Government.svg';
import {
  OPTIONS_HOSPITALTYPES,
  REGEXP_MOBILE,
  USERTYPE_HSPTLIC,
  OPTIONS_HOSPITALSTATUS,
  OPTIONS_REQUESTHOSPITALSTATUS,
  VALIDATION,
  REGEXP_ONLYCHARS,
  REGEXP_PINCODE,
  REGEXEXP_NOWHITESPACEATENDANDBEGIN,
  REGEXP_PINCODEVALID,
  REGEXEXP_NOSPACEATENDANDBEGIN,
} from '../../../constants';
import useStyles from '../../../styles/styles';
import { generateSeedWordsAddress } from '../../../utils';
import GreenTagSectionHeader from '../../../components/common/GreenTagSectionHeader';
import LoadableButton from '../../../components/Custom/LoadableButton';

function HospitalProfile(props) {
  const [hospitalDetails, setHospitalDetails] = useState(cloneDeep(EMPTY_HOSPITAL));
  const [requestSubmittedDialogOpenStatus, setRequestSubmittedDialogOpenStatus] = useState(false);
  const { incharges, address } = hospitalDetails;
  const { validation, onValidation } = props;
  const { apiGet, apiPost, apiCallStatuses } = useCancellableApiCall();

  useEffect(() => {
    // apiGet(PATH_EXP_GETHOSPITAL, { hospitalId })
    //   .then((hospitalProfile) => {
    //     setHospitalDetails(hospitalProfile);
    //   })
    //   .catch(() => {});
    // apiGet(PATH_EXP_GETMEDICALINCHARGES, { hospitalId })
    //   .then((hospitalProfile) => {
    //     setMedicalIncharges(hospitalProfile);
    //   })
    //   .catch(() => {});
  }, [apiGet]);

  const classes = useStyles();

  const [submitHospitalRequestApiId, setSubmitHospitalRequestApiId] = useState('APIID');
  const _submitHospitalRequest = async () => {
    const { incharges: inchargesData } = hospitalDetails;
    const { validate } = props;

    const inchargesWithSeedwords = inchargesData.map((incharge) => {
      const { seedWords, address: ethAddress } = generateSeedWordsAddress();
      return { ...incharge, seedWords, ethAddress };
    });

    const { seedWords, address: ethAddress } = generateSeedWordsAddress();
    const hospital = {
      ...hospitalDetails,
      seedWords,
      ethAddress,
      incharges: inchargesWithSeedwords,
    };
    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (valid) => {
      if (!valid) {
        return;
      }
      apiPost(
        PATH_REGISTERREQUESTEDHOSPITAL,
        {
          hospital,
        },
        {
          apiIdCb: (apiId) => {
            setSubmitHospitalRequestApiId(apiId);
          },
          unAuthenticated: true,
        }
      )
        .then(() => {
          window.location.reload();
        })
        .catch(() => {});
    });
  };

  const _renderRequestSubmittedDialog = () => {
    return (
      <Dialog
        open={requestSubmittedDialogOpenStatus}
        onClose={() => {
          setRequestSubmittedDialogOpenStatus(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle>
          <Grid container justify="space-between" alignItems="center">
            Thank you!
          </Grid>
        </StyledDialogTitle>{' '}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Typography
                variant="body1"
                style={{ fontWeight: 600, fontSize: '16px', color: '#34495E', marginTop: '5px' }}
              >
                Request Successfully Submitted.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setRequestSubmittedDialogOpenStatus(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const _isDisabled = () => {
    const { status, requestStatus } = hospitalDetails;
    return !(
      status === OPTIONS_HOSPITALSTATUS.NEW ||
      status === OPTIONS_HOSPITALSTATUS.SUBMITTED ||
      requestStatus === OPTIONS_REQUESTHOSPITALSTATUS.REQUESTED
    );
  };

  const _renderNewHospitalRequestFooterButtons = () => {
    const { status } = hospitalDetails;
    if (status === OPTIONS_HOSPITALSTATUS.NEW) {
      return (
        <Grid>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => {
              _submitHospitalRequest();
            }}
          >
            Submit Request
          </Button> */}
          <LoadableButton
            apiCallId={submitHospitalRequestApiId}
            apiCallStatuses={apiCallStatuses}
            loadingText="Submitting..."
            onClick={() => {
              _submitHospitalRequest();
            }}
            style={{
              fontWeight: 600,
              fontSize: '16px',
            }}
            color="primary"
            variant="contained"
          >
            Submit
          </LoadableButton>
        </Grid>
      );
    }
    return null;
  };

  // const _renderIncharges = () => {
  //   return (
  //     <Grid container spacing={5}>
  //       {incharges.map((i, idx) => (
  //         <>
  //           <Grid item sm={3}>
  //             <ValidatedInput
  //               required
  //               value={i.name}
  //               label="Name"
  //               onChange={(e) => {
  //                 const updatedIncharges = [];
  //                 incharges.forEach((incharge, index) => {
  //                   if (index === idx) {
  //                     updatedIncharges.push({ ...incharge, name: e.target.value });
  //                   } else {
  //                     updatedIncharges.push(incharge);
  //                   }
  //                 });

  //                 setHospitalDetails({
  //                   ...hospitalDetails,
  //                   incharges: [...updatedIncharges],
  //                 });
  //               }}
  //               schema={Joi.string()
  //                 .pattern(REGEXP_ONLYCHARS)
  //                 .message({ 'string.pattern.base': 'Cannot contain numbers' })}
  //               fullWidth
  //               validation={validation}
  //               onValidation={onValidation}
  //             />
  //           </Grid>
  //           <Grid item sm={3}>
  //             <ValidatedInput
  //               required
  //               value={i.email}
  //               label="E-mail id"
  //               onChange={(e) => {
  //                 const updatedIncharges = [];
  //                 incharges.forEach((incharge, index) => {
  //                   if (index === idx) {
  //                     updatedIncharges.push({ ...incharge, email: e.target.value });
  //                   } else {
  //                     updatedIncharges.push(incharge);
  //                   }
  //                 });
  //                 setHospitalDetails({
  //                   ...hospitalDetails,
  //                   incharges: [...updatedIncharges],
  //                 });
  //               }}
  //               schema={Joi.string().email({ tlds: { allow: false } })}
  //               fullWidth
  //               validation={validation}
  //               onValidation={onValidation}
  //             />
  //           </Grid>
  //           <Grid item sm={3}>
  //             <ValidatedSelect
  //               label="UserType"
  //               value={i.userType}
  //               options={Object.values(USERTYPE_HSPTLIC)}
  //               onChange={(e) => {
  //                 const updatedIncharges = [];
  //                 incharges.forEach((incharge, index) => {
  //                   if (index === idx) {
  //                     updatedIncharges.push({ ...incharge, userType: e.value });
  //                   } else {
  //                     updatedIncharges.push(incharge);
  //                   }
  //                 });
  //                 setHospitalDetails({
  //                   ...hospitalDetails,
  //                   incharges: [...updatedIncharges],
  //                 });
  //               }}
  //               validation={validation}
  //               onValidation={onValidation}
  //               schema={Joi.string()}
  //               fullWidth
  //             />
  //           </Grid>
  //           <Grid item sm={3}>
  //             <ValidatedInput
  //               required
  //               id="Contact"
  //               name="Contact"
  //               label="Contact"
  //               value={i.contact}
  //               fullWidth
  //               schema={Joi.string().regex(REGEXP_MOBILE)}
  //               onChange={(e) => {
  //                 const updatedIncharges = [];
  //                 incharges.forEach((incharge, index) => {
  //                   if (index === idx) {
  //                     updatedIncharges.push({ ...incharge, contact: e.target.value });
  //                   } else {
  //                     updatedIncharges.push(incharge);
  //                   }
  //                 });
  //                 setHospitalDetails({
  //                   ...hospitalDetails,
  //                   incharges: [...updatedIncharges],
  //                 });
  //               }}
  //               onValidation={onValidation}
  //               validation={validation}
  //             />
  //           </Grid>
  //         </>
  //       ))}
  //     </Grid>
  //   );
  // };
  const _renderIncharges = (i, index) => {
    const { name, email, userType, contact } = i;
    return (
      <>
        <Grid container spacing={5}>
          <Grid item sm={3} xs={12}>
            <ValidatedInput
              required
              value={name}
              label="Name"
              onChange={(e) => {
                const updatedIncharges = [];
                incharges.forEach((incharge, idx) => {
                  if (idx === index) {
                    updatedIncharges.push({ ...incharge, name: e.target.value });
                  } else {
                    updatedIncharges.push(incharge);
                  }
                });

                setHospitalDetails({
                  ...hospitalDetails,
                  incharges: [...updatedIncharges],
                });
              }}
              schema={Joi.string()
                .pattern(REGEXP_ONLYCHARS)
                .message({ 'string.pattern.base': 'Cannot contain numbers' })
                .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })}
              fullWidth
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <ValidatedInput
              required
              value={email}
              label="E-mail id"
              onChange={(e) => {
                const updatedIncharges = [];
                incharges.forEach((incharge, idx) => {
                  if (idx === index) {
                    updatedIncharges.push({ ...incharge, email: e.target.value });
                  } else {
                    updatedIncharges.push(incharge);
                  }
                });
                setHospitalDetails({
                  ...hospitalDetails,
                  incharges: [...updatedIncharges],
                });
              }}
              schema={Joi.string().email({ tlds: { allow: false } })}
              fullWidth
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <ValidatedSelect
              label="UserType"
              value={userType}
              options={Object.values(USERTYPE_HSPTLIC)}
              onChange={(e) => {
                const updatedIncharges = [];
                incharges.forEach((incharge, idx) => {
                  if (idx === index) {
                    updatedIncharges.push({ ...incharge, userType: e.value });
                  } else {
                    updatedIncharges.push(incharge);
                  }
                });
                setHospitalDetails({
                  ...hospitalDetails,
                  incharges: [...updatedIncharges],
                });
              }}
              validation={validation}
              onValidation={onValidation}
              schema={Joi.string()}
              fullWidth
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <ValidatedInput
              required
              id="Contact"
              name="Contact"
              label="Contact"
              value={contact}
              schema={Joi.string()
                .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })
                .pattern(REGEXP_MOBILE)
                .message({ 'string.pattern.base': 'Should be a 10 digit number' })}
              fullWidth
              onChange={(e) => {
                const updatedIncharges = [];
                incharges.forEach((incharge, idx) => {
                  if (idx === index) {
                    updatedIncharges.push({ ...incharge, contact: e.target.value });
                  } else {
                    updatedIncharges.push(incharge);
                  }
                });
                setHospitalDetails({
                  ...hospitalDetails,
                  incharges: [...updatedIncharges],
                });
              }}
              onValidation={onValidation}
              validation={validation}
            />
          </Grid>
          {index >= 2 ? (
            <Grid item sm={3} xs={2}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                // disabled={isDisabled}
                onClick={() => {
                  const newIncharges = incharges.filter((nr, nrIndex) => nrIndex !== index);
                  setHospitalDetails({
                    ...hospitalDetails,
                    incharges: [...newIncharges],
                  });
                }}
              >
                Delete
              </Button>
            </Grid>
          ) : null}
          <Grid container item sm={12}>
            {/* <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} /> */}
          </Grid>
        </Grid>
      </>
    );
  };

  const addNewHospitalIncharge = () => {
    setHospitalDetails({
      ...hospitalDetails,
      incharges: [...incharges, HOSPITALINCHARGE],
    });
  };

  return (
    <Box style={{ marginTop: 20, marginLeft: '15px', marginRight: '15px' }}>
      {hospitalDetails.status === 'NEW' ? (
        <Paper className={classes.paperTransplantInfo}>
          <Box justify="space-between" display="flex" flexDirection="row">
            <Grid container style={{ padding: 15, flexDirection: 'row' }}>
              <Grid item>
                <Typography
                  variant="h3"
                  style={{ fontSize: '16px', marginBottom: '10px', padding: 10 }}
                >
                  Hospital Registration
                </Typography>
                <Typography
                  style={{
                    fontWeight: 300,
                    fontSize: '14px',
                    lineHeight: '15px',
                  }}
                >
                  Application for Hospital Registration.
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ padding: 15, flexDirection: 'row-reverse' }} spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    height: '36px',
                  }}
                  startIcon={<KeyboardBackspaceIcon />}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      ) : null}
      <Paper>
        <Grid container>
          <Grid container direction="column" className={classes.gridDetails} spacing={2}>
            <Grid container justify="space-between">
              <Grid item xs={10}>
                <GreenTagSectionHeader headerText="REQUEST FOR LOGIN ACCESS CREDENTIALS" />
              </Grid>
              <Grid item sm={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container justify="space-between">
              <Grid item xs={10}>
                <GreenTagSectionHeader headerText="Institute/Hospital Details" />
              </Grid>
            </Grid>

            <Grid container spacing={5} style={{ marginBottom: 10, marginTop: 10 }}>
              <Grid item sm={3}>
                <ValidatedInput
                  required
                  value={hospitalDetails.name}
                  label="Hospital Name"
                  fullWidth
                  disabled={_isDisabled()}
                  onChange={(e) => {
                    setHospitalDetails({
                      ...hospitalDetails,
                      name: e.target.value,
                    });
                  }}
                  schema={Joi.string()
                    // .pattern(REGEXP_ONLYCHARS)
                    // .message({ 'string.pattern.base': 'Allows only alphanumeric values' })
                    .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                    .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })}
                  onValidation={onValidation}
                  validation={validation}
                />
              </Grid>
              <Grid item sm={3}>
                <ValidatedInput
                  required
                  id="nursinghomeregid"
                  value={hospitalDetails.nursingHomeRegnNo}
                  name="nursinghomeregid"
                  label="Nursing Home Registration Id"
                  fullWidth
                  disabled={_isDisabled()}
                  onChange={(e) => {
                    setHospitalDetails({
                      ...hospitalDetails,
                      nursingHomeRegnNo: e.target.value,
                    });
                  }}
                  onValidation={onValidation}
                  validation={validation}
                  schema={Joi.string()
                    // .pattern(REGEXP_ONLYCHARS)
                    // .message({ 'string.pattern.base': 'Allows only alphanumeric values' })
                    .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                    .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })}
                />
              </Grid>
              <Grid item sm={3}>
                <ValidatedInput
                  required
                  value={hospitalDetails.email}
                  label="Email Id"
                  fullWidth
                  disabled={_isDisabled()}
                  onChange={(e) => {
                    setHospitalDetails({
                      ...hospitalDetails,
                      email: e.target.value,
                    });
                  }}
                  schema={Joi.string().email({ tlds: { allow: false } })}
                  onValidation={onValidation}
                  validation={validation}
                />
              </Grid>
              <Grid item sm={3}>
                <ValidatedInput
                  required
                  value={hospitalDetails.address ? hospitalDetails.address.line1 : ' '}
                  label="Address"
                  fullWidth
                  disabled={_isDisabled()}
                  multiline
                  rows={4}
                  onChange={(e) => {
                    setHospitalDetails({
                      ...hospitalDetails,
                      address: {
                        ...address,
                        line1: e.target.value,
                      },
                    });
                  }}
                  onValidation={onValidation}
                  validation={validation}
                  schema={Joi.string()
                    .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                    .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })}
                />
              </Grid>
              <Grid item sm={3}>
                <ValidatedInput
                  required
                  id="pin"
                  value={hospitalDetails.address ? hospitalDetails.address.pinCode : ''}
                  name="pin"
                  label="PIN Code"
                  fullWidth
                  disabled={_isDisabled()}
                  onChange={(e) => {
                    setHospitalDetails({
                      ...hospitalDetails,
                      address: {
                        ...address,
                        pinCode: e.target.value,
                      },
                    });
                  }}
                  schema={Joi.string()
                    .pattern(REGEXP_PINCODE)
                    .message({ 'string.pattern.base': 'Should be a 6 digit number' })
                    .pattern(REGEXP_PINCODEVALID)
                    .message({ 'string.pattern.base': 'Pin code should be valid' })}
                  onValidation={onValidation}
                  validation={validation}
                />
              </Grid>
              <Grid item sm={3}>
                <ValidatedSelect
                  required
                  value={hospitalDetails.address ? hospitalDetails.address.district : ''}
                  label="District"
                  fullWidth
                  disabled={_isDisabled()}
                  options={DISTRICTS_MH}
                  onChange={(e) => {
                    setHospitalDetails({
                      ...hospitalDetails,
                      address: { ...address, district: e.value },
                    });
                  }}
                  onValidation={onValidation}
                  validation={validation}
                />
              </Grid>
              <Grid item sm={3}>
                <ValidatedInput
                  required
                  id="hospitalcontact"
                  value={hospitalDetails.contact}
                  name="hospitalcontact"
                  label="Hospital Contact"
                  fullWidth
                  schema={Joi.number()}
                  disabled={_isDisabled()}
                  onChange={(e) => {
                    setHospitalDetails({
                      ...hospitalDetails,
                      contact: e.target.value,
                    });
                  }}
                  onValidation={onValidation}
                  validation={validation}
                />
              </Grid>
              <Grid item sm={3}>
                <ValidatedSelect
                  required
                  value={hospitalDetails.hospitalTypes}
                  options={Object.values(OPTIONS_HOSPITALTYPES)}
                  label="Hospital Types"
                  multiple={true}
                  disabled={_isDisabled()}
                  onChange={(v) =>
                    setHospitalDetails({
                      ...hospitalDetails,
                      hospitalTypes: v.map((opt) => opt.value),
                    })
                  }
                  fullWidth
                  onValidation={onValidation}
                  validation={validation}
                />
              </Grid>
              <Grid item sm={12}>
                <hr style={{ width: '100%' }} />
              </Grid>
            </Grid>

            <Grid container justify="space-between" style={{ marginBottom: 20, marginTop: 10 }}>
              <Grid item>
                <GreenTagSectionHeader headerText="Institute/Hospital Incharges" />
              </Grid>
              <Grid item style={{ marginTop: 20 }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    height: '40px',
                    lineHeight: '20px',
                    marginTop: '5px',
                  }}
                  onClick={() => {
                    addNewHospitalIncharge();
                  }}
                >
                  + Hospital Incharge
                </Button>
              </Grid>
            </Grid>
            <Grid container>{incharges.map((i, index) => _renderIncharges(i, index))}</Grid>
            <Grid container justify="center" style={{ margin: 20 }}>
              {_renderNewHospitalRequestFooterButtons()}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {_renderRequestSubmittedDialog()}
    </Box>
  );
}

HospitalProfile.propTypes = {
  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
};

export default withValidation(HospitalProfile);
