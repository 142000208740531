export const DISTRICTS_PY = [
  {
    label: 'Karaikal',
    value: 'KARAIKAL'
  },
  {
    label: 'Mahe',
    value: 'MAHE'
  },
  {
    label: 'Puducherry',
    value: 'PUDUCHERRY'
  },
  {
    label: 'Yanam',
    value: 'YANAM'
  }
];
