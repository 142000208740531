import React, { Component } from 'react';
// import { Row, Col, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { ValidatedAttachment, ValidatedInput } from '.';

// eslint-disable-next-line react/prefer-stateless-function
class ValidatedRadioAttachment extends Component {
  render() {
    const {
      name,
      value,
      validation,
      onValidation,
      validateAttachment,
      onRadioChange,
      onAttachmentChange,
      ...other
    } = this.props;
    let attachmentValidationProps = {};
    if (validateAttachment) {
      attachmentValidationProps = {
        validation,
        onValidation,
      };
    }
    const attachmentName = `${name}.attachment`;
    return null;
    // <Row>
    //   <Col lg="4" className="px-0">
    //     <FormGroup check inline className="radio">
    //       <ValidatedInput
    //         className="form-check-input"
    //         type="radio"
    //         id="yes"
    //         name={`${name}.value`}
    //         value="yes"
    //         checked={value.value}
    //         onChange={e => onRadioChange(e.target.name, e.target.checked, e)}
    //         {...other}
    //       />
    //       <Label check className="form-check-label" htmlFor="yes">
    //         Yes
    //       </Label>
    //     </FormGroup>
    //     <FormGroup check inline className="radio">
    //       <ValidatedInput
    //         className="form-check-input"
    //         type="radio"
    //         id="no"
    //         name={`${name}.value`}
    //         value="no"
    //         checked={!value.value}
    //         onChange={e => {
    //           onRadioChange(e.target.name, !e.target.checked, e);
    //           onAttachmentChange(attachmentName, null);
    //         }}
    //         {...other}
    //       />
    //       <Label check className="form-check-label" htmlFor="no">
    //         No
    //       </Label>
    //     </FormGroup>
    //   </Col>
    //   <Col lg="8" className="px-0">
    //     {value.value ? (
    //       <ValidatedAttachment
    //         name={attachmentName}
    //         value={value.attachment}
    //         disabled={!value.value}
    //         onChange={e => onAttachmentChange(e.target.name, e.target.files[0], e)}
    //         {...attachmentValidationProps}
    //       />
    //     ) : null}
    //   </Col>
    // </Row>
  }
}
ValidatedRadioAttachment.propTypes = {
  value: PropTypes.objectOf(PropTypes.any),
  onRadioChange: PropTypes.func,
  onAttachmentChange: PropTypes.func,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onValidation: PropTypes.func,
  validateAttachment: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
ValidatedRadioAttachment.defaultProps = {
  value: undefined,
  onRadioChange: () => 'onRadioChange STUB',
  onAttachmentChange: () => 'onAttachmentChange STUB',
  validation: false,
  onValidation: () => 'onValidation STUB',
  validateAttachment: false,
  name: undefined,
  disabled: false,
};

export default ValidatedRadioAttachment;
