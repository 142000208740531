import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container, Typography } from '@material-ui/core';
import logoOuter from '../../assets/img/brand/logo_outer.png';
import './loader.css';

const LoadingScreen = ({ loadingText = 'Loading...' }) => (
  <Container style={{ height: '100vh' }}>
    <Grid style={{ height: '100%' }} container justify="center" alignItems="center">
      <img
        src={logoOuter}
        alt="logo"
        width="230"
        style={{
          position: 'absolute',
          left: '46%',
          top: '45%',
          transform: 'translate(-50%, -50%)',
        }}
      />
      <div
        className="cssload-ball"
        style={{
          position: 'absolute',
          left: '46%',
          top: '45%',
        }}
      />
      <Typography
        variant="body1"
        style={{
          position: 'absolute',
          top: '45%',
          left: '46%',
          transform: 'translate(-50%, 100px)',
        }}
      >
        <strong>{loadingText}</strong>
      </Typography>
    </Grid>
  </Container>
);
LoadingScreen.propTypes = {
  loadingText: PropTypes.string,
};

LoadingScreen.defaultProps = {
  loadingText: 'Loading...',
};

export default LoadingScreen;
