import * as log from 'loglevel';

const logger = log.getLogger('Constants');

export const ENV = {
  DEV: 'DEV',
  TEST: 'TEST',
  PROD: 'PROD',
};
export const env = ENV[process.env.REACT_APP_ENV] ? ENV[process.env.REACT_APP_ENV] : ENV.PROD;

logger.info(`Using environment: ${env}`);

export const REGEXP_MOBILE = new RegExp('^(0|91)?[6-9][0-9]{9}$');
export const REGEXP_PINCODE = new RegExp('\\b[0-9]{6}\\b');
export const REGEXP_AADHAAR = new RegExp('^[2-9][0-9]{3}[ -]?[0-9]{4}[ -]?[0-9]{4}$');
export const REGEXP_ONLYCHARS = new RegExp('^[a-zA-Z.\\s]*$');
export const REGEXEXP_NOWHITESPACES = new RegExp('/^S*$/');
export const REGEXEXP_NOWHITESPACEATENDANDBEGIN = new RegExp('^(?! ).*[^ ]$');
export const REGEXP_PINCODEVALID = new RegExp('^[1-9][0-9]{5}$');
export const REGEXEXP_NOSPACEATENDANDBEGIN = new RegExp('^[^0-9 ]{1}([a-zA-Z ])+[a-zA-Z]+$');
export const REGEXEXP_ALPHANUMERIC = new RegExp('^[a-zA-Z0-9]*$');
export const REGEXP_ONLYNUMBERS = new RegExp('^[0-9]+([.][0-9]+)?$');
export const REGEXP_VALIDEMAIL = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+');
export const REGEXP_PANCARD = new RegExp('^[A-Z]{5}[0-9]{4}[A-Z]{1}$');
export const REGEXP_DRIVRINGLICENCE = new RegExp('^(([A-Z]{2}[0-9]{2})(-| )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$');
export const REGEXP_BANKACCNO = new RegExp('^[0-9]{9,18}$');
export const REGEXP_RATIONCARD = new RegExp('^[0-9]{10}$');
export const REGEXP_VOTERID = new RegExp('^[A-Z]{3}[0-9]{7}$');
export const REGEXP_BIRTHCERTIFICATE = new RegExp('^[0-9]{10,15}$');
export const REGEXP_ZERO = new RegExp('^(0.[0-9]+|[1-9][0-9]*(.[0-9]+)?)$');
export const REGEXEXP_OTP= new RegExp('^[0-9]{6}$');


export const URL_ETHERSCAN = 'https://etherscan.io/tx/0x77aa3a1839d4fe0a31da9bce662ba06cf268aad504dc9eedd8d753d0b9e6c500';

export const API_AYUDAANEXPRESS = 'AyudaanExpress';
export const API_AYUDAAN = 'AyudaanCore';
export const API_ORGAN = 'AdnOrgan';
export const API_TRANSPLANT = 'AdnTransplant';
export const API_EYEBANK = 'AdnEyeBank';
export const API_TISSUE = 'AdnTissue';
export const API_RESOURCES = 'AdnResources';
export const API_ALLOCATION = 'AdnAllocation';


const CONTEXT_HOSPITAL = `/hospital`;

const CONTEXT_BSD = '/bsd';

const CONTEXT_ORGAN = '/organ';
const CONTEXT_TRANSPLANT = '/tx';


const CONTEXT_RESOURCES = '/resource';
const CONTEXT_FEEDBACK = '/feedback';
export const PATH_RESOURCE_POSTFEEDBACK = `${CONTEXT_RESOURCES + CONTEXT_FEEDBACK}`;
export const PATH_RESOURCE_POSTREACTFEEDBACK = `${CONTEXT_RESOURCES + CONTEXT_FEEDBACK}/reactError`;
export const PATH_GETNOTIFICATIONDETAILS = `${CONTEXT_RESOURCES}/hospital/%s/notificationDetails`;


export const SET_USERATTR = 'SET_USERATTR';
export const SET_LASTLOGIN = 'SET_LASTLOGIN';
export const SET_USER = 'SET_USER';
export const SET_HOSPITAL_AND_USER = 'SET_HOSPITAL_AND_USER';
export const SET_ISDEMO = 'SET_ISDEMO';

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';

export const Loading = function Loading(isLoading, message) {
  this.isLoading = isLoading;
  this.message = message;
};

export const MSG_LOADING_UPLOADINGTOBLOCKCHAIN = 'Uploading to blockchain...';
export const MSG_LOADING_DEFAULT = 'Loading...';
export const MSG_LOADING_FORM12 = 'Uploading Form12 to blockchain';
export const MSG_LOADING_SUBMITTINGSIGN = 'Submitting sign to blockchain';
export const MSG_LOADING_SUBMITTINGAPPROVAL = 'Submitting approval to blockchain';
export const MSG_LOADING_REGISTERREGULATORS = 'Registering Regulators';
export const MSG_LOADING_REGISTERALLOCATORS = 'Registering Allocators';
export const MSG_LOADING_REGISTERHOSPITAL = 'Registering Hospital';
export const MSG_LOADING_SUBMITTINGTRANSPLANT = 'Submitting transplant to blockchain';
export const MSG_LOADING_SUBMITTINGTRANSPLANTAPPROVAL = 'Submitting approval to blockchain';
export const MSG_LOADING_GETTINGDATA = 'Getting Data';
export const MSG_LOADING_REGISTERINSPECTORS = 'Registering Inspectors';
export const MSG_LOADING_SUBMITTINGREPORTSIGN = 'Submitting sign to blockchain';

export const MSG_LOADING_APPROVINGSPECIALIST = 'Approving Specialist';

export const MSG_LOADING_SUBMITTINGDONOR = 'Submitting donor to blockchain';
export const MSG_LOADING_REQUESTINGDONORACCESS = 'Requesting Donor access';
export const MSG_LOADING_GRANTINGDONORACCESS = 'Granting Donor access';
export const MSG_LOADING_DENYINGDONORACCESS = 'Denying Donor access';

export const MSG_LOADING_SUBMITTINGRECIPIENT = 'Submitting recipient to blockchain';
export const MSG_LOADING_REQUESTINGRECIPIENTACCESS = 'Requesting recipient access';
export const MSG_LOADING_GRANTINGRECIPIENTACCESS = 'Granting recipient access';
export const MSG_LOADING_DENYINGRECIPIENTACCESS = 'Denying recipient access';

export const MSG_LOADING_ADDBSDPATIENT = 'Adding Patient';
export const MSG_LOADING_ADDGCSREADING = 'Adding GCS Reading';
export const MSG_LOADING_ADDAPNEATEST = 'Adding Apnea Test';

export const FORMAT_DATE_UNIXTS = 'X';
export const FORMAT_DATE_CREATED = 'DD-MM-YYYY';
export const FORMAT_DATE_VALIDTILL = 'DD-MM-YYYY';
export const FORMAT_DATE_INPUT = 'YYYY-MM-DD';
export const FORMAT_DATE_REPORT = 'DD/MM/YYYY';
export const FORMAT_DATE_MMMYYYY = 'MMM YYYY';

export const VALIDATION = {
  OFF: 0,
  ALL: 1,
  DIRTY: 2,
  INVALIDATE_EMPTY: 4
};

export const REGEX_ILLEGALCHARS = new RegExp('<|>');

export const BLOCKCHAIN_AYUDAAN_EVENTS = {
  HOSPICADDED: {
    label: 'HospitalInchargeAdded',
    value: 'HospitalInchargeAdded'
  },
  USETADDED: {
    label: 'UserAdded',
    value: 'UserAdded'
  },
  REGULATORADDED: {
    label: 'RegulatorAdded',
    value: 'RegulatorAdded'
  },
  INSPECTORADDED: {
    label: 'InspectorAdded',
    value: 'InspectorAdded'
  },
  SPECIALISTADDED: {
    label: 'SpecialistAdded',
    value: 'SpecialistAdded'
  },
  SPECIALISTAPPROVED: {
    label: 'SpecialistApproved',
    value: 'SpecialistApproved'
  },
  HOSPITALREGISTERED: {
    label: 'HospitalRegistered',
    value: 'HospitalRegistered'
  },
  FORM12ADDED: {
    label: 'Form12Added',
    value: 'Form12Added'
  },
  FORM13ADDED: {
    label: 'Form13Added',
    value: 'Form13Added'
  },
  FORM15EBADDED: {
    label: 'Form15EbAdded',
    value: 'Form15EbAdded'
  },
  FORM15CTCADDED: {
    label: 'Form15CtcAdded',
    value: 'Form15CtcAdded'
  },
  FORM15ERCADDED: {
    label: 'Form15ErcAdded',
    value: 'Form15ErcAdded'
  },
  TRANSPLANTADDED: {
    label: 'TransplantAdded',
    value: 'TransplantAdded'
  }
}

export const OPTIONS_DONOR_RELATION = {
  RELATED: { label: 'Related', value: 'RELATED', slctId: 'related' },
  UNRELATED: { label: 'Unrelated', value: 'UNRELATED', slctId: 'unrelated' },
  CADAVER: { label: 'Cadaver', value: 'CADAVER', slctId: 'cadaver' },
  SWAP: { label: 'Swap', value: 'SWAP', slctId: 'swap' }
};

export const DEFAULTVALUE_DONOR_CADAVER = '';
export const DEFAULTVALUE_DONOR = '';
export const DEFAULTVALUE_CADAVER_DONOR_RELATION_WITH_RECPT = 'CADAVER';
export const DEFAULTVALUE_UNRELATED_DONOR_RELATION_WITH_RECPT = 'UNRELATED';

export const HOSPITALTYPES = {
  ORGAN_TXCNTR: 'ORGAN_TXCNTR',
  ORGAN_RETRCNTR: 'ORGAN_RETRCNTR',
  EYE_EYEBANK: 'EYE_EYEBANK',
  EYE_EYERETRCNTR: 'EYE_EYERETRCNTR',
  EYE_CORNEATXCNTR: 'EYE_CORNEATXCNTR',
  TISSUE_TISSUEBANK: 'TISSUE_TISSUEBANK',
};

export const OPTIONS_HOSPITALTYPES = {
  ORGAN_TXCNTR: {
    label: 'Organ Transplant Center',
    value: 'ORGAN_TXCNTR'
  },
  ORGAN_RETRCNTR: {
    label: 'Organ Retrieval Center',
    value: 'ORGAN_RETRCNTR'
  },
  EYE_EYEBANK: {
    label: 'Eye Bank',
    value: 'EYE_EYEBANK'
  },
  EYE_EYERETRCNTR: {
    label: 'Eye Retrieval Center',
    value: 'EYE_EYERETRCNTR'
  },
  EYE_CORNEATXCNTR: {
    label: 'Corneal Transplantation Centre',
    value: 'EYE_CORNEATXCNTR'
  },
  TISSUE_TISSUEBANK: {
    label: 'Tissue Bank',
    value: 'TISSUE_TISSUEBANK'
  }
};

export const HOSPITALTYPE = {
  ADMIN: 'ADMIN',
  DHS: 'DHS',
  INSPECTION: 'INSPECTION',
  ZTCC: 'ZTCC',
  HOSPITAL: 'HOSPITAL',
  DMER: 'DMER',
};

export const OPTIONS_APPROVALSTATUS = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};

export const FORM12_OTHERSUPPORTIVEEXPERTS = {
  nephrologist: 'Nephrologist',
  neurologist: 'Neurologist',
  neuroSurgeon: 'NeuroSurgeon',
  urologist: 'Urologist',
  gISurgeon: 'Gastro-Intestine Surgeon',
  paediatrician: 'Paediatrician',
  physiotherapist: 'Physiotherapist',
  socialWorker: 'Social Worker',
  immunologists: 'Immunologists',
  cardiologist: 'Cardiologist',
  respiratoryPhysician: 'Respiratory Physician',
};

export const FORM13_OTHERSUPPORTIVEEXPERTS = {
  nephrologist: 'Nephrologist',
  neurologist: 'Neurologist',
  neuroSurgeon: 'NeuroSurgeon',
  urologist: 'Urologist',
  gISurgeon: 'Gastro-Intestine Surgeon',
  paediatrician: 'Paediatrician',
  physiotherapist: 'Physiotherapist',
  socialWorker: 'Social Worker',
  immunologists: 'Immunologists',
  cardiologist: 'Cardiologist',
  respiratoryPhysician: 'Respiratory Physician',
};

export const isTestEnv = () => process.env.REACT_APP_ENV === 'TEST'
export const isProdEnv = () => process.env.REACT_APP_ENV === 'PROD'
export const isDevEnv = () => process.env.REACT_APP_ENV === 'DEV'

export const ID_ADMIN = '0';
export const ID_DHS = '1';
export const ID_INSPECTOR = '2';
export const ID_DMER = '4';
export const ID_ROTTO = '6';
export const ID_ZTCC_MUMBAI = '7';
export const ID_ZTCC_PUNE = '8';
export const ID_ZTCC_NAGPUR = '9';
export const ID_ZTCC_AURANGABAD = '10';


export const isOrganTransplantCenter = hospitalTypes =>
  Array.isArray(hospitalTypes) && hospitalTypes.includes(HOSPITALTYPES.ORGAN_TXCNTR);

export const isOrganRetrievalCenter = hospitalTypes =>
  Array.isArray(hospitalTypes) && hospitalTypes.includes(HOSPITALTYPES.ORGAN_RETRCNTR);

export const isEyeBank = hospitalTypes =>
  Array.isArray(hospitalTypes) && hospitalTypes.includes(HOSPITALTYPES.EYE_EYEBANK);

export const isEyeRetrievalCenter = hospitalTypes =>
  Array.isArray(hospitalTypes) && hospitalTypes.includes(HOSPITALTYPES.EYE_EYERETRCNTR);

export const isCorneaTransplantCenter = hospitalTypes =>
  Array.isArray(hospitalTypes) && hospitalTypes.includes(HOSPITALTYPES.EYE_CORNEATXCNTR);

export const isTissueBank = hospitalType => hospitalType.includes(HOSPITALTYPES.TISSUE_TISSUEBANK);

export const ROLE_ADMIN = {
  ADMIN: {
    label: 'Admin',
    value: 'ADMIN',
    id: 0
  },
};

export const isAdminUserType = userType => Object.values(ROLE_ADMIN)
  .filter(
    v => v.value === userType,
  ).length > 0;

export const USERTYPE_DHS = {
  SUPERINTENDENT: {
    label: 'Office Superintendent',
    value: 'DHS_SUPERINTENDENT',
    id: 27,
    context: 'superintendent',
    slctId: 'superintendent'
  },
  INSPECTOR: {
    label: 'Inspector',
    value: 'DHS_INSPECTOR',
    id: 26,
    context: 'inspector',
    slctId: 'inspector'
  },
  ASSTDIRECTOR: {
    label: 'Assistant Director',
    value: 'DHS_ASSTDIRECTOR',
    id: 25,
    context: 'asstDirector',
    slctId: 'asstDirector',
  },
  JNTDIRECTOR: {
    label: 'Joint Director',
    value: 'DHS_JNTDIRECTOR',
    id: 24,
    context: 'jntDirector',
    slctId: 'jntDirector'
  },
  ADDNL_MD: {
    label: 'Additional M.D.',
    value: 'DHS_ADDNLMD',
    id: 23,
    context: 'addnlMd',
    slctId: 'addnlMd',
  },
  DIRECTOR: {
    label: 'Director',
    value: 'DHS_DIRECTOR',
    id: 22,
    context: 'director',
    slctId: 'director'
  },
  COMMISSIONER: {
    label: 'Commissioner',
    value: 'DHS_COMMISSIONER',
    id: 21,
    context: 'commissioner',
    slctId: 'commissioner'
  },
  DDHS: {
    label: 'DdhsUser',
    value: 'DHS_DDHSUSER',
    context: 'ddhsUser',
    slctId: 'ddhsUser'
  },
};
export const SIGNINGROLE_REGN_DHS = USERTYPE_DHS;

export const isDhsUserType = userType => Object.values(USERTYPE_DHS)
  .filter(
    v => v.value === userType,
  ).length > 0;

export const getDhsContext = (userType) => {
  const dhsEntry = Object.values(USERTYPE_DHS).filter(v => v.value === userType)[0];
  return dhsEntry ? dhsEntry.context : '';
};

export const getDhsValue = (userType) => {
  const dhsEntry = Object.values(USERTYPE_DHS).filter(v => v.value === userType)[0];
  return dhsEntry ? dhsEntry.value : '';
};

export const USERTYPE_DMERINCHARGE = {
  INCHARGE: {
    label: 'Incharge',
    value: 'INCHARGE',
    id: 0
  },
};

export const USERTYPE_ROTTOUSER = {
  DIRECTOR: {
    label: 'Director', value: 'ROTTODIRECTOR', context: 'rottoDirector', id: 28,
  },
  JOINTDIRECTOR: {
    label: 'Joint Director', value: 'ROTTOJOINTDIRECTOR', context: 'rottoJointDirector', id: 28,
  },
  ASSTDIRECTOR: {
    label: 'Assistant Director', value: 'ROTTOASSTDIRECTOR', context: 'rottoAsstDirector', id: 28,
  },
  CONSULTANT: {
    label: 'Consultant', value: 'ROTTOCONSULTANT', context: 'rottoConsultant', id: 28,
  },
  STATETXCOORDINATOR: {
    label: 'State Transplant Coordinator', value: 'ROTTOSTATETXCOORDINATOR', context: 'rottoStateTxCoordinator', id: 28,
  },
  TXCOORDINATOR: {
    label: 'Transplant Coordinator', value: 'ROTTOTXCOORDINATOR', context: 'rottoTxCoordinator', id: 28,
  },
  DATAENTRYOPERATOR: {
    label: 'Data Entry Officer',
    value: 'ROTTO_DATA_ENTRY_OFFICER',
    context: 'rottoDataEntryOfficer',
    id: 28,
  },
};

export const USERTYPE_DMER = {
  DIRECTOR: {
    label: 'Director', value: 'DMERDIRECTOR', context: 'dmerDirector', id: 28,
  },
  JOINTDIRECTOR: {
    label: 'Joint Director', value: 'DMERJOINTDIRECTOR', context: 'dmerJointDirector', id: 28,
  },
  ASSTDIRECTOR: {
    label: 'Assistant Director', value: 'DMERASSTDIRECTOR', context: 'dmerAsstDirector', id: 28,
  },
  CONSULTANT: {
    label: 'Consultant', value: 'DMERCONSULTANT', context: 'dmerConsultant', id: 28,
  },
  STATETXCOORDINATOR: {
    label: 'State Transplant Coordinator', value: 'DMERSTATETXCOORDINATOR', context: 'dmerStateTxCoordinator', id: 28,
  },
  // TXCOORDINATOR: {
  //   label: 'Transplant Coordinator', value: 'DMERTXCOORDINATOR', context: 'dmerTxCoordinator', id: 28,
  // },
  // DATAENTRYOPERATOR: {
  //   label: 'Data Entry Officer',
  //   value: 'DMER_DATA_ENTRY_OFFICER',
  //   context: 'dmerDataEntryOfficer',
  //   id: 28,
  // },
};

export const isDmerInchargeUserType = userType => Object.values(USERTYPE_DMERINCHARGE)
  .filter(
    v => v.value === userType,
  ).length > 0;

export const isRottoUserUserType = userType => Object.values(USERTYPE_ROTTOUSER)
  .filter(
    v => v.value === userType,
  ).length > 0;

export const USERTYPE_ZTCC = {
  PRESIDENT: {
    label: 'President',
    value: 'PRESIDENT',
    context: 'president',
    id: 0,
  },
  VICEPRESIDENT: {
    label: 'Vice President',
    value: 'VICEPRESIDENT',
    context: 'vicePresident',
    id: 1,
  },
  SECRETRAY: {
    label: 'Secretary',
    value: 'SECRETARY',
    context: 'secretary',
    id: 2,
  },
  GENSECRETARY: {
    label: 'General Secretary',
    value: 'GENSECRETARY',
    context: 'generalSecretary',
    id: 3,
  },
  JNTSECRETARY: {
    label: 'Joint Secretary',
    value: 'JNTSECRETARY',
    context: 'jointSecretary',
    id: 4,
  },
  CHAIRPERSON: {
    label: 'Chairperson',
    value: 'CHAIRPERSON',
    context: 'chairperson',
    id: 5,
  },
  COCHAIRPERSON: {
    label: 'Co-Chairperson',
    value: 'COCHAIRPERSON',
    context: 'coChairperson',
    id: 6,
  },
  MEMBER: {
    label: 'Member',
    value: 'MEMBER',
    context: 'member',
    id: 7,
  },
  TREASURER: {
    label: 'Treasurer',
    value: 'TREASURER',
    context: 'treasurer',
    id: 8,
  },
  CHIEFTXCOORD: {
    label: 'Chief Transplant Coordinator',
    value: 'CHIEFTXCOORD',
    context: 'chiefTxCoord',
    id: 9,
  },
  DATAENTRYOPERATOR: {
    label: 'Data Entry Officer',
    value: 'DATA_ENTRY_OFFICER',
    context: 'dataEntryOfficer',
    id: 10,
  },
};

export const isZtccUserType = userType => Object.values(USERTYPE_ZTCC)
  .filter(
    v => v.value === userType,
  ).length > 0;

export const isZtccUserTypeSubCommittee = (userType, subCommitteeMember) => Object.values(USERTYPE_ZTCC)
  .filter(
    v => v.value === userType,
  ).length > 0 && subCommitteeMember;


export const USERTYPE_INSPECTOR = {
  MEMBER: {
    label: 'Member',
    value: 'INSP_MEMBER',
    context: 'member',
    id: 2,
  },
  CHAIRPERSON: {
    label: 'Chair Person',
    value: 'INSP_CHAIR',
    context: 'chair',
    id: 1,
  },
};

export const isInspectorUserType = userType => Object.values(USERTYPE_INSPECTOR)
  .filter(
    v => v.value === userType,
  ).length > 0;

export const USERTYPE_HSPTLIC = {
  PRGINCHARGE: {
    label: 'Program Incharge',
    value: 'HOSPITAL_PRGIC',
    context: 'programincharge',
    slctId: 'programincharge'
  },
  CHAIRMAN: {
    label: 'Chairman',
    value: 'HOSPITAL_CHAIRMAN',
    context: 'chairman',
    slctId: 'chairman'
  },
  // PATIENT: {
  //   label: 'Patient',
  //   value: 'HOSPITAL_PATIENT',
  //   context: 'Patient',
  //   slctId: 'Patient'
  // },
};

export const USERTYPE_SOTTO = {
  ALLOCATOR: {
    label: 'Allocator',
    value: 'SOTTO_ALLOCATOR',
    context: 'allocator',
    id: 0,
  },
};


export const isHospitalIcUserType = userType => Object.values(USERTYPE_HSPTLIC)
  .filter(
    v => v.value === userType,
  ).length > 0;

export const isHospChairmanUserType = userType => USERTYPE_HSPTLIC.CHAIRMAN.value === userType
export const isHospPrgIcUserType = userType => USERTYPE_HSPTLIC.PRGINCHARGE.value === userType
export const isPatientAccessUserType = userType => userType === 'HOSPITAL_PATIENT'


export const isSottoUserType = userType => Object.values(USERTYPE_SOTTO)
  .filter(
    v => v.value === userType,
  ).length > 0;


export const isRottoSottoUserType = userType => Object.values(USERTYPE_ROTTOUSER).filter(
  v => v.value === userType,
).length > 0;;

export const USERTYPE_SPECIALIST = 'SPECIALIST';

export const USERTYPE_MEDICALINCHARGE = 'MEDICAL_INCHARGE';

export const USERTYPE_BSD_STAFF = {
  SOCIALWORKER: {
    label: 'Social Worker',
    value: 'BSD_SOCIALWORKER',
    context: 'socialworker',
  },
  SPECIALIST: {
    label: 'Specialist',
    value: 'BSD_SPECIALIST',
    context: 'specialist',
  },
};

export const HOTA_FORMS = {
  FORM12: 'Form12',
  FORM13: 'Form13',
  CERTIFIEDFORM12: 'CertifiedForm12',
  CERTIFIEDFORM13: 'CertifiedForm13',
  FORM14: 'Form14',
  CERTIFIEDFORM14: 'CertifiedForm14',
  FORM15EB: 'Form15Eb',
  FORM15ERC: 'Form15Erc',
  FORM15CTC: 'Form15Ctc',
  CERTIFIEDFORM15EB: 'CertifiedForm15Eb',
  CERTIFIEDFORM15ERC: 'CertifiedForm15Erc',
  CERTIFIEDFORM15CTC: 'CertifiedForm15Ctc',
};

export const OPTIONS_MONTHLYNOTIFICATIONSTATUS = {
  SCHEDULED: 'SCHEDULED',
  SENT: 'SENT',
  CANCELLED: 'CANCELLED',
};

export const isBsdStaffUserType = userType => Object.values(USERTYPE_BSD_STAFF)
  .filter(
    v => v.value === userType,
  ).length > 0;

export const OPTIONS_FORM12STATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  ATTESTED: 'ATTESTED',
  INITIATED: 'INITIATED',
  APPROVED: 'APPROVED',
  REVERTED: 'REVERTED',
  RESUBMITTED: 'RESUBMITTED',
  REATTESTED: 'REATTESTED',
};

export const OPTIONS_SPECIALISTCHANGEREQUESTSTATUS = {
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
  ATTESTED: 'ATTESTED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PROCESSED: 'PROCESSED'
};

export const OPTIONS_FORM13STATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  ATTESTED: 'ATTESTED',
  INITIATED: 'INITIATED',
  APPROVED: 'APPROVED',
  REVERTED: 'REVERTED',
  RESUBMITTED: 'RESUBMITTED',
  REATTESTED: 'REATTESTED',
};

export const STATUS_INSPECTION = {
  UNINITIALISED: 'UNINITIALISED',
  ASSIGNED: 'ASSIGNED',
  SCHEDULED: 'SCHEDULED',
  COMPLETED: 'COMPLETED',
};

export const OPTIONS_TRANSPLANTSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  ATTESTED: 'ATTESTED',
};

export const OPTIONS_HOSPITALSTATUS = {
  NEW: 'NEW',
  SUBMITTED: 'SUBMITTED'
};

export const OPTIONS_REQUESTHOSPITALSTATUS = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};

export const OPTIONS_SPECIALISTFORMSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  PROCESSED: 'PROCESSED',
};

export const OPTIONS_MEDICALINCHARGEFORMSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  PROCESSED: 'PROCESSED',
};

export const OPTIONS_AUTHORISATIONCOMMITTEE = [
//   {
//   label: 'Not Applicable',
//   value: 'NA',
//   slctId: 'NA'
// },
// {
//   label: 'Hospital Committee',
//   value: 'HOSPITAL',
//   slctId: 'hospital'
// },
// {
//   label: 'Local Committee',
//   value: 'LOCAL',
//   slctId: 'local'
// },
// {
//   label: 'State Committee',
//   value: 'STATE',
//   slctId: 'state'
// },
// {
//   label: 'State Govt Authority',
//   value: 'STATEGVT',
//   slctId: 'stategvt'
// },
{
  label: 'Hospital Local Authorisation Committee',
  value: 'HOSPITALLOCALAUTHORISATIONCOMMITTEE',
  slctId: 'hospitallocalauthorisationcommittee'
},
{
  label: 'Hospital Committee',
  value: 'HOSPITAL',
  slctId: 'hospital'
},
{
  label: 'Regional Authorisation Committee',
  value: 'REGIONALAUTHORISATIONCOMMITTEE',
  slctId: 'regionalauthorisationcommittee'
},
{
  label: 'State Authorisation Committee',
  value: 'STATEAUTHORISATIONCOMMITTEE',
  slctId: 'stateauthorisationcommittee'
},
{
  label: 'Other',
  value: 'OTHER',
  slctId: 'other'
},{
  label: 'Not Applicable',
  value: 'NA',
  slctId: 'na'
}];

export const OPTIONS_TXFORMTYPE = [{
  label: 'HOTA Form 1',
  value: 'HOTA_FORM1',
  slctId: 'hotaForm1'
},
{
  label: 'HOTA Form 2',
  value: 'HOTA_FORM2',
  slctId: 'hotaForm2'
},
{
  label: 'HOTA Form 3',
  value: 'HOTA_FORM3',
  slctId: 'hotaForm3'
},
{
  label: 'HOTA Form 4',
  value: 'HOTA_FORM4',
  slctId: 'hotaForm4'
},
{
  label: 'HOTA Form 5',
  value: 'HOTA_FORM5',
  slctId: 'hotaForm5'
},
{
  label: 'HOTA Form 6',
  value: 'HOTA_FORM6',
  slctId: 'hotaForm6'
},
{
  label: 'HOTA Form 7',
  value: 'HOTA_FORM7',
  slctId: 'hotaForm7'
},
{
  label: 'HOTA Form 8',
  value: 'HOTA_FORM8',
  slctId: 'hotaForm8'
},
{
  label: 'HOTA Form 9',
  value: 'HOTA_FORM9',
  slctId: 'hotaForm9'
},
{
  label: 'HOTA Form 10',
  value: 'HOTA_FORM10',
  slctId: 'hotaForm10'
},
{
  label: 'HOTA Form 11',
  value: 'HOTAFORM11',
  slctId: 'hotaForm11'
},
{
  label: 'HOTA Form 18',
  value: 'HOTA_FORM18',
  slctId: 'hotaForm18'
},
{
  label: 'HOTA Form 19',
  value: 'HOTA_FORM19',
  slctId: 'hotaForm19'
},
{
  label: 'HOTA Form 20',
  value: 'HOTA_FORM20',
  slctId: 'hotaForm20'
},
{
  label: 'HOTA Form 21',
  value: 'HOTA_FORM21',
  slctId: 'hotaForm21'
}];

export const OPTIONS_SPECIALISATION = [{
  label: 'Cardiologist',
  value: 'CARDIOLOGIST',
  slctId: 'cardiologist'
},
{
  label: 'Endocrinologist',
  value: 'ENDPCRINOLOGIST',
  slctId: 'endocrinologist'
},
{
  label: 'Hepatologist',
  value: 'HEPATOLOGIST',
  slctId: 'hepatologist'
},
{
  label: 'Nephrologist',
  value: 'NEPHROLOGIST',
  slctId: 'nephrologist'
},
{
  label: 'Urologist',
  value: 'UROLOGIST',
  slctId: 'urologist'
},
{
  label: 'Physician',
  value: 'PHYSICIAN',
  slctId: 'physician'
},
{
  label: 'Neurologist',
  value: 'NEUROLOGIST',
  slctId: 'neurologist'
},
{
  label: 'Neuro Surgeon',
  value: 'NEUROSURGEON',
  slctId: 'neurosurgeon'
},
{
  label: 'Vascular Surgeon',
  value: 'VASCULARSURGEON',
  slctId: 'vascularsurgeon'
},
{
  label: 'Intensivist',
  value: 'INTENSIVIST',
  slctId: 'intensivist'
},
{
  label: 'Gastroenterologist',
  value: 'GASTROENTEROLOGIST',
  slctId: 'gastroenterologist'
},
{
  label: 'GI Surgeon',
  value: 'GISURGEON',
  slctId: 'giSurgeon'
},
{
  label: 'Ophthalmologist',
  value: 'OPHTHALMOLOGIST',
  slctId: 'opthalmologist'
},
{
  label: 'Orthopadecian',
  value: 'ORTHOPADECIAN',
  slctId: 'orthopadecian'
},
{
  label: 'Pathologist',
  value: 'PATHOLOGIST',
  slctId: 'pathologist'
},
{
  label: 'Hepatic Surgeon',
  value: 'HEPATICSURGEON',
  slctId: 'hepaticsurgeon'
},
{
  label: 'Ophthalmic Surgeon',
  value: 'OPHTHALMICSURGEON',
  slctId: 'opthalmicsurgeon'
},
{
  label: 'CVT Surgeon',
  value: 'CVTSURGEON',
  slctId: 'cvtsurgeon'
},
{
  label: 'Chest Physician',
  value: 'CHESTPHYSICIAN',
  slctId: 'chestphysician'
},
{
  label: 'Plastic Surgeon',
  value: 'PLASTICSURGEON',
  slctId: 'plasticsurgeon'
},
{
  label: 'Anaesthetist',
  value: 'ANAESTHETIST',
  slctId: 'anaesthetist'
},
{
  label: 'General Surgeon',
  value: 'GENERALSURGEON',
  slctId: 'generalsurgeon'
},
{
  label: 'Pulmonologist',
  value: 'PULMONOLOGIST',
  slctId: 'pulmonologist'
},
{
  label: 'Gynecologist',
  value: 'GYNECOLOGIST',
  slctId: 'gynecologist'
},
{
  label: 'Pediatrician',
  value: 'PEDIATRICIAN',
  slctId: 'pediatrician'
}
];

export const OPTIONS_MEDICALINCHARGE_DESIGNATION = [{
  label: 'Chairman',
  value: 'CHAIRMAN',
  slctId: 'chairman'
},
{
  label: 'Medical Director',
  value: 'MEDICAL_DIR',
  slctId: 'medicalDir'
},
{
  label: 'Medical Superintendent',
  value: 'MEDICAL_SI',
  slctId: 'medicalSi'
},
{
  label: 'Head of Hospital / CEO',
  value: 'HOSPITALHEAD',
  slctId: 'hospitalHead'
},
];

export const MEDICALINCHARGE_DESIGNATION = [{
  label: 'Chairman',
  value: 'CHAIRMAN',
  slctId: 'chairman'
},
{
  label: 'Medical Director',
  value: 'MEDICAL_DIR',
  slctId: 'medicalDir'
},
{
  label: 'Medical Superintendent',
  value: 'MEDICAL_SI',
  slctId: 'medicalSi'
},
];

export const OPTIONS_YESNO = [{
  label: 'Yes',
  value: true
},
{
  label: 'No',
  value: false
},
]
export const OPTIONS_MEDICOLEGALCASE = [{
  label: 'Police NOC Approved',
  value: 'POLICENOCAPPROVED'
},
{
  label: 'Police NOC Awaited',
  value: 'POLICENOCAWAITED'
},
{
  label: 'Not Done',
  value: 'NOTDONE'
},
]

export const OPTIONS_ALLOCATIONSTATUS = [
//   {
//   label: 'Under Process',
//   value: 'UNDERPROCESS'
// },
{
  label: 'Allocated',
  value: 'ALLOCATED'
},
]

export const OPTIONS_REASON_FOR_NOT_DONATING_ORGANS = [{
  label: 'No Consent',
  value: 'NOCONSENT'
},
{
  label: 'Medically Unfit for Donation',
  value: 'MEDICALLYUNFIT'
},
{
  label: 'Patient got Cardiac Arrest Prior to Donation',
  value: 'CARDIACARREST'
},
{
  label: 'Other Reason',
  value: 'OTHER'
},
]

export const OPTIONS_BSDMEMBERTYPE = {
  MEDICALPRACTITIONER: {
    label: 'MedicalPractitioner',
    value: 'MEDICALPRACTITIONER'
  },
  SURGEON: {
    label: 'Surgeon',
    value: 'SURGEON'
  },
  PHYSICIAN: {
    label: 'Physician',
    value: 'PHYSICIAN'
  }
}

export const OPTIONS_BSDSPECIALISTDESIGNATION = [{
  label: 'Intensivist',
  value: 'BSD_INTENSIVIST'
},
{
  label: 'Neurologist',
  value: 'BSD_NEUROLOGIST'
},
{
  label: 'Surgeon',
  value: 'BSD_SURGEON'
},
{
  label: 'Anaesthetist',
  value: 'BSD_ANAESTHETIST'
},
{
  label: 'Pulmonologist',
  value: 'BSD_PULMONOLOGIST'
},
{
  label: 'General Physician',
  value: 'BSD_GENERALPHYSICIAN'
},
{
  label: 'General Surgeon',
  value: 'BSD_GENERALSURGEON'
},
];

export const TRANSPLANTSTATUS = {
  AUTHORIZED: {
    label: 'Authorized',
    value: 'AUTHORIZED'
  },
  TRANSPLANTED: {
    label: 'Transplanted',
    value: 'TRANSPLANTED'
  }
};

export const NOOFKIDNEYS = {
  ONE: {
    label: 'One',
    value: 'ONE'
  },
  BOTH: {
    label: 'Both',
    value: 'BOTH'
  }
}

export const SIDEOFKIDNEY = {
  LEFT: {
    label: 'Left',
    value: 'LEFTKIDNEY'
  },
  RIGHT: {
    label: 'Right',
    value: 'RIGHTKIDNEY'
  },
  NOTDEFINED: {
    label: 'UNSPECIFIED',
    value: 'NOTSPECIFIED'
  }
}

export const LIVERUSAGE = {
  WHOLE: {
    label: 'Whole',
    value: 'LIVER'
  },
  LEFTLATERALSEGMENT: {
    label: 'Left Lateral Segment',
    value: 'LIVERLEFTLATERALSEGMENT'
  },
  LEFTLATERALSECTIONECTOMY: {
    label: 'Left Lateral Sectionectomy',
    value: 'LEFTLATERALSECTIONECTOMY'
  },
  LEFTHEPATECTOMY: {
    label: 'Left Hepatectomy',
    value: 'LIVERHEPATECTOMY'
  },
  LEFTEXTENDEDHEPATECTOMY: {
    label: 'Left Extended Hepatectomy',
    value: 'LIVERLEFTEXTENDEDHEPATECTOMY'
  },
  RIGHTEXTENDEDLOBE: {
    label: 'Right Extended Lobe',
    value: 'LIVERRIGHTEXTENDEDLOBE'
  },
  RIGHTEXTENDEDHEPATECTOMY: {
    label: 'Right Extended Hepatectomy',
    value: 'LIVERRIGHTEXTENDEDHEPATECTOMY'
  },
  RIGHTHEPATECTOMYWITHOUTMHV: {
    label: 'Right Hepatectomy without MHV',
    value: 'LIVERRIGHTHEPATECTOMYWITHOUTMHV'
  },
  RIGHTHEPATECTOMYWITHMHV: {
    label: 'Right Hepatectomy with MHV',
    value: 'LIVERRIGHTHEPATECTOMYWITHMHV'
  },
  RIGHTPOSTERIORSECTIONECTOMY: {
    label: 'Right Posterior Sectionectomy',
    value: 'LIVERRIGHTPOSTERIORSECTIONECTOMY'
  }
}

export const URGENCY = {
  REGULAR: {
    label: 'Regular',
    value: 'REGULAR'
  },
  URGENT: {
    label: 'Urgent',
    value: 'URGENT'
  },
  SUPERURGENT: {
    label: 'SuperUrgent',
    value: 'SUPERURGENT'
  }
}


export const OPTIONS_DONORFORMTYPE = [{
  label: 'Donor Form 1',
  value: 'DONOR_FORM1',
  slctId: 'donorForm1'
},
{
  label: 'Donor Form 2',
  value: 'DONOR_FORM2',
  slctId: 'donorForm2'
},
{
  label: 'Donor Form 3',
  value: 'DONOR_FORM3',
  slctId: 'donorForm3'
}];



export const ORGAN = {
  HEART: {
    label: 'Heart',
    value: 'HEART',
    slctId: 'heart'
  },
  LUNGS: {
    label: 'Lungs',
    value: 'LUNGS',
    slctId: 'lungs'
  },
  LIVER: {
    label: 'Liver',
    value: 'LIVER',
    slctId: 'liver'
  },
  KIDNEY: {
    label: 'Kidney',
    value: 'KIDNEY',
    slctId: 'kidney'
  },
  HEARTLUNGS: {
    label: 'HeartLungs',
    value: 'HEARTLUNGS',
    slctId: 'heartlungs'
  },
  INTESTINE: {
    label: 'Intestine',
    value: 'INTESTINE',
    slctId: 'intestine'
  },
  PANCREAS: {
    label: 'Pancreas',
    value: 'PANCREAS',
    slctId: 'pancreas'
  },
  HAND: {
    label: 'Hand',
    value: 'HAND',
    slctId: 'hand'
  },
  PANCREASANDINTESTINE: {
    label: 'Pancreas & Intestine',
    value: 'PANCREASANDINTESTINE',
    slctId: 'pancreasAndIntestine'
  },
  KIDNEYANDLIVER: {
    label: 'Kidney & Liver',
    value: 'KIDNEYANDLIVER',
    slctId: 'kidneyAndLiver'
  },
  KIDNEYANDPANCREAS: {
    label: 'Kidney & Pancreas',
    value: 'KIDNEYANDPANCREAS',
    slctId: 'kidneyAndPancreas'
  },
  LIVERANDPANCREAS: {
    label: 'Liver & Pancreas',
    value: 'LIVERANDPANCREAS',
    slctId: 'liverAndPancreas'
  },
  SMALLBOWEL: {
    label: 'Small Bowel',
    value: 'SMALLBOWEL',
    slctId: 'smallBowel'
  },
  UTERUS: {
    label: 'Uterus',
    value: 'UTERUS',
    slctId: 'uterus'
  },
  OTHER: {
    label: 'Other',
    value: 'OTHER',
    slctId: 'other'
  },
};


export const BSDORGAN = {
  HEART: {
    label: 'Heart',
    value: 'HEART',
    slctId: 'heart'
  },
  LEFTKIDNEY: {
    label: 'Left Kidney',
    value: 'LEFTKIDNEY',
    slctId: 'leftKidney'
  },
  RIGHTKIDNEY: {
    label: 'Right Kidney',
    value: 'RIGHTKIDNEY',
    slctId: 'rightKidney'
  },
  LUNGS: {
    label: 'Lungs',
    value: 'LUNGS',
    slctId: 'lungs'
  },
  LIVER: {
    label: 'Liver',
    value: 'LIVER',
    slctId: 'liver'
  },
  LIVERRIGHTEXTENDEDLOBE: {
    label: 'Liver Right Extended Lobe',
    value: 'LIVERRIGHTEXTENDEDLOBE',
    slctId: 'liverRightExtendedLobe'
  },
  LIVERLEFTLATERALSEGMENT: {
    label: 'Liver Left Lateral Segment',
    value: 'LIVERLEFTLATERALSEGMENT',
    slctId: 'liverLeftLateralSegment'
  },
  // LEFTLATERALSEGMENT: {
  //   label: 'Left Lateral Segment',
  //   value: 'LIVERLEFTLATERALSEGMENT'
  // },
  LEFTLATERALSECTIONECTOMY: {
    label: 'Left Lateral Sectionectomy',
    value: 'LEFTLATERALSECTIONECTOMY'
  },
  LIVERHEPATECTOMY: {
    label: 'Liver Left Hepatectomy',
    value: 'LIVERHEPATECTOMY'
  },
  LIVERLEFTEXTENDEDHEPATECTOMY: {
    label: 'Liver Left Extended Hepatectomy',
    value: 'LIVERLEFTEXTENDEDHEPATECTOMY'
  },
  // RIGHTEXTENDEDLOBE: {
  //   label: 'Right Extended Lobe',
  //   value: 'LIVERRIGHTEXTENDEDLOBE'
  // },
  LIVERRIGHTEXTENDEDHEPATECTOMY: {
    label: 'Liver Right Extended Hepatectomy',
    value: 'LIVERRIGHTEXTENDEDHEPATECTOMY'
  },
  LIVERRIGHTHEPATECTOMYWITHOUTMHV: {
    label: 'Liver Right Hepatectomy without MHV',
    value: 'LIVERRIGHTHEPATECTOMYWITHOUTMHV'
  },
  LIVERRIGHTHEPATECTOMYWITHMHV: {
    label: 'Liver Right Hepatectomy with MHV',
    value: 'LIVERRIGHTHEPATECTOMYWITHMHV'
  },
  LIVERRIGHTPOSTERIORSECTIONECTOMY: {
    label: 'Liver Right Posterior Sectionectomy',
    value: 'LIVERRIGHTPOSTERIORSECTIONECTOMY'
  },
  HEARTLUNGS: {
    label: 'HeartLungs',
    value: 'HEARTLUNGS',
    slctId: 'heartlungs'
  },
  INTESTINE: {
    label: 'Intestine',
    value: 'INTESTINE',
    slctId: 'intestine'
  },
  PANCREAS: {
    label: 'Pancreas',
    value: 'PANCREAS',
    slctId: 'pancreas'
  },
  HAND: {
    label: 'Hand',
    value: 'HAND',
    slctId: 'hand'
  },
  UTERUS: {
    label: 'Uterus',
    value: 'UTERUS',
    slctId: 'uterus'
  },
  OTHER: {
    label: 'Other',
    value: 'OTHER',
    slctId: 'other'
  },
  NONE: {
    label: 'No Organs Donated',
    value: 'NONE',
    slctId: 'none'
  },
};

export const TISSUE = {
  CORNEA: {
    label: 'Cornea',
    value: 'CORNEA',
    slctId: 'cornea'
  },
  
  SKIN: {
    label: 'Skin',
    value: 'SKIN',
    slctId: 'skin'
  },
  
  BONES: {
    label: 'Bones',
    value: 'BONES',
    slctId: 'bones'
  },
  HEARTVALVES: {
    label: 'Heart Valves',
    value: 'HEARTVALVES',
    slctId: 'heartvalves'
  },
  BLOODVESSELS: {
    label: 'Blood Vessels',
    value: 'BLOODVESSELS',
    slctId: 'bloodvessels'
  },
  NONE: {
    label: 'No Tissues Donated',
    value: 'NONE',
    slctId: 'none'
  },
};

export const STATUS_FORM12_ISSUES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  RESOLVED: 'RESOLVED',
};

export const STATUS_FORM13_ISSUES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  RESOLVED: 'RESOLVED',
};

export const STATUS_FORM15_ISSUES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  RESOLVED: 'RESOLVED',
};

export const OPTIONS_GVTPVT = [{
  label: 'Government',
  value: 'GVT'
},
{
  label: 'Private',
  value: 'PVT'
},
{
  label: 'Voluntary',
  value: 'VOLNTRY'
},
];

export const OPTIONS_TEACHING = [{
  label: 'Teaching',
  value: 'TEACHING'
},
{
  label: 'Non-Teaching',
  value: 'NONTEACHING'
},
];

export const OPTIONS_TRANSPLANTCOORDDOCTYPE = [{
  label: 'Graduate Certificate',
  value: 'UG',
  slctId: 'ug'
}, {
  label: 'Training Certificate',
  value: 'TRAININGCERT',
  slctId: 'trainingcertificate'
},
{
  label: 'CV',
  value: 'CV',
  slctId: 'cv'
}
];

export const RECIPIENT_HOSPITALTYPE = [{
  label: 'Inhouse',
  value: 'INHOUSE',
  slctId: 'inhouse'
}, {
  label: 'Global',
  value: 'GLOBAL',
  slctId: 'global'
},
];

export const TRAINEDPERSON_TYPE = {
  SURGEON: {
    label: 'Surgeon',
    value: 'SURGEON'
  },
  PHYSICIAN: {
    label: 'Physician',
    value: 'PHYSICIAN'
  },
  ANAESTHETIST: {
    label: 'Anaesthetist',
    value: 'ANAESTHETIST'
  },
};

export const NEAR_RELATION_TYPE = {
  FATHER: {
    label: `Father`,
    value: 'FN',
    id: 0,
    slctId: 'father'
  },
  MOTHER: {
    label: `Mother`,
    value: 'MN',
    id: 1,
    slctId: 'mother'
  },
  HUSBAND: {
    label: `Husband`,
    value: 'HN',
    id: 3,
    slctId: 'husband'
  },
  WIFE: {
    label: `Wife`,
    value: 'WN',
    id: 4,
    slctId: 'wife'
  },
  Son: {
    label: `Son`,
    value: 'SN',
    id: 4,
    slctId: 'wife'
  },
  DAUGHTER: {
    label: `Daugther`,
    value: 'DN',
    id: 4,
    slctId: 'wife'
  },
};

export const RELATIONS = [
  {
    label: 'Father',
    value: 'FATHER',
  },
  {
    label: 'Mother',
    value: 'MOTHER',
  },

  {
    label: 'Husband',
    value: 'HUSBAND',
  },
  {
    label: 'Wife',
    value: 'WIFE',
  },
  {
    label: 'Son',
    value: 'SON',
  },
  {
    label: 'Daughter',
    value: 'DAUGHTER',
  },
  {
    label: 'Brother',
    value: 'BROTHER',
  },
  {
    label: 'Sister',
    value: 'SISTER',
  },
  {
    label: 'Father-in-law',
    value: 'FATHERINLAW',
  },
  {
    label: 'Mother-in-law',
    value: 'MOTHERINLAW',
  },
  {
    label: 'Brother-in-law',
    value: 'BROTHERINLAW',
  },
  {
    label: 'Sister-in-law',
    value: 'SISTERINLAW',
  },
  {
    label: 'Step Father',
    value: 'STEPFATHER',
  },
  {
    label: 'Step Mother',
    value: 'STEPMOTHER',
  },
  {
    label: 'Step Daughter',
    value: 'STEPDAUGHTER',
  },
  {
    label: 'Step Son',
    value: 'STEPSON',
  },
  {
    label: 'Step Brother',
    value: 'STEPBROTHER',
  },
  {
    label: 'Step Sister',
    value: 'STEPSISTER',
  },
  {
    label: 'Maternal Cousin Brother',
    value: 'MATERNALCOUSINBROTHER',
  },
  {
    label: 'Maternal Cousin Sister',
    value: 'MATERNALCOUSINSISTER',
  },
  {
    label: 'Maternal Uncle',
    value: 'MATERNALUNCLE',
  },
  {
    label: 'Maternal Aunty',
    value: 'MATERNALAUNTY',
  },
  {
    label: 'Paternal Cousin Brother',
    value: 'PATERNALCOUSINBROTHER',
  },
  {
    label: 'Paternal Cousin Sister',
    value: 'PATERNALCOUSINSISTER',
  },
  {
    label: 'Paternal Uncle',
    value: 'PATERNALUNCLE',
  },
  {
    label: 'Paternal Aunty',
    value: 'PATERNALAUNTY',
  },
  {
    label: 'Paternal Grandmother',
    value: 'PATERNALGRANDMOTHER',
  },
  {
    label: 'Paternal Grandfather',
    value: 'PATERNALGRANDFATHER',
  },
  {
    label: 'Maternal Grandmother',
    value: 'MATERANALGRANDMOTHER',
  },
  {
    label: 'Maternal Grandfather',
    value: 'MATERNALGRANDFATHER',
  },
  {
    label: 'Wife-Divorced',
    value: 'WIFEDIVORCED',
  },
  {
    label: 'Husband-Divorced',
    value: 'HUSBANDDIVORCED',
  },
  {
    label: 'Nephew',
    value: 'NEPHEW',
  },
  {
    label: 'Niece',
    value: 'NIECE',
  },
  {
    label: 'Daughter-In-Law',
    value: 'DAUGHTERINLAW',
  },
  {
    label: 'Son-In-Law',
    value: 'SONINLAW',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const IDENTITY_CARD_TYPE = [
  {
    label: 'PAN Card',
    value: 'PANCARD',
  },
  {
    label: 'Driving License',
    value: 'DRIVINGLICENSE',
  },
  {
    label: 'Aadhaar',
    value: 'AADHAR',
  },
  {
    label: 'Bank Passbook',
    value: 'BANKPASSBOOK',
  },
  {
    label: 'Ration Card',
    value: 'RATIONCARD',
  },
  {
    label: 'Voter Id',
    value: 'VOTERID',
  },
  {
    label: 'Passport',
    value: 'PASSPORT',
  },
  {
    label: 'Birth Certificate',
    value: 'BIRTHCERT',
  },
  {
    label: 'Marriage Certificate',
    value: 'MARRIAGE_CERTIFICATE',
  },
  {
    label: 'Academic Certificate',
    value: 'ACADEMIC_CERTIFICATE',
  },
  {
    label: 'School Leaving Certificate',
    value: 'SCHOOL_LEAVING_CERTIFICATE',
  },
  {
    label: 'Maintenance Bill',
    value: 'MAINTENANCE_BILL',
  },
  {
    label: 'Light Bill',
    value: 'LIGHT_BILL',
  },
  {
    label: 'Land/Property Document',
    value: 'LAND_PROPERTY_DOCUMENT',
  },
];

export const RELIGION = [
  {
    label: 'Buddhism',
    value: 'BUDDHISM',
  },
  {
    label: 'Christianity',
    value: 'CHRISTIANITY',
  },
  {
    label: 'Hinduism',
    value: 'HINDUISM',
  },
  {
    label: 'Islam',
    value: 'ISLAM',
  },
  {
    label: 'Jainism',
    value: 'JAINISM',
  },

  {
    label: 'Judaism',
    value: 'JUDAISM',
  },
  {
    label: 'Sikhism',
    value: 'SIKHISM',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const ETIOLOGY = [
  { label: 'Alcohol', value: 'ALCOHOL' },
  { label: 'Hepatitis A', value: 'HEPATITIS_A' },
  { label: 'Hepatitis B', value: 'HEPATITIS_B' },
  { label: 'Hepatitis C', value: 'HEPATITIS_C' },
  { label: 'Hepatitis E', value: 'HEPATITIS_E' },
  { label: 'Autoimmune Hepatitis', value: 'AUTOIMMUNE_HEPATITIS' },
  { label: 'PBC', value: 'PBC' },
  { label: 'PSC', value: 'PSC' },
  { label: 'HVOTO', value: 'HVOTO' },
  { label: 'Toxin (Yellow Phosporus)', value: 'TOXIN' },
  { label: 'Cryptogenic', value: 'CRYPTOGENIC' },
  { label: 'HCC', value: 'HCC' },
  { label: 'NET', value: 'NET' },
  { label: 'Cholangio Ca', value: 'CHOLANGIO_CA' },
  { label: 'Bilany Atresia', value: 'BILANY_ATRESIA' },
  { label: 'PFIC 1', value: 'PFIC1' },
  { label: 'PFIC 2', value: 'PFIC2' },
  { label: 'Bile Duct Synthetic Defect', value: 'BILE_DUCT_SYNTHETIC_DEFECT' },
  {
    label: 'Calycogen Storage Disease Type IV',
    value: 'CALYCOGEN_STORAGE_DISEASE_TYPE_IV',
  },
  { label: 'Alagille Syndrome', value: 'ALAGILLE_SYNDROME' },
  { label: 'Primary Hyperoxaluria', value: 'PRIMARY_HYPEROXALURIA' },
  { label: 'Urea Cycle Defect', value: 'UREA_CYCLE_DEFECT' },
  { label: 'Organic Acidemia', value: 'ORGANIC_ACIDEMIA' },
  { label: 'Crigler-Najjar syndrome', value: 'CRIGGLER_NAJAR_SYNDROME' },
  { label: 'NASH', value: 'NASH' },
  { label: 'Other', value: 'OTHER' },
];

export const SEX = {
  MALE: {
    label: 'Female',
    value: 'F',
    id: 0,
    slctId: 'female'
  },
  FEMALE: {
    label: 'Male',
    value: 'M',
    id: 1,
    slctId: 'male'
  },
  OTHER: {
    label: 'Other',
    value: 'O',
    id: 2,
    slctId: 'other'
  },
};

export const DISCHARGESTATUS = {
  DISCHARGED: {
    label: 'Discharged',
    value: true
  },
  NOTDISCHARGED: {
    label: 'Not Discharged',
    value: false
  }
}

export const OPTIONS_DONOR_RELATIONSHIP_FOR_REALTED = {
  WIFE: {
    label: 'Wife',
    value: 'WIFE'
  },
  HUSBAND: {
    label: 'Husband',
    value: 'HUSBAND'
  },
  DAUGHTER: {
    label: 'Daughter',
    value: 'DAUGHTER'
  },
  SON: {
    label: 'Son',
    value: 'SON'
  },
  MOTHER: {
    label: 'Mother',
    value: 'MOTHER'
  },
  FATHER: {
    label: 'Father',
    value: 'FATHER'
  },
  SISTER: {
    label: 'Sister',
    value: 'SISTER'
  },
  BROTHER: {
    label: 'Brother',
    value: 'BROTHER'
  },
  GRANDDAUGHTER: {
    label: 'Grand Daughter',
    value: 'GRANDDAUGHTER'
  },
  GRANDSON: {
    label: 'Grand Son',
    value: 'GRANDSON'
  },
  GRANDMOTHER: {
    label: 'Grand Mother',
    value: 'GRANDMOTHER'
  },
  GRANDFATHER: {
    label: 'Grand Father',
    value: 'GRANDFATHER'
  },
  OTHER: {
    label: 'Other',
    value: 'OTHER'
  }
}

export const OPTIONS_DONOR_RELATIONSHIP_FOR_UNREALTED = {
  FRIEND: {
    label: 'Friend',
    value: 'FRIEND'
  },
  OTHER: {
    label: 'Other',
    value: 'OTHER'
  }
}

export const TRANSPLANTED_RECIPIENTSTATUS = {
  ALIVE: {
    label: 'Alive',
    value: 'ALIVE'
  },
  EXPIRED: {
    label: 'Expired',
    value: 'EXPIRED'
  }
}

export const DONOR_HOSPITAL = {
  INHOUSE: {
    label: 'Inhouse',
    value: 'INHOUSE'
  },
  OTHER: {
    label: 'Other (within Maharashtra)',
    value: 'OTHER'
  },
  OTHER_OUT_OF_MAHARASHTRA: {
    label: 'Other (out of Maharashtra)',
    value: 'OTHER_STATE'
  }
}

export const RECIPIENT_HOSPITAL = {
  INHOUSE: {
    label: 'Inhouse',
    value: 'INHOUSE'
  },
  OTHER: {
    label: 'Other (Within Maharashtra)',
    value: 'OTHER'
  },
  OTHERSTATE: {
    label: 'Other (Out of Maharashtra)',
    value: 'OUT_OF_MAHARASHTRA'
  }
}

export const RECIPIENT_HOSPITALFORLIVER = {
  INHOUSE: {
    label: 'Inhouse',
    value: 'INHOUSE'
  },
  OTHER: {
    label: 'Other',
    value: 'OTHER'
  },
  INHOUSEANDOTHER: {
    label: 'Inhouse & Other',
    value: 'INHOUSEANDOTHER'
  }
}

export const BLOODGROUP_ALL = {
  ALL: {
    label: 'All Blood Groups',
    value: 'ALL',
    id: 8,
    slctId: 'All'
  },
  OP: {
    label: 'O +ve',
    value: 'OP',
    id: 0,
    slctId: 'Opositive'
  },
  ON: {
    label: 'O -ve',
    value: 'ON',
    id: 1,
    slctId: 'Onegative'
  },
  AP: {
    label: 'A +ve',
    value: 'AP',
    id: 2,
    slctId: 'Apositive'
  },
  AN: {
    label: 'A -ve',
    value: 'AN',
    id: 3,
    slctId: 'Anegative'
  },
  BP: {
    label: 'B +ve',
    value: 'BP',
    id: 4,
    slctId: 'Bpositive'
  },
  BN: {
    label: 'B -ve',
    value: 'BN',
    id: 5,
    slctId: 'Bnegative'
  },
  ABP: {
    label: 'AB +ve',
    value: 'ABP',
    id: 6,
    slctId: 'ABpositive'
  },
  ABN: {
    label: 'AB -ve',
    value: 'ABN',
    id: 7,
    slctId: 'ABnegative'
  },
};

export const BLOODGROUP = {
  OP: {
    label: 'O +ve',
    value: 'OP',
    id: 0,
    slctId: 'Opositive'
  },
  ON: {
    label: 'O -ve',
    value: 'ON',
    id: 1,
    slctId: 'Onegative'
  },
  AP: {
    label: 'A +ve',
    value: 'AP',
    id: 2,
    slctId: 'Apositive'
  },
  AN: {
    label: 'A -ve',
    value: 'AN',
    id: 3,
    slctId: 'Anegative'
  },
  BP: {
    label: 'B +ve',
    value: 'BP',
    id: 4,
    slctId: 'Bpositive'
  },
  BN: {
    label: 'B -ve',
    value: 'BN',
    id: 5,
    slctId: 'Bnegative'
  },
  ABP: {
    label: 'AB +ve',
    value: 'ABP',
    id: 6,
    slctId: 'ABpositive'
  },
  ABN: {
    label: 'AB -ve',
    value: 'ABN',
    id: 7,
    slctId: 'ABnegative'
  },
  A: {
    label: 'A',
    value: 'A',
    id: 8,
    slctId: 'A'
  },
  B: {
    label: 'B',
    value: 'B',
    id: 9,
    slctId: 'B'
  },
  AB: {
    label: 'AB',
    value: 'AB',
    id: 7,
    slctId: 'AB'
  },
  O: {
    label: 'O',
    value: 'O',
    id: 7,
    slctId: 'O'
  },
};

export const OPTIONS_BSD_GCS_EYES = [{
  label: 'Spontaneous',
  value: 'E4'
},
{
  label: 'To sound',
  value: 'E3'
},
{
  label: 'To pressure',
  value: 'E2'
},
{
  label: 'None',
  value: 'E1'
}
]

export const OPTIONS_BSD_GCS_VERBAL = [{
  label: 'Oriented',
  value: 'V5'
},
{
  label: 'Confused',
  value: 'V4'
},
{
  label: 'Words',
  value: 'V3'
},
{
  label: 'Sounds',
  value: 'V2'
},
{
  label: 'None',
  value: 'V1'
},
]

export const OPTIONS_BSD_GCS_MOTOR = [{
  label: 'Obey commands',
  value: 'M6'
},
{
  label: 'Localising',
  value: 'M5'
},
{
  label: 'Normal flexion',
  value: 'M4'
},
{
  label: 'Abnormal flexion',
  value: 'M3'
},
{
  label: 'Extension',
  value: 'M2'
},
{
  label: 'None',
  value: 'M1'
}
]

export const OPTIONS_SPECIALISTAPPROVALSTATUS = {
  APPROVED: {
    label: 'Approved',
    value: 'APPROVED'
  },
  PENDING: {
    label: 'Pending',
    value: 'PENDING'
  }
};

export const OPTIONS_BSD_OPDFORM_VISITTYPE = [{
  label: 'OP',
  value: 'OP',
}];

export const OPTIONS_BSD_OPDFORM_MOA = [{
  label: 'Ambulatory',
  value: 'AMBULATORY',
}, {
  label: 'Wheelchair',
  value: 'WHEELCHAIR',
}, {
  label: 'Stretcher',
  value: 'STRETCHER',
}];

export const OPTIONS_BSD_OPDFORM_TRANSFERREDFROM = [{
  label: 'Hospital',
  value: 'HOSPITAL',
}, {
  label: 'Scene',
  value: 'SCENE',
}, {
  label: 'Home',
  value: 'HOME',
}, {
  label: 'Other',
  value: 'OTHER',
}];

export const OPTIONS_BSD_OPDFORM_SOURCEOFHISTORY = [{
  label: 'Patient',
  value: 'PATIENT',
}, {
  label: 'Relative',
  value: 'RELATIVE',
}, {
  label: 'Others',
  value: 'OTHERS',
}];

export const OPTIONS_BSD_OPDFORM_PASTHX = [{
  label: 'Nil',
  value: 'NIL',
}, {
  label: 'DM',
  value: 'DM',
}, {
  label: 'HTN',
  value: 'HTN',
}, {
  label: 'Asthma',
  value: 'ASTHMA',
}, {
  label: 'CAD',
  value: 'CAD',
}, {
  label: 'CVA',
  value: 'CVA',
}, {
  label: 'COPD',
  value: 'COPD',
}, {
  label: 'Others',
  value: 'OTHERS',
}];

export const OPTIONS_BSD_OPDFORM_NUTRSCREENING = [{
  label: 'Malnurished',
  value: 'MALNURISHED',
}, {
  label: 'Properly Nurished',
  value: 'PROPERLYNURISHED',
}, {
  label: 'Obese',
  value: 'OBESE',
}];

export const OPTIONS_BSD_OPDFORM_SEX = [{
  label: 'Male',
  value: 'M',
}, {
  label: 'Female',
  value: 'F',
}, {
  label: 'Other',
  value: 'O',
}];

export const OPTIONS_SPECIALIST_DOCTYPE = [{
  label: 'MBBS Degree Certificate',
  value: 'MBBS',
}, {
  label: 'PG Degree Certificate',
  value: 'PG',
},
{
  // TODO move the isDisabled logic to jsx
  // when the specialist files are merged
  label: 'MMC Certificate Of Registration (MBBS)',
  value: 'MMCREGN',
  isDisabled: true
}, {
  label: 'MMC Additional Med Qualification Regn Cert',
  value: 'ADDNLQUALIFICATION',
},
{
  // TODO move the isDisabled logic to jsx
  // when the specialist files are merged
  label: 'MMC Registration Renewal',
  value: 'MMC',
  isDisabled: true
},
{
  label: 'Experience Certificate',
  value: 'EXPERIENCECERT',
}, {
  label: 'Other',
  value: 'OTHER'
}];

export const OPTIONS_MEDICALINCHARGE_DOCTYPE = [{
  label: 'MBBS Certificate',
  value: 'MBBS'
}, {
  label: 'PG Certificate',
  value: 'PG'
}, {
  label: 'MBBS Registration',
  value: 'MBBSREGN'
}, {
  label: 'Additional Qualification',
  value: 'ADDNLQUALIFICATION'
}, {
  label: 'Experience Certificate',
  value: 'EXPERIENCECERT'
}, {
  label: 'Other',
  value: 'OTHER'
}, {
  // TODO move the isDisabled logic to jsx
  // when the specialist files are merged
  label: 'MMC Registration Renewal',
  value: 'MMC',
  isDisabled: true
}];


export const OPTIONS_SPECIALIST_ATTESTEDBY = [{
  label: 'Gazetted Officer',
  value: 'GAZETTEDOFFICER',
  slctId: 'gazettedofficer'
}, {
  label: 'Self',
  value: 'SELF',
  slctId: 'self'
}, {
  label: 'CEO',
  value: 'CEO',
  slctId: 'ceo'
}, {
  label: 'HR Department',
  value: 'HRDEPT',
  slctId: 'hrdept'
}];

export const OPTIONS_MEDICALINCHARGE_ATTESTEDBY = [{
  label: 'Gazetted Officer',
  value: 'GAZETTEDOFFICER',
  slctId: 'gazettedofficer'
}, {
  label: 'Self',
  value: 'SELF',
  slctId: 'self'
}, {
  label: 'CEO',
  value: 'CEO',
  slctId: 'ceo'
}, {
  label: 'HR Department',
  value: 'HRDEPT',
  slctId: 'hrdept'
}];


export const STATUS_BSDPATIENT = {
  STABLE: 'STABLE',
  CRITICAL: 'CRITICAL',
  EXPIRED: 'EXPIRED',
};

export const OPTIONS_BSD_PATIENTREPORT_APNEASTATUS = [{
  label: 'Positive',
  value: 'P',
  color: 'green'
}, {
  label: 'Negative',
  value: 'N',
  color: 'red'
}, {
  label: 'Inconclusive',
  value: 'I',
  color: 'blue'
}];



export const FILESUFFIX_FORMAT_DATE = 'DDMMYYYY_HHmmss';

export const FORMAT_FILENAME = '%s_%s_%s_%s.%s';

export const FILEPREFIX = {
  PHOTO: 'PHOTO',
  FORM8: 'FRM8',
  FORM10: 'FRM10',
  POLICEREPORT: 'POLRPRT',
  DONORFORMS: 'DONFRMS',
  HEARTURGENCYDOCUMENT: 'HRTURGDOC',
  LIVERURGENCYDOCUMENT: 'LVRURGDOC',
  TISSUE: 'TISSUE',
  TEMPSTAFF: 'TEMPSTAFF',
  INVESTIGATION: 'INVESTIGATION',
  PERMSTAFF: 'PERMSTAFF',
  PERSONNELSTAFF: 'PERSONNELSTAFF',
  TRAINEDPERSON: 'TRAINEDPERSON',
  DISCIPLINES: 'DISC',
  NURSINGHOMEREGN: 'NURS',
  FIREREGN: 'FIRE',
  BIOMEDICALREGN: 'BMED',
  SHOPSACTREGN: 'SHPSA',
  APPROVED_HOSPITALPLAN: 'HSPPLN',

  SURGICAL_PERMANENTSTAFF: 'SPRMSTF',
  SURGICAL_TEMPORARYSTAFF: 'STMPSTF',

  MEDICAL_PERMANENTSTAFF: 'MPRMSTF',
  MEDICAL_TEMPORARYSTAFF: 'MTMPSTF',

  ANAESTHESIOLOGY_PERMANENTSTAFF: 'APRMSTF',
  ANAESTHESIOLOGY_TEMPORARYSTAFF: 'ATMPSTF',
  ANAESTHESIOLOGY_NOOFOP: 'ANOOP',
  ANAESTHESIOLOGY_NOOFEQUIP: 'ANOEQ',

  ICU_NURSES: 'IN',
  ICU_TECHNICIANS: 'IT',
  ICU_NOOFEQUIP: 'INOEQ',

  LABFACILITIES_PERMANENTSTAFF: 'LPRMSTF',
  LABFACILITIES_TEMPORARYSTAFF: 'LTMPSTF',
  LABFACILITIES_NOOFINVSTGTNS: 'LNOINV',
  LABFACILITIES_NOOFEQUIP: 'LNOEQ',

  IMGFACILITIES_PERMANENTSTAFF: 'IPRMSTF',
  IMGFACILITIES_TEMPORARYSTAFF: 'ITMPSTF',
  IMGFACILITIES_NOOFINVSTGTNS: 'INOINV',
  IMGFACILITIES_NOOFEQUIP: 'IMGNEQ',

  HAEMATOLGYFACILITIES_PERMANENTSTAFF: 'HPRMSTF',
  HAEMATOLGYFACILITIES_TEMPORARYSTAFF: 'HTMPSTF',
  HAEMATOLGYFACILITIES_NOOFINVSTGTNS: 'HNOINV',
  HAEMATOLGYFACILITIES_NOOFEQUIP: 'HNOEQ',

  BLOODBANKFACILITY_MOU: 'BBMOU',

  DIALYSISFACILITY_MOU: 'DMOU',

  OTHERFACILITIES: 'OTHERFACILITIES',

  TRANSPLANTCOORDINATOR: 'TPNTCRD',

  TXCOMMITTEE: 'TXCOMM',

  NOTING: 'NTNG',
  SEALOFORGANIZATION: 'SEALOFORGANIZATION',
  SIGNOFAUTHORITY: 'SIGNOFAUTHORITY',
  APPROVALCERTIFICATE: 'ACERT',
  UNDERTAKINGDOCUMENT: 'UNDRT',

  INSPECTIONREPORT: 'INSPRP',

  SPECIALISTDOCUMENT: 'SPLDOC',

  TRNSPLNTCOORDDOCUMENT: 'TPNTDOC',

  TRNSRLTDFORMS: 'TRNRF',

  DONORPHOTO: 'DNRPTO',
  RECIPIENTPHOTO: 'RCTPTO',

  BRDATTACH: 'BRDATTACH',

  FIRSTAPNEA: 'FIRSTAPNEA',
  SECONDAPNEA: 'SECONDAPNEA',
  FIRSTAPNEA10: 'FIRSTAPNEA10',
  SECONDAPNEA10: 'SECONDAPNEA10',
  KYPREPORT: 'KYPREPORT',
  FINANCIALDETAILSATTACHMENT: 'FINANCIALDETAILSATTACHMENT',
  FAMILYMEMBERDETAILSATTACHMENT: 'FAMILYMEMBERDETAILSATTACHMENT',
  WITNESSIDENTITYATTACHMENT: 'WITNESSIDENTITYATTACHMENT',
  IDENTITYPROOFATTACHMENT: 'IDENTITYPROOFATTACHMENT',
  CONSENTATTACHMENT: 'CONSENTATTACHMENT',
  APPOINTMENTLETTER: 'APPOINTMENTLETTER',
  FAMILYTREEATTACHMENT: 'FAMILYTREEATTACHMENT',
};

export const ALLOCATIONRECIPIENT_FILEPREFIX = {
  ADDRESSPROOF: 'ADDRESSPROOF',
  PHOTOPROOF: 'PHOTOPROOF',
  AGEPROOF: 'AGEPROOF',
  BLOODGROUPPROOF: 'BLOODGROUPPROOF',
  ATTACHMENT: 'ATTACHMENT',
  ETIOLOGY: 'ETIOLOGY',
  PASSPORT: 'PASSPORT',
  VISA: 'VISA',
  NOTTOIDPROOF: 'NOTTOIDPROOF',
  ECONOMICSTATUSPROOF: 'ECONOMICSTATUSPROOF',
  FIRSTDOSEATTACHMENT: 'FIRSTDOSEATTACHMENT',
  DOSEATTACHMENT: 'DOSEATTACHMENT',
  PRIMARYECDATTACHMENT: 'PRIMARYECDATTACHMENT',
  PRIMARYDCDATTACHMENT: 'PRIMARYDCDATTACHMENT',
  PREVIOUSREGISTRATIONATTACHMENT:'PREVIOUSREGISTRATIONATTACHMENT',
  LIVINGDONATIONREPORT: 'LIVINGDONATIONREPORT',
  PROOFOFKIDNEYTRANSPLANT: 'PROOFOFKIDNEYTRANSPLANT',
  REPORTOFDIALYSISINITIATION: 'REPORTOFDIALYSISINITIATION',
  PRAREPORT: 'PRAREPORT',
  DIALYSISINITIATIONREPORT: 'DIALYSISINITIATIONREPORT',
  ATTACHMENTS: 'ATTACHMENTS',
  VAFGRAFTREPORT: 'VAFGRAFTREPORT',
  VAFFITSULAREPORT: 'VAFFITSULAREPORT',
  HBSAGREPORT: 'HBSAGREPORT',
  ANTIHCVREPORT: 'ANTIHCVREPORT',
  HCVRNAQUANTITATIVEREPORT: 'HCVRNAQUANTITATIVEREPORT',
  HCVDNAREPORT: 'HCVDNAREPORT',
  CARDIACFITNESSCERTIFICATE: 'CARDIACFITNESSCERTIFICATE',
  OTHER: 'OTHER',
  PSYCHIATRICFITNESSCERTIFICATE: 'PSYCHIATRICFITNESSCERTIFICATE',
  PULMONARYFITNESSCERTIFICATE: 'PULMONARYFITNESSCERTIFICATE',
  UROLOGYFITNESSCERTIFICATE: 'UROLOGYFITNESSCERTIFICATE',
  HIVREPORT: 'HIVREPORT',
  ECHO2DREPORT: 'ECHO2DREPORT',
  RTPCRREPORT: 'RTPCRREPORT',
  COAGULATIONPROFILE: 'COAGULATIONPROFILE',
  INFECTIONPROFILEREPORT: 'INFECTIONPROFILEREPORT',
  KIDNEYFUCNTIONALREPORT: 'KIDNEYFUCNTIONALREPORT',
  LIVERFUNCTIONALREPORT: 'LIVERFUNCTIONALREPORT',
  OTHERATTACHMENTS: 'OTHERATTACHMENTS',
  DIABETICREPORT: 'DIABETICREPORT',
  PEPTIDEREPORT: 'PEPTIDEREPORT',
  FBSREPORT: 'FBSREPORT',
  ANTIINSULINREPORT: 'ANTIINSULINREPORT',
  ANTIGADREPORT: 'ANTIGADREPORT',
  HBA1CREPORT: 'HBA1CREPORT',
  ABGREPORT: 'ABGREPORT',
  HRCTREPORT: 'HRCTREPORT',
  PFTREPORT: 'PFTREPORT',
  PATIENTADMISSIONFILE: 'PATIENTADMISSIONFILE',
  TREATINGDOCTORCLINICALSUMMARYREPORT: 'TREATINGDOCTORCLINICALSUMMARYREPORT',
  ANGIOGRAPHYATTACHMENT: 'ANGIOGRAPHYATTACHMENT',
  CARDIOLOGYCERTIFICATE: 'CARDIOLOGYCERTIFICATE',
  COLOURDOPPLERSATTACHMENT: 'COLOURDOPPLERSATTACHMENT',
  EMGMCV: 'EMGMCV',
  NEPROLOGYCERTIFICATE: 'NEPROLOGYCERTIFICATE',
  PHOTOOFAMPUTATEDLIMB: 'PHOTOOFAMPUTATEDLIMB',
  PSYCHIATRYCERTIFICATE: 'PSYCHIATRYCERTIFICATE',
  PULMONARYCERTIFICATE: 'PULMONARYCERTIFICATE',
  XRAYATTACHMENT: 'XRAYATTACHMENT',
  MRIATTACHMENT: 'MRIATTACHMENT',
  NECROTIZINGENTEROCOLITISATTACHMENT: 'NECROTIZINGENTEROCOLITISATTACHMENT',
  MESENTRICDESMOIDSATTACHMENT: 'MESENTRICDESMOIDSATTACHMENT',
  MASSIVEBOWELRESECTIONATTACHMENT: 'MASSIVEBOWELRESECTIONATTACHMENT',
  CRONHSDISEASEATTACHMENT: 'CRONHSDISEASEATTACHMENT',
  SEVEREDEHYDRATIONATTACHMENT: 'SEVEREDEHYDRATIONATTACHMENT',
  THROMBOSISOFTWOORMORECENTRALVEINSATTACHMENT: 'THROMBOSISOFTWOORMORECENTRALVEINSATTACHMENT',
  LINERELATEDFUNGEMIAATTACHMENT: 'LINERELATEDFUNGEMIAATTACHMENT',
  IMPENDINGOROVERTLIVERFAILURELFTATTACHMENT: 'IMPENDINGOROVERTLIVERFAILURELFTATTACHMENT',
  CATHETERREALTEDSYSTEMICSEPSISATTACHMENT: 'CATHETERREALTEDSYSTEMICSEPSISATTACHMENT',
  SBPANCATTACHMENT: 'SBPANCATTACHMENT',
  PAHHEARTCATHREPORT: 'PAHHEARTCATHREPORT',
  HCCAFPREPORTS: 'HCCAFPREPORTS',
  HCCTRIPHASISCTSCAN: 'HCCTRIPHASISCTSCAN',
  HCCBONESCAN: 'HCCBONESCAN',
  HCCHRCTCHESTSCAN: 'HCCHRCTCHESTSCAN',
  HCCPETCTSCAN: 'HCCPETCTSCAN',
  SEPSISREPORT: 'SEPSISREPORT',
  CULTUREREPORT: 'CULTUREREPORT',
  LIVERDYSFUNCTIONULTRASOUND: 'LIVERDYSFUNCTIONULTRASOUND',
  LIVERDYSFUNCTIONLFT: 'LIVERDYSFUNCTIONLFT',
  THROMBOSISULTRASOUND: 'THROMBOSISULTRASOUND',
  THROMBOSISCT: 'THROMBOSISCT',
  HEPATECTOMY: 'HEPATECTOMY',
  LIVERTRANSPLANTATION: 'LIVERTRANSPLANTATION',
  CROSSSECTION: 'CROSSSECTION',
  SYNDROMES: 'SYNDROMES',
  REPORTS: 'REPORTS',
  REFRACTORYASCITESATTACHMENT: 'REFRACTORYASCITESATTACHMENT',
  HEPATICHYDROTHORAXATTACHMENT: 'HEPATICHYDROTHORAXATTACHMENT',
  HRSATTACHMENT: 'HRSATTACHMENT',
  JAUNDICEATTACHMENT: 'JAUNDICEATTACHMENT',
  PVTATTACHMENT: 'PVTATTACHMENT',
  VARICEALBLEEDNOENDOATTACHMENT: 'VARICEALBLEEDNOENDOATTACHMENT',
  HENEEDINGHICUATTACHMENT: 'HENEEDINGHICUATTACHMENT',
  PAHYESNOATTACHMENT: 'PAHYESNOATTACHMENT',
  HPSYESNOATTACHMENT: 'HPSYESNOATTACHMENT',
  HCCYESNOATTACHMENT: 'HCCYESNOATTACHMENT',
  PSCYESNOATTACHMENT: 'PSCYESNOATTACHMENT',
  PBCYESNOATTACHMENT: 'PBCYESNOATTACHMENT',
  POSTTRANSPLANTPATIENTREQUIRINGRETRANSPLANTYESNOATTACHMENT: 'POSTTRANSPLANTPATIENTREQUIRINGRETRANSPLANTYESNOATTACHMENT',
  MLDYESNOATTACHMENT: 'MLDYESNOATTACHMENT',
  MLDOTWYESNOATTACHMENT: 'MLDOTWYESNOATTACHMENT',
  NONHCCYESNOATTACHMENT: 'NONHCCYESNOATTACHMENT',
  HEMATOLOGYATTACHMENT: 'HEMATOLOGYATTACHMENT',
  KYPDOCUMENT: 'KYPDOCUMENT'
};

export const TRANSFERREGISTRATION_FILEPREFIX = {
  TRANSFERREGISTRATIONATTACHMENT: 'TRANSFERREGISTRATIONATTACHMENT'
}
export const ALLOCATIONDONOR_FILEPREFIX = {
  ABDOMINALCHESTINJURIESREPORT: 'ABDOMINALCHESTINJURIESREPORT',
  OPERATIONONDONORREPORT: 'OPERATIONONDONORREPORT',
  FIRSTAPNEABASELINEREPORT: 'FIRSTAPNEABASELINEREPORT',
  FIRSTAPNEAAFTERTENMINUTESREPORT: 'FIRSTAPNEAAFTERTENMINUTESREPORT',
  SECONDAPNEABASELINEREPORT: 'SECONDAPNEABASELINEREPORT',
  SECONDAPNEAAFTERTENMINUTESREPORT: 'SECONDAPNEAAFTERTENMINUTESREPORT',
  LATESTRTPCR: 'LATESTRTPCR',
  ANYOTHERMEDICATIONS: 'ANYOTHERMEDICATIONS',
  DIABETICREPORT: 'DIABETICREPORT',
  HTNREPORT: 'HTNREPORT',
  IHDREPORT: 'IHDREPORT',
  ASTHMAREPORT: 'ASTHMAREPORT',
  LUNGDISEASEREPORT: 'LUNGDISEASEREPORT',
  HBSAGREPORT: 'HBSAGREPORT',
  ANTIHCVREPORT: 'ANTIHCVREPORT',
  HCVRNAQUANTITATIVEREPORT: 'HCVRNAQUANTITATIVEREPORT',
  HIVREPORT: 'HIVREPORT',
  HEPATETISCREPORT: 'HEPATETISCREPORT',
  HBCABREPORT: 'HBCABREPORT',
  CBCREPORT: 'CBCREPORT',
  ABGREPORT: 'ABGREPORT',
  HBA1CREPORT: 'HBA1CREPORT',
  REPORTS: 'REPORTS',
  ANGIOGRAPHYREPORT: 'ANGIOGRAPHYREPORT',
  BLOODSAMPLEFORCROSSMATCHREPORT: 'BLOODSAMPLEFORCROSSMATCHREPORT',
  BLOODSAMPLEFORHEARTCROSSMATCHINGREPORT: 'BLOODSAMPLEFORHEARTCROSSMATCHINGREPORT',
  CHESTXRAYREPORT: 'CHESTXRAYREPORT',
  CTTHORAXREPORT: 'CTTHORAXREPORT',
  TROPTREPORT: 'TROPTREPORT',
  TWODECHOREPORT: 'TWODECHOREPORT',
  BLOODTRANSFUSIONREPORT: 'BLOODTRANSFUSIONREPORT',
  CONTACTWITHTRANSMISSIBLEDISEASESREPORT: 'CONTACTWITHTRANSMISSIBLEDISEASESREPORT',
  ILLICITDRUGSREPORT: 'ILLICITDRUGSREPORT',
  INFECTIONSREPORT: 'INFECTIONSREPORT',
  LIVEVACCINESREPORT: 'LIVEVACCINESREPORT',
  OCCUPATIONALEXPOSUREREPORT: 'OCCUPATIONALEXPOSUREREPORT',
  TATTOEARORBODYPIERCINGREPORT: 'TATTOEARORBODYPIERCINGREPORT',
  UNSAFESEXPRACTICESREPORT: 'UNSAFESEXPRACTICESREPORT',
  ADMISSIONREPORT: 'ADMISSIONREPORT',
  ANYREPORT: 'ANYREPORT',
  CLINICALHISTORYREPORT: 'CLINICALHISTORYREPORT',
  CULTUREREPORT: 'CULTUREREPORT',
  FORM8REPORT: 'FORM8REPORT',
  FORM10REPORT: 'FORM10REPORT',
  ICUCHARTREPORT: 'ICUCHARTREPORT',
  IMAGINGHISTOPATHOLOGYENDOSCOPIESREPORT: 'IMAGINGHISTOPATHOLOGYENDOSCOPIESREPORT',
  INVESTIGATIONCHARTREPORT: 'INVESTIGATIONCHARTREPORT',
  PROCALCITONINREPORT: 'PROCALCITONINREPORT',
  MEDICOLEGALCASENOC: 'MEDICOLEGALCASENOC',
  DOSEATTACHMENT:'DOSEATTACHMENT',
  FIRSTDOSEATTACHMENT:'FIRSTDOSEATTACHMENT',
  BOTHDOSE:'BOTHDOSE',
  DEATHCERTIFICATE: 'DEATHCERTIFICATE',
  LIVERULTRASOUNDREPORT: 'LIVERULTRASOUNDREPORT',
  URINEROUTINEREPORT: 'URINEROUTINEREPORT',
  CTHEADREPORT: 'CTHEADREPORT',
  MRIHEADREPORT: 'MRIHEADREPORT',
  XRAYCHESTREPORT: 'XRAYCHESTREPORT',
  XRAYABDOMENREPORT: 'XRAYABDOMENREPORT',
  CTCHESTREPORT: 'CTCHESTREPORT',
  USGABDOMENANDPELVISREPORT: 'USGABDOMENANDPELVISREPORT',
  HANDXRAY: 'HANDXRAY',
  HCVRNAREPORT: 'HCVRNAREPORT',
  HBVDNAREPORT: 'HBVDNAREPORT',
};

export const FORM_DOCUMENTS_TYPE = {
  TXCOMMITTEE: {
    label: 'Transplant Committee',
    value: 'TXCOMM'
  }
};

export const ROTTO_WHO_SURVEY_REPORTS = {
  TISSUEANDCORNEATXDATA: {
    label: 'Tissue Donation And Tx Including Composite Vascular Tissue And Cornea',
    value: 'tissue Donation AndT xIncludingCompositeVascularTissueAndCornea'
  },
  ORGANWISEWAITINGLIST: {
    label: 'Organ Wise Waiting List',
    value: 'organWiseWaitingList'
  },
  DEATHSDUETOORGANTX: {
    label: 'Deaths Due to Organ Tx',
    value: 'deathsDueToOrganTx'
  },
  DEATHSDUETOGRAFTFAILURE: {
    label: 'Deaths Due to Graft Failure',
    value: 'deathsDueToPrimaryGraftFailureData'
  },
  GRAFTREJECTION: {
    label: 'Graft Rejection Data',
    value: 'graftRejectionData'
  },
  COMPLICATIONINORGANTX: {
    label: 'Complications in Organ Tx Data',
    value: 'complicationsInOrganTxData'
  },
  RECIPIENTEPIDEMIOLOGYBYAGE: {
    label: 'Recipient Epidemiology by Age',
    value: 'recipientEpidemiologyByAgeData'
  },
  RECIPIENTEPIDEMIOLOGYBYGOTPVTSECTORWISEDATA: {
    label: 'Recipient Epidemiology by Got Pvt Sector Wise Data',
    value: 'recipientEpidemiologyByGovtPvtSectorWiseData'
  },
  RECIPIENTEPIDEMIOLOGYBYSEXRATIO: {
    label: 'Recipient Epidemiology by Sex Ratio Data',
    value: 'recipientEpidemiologyBySexRatioData'
  },
  CAUSEOFENDORGANFAILURENECESSITATINGTX: {
    label: 'Cause Of End Organ Failure Necessitating Transplant',
    value: 'CauseOfEndOrganFailureNecessitatingTransplant'
  },
  RELIGIONWISERECIPIENTEPIDEMIOLOGY: {
    label: 'Religion Wise Recipient Epidemiology Data',
    value: 'religionWiseRecipientEpidemiologyData'
  },
  CONSTITUTIONANDRICKFACTORDATA: {
    label: 'Constitution And Risk Factor Data',
    value: 'constitutionAndRiskFactorData'
  }
}

export const ROTTO_REPORTS = {
  DAILYTRANSPLANTREPORT: {
    label: 'Daily Transplants Report',
    value: 'dailyTransplantsReport',
    slctId: 'dailyTransplantsReport'
  },
  POSTTRANSPLANTOUTCOMEDATAOFORGANSTRANSPLANTED: {
    label: 'Post Transplant Outcome Data of Organs Transplanted',
    value: 'postTransplantOutcomeDataOfOrgansTransplanted',
    slctId: 'postTransplantOutcomeDataOfOrgansTransplanted'
  },
  LIVEDONORINFORMATIONSHEET: {
    label: 'Live Donor Information Report',
    value: 'liveDonorInformationSheet',
    slctId: 'liveDonorInformationSheet'
  },
  ROTTOSOTTO: {
    label: 'Living Donor Transplant Report',
    value: 'rottoSotto',
    slctId: 'rottoSotto'
  },
  WHODESKSURVEYREPORTS: {
    label: 'WHO Desk Survey Reports',
    value: 'whoDeskSurveyReports'
  },
  TRANSPLANTABROADANDNONRESIDENTS: {
    label: 'Transplants Abroad and Of Non-Residents Report',
    value: 'transplantsAbroadAndTransplantationOfNonResidents',
    slctId: 'transplantsAbroadAndTransplantationOfNonResidents'
  },
  PROFORMAFORMONTHLYREPORTOFTXOFORGAN: {
    label: 'Proforma for Monthly Report of Transplant',
    value: 'proformaForMnthRptOfTx',
    slctId: 'proformaForMnthRptOfTx'
  },

  KIDNEYSPOUSALTRANSPLANTATIONDATA: {
    label: 'Kidney Spousal Transplantation Report',
    value: 'kidneySpousalTransplantationData',
    slctId: 'kidneySpousalTransplantationData'
  },
  PROFORMAREPORTOFDONOROFTISSUE: {
    label: 'Proforma Report of Donor of Tissue',
    value: 'proformaReportofDonorofTissue',
    slctId: 'proformaReportofDonorofTissue'
  },
  PROFORMAREPORTOFTRANSPLANTOFTISSUE: {
    label: 'Proforma Report of Transplant of Tissue',
    value: 'proformaReportofTransplantofTissue',
    slctId: 'proformaReportofTransplantofTissue'
  },
}

export const NOTTO_REPORTS = {
  ANNEXUREB1REPORT: {
    label: 'Annexure B1',
    value: 'annexureb1',
  },
  ANNEXUREB2REPORT: {
    label: 'Annexure B2',
    value: 'annexureb2'
  },
  ORGANDONATIONANDTRANSPLANTATIONREPORT: {
    label: 'Organ Donation and Transplantation Report',
    value: 'organdonationandtransplantationreport'
  },
}

export const MONTHLYREPORTTYPE = {
  ANNEXUREA: {
    label: 'Annexure A',
    value: 'annexureA',
    slctId: 'annexureA'
  },
  ANNEXUREB: {
    label: 'Annexure B',
    value: 'annexureB',
    slctId: 'annexureB'
  },
  ANNEXUREC: {
    label: 'Annexure C',
    value: 'annexureC',
    slctId: 'annexureC'
  },
  ANNEXUREZ: {
    label: 'Annexure Z',
    value: 'annexureZ',
    slctId: 'annexureZ'
  },
  BSDREPORT: {
    label: 'Brain Stem Death (BSD) Report',
    value: 'bsdReport',
    slctId: 'bsdReport'
  },
  POSTTRANSPLANT_RECIPIENT: {
    label: 'Post-transplant Recipient',
    value: 'postTsplntRecipient',
    slctId: 'postTsplntRecipient'
  },
  POSTTRANSPLANT_DONOR: {
    label: 'Post-transplant Donor',
    value: 'postTsplntDonor',
    slctId: 'postTsplntDonor'
  },
  LIVEORGANTRANSPLANTRECORD: {
    label: 'Live Organ Transplant Record',
    value: 'liveOrganTsplntRecord',
    slctId: 'liveOrganTsplntRecord'
  },
  CADAVERORGANTRANSPLANTRECORD: {
    label: 'Deceased Organ Transplant Record',
    value: 'deceasedOrganTsplntRecord',
    slctId: 'deceasedOrganTsplntRecord'
  },
  KIDNEYSPOUSALTRANSPLANTATIONDATA: {
    label: 'Kidney Spousal Transplantation Report',
    value: 'kidneySpousalTransplantationData',
    slctId: 'kidneySpousalTransplantationData'
  },


};

export const ETH_ZEROADDRESS = '0x0000000000000000000000000000000000000000';

export const RECIPIENT_FORMSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED'
};

export const DONOR_FORMSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED'
};

export const RECIPIENT_STATUS = {
  ACTIVE: 'ACTIVE',
  OFFLIST_GLOBAL: 'OFFLIST_GLOBAL',
  OFFLIST_LOCAL: 'OFFLIST_LOCAL'
};

export const RECIPIENTHEART_FORMSTATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED'
}

export const RECIPIENTKIDNEY_FORMSTATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED'
}

export const DONORHEART_FORMSTATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED'
}

export const RECIPIENTLIVER_FORMSTATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED'
}

export const HEARTORGANCALL_FORMSTATUS = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED'
}

export const KIDNEYORGANCALL_FORMSTATUS = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED'
}

export const DONORLIVER_FORMSTATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED'
}

export const ORGANS = {
  ALL: 'ALL',
  HEART: 'HEART',
  KIDNEY: 'KIDNEY',
  LIVER: 'LIVER',
  LUNGS: 'LUNGS',
  HEARTLUNGS: 'HEARTLUNGS',
  PANCREAS: 'PANCREAS',
  INTESTINE: 'INTESTINE',
  HAND: 'HAND',
  LIVERRIGHTEXTENDEDLOBE: 'LIVERRIGHTEXTENDEDLOBE',
  LIVERLEFTLATERALSEGMENT: 'LIVERLEFTLATERALSEGMENT',
  LEFTLATERALSECTIONECTOMY: 'LEFTLATERALSECTIONECTOMY',
  LIVERHEPATECTOMY: 'LIVERHEPATECTOMY',
  LIVERLEFTEXTENDEDHEPATECTOMY: 'LIVERLEFTEXTENDEDHEPATECTOMY',
  LIVERRIGHTEXTENDEDHEPATECTOMY: 'LIVERRIGHTEXTENDEDHEPATECTOMY',
  LIVERRIGHTHEPATECTOMYWITHOUTMHV: 'LIVERRIGHTHEPATECTOMYWITHOUTMHV',
  LIVERRIGHTHEPATECTOMYWITHMHV: 'LIVERRIGHTHEPATECTOMYWITHMHV',
  LIVERRIGHTPOSTERIORSECTIONECTOMY: 'LIVERRIGHTPOSTERIORSECTIONECTOMY'
}

export const TISSUES = {
  ALL: 'ALL',
  CORNEA: 'CORNEA',
  SKIN: 'SKIN',
  BONES: 'BONES',
  HEARTVALVES: 'HEARTVALVES',
  BLOODVESSELS: 'BLOODVESSELS'
}

export const ZTCC_ZONES = {
  ALL: { VALUE: 'ALL', ORGANS: Object.values(ORGANS) },
  MUMBAI: { VALUE: 'MUMBAI', ORGANS: [ORGANS.HEART, ORGANS.LIVER, ORGANS.LUNGS, ORGANS.KIDNEY, ORGANS.INTESTINE] },
  PUNE: { VALUE: 'PUNE', ORGANS: [ORGANS.HEART, ORGANS.LIVER, ORGANS.LUNGS, ORGANS.KIDNEY, ORGANS.HANDS] },
  NAGPUR: { VALUE: 'NAGPUR', ORGANS: [ORGANS.HEART, ORGANS.LUNGS, ORGANS.HAND, ORGANS.PANCREAS] },
  AURANGABAD: { VALUE: 'AURANGABAD', ORGANS: [ORGANS.HEART, ORGANS.LIVER, ORGANS.KIDNEY] }
}

export const BROADCAST_ZONES = {
  ALL: {
    value: 'ALL',
    label: 'All'
  },
  MUMBAI: {
    value: 'MUMBAI',
    label: 'Mumbai'
  },
  PUNE: {
    value: 'Pune',
    label: 'PUNE'
  },
  NAGPUR: {
    value: 'NAGPUR',
    label: 'Nagpur'
  },
  AURANGABAD: {
    value: 'AURANGABAD',
    label: 'Aurangabad'
  }
}



export const ORGANSWITHSPECIFICKIDNEY = {
  ALL: 'ALL',
  HEART: 'HEART',
  LEFTKIDNEY: 'LEFTKIDNEY',
  RIGHTKIDNEY: 'RIGHTKIDNEY',
  LIVER: 'LIVER',
  LUNGS: 'LUNGS',
  HEARTLUNGS: 'HEARTLUNGS',
  PANCREAS: 'PANCREAS',
  INTESTINE: 'INTESTINE',
  HAND: 'HAND'
}

const CONTEXT_ZTCC = '/ztcc';
const CONTEXT_ALLOCATION = '/allocation';
const CONTEXT_SOTTO = '/sotto';
const CONTEXT_TX_BSD = `${CONTEXT_ORGAN + CONTEXT_TRANSPLANT + CONTEXT_BSD}`;

// Recipient Urls

export const PATH_HOSPITAL_SUBMITRECIPIENT = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipient`;
export const PATH_HOSPITAL_GETRECIPIENT = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipient/%s`;
export const PATH_HOSPITAL_GETRECIPIENTSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientSummaries`;
export const PATH_ZTCC_GETRECIPIENTSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/recipientSummaries`;

// RecipientHeart Urls

export const PATH_HOSPITAL_SUBMITDRAFTRECIPIENTHEART = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientHeart/draft`;
export const PATH_HOSPITAL_SUBMITRECIPIENTHEARTEDITS = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientHeart`;
export const PATH_HOSPITAL_SUBMITRECIPIENTHEART = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientHeartStatus`;
export const PATH_HOSPITAL_GETDRAFTRECIPIENTHEART = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientHeart`;
export const PATH_HOSPITAL_GETRECIPIENTHEART = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientHeart/%s`;
export const PATH_HOSPITAL_GETRECIPIENTHEARTSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientHeartSummaries`;
export const PATH_ZTCC_GETRECIPIENTHEARTSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/recipientHeartSummaries`;
export const PATH_ZTCC_GETRECIPIENTHEART = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/recipientHeart/%s`;
export const PATH_SOTTO_GETRECIPIENTHEART = `${CONTEXT_ALLOCATION + CONTEXT_SOTTO}/recipientHeart/%s`;


export const PATH_SOTTO_GETRECIPIENTHEARTSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_SOTTO}/recipientHeartSummaries`;

// RecipientKidney Urls

export const PATH_HOSPITAL_SUBMITDRAFTRECIPIENTKIDNEY = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientKidney/draft`;
export const PATH_HOSPITAL_SUBMITRECIPIENTKIDNEYEDITS = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientKidney`;
export const PATH_HOSPITAL_SUBMITRECIPIENTKIDNEY = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientKidneyStatus`;
export const PATH_HOSPITAL_GETDRAFTRECIPIENTKIDNEY = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientKidney`;
export const PATH_HOSPITAL_GETRECIPIENTKIDNEY = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientKidney/%s`;
export const PATH_HOSPITAL_GETRECIPIENTKIDNEYSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientKidneySummaries`;
export const PATH_ZTCC_GETRECIPIENTKIDNEYSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/recipientKidneySummaries`;
export const PATH_ZTCC_GETRECIPIENTKIDNEY = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/recipientKidney/%s`;

export const PATH_SOTTO_GETRECIPIENTKIDNEYSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_SOTTO}/recipientKidneySummaries`;

// RecipientLiver Urls

export const PATH_HOSPITAL_SUBMITDRAFTRECIPIENTLIVER = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientLiver/draft`;
export const PATH_HOSPITAL_SUBMITRECIPIENTLIVEREDITS = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientLiver`;
export const PATH_HOSPITAL_SUBMITRECIPIENTLIVER = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientLiverStatus`;
export const PATH_HOSPITAL_GETDRAFTRECIPIENTLIVER = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientLiver`;
export const PATH_HOSPITAL_GETRECIPIENTLIVER = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientLiver/%s`;
export const PATH_HOSPITAL_GETRECIPIENTLIVERSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/recipientLiverSummaries`;

export const PATH_ZTCC_GETRECIPIENTLIVERSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/recipientLiverSummaries`;
export const PATH_SOTTO_GETRECIPIENTLIVERSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_SOTTO}/recipientLiverSummaries`;

// Donor Urls

export const PATH_HOSPITAL_SUBMITDRAFTDONOR = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donor`;
export const PATH_HOSPITAL_SUBMITDONOREDITS = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donor`;
export const PATH_HOSPITAL_SUBMITDONOR = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorStatus`;
export const PATH_HOSPITAL_GETDONOR = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donor/%s`;
export const PATH_HOSPITAL_GETDRAFTDONOR = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donor`;
export const PATH_HOSPITAL_GETDONORSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorSummaries`;
export const PATH_ZTCC_GETDONORSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_ZTCC}/donorSummaries`;
export const PATH_SOTTO_GETDONORSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_SOTTO}/donorSummaries`;
export const PATH_ZTCC_GETDONOR = `${CONTEXT_TX_BSD + CONTEXT_ZTCC}/donor/%s`;
export const PATH_SOTTO_GETDONOR = `${CONTEXT_TX_BSD + CONTEXT_SOTTO}/donor/%s`;


// All Organs Urls

export const PATH_HOSPITAL_GETDONORALLORGANSSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorAllOrgansSummaries`;

// DonorHeart Urls

export const PATH_HOSPITAL_SUBMITDRAFTDONORHEART = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorHeart/draft`;
export const PATH_HOSPITAL_SUBMITDONORHEARTEDITS = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorHeart`;
export const PATH_HOSPITAL_SUBMITDONORHEART = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorHeartStatus`;
export const PATH_HOSPITAL_GETDRAFTDONORHEART = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorHeart`;
export const PATH_HOSPITAL_GETDONORHEART = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorHeart/%s`;
export const PATH_HOSPITAL_GETDONORHEARTSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorHeartSummaries`;
export const PATH_ZTCC_GETTOPRECIPIENTHEARTSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_ZTCC}/topRecipientHeartSummaries`;
export const PATH_ZTCC_GETDONORHOSPITALTOPRECIPIENTHEARTSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_ZTCC}/donorHospTopRecipientHeartSummaries`;

// DonorKidney Urls

export const PATH_HOSPITAL_SUBMITDRAFTDONORKIDNEY = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorKidney/draft`;
export const PATH_HOSPITAL_SUBMITDONORKIDNEYEDITS = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorKidney`;
export const PATH_HOSPITAL_SUBMITDONORKIDNEY = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorKidneyStatus`;
export const PATH_HOSPITAL_GETDRAFTDONORKIDNEY = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorKidney`;
export const PATH_HOSPITAL_GETDONORKIDNEY = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorKidney/%s`;
export const PATH_HOSPITAL_GETDONORKIDNEYSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorKidneySummaries`;
export const PATH_ZTCC_GETTOPRECIPIENTKIDNEYSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_ZTCC}/topRecipientKidneySummaries`;
export const PATH_ZTCC_GETDONORHOSPITALTOPRECIPIENTKIDNEYSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_ZTCC}/donorHospTopRecipientKidneySummaries`;

// DonorLiver Urls

export const PATH_HOSPITAL_SUBMITDRAFTDONORLIVER = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorLiver/draft`;
export const PATH_HOSPITAL_SUBMITDONORLIVEREDITS = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorLiver`;
export const PATH_HOSPITAL_SUBMITDONORLIVER = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorLiverStatus`;
export const PATH_HOSPITAL_GETDRAFTDONORLIVER = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorLiver/draft`;
export const PATH_HOSPITAL_GETDONORLIVER = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorLiver/%s`;
export const PATH_HOSPITAL_GETDONORLIVERSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_HOSPITAL}/%s/donorLiverSummaries`;
export const PATH_ZTCC_GETTOPRECIPIENTLIVERSUMMARIES = `${CONTEXT_TX_BSD + CONTEXT_ZTCC}/topRecipientLiverSummaries`;

// Heart Organ Call

export const PATH_HOSPITAL_GETHEARTORGANCALLSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/heartOrganCallSummaries`;
export const PATH_HOSPITAL_GETHEARTORGANCALL = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/heartOrganCall/%s`;
export const PATH_HOSPITAL_UPDATEHEARTORGANCALLSTATUS = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/heartOrganCallStatus`;
export const PATH_HOSPITAL_UPDATEHEARTORGANCALLTRANSPLANTSTATUS = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/heartOrganCallTransplantStatus`;
export const PATH_ZTCC_GETHEARTORGANCALLSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/heartOrganCallSummaries`;
export const PATH_ZTCC_SUBMITHEARTORGANCALL = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/heartOrganCall`;
export const PATH_ZTCC_GETHEARTORGANCALL = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/heartOrganCall/%s`;
export const PATH_ZTCC_UPDATEHEARTORGANCALLSTATUS = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/heartOrganCallStatus`;
export const PATH_ZTCC_SUBMITHEARTORGANCALLCROSSMATCHING = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/heartOrganCallCrossMatching`;
export const PATH_ZTCC_SUBMITHEARTORGANCALLMATCHING = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/heartOrganCallMatching`;
export const PATH_SOTTO_GETHEARTORGANCALLSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_SOTTO}/heartOrganCallSummaries`;

// Kidney Organ Call

export const PATH_HOSPITAL_GETKIDNEYORGANCALLSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/kidneyOrganCallSummaries`;
export const PATH_HOSPITAL_GETKIDNEYORGANCALL = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/kidneyOrganCall/%s`;
export const PATH_HOSPITAL_UPDATEKIDNEYORGANCALLSTATUS = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/kidneyOrganCallStatus`;
export const PATH_HOSPITAL_UPDATEKIDNEYORGANCALLTRANSPLANTSTATUS = `${CONTEXT_ALLOCATION + CONTEXT_HOSPITAL}/%s/kidneyOrganCallTransplantStatus`;
export const PATH_ZTCC_GETKIDNEYORGANCALLSUMMARIES = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/kidneyOrganCallSummaries`;
export const PATH_ZTCC_SUBMITKIDNEYORGANCALL = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/kidneyOrganCall`;
export const PATH_ZTCC_GETKIDNEYORGANCALL = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/kidneyOrganCall/%s`;
export const PATH_ZTCC_UPDATEKIDNEYORGANCALLSTATUS = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/kidneyOrganCallStatus`;
export const PATH_ZTCC_SUBMITKIDNEYORGANCALLCROSSMATCHING = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/kidneyOrganCallCrossMatching`;
export const PATH_ZTCC_SUBMITKIDNEYORGANCALLMATCHING = `${CONTEXT_ALLOCATION + CONTEXT_ZTCC}/kidneyOrganCallMatching`;

export const IMGDIMENSIONS = {
  PROFILE: {
    width: 100,
    height: 125
  },
  SIGN: {
    width: 100,
    height: 125
  },
  SEAL: {
    width: 100,
    height: 125
  },
}
