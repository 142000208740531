export const DISTRICTS_SK = [
  {
    label: 'East Sikkim',
    value: 'EAST_SIKKIM'
  },
  {
    label: 'North Sikkim',
    value: 'NORTH_SIKKIM'
  },
  {
    label: 'South Sikkim',
    value: 'SOUTH_SIKKIM'
  },
  {
    label: 'West Sikkim',
    value: 'WEST_SIKKIM'
  }
];
