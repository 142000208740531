import React from 'react';
import {
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatedAttachment, ValidatedSelect, ValidatedRadio } from '../../Custom';
import { FILETYPES } from '../../Custom/ValidatedAttachment';

function MedicalReportsHandAssessment({
  donorMedicalReportsDetails,
  setDonorMedicalReportsDetails,
}) {
  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Hand Assessment (Scars, Injuries, Vascularity)</Typography>
          <ValidatedSelect
            required
            label=""
            value={donorMedicalReportsDetails?.handAssessment?.handAssessmentDamageType}
            multiple={true}
            options={[
              {
                label: 'Scars',
                value: 'SCARS',
              },
              {
                label: 'Injuries',
                value: 'INJURIES',
              },
              {
                label: 'Vascularity',
                value: 'VASCULARITY',
              },
              {
                label: 'Other',
                value: 'OTHER',
              },
            ]}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                handAssessment: {
                  ...donorMedicalReportsDetails.handAssessment,
                  handAssessmentDamageType: v.map((opt) => opt.value),
                },
              });
            }}
          />
        </Grid>
        {/* <Grid item sm={6} xs={12}>
          <Typography variant="body1">Hand assessment (scars, injuries, vascularity)</Typography>
          <RadioGroup
            row={true}
            aria-label="Hand assessment (scars, injuries, vascularity)"
            name="Hand assessment (scars, injuries, vascularity)"
            value={donorMedicalReportsDetails?.handAssessment?.handAssessmentDamageType}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                handAssessment: {
                  ...donorMedicalReportsDetails.handAssessment,
                  handAssessmentDamageType: e.target.value,
                },
              });
            }}
          >
            <FormControlLabel value={"SCARS"} control={<Radio />} label="Scars" />
            <FormControlLabel value={"INJURIES"} control={<Radio />} label="Injuries" />
            <FormControlLabel value={"VASCULARITY"} control={<Radio />} label="Vascularity" />
          </RadioGroup>
        </Grid> */}
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Hand X-Ray</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            value={donorMedicalReportsDetails?.handAssessment?.handXRay}
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                handAssessment: {
                  ...donorMedicalReportsDetails.handAssessment,
                  handXRay: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ValidatedRadio
            label="Rule out history of neurological illnesses like leprosy, stroke"
            name="Rule out history of neurological illnesses like leprosy, stroke"
            value={donorMedicalReportsDetails?.handAssessment?.historyOfNeurologicalIllness}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                handAssessment: {
                  ...donorMedicalReportsDetails.handAssessment,
                  historyOfNeurologicalIllness: e.target.value,
                },
              });
            }}
            options={[
              { value: 'YES', label: 'YES' },
              { value: 'NO', label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ValidatedRadio
            label="Rule out history of previous trauma like nerve injuries, tendon injuries, fractures etc"
            name="Rule out history of previous trauma like nerve injuries, tendon injuries, fractures etc"
            value={donorMedicalReportsDetails?.handAssessment?.historyOfPreviousTrauma}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                handAssessment: {
                  ...donorMedicalReportsDetails.handAssessment,
                  historyOfPreviousTrauma: e.target.value,
                },
              });
            }}
            options={[
              { value: 'YES', label: 'YES' },
              { value: 'NO', label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ValidatedRadio
            label="History s/o any arthritis / connective tissue disorders / degenerative disorders."
            name="History s/o any arthritis / connective tissue disorders / degenerative disorders."
            value={donorMedicalReportsDetails?.handAssessment?.historyOfOtherDisease}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                handAssessment: {
                  ...donorMedicalReportsDetails.handAssessment,
                  historyOfOtherDisease: e.target.value,
                },
              });
            }}
            options={[
              { value: 'YES', label: 'YES' },
              { value: 'NO', label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
      </Grid>
    </>
  );
}

MedicalReportsHandAssessment.propTypes = {
  donorMedicalReportsDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  setDonorMedicalReportsDetails: PropTypes.func.isRequired,
};
MedicalReportsHandAssessment.defaultProps = {};

export default MedicalReportsHandAssessment;
