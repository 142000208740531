import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon';
import ErrorIcon from 'mdi-react/AlertCircleIcon';
import SuccessIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import { Button } from '@material-ui/core';

export default function LoadableButton({
  children,
  apiCallStatuses,
  apiCallId,
  loadingText,
  successText,
  errorText,
  disabledButton,
  ...props
}) {
  const [loadingStatus, setLoadingStatus] = useState('UNSET');

  useEffect(() => {
    setLoadingStatus('UNSET');
  }, [setLoadingStatus]);

  useEffect(() => {
    if (loadingStatus === 'SUCCESS') {
      setTimeout(() => {
        setLoadingStatus('UNSET');
      }, 2000);
    }
  }, [loadingStatus]);

  useEffect(() => {
    if (apiCallId) {
      setLoadingStatus(apiCallStatuses[apiCallId] || 'UNSET');
    } else if (
      Object.values(apiCallStatuses || {}).reduce(
        (loading, apiCallStatus) => loading || apiCallStatus === 'LOADING',
        false
      )
    ) {
      setLoadingStatus('LOADING');
    } else if (!Object.values(apiCallStatuses || {}).length) {
      setLoadingStatus('UNSET');
    } else if (
      Object.values(apiCallStatuses || {}).reduce(
        (loading, apiCallStatus) => loading && apiCallStatus === 'SUCCESS',
        true
      )
    ) {
      setLoadingStatus('SUCCESS');
    }
  }, [apiCallStatuses, apiCallId, setLoadingStatus]);

  if (disabledButton) {
    if (loadingStatus === 'LOADING') {
      return (
        <Button {...props} disabled={true}>
          {children || null}
        </Button>
      );
    }
    return (
      <Button {...props} disabled={false}>
        {children || null}
      </Button>
    );
  }

  if (!loadingText) {
    loadingText = 'Loading';
  }

  if (loadingStatus === 'UNSET') {
    return <Button {...props}>{children || null}</Button>;
  }

  const _getLoadingIcon = () => {
    switch (loadingStatus) {
      case 'LOADING':
        return (
          <>
            {loadingText} <LoadingIcon className="spin" style={{ marginLeft: '5px' }} />
          </>
        );
      case 'SUCCESS':
        return (
          <>
            {successText} <SuccessIcon style={{ marginLeft: '5px', color: 'green' }} />
          </>
        );
      case 'ERROR':
        return (
          <>
            {errorText} <ErrorIcon style={{ marginLeft: '5px', color: 'red' }} />
          </>
        );
      default:
        return null;
    }
  };
  return <Button {...props}>{_getLoadingIcon()}</Button>;
}
LoadableButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  apiCallStatuses: PropTypes.objectOf(PropTypes.any),
  apiCallId: PropTypes.string,
  loadingText: PropTypes.string,
  successText: PropTypes.string,
  errorText: PropTypes.string,
  disabledButton: PropTypes.bool,
};
LoadableButton.defaultProps = {
  apiCallStatuses: {},
  apiCallId: undefined,
  loadingText: 'Loading',
  successText: 'Success',
  errorText: 'Error',
  disabledButton: false,
};
