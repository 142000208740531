import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Button } from 'reactstrap';
import { Button } from '@material-ui/core';
import classnames from 'classnames';
import Joi from 'joi';
import { VALIDATION } from '../../constants';

class ValidatedCheckButton extends Component {
  render() {
    const {
      checked,
      color,
      onChange,
      onValidation,
      validationDisabled,
      placeholder,
      showValid,
      ...other
    } = this.props;
    // eslint-disable-next-line prefer-const
    let { validation, schema, ...otherProps } = other;
    let valid = true;
    let messages = [];
    let joiError = null;
    const style = {};
    if (validation === true) {
      validation = VALIDATION.ALL;
    } else if (validation === false) {
      validation = VALIDATION.OFF;
    }
    if (
      !validationDisabled &&
      (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && this.isDirty))
    ) {
      if (!schema || !schema.isJoi) {
        schema = Joi.alternatives([Joi.number(), Joi.boolean()]);
      }
      if (!schema._getLabel()) {
        schema = schema.label(placeholder);
      }
      if (validation & VALIDATION.INVALIDATE_EMPTY) {
        schema = schema.required();
      }
      const { error } = schema.validate(checked);
      if (error) {
        valid = false;
        messages = error.details.map((d) => d.message);
        joiError = error;
        style.borderColor = 'red';
      } else if (showValid) {
        style.borderColor = 'green';
      }
      onValidation(valid, messages, joiError);
    } else {
      valid = true;
      messages = [];
    }

    return (
      <>
        {checked ? (
          <Button
            {...otherProps}
            color="success"
            checked={true}
            onClick={(e) => {
              if (!this.isDirty) {
                this.isDirty = true;
              }
              const { target, currentTarget, ...others } = e;
              const onChangeE = { target: currentTarget, ...others };
              onChange(onChangeE);
            }}
            style={style}
          >
            <i className="fa fa-check-square-o" />
          </Button>
        ) : (
          <Button
            {...otherProps}
            color="secondary"
            checked={false}
            onClick={(e) => {
              if (!this.isDirty) {
                this.isDirty = true;
              }
              const { target, currentTarget, ...others } = e;
              const onChangeE = { target: currentTarget, ...others };
              onChange(onChangeE);
            }}
            style={style}
          >
            <i className="fa fa-square-o" />
          </Button>
        )}
        <p
          className={classnames({
            'invalid-feedback': validation && !valid,
            'valid-feedback': validation && showValid && valid,
          })}
          style={
            (validation && !valid) || (validation && showValid && valid)
              ? { display: 'block' }
              : { display: 'none' }
          }
        >
          {messages[0]}
        </p>
      </>
    );
  }
}
ValidatedCheckButton.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  color: PropTypes.string,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  schema: PropTypes.instanceOf(Joi.constructor),
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  validationDisabled: PropTypes.bool,
  showValid: PropTypes.bool,
  placeholder: PropTypes.string,
};
ValidatedCheckButton.defaultProps = {
  checked: false,
  color: undefined,
  validation: false,
  schema: undefined,
  onChange: () => 'onChange STUB',
  onValidation: () => 'onValidation STUB',
  validationDisabled: false,
  showValid: false,
  placeholder: 'Value',
};

export default ValidatedCheckButton;
