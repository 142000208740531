import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import { ValidatedAttachment, ValidatedDate, ValidatedInput, ValidatedRadio } from '../../Custom';
import { FILETYPES } from '../../Custom/ValidatedAttachment';

function MedicalReportsHemodynamics({ donorMedicalReportsDetails, setDonorMedicalReportsDetails }) {

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="HBsAg"
            name="hbsagRemark"
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hbsagRemark}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hbsagRemark: e.target.value,
                  hbSagReport: {
                    name: '',
                    key: '',
                    url: '',
                  },
                  hbsagReportDate: null,
                },
              })
            }
            options={[
              {
                label: 'Positive',
                value: 'POSITIVE',
              },
              {
                label: 'Negative',
                value: 'NEGATIVE',
              },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">HBsAg Report Date</Typography>
          <ValidatedDate
            required
            fullWidth
            label=""
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hbsagReportDate}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hbsagReportDate: v,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">HBsAg Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hbSagReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hbSagReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="HBcAb"
            name="hbcabRemark"
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hbcabRemark}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hbcabRemark: e.target.value,
                  hbcabReport: {
                    name: '',
                    key: '',
                    url: '',
                  },
                  hbcabReportDate: null,
                },
              })
            }
            options={[
              {
                label: 'Positive',
                value: 'POSITIVE',
              },
              {
                label: 'Negative',
                value: 'NEGATIVE',
              },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">HBcAb Report Date</Typography>
          <ValidatedDate
            required
            fullWidth
            label=""
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hbcabReportDate}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hbcabReportDate: v,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">HBcAb Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hbcabReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hbcabReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        {donorMedicalReportsDetails?.viralMarkerStatus?.hbsagRemark === 'POSITIVE' &&
        donorMedicalReportsDetails?.viralMarkerStatus?.hbcabRemark === 'POSITIVE' ? (
          <>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">HBV DNA</Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorMedicalReportsDetails?.viralMarkerStatus?.hbvdna}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    viralMarkerStatus: {
                      ...donorMedicalReportsDetails.viralMarkerStatus,
                      hbvdna: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">HBV DNA Report</Typography>
              <ValidatedAttachment
                required
                label="Attachment"
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                value={donorMedicalReportsDetails?.viralMarkerStatus?.hbvdnaReport}
                onChange={(e) =>
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    viralMarkerStatus: {
                      ...donorMedicalReportsDetails.viralMarkerStatus,
                      hbvdnaReport: {
                        key: '',
                        name: e.target.files[0]?.name,
                        file: e.target.files[0],
                      },
                    },
                  })
                }
              />
            </Grid>
            <Grid item sm={4} xs={12} />
          </>
        ) : null}

        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Anti HCV (Hepatitis C)"
            name="antiHcvRemark"
            value={donorMedicalReportsDetails?.viralMarkerStatus?.antiHcvRemark}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  antiHcvRemark: e.target.value,
                  antiHcvReport: {
                    name: '',
                    key: '',
                    url: '',
                  },
                  antiHcvReportDate: null,
                },
              })
            }
            options={[
              {
                label: 'Positive',
                value: 'POSITIVE',
              },
              {
                label: 'Negative',
                value: 'NEGATIVE',
              },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Anti HCV (Hepatitis C) Report Date</Typography>
          <ValidatedDate
            required
            fullWidth
            label=""
            value={donorMedicalReportsDetails?.viralMarkerStatus?.antiHcvReportDate}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  antiHcvReportDate: v,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Anti HCV (Hepatitis C) Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.viralMarkerStatus?.antiHcvReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  antiHcvReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        {donorMedicalReportsDetails?.viralMarkerStatus?.antiHcvRemark === 'POSITIVE' ? (
          <>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">HCV RNA</Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorMedicalReportsDetails?.viralMarkerStatus?.hcvrna}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    viralMarkerStatus: {
                      ...donorMedicalReportsDetails.viralMarkerStatus,
                      hcvrna: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">HCV RNA Report</Typography>
              <ValidatedAttachment
                required
                label="Attachment"
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                value={donorMedicalReportsDetails?.viralMarkerStatus?.hcvrnaReport}
                onChange={(e) =>
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    viralMarkerStatus: {
                      ...donorMedicalReportsDetails.viralMarkerStatus,
                      hcvrnaReport: {
                        key: '',
                        name: e.target.files[0]?.name,
                        file: e.target.files[0],
                      },
                    },
                  })
                }
              />
            </Grid>
            <Grid item sm={4} xs={12} />
          </>
        ) : null}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">HCV RNA Quantitative</Typography>
          <RadioGroup
            row={true}
            aria-label="HCV RNA Quantitative"
            name="hcvRnaQuantitativeRemark"
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hcvRnaQuantitativeRemark: e.target.value,
                  hcvRnaQuantitativeReport: {
                    name: '',
                    key: '',
                    url: '',
                  },
                  hcvRnaQuantitativeReportDate: null,
                },
              })
            }
          >
            {[
              {
                label: 'Positive',
                value: 'POSITIVE',
              },
              {
                label: 'Negative',
                value: 'NEGATIVE',
              },
            ].map((o) => (
              <FormControlLabel value={`${o.value}`} control={<Radio />} label={o.label} />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">HCV RNA Quantitative Report Date</Typography>
          <ValidatedDate
            required
            fullWidth
            label=""
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hcvRnaQuantitativeReportDate: v,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">HCV RNA Quantitative Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hcvRnaQuantitativeReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hcvRnaQuantitativeReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid> */}
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="HIV"
            name="hivRemark"
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hivRemark}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hivRemark: e.target.value,
                  hivReport: {
                    name: '',
                    key: '',
                    url: '',
                  },
                  hivReportDate: null,
                },
              })
            }
            options={[
              {
                label: 'Positive',
                value: 'POSITIVE',
              },
              {
                label: 'Negative',
                value: 'NEGATIVE',
              },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">HIV Report Date</Typography>
          <ValidatedDate
            required
            fullWidth
            label=""
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hivReportDate}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hivReportDate: v,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">HIV Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hivReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hivReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Hepatetis C</Typography>
          <RadioGroup
            row={true}
            aria-label="Hepatetis C"
            name="hepatetisCRemark"
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hepatetisCRemark}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hepatetisCRemark: e.target.value,
                  hepatetisCReport: {
                    name: '',
                    key: '',
                    url: '',
                  },
                  hepatetisCReportDate: null,
                },
              })
            }
          >
            {[
              {
                label: 'Positive',
                value: 'POSITIVE',
              },
              {
                label: 'Negative',
                value: 'NEGATIVE',
              },
            ].map((o) => (
              <FormControlLabel value={`${o.value}`} control={<Radio />} label={o.label} />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Hepatetis C Report Date</Typography>
          <ValidatedDate
            required
            fullWidth
            label=""
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hepatetisCReportDate}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hepatetisCReportDate: v,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Hepatetis C Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.viralMarkerStatus?.hepatetisCReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                viralMarkerStatus: {
                  ...donorMedicalReportsDetails.viralMarkerStatus,
                  hepatetisCReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid> */}
      </Grid>
    </>
  );
}

MedicalReportsHemodynamics.propTypes = {
  donorMedicalReportsDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  setDonorMedicalReportsDetails: PropTypes.func.isRequired,
};
MedicalReportsHemodynamics.defaultProps = {};

export default MedicalReportsHemodynamics;
