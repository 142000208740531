export const DISTRICTS_MN = [
  {
    label: 'Bishnupur',
    value: 'BISHNUPUR'
  },
  {
    label: 'Chandel',
    value: 'CHANDEL'
  },
  {
    label: 'Churachandpur',
    value: 'CHURACHANDPUR'
  },
  {
    label: 'Imphal East',
    value: 'IMPHAL_EAST'
  },
  {
    label: 'Imphal West',
    value: 'IMPHAL_WEST'
  },
  {
    label: 'Jiribam',
    value: 'JIRIBAM'
  },
  {
    label: 'Kakching',
    value: 'KAKCHING'
  },
  {
    label: 'Kamjong',
    value: 'KAMJONG'
  },
  {
    label: 'Kangpokpi',
    value: 'KANGPOKPI'
  },
  {
    label: 'Noney',
    value: 'NONEY'
  },
  {
    label: 'Pherzawl',
    value: 'PHERZAWL'
  },
  {
    label: 'Senapati',
    value: 'SENAPATI'
  },
  {
    label: 'Tamenglong',
    value: 'TAMENGLONG'
  },
  {
    label: 'Tengnoupal',
    value: 'TENGNOUPAL'
  },
  {
    label: 'Thoubal',
    value: 'THOUBAL'
  },
  {
    label: 'Ukhrul',
    value: 'UKHRUL'
  }
];
