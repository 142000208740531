import Loadable from 'react-loadable';
import {
  isHospitalIcUserType,
  isPatientAccessUserType,
  isRottoSottoUserType,
  isZtccUserType,
} from './constants';
import { LoadingComponent } from './components/Custom';
import { exact } from 'prop-types';
import EyeDonor from './views/eyebank/transplant/EyeDonor/EyeDonor';
import EyeDonorOUI from './views/eyebank/transplant/EyeDonor/EyeDonorOUI';

// CorneaMatching Component
const CorneaMatchingList = Loadable({
  loader: () => import('./views/eyebank/transplant/CorneaMatchingList/CorneaMatchingList'),
  loading: LoadingComponent,
})

const EyeDonorList = Loadable({
  loader: () => import('./views/eyebank/transplant/EyeDonorList/EyeDonorList'),
  loading: LoadingComponent,
})

const EyeRecipientList = Loadable({
  loader: () => import('./views/eyebank/transplant/EyeRecipientList/EyeRecipientList'),
  loading: LoadingComponent,
})

const Dashboard = Loadable({
  loader: () => import('./components/Dashboard/Dashboard'),
  loading: LoadingComponent,
});

const DhsDashboard = Loadable({
  loader: () => import('./components/Dashboard/DhsDashboard'),
  loading: LoadingComponent,
});

const RottoSottoDashboard = Loadable({
  loader: () => import('./components/Dashboard/RottoSottoDashboard'),
  loading: LoadingComponent,
});

const ZtccDashboard = Loadable({
  loader: () => import('./components/Dashboard/ZtccDashboard'),
  loading: LoadingComponent,
});

const HospitalProfile = Loadable({
  loader: () => import('./components/HospitalProfile/HospitalProfile'),
  loading: LoadingComponent,
});

const GriefCounselling = Loadable({
  loader: () => import('./components/GriefCounselling/GriefCounselling'),
  loading: LoadingComponent,
});

const Form7 = Loadable({
  loader: () => import('./components/Form7/Form7'),
  loading: LoadingComponent,
});

const Form12ListTabs = Loadable({
  loader: () => import('./components/Form12ListTabs/Form12ListTabs'),
  loading: LoadingComponent,
});

const Form12 = Loadable({
  loader: () => import('./components/Form12/Form12'),
  loading: LoadingComponent,
});

const LegacyForm12 = Loadable({
  loader: () => import('./components/LegacyForm12/LegacyForm12'),
  loading: LoadingComponent,
});

const Form13 = Loadable({
  loader: () => import('./components/Form13/Form13'),
  loading: LoadingComponent,
});

const LegacyForm13 = Loadable({
  loader: () => import('./components/LegacyForm13/LegacyForm13'),
  loading: LoadingComponent,
});

const Form13ListTabs = Loadable({
  loader: () => import('./components/Form13ListTabs/Form13ListTabs'),
  loading: LoadingComponent,
});

const Form14 = Loadable({
  loader: () => import('./components/Form14/Form14'),
  loading: LoadingComponent,
});

const LegacyForm14 = Loadable({
  loader: () => import('./components/LegacyForm14/LegacyForm14'),
  loading: LoadingComponent,
});

const Form14ListTabs = Loadable({
  loader: () => import('./components/Form14ListTabs/Form14ListTabs'),
  loading: LoadingComponent,
});

const Form15Eb = Loadable({
  loader: () => import('./components/Form15Eb/Form15Eb'),
  loading: LoadingComponent,
});

const LegacyForm15Eb = Loadable({
  loader: () => import('./components/LegacyForm15Eb/LegacyForm15Eb'),
  loading: LoadingComponent,
});

const Form15EbListTabs = Loadable({
  loader: () => import('./components/Form15EbListTabs/Form15EbListTabs'),
  loading: LoadingComponent,
});

const Form15Ctc = Loadable({
  loader: () => import('./components/Form15Ctc/Form15Ctc'),
  loading: LoadingComponent,
});

const LegacyForm15Ctc = Loadable({
  loader: () => import('./components/LegacyForm15Ctc/LegacyForm15Ctc'),
  loading: LoadingComponent,
});

const Form15CtcListTabs = Loadable({
  loader: () => import('./components/Form15CtcListTabs/Form15CtcListTabs'),
  loading: LoadingComponent,
});

const Form15Erc = Loadable({
  loader: () => import('./components/Form15Erc/Form15Erc'),
  loading: LoadingComponent,
});

const LegacyForm15Erc = Loadable({
  loader: () => import('./components/LegacyForm15Erc/LegacyForm15Erc'),
  loading: LoadingComponent,
});

const Form15ErcListTabs = Loadable({
  loader: () => import('./components/Form15ErcListTabs/Form15ErcListTabs'),
  loading: LoadingComponent,
});

const Transplant = Loadable({
  loader: () => import('./components/Transplant/Transplant'),
  loading: LoadingComponent,
});

const TransplantList = Loadable({
  loader: () => import('./components/TransplantList/TransplantList'),
  loading: LoadingComponent,
});

const BsdDonor = Loadable({
  loader: () => import('./components/BsdDonor/BsdDonor'),
  loading: LoadingComponent,
});

const DonorDetails = Loadable({
  loader: () => import('./components/BsdDonor/DonorDetails'),
  loading: LoadingComponent,
});

const UpBsdDonor = Loadable({
  loader: () => import('./components/BsdDonor/BsdDonor_bk'),
  loading: LoadingComponent,
})

const BsdDonorList = Loadable({
  loader: () => import('./components/BsdDonorList/BsdDonorList'),
  loading: LoadingComponent,
});

const Kyp = Loadable({
  loader: () => import('./components/Kyp/Kyp'),
  loading: LoadingComponent,
});

const KypList = Loadable({
  loader: () => import('./components/KypList/KypList'),
  loading: LoadingComponent,
});

const LivingOrganTransplantList = Loadable({
  loader: () => import('./components/LivingOrganTransplantList/LivingOrganTransplantList'),
  loading: LoadingComponent,
});

const LivingOrganTransplant = Loadable({
  loader: () => import('./components/LivingOrganTransplant/LivingOrganTransplant'),
  loading: LoadingComponent,
});

const SwapTransplantList = Loadable({
  loader: () => import('./components/SwapTransplantList/SwapTransplantList'),
  loading: LoadingComponent,
});

const SwapAnalysis = Loadable({
  loader: () => import('./components/SwapAnalysis/SwapAnalysis'),
  loading: LoadingComponent,
});

const SwapTransplant = Loadable({
  loader: () => import('./components/SwapTransplant/SwapTransplant'),
  loading: LoadingComponent,
});

const SwapAnalysisRecordList = Loadable({
  loader: () => import('./components/SwapAnalysisRecordList/SwapAnalysisRecordList'),
  loading: LoadingComponent,
});

const SwapAnalysisRecord = Loadable({
  loader: () => import('./components/SwapAnalysisRecord/SwapAnalysisRecord'),
  loading: LoadingComponent,
});

const Reports = Loadable({
  loader: () => import('./components/Reports/Reports'),
  loading: LoadingComponent,
});

const TransplantCoordinator = Loadable({
  loader: () => import('./components/TransplantCoordinator/TransplantCoordinator'),
  loading: LoadingComponent,
});

const TransplantCoordinatorList = Loadable({
  loader: () => import('./components/TransplantCoordinatorList/TransplantCoordinatorList'),
  loading: LoadingComponent,
});

const Specialist = Loadable({
  loader: () => import('./components/Specialist/Specialist'),
  loading: LoadingComponent,
});

const SpecialistList = Loadable({
  loader: () => import('./components/SpecialistList/SpecialistList'),
  loading: LoadingComponent,
});

const HospitalList = Loadable({
  loader: () => import('./components/HospitalList/HospitalList'),
  loading: LoadingComponent,
});

const TransplantAndRetrievalCentersList = Loadable({
  loader: () =>
    import('./components/TransplantAndRetrievalCentersList/TransplantAndRetrievalCentersList'),
  loading: LoadingComponent,
});

const DonorList = Loadable({
  loader: () => import('./components/DonorList/DonorList'),
  loading: LoadingComponent,
});

const RecipientList = Loadable({
  loader: () => import('./components/RecipientList/RecipientList'),
  loading: LoadingComponent,
});

const InstituteList = Loadable({
  loader: () => import('./components/InstituteList/InstituteList'),
  loading: LoadingComponent,
});

const ZtccList = Loadable({
  loader: () => import('./components/ZtccList/ZtccList'),
  loading: LoadingComponent,
});

const MedicalIncharge = Loadable({
  loader: () => import('./components/MedicalIncharge/MedicalIncharge'),
  loading: LoadingComponent,
});

const Form7List = Loadable({
  loader: () => import('./components/Form7List/Form7List'),
  loading: LoadingComponent,
});

const DonorProfile = Loadable({
  loader: () => import('./components/DonorProfile/DonorProfile'),
  loading: LoadingComponent,
});

const NewBroadcast = Loadable({
  loader: () => import('./components/ForumBroadcast/NewBroadcast'),
  loading: LoadingComponent,
});

const BroadcastList = Loadable({
  loader: () => import('./components/ForumBroadcast/BroadcastList'),
  loading: LoadingComponent,
});

const RecievedBroadcastList = Loadable({
  loader: () => import('./components/ForumBroadcast/RecievedBroadcastList'),
  loading: LoadingComponent,
});

const BroadcastDiscussion = Loadable({
  loader: () => import('./components/ForumBroadcast/BroadcastDiscussion'),
  loading: LoadingComponent,
});

const BroadcastDiscussionList = Loadable({
  loader: () => import('./components/ForumBroadcast/BroadcastDiscussionList'),
  loading: LoadingComponent,
});

const BroadcastResponseList = Loadable({
  loader: () => import('./components/ForumBroadcast/BroadcastResponseList'),
  loading: LoadingComponent,
});

const OrganCallList = Loadable({
  loader: () => import('./components/OrganCall/OrganCallList'),
  loading: LoadingComponent,
});

const RecipientsList = Loadable({
  loader: () => import('./components/OrganCall/RecipientsList'),
  loading: LoadingComponent,
});

const OrganCall = Loadable({
  loader: () => import('./components/OrganCall/OrganCall'),
  loading: LoadingComponent,
});

const OrganRecipient = Loadable({
  loader: () => import('./components/OrganCall/OrganRecipient'),
  loading: LoadingComponent,
});

const AllocationRecipientListing = Loadable({
  loader: () => import('./components/Allocation/AllocationRecipientList'),
  loading: LoadingComponent,
});

const Registration = Loadable({
  loader: () => import('./components/Allocation/Registration'),
  loading: LoadingComponent,
});

const DonorView = Loadable({
  loader: () => import('./components/Allocation/DonorView'),
  loading: LoadingComponent,
});

const DraftAllocationRecipient = Loadable({
  loader: () => import('./components/Allocation/Recipient/DraftAllocationRecipient'),
  loading: LoadingComponent,
});

const AllocationRecipient = Loadable({
  loader: () => import('./components/Allocation/Recipient/AllocationRecipient'),
  loading: LoadingComponent,
});
const AllocationRecipientAttachment = Loadable({
  loader: () => import('./components/Allocation/Recipient/AllocationRecipientAttachment'),
  loading: LoadingComponent,
});

const AllocationDonorList = Loadable({
  loader: () => import('./components/Allocation/Donor/AllocationDonorList'),
  loading: LoadingComponent,
});

const AllocationDonor = Loadable({
  loader: () => import('./components/Allocation/Donor/AllocationDonor'),
  loading: LoadingComponent,
});
const AllocationDonorEcdDcdInfo = Loadable({
  loader: () => import('./components/Allocation/Donor/AllocationDonorEcdDcdInfo'),
  loading: LoadingComponent,
});
const DraftAllocationDonor = Loadable({
  loader: () => import('./components/Allocation/Donor/DraftAllocationDonor'),
  loading: LoadingComponent,
});

const Allocation = Loadable({
  loader: () => import('./components/Allocation/Allocation'),
  loading: LoadingComponent,
});

const MlasDetails = Loadable({
  loader: () => import('./components/MlasScoring/MlasScoring'),
  loading: LoadingComponent,
});

const CommitteeMember = Loadable({
  loader: () => import('./components/CommitteeMember/CommitteeMember'),
  loading: LoadingComponent,
});

const CommitteeMemberList = Loadable({
  loader: () => import('./components/CommitteeMemberList/CommitteeMemberList'),
  loading: LoadingComponent,
});

const Ddhs = Loadable({
  loader: () => import('./components/Ddhs/Ddhs'),
  loading: LoadingComponent,
});

const DdhsList = Loadable({
  loader: () => import('./components/DdhsList/DdhsList'),
  loading: LoadingComponent,
});

const PatientAccessList = Loadable({
  loader: () => import('./components/PatientAccessList/PatientAccessList'),
  loading: LoadingComponent,
});
const PatientAccess = Loadable({
  loader: () => import('./components/PatientAccess/PatientAccess'),
  loading: LoadingComponent,
});

export default (hospitalType, userType) => {
  if (hospitalType === 'HOSPITAL') {
    return [
      { path: '/dashboard', component: Dashboard, exact: true },
      { path: '/hospitalProfile', component: HospitalProfile, exact: true },
      { path: '/griefCounselling', component: GriefCounselling, exact: true },
      { path: '/form12', component: Form12, exact: true },
      { path: '/form12List', component: Form12ListTabs, exact: true },
      //Cornea Matching Route
      { path: '/corneaMatchingList', component: CorneaMatchingList, exact: true },
      { path: '/eyeDonorList', component: EyeDonorList, exact: true },
      { path: '/eyeRecipientList', component: EyeRecipientList, exact: true },
      { path: '/dashboard', redirect: true },
    ];
  }
  if (!hospitalType) {
    if (isPatientAccessUserType(userType)) {
      return [
        { path: '/kypList', component: KypList, exact: true },
        { path: '/kyp', component: Kyp, exact: true },
        { path: '/kyp/record/:kypRequestId', component: Kyp, exact: true },
        {
          path: '/livingOrganTransplantList',
          component: LivingOrganTransplantList,
          exact: true,
        },
        { path: '/livingOrganTransplant', component: LivingOrganTransplant, exact: true },
        {
          path: '/livingOrganTransplant/record/:formId',
          component: LivingOrganTransplant,
          exact: true,
        },
        {
          path: '/livingOrganTransplantList',
          component: LivingOrganTransplantList,
          redirect: true,
        },
      ];
    }
    if (isHospitalIcUserType(userType)) {
      return [
        { path: '/dashboard', component: Dashboard, exact: true },
        { path: '/hospitalProfile', component: HospitalProfile, exact: true },
        { path: '/medicalIncharge/processed/:userId', component: MedicalIncharge, exact: true },
        { path: '/transplantCoordinatorList', component: TransplantCoordinatorList, exact: true },
        { path: '/transplantCoordinator/:_id', component: TransplantCoordinator, exact: true },
        { path: '/transplantCoordinator/new', component: TransplantCoordinator, exact: true },
        { path: '/specialistList', component: SpecialistList, exact: true },
        { path: '/specialist/processed/:userId', component: Specialist, exact: true },
        { path: '/specialist/new', component: Specialist, exact: true },
        { path: '/griefCounselling', component: GriefCounselling, exact: true },
        { path: '/donorPledge', component: Form7, exact: true },
        { path: '/form12', component: Form12, exact: true },
        { path: '/legacyForm12', component: LegacyForm12, exact: true },
        { path: '/legacyForm12/saved/:draftId', component: LegacyForm12, exact: true },
        { path: '/legacyForm12/record/:form12Id', component: LegacyForm12, exact: true },
        { path: '/form12/saved/:draftId', component: Form12, exact: true },
        { path: '/form12/record/:form12Id', component: Form12, exact: true },
        { path: '/form12List', component: Form12ListTabs, exact: true },
        { path: '/form13', component: Form13, exact: true },
        { path: '/legacyForm13/new', component: LegacyForm13, exact: true },
        { path: '/legacyForm13/saved/:draftId', component: LegacyForm13, exact: true },
        { path: '/legacyForm13/record/:form14Id', component: LegacyForm13, exact: true },
        { path: '/form13/saved/:draftId', component: Form13, exact: true },
        { path: '/form13/record/:form13Id', component: Form13, exact: true },
        { path: '/form12/record/:form12Id', component: Form12, exact: true },
        { path: '/form13List', component: Form13ListTabs, exact: true },
        { path: '/form14', component: Form14, exact: true },
        { path: '/form14List', component: Form14ListTabs, exact: true },
        { path: '/form14/saved/:draftId', component: Form14, exact: true },
        { path: '/form14/record/:form14Id', component: Form14, exact: true },
        { path: '/legacyForm14', component: LegacyForm14, exact: true },
        { path: '/legacyForm14/saved/:draftId', component: LegacyForm14, exact: true },
        { path: '/legacyForm14/record/:form14Id', component: LegacyForm14, exact: true },
        { path: '/form15Eb', component: Form15Eb, exact: true },
        { path: '/form15Eb/saved/:draftId', component: Form15Eb, exact: true },
        { path: '/form15Eb/record/:form15EbId', component: Form15Eb, exact: true },
        { path: '/form15EbList', component: Form15EbListTabs, exact: true },
        { path: '/legacyForm15Eb', component: LegacyForm15Eb, exact: true },
        { path: '/legacyForm15Eb/saved/:draftId', component: LegacyForm15Eb, exact: true },
        { path: '/legacyForm15Eb/record/:form15EbId', component: LegacyForm15Eb, exact: true },
        { path: '/form15Erc', component: Form15Erc, exact: true },
        { path: '/form15Erc/saved/:draftId', component: Form15Erc, exact: true },
        { path: '/form15Erc/record/:form15ErcId', component: Form15Erc, exact: true },
        { path: '/form15ErcList', component: Form15ErcListTabs, exact: true },
        { path: '/legacyForm15Erc', component: LegacyForm15Erc, exact: true },
        { path: '/legacyForm15Erc/saved/:draftId', component: LegacyForm15Erc, exact: true },
        { path: '/legacyForm15Erc/record/:form15ErcId', component: LegacyForm15Erc, exact: true },
        { path: '/form15Ctc', component: Form15Ctc, exact: true },
        { path: '/form15Ctc/saved/:draftId', component: Form15Ctc, exact: true },
        { path: '/form15Ctc/record/:form15CtcId', component: Form15Ctc, exact: true },
        { path: '/form15CtcList', component: Form15CtcListTabs, exact: true },
        { path: '/legacyForm15Ctc', component: LegacyForm15Ctc, exact: true },
        { path: '/legacyForm15Ctc/saved/:draftId', component: LegacyForm15Ctc, exact: true },
        { path: '/legacyForm15Ctc/record/:form15CtcId', component: LegacyForm15Ctc, exact: true },
        { path: '/transplant', component: Transplant, exact: true },
        { path: '/transplant/saved/:draftId', component: Transplant, exact: true },
        { path: '/transplant/record/:transplantId', component: Transplant, exact: true },
        { path: '/transplantList', component: TransplantList, exact: true },
        { path: '/reports', component: Reports, exact: true },
        { path: '/bsdDonorList', component: BsdDonorList, exact: true },
        { path: '/bsdDonor', component: BsdDonor, exact: true },
        { path: '/donorDetails', component: DonorDetails, exact: true },

        // { path: '/UpBsdDonor', component: UpBsdDonor, exact: true }, // Test URL
        // { path: '/UpBsdDonor/saved/:draftId', component: UpBsdDonor, exact: true },
        // { path: '/UpBsdDonor/record/:donorId', component: UpBsdDonor, exact: true },

        { path: '/bsdDonor/saved/:draftId', component: BsdDonor, exact: true },
        { path: '/bsdDonor/record/:donorId', component: BsdDonor, exact: true },
        { path: '/kypList', component: KypList, exact: true },
        { path: '/kyp', component: Kyp, exact: true },
        { path: '/kyp/record/:kypRequestId', component: Kyp, exact: true },
        { path: '/livingOrganTransplantList', component: LivingOrganTransplantList, exact: true },
        { path: '/livingOrganTransplant', component: LivingOrganTransplant, exact: true },
        {
          path: '/livingOrganTransplant/record/:formId',
          component: LivingOrganTransplant,
          exact: true,
        },
        { path: '/swapTransplantList', component: SwapTransplantList, exact: true },
        { path: '/swapTransplant', component: SwapTransplant, exact: true },
        {
          path: '/swapTransplant/record/:formId',
          component: SwapTransplant,
          exact: true,
        },
        { path: '/swapAnalysis', component: SwapAnalysis, exact: true },
        { path: '/swapAnalysisRecordsList', component: SwapAnalysisRecordList, exact: true },
        {
          path: '/swapAnalysisRecord/:formId',
          component: SwapAnalysisRecord,
          exact: true,
        },
        { path: '/committeeMemberList', component: CommitteeMemberList, exact: true },
        { path: '/committeeMember', component: CommitteeMember, exact: true },
        { path: '/committeeMember/:_id', component: CommitteeMember, exact: true },
        { path: '/committeeMember/new', component: CommitteeMember, exact: true },
        { path: '/patientAccessList', component: PatientAccessList, exact: true },
        { path: '/patientAccess', component: PatientAccess, exact: true },
        { path: '/patientAccessRecord/:_id', component: PatientAccess, exact: true },
        { path: '/patientAccess/new', component: PatientAccess, exact: true },
        { path: '/medicalIncharge', component: MedicalIncharge, exact: true },
        { path: '/donorprofile/:form7Id', component: DonorProfile, exact: true },
        { path: '/pledgedDonorsList', component: Form7List, exact: true },
        { path: '/initiatedAllocation/:initiatedAllocationId', component: Allocation, exact: true },
        { path: '/form7/record/:form7Id', component: Form7, exact: true },
        { path: '/donorPledgeForm/:hospitalId', component: Form7, exact: true },
        { path: '/forumBroadcastList', component: RecievedBroadcastList, exact: true },
        { path: '/forumBroadcastDiscussionList', component: BroadcastDiscussionList, exact: true },
        {
          path: '/forumBroadcastDiscussion/:broadcastId',
          component: BroadcastDiscussion,
          exact: true,
        },
        { path: '/organCallList', component: OrganCallList, exact: true },
        { path: '/organCall/:organCallId', component: OrganCall, exact: true },
        { path: '/recipientsList', component: RecipientsList, exact: true },
        { path: '/organRecipient/new', component: OrganRecipient, exact: true },
        { path: '/organRecipient/record/:recipientId', component: OrganRecipient, exact: true },
        { path: '/allocationRecipientListing', component: AllocationRecipientListing, exact: true },
        {
          path: '/allocationRecipientListing/new',
          component: AllocationRecipient,
          exact: true,
        },
        {
          path: '/registration/recipient',
          component: Registration,
          exact: true,
        },
        {
          path: '/registration/donor',
          component: Registration,
          exact: true,
        },
        {
          path: '/registration/transfer',
          component: Registration,
          exact: true,
        },
        {
          path: '/allocationRecipientListing/:_id',
          component: AllocationRecipient,
          exact: true,
        },
        {
          path: '/allocationRecipientListing/uploadedDocument/:_id',
          component: AllocationRecipientAttachment,
          exact: true,
        },
        {
          path: '/recipientListing/saved/:_id',
          component: DraftAllocationRecipient,
          exact: true,
        },
        {
          path: '/updateAllocationRecipientListing/:_id',
          component: AllocationRecipient,
          exact: true,
        },
        {
          path: '/recipientListing/record/:_id',
          component: DraftAllocationRecipient,
          exact: true,
        },
        { path: '/allocationDonor', component: AllocationDonorList, exact: true },
        {
          path: '/donorListing/new',
          component: AllocationRecipient,
          exact: true,
        },
        {
          path: '/allocationDonor/:_id',
          component: AllocationDonor,
          exact: true,
        },
        {
          path: '/allocationDonor/record/:_id',
          component: DraftAllocationDonor,
          exact: true,
        },
        {
          path: '/donorListing/record/:_id',
          component: DraftAllocationDonor,
          exact: true,
        },
        {
          path: '/updateAllocationDonorListing/:_id',
          component: AllocationDonor,
          exact: true,
        },
        {
          path: '/mlas/:_id',
          component: MlasDetails,
          exact: true,
        },
        // Cornea Matching Routes
        { path: '/corneaMatchingList', component: CorneaMatchingList, exact: true },
        { path: '/eyeDonorList', component: EyeDonorList, exact: true },
        { path: '/eyeDonor', component: EyeDonor, exact: true },
        { path: '/eyeDonor/saved/:draftId', component: EyeDonor, exact: true },
        { path: '/eyeDonor/record/:eyeDonorId', component: EyeDonor, exact: true },

        { path: '/eyeRecipientList', component: EyeRecipientList, exact: true },
        { path: '/newDonorOUI', component: EyeDonorOUI, exact: true },
        // { path:'',component: '' ,exact:true},

        { path: '/dashboard', redirect: true },
      ];
    }
    if (isZtccUserType(userType)) {
      return [
        { path: '/ztccDashboard', component: ZtccDashboard, exact: true },
        { path: '/institutesList', component: InstituteList, exact: true },
        { path: '/ztccList', component: ZtccList, exact: true },
        { path: '/hospitals', component: HospitalList, exact: true },
        {
          path: '/transplantAndRetrievalCenters',
          component: TransplantAndRetrievalCentersList,
          exact: true,
        },
        {
          path: '/allocationRecipientListing/:_id',
          component: AllocationRecipient,
          exact: true,
        },
        {
          path: '/allocationDonor/:_id',
          component: AllocationDonor,
          exact: true,
        },
        { path: '/DonorView/:_id', component: DonorView, exact: true },

        {
          path: '/donorView/donorDetails/:_id',
          component: DonorView,
          exact: true,
        },
        {
          path: '/donorView/donorEcdDcdInfo/:_id',
          component: DonorView,
          exact: true,
        },

        {
          path: '/donorEcdDcdInfo/:_id',
          component: AllocationDonorEcdDcdInfo,
          exact: true,
        },
        { path: '/hospitalProfile', component: HospitalProfile, exact: true },
        { path: '/hospitalRequest/:requestId', component: HospitalProfile, exact: true },
        { path: '/hospitalDetails/:hospitalId', component: HospitalProfile, exact: true },
        { path: '/transplantCoordinatorList', component: TransplantCoordinatorList, exact: true },
        { path: '/transplantCoordinator', component: TransplantCoordinator, exact: true },
        { path: '/transplantCoordinator/:_id', component: TransplantCoordinator, exact: true },
        { path: '/transplantCoordinator/new', component: TransplantCoordinator, exact: true },
        { path: '/specialistList', component: SpecialistList, exact: true },
        { path: '/specialist/processed/:userId', component: Specialist, exact: true },
        { path: '/specialist/new', component: Specialist, exact: true },
        { path: '/griefCounselling', component: GriefCounselling, exact: true },
        { path: '/donorPledge', component: Form7, exact: true },
        { path: '/form12', component: Form12, exact: true },
        { path: '/legacyForm12', component: LegacyForm12, exact: true },
        { path: '/legacyForm12/saved/:draftId', component: LegacyForm12, exact: true },
        { path: '/legacyForm12/record/:form12Id', component: LegacyForm12, exact: true },
        { path: '/form12/saved/:draftId', component: Form12, exact: true },
        { path: '/form12/record/:form12Id', component: Form12, exact: true },
        { path: '/form12List', component: Form12ListTabs, exact: true },
        { path: '/form13', component: Form13, exact: true },
        { path: '/legacyForm13/new', component: LegacyForm13, exact: true },
        { path: '/legacyForm13/saved/:draftId', component: LegacyForm13, exact: true },
        { path: '/legacyForm13/record/:form13Id', component: LegacyForm13, exact: true },
        { path: '/form13/saved/:draftId', component: Form13, exact: true },
        { path: '/form13/record/:form13Id', component: Form13, exact: true },
        { path: '/form12/record/:form12Id', component: Form12, exact: true },
        { path: '/form13List', component: Form13ListTabs, exact: true },
        { path: '/form14', component: Form14, exact: true },
        { path: '/form14/saved/:draftId', component: Form14, exact: true },
        { path: '/form14/record/:form14Id', component: Form14, exact: true },
        { path: '/form14List', component: Form14ListTabs, exact: true },
        { path: '/legacyForm14', component: LegacyForm14, exact: true },
        { path: '/legacyForm14/saved/:draftId', component: LegacyForm14, exact: true },
        { path: '/legacyForm14/record/:form14Id', component: LegacyForm14, exact: true },
        { path: '/form15Eb', component: Form15Eb, exact: true },
        { path: '/form15Eb/saved/:draftId', component: Form15Eb, exact: true },
        { path: '/form15Eb/record/:form15EbId', component: Form15Eb, exact: true },
        { path: '/form15EbList', component: Form15EbListTabs, exact: true },
        { path: '/legacyForm15Eb', component: LegacyForm15Eb, exact: true },
        { path: '/legacyForm15Eb/saved/:draftId', component: LegacyForm15Eb, exact: true },
        { path: '/legacyForm15Eb/record/:form15EbId', component: LegacyForm15Eb, exact: true },
        { path: '/form15Erc', component: Form15Erc, exact: true },
        { path: '/form15Erc/saved/:draftId', component: Form15Erc, exact: true },
        { path: '/form15Erc/record/:form15ErcId', component: Form15Erc, exact: true },
        { path: '/form15ErcList', component: Form15ErcListTabs, exact: true },
        { path: '/legacyForm15Erc', component: LegacyForm15Erc, exact: true },
        { path: '/legacyForm15Erc/saved/:draftId', component: LegacyForm15Erc, exact: true },
        { path: '/legacyForm15Erc/record/:form15ErcId', component: LegacyForm15Erc, exact: true },
        { path: '/form15Ctc', component: Form15Ctc, exact: true },
        { path: '/form15Ctc/saved/:draftId', component: Form15Ctc, exact: true },
        { path: '/form15Ctc/record/:form15CtcId', component: Form15Ctc, exact: true },
        { path: '/form15CtcList', component: Form15CtcListTabs, exact: true },
        { path: '/legacyForm15Ctc', component: LegacyForm15Ctc, exact: true },
        { path: '/legacyForm15Ctc/saved/:draftId', component: LegacyForm15Ctc, exact: true },
        { path: '/legacyForm15Ctc/record/:form15CtcId', component: LegacyForm15Ctc, exact: true },
        { path: '/transplant', component: Transplant, exact: true },
        { path: '/transplant/saved/:draftId', component: Transplant, exact: true },
        { path: '/transplant/record/:transplantId', component: Transplant, exact: true },
        { path: '/transplantList', component: TransplantList, exact: true },
        { path: '/reports', component: Reports, exact: true },
        { path: '/bsdDonorList', component: BsdDonorList, exact: true },
        { path: '/bsdDonor', component: BsdDonor, exact: true },

        { path: '/donorDetails', component: DonorDetails, exact: true },

        { path: '/bsdDonor/record/:donorId', component: BsdDonor, exact: true },
        { path: '/medicalIncharge', component: MedicalIncharge, exact: true },
        { path: '/pledgedDonorsList', component: Form7List, exact: true },
        { path: '/initiatedAllocation/:initiatedAllocationId', component: Allocation, exact: true },
        { path: '/form7/record/:form7Id', component: Form7, exact: true },
        { path: '/donorprofile/:form7Id', component: DonorProfile, exact: true },
        { path: '/donorPledgeForm/:hospitalId', component: Form7, exact: true },
        { path: '/forumBroadcast/new', component: NewBroadcast, exact: true },
        { path: '/forumBroadcastList', component: BroadcastList, exact: true },
        { path: '/forumBroadcastDiscussionList', component: BroadcastDiscussionList, exact: true },
        {
          path: '/forumBroadcastDiscussion/:broadcastId',
          component: BroadcastDiscussion,
          exact: true,
        },
        {
          path: '/forumBroadcastMessageResponseStatus/:broadcastId',
          component: BroadcastResponseList,
        },
        { path: '/organCallList', component: OrganCallList, exact: true },
        { path: '/organCall/:organCallId', component: OrganCall, exact: true },
        { path: '/recipientsList', component: RecipientsList, exact: true },
        { path: '/organRecipient/record/:recipientId', component: OrganRecipient, exact: true },
        { path: '/ztccDashboard', redirect: true },
        { path: '/ztccDashboard', redirect: true },
        { path: '/allocationRecipientListing', component: AllocationRecipientListing, exact: true },
      ];
    }
    if (isRottoSottoUserType(userType)) {
      return [
        { path: '/rottoSottoDashboard', component: RottoSottoDashboard, exact: true },
        { path: '/institutesList', component: InstituteList, exact: true },
        { path: '/ztccList', component: ZtccList, exact: true },
        { path: '/hospitals', component: HospitalList, exact: true },
        {
          path: '/transplantAndRetrievalCenters',
          component: TransplantAndRetrievalCentersList,
          exact: true,
        },
        {
          path: '/donorList',
          component: DonorList,
          exact: true,
        },
        {
          path: '/recipientList',
          component: RecipientList,
          exact: true,
        },
        {
          path: '/allocationRecipientListing/:_id',
          component: AllocationRecipient,
          exact: true,
        },
        {
          path: '/registration/donor',
          component: Registration,
          exact: true,
        },
        {
          path: '/allocationDonor/:_id',
          component: AllocationDonor,
          exact: true,
        },
        { path: '/hospitalProfile', component: HospitalProfile, exact: true },
        { path: '/hospitalRequest/:requestId', component: HospitalProfile, exact: true },
        { path: '/hospitalDetails/:hospitalId', component: HospitalProfile, exact: true },
        { path: '/transplantCoordinatorList', component: TransplantCoordinatorList, exact: true },
        { path: '/transplantCoordinator', component: TransplantCoordinator, exact: true },
        { path: '/transplantCoordinator/:_id', component: TransplantCoordinator, exact: true },
        { path: '/transplantCoordinator/new', component: TransplantCoordinator, exact: true },
        { path: '/specialistList', component: SpecialistList, exact: true },
        { path: '/specialist/processed/:userId', component: Specialist, exact: true },
        { path: '/specialist/new', component: Specialist, exact: true },
        { path: '/griefCounselling', component: GriefCounselling, exact: true },
        { path: '/donorPledge', component: Form7, exact: true },
        { path: '/form12', component: Form12, exact: true },
        { path: '/legacyForm12', component: LegacyForm12, exact: true },
        { path: '/legacyForm12/saved/:draftId', component: LegacyForm12, exact: true },
        { path: '/legacyForm12/record/:form12Id', component: LegacyForm12, exact: true },
        { path: '/form12/saved/:draftId', component: Form12, exact: true },
        { path: '/form12/record/:form12Id', component: Form12, exact: true },
        { path: '/form12List', component: Form12ListTabs, exact: true },
        { path: '/form13', component: Form13, exact: true },
        { path: '/legacyForm13/new', component: LegacyForm13, exact: true },
        { path: '/legacyForm13/saved/:draftId', component: LegacyForm13, exact: true },
        { path: '/legacyForm13/record/:form13Id', component: LegacyForm13, exact: true },
        { path: '/form13/saved/:draftId', component: Form13, exact: true },
        { path: '/form13/record/:form13Id', component: Form13, exact: true },
        { path: '/form12/record/:form12Id', component: Form12, exact: true },
        { path: '/form13List', component: Form13ListTabs, exact: true },
        { path: '/form14', component: Form14, exact: true },
        { path: '/form14/saved/:draftId', component: Form14, exact: true },
        { path: '/form14/record/:form14Id', component: Form14, exact: true },
        { path: '/form14List', component: Form14ListTabs, exact: true },
        { path: '/legacyForm14', component: LegacyForm14, exact: true },
        { path: '/legacyForm14/saved/:draftId', component: LegacyForm14, exact: true },
        { path: '/legacyForm14/record/:form14Id', component: LegacyForm14, exact: true },
        { path: '/form15Eb', component: Form15Eb, exact: true },
        { path: '/form15Eb/saved/:draftId', component: Form15Eb, exact: true },
        { path: '/form15Eb/record/:form15EbId', component: Form15Eb, exact: true },
        { path: '/form15EbList', component: Form15EbListTabs, exact: true },
        { path: '/legacyForm15Eb', component: LegacyForm15Eb, exact: true },
        { path: '/legacyForm15Eb/saved/:draftId', component: LegacyForm15Eb, exact: true },
        { path: '/legacyForm15Eb/record/:form15EbId', component: LegacyForm15Eb, exact: true },
        { path: '/form15Erc', component: Form15Erc, exact: true },
        { path: '/form15Erc/saved/:draftId', component: Form15Erc, exact: true },
        { path: '/form15Erc/record/:form15ErcId', component: Form15Erc, exact: true },
        { path: '/form15ErcList', component: Form15ErcListTabs, exact: true },
        { path: '/legacyForm15Erc', component: LegacyForm15Erc, exact: true },
        { path: '/legacyForm15Erc/saved/:draftId', component: LegacyForm15Erc, exact: true },
        { path: '/legacyForm15Erc/record/:form15ErcId', component: LegacyForm15Erc, exact: true },
        { path: '/form15Ctc', component: Form15Ctc, exact: true },
        { path: '/form15Ctc/saved/:draftId', component: Form15Ctc, exact: true },
        { path: '/form15Ctc/record/:form15CtcId', component: Form15Ctc, exact: true },
        { path: '/form15CtcList', component: Form15CtcListTabs, exact: true },
        { path: '/legacyForm15Ctc', component: LegacyForm15Ctc, exact: true },
        { path: '/legacyForm15Ctc/saved/:draftId', component: LegacyForm15Ctc, exact: true },
        { path: '/legacyForm15Ctc/record/:form15CtcId', component: LegacyForm15Ctc, exact: true },
        { path: '/transplant', component: Transplant, exact: true },
        { path: '/transplant/saved/:draftId', component: Transplant, exact: true },
        { path: '/transplant/record/:transplantId', component: Transplant, exact: true },
        { path: '/transplantList', component: TransplantList, exact: true },
        { path: '/reports', component: Reports, exact: true },
        { path: '/bsdDonorList', component: BsdDonorList, exact: true },
        { path: '/bsdDonor', component: BsdDonor, exact: true },

        { path: '/UpBsdDonor', component: UpBsdDonor, exact: true }, // Test URL


        { path: '/bsdDonor/record/:donorId', component: BsdDonor, exact: true },
        { path: '/medicalIncharge', component: MedicalIncharge, exact: true },
        { path: '/pledgedDonorsList', component: Form7List, exact: true },
        { path: '/initiatedAllocation/:initiatedAllocationId', component: Allocation, exact: true },
        { path: '/form7/record/:form7Id', component: Form7, exact: true },
        { path: '/donorprofile/:form7Id', component: DonorProfile, exact: true },
        { path: '/donorPledgeForm/:hospitalId', component: Form7, exact: true },
        { path: '/forumBroadcast/new', component: NewBroadcast, exact: true },
        { path: '/forumBroadcastList', component: BroadcastList, exact: true },
        { path: '/forumBroadcastDiscussionList', component: BroadcastDiscussionList, exact: true },
        {
          path: '/forumBroadcastDiscussion/:broadcastId',
          component: BroadcastDiscussion,
          exact: true,
        },
        {
          path: '/forumBroadcastMessageResponseStatus/:broadcastId',
          component: BroadcastResponseList,
        },
        { path: '/organCallList', component: OrganCallList, exact: true },
        { path: '/organCall/:organCallId', component: OrganCall, exact: true },
        { path: '/recipientsList', component: RecipientsList, exact: true },
        { path: '/organRecipient/record/:recipientId', component: OrganRecipient, exact: true },
        { path: '/rottoSottoDashboard', redirect: true },
        { path: '/rottoSottoDashboard', redirect: true },
        { path: '/allocationRecipientListing', component: AllocationRecipientListing, exact: true },
      ];
    }
    if (userType === 'ADMIN') {
      return [
        { path: '/adminDashboard', component: InstituteList, exact: true },
        { path: '/institutesList', component: InstituteList, exact: true },
        { path: '/ztccList', component: ZtccList, exact: true },
        { path: '/hospitals', component: HospitalList, exact: true },
        {
          path: '/transplantAndRetrievalCenters',
          component: TransplantAndRetrievalCentersList,
          exact: true,
        },
        { path: '/kypList', component: KypList, exact: true },
        { path: '/kyp', component: Kyp, exact: true },
        { path: '/kyp/record/:kypRequestId', component: Kyp, exact: true },
        { path: '/livingOrganTransplantList', component: LivingOrganTransplantList, exact: true },
      ];
    }
    if (userType === 'HOSPITAL_PATIENT') {
      return [
        { path: '/kypList', component: KypList, exact: true },
        { path: '/kyp', component: Kyp, exact: true },
        { path: '/kyp/record/:kypRequestId', component: Kyp, exact: true },
        {
          path: '/livingOrganTransplantList',
          component: LivingOrganTransplantList,
          exact: true,
        },
        {
          path: '/livingOrganTransplantList',
          component: LivingOrganTransplantList,
          redirect: true,
        },
      ];
    }
    return [
      { path: '/dhsDashboard', component: DhsDashboard, exact: true },
      { path: '/rottoSottoDashboard', component: RottoSottoDashboard, exact: true },
      { path: '/ztccDashboard', component: ZtccDashboard, exact: true },
      { path: '/institutesList', component: InstituteList, exact: true },
      { path: '/ztccList', component: ZtccList, exact: true },
      { path: '/hospitals', component: HospitalList, exact: true },
      {
        path: '/transplantAndRetrievalCenters',
        component: TransplantAndRetrievalCentersList,
        exact: true,
      },
      { path: '/hospitalProfile', component: HospitalProfile, exact: true },
      { path: '/hospitalRequest/:requestId', component: HospitalProfile, exact: true },
      { path: '/hospitalDetails/:hospitalId', component: HospitalProfile, exact: true },
      { path: '/transplantCoordinatorList', component: TransplantCoordinatorList, exact: true },
      { path: '/transplantCoordinator', component: TransplantCoordinator, exact: true },
      { path: '/transplantCoordinator/:_id', component: TransplantCoordinator, exact: true },
      { path: '/transplantCoordinator/new', component: TransplantCoordinator, exact: true },
      { path: '/specialistList', component: SpecialistList, exact: true },
      { path: '/specialist/processed/:userId', component: Specialist, exact: true },
      { path: '/specialist/new', component: Specialist, exact: true },
      { path: '/griefCounselling', component: GriefCounselling, exact: true },
      { path: '/donorPledge', component: Form7, exact: true },
      { path: '/form12', component: Form12, exact: true },
      { path: '/legacyForm12', component: LegacyForm12, exact: true },
      { path: '/legacyForm12/saved/:draftId', component: LegacyForm12, exact: true },
      { path: '/legacyForm12/record/:form12Id', component: LegacyForm12, exact: true },
      { path: '/form12/saved/:draftId', component: Form12, exact: true },
      { path: '/form12/record/:form12Id', component: Form12, exact: true },
      { path: '/form12List', component: Form12ListTabs, exact: true },
      { path: '/form13', component: Form13, exact: true },
      { path: '/legacyForm13/new', component: LegacyForm13, exact: true },
      { path: '/legacyForm13/saved/:draftId', component: LegacyForm13, exact: true },
      { path: '/legacyForm13/record/:form13Id', component: LegacyForm13, exact: true },
      { path: '/form13/saved/:draftId', component: Form13, exact: true },
      { path: '/form13/record/:form13Id', component: Form13, exact: true },
      { path: '/form12/record/:form12Id', component: Form12, exact: true },
      { path: '/form13List', component: Form13ListTabs, exact: true },
      { path: '/form14', component: Form14, exact: true },
      { path: '/form14/saved/:draftId', component: Form14, exact: true },
      { path: '/form14/record/:form14Id', component: Form14, exact: true },
      { path: '/form14List', component: Form14ListTabs, exact: true },
      { path: '/legacyForm14', component: LegacyForm14, exact: true },
      { path: '/legacyForm14/saved/:draftId', component: LegacyForm14, exact: true },
      { path: '/legacyForm14/record/:form14Id', component: LegacyForm14, exact: true },
      { path: '/form15Eb', component: Form15Eb, exact: true },
      { path: '/form15Eb/saved/:draftId', component: Form15Eb, exact: true },
      { path: '/form15Eb/record/:form15EbId', component: Form15Eb, exact: true },
      { path: '/form15EbList', component: Form15EbListTabs, exact: true },
      { path: '/legacyForm15Eb', component: LegacyForm15Eb, exact: true },
      { path: '/legacyForm15Eb/saved/:draftId', component: LegacyForm15Eb, exact: true },
      { path: '/legacyForm15Eb/record/:form15EbId', component: LegacyForm15Eb, exact: true },
      { path: '/form15Erc', component: Form15Erc, exact: true },
      { path: '/form15Erc/saved/:draftId', component: Form15Erc, exact: true },
      { path: '/form15Erc/record/:form15ErcId', component: Form15Erc, exact: true },
      { path: '/form15ErcList', component: Form15ErcListTabs, exact: true },
      { path: '/legacyForm15Erc', component: LegacyForm15Erc, exact: true },
      { path: '/legacyForm15Erc/saved/:draftId', component: LegacyForm15Erc, exact: true },
      { path: '/legacyForm15Erc/record/:form15ErcId', component: LegacyForm15Erc, exact: true },
      { path: '/form15Ctc', component: Form15Ctc, exact: true },
      { path: '/form15Ctc/saved/:draftId', component: Form15Ctc, exact: true },
      { path: '/form15Ctc/record/:form15CtcId', component: Form15Ctc, exact: true },
      { path: '/form15CtcList', component: Form15CtcListTabs, exact: true },
      { path: '/legacyForm15Ctc', component: LegacyForm15Ctc, exact: true },
      { path: '/legacyForm15Ctc/saved/:draftId', component: LegacyForm15Ctc, exact: true },
      { path: '/legacyForm15Ctc/record/:form15CtcId', component: LegacyForm15Ctc, exact: true },
      { path: '/transplant', component: Transplant, exact: true },
      { path: '/transplant/saved/:draftId', component: Transplant, exact: true },
      { path: '/transplant/record/:transplantId', component: Transplant, exact: true },
      { path: '/transplantList', component: TransplantList, exact: true },
      { path: '/reports', component: Reports, exact: true },
      { path: '/bsdDonorList', component: BsdDonorList, exact: true },
      { path: '/bsdDonor', component: BsdDonor, exact: true },
      { path: '/bsdDonor/record/:donorId', component: BsdDonor, exact: true },
      { path: '/kypList', component: KypList, exact: true },
      { path: '/kyp', component: Kyp, exact: true },
      { path: '/kyp/record/:kypRequestId', component: Kyp, exact: true },
      { path: '/committeeMemberList', component: CommitteeMemberList, exact: true },
      { path: '/committeeMember', component: CommitteeMember, exact: true },
      { path: '/committeeMember/:_id', component: CommitteeMember, exact: true },
      { path: '/committeeMember/new', component: CommitteeMember, exact: true },
      { path: '/ddhsList', component: DdhsList, exact: true },
      { path: '/ddhs', component: Ddhs, exact: true },
      { path: '/ddhs/:_id', component: Ddhs, exact: true },
      { path: '/ddhs/new', component: Ddhs, exact: true },
      { path: '/medicalIncharge', component: MedicalIncharge, exact: true },
      { path: '/pledgedDonorsList', component: Form7List, exact: true },
      { path: '/initiatedAllocation/:initiatedAllocationId', component: Allocation, exact: true },
      { path: '/form7/record/:form7Id', component: Form7, exact: true },
      { path: '/donorprofile/:form7Id', component: DonorProfile, exact: true },
      { path: '/donorPledgeForm/:hospitalId', component: Form7, exact: true },
      { path: '/allocationRecipientListing', component: AllocationRecipientListing, exact: true },
      { path: '/dhsDashboard', redirect: true },
    ];
  }
  return [{ path: '/dashboard', redirect: true }];
};
