import Joi from 'joi';
import { FIELDTYPES } from '../common/FieldsToInput';
import { BLOODGROUP, REGEXP_MOBILE, REGEXP_ONLYCHARS, SEX } from '../../constants';

const OPTIONS_YESNO = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
export default (validation, onValidation) => ({
  updateMlas: {
    'mlas.bilirubin': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'Bilirubin (mg/dl)',
    },
    'mlas.inr': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: ' INR',
    },
    'mlas.creatinine': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: ' Creatinine',
    },
    'mlas.dialysisTwice': {
      type: FIELDTYPES.RADIO,
      textLabel: 'Dialysis twice / 24hrs of CVVHDF in last 7 days',
      options: OPTIONS_YESNO,
      props: {
        validation,
        onValidation,
      },
    },
    'mlas.na': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: ' Na',
    },
    'mlas.albumin': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'Albumin (g/dL)',
    },
    'mlas.growthFailure': {
      type: FIELDTYPES.RADIO,
      textLabel: 'Growth failure at Listing <2 standard deviation',
      options: OPTIONS_YESNO,
      props: {
        validation,
        onValidation,
      },
    },
    'mlas.listingOptnMeld': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'MELD',
      disabled: true,
      isNotRequired: true,
    },
    'mlas.listingOptnPeld': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'PELD',
      disabled: true,
      isNotRequired: true,
    },
    'mlas.meld': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'MELD (age>12yrs) / PELD (age<=12yrs) for MLAS (A)',
      disabled: true,
      isNotRequired: true,
    },
  },
  mlas: {
    // 'mlas.dateOfBirth': {
    //   type: FIELDTYPES.DATE,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Date of Birth',
    //   disabled: false,
    //   gridProps: {
    //     sm: 4,
    //   },
    // },
    // 'mlas.currentAge': {
    //   type: FIELDTYPES.TEXT,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Current Age',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.dateOfListing': {
    //   type: FIELDTYPES.DATE,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Date of Listing',
    // },
    // 'mlas.listingAge': {
    //   type: FIELDTYPES.TEXT,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Listing Age',
    //   disabled: true, isNotRequired: true
    // },
    'mlas.listingBilirubin': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'Listing Bilirubin (mg/dl)',
    },
    'mlas.listingInr': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'Listing INR',
    },
    'mlas.listingCreatinine': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'Listing Creatinine',
    },
    'mlas.listingDialysisTwice': {
      type: FIELDTYPES.RADIO,
      textLabel: 'Dialysis twice / 24hrs of CVVHDF in last 7 days',
      options: OPTIONS_YESNO,
      props: {
        validation,
        onValidation,
      },
    },
    'mlas.listingNa': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'Listing Na',
    },
    'mlas.listingAlbumin': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'Listing Albumin (g/dL)',
    },
    'mlas.listingGrowthFailure': {
      type: FIELDTYPES.RADIO,
      textLabel: 'Growth failure at Listing <2 standard deviation',
      options: OPTIONS_YESNO,
      props: {
        validation,
        onValidation,
      },
    },
    'mlas.listingOptnMeld': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'MELD',
      disabled: true,
      isNotRequired: true,
    },
    'mlas.listingOptnPeld': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'PELD',
      disabled: true,
      isNotRequired: true,
    },
    // 'mlas.lastUpdateDate': {
    //   type: FIELDTYPES.DATE,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Date of Last Update',
    // },
    // 'mlas.currentBilirubin': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Current Bilirubin',
    // },
    // 'mlas.currentInr': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Current INR',
    // },
    // 'mlas.currentCreatinine': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Current Creatinine',
    // },
    // 'mlas.currentDialysisTwice': {
    //   type: FIELDTYPES.RADIO,
    //   textLabel: 'Dialysis twice / 24hrs of CVVHDF in last 7 days',
    //   options: OPTIONS_YESNO,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    // },
    // 'mlas.currentNa': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Current Na',
    // },
    // 'mlas.currentAlbumin': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Current Albumin',
    // },
    // 'mlas.currentGrowthFailure': {
    //   type: FIELDTYPES.RADIO,
    //   textLabel: 'Growth failure <2 standard deviation',
    //   options: OPTIONS_YESNO,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    // },
    // 'mlas.currentOptnMeld': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Current OPTN MELD',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.currentOptnPeld': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Current OPTN PELD',
    //   disabled: true, isNotRequired: true
    // },
    'mlas.meld': {
      type: FIELDTYPES.NUMBER,
      props: {
        validation,
        onValidation,
      },
      textLabel: 'MELD (age>12yrs) / PELD (age<=12yrs) for MLAS (A)',
      disabled: true,
      isNotRequired: true,
    },
    // 'mlas.waitingTime': {
    //   type: FIELDTYPES.TEXT,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Waiting time',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.waitingTimeCategory': {
    //   type: FIELDTYPES.TEXT,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Waiting time category',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.durationFromUpdate': {
    //   type: FIELDTYPES.TEXT,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Duration from update',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.eligibleForWaitingTimePoints': {
    //   type: FIELDTYPES.TEXT,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Eligible for Waiting time points',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.waitingTimePoints': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Waiting time points (C)',
    // },
    // 'mlas.slktYesNo': {
    //   type: FIELDTYPES.RADIO,
    //   textLabel: 'SLKT / Multi visceral transplant: Yes / No',
    //   options: OPTIONS_YESNO,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    // },
    // 'mlas.slktLiverCirrhoticAndKidneyCkd': {
    //   type: FIELDTYPES.RADIO,
    //   textLabel: 'Liver (cirrhotic) + Kidney (CKD)',
    //   options: OPTIONS_YESNO,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    // },
    // 'mlas.slktMultiVisceralTransplant': {
    //   type: FIELDTYPES.RADIO,
    //   textLabel: 'Multi-visceral transplant',
    //   options: OPTIONS_YESNO,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    // },
    // 'mlas.eligibleForSlktExceptionPoints': {
    //   type: FIELDTYPES.TEXT,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Eligible for SLKT / Multi visceral transplant exception points',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.slktExceptionPoints': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'SLKT / Multi visceral transplant exception points',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.meldExceptionPoints': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'MELD Exception points',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.mlasAbc': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'MLAS (A+B+C)',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.mlasDc': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'MLAS (D+C)',
    //   disabled: true, isNotRequired: true
    // },
    // 'mlas.finalMlas': {
    //   type: FIELDTYPES.NUMBER,
    //   props: {
    //     validation,
    //     onValidation,
    //   },
    //   textLabel: 'Final MLAS',
    //   disabled: true, isNotRequired: true
    // },
  },
  complicationScore: {
    sbp: {
      'complicationScore.sbp.sbpAnc': {
        type: FIELDTYPES.NUMBER,
        textLabel: 'Ascities Fluid Absolute Neutrophil count',
        props: {
          validation,
          onValidation,
        },
        gridProps: {
          sm: 4,
        },
      },
      'complicationScore.sbp.sbpAncAttachment': {
        type: FIELDTYPES.ATTACHMENT,
        textLabel: 'SBP Reports',
        gridProps: {
          sm: 4,
        },
      },
      'complicationScore.sbp.sbpAncAttachmentDate': {
        type: FIELDTYPES.DATE,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'SBP Reports Date',
        gridProps: {
          sm: 4,
        },
      },
      'complicationScore.sbp.spbPointsEligible': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Eligible for SBP points',
        disabled: true,
        isNotRequired: true,
      },
      'complicationScore.sbp.spbPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'SBP points',
        disabled: true,
        isNotRequired: true,
      },
    },
    hepaticHydrothoraxModToSevere: {
      // 'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxModToSevereYesNo': {
      //   type: FIELDTYPES.RADIO,
      //   textLabel: 'Hepatic hydrothorax (Moderate to severe on HRCT/CxR/USG thorax)',
      //   options: OPTIONS_YESNO,
      // },
      'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxModerateOnHrctChestXrayUsgThorax':
        {
          type: FIELDTYPES.RADIO,
          textLabel: 'Hepatic Hydrothorax (moderate to severe on HRCT/Chest Xray/USG Thorax)',
          options: OPTIONS_YESNO,
          gridProps: {
            sm: 6,
          },
        },
      'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxTherapeuticTaps': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Hepatic hydrothorax (≥ 2 therapeutic taps)',
        options: OPTIONS_YESNO,
        gridProps: {
          sm: 6,
        },
      },
      'complicationScore.hepaticHydrothoraxModToSevere.refractoryAscites': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Refractory ascites (≥ 2 LVPs (≥ 5L) / month)',
        options: OPTIONS_YESNO,
      },
      'complicationScore.hepaticHydrothoraxModToSevere.refractoryAscitesDiuretic': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Refractory ascites (diuretic resistance)',
        options: OPTIONS_YESNO,
      },
      'complicationScore.hepaticHydrothoraxModToSevere.refractoryAscitesNoradrenaline': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Refractory ascites (needing noradrenaline / midodrine / terlipressin)',
        options: OPTIONS_YESNO,
      },

      'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxEligible': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Eligible for Hepatic hydrothorax / Refractory ascites points',
        disabled: true,
        isNotRequired: true,
      },
      'complicationScore.hepaticHydrothoraxModToSevere.hepaticHydrothoraxPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Hepatic hydrothorax / Refractory ascites points',
        disabled: true,
        isNotRequired: true,
      },
    },
    heNeedingHIcu: {
      'complicationScore.heNeedingHIcu.heNeedingGreaterThanEquals3': {
        type: FIELDTYPES.RADIO,
        textLabel: 'HE (Past / Current) (≥ grade 3 requiring hospitalization (ICU))',
        options: OPTIONS_YESNO,
      },
      'complicationScore.heNeedingHIcu.structuralNeurologicalDisease': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Structural neurological diseases',
        options: OPTIONS_YESNO,
      },
      'complicationScore.heNeedingHIcu.hePointsEligible': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Eligible for HE points',
        disabled: true,
        isNotRequired: true,
      },
      'complicationScore.heNeedingHIcu.hePoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'HE points',
        disabled: true,
        isNotRequired: true,
      },
    },
    varicealBleedNoEndo: {
      'complicationScore.varicealBleedNoEndo.recurrentVaricealBleedNotAmenable': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Recurrent variceal bleed (not amenable to endotherapy and / or TIPSS)',
        options: OPTIONS_YESNO,
      },
      'complicationScore.varicealBleedNoEndo.recurrentVaricealEligible': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Eligible for Recurrent variceal bleed points',
        disabled: true,
        isNotRequired: true,
      },
      'complicationScore.varicealBleedNoEndo.recurrentVaricealPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Recurrent variceal bleed points',
        disabled: true,
        isNotRequired: true,
      },
    },
    result: {
      'complicationScore.result.complicationPointsEligible': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Eligible for Complication points',
        disabled: true,
        isNotRequired: true,
      },
      'complicationScore.result.complicationPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Complication points (B)',
        disabled: true,
        isNotRequired: true,
      },
    },
  },
  meldExceptionCriteria: {
    hps: {
      // 'meldExceptionCriteria.hps.hpsYesNo': {
      //   type: FIELDTYPES.RADIO,
      //   textLabel: 'HPS: Yes/No',
      //   options: OPTIONS_YESNO,
      // },
      'meldExceptionCriteria.hps.hpsPao2': {
        type: FIELDTYPES.RADIO,
        textLabel: 'PaO2 <60 mmHg on room air',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.hps.hpsAlveolarGradient': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Alveolar arterial oxygen gradient >15 mmHg />20 mmHg if >64 yrs age',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.hps.hpsShunt': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Bubble ECHO / MAA scan positive for Shunt',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.hps.hpsLiverDiseaseAndHyperTension': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Clinical e/o liver disease & portal hypertension',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.hps.hpsPulmonaryDisease': {
        type: FIELDTYPES.RADIO,
        textLabel: 'E/O underlying primary pulmonary disease',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.hps.eligibleForHpsExceptionPoints': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        gridProps: {
          sm: 6,
        },
        textLabel: 'Eligible for HPS exception points',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.hps.hpsExceptionPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'HPS exception points',
        disabled: true,
        isNotRequired: true,
      },
    },
    pah: {
      // 'meldExceptionCriteria.pah.pahYesNo': {
      //   type: FIELDTYPES.RADIO,
      //   textLabel: 'PAH: Yes/No',
      //   options: OPTIONS_YESNO,
      // },
      'meldExceptionCriteria.pah.pahPostTreatmentMeanPulmonaryArterialPressure': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Post-treatment Mean pulmonary arterial pressure (MPAP) 25 to 35 mmHg',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.pah.pahPostTreatmentPulmonaryVascularResistance': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Post-treatment pulmonary vascular resistance (PVR) 120 to 400 dynes/sec/cm',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.pah.pahPulmonaryCapillaryWedgePressure': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Pulmonary Capillary Wedge Pressure (PCWP) < 15 mmg',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.pah.pahHeartCathReport': {
        type: FIELDTYPES.ATTACHMENT,
        textLabel: 'Right Heart Catheterization Reports',
      },
      'meldExceptionCriteria.pah.pahHeartCathReportDate': {
        type: FIELDTYPES.DATE,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Heart Cath Report Date',
        gridProps: {
          sm: 4,
        },
      },
      'meldExceptionCriteria.pah.eligibleForPahExceptionPoints': {
        type: FIELDTYPES.TEXT,
        // props: {
        //   validation,
        //   onValidation,
        // },
        gridProps: {
          sm: 6,
        },
        textLabel: 'Eligible for PAH exception points',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.pah.pahExceptionPoints': {
        type: FIELDTYPES.NUMBER,
        // props: {
        //   validation,
        //   onValidation,
        // },
        textLabel: 'PAH exception points',
        disabled: true,
        isNotRequired: true,
      },
    },
    hcc: {
      // 'meldExceptionCriteria.hcc.hccYesNo': {
      //   type: FIELDTYPES.RADIO,
      //   textLabel: 'HCC: Yes/No',
      //   options: OPTIONS_YESNO,
      // },
      'meldExceptionCriteria.hcc.hccSingleTumorLessThan6AndHalfCm': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Single tumor <= 6.5cm',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.hcc.hccLessThan3TumorWithLargestLessThan4AndHalfcm': {
        type: FIELDTYPES.RADIO,
        textLabel: '<= 3 tumors with largest tumor <= 4.5cm',
        options: OPTIONS_YESNO,
        disabled: false,
      },
      'meldExceptionCriteria.hcc.hccLessThan3TumorDiameterLessThan8cm': {
        type: FIELDTYPES.RADIO,
        textLabel: '<= 3 tumors with total tumor diameter <= 8cm',
        options: OPTIONS_YESNO,
        disabled: false,
      },
      'meldExceptionCriteria.hcc.hccWithinUcsfCriteria': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Within UCSF criteria',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.hcc.hccLargestTumorSize': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Largest tumor size (cm)',
      },
      'meldExceptionCriteria.hcc.hccActivePvtt': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Active portal vein tumor thrombus (PVTT) (bland PVT is acceptable)',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.hcc.hccExtrahepaticDisease': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Extrahepatic disease',
        options: OPTIONS_YESNO,
        gridProps: {
          sm: 6,
        },
      },
      'meldExceptionCriteria.hcc.hccAtleastOneLesionGreaterThan2cm': {
        type: FIELDTYPES.RADIO,
        textLabel: 'At least one lesion should be > 2 cms',
        options: OPTIONS_YESNO,
        gridProps: {
          sm: 6,
        },
      },
      'meldExceptionCriteria.hcc.hccAfp': {
        type: FIELDTYPES.NUMBER,
        textLabel: 'AFP value',
      },
      'meldExceptionCriteria.hcc.hccAfpReports': {
        type: FIELDTYPES.ATTACHMENT,
        textLabel: 'AFP Reports',
      },
      'meldExceptionCriteria.hcc.hccAfpReportsDate': {
        type: FIELDTYPES.DATE,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'AFP Reports Date',
      },
      'meldExceptionCriteria.hcc.hccDateOfLastTriphasicCt': {
        type: FIELDTYPES.DATE,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Date of last Triphasic CT / MRI (dd-mm-yyyy)',
      },
      'meldExceptionCriteria.hcc.hccDurationFromLastTriphasicCt': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Duration from last Triphasic CT/ MRI',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.hcc.hccTriphasisCtScan': {
        type: FIELDTYPES.ATTACHMENT,
        textLabel: 'Triphasic CT Scan',
      },
      'meldExceptionCriteria.hcc.hccDateOfLastBoneScan': {
        type: FIELDTYPES.DATE,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Date of last bone scan (dd-mm-yyyy)',
      },
      'meldExceptionCriteria.hcc.hccDurationFromlastBoneScan': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Duration from last bone scan',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.hcc.hccBoneScan': {
        type: FIELDTYPES.ATTACHMENT,
        textLabel: 'Bone Scan',
      },
      'meldExceptionCriteria.hcc.hccDateOfLastHrctChest': {
        type: FIELDTYPES.DATE,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Date of last HRCT chest (dd-mm-yyyy)',
      },
      'meldExceptionCriteria.hcc.hccDurationFromLastHrctChest': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Duration from last HRCT chest',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.hcc.hccHrctChestScan': {
        type: FIELDTYPES.ATTACHMENT,
        textLabel: 'HRCT Chest Scan',
      },
      'meldExceptionCriteria.hcc.hccDateOfLastPetCt': {
        type: FIELDTYPES.DATE,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Date of last PET CT (dd-mm-yyyy)',
      },
      'meldExceptionCriteria.hcc.hccDurationFromLastPetCt': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Duration from last PET CT',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.hcc.hccPetCtScan': {
        type: FIELDTYPES.ATTACHMENT,
        textLabel: 'PET CT Scan',
      },
      'meldExceptionCriteria.hcc.eligibleForHccExceptionPoints': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        gridProps: {
          sm: 6,
        },
        textLabel: 'Eligible for HCC exception points',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.hcc.hccExceptionPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'HCC exception points',
        disabled: true,
        isNotRequired: true,
      },
    },
    psc: {
      // 'meldExceptionCriteria.psc.pscYesNo': {
      //   type: FIELDTYPES.RADIO,
      //   textLabel: 'Primary Sclerosing Cholangitis (PSC): Yes/No',
      //   options: OPTIONS_YESNO,
      // },
      'meldExceptionCriteria.psc.pscProvenOnMrcp': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Proven on MRCP',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.psc.pscRecurrentCholangitisLessThan4Episodes': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Recurrent cholangitis (>= 4 episodes in a year)',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.psc.pscRefactoryCholangitis': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Refractory cholangitis',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.psc.pscSevereIntractableItchingAfterTherapy': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Severe Intractable itching after therapy',
        options: OPTIONS_YESNO,
        gridProps: {
          sm: 12,
        },
      },
      'meldExceptionCriteria.psc.eligibleForPscExceptionPoints': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        gridProps: {
          sm: 6,
        },
        textLabel: 'Eligible for PSC exception points',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.psc.pscExceptionPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'PSC exception points',
        disabled: true,
        isNotRequired: true,
      },
    },
    pbc: {
      // 'meldExceptionCriteria.pbc.pbcYesNo': {
      //   type: FIELDTYPES.RADIO,
      //   textLabel: 'Primary Biliary Cholangitis (PBC): Yes/No',
      //   options: OPTIONS_YESNO,
      // },
      'meldExceptionCriteria.pbc.pbcAmaPositive': {
        type: FIELDTYPES.RADIO,
        textLabel: 'AMA positive ± Biopsy proven',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.pbc.pbcSevereIntractablePruritusAfterTreatment': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Severe intractable pruritus after treatment',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.pbc.pbcSevereMetabolicBoneDiseaseNonTraumaticFractures': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Severe metabolic bone disease (non-traumatic fractures)',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.pbc.pbcSevereMetabolicBoneDiseaseZScore': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Severe metabolic bone disease (Z score < -4 on DEXA scan)',
        options: OPTIONS_YESNO,
        gridProps: {
          sm: 12,
        },
      },
      'meldExceptionCriteria.pbc.eligibleForPbcExceptionPoints': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        gridProps: {
          sm: 6,
        },
        textLabel: 'Eligible for PBC exception points',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.pbc.pbcExceptionPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'PBC exception points',
        disabled: true,
        isNotRequired: true,
      },
    },
    postTransplantPatientRequiringRetransplant: {
      // 'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.postTransplantPatientRequiringRetransplantYesNo':
      //   {
      //     type: FIELDTYPES.RADIO,
      //     textLabel: 'Post-transplant patient requiring re-transplant: Yes / No',
      //     options: OPTIONS_YESNO,
      //   },
      'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.postTransplantPatientRequiringRetransplantEligibleForSuperUrgentListing':
        {
          type: FIELDTYPES.RADIO,
          textLabel: 'Eligible for super-urgent listing (rejection / late HAT / ITBL)',
          options: OPTIONS_YESNO,
          gridProps: {
            sm: 12,
          },
        },
      'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.eligibleForPostTransplantPatientRequiringRetransplantExceptionPoints':
        {
          type: FIELDTYPES.TEXT,
          props: {
            validation,
            onValidation,
          },
          gridProps: {
            sm: 6,
          },
          textLabel: 'Eligible for Post Transplant Patient Requiring Retransplant exception points',
          disabled: true,
          isNotRequired: true,
        },
      'meldExceptionCriteria.postTransplantPatientRequiringRetransplant.postTransplantPatientRequiringRetransplantExceptionPoints':
        {
          type: FIELDTYPES.NUMBER,
          props: {
            validation,
            onValidation,
          },
          textLabel: 'Post Transplant Patient Requiring Retransplant exception points',
          disabled: true,
          isNotRequired: true,
        },
    },
    mld: {
      // 'meldExceptionCriteria.mld.mldYesNo': {
      //   type: FIELDTYPES.RADIO,
      //   textLabel:
      //     'Metabolic liver diseases (Native liver offered for Domino liver transplant): Yes / No',
      //   options: OPTIONS_YESNO,
      // },
      'meldExceptionCriteria.mld.mldFamilialAmyloidPolyneurpathy': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Familial amyloid polyneuropathy',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.mld.mldMapleSyrupUrineDisease': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Maple syrup urine disease',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.mld.eligibleForMldExceptionPoints': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        gridProps: {
          sm: 6,
        },
        textLabel:
          'Eligible for Metabolic liver diseases (Native liver offered for Domino liver transplant) exception points',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.mld.mldExceptionPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel:
          'Metabolic liver diseases (Native liver offered for Domino liver transplant) exception points',
        disabled: true,
        isNotRequired: true,
      },
    },
    mldOtw: {
      // 'meldExceptionCriteria.mldOtw.mldOtwYesNo': {
      //   type: FIELDTYPES.RADIO,
      //   textLabel: 'Metabolic liver diseases (other than Wilsons): Yes / No',
      //   options: OPTIONS_YESNO,
      // },
      'meldExceptionCriteria.mldOtw.mldOtwFamilialHypercholesterolemia': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Familial hypercholesterolemia',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.mldOtw.mldOtwSickleCellHepatopathy': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Sickle cell hepatopathy',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.mldOtw.mldOtwPrimaryHyperoxaluria': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Primary hyperoxaluria',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.mldOtw.mldOtwPolycysticLiverDisease': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Polycystic liver disease',
        options: OPTIONS_YESNO,
        gridProps: {
          sm: 12,
        },
      },
      'meldExceptionCriteria.mldOtw.eligibleForMldOtwExceptionPoints': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        gridProps: {
          sm: 6,
        },
        textLabel: 'Eligible for Metabolic liver diseases (other than Wilsons) exception points',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.mldOtw.mldOtwExceptionPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Metabolic liver diseases (other than Wilsons) exception points',
        disabled: true,
        isNotRequired: true,
      },
    },
    nonHcc: {
      // 'meldExceptionCriteria.nonHcc.nonHccYesNo': {
      //   type: FIELDTYPES.RADIO,
      //   textLabel: 'Non-HCC liver tumors: Yes / No',
      //   options: OPTIONS_YESNO,
      // },
      'meldExceptionCriteria.nonHcc.nonHccHepaticEpithelioid': {
        type: FIELDTYPES.RADIO,
        textLabel: 'Hepatic epithelioid haemangioendothelioma',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.nonHcc.nonHccBilobarNetSixMonths': {
        type: FIELDTYPES.RADIO,
        textLabel:
          'Bilobar NET (Neuro Endocrine Tumor) liver metastases: at least 6 months after resolution of primary tumor',
        options: OPTIONS_YESNO,
      },
      'meldExceptionCriteria.nonHcc.nonHccBilobarNetSpecialScans': {
        type: FIELDTYPES.RADIO,
        textLabel:
          'Bilobar NET (Neuro Endocrine Tumor) liver metastases:confirmed metastasis restricted to liver by whole body special scans',
        options: OPTIONS_YESNO,
      },

      'meldExceptionCriteria.nonHcc.eligibleForNonHccExceptionPoints': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        gridProps: {
          sm: 6,
        },
        textLabel: 'Eligible for Metabolic liver diseases (other than Wilsons) exception points',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.nonHcc.nonHccExceptionPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Metabolic liver diseases (other than Wilsons) exception points',
        disabled: true,
        isNotRequired: true,
      },
    },
    result: {
      'meldExceptionCriteria.result.meldExceptionCriteriaPointsEligible': {
        type: FIELDTYPES.TEXT,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Eligible for Meld Exception Criteria Points',
        disabled: true,
        isNotRequired: true,
      },
      'meldExceptionCriteria.result.meldExceptionCriteriaPoints': {
        type: FIELDTYPES.NUMBER,
        props: {
          validation,
          onValidation,
        },
        textLabel: 'Meld Exception Criteria points (D)',
        disabled: true,
        isNotRequired: true,
      },
    },
  },
});
