import React from 'react';
import { useSelector } from 'react-redux';
import Joi from 'joi';
import {
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {  ValidatedInput, ValidatedSelect, ValidatedRadio } from '../../Custom';

function MedicalReportsHemodynamics({
  donorMedicalReportsDetails,
  setDonorMedicalReportsDetails,
  // validation,
  // onValidation,
}) {

  const useStyles = makeStyles(() => ({
    paperTransplantInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 20,
      marginBottom: 10,
    },
    gridTransplantDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperTransplantSections: {
      height: '100%',
      marginTop: 5,
    },
    transplantSectionsStyle: {
      fontWeight: 300,
      lineHeight: '15px',
      marginTop: 20,
      marginLeft: 20,
    },
    transplantSectionsHeadingStyle: {
      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    transplantDetailsHeading: {
      fontWeight: 600,
      fontSize: '17px',
      color: '#34495E',
      marginTop: '5px',
    },
    form12BoxStyle: {
      direction: 'row',
      // marginTop: 90,
      // ...(_isDisabled() ? { marginTop: 120 } : {}),
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Pulse (beats/min)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.pulse}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  pulse: e.target.value,
                },
              });
            }}
            schema={Joi.string()}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">BP (mmHg)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.bp}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  bp: e.target.value,
                },
              });
            }}
            schema={Joi.string()}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Temperature (Degree Celcius)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.temperature}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  temperature: e.target.value,
                },
              });
            }}
            schema={Joi.string()}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Urine Output (ml)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.hourlyUrineOutput}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  hourlyUrineOutput: e.target.value,
                },
              });
            }}
            schema={Joi.string()}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Adrenaline Dose(micro gram/ kg)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.adrenalineDose}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  adrenalineDose: e.target.value,
                },
              });
            }}
            schema={Joi.string()}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Noradrenaline Dose(micro gram/ kg)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.noradrenalineDose}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  noradrenalineDose: e.target.value,
                },
              });
            }}
            schema={Joi.string()}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Dopamine Dose(micro gram/ kg)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.dopamineDose}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  dopamineDose: e.target.value,
                },
              });
            }}
            schema={Joi.string()}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Vasopressin Dose(micro gram/ kg)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.vasopressinDose}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  vasopressinDose: e.target.value,
                },
              });
            }}
            schema={Joi.string()}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E', margin: 5 }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Ventilator Setting
          </Typography>
        </Grid>
        <Grid container>
          <Grid item sm={4} xs={4} style={{ padding: 10 }}>
            <ValidatedRadio
              label=" Does the patient on Ventilator Support? "
              name="controlled-radio-buttons-group"
              value={
                donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting
                  ?.ventilationSupport
              }
              onChange={(e) => {
                const isYes = e.target.value === 'true';
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    ventilatorSetting: {
                      ventilationSupport: isYes,
                      durationOnVentilator: isYes ? donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting?.durationOnVentilator : '',
                      modeOfVentilation: isYes ? donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting?.modeOfVentilation : '',
                      fio2: isYes ? donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting?.fio2 : '',
                      peep: isYes ? donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting?.peep : '',
                    },
                  },
                });
              }}
              options={[
                { value: true, label: 'YES' },
                { value: false, label: 'NO' },
              ]}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting
            ?.ventilationSupport ? (
            <Grid container spacing={3} style={{ margin: 20, padding: 10 }}>
              <Grid item sm={4} xs={12}>
                <Typography variant="body1">Duration on Ventilators(in Days)</Typography>
                <ValidatedInput
                  fullWidth
                  value={
                    donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting
                      ?.durationOnVentilator
                  }
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      hemoDynamicParameters: {
                        ...donorMedicalReportsDetails.hemoDynamicParameters,
                        ventilatorSetting: {
                          ...donorMedicalReportsDetails.hemoDynamicParameters.ventilatorSetting,
                          durationOnVentilator: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              {/* <Grid item sm={4} xs={12}>
                <Typography variant="body1">Ventilator Setting</Typography>
                <ValidatedInput
                  fullWidth
                  value={
                    donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting
                      ?.ventilatorSettings
                  }
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      hemoDynamicParameters: {
                        ...donorMedicalReportsDetails.hemoDynamicParameters,
                        ventilatorSetting: {
                          ...donorMedicalReportsDetails.hemoDynamicParameters.ventilatorSetting,
                          ventilatorSettings: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid> */}
              <Grid item xs={4} sm={4} style={{ marginTop: 30 }}>
                <ValidatedSelect
                  label="Mode of Ventilations"
                  value={
                    donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting
                      ?.modeOfVentilation
                  }
                  // disabled={!organ || updateForm || isDisabled}
                  options={[
                    {
                      label: 'Pressure Control Ventilator (PCV)',
                      value: 'PCV',
                    },
                    {
                      label: 'Synchronized Intermittent Mandatory Ventilation (SIMV) ',
                      value: 'SIMV',
                    },
                  ]}
                  onChange={(v) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      hemoDynamicParameters: {
                        ...donorMedicalReportsDetails.hemoDynamicParameters,
                        ventilatorSetting: {
                          ...donorMedicalReportsDetails.hemoDynamicParameters.ventilatorSetting,
                          modeOfVentilation: v.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item sm={4} xs={12}>
                <Typography variant="body1">FIO2</Typography>
                <ValidatedInput
                  required
                  fullWidth
                  value={donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting?.fio2}
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      hemoDynamicParameters: {
                        ...donorMedicalReportsDetails.hemoDynamicParameters,
                        ventilatorSetting: {
                          ...donorMedicalReportsDetails.hemoDynamicParameters.ventilatorSetting,
                          fio2: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography variant="body1">PEEP</Typography>
                <ValidatedInput
                  required
                  fullWidth
                  value={donorMedicalReportsDetails?.hemoDynamicParameters?.ventilatorSetting?.peep}
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      hemoDynamicParameters: {
                        ...donorMedicalReportsDetails.hemoDynamicParameters,
                        ventilatorSetting: {
                          ...donorMedicalReportsDetails.hemoDynamicParameters.ventilatorSetting,
                          peep: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        {/* <Grid item sm={4} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Any Episode of Cardiac Arrest</FormLabel>
            <RadioGroup
              row
              aria-label="Any Episode of Cardiac Arrest"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.episodeOfCardiacArrest}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    episodeOfCardiacArrest: e.target.value === 'true',
                    durationOfCardiacArrest: '',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {donorMedicalReportsDetails?.hemoDynamicParameters?.episodeOfCardiacArrest ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">If Yes, duration (in minutes)</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.durationOfCardiacArrest}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    durationOfCardiacArrest: e.target.value,
                  },
                });
              }}
            />
          </Grid>
        ) : null} */}
        {/* <Grid item xs={12} sm={4}>
          <ValidatedSelect
            label="Pressure Support"
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.pressureSupport}
            // disabled={!organ || updateForm || isDisabled}
            // multiple={true}
            options={[
              {
                label: 'Noradrenaline Infusion',
                value: 'NORADRENALINE_INFUSION',
              },
              {
                label: 'Vosopressin Infusion',
                value: 'VOSOPRESSIN_INFUSION',
              },
              {
                label: 'Not Applicable',
                value: 'NA',
              },
            ]}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  pressureSupport: v.value,
                  durationOfPressureSupport: '',
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Duration of Pressure Support (in hours)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.durationOfPressureSupport}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  durationOfPressureSupport: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Drug and Dose (current)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.drugAndDoseCurrent}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  drugAndDoseCurrent: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Drug and Dose (previous)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.drugAndDosePrevious}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  drugAndDosePrevious: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Any Other Medications</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.hemoDynamicParameters?.anyOtherMedications}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                hemoDynamicParameters: {
                  ...donorMedicalReportsDetails.hemoDynamicParameters,
                  anyOtherMedications: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        {/* <Grid item sm={4} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">H/O Alcoholism</FormLabel>
            <RadioGroup
              row
              aria-label="H/O Alcoholism"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.alcoholism}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    alcoholism: e.target.value === 'true',
                    alcoholismReport: {
                      key: '',
                      name: '',
                      url: '',
                    },
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {donorMedicalReportsDetails?.hemoDynamicParameters?.alcoholism ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Alcoholism Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.alcoholismReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    alcoholismReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null}
        <Grid item sm={4} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Diabetes</FormLabel>
            <RadioGroup
              row
              aria-label="Diabetes"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.diabetics}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    diabetics: e.target.value === 'true',
                    diabeticsReport: {
                      key: '',
                      name: '',
                      url: '',
                    },
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {donorMedicalReportsDetails?.hemoDynamicParameters?.diabetics ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Diabetes Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.diabeticsReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    diabeticsReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null}
        <Grid item sm={4} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Hypertension</FormLabel>
            <RadioGroup
              row
              aria-label="Hypertension"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.htn}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    htn: e.target.value === 'true',
                    htnReport: {
                      key: '',
                      name: '',
                      url: '',
                    },
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {donorMedicalReportsDetails?.hemoDynamicParameters?.htn ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Hypertension Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.htnReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    htnReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null}
        <Grid item sm={4} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Ischemic Heart Disease</FormLabel>
            <RadioGroup
              row
              aria-label="Ischemic Heart Disease"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.ihd}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    ihd: e.target.value === 'true',
                    ihdReport: {
                      key: '',
                      name: '',
                      url: '',
                    },
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {donorMedicalReportsDetails?.hemoDynamicParameters?.ihd ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Ischemic Heart Disease Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.ihdReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    ihdReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null}
        <Grid item sm={4} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Asthma</FormLabel>
            <RadioGroup
              row
              aria-label="Asthma"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.asthma}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    asthma: e.target.value === 'true',
                    asthmaReport: {
                      key: '',
                      name: '',
                      url: '',
                    },
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {donorMedicalReportsDetails?.hemoDynamicParameters?.asthma ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Asthma Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.asthmaReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    asthmaReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null}
        <Grid item sm={4} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Lung Disease</FormLabel>
            <RadioGroup
              row
              aria-label="Lung Disease"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.lungDisease}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    lungDisease: e.target.value === 'true',
                    lungDiseaseReport: {
                      key: '',
                      name: '',
                      url: '',
                    },
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {donorMedicalReportsDetails?.hemoDynamicParameters?.lungDisease ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Lung Disease Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.hemoDynamicParameters?.lungDiseaseReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  hemoDynamicParameters: {
                    ...donorMedicalReportsDetails.hemoDynamicParameters,
                    lungDiseaseReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null} */}
      </Grid>
    </>
  );
}

MedicalReportsHemodynamics.propTypes = {
  donorMedicalReportsDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  setDonorMedicalReportsDetails: PropTypes.func.isRequired,
};
MedicalReportsHemodynamics.defaultProps = {};

export default MedicalReportsHemodynamics;
