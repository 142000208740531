import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { BLOODGROUP} from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class MultivisceralIntestineFormPdf {
  formData = {};

  constructor(formData) {
    const {
      intestineDetails: { lit, primaryIntestinalFailure, failureOfParenteralNutrition },
      basicDetails,
      contactNos,
      hospitalName,
    } = formData;

    const _getZtccZoneLabelById = (zone) => {
      if (zone === '7') {
        return 'ZTCC MUMBAI';
      } else if (zone === '8') {
        return 'ZTCC PUNE';
      } else if (zone === '9') {
        return 'ZTCC NAGPUR';
      } else if (zone === '10') {
        return 'ZTCC AURANGABAD';
      } else if (zone) {
        return zone;
      }

      return '';
    };

    this.formData = formData;
    const reportDocDefinition = {
      content: [
        {
          text: '',
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'State Appropriate Authority of Maharashtra - MULTIVISCERAL INTESTINE REGISTRATION FORM ',
                  style: 'textStyle',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `Date: ${moment().format('DD MMMM YYYY')}`,
                  style: 'textStyle',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Name: ${basicDetails?.name}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `ZTCC Zone: : ${_getZtccZoneLabelById(
                    basicDetails?.registeredHosp?.ztccZone
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
                { text: '' },
                { text: '' },
              ],
              [
                {
                  text: `NOTTO ID : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: `${basicDetails?.nottoId}`, colSpan: 2 },
                { text: '' },
                { text: '' },
              ],
              [
                {
                  text: 'Date of Dialysis :',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: '', colSpan: 2 },
                {},
              ],
              [
                {
                  text: 'Period of Dialysis :',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: '', colSpan: 2 },
                {},
              ],
              [
                {
                  text: 'Report of Dialysis Initiation :',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: '', colSpan: 2 },
                {},
              ],
              [
                {
                  text: `Address & Telephone No: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${basicDetails?.presentAddress} - ${contactNos[0]}`, colSpan: 2 },
                {},
              ],
              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${basicDetails?.nationality}`, colSpan: 2 },
                {},
              ],
              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${hospitalName}`, colSpan: 2 },
                {},
              ],

              [
                {
                  text: `Age: ${basicDetails?.currentAge}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `Sex: ${basicDetails?.sex}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Economic Status: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${basicDetails?.economicStatus}`, colSpan: 2 },
                {},
              ],

              [
                {
                  text: `Blood Group: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have Primary Intestinal failure? \n \n Massive bowel resection \n Crohn’s disease \n Necrotizing enterocolitis \n Malabsorption syndromes \n Tumors such as massive mesenteric desmoids \n Visceral myopathy \n Pseudo obstruction',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${primaryIntestinalFailure?.massiveBowelResection} - 
                ${primaryIntestinalFailure?.cronhsDisease} -
                ${primaryIntestinalFailure?.necrotizingEnterocolitis} -
                ${primaryIntestinalFailure?.malabsorptionSyndromes} -
                ${primaryIntestinalFailure?.tumors} -
                ${primaryIntestinalFailure?.visceralMyopathy} -
                ${primaryIntestinalFailure?.pseudoObstruction}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Does the patient have the Failure of Parenteral Nutrition? \n \n Impending or overt liver failure due to TPN-induced liver injury \n Thrombosis of two or more central veins \n Two or more episodes per year of catheter-related systemic sepsis that requires hospitalization \n A single episode of line-related fungemia, septic shock, or acute respiratory distress syndrome \n Frequent episodes of severe dehydration despite intravenous fluid supplementation in addition to TPN',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${failureOfParenteralNutrition?.impendingOrOvertLiverFailure} - 
                ${failureOfParenteralNutrition?.thrombosisOfTwoOrMoreCentralVeins} -
                ${failureOfParenteralNutrition?.twoOrMoreEpisodesPerYearOfCatheterRelatedSystemicSepsisThatRequiresHospitalization} -
                ${failureOfParenteralNutrition?.singleEpisodeOfLineRelatedFungemiaSepticShockOrAcuteRespiratoryDistressSyndrome} -
                ${failureOfParenteralNutrition?.frequentEpisodesOfSevereDehydrationDespiteIntravenousFluidSupplementationInAdditionToTPN}`,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto'],
            heights: [40, 50],
            body: [
              [
                {
                  text: 'Administrative Head of the Hospital:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: 'Patient Sign:',
                  colSpan: 2,
                },
                {},
              ],
              [
                { text: 'Doctor Signature', colSpan: 3, alignment: 'right' },
                {},

                {
                  text: '',
                },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Multivisceral Intestine Form`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default MultivisceralIntestineFormPdf;
