import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { TextField } from '@material-ui/core';
import { REGEXP_ONLYCHARS, VALIDATION } from '../../constants';

export default function ValidatedInput({
  value,
  onChange,
  onValidation,
  required,
  allowEmpty,
  showValid,
  validationDisabled,
  ...other
}) {
  let isDirty = false;
  const REGEXP_ILLEGALCHARS = new RegExp('.*(<\\w*?>|\\||~|`|\\^).*');
  const [focusShrink, setFocusShrink] = useState(false);

  // eslint-disable-next-line prefer-const
  let {
    validation,
    schema,
    ignoreChangeSchema,
    ignoreChangeSchemaMode,
    InputLabelProps,
    padding,
    ...otherProps
  } = other;
  let valid = true;
  let messages = [];
  let joiError = null;
  if (validation === true) {
    validation = VALIDATION.ALL;
  } else if (validation === false) {
    validation = VALIDATION.OFF;
  }
  if (
    !validationDisabled &&
    (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && isDirty))
  ) {
    if (!schema || !Joi.isSchema(schema)) {
      schema = Joi.alternatives(
        Joi.boolean(),
        Joi.number(),
        Joi.number().max(15).min(5),
        Joi.string().email({ tlds: { allow: false } }),
        Joi.string().uri(),
        Joi.string().regex(REGEXP_ILLEGALCHARS, { invert: true })
      );
    } else if (schema.type === 'string') {
      schema = schema.regex(REGEXP_ILLEGALCHARS, { invert: true });
    }
    schema = schema.messages({
      'alternatives.any': 'Please check',
      'any.required': 'Cannot be empty',
      'string.empty': 'Cannot be empty',
      'string.base': 'Should be a valid string',
      'string.email': 'Should be a valid email',
      'string.pattern.base': 'Cannot contain illegal characters',
      'string.pattern.invert.base': 'Cannot contain illegal characters',
      'string.lowercase': 'Cannot contain uppercase letters',
      'string.uri': 'Should be a valid URI',
      'string.length': 'Should be of length {#limit}',
      'string.max': 'Cannot exceed a length of {#limit}',
      'string.min': 'Cannot have length smaller than {#limit}',
      'string.domain': 'Should be a valid domain',
      'string.hostname': 'Should be a valid hostname',
      'string.ip': 'Should be a valid IP address',
      'string.hex': 'Should be a valid hex number',
      'boolean.base': 'Should be valid selection',
      'number.base': 'Should be a number',
      'number.integer': 'Should be an integer',
      'number.greater': 'Cannot be greater than {#limit}',
      'number.less': 'Cannot be less than {#limit}',
      'number.max': 'Cannot be greater than {#limit}',
      'number.min': 'Cannot be less than {#limit}',
      'number.positive': 'Should be a positive number',
      'number.negative': 'Should be a negative number',
    });
    if (required) {
      schema = schema.required();
    } else if (allowEmpty) {
      schema = schema.allow('').allow(null);
    }
    if (validation & VALIDATION.INVALIDATE_EMPTY) {
      schema = schema.required();
    }

    const { error } = schema.validate(value);
    if (error) {
      valid = false;
      messages = error.details.map((d) => d.message);
      joiError = error;
    }
    onValidation(valid, messages, joiError);
  } else {
    valid = true;
    messages = [];
  }

  if (!Joi.isSchema(ignoreChangeSchema) && ignoreChangeSchemaMode === 'ONLYCHARS') {
    ignoreChangeSchema = Joi.string().pattern(REGEXP_ONLYCHARS);
  }
  return (
    <>
      <TextField
        variant="outlined"
        size="small"
        multiline
        error={!!validation && !valid}
        {...otherProps}
        helperText={messages[0] ? messages[0] : otherProps.helperText || ''}
        onChange={(...params) => {
          if (!isDirty) {
            isDirty = true;
          }
          if (Joi.isSchema(ignoreChangeSchema)) {
            ignoreChangeSchema = ignoreChangeSchema.allow('').allow(null);
            const { error } = ignoreChangeSchema.validate(params[0]?.target?.value);
            if (error) {
              return;
            }
          }
          onChange(...params);
        }}
        onFocus={() => {
          setFocusShrink(true);
        }}
        onBlur={() => {
          setFocusShrink(false);
        }}
        InputLabelProps={{
          ...(otherProps.InputLabelProps || {}),
          shrink: focusShrink || value === 0 || !!value,
          style: { padding: 5, margin: -5, fontSize: InputLabelProps?.style?.fontSize },
        }}
        required={required}
        value={value}
        style={{ fontWeight: 'bolder', padding }}
      />
    </>
  );
}
ValidatedInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  schema: PropTypes.instanceOf(Joi.constructor),
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  validationDisabled: PropTypes.bool,
  showValid: PropTypes.bool,
  required: PropTypes.bool,
  allowEmpty: PropTypes.bool,
};
ValidatedInput.defaultProps = {
  value: undefined,
  validation: false,
  schema: undefined,
  onChange: () => 'onChange STUB',
  onValidation: () => 'onValidation STUB',
  validationDisabled: false,
  showValid: false,
  required: false,
  allowEmpty: false,
};
