import { transports, createLogger, format } from 'winston';
import store from './store';
import getPlatformInfo from './platformInfo';

const { combine, timestamp, printf } = format;

const logFormat = printf(info => {
  // const state = store.getState();
  const email = /* state.userContext.email ? state.userContext.email :  */ 'user@mahaayudaan.com';

  let fmt = `${info.timestamp} ${info.level.toUpperCase()} ${email}: ${info.message}`;

  if (info.error) {
    const err = info.error;
    fmt = `${fmt}\n${err.stack}`;
  } else if (info.apiResponse) {
    const { apiResponse } = info;
    fmt = `${fmt}\n${JSON.stringify(apiResponse, null, 2)}`;
  }
  return fmt;
});

const logger = createLogger({
  transports: [
    new transports.Console({
      format: combine(timestamp(), logFormat),
      level: 'silly'
    })
  ]
});

(function changeLog(lgr) {
  const _log = lgr.log;
  lgr.log = function log() {
    let err = {};
    // const state = store.getState();
    const email = /* state.userContext.email ? state.userContext.email :  */ 'user@mahaayudaan.com';

    const args = Array.prototype.slice.call(arguments);
    const callback = typeof args[args.length - 1] === 'function' ? args.pop() : null;
    let meta = typeof args[args.length - 1] === 'object' ? args.pop() : {};

    if (meta instanceof Error) {
      err = meta;

      meta = {
        error: { name: err.name, message: err.message, stack: err.stack }
      };
    }

    const build = process.env.REACT_APP_BUILDID;
    const platformInfo = getPlatformInfo();
    const details = {
      build,
      platformInfo
    };

    args.push({ email, details, ...meta });
    if (callback) args.push(callback);

    _log.apply(lgr, args);
  };
})(logger);

export default logger;
