import {
  SET_USER,
  SET_HOSPITAL_AND_USER,
  SET_LOADING_STATUS,
  SET_USERATTR,
  MSG_LOADING_DEFAULT,
  Loading,
  SET_ISDEMO,
  SET_LASTLOGIN,
} from '../constants';

const defaultUserAttr = {
  email: '',
  name: '',
  phone_number: '',
};

const defaultLastLogin = {
  lastlogin_ip: 'NA',
  lastlogin_time: 'NA',
  lastlogin_browser: 'NA',
};

const defaultUser = {
  userId: '',
  name: '',
  email: '',
  contact: '',
  seedWords: '',
  ethAddress: '',
  userType: '',
  designation: '',
};

const defaultState = {
  userAttr: defaultUserAttr,
  lastLogin: defaultLastLogin,
  user: defaultUser,
  hospital: null,
  isLoggedIn: false,
  isDemo: false,

  loadingStatus: new Loading(false, MSG_LOADING_DEFAULT),
};

const setState = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ISDEMO: {
      const { isDemo } = action;
      return {
        ...state,
        isDemo,
      };
    }
    case SET_USERATTR: {
      const { userAttr } = action;
      return {
        ...state,
        userAttr,
      };
    }
    case SET_LASTLOGIN: {
      const { lastLogin } = action;
      return {
        ...state,
        lastLogin,
      };
    }
    case SET_USER: {
      const { user } = action;
      return {
        ...state,
        user,
      };
    }
    case SET_HOSPITAL_AND_USER: {
      const { hospitalAndUserType } = action;
      const { hospital, userType } = hospitalAndUserType;
      const user = hospital.users.filter(
        (u) => u.email === state.userAttr.email && u.userType === userType
      )[0];
      const isLoggedIn = true;
      return {
        ...state,
        hospital,
        user,
        isLoggedIn,
      };
    }
    case SET_LOADING_STATUS: {
      const { loadingStatus } = action;
      return {
        ...state,
        loadingStatus,
      };
    }
    default:
      return state;
  }
};

export default setState;
