import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Card,
  FormGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Badge,
  Backdrop,
} from '@material-ui/core';
import AddAPhotoIcon from 'mdi-react/CameraPlusIcon';
import EditIcon from '@material-ui/icons/Edit';
import LoadingIcon from 'mdi-react/LoadingIcon';
import Webcam from 'react-webcam';
import { useSnackbar } from 'notistack';
import { IMGDIMENSIONS } from '../../constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadableButton from '../Custom/LoadableButton';

export default function PhotoUpload({
  photoBase64,
  uploadPhoto,
  label,
  imgDimensions: { width, height },
  disabled,
  elementId,
  disableUploadFromDevice,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const FILE_SIGNATURES = {
    // https://stackoverflow.com/a/29672957/2503940
    PNG: ['89504e47'],
    GIF: ['47494638'],
    JPEG: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'],
  };

  const webcamRef = useRef(null);
  const [webcamPhotoModal, setWebcamPhotoModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [photoId] = useState(Math.random());
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const _renderImage = () => {
    return (
      <Backdrop style={{ zIndex: 1202 }} open={open} onClick={handleClose}>
        <img
          title="docViewer"
          src={`data:image/jpeg;base64,${photoBase64}`}
          style={{ width: '600px', height: '600px' }}
        />{' '}
      </Backdrop>
    );
  };
  const _renderWebCamPhotoModal = () => {
    return (
      <>
        <Dialog
          onClose={() => {
            setWebcamPhotoModal(false);
          }}
          aria-labelledby="simple-dialog-title"
          fullWidth={true}
          maxWidth="sm"
          open={webcamPhotoModal}
        >
          <DialogTitle id="simple-dialog-title">Take picture to upload</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid container item xs={12} justify="center">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  mirrored={true}
                  videoConstraints={{
                    width: width * 3,
                    height: height * 3,
                    facingMode: { ideal: 'environment' },
                  }}
                  screenshotFormat="image/jpeg"
                />
              </Grid>

              <Grid container item xs={12} justify="center">
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={isUploading}
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    let imageSrc = webcamRef.current.getScreenshot({
                      width,
                      height,
                    });
                    imageSrc = imageSrc.slice(23);

                    setIsUploading(true);
                    uploadPhoto(imageSrc);
                    setIsUploading(false);
                    setWebcamPhotoModal(false);
                  }}
                >
                  Capture
                  {isUploading ? (
                    <LoadingIcon className="spin" style={{ marginLeft: '5px' }} />
                  ) : null}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <label htmlFor={elementId} id={`${photoId}`}>
        <FormGroup row>
          {!photoBase64 ? (
            !disableUploadFromDevice ? (
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: height * 1.8,
                  width: width * 1.8,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconButton
                  style={{
                    opacity: 0.9,
                    backgroundColor: 'lightgrey',
                  }}
                  disabled={isUploading}
                  size="medium"
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  {isUploading ? (
                    <LoadingIcon className="spin" />
                  ) : (
                    <AddAPhotoIcon fontSize="small" />
                  )}
                </IconButton>
                <Typography variant="caption">Select image</Typography>
              </Card>
            ) : null
          ) : (
            <>
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                invisible={disabled}
                badgeContent={!disableUploadFromDevice && <EditIcon color="secondary" />}
              >
                <Button component="span">
                  <img
                    height={height * 1.8}
                    width={width * 1.8}
                    src={`data:image/png;base64,${photoBase64}`}
                    alt={elementId}
                  />
                </Button>
              </Badge>
              <IconButton edge="end" size="small">
                <VisibilityIcon
                  onClick={() => {
                    setOpen(true);
                  }}
                  color="primary"
                  variant="text"
                />
              </IconButton>
            </>
          )}
          {!disabled && !disableUploadFromDevice && (
            <input
              style={{ display: 'none' }}
              accept="image/png,image/jpeg"
              id={elementId}
              type="file"
              disabled={isUploading}
              onChange={(e) => {
                const file = e.target.files[0];
                if (!file) {
                  return;
                }
                const fileSize = file.size / 1024 / 1024; // in MiB
                if (fileSize > 2) {
                  enqueueSnackbar('File size exceeds 2 MB', {
                    variant: 'error',
                  });
                  return;
                }

                const reader = new FileReader();
                reader.onload = (ev) => {
                  const arr = new Uint8Array(ev.target.result).subarray(0, 4);
                  let header = '';
                  for (let i = 0; i < arr.length; i += 1) {
                    header += arr[i].toString(16);
                  }
                  if (
                    !FILE_SIGNATURES.PNG.includes(header) &&
                    !FILE_SIGNATURES.JPEG.includes(header)
                  ) {
                    enqueueSnackbar('Please upload a PNG or JPEG file', {
                      variant: 'error',
                    });
                    return;
                  }

                  const base64 = Buffer.from(ev.target.result).toString('base64');
                  setIsUploading(true);
                  uploadPhoto(base64);
                  setIsUploading(false);
                };
                reader.readAsArrayBuffer(file);
              }}
              name="file"
            />
          )}
        </FormGroup>
      </label>
      <Typography variant="body1" style={{ marginTop: 20 }}>
        {elementId}
        {!disabled && (
          <Button
            size="small"
            style={{ marginLeft: 10 }}
            color="primary"
            variant="contained"
            disabled={isUploading}
            onClick={() => {
              setWebcamPhotoModal(true);
            }}
          >
            Open Camera{' '}
            {isUploading ? <LoadingIcon className="spin" style={{ marginLeft: '5px' }} /> : null}
          </Button>
        )}
      </Typography>
      <Typography variant="caption" style={{ marginTop: 8 }}>
        (Maximum size 2 MB, PNG/JPG Format)
      </Typography>
      {_renderWebCamPhotoModal()}
      {_renderImage()}
    </>
  );
}
PhotoUpload.propTypes = {
  photoBase64: PropTypes.string,
  uploadPhoto: PropTypes.func.isRequired,
  label: PropTypes.string,
  elementId: PropTypes.string,
  imgDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  disableUploadFromDevice: PropTypes.bool,
};
PhotoUpload.defaultProps = {
  photoBase64: undefined,
  label: 'Photo',
  elementId: '',
  imgDimensions: {
    width: IMGDIMENSIONS.PROFILE.width,
    height: IMGDIMENSIONS.PROFILE.height,
  },
  disabled: false,
  disableUploadFromDevice: false,
};
