import './polyfill';
import './preloadScripts';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import {
  Authenticator,
  SignIn,
  SignUp,
  Greetings,
  ForgotPassword,
  RequireNewPassword,
} from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import awsConfig from './aws-exports';
import customAwsExports from './aws-exports.custom';
import Login from './views/Login';
import reducer from './reducers';
// import { UnroutedErrorBoundary } from './views/Custom';
import AyudaanSignup from './views/AyudaanSignup';
import CustomForgotPassword from './views/Login/CustomForgotPassword';
import NewApp from './NewApp';
import * as serviceWorker from './serviceWorker';
// import store from './store';
import RoutedErrorBoundary from './components/Custom/ErrorBoundary/RoutedErrorBoundary';

import 'typeface-muli';

// import RottoSottoSignup from './views/RottoSottoSignup';
// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';
// console.log(awsConfig);
// console.log(customAwsExports);
const store = createStore(reducer);

const theme = createTheme({
  palette: {
    background: {
      default: '#E9F0F4',
    },
    primary: {
      main: '#25B67D',
    },
    text: {
      primary: '#243238',
      secondary: 'rgb(36,50,56, 0.6)',
      hint: 'rgb(36,50,56, 0.6)',
      // disabled: 'rgba(0, 0, 0, 0.7)',
    },
  },
  typography: {
    // pxToRem: f(),
    fontFamily: ['Muli', 'sans-serif'].join(','),
    fontSize: 12,
    fontWeightBold: 700,
    h1: {
      fontFamily: ['Muli', 'sans-serif'].join(','),
    },
    h2: {
      fontFamily: ['Muli', 'sans-serif'].join(','),
    },
    h3: {
      fontFamily: ['Muli', 'sans-serif'].join(','),
    },
    h4: {
      fontFamily: ['Muli', 'sans-serif'].join(','),
    },
    h5: {
      fontFamily: ['Muli', 'sans-serif'].join(','),
      fontWeight: 500,
      fontSize: '22px',
    },
    h6: {
      fontFamily: ['Muli', 'sans-serif'].join(','),
    },
    body1: {
      padding: '5px',
    },
  },
});

theme.overrides = {
  MuiButton: {
    sizeLarge: {
      fontWeight: 600,
      width: '256px',
      fontSize: '16px',
    },
    containedPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      label: {
        color: 'white',
        backgroundColor: 'white',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    contained: {
      fontFamily: ['Muli', 'sans-serif'].join(','),
      textTransform: 'none',
    },
    outlined: {
      fontFamily: ['Muli', 'sans-serif'].join(','),
      textTransform: 'none',
      color: theme.palette.primary.main,
    },
    textPrimary: {
      fontFamily: ['Muli', 'sans-serif'].join(','),
    },
  },
  MuiInputBase: {
    root: {
      '&$disabled': {
        color: '#000000',
        '& fieldset': {
          border: 'none',
        },
      },
    },
  },
  MuiFormLabel: {
    root: {
      '&$disabled': {
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.primary.main,
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      disableEnforceFocus: true,
    },
  },
};

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <RoutedErrorBoundary>
            <SnackbarProvider maxSnack={3}>
              <Authenticator
                theme={{ button: { background: '#25B67D' } }}
                hide={[SignUp, SignIn, Greetings, ForgotPassword]}
                amplifyConfig={{ ...awsConfig, ...customAwsExports }}
              >
                {/* <TsRequireNewPassword /> */}
                <CustomForgotPassword />
                <Login />
                <NewApp />
                <AyudaanSignup />
              </Authenticator>
            </SnackbarProvider>
          </RoutedErrorBoundary>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// disable ServiceWorker
// registerServiceWorker();
