export const DISTRICTS_ML = [
  {
    label: 'East Garo Hills',
    value: 'EAST_GARO_HILLS'
  },
  {
    label: 'East Jaintia Hills',
    value: 'EAST_JAINTIA_HILLS'
  },
  {
    label: 'East Khasi Hills',
    value: 'EAST_KHASI_HILLS'
  },
  {
    label: 'North Garo Hills',
    value: 'NORTH_GARO_HILLS'
  },
  {
    label: 'Ri Bhoi',
    value: 'RI_BHOI'
  },
  {
    label: 'South Garo Hills',
    value: 'SOUTH_GARO_HILLS'
  },
  {
    label: 'South West Garo Hills',
    value: 'SOUTH_WEST_GARO_HILLS'
  },
  {
    label: 'South West Khasi Hills',
    value: 'SOUTH_WEST_KHASI_HILLS'
  },
  {
    label: 'West Garo Hills',
    value: 'WEST_GARO_HILLS'
  },
  {
    label: 'West Jaintia Hills',
    value: 'WEST_JAINTIA_HILLS'
  },
  {
    label: 'West Khasi Hills',
    value: 'WEST_KHASI_HILLS'
  }
];
