export const DISTRICTS_GJ = [
  {
    label: 'Ahmedabad',
    value: 'AHMEDABAD'
  },
  {
    label: 'Amreli',
    value: 'AMRELI'
  },
  {
    label: 'Anand',
    value: 'ANAND'
  },
  {
    label: 'Aravalli',
    value: 'ARAVALLI'
  },
  {
    label: 'Banaskantha (Palanpur)',
    value: 'BANASKANTHA'
  },
  {
    label: 'Bharuch',
    value: 'BHARUCH'
  },
  {
    label: 'Bhavnagar',
    value: 'BHAVNAGAR'
  },
  {
    label: 'Botad',
    value: 'BOTAD'
  },
  {
    label: 'Chhota Udepur',
    value: 'CHHOTA_UDEPUR'
  },
  {
    label: 'Dahod',
    value: 'DAHOD'
  },
  {
    label: 'Dangs (Ahwa)',
    value: 'DANGS'
  },
  {
    label: 'Devbhoomi Dwarka',
    value: 'DEVBHOOMI_DWARKA'
  },
  {
    label: 'Gandhinagar',
    value: 'GANDHINAGAR'
  },
  {
    label: 'Gir Somnath',
    value: 'GIR_SOMNATH'
  },
  {
    label: 'Jamnagar',
    value: 'JAMNAGAR'
  },
  {
    label: 'Junagadh',
    value: 'JUNAGADH'
  },
  {
    label: 'Kachchh',
    value: 'KACHCHH'
  },
  {
    label: 'Kheda (Nadiad)',
    value: 'KHEDA'
  },
  {
    label: 'Mahisagar',
    value: 'MAHISAGAR'
  },
  {
    label: 'Mehsana',
    value: 'MEHSANA'
  },
  {
    label: 'Morbi',
    value: 'MORBI'
  },
  {
    label: 'Narmada (Rajpipla)',
    value: 'NARMADA'
  },
  {
    label: 'Navsari',
    value: 'NAVSARI'
  },
  {
    label: 'Panchmahal (Godhra)',
    value: 'PANCHMAHAL'
  },
  {
    label: 'Patan',
    value: 'PATAN'
  },
  {
    label: 'Porbandar',
    value: 'PORBANDAR'
  },
  {
    label: 'Rajkot',
    value: 'RAJKOT'
  },
  {
    label: 'Sabarkantha (Himmatnagar)',
    value: 'SABARKANTHA'
  },
  {
    label: 'Surat',
    value: 'SURAT'
  },
  {
    label: 'Surendranagar',
    value: 'SURENDRANAGAR'
  },
  {
    label: 'Tapi (Vyara)',
    value: 'TAPI'
  },
  {
    label: 'Vadodara',
    value: 'VADODARA'
  },
  {
    label: 'Valsad',
    value: 'VALSAD'
  }
];
