import React, { Component } from 'react';
import {
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { VALIDATION } from '../../../constants';
import { ValidatedInput } from '../../Custom';
import TransplantSpecialistPicker from './TransplantSpecialistPicker';
import StyledDialogTitle from '../../common/StyledDialogTitle';

class ValidatedSpecialistInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectSpecialistModal: false,
      selectedSpecialist: '',
    };
  }

  _toggleSelectSpecialistModal(show) {
    this.setState({
      selectSpecialistModal: show,
    });
  }

  render() {
    return (
      <>
        {this._renderSpecialistInput()}
        {this._renderSelectSpecialistModal()}
      </>
    );
  }

  _renderSpecialistInput() {
    const { specialistUser, disabled, showValid, onValidation, formStatus } = this.props;
    let { validation } = this.props;
    let valid = true;
    let messages = [];
    let joiError = null;
    const style = {};
    if (validation === true) {
      validation = VALIDATION.ALL;
    } else if (validation === false) {
      validation = VALIDATION.OFF;
    }
    if (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && this.isDirty)) {
      if (!specialistUser || !specialistUser.userId) {
        valid = false;
        messages = ['Cannot be empty'];
        joiError = new Error('Cannot be empty');
        style.borderColor = 'red';
      } else if (showValid) {
        style.borderColor = 'green';
      }
      onValidation(valid, messages, joiError);
    } else {
      valid = true;
      messages = [];
    }

    return (
      <>
        {specialistUser && specialistUser.userId ? (
          // <InputGroup>
          //   <ValidatedInput
          //     type="text"
          //     value={specialistUser.name}
          //     disabled={true}
          //     placeholder="Specialist"
          //     validation={VALIDATION.OFF}
          //   />
          //   {!disabled ? (
          //     <InputGroupAddon addonType="append">
          //       <Button
          //         type="button"
          //         color="primary"
          //         onClick={() => {
          //           this._toggleSelectSpecialistModal(true);
          //         }}
          //       >
          //         Change
          //       </Button>
          //     </InputGroupAddon>
          //   ) : null}
          // </InputGroup>
          <Box>
            <ValidatedInput
              type="text"
              value={specialistUser.name}
              disabled={true}
              placeholder="Specialist"
              validation={VALIDATION.OFF}
            />
            {!disabled && formStatus !== 'SUBMITTED' ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this._toggleSelectSpecialistModal(true);
                }}
              >
                Change
              </Button>
            ) : null}
          </Box>
        ) : (
          <>
            {!disabled ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this._toggleSelectSpecialistModal(true);
                  }}
                  style={style}
                >
                  Select
                </Button>
                <p
                  className={classnames({
                    'invalid-feedback': validation && !valid,
                    'valid-feedback': validation && showValid && valid,
                  })}
                  style={
                    (validation && !valid) || (validation && showValid && valid)
                      ? { display: 'block', color: 'red' }
                      : { display: 'none' }
                  }
                >
                  {messages[0]}
                </p>
              </>
            ) : null}
          </>
        )}
      </>
    );
  }

  _renderSelectSpecialistModal() {
    const { selectSpecialistModal, selectedSpecialist } = this.state;
    const { specialistType, onChange } = this.props;
    return (
      <Dialog
        open={selectSpecialistModal}
        onClose={() => {
          this._toggleSelectSpecialistModal(false);
        }}
        maxWidth="md"
        fullWidth
        disableEnforceFocus
      >
        <StyledDialogTitle>
          <Grid container justify="space-between" alignItems="center">
            {`Add ${specialistType}`}
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                this._toggleSelectSpecialistModal(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </StyledDialogTitle>{' '}
        <DialogContent style={{ margin: 20 }}>
          <Grid container sm={12} xs={12} spacing={2}>
            <TransplantSpecialistPicker
              specialistsType={specialistType}
              selectedSpecialist={selectedSpecialist}
              selectSpecialistUser={onChange}
              component={this}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this._toggleSelectSpecialistModal(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  componentDidMount() {
    const { specialist } = this.props;
    this.setState({ selectedSpecialist: specialist });
  }
}
ValidatedSpecialistInput.propTypes = {
  specialistUser: PropTypes.objectOf(PropTypes.any),
  specialistType: PropTypes.string.isRequired,
  specialist: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onValidation: PropTypes.func,
  showValid: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};
ValidatedSpecialistInput.defaultProps = {
  specialistUser: undefined,
  specialist: undefined,
  validation: false,
  onValidation: () => 'onValidation STUB',
  showValid: false,
};

export default ValidatedSpecialistInput;
