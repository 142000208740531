export const DISTRICTS_UK = [
  {
    label: 'Almora',
    value: 'ALMORA'
  },
  {
    label: 'Bageshwar',
    value: 'BAGESHWAR'
  },
  {
    label: 'Chamoli',
    value: 'CHAMOLI'
  },
  {
    label: 'Champawat',
    value: 'CHAMPAWAT'
  },
  {
    label: 'Dehradun',
    value: 'CEHRADUN'
  },
  {
    label: 'Haridwar',
    value: 'HARIDWAR'
  },
  {
    label: 'Nainital',
    value: 'NAINITAL'
  },
  {
    label: 'Pauri Garhwal',
    value: 'PAURI_GARHWAL'
  },
  {
    label: 'Pithoragarh',
    value: 'PITHORAGARH'
  },
  {
    label: 'Rudraprayag',
    value: 'RUDRAPRAYAG'
  },
  {
    label: 'Tehri Garhwal',
    value: 'TEHRI_GARHWAL'
  },
  {
    label: 'Udham Singh Nagar',
    value: 'UDHAM_SINGH_NAGAR'
  },
  {
    label: 'Uttarkashi',
    value: 'UTTARKASHI'
  }
];
