export const RECIPIENT_KIDNEY = {
  dateOfDialysis: null,
  periodOfDialysis: '',
  hlaTyping: {
    a: '',
    b: '',
    c: '',
    dq: '',
    dr: '',
    dm: '',
    dp: '',
    tap: '',
    tnf: '',
  },
  covidVaccination: {
    doseType: '',
    firstDoseDate: null,
    secondDoseDate: null,
    boosterDoseDate: null,
    doseAttachment: {
      name: '',
      key: '',
      url: '',
    },
  },
  dialysisInitiationReport: {
    name: '',
    key: '',
    url: '',
  },
  previousFailedGraft: null,
  proofOfKidneyTransplant: {
    name: '',
    key: '',
    url: '',
  },
  livingDonorNeedingTransplant: null,
  livingDonationReport: {
    name: '',
    key: '',
    url: '',
  },
  vafGraft: {
    vafGraft: null,
    vafGraftRemarks: '',
    vafGraftReport: {
      name: '',
      key: '',
      url: '',
    },
    vafGraftnull: null,
    vafGraftFailure: [],
  },
  vafFitsula: {
    vafFitsula: null,
    vafFitsulaRemarks: '',
    vafFitsulaReport: {
      name: '',
      key: '',
      url: '',
    },
    // vafFitsulanull: null,
    vafFitsulaFailure: [],
  },
  pra: {
    praYesNo: '',
    praReport: {
      name: '',
      key: '',
      url: '',
    },
  },
  viralMarkerStatus: {
    hbsagRemark: '',
    hbsagReportDate: null,
    hbsagReport: {
      name: '',
      key: '',
      url: '',
    },
    antiHcvRemark: '',
    antiHcvReportDate: null,
    antiHcvReport: {
      name: '',
      key: '',
      url: '',
    },
    hcvRnaQuantitativeRemark: '',
    hcvRnaQuantitativeReportDate: null,
    hcvRnaQuantitativeReport: {
      name: '',
      key: '',
      url: '',
    },
    hcvDnaRemark: '',
    hcvDnaReportDate: null,
    hcvDnaReport: {
      name: '',
      key: '',
      url: '',
    },
    hivRemark: '',
    hivReportDate: null,
    hivReport: {
      name: '',
      key: '',
      url: '',
    },
  },
  cardiacFitnessCertificate: {
    name: '',
    key: '',
    url: '',
  },
  pulmonaryFitnessCertificateYesNo: null,
  pulmonaryFitnessCertificateDetails: '',
  pulmonaryFitnessCertificate: {
    name: '',
    key: '',
    url: '',
  },
  psychiatricFitnessCertificate: {
    name: '',
    key: '',
    url: '',
  },
  urologyFitnessCertificate: {
    name: '',
    key: '',
    url: '',
  },
  otherAttachments: [],
  etiologyDetails: '',
  etiologies: [
    {
      etiology: '',
      attachment: {
        name: '',
        key: '',
        url: '',
      },
    },
  ],
  nephrologistTeam: [],
  transplantSurgeon: [],
};

export const RECIPIENT_LUNGS = {
  created: '',
  organId: '',
  organType: 'LUNGS',
  hospitalId: '',
  recipientLungsStatus: '',
  patientAdmissionfile: {
    name: '',
    key: '',
    url: '',
  },
  treatingDoctorClinicalSummaryReport: {
    name: '',
    key: '',
    url: '',
  },
  echo2dReport: {
    name: '',
    key: '',
    url: '',
  },
  abgReport: {
    name: '',
    key: '',
    url: '',
  },
  pftReport: {
    name: '',
    key: '',
    url: '',
  },
  hrctReport: {
    name: '',
    key: '',
    url: '',
  },
  endStageLungDisease: null,
  inotropeDetails: '',
  inotropePositiveDetails: '',
  typesOfEcmo: '',
  ecmoSupport: null,
  ecmoCannulation: '',
  dateOfEcmo: '',
  irv: '',
  ecmoRpm: '',
  ecmoWeaning: null,
  ecmoComplication: '',
  respiratoryFialureDependent: {
    dependentOnMechanicalVentilation: null,
    durationOnVentilator: '',
    ventilationSupport: null,
    ventilatorSetting: '',
    lungComplicationDetails: '',
  },
  ishltCriteria: null,
  etiologyDetails: '',
  etiologies: [
    {
      etiology: '',
      attachment: {
        name: '',
        key: '',
        url: '',
      },
    },
  ],
  intensivistTeam: [],
  pulmonologistTeam: [],
  transplantSurgeon: [],
  otherComplications: '',
  otherAttachments: [],
  urgencyStatus: '',
  viralMarkerStatus: {
    hbsagRemark: '',
    hbsagReportDate: null,
    hbsagReport: {
      name: '',
      key: '',
      url: '',
    },
    antiHcvRemark: '',
    antiHcvReportDate: null,
    antiHcvReport: {
      name: '',
      key: '',
      url: '',
    },
    hcvRnaQuantitativeRemark: '',
    hcvRnaQuantitativeReportDate: null,
    hcvRnaQuantitativeReport: {
      name: '',
      key: '',
      url: '',
    },
    hcvDnaRemark: '',
    hcvDnaReportDate: null,
    hcvDnaReport: {
      name: '',
      key: '',
      url: '',
    },
    hivRemark: '',
    hivReportDate: null,
    hivReport: {
      name: '',
      key: '',
      url: '',
    },
  },
};

export const RECIPIENT_PANCREAS = {
  dateOfDialysis: null,
  periodOfDialysis: '',
  reportOfDialysisInitiation: {
    name: '',
    key: '',
    url: '',
  },
  serumCreatinine: '',
  peptide: '',
  peptideReport: {
    name: '',
    key: '',
    url: '',
  },
  viralMarkerStatus: {
    hbsagRemark: '',
    hbsagReportDate: null,
    hbsagReport: {
      name: '',
      key: '',
      url: '',
    },
    antiHcvRemark: '',
    antiHcvReportDate: null,
    antiHcvReport: {
      name: '',
      key: '',
      url: '',
    },
    hcvRnaQuantitativeRemark: '',
    hcvRnaQuantitativeReportDate: null,
    hcvRnaQuantitativeReport: {
      name: '',
      key: '',
      url: '',
    },
    hcvDnaRemark: '',
    hcvDnaReportDate: null,
    hcvDnaReport: {
      name: '',
      key: '',
      url: '',
    },
    hivRemark: '',
    hivReportDate: null,
    hivReport: {
      name: '',
      key: '',
      url: '',
    },
  },
  hypoglycemia: null,
  brittleDiabetes: null,
  pancreaticExocrineInsuff: null,
  pancreasAsMultipleOrgan: null,
  poorQualityOfLifeWithInsulin: null,
  diabeticKetoacidosis: null,
  insulinRequiredPerDay: '',
  numberOfDialysisAccessChange: null,
  fbsReport: {
    name: '',
    key: '',
    url: '',
  },
  fbsReportDate: null,
  hba1cReport: {
    name: '',
    key: '',
    url: '',
  },
  hba1cReportDate: null,
  antiInsulinYesNo: null,
  antiInsulinReport: {
    name: '',
    key: '',
    url: '',
  },
  antiInsulinReportDate: null,
  antigadYesNo: null,
  antigadReport: {
    name: '',
    key: '',
    url: '',
  },
  antigadReportDate: null,
  diabetes: {
    diabetesType: '',
    secondaryComplications: null,
    insulinDependency: null,
    minimalCoronaryRisk: null,
    treatmentDetails: '',
    diabeticReport: {
      name: '',
      key: '',
      url: '',
    },
    noneDetails: '',
  },
  otherAttachments: [],
  etiologyDetails: '',
  etiologies: [
    {
      etiology: '',
      attachment: {
        name: '',
        key: '',
        url: '',
      },
    },
  ],
  renalInsuff: null,
  creatinineClearance: '',
  endocrinologistTeam: [],
  transplantSurgeon: [],
};

export const RECIPIENT_INTESTINE = {
  liverDysfucntion: null,
  patientRequiresAbdominalWall: null,
  ultraSoundLiverAttachment: {
    name: '',
    key: '',
    url: '',
  },
  lftAttachment: {
    name: '',
    key: '',
    url: '',
  },
  ultraSoundAndLftDate: null,
  extensiveThrombosis: null,
  ultraSoundLiverAttachmentForThrombosis: {
    name: '',
    key: '',
    url: '',
  },
  ctAttachment: {
    name: '',
    key: '',
    url: '',
  },
  ultraSoundThrombosisAndCtDate: null,
  portomesentricThrombosis: null,
  portomesentricThrombosisReport: {
    name: '',
    key: '',
    url: '',
  },
  massiveBowelResection: {
    massiveBowelResection: null,
    massiveBowelResectionAttachment: {
      name: '',
      key: '',
      url: '',
    },
    massiveBowelResectionDate: null,
    lengthOfBowel: null,
  },
  viralMarkerStatus: {
    hbsagRemark: '',
    hbsagReportDate: null,
    hbsagReport: {
      name: '',
      key: '',
      url: '',
    },
    antiHcvRemark: '',
    antiHcvReportDate: null,
    antiHcvReport: {
      name: '',
      key: '',
      url: '',
    },
    hcvRnaQuantitativeRemark: '',
    hcvRnaQuantitativeReportDate: null,
    hcvRnaQuantitativeReport: {
      name: '',
      key: '',
      url: '',
    },
    hcvDnaRemark: '',
    hcvDnaReportDate: null,
    hcvDnaReport: {
      name: '',
      key: '',
      url: '',
    },
    hivRemark: '',
    hivReportDate: null,
    hivReport: {
      name: '',
      key: '',
      url: '',
    },
  },
  otherAttachments: [],
  etiologies: [
    {
      reportType: [],
      dateOfReport: '',
      attachment: {
        name: '',
        key: '',
      },
    },
  ],
  etiologyDetails: '',
  primaryIntestinalFailure: {
    primaryIntestinalFailure: null,
    tpn: {
      tpn: null,
      failureOfParenteralNutrition: {
        failureOfParenteralNutrition: null,
        impendingOrOvertLiverFailure: null,
        impendingOrOvertLiverFailureLftAttachment: {
          name: '',
          key: '',
          url: '',
        },
        impendingOrOvertLiverFailureLftDate: null,
        thrombosisOfTwoOrMoreCentralVeins: null,
        thrombosisOfTwoOrMoreCentralVeinsAttachment: {
          name: '',
          key: '',
          url: '',
        },
        thrombosisOfTwoOrMoreCentralVeinsAttachmentDate: null,
        catheterRealtedSystemicSepsis: null,
        catheterRealtedSystemicSepsisAttachment: {
          name: '',
          key: '',
          url: '',
        },
        catheterRealtedSystemicSepsisAttachmentDate: null,
        lineRelatedFungemia: null,
        lineRelatedFungemiaAttachment: {
          name: '',
          key: '',
          url: '',
        },
        lineRelatedFungemiaAttachmentDate: null,
        severeDehydration: null,
        severeDehydrationAttachment: {
          name: '',
          key: '',
          url: '',
        },
        severeDehydrationAttachmentDate: null,
      },
    },
  },
  gastroenterologistTeam: [],
  transplantSurgeon: [],
};

export const DONOR = {
  basicInfo: {
    isNottoId: null,
    nottoId: '',
    reason: '',
    name: '',
    dateOfBirth: null,
    currentAge: '',
    bloodGroup: '',
    sex: '',
    heightCm: '',
    heightIn: '',
    weightKg: '',
    dateOfAdmission: null,
    dateOfDeath: null,
    nationality: '',
    countryOfResidence: '',
    state: '',
    district: '',
    religion: '',
    treatingDoctorName: '',
    treatingDoctorEmail: '',
    treatingDoctorContact: '',
    icuDoctorName: '',
    icuDoctorContact: '',
    listingType: '',
  },
  medicalReportsAndStatus: {
    basicMedicalInformation: {
      chestCircumIn: '',
      chestCircumCm: '',
      abdomenCircumIn: '',
      abdomenCircumCm: '',
      causeOfDeath: '',
      firstApneaTime: '',
      firstApneaBaselineReport: {
        name: '',
        key: '',
        url: '',
      },
      firstApneaAfterTenMinutesReport: {
        name: '',
        key: '',
        url: '',
      },
      secondApneaTime: '',
      secondApneaBaselineReport: {
        name: '',
        key: '',
        url: '',
      },
      secondApneaAfterTenMinutesReport: {
        name: '',
        key: '',
        url: '',
      },
      covidVaccination: {
        dosesDone: '',
        firstDose: {
          firstDoseDate: '',
          firstDoseAttachment: {
            name: '',
            key: '',
            url: '',
          },
        },
        bothDose: {
          firstDoseDate: '',
          secondDoseDate: '',
          bothDoseAttachment: {
            name: '',
            key: '',
            url: '',
          },
        },
        boosterDose: {
          firstDoseDate: '',
          secondDoseDate: '',
          boosterDoseDate: '',
          doseAttachment: {
            name: '',
            key: '',
            url: '',
          },
        },
      },
      medicoLegalCase: '',
      medicoLegalCaseYesNo: null,
      medicoLegalCaseNotRequiredReason: '',
      medicoLegalCaseNoc: {
        name: '',
        key: '',
        url: '',
      },
      consentObtained: null,
      consentReason: '',
      dateOfOnsetOfIllness: null,
      dateOfHospitalization: null,
      abdominalChestInjuries: null,
      abdominalChestInjuriesDetails: '',
      operationOnDonor: null,
      operationOnDonorDetails: '',
      abdominalOrChestInjuryReport: {
        name: '',
        key: '',
        url: '',
      },
      operationOnDonorReport: {
        name: '',
        key: '',
        url: '',
      },
      covidHistory: null,
      covidPositiveDate: '',
      covidCureDate: '',
      covidSymptoms: '',
      latestRtpct: {
        name: '',
        key: '',
        url: '',
      },
      episodeOfCardiacArrest: null,
      durationOfCardiacArrest: '',
      alcoholism: null,
      alcoholismInfo: '',
      alcoholismHistory: '',
      alcoholismReport: {
        name: '',
        key: '',
        url: '',
      },
      smokingHistory: '',
      smokingInfo: '',
      diabetics: '',
      diabeticsInfo: '',
      htn: '',
      htnInfo: '',
      ihd: '',
      ihdInfo: '',
      asthma: '',
      asthmaInfo: '',
      lungDisease: '',
      lungDiseaseInfo: '',
      anyOtherMedications: {
        name: '',
        key: '',
        url: '',
      },
    },
    hemoDynamicParameters: {
      pulse: '',
      bp: '',
      temperature: '',
      hourlyUrineOutput: '',
      noradrenalineDose: '',
      adrenalineDose: '',
      dopamineDose: '',
      vasopressinDose: '',
      ventilatorSetting: {
        modeOfVentilation: '',
        fio2: '',
        peep: '',
        durationOnVentilator: '',
        ventilationSupport: null,
        ventilatorSettings: '',
      },
      pressureSupport: '',
      durationOfPressureSupport: '',
      drugAndDoseCurrent: '',
      drugAndDosePrevious: '',
    },
    viralMarkerStatus: {
      hbsagRemark: '',
      hbsagReportDate: null,
      hbSagReport: {
        name: '',
        key: '',
        url: '',
      },
      hbcabRemark: '',
      hbcabReportDate: null,
      hbcabReport: {
        name: '',
        key: '',
        url: '',
      },
      hbvdna: '',
      hbvdnaReport: {
        name: '',
        key: '',
        url: '',
      },
      hcvrna: '',
      hcvrnaReport: {
        name: '',
        key: '',
        url: '',
      },
      antiHcvRemark: '',
      antiHcvReportDate: null,
      antiHcvReport: {
        name: '',
        key: '',
        url: '',
      },
      hivRemark: '',
      hivReportDate: null,
      hivReport: {
        name: '',
        key: '',
        url: '',
      },
      hcvRnaQuantitativeRemark: '',
      hcvRnaQuantitativeReportDate: null,
      hcvRnaQuantitativeReport: {
        name: '',
        key: '',
        url: '',
      },
      hepatetisCRemark: '',
      hepatetisCReportDate: null,
      hepatetisCReport: {
        name: null,
        key: null,
        url: null,
      },
    },
    latestInvestigations: {
      bloodSugarLevel: '',
      hemoglobin: '',
      wbc: '',
      differential: '',
      platelet: '',
      cbcReport: {
        name: '',
        key: '',
        url: '',
      },
      ph: '',
      pco2: '',
      po2: '',
      hco3: '',
      sao2: '',
      abgReport: {
        name: '',
        key: '',
        url: '',
      },
      hba1c: '',
      hba1cReport: {
        name: '',
        key: '',
        url: '',
      },
      urineRoutineReport: {
        name: '',
        key: '',
        url: '',
      },
    },
    renalFunction: {
      serumCreatinineAtPresent: '',
      serumCreatinineDuringAdmission: '',
      naDuringAdmission: '',
      naAtPresent: '',
      potassium: '',
      calcium: '',
      chloride: '',
      bun: '',
      usgKidneyForAbnormality: '',
      sepsis: '',
      kidneySize: '',
      evidenceOfRcc: null,
      anyOtherCyst: null,
      lossOfCmd: null,
      urineRoutine: {
        physical: {
          quantity: null,
          color: null,
          appearance: null,
          deposit: null,
          reaction: null,
          specificGravity: null,
        },
        chemical: {
          protein: null,
          glucose: null,
          occultBlood: null,
          ketones: null,
          bileSalt: null,
          pigments: null,
        },
        microscopic: {
          pusCells: null,
          rbc: null,
          epithelialCells: null,
          casts: null,
          crystals: null,
        },
      },
      reports: [],
    },
    liverFunction: {
      sgotDuringAdmission: '',
      sgptDuringAdmission: '',
      sgotAtPresent: '',
      sgptPresent: '',
      sBilirubin: '',
      totalBilirubinDuringAdmission: '',
      totalBilirubinAtPresent: '',
      directBilirubinDuringAdmission: '',
      directBilirubinAtPresent: '',
      indirectBilirubinDuringAdmission: '',
      indirectBilirubinAtPresent: '',
      alkalinePhosphataseProthrombinDuringAdmission: '',
      alkalinePhosphataseProthrombinAtPresent: '',
      inr: '',
      totalProteinDuringAdmission: '',
      totalProteinAtPresent: '',
      albuminDuringAdmission: '',
      albuminAtPresent: '',
      rftLft: '',
      usgLiver: '',
      usgAbdomin: '',
      usgKidney: '',
      sepsis: '',
      fat: '',
      sizeLiverSpan: '',
      lingularLobe: null,
      so2: null,
      hacmangioma: null,
      noOneOrMoreHcc: null,
      surfaceNodularity: null,
      roudingOfLiverMargins: null,
      parenchymalNodules: null,
      alteredEchotexlere: null,
      volumeRedistribution: null,
      caudateHypertorphy: null,
      obviousCirrhoticLiver: null,
      prothrombinTime: '',
      dvDiameter: '',
      pvDiameter: '',
      pvFlow: '',
      svDiameter: '',
      collaterals: null,
      splenomegaly: null,
      ascites: null,
      liverUltraSoundReport: {
        name: '',
        key: '',
        url: '',
      },
      reports: [],
    },
    heartFunction: {
      twoDEcho: '',
      twoDEchoReport: {
        name: '',
        key: '',
        url: '',
      },
      chestXRay: '',
      chestXRayReport: {
        name: '',
        key: '',
        url: '',
      },
      angiographyYesNo: null,
      angiography: '',
      angiographyReport: {
        name: '',
        key: '',
        url: '',
      },
      bloodSampleForCrossMatchYesNo: null,
      bloodSampleForCrossMatch: '',
      bloodSampleForCrossMatchReport: {
        name: '',
        key: '',
        url: '',
      },
      bloodSampleForHeartCrossMatchingYesNo: null,
      bloodSampleForHeartCrossMatching: '',
      bloodSampleForHeartCrossMatchingReport: {
        name: '',
        key: '',
        url: '',
      },
      ctThoraxYesNo: null,
      ctThorax: '',
      ctThoraxReport: {
        name: '',
        key: '',
        url: '',
      },
      tropTYesNo: null,
      tropT: '',
      tropTReport: {
        name: '',
        key: '',
        url: '',
      },
    },
    medicalHistory: {
      tumor: null,
      infections: '',
      infectionsReport: {
        name: '',
        key: '',
        url: '',
      },
      liveVaccines: null,
      liveVaccinesReport: {
        name: '',
        key: '',
        url: '',
      },
      occupationalExposure: '',
      occupationalExposureDetails: '',
      // occupationalExposureReport: {
      //   name: '',
      //   key: '',
      //   url: '',
      // },
      travelHistory: '',
      travelHistoryReport: '',
      // {
      //   name: '',
      //   key: '',
      //   url: '',
      // },
      bloodTransfusion: '',
      bloodTransfusionDetails: '',
      // bloodTransfusionReport: {
      //   name: '',
      //   key: '',
      //   url: '',
      // },
      contactWithTransmissibleDiseases: '',
      contactWithTransmissibleDiseasesDetails: '',
      illicitDrugs: null,
      illicitDrugsDetails: '',
      contactWithTransmissibleDiseasesReport: {
        name: '',
        key: '',
        url: '',
      },
      illicitDrugsReport: {
        name: '',
        key: '',
        url: '',
      },
      tattoEarOrBodyPiercing: null,
      tattoEarOrBodyPiercingDetails: '',
      unsafeSexPratices: null,
      unsafeSexPraticesDetails: '',
      tattoEarOrBodyPiercingReport: {
        name: '',
        key: '',
        url: '',
      },
      unsafeSexPraticesReport: {
        name: '',
        key: '',
        url: '',
      },
    },
    handAssessment: {
      handAssessmentDamageType: [],
      handXRay: {
        name: '',
        key: '',
        url: '',
      },
      historyOfNeurologicalIllness: '',
      historyOfPreviousTrauma: '',
      historyOfOtherDisease: '',
    },
    imaging: {
      ctHead: {
        ctHeadReport: {
          name: '',
          key: '',
          url: '',
        },
        ctHeadSdh: null,
        ctHeadEdh: null,
        ctHeadIntraVentricularBleed: null,
        ctHeadMidLineShift: null,
      },
      mriHead: {
        mriHeadReport: {
          name: '',
          key: '',
          url: '',
        },
        mriHeadSdh: null,
        mriHeadEdh: null,
        mriHeadIntraVentricularBleed: null,
        mriHeadMidLineShift: null,
      },
      xRayChest: {
        xRayChestReport: {
          name: '',
          key: '',
          url: '',
        },
      },
      xRayAbdomen: {
        xRayAbdomenYesNo: null,
        xRayAbdomenReport: {
          name: '',
          key: '',
          url: '',
        },
      },
      ctChest: {
        ctChestYesNo: null,
        ctChestReport: {
          name: '',
          key: '',
          url: '',
        },
        evidenceOfPneumonia: null,
        evidenceOfLungsFibrosis: null,
        evidenceOfCovid: null,
        evidenceOfAnyMalignancy: null,
      },
      usgAbdomenAndPelvis: {
        usgAbdomenAndPelvisReport: {
          name: '',
          key: '',
          url: '',
        },
      },
      ctAbdomen: {
        ctAbdomenYesNo: null,
        liverSize: '',
        pvSize: '',
        pvt: null,
        ecotexture: null,
        fattyLiver: null,
        evidenceOfPortalHypertension: null,
        splenomegaly: null,
        collaterals: null,
        ascites: null,
        solInLiver: null,
        solInLiverDetails: '',
        lossOfCmd: null,
        lossOfCmdDetails: '',
        anyLesionInKidney: null,
        anyLesionInKidneyDetails: '',
        anyOtherAbnormalFinding: null,
        anyOtherAbnormalFindingDetails: '',
      },
      mriAbdomen: {
        mriAbdomenYesNo: null,
        liverSize: '',
        pvSize: '',
        pvt: null,
        ecotexture: null,
        fattyLiver: null,
        evidenceOfPortalHypertension: null,
        splenomegaly: null,
        collaterals: null,
        ascites: null,
        solInLiver: null,
        solInLiverDetails: '',
        lossOfCmd: null,
        lossOfCmdDetails: '',
        anyLesionInKidney: null,
        anyLesionInKidneyDetails: '',
        anyOtherAbnormalFinding: null,
        anyOtherAbnormalFindingDetails: '',
      },
      reports: [],
    },
  },
  attachments: {
    anyReports: [],
    form8Report: {
      name: '',
      key: '',
      url: '',
    },
    form10Report: {
      name: '',
      key: '',
      url: '',
    },
    admissionReport: {
      name: '',
      key: '',
      url: '',
    },
    clinicalHistoryReport: {
      name: '',
      key: '',
      url: '',
    },
    icuChartReport: {
      name: '',
      key: '',
      url: '',
    },
    investigationChartReport: {
      name: '',
      key: '',
      url: '',
    },
    imagingHistopathologyEndoscopiesReport: {
      name: '',
      key: '',
      url: '',
    },
    angiographyReport: {
      name: '',
      key: '',
      url: '',
    },
    bronchoscopyReport: {
      name: '',
      key: '',
      url: '',
    },
    cultureReportYesNo: null,
    cultureReportType: [],
    cultureReportStatus: '',
    cultureReport: {
      name: '',
      key: '',
      url: '',
    },
    procalcitoninReport: {
      name: '',
      key: '',
      url: '',
    },
  },
  organRetrievalInformation: {
    donorEligibleForDonation: null,
    notEligibleReason: '',
    retrievedOrgans: [],
    retrievedOtherOrgans: '',
    abdominalWallHarvested: '',
    levelOfRetrivalFromLeftHand: '',
    levelOfRetrivalFromRightHand: '',
    liverSplittingCriteria: {
      meetSplittingCriteria: '',
      icu: '',
      sgot: '',
      vasopresser: '',
    },
    anyEcdOrgans: null,
    ecdOrgans: [],
    anyDcdOrgans: null,
    deathCertificate: {
      name: '',
      key: '',
      url: '',
    },
    retrievalOrganTime: '',
    bodyHandoverTime: '',
    sideOfKidneyCompatibleForDonation: '',
  },
  tumorInformation: {
    absoluteContraindication: {
      absoluteContraindication: null,
      primaryCerebralLymphoma: null,
      secondaryIntracranialTumors: null,
      activeCancerSpread: null,
      activeHaematologicalMalignancy: null,
    },
    highRisk: {
      highRisk: null,
      malignantMelanoma: null,
      breatCarcinoma: null,
      colonCarcinoma: null,
      choriocarinoma: null,
      cnsTumorWithVentriculoperitoneal: null,
      cnsTumorWhoGrade: null,
    },
    intermediateRisk: {
      intermediateRisk: null,
      colonCarcinoma: null,
      choriocarinoma: null,
      cnsTumorWithVentriculoperitoneal: null,
      cnsTumorWhoGrade: null,
    },
    lowRisk: {
      lowRisk: null,
      solitaryRenalCellCarcinoma: null,
      lowGradeCns: null,
      primaryCnsmatureTeratoma: null,
      solitaryPapillaryThyroidCarcinoma: null,
      minimallyInvasiveFollicularCarcinoma: null,
      historyOfTreatedNonCnsMalignancy: null,
    },
    minimalRisk: {
      minimalRisk: null,
      solitaryRenalCellCarcinoma: null,
      lowGradeCns: null,
      primaryCnsmatureTeratoma: null,
      solitaryPapillaryThyroidCarcinoma: null,
      minimallyInvasiveFollicularCarcinoma: null,
      nonCnsMalignancy: null,
    },
  },
  ecdInformation: {
    heartEcd: {
      significantCardiacAnomalies: null,
      myocardialInfarction: null,
      significantValvularAbnormalities: null,
      refractoryVentricularArrhythmias: null,
      ejectionFractionLessThanFortyFivePercentByEcho: null,
      sizeWeightMatching: null,
    },
    kidneyEcd: {
      chronicKidneyDisease: null,
      combodities: null,
    },
    liverEcd: {
      macrosteatosis: '',
      highInotrope: null,
      dopamine: '',
      noradrenaline: '',
      adrenaline: '',
      vasopressin: '',
      transaminitis: null,
      transaminitisSelect: '',
      bilirubin: '',
      positiveBloodCulture: null,
      cit: '',
      partialGraft: null,
      splitGraft: null,
      sodium: '',
    },
  },
  transferToRotto: null,
  isAddedByRotto : null
};

// export const DONOR
