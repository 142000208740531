import React from 'react';
import { compose } from 'redux';
import { makeStyles, Paper, Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { withRouter } from 'react-router-dom';
import { withValidation } from '../../../hoc';
import { ValidatedAttachment } from '../../Custom';
import GreenTagSectionHeader from '../../common/GreenTagSectionHeader';

function AllocationDonorAttachment(props) {
  const { donorMedicalReportsDetails, donorAttachmentsDetails } = props;
  const {
    medicoLegalCaseNoc,
    abdominalOrChestInjuryReport,
    operationOnDonorReport,
    firstApneaBaselineReport,
    firstApneaAfterTenMinutesReport,
    secondApneaBaselineReport,
    secondApneaAfterTenMinutesReport,
    latestRtpct,
    anyOtherMedications,
  } = donorMedicalReportsDetails?.basicMedicalInformation || {};

  const { hbSagReport, antiHcvReport, hivReport, hbcabReport, hbvdnaReport, hcvrnaReport } =
    donorMedicalReportsDetails?.viralMarkerStatus || {};
  const { cbcReport, abgReport, hba1cReport, urineRoutineReport } =
    donorMedicalReportsDetails?.latestInvestigations || {};
  // renalFunction: { reports: renalFunctionReports },
  const { liverUltraSoundReport } = donorMedicalReportsDetails?.liverFunction || {};
  const {
    twoDEchoReport,
    angiographyReport: heartFunctionAngiographyReport,
    chestXRayReport,
    bloodSampleForHeartCrossMatchingReport,
    ctThoraxReport,
    tropTReport,
  } = donorMedicalReportsDetails?.heartFunction || {};
  const { infectionsReport, liveVaccinesReport } = donorMedicalReportsDetails?.medicalHistory || {};
  const { handXRay } = donorMedicalReportsDetails?.handAssessment || {};

  const { ctHeadReport } = donorMedicalReportsDetails?.imaging?.ctHead || {};
  const { mriHeadReport } = donorMedicalReportsDetails?.imaging?.mriHead || {};
  const { xRayChestReport } = donorMedicalReportsDetails?.imaging?.xRayChest || {};
  const { xRayAbdomenReport } = donorMedicalReportsDetails?.imaging?.xRayAbdomen || {};
  const { ctChestReport } = donorMedicalReportsDetails?.imaging?.ctChest || {};
  const { usgAbdomenAndPelvisReport } =
    donorMedicalReportsDetails?.imaging?.usgAbdomenAndPelvis || {};

  const {
    anyReports,
    form8Report,
    form10Report,
    admissionReport,
    clinicalHistoryReport,
    icuChartReport,
    investigationChartReport,
    imagingHistopathologyEndoscopiesReport,
    angiographyReport,
    cultureReport,
  } = donorAttachmentsDetails || {};

  const useStyles = makeStyles(() => ({
    paperTransplantInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 10,
      marginBottom: 10,
    },
    gridTransplantDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperTransplantSections: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px 0px 0px 4px',
      height: '100%',
    },
    transplantSectionsStyle: {
      fontWeight: 300,
      lineHeight: '15px',
      marginTop: 20,
      marginLeft: 20,
    },
    transplantSectionsHeadingStyle: {
      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    transplantDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#34495E',
      marginTop: '5px',
    },
  }));

  const classes = useStyles();

  const _isDisabled = () => {
    return true;
  };
  const _renderOtherAttachment = (d) => {
    return (
      <Grid item sm={4} xs={12}>
        <ValidatedAttachment
          required
          id="Attachment"
          name="Attachment"
          label="Other Attachment"
          value={d}
          disabled={_isDisabled()}
        />
      </Grid>
    );
  };
  const _renderAnyOtherAttachment = (d) => {
    const { attachmentName, attachment } = d;
    return (
      <Grid item sm={4} xs={12}>
        <ValidatedAttachment
          required
          id="Attachment"
          name="Attachment"
          label="Other Attachment"
          value={attachment}
          disabled={_isDisabled()}
        />
      </Grid>
    );
  };

  return (
    <>
      <Grid container>
        <Grid container item xs={12} direction="row" className={classes.gridTransplantDetails}>
          <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
            <Grid container justify="space-between">
              <GreenTagSectionHeader headerText="Medical Report and Status" />
            </Grid>
            <Grid container item sm={12}>
              <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} />
            </Grid>
            <Grid container justify="space-between">
              <GreenTagSectionHeader headerText="Basic Medical Information" />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="First Apnea baseline Report"
                name="First Apnea baseline Report"
                label="First Apnea baseline Report"
                value={firstApneaBaselineReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="First Apnea after 10 minutes Report"
                name="First Apnea after 10 minutes Report"
                label="First Apnea after 10 minutes Report"
                value={firstApneaAfterTenMinutesReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Second Apnea baseline Report"
                name="Second Apnea baseline Report"
                label="Second Apnea baseline Report"
                value={secondApneaBaselineReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Second Apnea after 10 minutes Report"
                name="Second Apnea after 10 minutes Report"
                label="Second Apnea after 10 minutes Report"
                value={secondApneaAfterTenMinutesReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Abdominal or Chest Injuries Report"
                name="Abdominal or Chest Injuries Report"
                label="Abdominal or Chest Injuries Report"
                value={abdominalOrChestInjuryReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Operation On Donor Report"
                name="Operation On Donor Report"
                label="Operation On Donor Report"
                value={operationOnDonorReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Police NOC Attachment"
                name="Police NOC Attachment"
                label="Police NOC Attachment"
                value={medicoLegalCaseNoc}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Latest RTPCR Report"
                name="Latest RTPCR Report"
                label="Latest RTPCR Report"
                value={latestRtpct}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Latest Medications Information"
                name="Latest Medications Information"
                label="Latest Medications Information"
                value={anyOtherMedications}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Infections Report"
                name="Infections Report"
                label="Infections Report"
                value={infectionsReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Live Vaccine Report"
                name="Live Vaccine Report"
                label="Live Vaccine Report"
                value={liveVaccinesReport}
                disabled={_isDisabled()}
              />
            </Grid>

            <Grid container item sm={12}>
              <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} />
            </Grid>
            <Grid container justify="space-between">
              <GreenTagSectionHeader headerText="Microbiology" />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="HBsAg Report"
                name="HBsAg Report"
                label="HBsAg Report"
                value={hbSagReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="HBcAb Report"
                name="HBcAb Report"
                label="HBcAb Report"
                value={hbcabReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="HBV DNA Report"
                name="HBV DNA Report"
                label="HBV DNA Report"
                value={hbvdnaReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Anti HCV (Hepatitis C) Report"
                name="Anti HCV (Hepatitis C) Report"
                label="Anti HCV (Hepatitis C) Report"
                value={antiHcvReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="HCV RNA Report"
                name="HCV RNA Report"
                label="HCV RNA Report"
                value={hcvrnaReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="HIV Report"
                name="HIV Report"
                label="HIV Report"
                value={hivReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid container item sm={12}>
              <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} />
            </Grid>
            <Grid container justify="space-between">
              <GreenTagSectionHeader headerText="Latest Investigation" />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="CBC Report"
                name="CBC Report"
                label="CBC Report"
                value={cbcReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="ABG Report"
                name="ABG Report"
                label="ABG Report"
                value={abgReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="HbA1c Report"
                name="HbA1c Report"
                label="HbA1c Report"
                value={hba1cReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Urine Routine Report"
                name="Urine Routine Report"
                label="Urine Routine Report"
                value={urineRoutineReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid container item sm={12}>
              <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} />
            </Grid>
            <Grid container justify="space-between">
              <GreenTagSectionHeader headerText="Liver Fuction" />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Liver Ultrasound Report"
                name="Liver Ultrasound Report"
                label="Liver Ultrasound Report"
                value={liverUltraSoundReport}
                disabled={_isDisabled()}
              />
            </Grid>
            {donorMedicalReportsDetails?.liverFunction?.reports.map((d, idx) =>
              _renderOtherAttachment(d, idx)
            )}
            <Grid container item sm={12}>
              <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} />
            </Grid>
            <Grid container justify="space-between">
              <GreenTagSectionHeader headerText="Heart Fuction" />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="2D-ECHO Report"
                name="2D-ECHO Report"
                label="2D-ECHO Report"
                value={twoDEchoReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Chest X-ray Report"
                name="Chest X-ray Report"
                label="Chest X-ray Report"
                value={chestXRayReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Angiography Report"
                name="Angiography Report"
                label="Angiography Report"
                value={heartFunctionAngiographyReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Blood Sample of Heart Cross Matching Report"
                name="Blood Sample of Heart Cross Matching Report"
                label="Blood Sample of Heart Cross Matching Report"
                value={bloodSampleForHeartCrossMatchingReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="CT Thorax for Lung Report"
                name="CT Thorax for Lung Report"
                label="CT Thorax for Lung Report"
                value={ctThoraxReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Trop T (Troponin) Report"
                name="Trop T (Troponin) Report"
                label="Trop T (Troponin) Report"
                value={tropTReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid container item sm={12}>
              <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} />
            </Grid>
            <Grid container justify="space-between">
              <GreenTagSectionHeader headerText="Kidney Fuction" />
            </Grid>
            {donorMedicalReportsDetails?.renalFunction?.reports.map((r, idx) =>
              _renderOtherAttachment(r, idx)
            )}
            <Grid container item sm={12}>
              <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} />
            </Grid>
            <Grid container justify="space-between">
              <GreenTagSectionHeader headerText="Hand Assessment" />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Hand X-Ray"
                name="Hand X-Ray"
                label="Hand X-Ray"
                value={handXRay}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid container item sm={12}>
              <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} />
            </Grid>
            <Grid container justify="space-between">
              <GreenTagSectionHeader headerText="Imaging" />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="CT Head Report"
                name="CT Head Report"
                label="CT Head Report"
                value={ctHeadReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="MRI Head Report"
                name="MRI Head Report"
                label="MRI Head Report"
                value={mriHeadReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="X-Ray Chest Film"
                name="X-Ray Chest Film"
                label="X-Ray Chest Film"
                value={xRayChestReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="X-Ray Abdomen Film"
                name="X-Ray Abdomen Film"
                label="X-Ray Abdomen Film"
                value={xRayAbdomenReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="CT Chest Report"
                name="CT Chest Report"
                label="CT Chest Report"
                value={ctChestReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="USG Abdomen and Pelvis Report Attachment"
                name="USG Abdomen and Pelvis Report Attachment"
                label="USG Abdomen and Pelvis Report Attachment"
                value={usgAbdomenAndPelvisReport}
                disabled={_isDisabled()}
              />
            </Grid>
            {donorMedicalReportsDetails?.imaging?.reports.map((r, idx) =>
              _renderOtherAttachment(r, idx)
            )}
            <Grid container item sm={12}>
              <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} />
            </Grid>
            <Grid container justify="space-between">
              <GreenTagSectionHeader headerText="Attachments" />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Form 8 - Declaration cum Consent"
                name="Form 8 - Declaration cum Consent"
                label="Form 8 - Declaration cum Consent"
                value={form8Report}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Form 10 - For Certification of Brain Stem Death"
                name="Form 10 - For Certification of Brain Stem Death"
                label="Form 10 - For Certification of Brain Stem Death"
                value={form10Report}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Admission Notes"
                name="Admission Notes"
                label="Admission Notes"
                value={admissionReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Clinical History and Medical Summary"
                name="Clinical History and Medical Summary"
                label="Clinical History and Medical Summary"
                value={clinicalHistoryReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="ICU Chart (Images Clicked / Copies for all days)"
                name="ICU Chart (Images Clicked / Copies for all days)"
                label="ICU Chart (Images Clicked / Copies for all days)"
                value={icuChartReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Investigation Chart / Table"
                name="Investigation Chart / Table"
                label="Investigation Chart / Table"
                value={investigationChartReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Histopathology and other reports relevant to the case"
                name="Histopathology and other reports relevant to the case"
                label="Histopathology and other reports relevant to the case"
                value={imagingHistopathologyEndoscopiesReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Bronchoscopy Report"
                name="Bronchoscopy Report"
                label="Bronchoscopy Report"
                value={angiographyReport}
                disabled={_isDisabled()}
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Culture Reports"
                name="Culture Reports"
                label="Culture Reports"
                value={cultureReport}
                disabled={_isDisabled()}
              />
            </Grid>
            {donorAttachmentsDetails?.anyReports?.map((a, index) =>
              _renderAnyOtherAttachment(a, index)
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
AllocationDonorAttachment.propTypes = {
  // validate: PropTypes.func.isRequired,
  // validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  // onValidation: PropTypes.func.isRequired,
};

export default compose(withRouter, withValidation)(AllocationDonorAttachment);
