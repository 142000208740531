export const DISTRICTS_KA = [
  { label: 'Bagalkot', value: 'BAGALKOT' },
  {
    label: 'Ballari (Bellary)',
    value: 'BALLARI'
  },
  {
    label: 'Belagavi (Belgaum)',
    value: 'BELAGAVI'
  },
  {
    label: 'Bengaluru (Bangalore) Rural',
    value: 'BENGALURU_RURAL'
  },
  {
    label: 'Bengaluru (Bangalore) Urban',
    value: 'BENGALURU_URBAN'
  },
  {
    label: 'Bidar',
    value: 'BIDAR'
  },
  {
    label: 'Chamarajanagar',
    value: 'CHAMARAJANAGAR'
  },
  {
    label: 'Chikballapur',
    value: 'CHIKBALLAPUR'
  },
  {
    label: 'Chikkamagaluru (Chikmagalur)',
    value: 'CHIKKAMAGALURU'
  },
  {
    label: 'Chitradurga',
    value: 'CHITRADURGA'
  },
  {
    label: 'Dakshina Kannada',
    value: 'DAKSHINAKANNADA'
  },
  {
    label: 'Davangere',
    value: 'DAVANGERE'
  },
  {
    label: 'Dharwad',
    value: 'DHARWAD'
  },
  {
    label: 'Gadag',
    value: 'GADAG'
  },
  {
    label: 'Hassan',
    value: 'HASSAN'
  },
  {
    label: 'Haveri',
    value: 'HAVERI'
  },
  {
    label: 'Kalaburagi (Gulbarga)',
    value: 'KALABURAGI'
  },
  {
    label: 'Kodagu',
    value: 'KODAGU'
  },
  {
    label: 'Kolar',
    value: 'KOLAR'
  },
  {
    label: 'Kopal',
    value: 'KOPAL'
  },
  {
    label: 'Mandya',
    value: 'MANDYA'
  },
  {
    label: 'Mysuru (Mysore)',
    value: 'MYSURU'
  },
  {
    label: 'Raichur',
    value: 'RAICHUR'
  },
  {
    label: 'Ramanagara',
    value: 'RAMANAGARA'
  },
  {
    label: 'Shivamogga (Shimoga)',
    value: 'SHIVAMOGGA'
  },
  {
    label: 'Tumakuru (Tumkur)',
    value: 'TUMAKURU'
  },
  {
    label: 'Udupi',
    value: 'UDUPI'
  },
  {
    label: 'Uttara Kannada (Karwar)',
    value: 'UTTARAKANNADA'
  },
  {
    label: 'Vijayapura (Bijapur)',
    value: 'VIJAYAPURA'
  },
  {
    label: 'Yadgir',
    value: 'YADGIR'
  }
];
