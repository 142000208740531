import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  DialogActions,
  DialogContent,
  Dialog,
  Box,
  TextField,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import util from 'util';
import cloneDeep from 'lodash.clonedeep';
import { withValidation } from '../../../hoc';
import { useCancellableApiCall } from '../../../hooks';
import NewOtherSupportiveSpecialistsModal from './NewOtherSupportiveSpecialistsModal';
import { SPECIALIST as EMPTY_SPECIALIST } from '../../../emptyModels';
import { VALIDATION } from '../../../constants';
import SpecialistPreview from '../../SpecialistPreview/SpecialistPreview';
import { PATH_EXP_GETSPECIALIST, PATH_EXP_GETSPECIALISTS } from '../../../appConstants';
import StyledDialogTitle from '../../common/StyledDialogTitle';

function ValidatedOtherSupportiveSpecialists(props) {
  const [newOtherSupportiveSpecialistsModal, setNewOtherSupportiveSpecialistsModal] =
    useState(false);
  const [previewSpecialistModal, setPreviewSpecialistModal] = useState(false);
  const [specialists, setSpecialists] = useState([]);
  const [selectedSpecialistUserId, setSelectedSpecialistUserId] = useState('');
  const [selectedSpecialistHospitalId, setSelectedSpecialistHospitalId] = useState('');
  const [selectedSpecialist, setSelectedSpecialist] = useState(cloneDeep(EMPTY_SPECIALIST));

  const { apiGet } = useCancellableApiCall();

  const app = useSelector((state) => state);
  const {
    user: { userType, hospitalId },
  } = app;

  useEffect(() => {
    apiGet(PATH_EXP_GETSPECIALISTS, {
      trainedPersonType: 'SURGEON',
      hospitalId,
      userType,
    })
      .then((spcls) => {
        setSpecialists(spcls);
      })
      .catch(() => {});
  }, []);

  const _toggleNewOtherSupportiveSpecialistsModal = (show) => {
    setNewOtherSupportiveSpecialistsModal(show);
  };

  const _togglePreviewSpecialistModal = (show, userId, hospitalId) => {
    setPreviewSpecialistModal(show);
    setSelectedSpecialistUserId(show ? userId : '');
    setSelectedSpecialistHospitalId(show ? hospitalId : '');
    apiGet(util.format(PATH_EXP_GETSPECIALIST, selectedSpecialistUserId), {
      hospitalId: selectedSpecialistHospitalId,
      userType,
    })
      .then((spcl) => {
        setSelectedSpecialist(spcl);
      })
      .catch(() => {});
  };

  const {
    currentSelectedOtherSupportiveSpecialists,
    allSelectedOtherSupportiveSpecialists,
    setOtherSupportiveSpecialists,
    disabled,
    showValid,
    onValidation,
  } = props;
  let { validation, isDirty } = props;
  let valid = true;
  let messages = [];
  let joiError = null;
  const style = {};
  if (validation === true) {
    validation = VALIDATION.ALL;
  } else if (validation === false) {
    validation = VALIDATION.OFF;
  }
  if (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && isDirty)) {
    if (
      !Array.isArray(currentSelectedOtherSupportiveSpecialists) ||
      currentSelectedOtherSupportiveSpecialists.length === 0
    ) {
      valid = false;
      messages = ['Cannot be empty'];
      joiError = new Error('Cannot be empty');
      style.borderColor = 'red';
    } else if (showValid) {
      style.borderColor = 'green';
    }
    onValidation(valid, messages, joiError);
  } else {
    valid = true;
    messages = [];
  }

  const _renderPreviewSpecialistModal = () => {
    const { userType, userId } = props;
    return (
      <Dialog
        open={previewSpecialistModal}
        onClose={() => {
          _togglePreviewSpecialistModal(false);
        }}
        maxWidth="sm"
        fullScreen
        disableEnforceFocus
      >
        <StyledDialogTitle>
          <Grid container justify="space-between" alignItems="center">
            Specialist Details
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                _togglePreviewSpecialistModal(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </StyledDialogTitle>
        <DialogContent style={{ margin: 20 }}>
          <Grid container sm={12} xs={12}>
            <SpecialistPreview
              specialistUserId={selectedSpecialistUserId}
              specialistHospitalId={selectedSpecialistHospitalId}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              _togglePreviewSpecialistModal(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const _renderTrainedPerson = (trainedPerson) => {
    return (
      <Grid item sm={3}>
        <Box display="flex" flexDirection="row">
          <TextField
            disabled={true}
            variant="outlined"
            fullWidth
            value={trainedPerson.user.name}
            InputLabelProps={{ shrink: true }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: 0,
              maxHeight: '100%',
              height: '60px',
              textAlign: 'center',
              margin: 0,
              padding: 0,
            }}
            onClick={() =>
              _togglePreviewSpecialistModal(
                true,
                trainedPerson.user.userId,
                trainedPerson.user.hospitalId
              )
            }
          >
            View
          </Button>
        </Box>
      </Grid>
    );
  };

  const _renderValidationError = (messages, style) => {
    return (
      <Grid>
        <TextField
          disabled={true}
          fullWidth
          variant="outlined"
          value="Please Check"
          label="TrainedPerson"
          InputLabelProps={{ shrink: true }}
          style={style}
        />
        <p className="invalid-feedback" style={{ display: 'block', color: 'red' }}>
          {messages[0]}
        </p>
      </Grid>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Grid container>
            {valid
              ? currentSelectedOtherSupportiveSpecialists.map((cSTP) => _renderTrainedPerson(cSTP))
              : _renderValidationError(messages, style)}
          </Grid>
        </Grid>
        <Grid item sm={12}>
          {!disabled ? (
            <Button
              id="trainedPerson"
              block
              color="primary"
              variant="contained"
              onClick={() => {
                _toggleNewOtherSupportiveSpecialistsModal(true);
              }}
            >
              Add Supportive Specialists
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <NewOtherSupportiveSpecialistsModal
        isOpen={newOtherSupportiveSpecialistsModal}
        toggle={_toggleNewOtherSupportiveSpecialistsModal}
        trainedPersons={allSelectedOtherSupportiveSpecialists}
        setOtherSupportiveSpecialists={(trainedPersons) =>
          setOtherSupportiveSpecialists(trainedPersons)
        }
        specialists={specialists}
      />
      {_renderPreviewSpecialistModal()}
    </>
  );
}

ValidatedOtherSupportiveSpecialists.propTypes = {
  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
};

export default withValidation(ValidatedOtherSupportiveSpecialists);
