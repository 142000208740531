import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { BLOODGROUP, FORMAT_DATE_UNIXTS, isHospitalIcUserType } from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class PancreasRegistrationFormPdf {
  formData = {};

  constructor(formData) {
    const {
      pancreasDetails,
      basicDetails,
      contactNos,
      contactEmailIds,
      hospitalName,
      ztccZone,
      organs,
    } = formData;

    const typeOfDiabeticsFormatter = (value) => {
      if (value === 'TYPE1DM') {
        return 'TYPE I DM';
      }
      if (value === 'TYPE2DM') {
        return 'TYPE II DM';
      }
      if (value === 'NONE') {
        return 'None';
      }
      return '-';
    };

    const _yesNoAndEmptyBooleanFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };

    const _yesNoFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };

    const _nationalityFormatter = (value) => {
      if (value === 'INDIAN_NATIONAL') {
        return 'Indian';
      }
      if (value === 'OCI_CARDHOLDER') {
        return 'Overseas Citizen of India (OCI) card holder';
      }
      if (value === 'FOREIGN_NATIONAL') {
        return 'Foreign national';
      }
      return '-';
    };
    const _yesNoAndEmptyFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      if (value === 'true') {
        return 'YES';
      }
      if (value === 'false') {
        return 'NO';
      }
      return '-';
    };

    const calculateCurrentAge = () => {
      if (basicDetails.dateOfBirth) {
        const duration = moment.duration(
          moment().diff(moment(basicDetails.dateOfBirth, FORMAT_DATE_UNIXTS))
        );
        return `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`;
      }
      if (basicDetails.currentAge) {
        return basicDetails.currentAge;
      }
      return '-';
    };
    const _getZtccZoneLabelById = (zone) => {
      if (zone === '7') {
        return 'ZTCC MUMBAI';
      }
      if (zone === '8') {
        return 'ZTCC PUNE';
      }
      if (zone === '9') {
        return 'ZTCC NAGPUR';
      }
      if (zone === '10') {
        return 'ZTCC AURANGABAD';
      }
      if (zone) {
        return zone;
      }

      return '';
    };

    this.formData = formData;
    const reportDocDefinition = {
      content: [
        {
          text: '',
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              // 20,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 40,
              // 40,
              'auto',
              'auto',
              // 40,
              'auto',
            ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'State Appropriate Authority of Maharashtra',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: 'PANCREAS REGISTRATION',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `ZTCC Zone: ${_getZtccZoneLabelById(ztccZone)}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date of Registration : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.dateOfRegistration
                      ? moment(basicDetails?.dateOfRegistration, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Name:`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.name ? basicDetails?.name : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `NOTTO ID : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.nottoId}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Birth: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.dateOfBirth
                      ? moment(basicDetails?.dateOfBirth, FORMAT_DATE_UNIXTS).format('DD MMMM YYYY')
                      : '-'
                  } `,

                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Age: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${calculateCurrentAge()}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Gender: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.sex ? basicDetails?.sex : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Group: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Weight (Kg): `,
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.weightKg}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in cm):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightCm}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in inches):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightIn ? basicDetails?.heightIn : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${hospitalName || '-'}`, colSpan: 2 },
                {},
              ],

              [
                {
                  text: `Address & Telephone No: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.presentAddress ? basicDetails?.presentAddress : '-'} - ${
                    contactNos.length > 0 ? contactNos[0] : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Patient contact emails:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${contactEmailIds.length > 0 ? contactEmailIds[0] : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Economic Status: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.economicStatus ? basicDetails?.economicStatus : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Occupation',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.occupation}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_nationalityFormatter(basicDetails?.nationality)}`,
                  colSpan: 2,
                },
                {},
              ],
              ...(basicDetails?.nationality !== 'INDIAN_NATIONAL'
                ? [
                    [
                      {
                        text: `Indian Contact Number : `,
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${
                          basicDetails?.indianContactNumber
                            ? basicDetails?.indianContactNumber
                            : '-'
                        }`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              [
                {
                  text: `Country of Residence: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.countryOfResidence ? basicDetails?.countryOfResidence : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              // [
              //   {
              //     text: 'Date of RT PCR',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${
              //       basicDetails.rtpcrdate
              //         ? moment(basicDetails.rtpcrdate, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
              //         : '-'
              //     }`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],
              // [
              //   {
              //     text: 'Is Covid Vaccination Done?',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${_covidVaccinationFormatter(basicDetails?.covidVaccination?.dosesDone)}`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],

              [
                {
                  text: 'UHID',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.uhid}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Present Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.presentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Permanent Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.permanentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Date of Admission / OPD',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.dateOfAdmission
                      ? moment(basicDetails?.dateOfAdmission, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Primary physician Name:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.primaryPhysicianName}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Primary physician contact numbers:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.primaryPhysicianContactNo
                      ? basicDetails?.primaryPhysicianContactNo
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary physician contact email:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.primaryPhysicianEmail ? basicDetails?.primaryPhysicianEmail : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary Questions Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient have active / metastatic malignancy?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMalignancy
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active MDR infection with sepsis or septic shock?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMdrInfection
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have severe irreversible systemic disease (cardiac, pulmonary, neurological or others with very short longevity)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noSevereIrreversibleSystemicDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active or recent major unresolved psychiatric illness?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noMajorUnresolvedPsychiatricIllness
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have high risk of non-compliance with long-term immunosuppression?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noHighRiskOfNonCompliance
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have adequate social or family support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(basicDetails?.prerequisites?.familySupport)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Identity Proof Document Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Document Type',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentType}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identify Proof Document Number',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentNumber}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identity Proof Document Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              // [
              //   {
              //     pageBreak: 'before',
              //     text: 'Family Member Details',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 3,
              //     bold: true,
              //   },

              //   {
              //     text: ``,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
            ],
          },
        },
        {
          text: 'Family Member Details',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Family Members Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Relation With Patient', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Number', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Email', style: 'tableHeader', alignment: 'left' },
              ],
              ...basicDetails.familyMembers.map((a) => [
                { text: `${a.name ? a.name : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.relation ? a.relation : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.contact ? a.contact : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.email ? a.email : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
          // layout: 'lightHorizontalLines',
        },
        {
          text: 'General Information',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Requested Organs', style: 'tableHeader', alignment: 'left' },
                { text: 'Requested Organs Listing Type', style: 'tableHeader', alignment: 'left' },
              ],
              ...organs.map((o) => [
                {
                  text: `${o.organType ? o.organType : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                { text: `${o.category ? o.category : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'General Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'Is Patient Willing to Accept ECD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'ECD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Is Patient Willing to Accept DCD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'DCD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Has the patient been registered in Maharashtra in Past:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.pastZtccListingInfo?.pastZtccListing
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Status',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastListingStatus}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Further Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastZtccDetails}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'PANCREAS FORM DETAILS ',
                  style: 'tableHeader',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              // [
              //   {
              //     text: 'Serum Creatinine(mg/dL)',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       pancreasDetails?.serumCreatinine ? pancreasDetails?.serumCreatinine : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: ' C-Peptide(ng/mL)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.peptide ? pancreasDetails?.peptide : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `C-Peptide Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.peptideReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have Frequent episodes of Hypoglycemia unawareness?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(pancreasDetails?.hypoglycemia)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Does the patient have Brittle Diabetes? ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(pancreasDetails?.brittleDiabetes)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Does the patient have the pancreatic exocrine insufficiency?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(pancreasDetails?.pancreaticExocrineInsuff)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: ' Does the patient have Episode of Diabetic Ketoacidosis?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(pancreasDetails?.diabeticKetoacidosis)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have the poor quality of life with Insulin?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(pancreasDetails?.poorQualityOfLifeWithInsulin)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'INSULIN REQUIREMENT PER DAY (UNITS/KILOGRAMS)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.insulinRequiredPerDay
                      ? pancreasDetails?.insulinRequiredPerDay
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `FBS Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.fbsReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Date of FBS Report : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.fbsReportDate
                      ? moment(pancreasDetails?.fbsReportDate, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HbA1c Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.hba1cReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of HbA1c Report : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.hba1cReportDate
                      ? moment(pancreasDetails?.hba1cReportDate, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Anti-Insulin Antibody',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(pancreasDetails?.antiInsulinYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Anti-Insulin Antibodies Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.antiInsulinReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Anti-Insulin Antibodies Report: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.antiInsulinReportDate
                      ? moment(pancreasDetails?.antiInsulinReportDate, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'AntiGAD Antibody',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(pancreasDetails?.antigadYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `AntiGAD Antibodies Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.antigadReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of AntiGAD Antibodies Report: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.antigadReportDate
                      ? moment(pancreasDetails?.antigadReportDate, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient require the procurement or transplantation of a pancreas as part of a multiple organ transplant for technical reasons',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(pancreasDetails?.pancreasAsMultipleOrgan)}`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: 'Does the patient have diagnosed with diabetes for Type I DM? \n a)Secondary Complications of diabetes \n b) Poor quality of life with Insulin',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${_yesNoAndEmptyFormatter(
              //       pancreasDetails?.diabetes?.secondaryComplications
              //         ? pancreasDetails?.diabetes?.secondaryComplications
              //         : '-'
              //     )}`,
              //     // ${
              //     //   pancreasDetails?.diabetes?.pooerQualityOfLifeWithInsulin
              //     //     ? pancreasDetails?.diabetes?.pooerQualityOfLifeWithInsulin
              //     //     : '-'
              //     // }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: 'Type of diabetes',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },

                {
                  text: `${typeOfDiabeticsFormatter(pancreasDetails?.diabetes?.diabetesType)}`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'If TYPE I DM',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Secondary Complications of diabetes?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    pancreasDetails?.diabetes?.secondaryComplications
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'If TYPE II DM',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient have Insulin dependency?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(pancreasDetails?.diabetes?.insulinDependency)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have any minimal coronary risk or with corrected coronary disease and therefore low cardiac risk?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    pancreasDetails?.diabetes?.minimalCoronaryRisk
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: 'Treatment Details',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       pancreasDetails?.diabetes?.treatmentDetails
              //         ? pancreasDetails?.diabetes?.treatmentDetails
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],

              [
                {
                  text: 'Treatment Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.diabetes?.treatmentDetails
                      ? pancreasDetails?.diabetes?.treatmentDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Diabetes Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.diabetes?.diabeticReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Viral Marker Status',
                  style: 'tableHeader',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'HBsAg',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.viralMarkerStatus?.hbsagRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HBsAg Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.viralMarkerStatus?.hbsagReportDate
                      ? moment(
                          pancreasDetails?.viralMarkerStatus?.hbsagReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HBsAg Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.viralMarkerStatus?.hbsagReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Anti HCV',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.viralMarkerStatus?.antiHcvRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Anti HCV Report Date `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.viralMarkerStatus?.antiHcvReportDate
                      ? moment(
                          pancreasDetails?.viralMarkerStatus?.antiHcvReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Anti HCV Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.viralMarkerStatus?.antiHcvReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'HCV RNA Quantitative',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE' ? 'NOT DONE' : pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HCV RNA Quantitative Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                          ? moment(
                              pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: `HCV RNA Quantitative Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeReport?.name
                          ? 'ATTACHED'
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: 'HCV DNA',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE' ? 'NOT DONE' : pancreasDetails?.viralMarkerStatus?.hcvDnaRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HCV DNA Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                pancreasDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        pancreasDetails?.viralMarkerStatus?.hcvDnaReportDate
                          ? moment(
                              pancreasDetails?.viralMarkerStatus?.hcvDnaReportDate,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: `HCV DNA Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                pancreasDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        pancreasDetails?.viralMarkerStatus?.hcvDnaReport?.name ? 'ATTACHED' : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: 'HIV',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.viralMarkerStatus?.hivRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HIV Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.viralMarkerStatus?.hivReportDate
                      ? moment(
                          pancreasDetails?.viralMarkerStatus?.hivReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HIV Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${pancreasDetails?.viralMarkerStatus?.hivReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Etiology `,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   pancreasDetails.etiologies.map((e) => [
              //     { text: `${e.etiology ? e.etiology : '-'}` },
              //   ]),

              //   {},
              // ],
            ],
          },
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Etiology', style: 'tableHeader', alignment: 'left' },
                { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...pancreasDetails?.etiologies?.map((e) => [
                {
                  text: `${e.etiology ? e.etiology : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: ['auto', 'auto', 'auto'],
            body: [
              [
                {
                  text: 'Etiology Details ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    pancreasDetails?.etiologyDetails ? pancreasDetails?.etiologyDetails : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },
        {
          text: 'Any Other Attachments',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...pancreasDetails?.otherAttachments?.map((e) => [
                {
                  text: `${e.attachmentName ? e.attachmentName : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Endocrinologist Team', style: 'tableHeader', alignment: 'left' },

                { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
              ],
              [
                (pancreasDetails?.endocrinologistTeam || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
                (pancreasDetails?.transplantSurgeon || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
              ],
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150],
            heights: [0, 50, 50, 50, 50, 50],
            body: [
              [
                {
                  text: 'All verification of the clinical details / reports / criteria eligibility to justify above criteria is the responsibility of the transplant team signing this form. \n1. All the reports (Lab reports + imaging report) should be within 1 month at the time of registration. The patients with older reports should not be considered for registration.\n2. All lab reports should be within 1 month and imaging should be within 3 months for allocation, patients with older reports should not be considered for allocation.',
                  style: 'tableHeader',
                  colSpan: 3,
                  // alignment: 'center',
                  // bold: true,
                  fontSize: 11,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Patient Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Surgeon Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Physician Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Transplant Coordinator Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Signature of Hospital Head:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
        subHeader: {
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 5],
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Pancreas Registration Form`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default PancreasRegistrationFormPdf;

// pancreas
