export const DISTRICTS_GA = [
  {
    label: 'North Goa',
    value: 'NORTH_GOA'
  },
  {
    label: 'South Goa',
    value: 'SOUTH_GOA'
  }
];
