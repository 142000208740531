import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class AllocationDonorEmptyFormPdf {
  constructor() {
    const reportDocDefinition = {
      content: [
        {
          text: '',
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'State Appropriate Authority of Maharashtra - DONOR FORM',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date: `,
                  style: 'textStyle',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `ZTCC Zone: : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `NOTTO ID : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ``,
                  colSpan: 2,
                },
                { text: '' },
                { text: '' },
              ],
              [
                {
                  text: `Date of Registration : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Admission : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Death : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `State & District: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: ``, colSpan: 2 },
                {},
              ],

              [
                {
                  text: `Age: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ` `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Gender:  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ` `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Height: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ``,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Weight: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ` `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Treating Doctor Name `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Treating Doctor Email `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Treating Contact Number `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `ICU Doctor Name `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `ICU Doctor Contact Number `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Is Donor eligible for Organ Donation?`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${donorOrganRetrievalDetails?.donorEligibleForDonation || '-'}`,
              //     colSpan: 3,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `If donor is not eligble, State Reason `,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       donorOrganRetrievalDetails?.notEligibleReason
              //         ? donorOrganRetrievalDetails?.notEligibleReason
              //         : '-'
              //     }`,
              //     colSpan: 3,
              //   },
              //   {},
              // ],
              [
                {
                  text: `List of Organs consented for Retrieval :- `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: `Is any donated organs under ECD ?`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: `List of Organs under ECD`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 3,
                },
                {},
              ],

              // Basic Medical Information
              [
                {
                  text: 'Basic Medical Information',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Chest Circumference (in cm): `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Chest Circumference (in inch): `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Abdomen Circumference (in cm)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Abdomen Circumference (in inch)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Cause of Death: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `First Apnea Time: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `First Apnea Baseline Report: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `First Apnea after 10 minutes Report: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Second Apnea Time:  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: `Second Apnea Baseline Report: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Second Apnea after 10 minutes Report: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Consent Obtained: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: `Date of Onset of Illness: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Abdomenal Chest Injuries: ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Abdomenal Chest Injury Details: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Abdomenal Chest Injury Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Operation on Donor',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Operation on Donor Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Operation on Donor Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Is Covid Vaccination Done?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ``,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Medico Legal Case(If yes provide Details)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: ``, colSpan: 2 },
                {},
              ],
              [
                {
                  text: `Police Noc Approved`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Covid History`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Last Covid Positive Date`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Any Episode of Cardiac Arrest',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Duration of Cardiac Arrest (Minutes)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: 'Pressure Support',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       hemoDynamicParameters?.pressureSupport
              //         ? hemoDynamicParameters?.pressureSupport
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `Duration of Pressure Support (Minutes)`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       hemoDynamicParameters?.durationOfPressureSupport
              //         ? hemoDynamicParameters?.durationOfPressureSupport
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `Current Drug and Dose`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       hemoDynamicParameters?.drugAndDoseCurrent
              //         ? hemoDynamicParameters?.drugAndDoseCurrent
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `Previous Drug and Dose`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       hemoDynamicParameters?.drugAndDosePrevious
              //         ? hemoDynamicParameters?.drugAndDosePrevious
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Any Other Medication`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Alcoholism`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Alcoholism Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${basicMedicalInformation?.alcoholismReport?.name ? 'ATTACHED' : '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Diabetics`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Diabetics Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: ``,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `HTN`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `HTN Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: ``,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `IHD`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `IHD`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${basicMedicalInformation?.ihdReport?.name ? 'ATTACHED' : '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Asthma`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Asthma Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: ``,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Lung Disease`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Lung Disease Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: 'Donor Medical History',
              //     style: 'tableHeader',
              //     colSpan: 3,
              //     alignment: 'center',
              //     bold: true,
              //   },
              //   {},
              //   {},
              // ],
              // [
              //   {
              //     text: `Does the donor have any kind of Tumor?`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${medicalHistory?.tumor || '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Infections in the current / recent addmission`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Infections Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Use of live vaccines (especially in paediatrics)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Infections Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Occupational Exposures`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Infections Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Recent Internation or Domestic Travel History (within 4 weeks)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Travel History Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Recent Transfusions with blood or blood products`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Transfusion Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any exposure with people with HIV, HBV, HCV or other transmissible diseases`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Contact With Transmissible Diseases Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Use of illicit drugs`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Infections Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Tattooing, Ear Piercing or Body Piercing`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Tatto, Ear Or Body Piercing Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Unsafe sexual practices`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Unsafe sexual practices Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       medicalHistory?.unsafeSexPraticesReport?.name ? 'ATTACHED' : 'NOT ATTACHED'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],

              // Hemodynamic
              [
                {
                  pageBreak: 'before',
                  text: 'Hemodynamic Parameter',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `Pulse`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Pressure`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Temperature',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Hourly Urine Output',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Adrenaline Dose ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Noradrenaline Dose ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Dopamine Dose ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Vasopressin Dose ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Ventilator Setting',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Mode of Ventilations',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'FIO2',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'PEEP',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'does the patient on ventilator support',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'duration in days',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              // MicroBiology
              // [
              //   {
              //     text: 'MicroBiology',
              //     style: 'tableHeader',
              //     colSpan: 3,
              //     alignment: 'center',
              //     bold: true,
              //   },
              //   {},
              //   {},
              // ],
              [
                {
                  text: `HBs AG Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HBcAb Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Anti HCV (Hepatetis C) \n (Positive/Negative)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HCV RNA Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HIV Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // Latest Investigations
              [
                {
                  text: 'Donor Latest Investigations',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Hemoglobin `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `WBC Count `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Platelet Count `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `CBC Report  \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ABG pH `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ABG pCO2 `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ABG pO2 `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ABG HCO3 `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ABG SaO2 `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ABG Report  \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Sugar `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hb A1c `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HBA1C Report \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Urine Routine`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Physical Examination',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `Quantity (in ml) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Color `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Appearance  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Deposit `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Reaction `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Specify Gravity `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Chemical Examination',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Protein `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Glucose `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Occult Blood `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Ketones `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Bile Salt `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Pigments `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Microscopic Examination',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Pus Cells  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Red Blood Cells  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Epithelial Cells  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Casts `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Crystals `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Donor Liver Function',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Total Bilirubin (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Total Bilirubin (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Direct Bilirubin (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Direct Bilirubin (at admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Indirect Bilirubin (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Indirect Bilirubin (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ` SGOT (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SGOT (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SGPT (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SGPT (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `S Alkaline Phosphatase (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `S Alkaline Phosphatase (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `S. Bilirubin`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${liverFunction?.sBilirubin ? liverFunction?.sBilirubin : '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Total Protein (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Total Protein (at admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Serum Albumin(during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Serum Albumin (at admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `INR`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `USG Liver for Fatty Liver or any other abnormlity`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `USG Abdomin and Pelvis for any other abnormality`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of Sepsis if Yes PCT report (Specify)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              // [
              //   {
              //     text: `Any abnormality of RFT and LFT till that time to be notified`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${liverFunction?.rftLft ? liverFunction?.rftLft : '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Size Liver Span (cm)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Liver Ultrasound(LFT)',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `Lingular Lobe`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Fat(%)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SO2`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hacmangioma`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HCC

                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Surface Nodularity`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Rounding of Liver Margins`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Parenchymal Nodules`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Altered Echotexlere`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Volume Redistribution`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Caudate Hypertrophy`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Obvious Cirrhotic Liver

                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `PV Diameter(mm)

                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `PV Flow

                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SV Diameter(mm) 

                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Collaterals

                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Splenomegaly

                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Ascites

                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Liver Ultrasound Report

                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Any Report Attachments`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              // Heart Function
              [
                {
                  text: 'Donor Heart Function',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `2D-ECHO`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `2D-ECHO Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Chest X-ray`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Chest X-ray Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Angiography`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Angiography Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${heartFunction?.angiographyReport ? 'ATTACHED' : 'NOT ATTACHED'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Donor Blood Sample for cross match minimum 10-12 Heparin tubes of 5ml each`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Donor Blood Sample for cross match minimum 10-12 Heparin tubes of 5ml each Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       heartFunction?.bloodSampleForCrossMatchReport ? 'ATTACHED' : 'NOT ATTACHED'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Blood Sample of Heart Cross Matching`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Blood Sample of Heart Cross Matching Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       heartFunction?.bloodSampleForHeartCrossMatchingReport
              //         ? 'ATTACHED'
              //         : 'NOT ATTACHED'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `CT Thorax for Lung`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `CT Thorax for Lung Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${heartFunction?.ctThoraxReport ? 'ATTACHED' : 'NOT ATTACHED'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Trop T (Troponin)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Trop T (Troponin) Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${heartFunction?.tropTReport ? 'ATTACHED' : 'NOT ATTACHED'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],

              // Kidney Function
              [
                {
                  text: 'Kidney Function',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Renal Function Test (RFT)',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `Creatinine (During Admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Creatinine (At Present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Electrolytes`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${renalFunction?.electrolytes || '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Sodium (Na) (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Sodium (Na) (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Potassium (K)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Chloride (Cl)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `BUN (Blood Urea Nitrogen)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `USG Kidney for any other abnormality`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of Sepsis`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Kidney Size`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of RCC`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any other cyst`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Loss of CMD`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Any Report Attachments`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              // Hand Assesment

              [
                {
                  text: 'Hand Assessment',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Hand assessment (scars, injuries, vascularity)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hand X-Ray`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Rule out history of neurological illnesses like leprosy, stroke`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Rule out history of previous trauma like \n nerve injuries, tendon injuries, fractures etc`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `History s/o any arthritis / connective tissue disorders / degenerative disorders.`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              // Imaging

              [
                {
                  text: 'Imaging',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `CT Head Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SDH`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `EDH`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Intra Ventricular Bleed`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Mid Line Shift`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              // MRI

              [
                {
                  text: `MRI Head Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SDH`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `EDH`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Intra Ventricular Bleed`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Mid Line Shift`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `X-ray Chest`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `X-ray Abdomen`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `X-ray Abdomen Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              // CT Chest

              [
                {
                  text: `CT Chest`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `CT Chest Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of Pneumonia`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of Lungs Fibrosis`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of COVID`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of any malignancy`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `USG Abdomen and Pelvis Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              // CT Abdomen

              [
                {
                  text: `CT Abdomen`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Liver Size`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `PV Size`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `PVT`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Ecotexture`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Fatty Liver`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of portal hypertension`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Splenomegaly`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Collaterals`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Ascites`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Sol in Liver
                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `sol in liver details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Loss of CMD
                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `loss of cmd details `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any lesion in Kidney
                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `any lesion in kidney details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any other abnormal finding
                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `any other abnormal finding details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              // MRI Abdomen
              [
                {
                  text: `MRI Abdomen`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Liver Size`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `PV Size`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `PVT`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Ecotexture`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Fatty Liver`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of portal hypertension`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Splenomegaly`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Collaterals`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Ascites`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Sol in Liver
                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `sol in liver details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Loss of CMD
                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `loss of cmd details `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any lesion in Kidney
                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `any lesion in kidney details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any other abnormal finding
                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `any other abnormal finding details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Organ Retrieval Information',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Does the donor meet the liver splitting criteria`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ICU stay ≤5 days`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SGOT (AST) / SGPT (ALT) ≤ 3x ULN \n (upper limit of normal) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `On a single or no vasopressor`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Is any donated organs under ECD (Extended Criteria Donor) ? `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `List of Organs under ECD (Extended Criteria Donor)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Is Donor under DCD (Organ Donation after Circulatory Death) ?`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ` Donor Death Certificate`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  pageBreak: 'before',
                  text: 'Extended Criteria Donor Information',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Liver ECD (Extended Criteria Donor) Information',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Macrosteatosis(in %)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `High Ionotropes `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Dopamine(micrograms/kg/min)                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Noradrenaline (micrograms/kg/min)                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Adrenaline (micrograms/kg/min)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Vasopressin (units/hour)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Transaminitis `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Transaminitis Options `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Cholestatic Liver: Bilirubin (mg/dL)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Positive blood culture within last 5 days`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Expected Cold Ischemia Time (CIT) (in Hours)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Partial Graft`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Split Graft`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Last pre-retrieval serum Sodium (mEq/L)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto'],
            heights: [40, 50],
            body: [
              [
                {
                  text: 'Administrative Head of the Hospital:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },

                // {
                //   text: 'Patient Sign:',
                //   colSpan: 2,
                // },
                {},
                {},
              ],
              [
                { text: 'Doctor Signature', colSpan: 3, alignment: 'left' },
                {},

                {
                  text: '',
                },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Donor Registration Form`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default AllocationDonorEmptyFormPdf;
