export const DISTRICTS_TR = [
  {
    label: 'Dhalai',
    value: 'DHALAI'
  },
  {
    label: 'Gomati',
    value: 'GOMATI'
  },
  {
    label: 'Khowai',
    value: 'KHOWAI'
  },
  {
    label: 'North Tripura',
    value: 'NORTH_TRIPURA'
  },
  {
    label: 'Sepahijala',
    value: 'SEPAHIJALA'
  },
  {
    label: 'South Tripura',
    value: 'SOUTH_TRIPURA'
  },
  {
    label: 'Unakoti',
    value: 'UNAKOTI'
  },
  {
    label: 'West Tripura',
    value: 'WEST_TRIPURA'
  }
];
