import React, { Component } from 'react';
// import { Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from '@material-ui/core';
import Joi from 'joi';
import PropTypes from 'prop-types';
import { VALIDATION } from '../../constants';
import { withCancellableResolver } from '../../hoc';

class ValidatedPhotoAttachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoModal: false,
      imageData: '',
      imageFile: '',
    };

    this._captureImageData = this._captureImageData.bind(this);
    this._togglePhotoModal = this._togglePhotoModal.bind(this);
    this._convertImageDataToFile = this._convertImageDataToFile.bind(this);
  }

  _togglePhotoModal(show) {
    this.setState({
      photoModal: show,
    });
  }

  _captureImageData(data) {
    // const { convertImage } = this.props;
    this.setState(
      {
        imageData: data,
      },
      () => {
        this._convertImageDataToFile(data);
      }
    );
  }

  _dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');

    const mime = arr[0].match(/:(.*?);/)[1];

    const bstr = atob(arr[1]);

    let n = bstr.length;

    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  _convertImageDataToFile(data) {
    const { component, handleRecipientDocument } = this.props;
    const file = this._dataURLtoFile(data, 'file.jpeg');
    this.setState(
      {
        imageFile: file,
      },
      () => {
        handleRecipientDocument('fullFacePhoto', file, component);
      }
    );
  }

  render() {
    const { imageFile } = this.state;
    const {
      // value,
      name,
      key,
      placeholder,
      onValidation,
      showValid,
      onChange,
      resolveWithCancellable,
      setLoadingStatus,
      ...other
    } = this.props;
    const value = imageFile;

    // eslint-disable-next-line prefer-const
    let { validation, schema: urlSchema } = other;
    let valid = true;
    let messages = [];
    let joiError = null;
    const style = {};
    if (validation === true) {
      validation = VALIDATION.ALL;
    } else if (validation === false) {
      validation = VALIDATION.OFF;
    }
    if (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && this.isDirty)) {
      if (!urlSchema || !urlSchema.isJoi) {
        urlSchema = Joi.string();
      }
      if (!urlSchema._getLabel()) {
        urlSchema = urlSchema.label(placeholder);
      }

      if (validation & VALIDATION.INVALIDATE_EMPTY) {
        const { emptyFileErr } = Joi.any().required().validate(value.file);
        const { emptyUrlErr } = Joi.string().required().validate(value.url);
        if (emptyFileErr && emptyUrlErr) {
          valid = false;
          messages.push('Cannot be empty');
        }
      }

      const { error } = urlSchema.validate(value.url);
      if (error) {
        valid = false;
        messages.push(error.details.map((d) => d.message));
        joiError = error;
      }

      if (!valid) {
        style.borderColor = 'red';
      } else if (showValid) {
        style.borderColor = 'green';
      }

      onValidation(valid, messages, joiError);
    } else {
      valid = true;
      messages = [];
    }

    return (
      <>
        <Button type="button" color="primary" onClick={() => this._togglePhotoModal(true)}>
          Click Photo
        </Button>
        {this._renderPhotoModal()}
      </>
    );
  }

  _renderPhotoModal() {
    // const { photoModal } = this.state;
    // return (
    //   <Modal
    //     isOpen={photoModal}
    //     style={{
    //       width: '35%',
    //       height: '20%',
    //       maxWidth: 'none'
    //     }}
    //     toggle={() => this._togglePhotoModal(false)}
    //     className="modal-primary"
    //     centered={true}
    //     zIndex={1050}
    //     size="lg"
    //   >
    //     <ModalHeader toggle={() => this._togglePhotoModal(false)}>Photo Modal</ModalHeader>
    //     <ModalBody>
    //       <div className="animated fadeIn">
    //         <Row>
    //           {' '}
    //           <ValidatedWebCam
    //             captureImage={this._captureImageData}
    //             closePhotoModal={this._togglePhotoModal}
    //           />
    //         </Row>
    //       </div>
    //     </ModalBody>
    //     <ModalFooter>
    //       {/* <Button color="secondary" onClick={() => this.capture()}>
    //         Click
    //       </Button> */}
    //       <Button color="secondary" onClick={() => this._togglePhotoModal(false)}>
    //         Close
    //       </Button>
    //     </ModalFooter>
    //   </Modal>
    // );
  }
}
ValidatedPhotoAttachment.propTypes = {
  component: PropTypes.objectOf(PropTypes.any).isRequired,
  // value: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  handleRecipientDocument: PropTypes.func.isRequired,
  onValidation: PropTypes.func,
  showValid: PropTypes.bool,
  placeholder: PropTypes.string,
  resolveWithCancellable: PropTypes.func.isRequired,
  setLoadingStatus: PropTypes.func.isRequired,
};
ValidatedPhotoAttachment.defaultProps = {
  onChange: () => 'onChange STUB',
  onValidation: () => 'onValidation STUB',
  showValid: false,
  placeholder: 'Value',
};

export default withCancellableResolver(ValidatedPhotoAttachment);
