import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import {
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { REGEXP_ONLYCHARS, VALIDATION } from '../../constants';

export default function ValidatedInput({
  value,
  label,
  options,
  onChange,
  onValidation,
  required,
  allowEmpty,
  showValid,
  validationDisabled,
  disabled,
  ...other
}) {
  let isDirty = false;
  const REGEXP_ILLEGALCHARS = new RegExp('.*(<\\w*?>|\\||~|`|\\^).*');
  const [focusShrink, setFocusShrink] = useState(false);

  // eslint-disable-next-line prefer-const
  let {
    validation,
    schema,
    ignoreChangeSchema,
    ignoreChangeSchemaMode,
    InputLabelProps,
    padding,
    ...otherProps
  } = other;
  let valid = true;
  let messages = [];
  let joiError = null;
  if (validation === true) {
    validation = VALIDATION.ALL;
  } else if (validation === false) {
    validation = VALIDATION.OFF;
  }
  if (
    !validationDisabled &&
    (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && isDirty))
  ) {
    if (allowEmpty && Object.is(value, NaN)) {
      valid = true;
      messages = [];
    } else {
      if (!schema || !Joi.isSchema(schema)) {
        schema = Joi.alternatives(Joi.boolean(), Joi.string());
      }
      schema = schema.messages({
        'alternatives.any': 'Please check',
        'any.required': 'Cannot be empty',
        'string.empty': 'Cannot be empty',
      });
      if (required) {
        schema = schema.required();
      }
      if (validation & VALIDATION.INVALIDATE_EMPTY) {
        schema = schema.required();
      }
      if (schema._flags.presence === 'required' && (value === '' || value === null)) {
        // TO HANDLE value === 0
        valid = false;
        messages = ['Cannot be empty'];
        joiError = new Error('Cannot be empty');
      } else {
        const { error } = schema.validate(value);
        if (error) {
          valid = false;
          messages = error.details.map((d) => d.message);
          joiError = error;
        }
      }
    }
    onValidation(valid, messages, joiError);
  } else {
    valid = true;
    messages = [];
  }

  if (!Joi.isSchema(ignoreChangeSchema) && ignoreChangeSchemaMode === 'ONLYCHARS') {
    ignoreChangeSchema = Joi.string().pattern(REGEXP_ONLYCHARS);
  }
  return (
    <>
      {/* <TextField
        variant="outlined"
        size="small"
        error={!!validation && !valid}
        {...otherProps}
        helperText={messages[0] ? messages[0] : otherProps.helperText || ''}
        onChange={(...params) => {
          if (!isDirty) {
            isDirty = true;
          }
          if (Joi.isSchema(ignoreChangeSchema)) {
            ignoreChangeSchema = ignoreChangeSchema.allow('').allow(null);
            const { error } = ignoreChangeSchema.validate(params[0]?.target?.value);
            if (error) {
              return;
            }
          }
          onChange(...params);
        }}
        onFocus={() => {
          setFocusShrink(true);
        }}
        onBlur={() => {
          setFocusShrink(false);
        }}
        InputLabelProps={{
          ...(otherProps.InputLabelProps || {}),
          shrink: focusShrink || value === 0 || !!value,
          style: { padding: 5, margin: -5, fontSize: InputLabelProps?.style?.fontSize },
        }}
        required={required}
        value={value}
        style={{ fontWeight: 'bolder', padding }}
      /> */}
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <Typography style={{ color: 'black' }}>
            {label}
            {required && <span style={{ color: 'black' }}> * </span>}
          </Typography>
          <Typography style={{ color: 'red' }}>
            {!!validation && !valid ? messages[0] : ''}
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-label={label}
          name="controlled-radio-buttons-group"
          value={value}
          error={!!validation && !valid}
          helperText={messages[0] ? messages[0] : otherProps.helperText || ''}
          onChange={(...params) => {
            if (!isDirty) {
              isDirty = true;
            }
            if (Joi.isSchema(ignoreChangeSchema)) {
              ignoreChangeSchema = ignoreChangeSchema.allow('').allow(null);
              const { error } = ignoreChangeSchema.validate(params[0]?.target?.value);
              if (error) {
                return;
              }
            }
            onChange(...params);
          }}
          disabled={disabled}
        >
          {options?.map((o) => {
            return (
              <FormControlLabel
                disabled={disabled}
                value={o.value}
                control={<Radio />}
                label={o.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
}
ValidatedInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  schema: PropTypes.instanceOf(Joi.constructor),
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  validationDisabled: PropTypes.bool,
  showValid: PropTypes.bool,
  required: PropTypes.bool,
  allowEmpty: PropTypes.bool,
};
ValidatedInput.defaultProps = {
  value: undefined,
  validation: false,
  schema: undefined,
  onChange: () => 'onChange STUB',
  onValidation: () => 'onValidation STUB',
  validationDisabled: false,
  showValid: false,
  required: false,
  allowEmpty: false,
};
