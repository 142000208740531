export const DISTRICTS_DL = [
  {
    label: 'Central Delhi',
    value: 'CENTRAL_DELHI'
  },
  {
    label: 'East Delhi',
    value: 'EAST_DELHI'
  },
  {
    label: 'New Delhi',
    value: 'NEW_DELHI'
  },
  {
    label: 'North Delhi',
    value: 'NORTH_DELHI'
  },
  {
    label: 'North East Delhi',
    value: 'NORTH_EAST_DELHI'
  },
  {
    label: 'North West Delhi',
    value: 'NORTH_WEST_DELHI'
  },
  {
    label: 'Shahdara',
    value: 'SHAHDARA'
  },
  {
    label: 'South Delhi',
    value: 'SOUTH_DELHI'
  },
  {
    label: 'South East Delhi',
    value: 'SOUTH_EAST_DELHI'
  },
  {
    label: 'South West Delhi',
    value: 'SOUTH_WEST_DELHI'
  },
  {
    label: 'West Delhi',
    value: 'WEST_DELHI'
  }
];
