import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Paper, Box, Button, Typography, Grid } from '@material-ui/core';
import {
  TISSUES,
  RECIPIENT_HOSPITAL,
  REGEXEXP_NOWHITESPACEATENDANDBEGIN,
  REGEXP_ONLYCHARS,
} from '../../constants';
import { ValidatedInput, ValidatedSelect } from '../Custom';
import ValidatedHospitalInput from './ValidatedHospitalInput/ValidatedHospitalInput';
import ValidatedOtherSupportiveSpecialists from '../Transplant/RecipientValidatedOtherSupportiveSpecialists';
import OtherHospitalValidatedOtherSupportiveSpecialists from '../Transplant/DonorValidatedOtherSupportiveSpecialists';
import OtherHospitalValidatedSpecialistInput from './DonorValidatedSpecialistInput';
import Joi from 'joi';

function TissueDetails({
  bsdDonor,
  setBsdDonor,
  hospitalId,
  selectedTissues,
  handleRecipientTissueHospitalChange,
  handleDonorTissueSpecialistChange,
  handleDonorTissueDetailsForOtherStateRecipientHospitalNameChange,
  handleDonorTissueDetailsForOtherStateRetrievingSurgeonNameChange,
  handleDonorTissueDetailsForOtherStateRetrievingSurgeonIMAIdChange,
  handleDonorTissueDetailsForOtherStateRetrievingAnaesthetistNameChange,
  handleDonorTissueDetailsChange,
  _setDonorCorneaOtherSupportiveSpecialists,
  _setDonorSkinOtherSupportiveSpecialists,
  _setDonorBonesOtherSupportiveSpecialists,
  _setDonorHeartValvesOtherSupportiveSpecialists,
  _setDonorBloodVesselsOtherSupportiveSpecialists,
  handleDonorTissueDetailsForOtherStateRetrievingAnaesthetistIMAIdChange,
  handleDonorTissueDetailsForHospitalNameChange,
  handleDonorTissueDetailsForPreservedOrDiscardedChange,
  handleDonorTissueDetailsForViableNonViableChange,
  userId,
  userType,
  _isDisabled,
  validation,
  onValidation,
}) {
  const _renderTissueDetailsCard = (tissue) => {
    let donorTissue;
    let setOtherSupportiveSpecialistFunction;
    let handleSpecialist;
    switch (tissue) {
      case TISSUES.CORNEA:
        donorTissue = bsdDonor.donorTissues?.filter((t) => t.tissueType === TISSUES.CORNEA)[0];
        handleSpecialist = handleDonorTissueSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorCorneaOtherSupportiveSpecialists;
        break;

      case TISSUES.SKIN:
        donorTissue = bsdDonor.donorTissues?.filter((t) => t.tissueType === TISSUES.SKIN)[0];
        handleSpecialist = handleDonorTissueSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorSkinOtherSupportiveSpecialists;
        break;

      case TISSUES.BONES:
        donorTissue = bsdDonor.donorTissues?.filter((t) => t.tissueType === TISSUES.BONES)[0];
        handleSpecialist = handleDonorTissueSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorBonesOtherSupportiveSpecialists;
        break;

      case TISSUES.HEARTVALVES:
        donorTissue = bsdDonor.donorTissues?.filter((t) => t.tissueType === TISSUES.HEARTVALVES)[0];
        handleSpecialist = handleDonorTissueSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorHeartValvesOtherSupportiveSpecialists;
        break;

      case TISSUES.BLOODVESSELS:
        donorTissue = bsdDonor.donorTissues?.filter(
          (t) => t.tissueType === TISSUES.BLOODVESSELS
        )[0];
        handleSpecialist = handleDonorTissueSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorBloodVesselsOtherSupportiveSpecialists;
        break;

      default:
        return '';
    }

    return (
      <Grid
        container
        spacing={2}
        style={{ border: '1px solid #B1E7D3', padding: 20, marginTop: 10, marginBottom: 10 }}
      >
        <Grid item sm={10}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {tissue}
          </Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography>VIABLE/ NON-VIABLE</Typography>
          <ValidatedSelect
            id="viableNonviable"
            name="viableNonviable"
            value={donorTissue?.viableNonviable}
            options={[
              {
                label: 'Viable',
                value: 'VIABLE',
              },
              {
                label: 'Non-Viable',
                value: 'NONVIABLE',
              },
            ]}
            onChange={(v) => handleDonorTissueDetailsForViableNonViableChange(tissue, v.value)}
            disabled={_isDisabled()}
          />
        </Grid>
        {donorTissue?.viableNonviable === 'VIABLE' ? (
          <>
            <Grid item sm={3}>
              <Typography>Recipient Hospital</Typography>
              <ValidatedSelect
                id="recipientHospital"
                name="recipientHospital"
                value={donorTissue?.recipientHospital}
                options={Object.values(RECIPIENT_HOSPITAL)}
                onChange={(v) =>
                  handleRecipientTissueHospitalChange('recipientHospital', v.value, tissue)
                }
                disabled={_isDisabled()}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {donorTissue?.recipientHospital === 'OTHER' ? (
              <Grid item sm={3}>
                <Typography>Recipient Hospital Name</Typography>
                <ValidatedHospitalInput
                  id="recipientHospitalName"
                  name="recipientHospitalName"
                  hospitalDetails={donorTissue?.hospitalDetails}
                  hospital={donorTissue?.hospital}
                  formStatus={bsdDonor.formStatus}
                  onChange={(hospital) => handleDonorTissueDetailsChange(tissue, hospital)}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>
            ) : null}
            {donorTissue?.recipientHospital === 'OUT_OF_MAHARASHTRA' ? (
              <Grid item sm={3}>
                <Typography>Recipient Hospital Name</Typography>
                <ValidatedInput
                  id="otherStateRecipientHospitalName"
                  name="otherStateRecipientHospitalName"
                  value={donorTissue?.otherStateRecipientHospitalName}
                  onChange={(e) =>
                    handleDonorTissueDetailsForOtherStateRecipientHospitalNameChange(
                      tissue,
                      e.target.value
                    )
                  }
                  placeholder="Other State Recipient Hospital Name"
                  disabled={_isDisabled()}
                  // validation={validation}
                  // onValidation={onValidation}
                  // schema={Joi.string()
                  //   .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                  //   .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })
                  //   }
                />
              </Grid>
            ) : null}
            {donorTissue?.recipientHospital === 'OTHER' ? (
              <Grid item sm={3}>
                <Typography>Retrieving Surgeon</Typography>
                <OtherHospitalValidatedSpecialistInput
                  id="donor_retrievingSurgeon"
                  name="retrievingSurgeon"
                  specialistUser={donorTissue?.retrievingSurgeonUser}
                  specialistType="SURGEON"
                  formStatus={bsdDonor.formStatus}
                  specialist={donorTissue?.retrievingSurgeonId}
                  onChange={(specialist) => handleSpecialist('SURGEON', specialist, tissue)}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>
            ) : null}
            {donorTissue?.recipientHospital === 'INHOUSE' ? (
              <Grid item sm={3}>
                <Typography>Retrieving Surgeon</Typography>
                <OtherHospitalValidatedSpecialistInput
                  id="donor_retrievingSurgeon"
                  name="retrievingSurgeon"
                  specialistUser={donorTissue?.retrievingSurgeonUser}
                  formStatus={bsdDonor.formStatus}
                  specialistType="SURGEON"
                  specialist={donorTissue?.retrievingSurgeonId}
                  onChange={(specialist) => handleSpecialist('SURGEON', specialist, tissue)}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>
            ) : null}
            {donorTissue?.recipientHospital === 'OUT_OF_MAHARASHTRA' ? (
              <>
                <Grid item sm={3}>
                  <Typography>Retrieving Surgeon Name</Typography>
                  <ValidatedInput
                    id="otherStateSurgeonName"
                    name="otherStateSurgeonName"
                    value={donorTissue?.otherStateSurgeonName}
                    onChange={(e) =>
                      handleDonorTissueDetailsForOtherStateRetrievingSurgeonNameChange(
                        tissue,
                        e.target.value
                      )
                    }
                    placeholder="Retrieving Surgeon Name"
                    disabled={_isDisabled()}
                    // validation={validation}
                    // onValidation={onValidation}
                    // schema={Joi.string()
                    //   .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                    //   .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })
                    //   .pattern(REGEXP_ONLYCHARS)
                    //   .message({ 'string.pattern.base': 'Accept only alphabet characters' })}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Typography>Retrieving Surgeon IMA Id</Typography>
                  <ValidatedInput
                    id="otherStateSurgeonIMAId"
                    name="otherStateSurgeonIMAId"
                    value={donorTissue?.otherStateSurgeonIMAId}
                    onChange={(e) =>
                      handleDonorTissueDetailsForOtherStateRetrievingSurgeonIMAIdChange(
                        tissue,
                        e.target.value
                      )
                    }
                    placeholder="Retrieving Surgeon IMA ID"
                    disabled={_isDisabled()}
                    // validation={validation}
                    // onValidation={onValidation}
                  />
                </Grid>
              </>
            ) : null}
            {donorTissue?.recipientHospital === 'OTHER' ? (
              <Grid item sm={3}>
                <Typography>Retrieving Anaesthetist</Typography>
                <OtherHospitalValidatedSpecialistInput
                  id="donor_retrievingAnaesthetist"
                  name="retrievingAnaesthetist"
                  specialistUser={donorTissue?.retrievingAnaesthetistUser}
                  specialistType="ANAESTHETIST"
                  formStatus={bsdDonor.formStatus}
                  specialist={donorTissue?.retrievingAnaesthetistId}
                  onChange={(specialist) => handleSpecialist('ANAESTHETIST', specialist, tissue)}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>
            ) : null}
            {donorTissue?.recipientHospital === 'INHOUSE' ? (
              <Grid item sm={3}>
                <Typography>Retrieving Anaesthetist</Typography>
                <OtherHospitalValidatedSpecialistInput
                  id="donor_retrievingAnaesthetist"
                  name="retrievingAnaesthetist"
                  specialistUser={donorTissue?.retrievingAnaesthetistUser}
                  specialistType="ANAESTHETIST"
                  formStatus={bsdDonor.formStatus}
                  specialist={donorTissue?.retrievingAnaesthetistId}
                  onChange={(specialist) => handleSpecialist('ANAESTHETIST', specialist, tissue)}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>
            ) : null}
            {donorTissue?.recipientHospital === 'OUT_OF_MAHARASHTRA' ? (
              <>
                <Grid item sm={3}>
                  <Typography>Retrieving Anaesthetist Name</Typography>
                  <ValidatedInput
                    id="otherStateAnaesthetistName"
                    name="otherStateAnaesthetistName"
                    value={donorTissue?.otherStateAnaesthetistName}
                    onChange={(e) =>
                      handleDonorTissueDetailsForOtherStateRetrievingAnaesthetistNameChange(
                        tissue,
                        e.target.value
                      )
                    }
                    placeholder="Retrieving Anaesthetist Name"
                    disabled={_isDisabled()}
                    // validation={validation}
                    // onValidation={onValidation}
                    // schema={Joi.string()
                    //   .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                    //   .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })
                    //   .pattern(REGEXP_ONLYCHARS)
                    //   .message({ 'string.pattern.base': 'Accept only alphabet characters' })}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Typography>Retrieving Anaesthetist IMA Id</Typography>
                  <ValidatedInput
                    id="otherStateAnaesthetistIMAId"
                    name="otherStateAnaesthetistIMAId"
                    value={donorTissue?.otherStateAnaesthetistIMAId}
                    onChange={(e) =>
                      handleDonorTissueDetailsForOtherStateRetrievingAnaesthetistIMAIdChange(
                        tissue,
                        e.target.value
                      )
                    }
                    placeholder="Retrieving Anaesthetist IMA ID"
                    disabled={_isDisabled()}
                    // validation={validation}
                    // onValidation={onValidation}
                  />
                </Grid>
              </>
            ) : null}
            {donorTissue?.recipientHospital === 'OTHER' ? (
              <Grid item sm={12}>
                <OtherHospitalValidatedOtherSupportiveSpecialists
                  currentSelectedOtherSupportiveSpecialists={
                    donorTissue?.otherSupportiveSpecialists || []
                  }
                  allSelectedOtherSupportiveSpecialists={
                    donorTissue?.otherSupportiveSpecialists || []
                  }
                  userType={userType}
                  tissue={tissue.tissueType}
                  setOtherSupportiveSpecialists={setOtherSupportiveSpecialistFunction}
                  disabled={_isDisabled()}
                  editMode={false}
                  userId={userId}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>
            ) : (
              <Grid item sm={12}>
                <ValidatedOtherSupportiveSpecialists
                  currentSelectedOtherSupportiveSpecialists={
                    donorTissue?.otherSupportiveSpecialists || []
                  }
                  allSelectedOtherSupportiveSpecialists={
                    donorTissue?.otherSupportiveSpecialists || []
                  }
                  userType={userType}
                  tissue={tissue.tissueType}
                  setOtherSupportiveSpecialists={setOtherSupportiveSpecialistFunction}
                  disabled={_isDisabled()}
                  editMode={false}
                  userId={userId}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>
            )}
          </>
        ) : null}
        {donorTissue?.viableNonviable === 'NONVIABLE' ? (
          <>
            <Grid item sm={3}>
              <Typography>PRESERVED/ DISCARDED</Typography>
              <ValidatedSelect
                id="preservedOrDiscarded"
                name="preservedOrDiscarded"
                value={donorTissue?.preservedOrDiscarded}
                options={[
                  {
                    label: 'Preserved',
                    value: 'PRESERVED',
                  },
                  {
                    label: 'Discarded',
                    value: 'DISCARDED',
                  },
                ]}
                onChange={(v) =>
                  handleDonorTissueDetailsForPreservedOrDiscardedChange(tissue, v.value)
                }
                disabled={_isDisabled()}
              />
            </Grid>
            {donorTissue?.preservedOrDiscarded === 'PRESERVED' ? (
              <Grid item sm={3}>
                <Typography>Hospital Name</Typography>
                <ValidatedInput
                  id="preservedHospitalName"
                  name="preservedHospitalName"
                  value={donorTissue?.preservedHospitalName}
                  onChange={(e) =>
                    handleDonorTissueDetailsForHospitalNameChange(tissue, e.target.value)
                  }
                  disabled={_isDisabled()}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>
            ) : null}
          </>
        ) : null}
      </Grid>
    );
  };

  return (
    <Grid item sm={12} style={{ padding: 20 }}>
      {selectedTissues.map((element) => _renderTissueDetailsCard(element))}
    </Grid>
  );
}

export default TissueDetails;
