import { Auth } from 'aws-amplify';
// import MemoryStorage from './authStorage';

const awsExports = {
  Auth: {
    identityPoolId: 'ap-south-1:dba512a1-b6b0-4f33-b368-010441b29079',
    region: 'ap-south-1',
    userPoolId: 'ap-south-1_YyhbmW87N',
    userPoolWebClientId: '2lfecmoct5o2lcmjnmk12sme8t',
    // storage: MemoryStorage,
  },
  Storage: {
    AWSS3: {
      bucket: 'ayudaan-uploads-prod',
      region: 'ap-south-1',
    },
  },
  API: {
    endpoints: [
      {
        name: 'AyudaanExpress',
        endpoint: 'https://api.mahaayudaan.in',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
      {
        name: 'AyudaanCore',
        endpoint: 'https://apicore.mahaayudaan.in',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
      {
        name: 'AdnOrgan',
        endpoint: 'https://apiorgan.mahaayudaan.in',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
      {
        name: 'AdnEyeBank',
        endpoint: 'https://apieyebank.mahaayudaan.in',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
      {
        name: 'AdnTissue',
        endpoint: 'https://apitissue.mahaayudaan.in',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
      {
        name: 'AdnResources',
        endpoint: 'https://apiresource.mahaayudaan.in',
        custom_header: async () => {
          try {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
          } catch (err) {
            // IGNORE
          }
          return {};
        },
      },
    ],
  },
};

export default awsExports;
