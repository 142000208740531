import React from 'react';
import Joi from 'joi';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  ValidatedAttachment,
  ValidatedDate,
  ValidatedInput,
  ValidatedRadio,
  ValidatedSelect,
} from '../../Custom';
import { FILETYPES } from '../../Custom/ValidatedAttachment';

function MedicalReportsBasicInfo({
  donorMedicalReportsDetails,
  setDonorMedicalReportsDetails,
  donorPages,
  setDonorPages,
  validate,
  validation,
  onValidation,
}) {
  // const app = useSelector((state) => state);

  // const useStyles = makeStyles(() => ({
  //   paperTransplantInfo: {
  //     background: '#F0FEF9',
  //     border: '1px solid #B1E7D3',
  //     borderRadius: '4px',
  //     marginTop: 20,
  //     marginBottom: 10,
  //   },
  //   gridTransplantDetails: {
  //     paddingLeft: 24,
  //     paddingTop: 30,
  //     paddingRight: 24,
  //     paddingBottom: 20,
  //   },
  //   paperTransplantSections: {
  //     height: '100%',
  //     marginTop: 5,
  //   },
  //   transplantSectionsStyle: {
  //     fontWeight: 300,
  //     lineHeight: '15px',
  //     marginTop: 20,
  //     marginLeft: 20,
  //   },
  //   transplantSectionsHeadingStyle: {
  //     fontWeight: 600,
  //     fontSize: '14px',
  //     marginLeft: 20,
  //   },
  //   transplantDetailsHeading: {
  //     fontWeight: 600,
  //     fontSize: '16px',
  //     color: '#34495E',
  //     marginTop: '5px',
  //   },
  //   form12BoxStyle: {
  //     direction: 'row',
  //     // marginTop: 90,
  //     // ...(_isDisabled() ? { marginTop: 120 } : {}),
  //   },
  // }));

  // const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Chest Circumference (in cm)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.basicMedicalInformation?.chestCircumCm}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  chestCircumCm: e.target.value,
                  chestCircumIn: (e.target.value * 0.39).toFixed(2),
                },
              });
            }}
            schema={Joi.string()}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Chest Circumference (in inch)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.basicMedicalInformation?.chestCircumIn}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  chestCircumIn: e.target.value,
                  chestCircumCm: (e.target.value * 2.54).toFixed(2),
                },
              });
            }}
            schema={Joi.string()}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Abdomen Circumference (in cm)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.basicMedicalInformation?.abdomenCircumCm}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  abdomenCircumCm: e.target.value,
                  abdomenCircumIn: (e.target.value * 0.39).toFixed(2),
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Abdomen Circumference (in inch)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.basicMedicalInformation?.abdomenCircumIn}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  abdomenCircumIn: e.target.value,
                  abdomenCircumCm: (e.target.value * 2.54).toFixed(2),
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Cause Of Death</Typography>
          <ValidatedSelect
            required
            label=""
            value={donorMedicalReportsDetails?.basicMedicalInformation?.causeOfDeath}
            options={[
              {
                label: 'Head injury - EDH',
                value: 'HEAD_INJURY_EDH',
              },
              {
                label: 'Head injury - Subdural',
                value: 'HEAD_INJURY_SUBDURAL',
              },
              {
                label: 'Head injury - intraparenchymal',
                value: 'HEAD_INJURY_INTRAPARENCHYMAL',
              },

              // {
              //   label: 'Brain Haemorrhage',
              //   value: 'BRAIN_HAEMORRHAGE',
              // },
              {
                label: 'Infections',
                value: 'INFECTIONS',
              },
              {
                label: 'Brain tumor',
                value: 'BRAIN_TUMOR',
              },
              {
                label: 'CVA - IC Bleed',
                value: 'CVA_ICBLEED',
              },
              {
                label: 'CVA - Ischemic Stroke',
                value: 'CVA_ISCHEMIC_STROKE',
              },
              {
                label: 'Hypoxia / anoxic Brain injury',
                value: 'HYPOXIAANOXIC',
              },
              {
                label: 'Meningitis - Bacterial Pyogenic',
                value: 'BACTERIAL_PYOGENIC',
              },
              {
                label: 'Meningitis - Bacterial Stroke TB',
                value: 'BACTERIAL_STROKE_TB',
              },
              {
                label: 'Meningitis - Viral',
                value: 'MENINGITIS_VIRAL',
              },
              {
                label: 'Encephalitis - Unknown',
                value: 'ENCEPHALITIS_UNKNOWN',
              },
              {
                label: 'Encephalitis - Origin',
                value: 'ENCEPHALITIS_ORIGIN',
              },
              {
                label: 'Other',
                value: 'OTHER',
              },
            ]}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  causeOfDeath: v.value,
                },
              });
            }}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        {donorMedicalReportsDetails?.basicMedicalInformation?.causeOfDeath === 'OTHER' ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Details</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.basicMedicalInformation?.causeOfDeathDetails}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    causeOfDeathDetails: e.target.value,
                  },
                });
              }}
              schema={Joi.string()}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
        ) : null}
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">First Apnea Date and Time</Typography>
          <ValidatedDate
            required
            fullWidth
            value={donorMedicalReportsDetails?.basicMedicalInformation?.firstApneaTime}
            label=""
            type="datetime-local"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  firstApneaTime: e,
                },
              });
            }}
            schema={Joi.date().max('now')}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedAttachment
            required
            label="First Apnea baseline Report"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.basicMedicalInformation?.firstApneaBaselineReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  firstApneaBaselineReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedAttachment
            required
            label="First Apnea after 10 minutes Report"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={
              donorMedicalReportsDetails?.basicMedicalInformation?.firstApneaAfterTenMinutesReport
            }
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  firstApneaAfterTenMinutesReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Second Apnea Date and Time</Typography>
          <ValidatedDate
            required
            fullWidth
            value={donorMedicalReportsDetails?.basicMedicalInformation?.secondApneaTime}
            label=""
            type="datetime-local"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  secondApneaTime: e,
                },
              });
            }}
            schema={Joi.date().max('now')}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedAttachment
            required
            label="Second Apnea baseline Report"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.basicMedicalInformation?.secondApneaBaselineReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  secondApneaBaselineReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedAttachment
            required
            label="Second Apnea after 10 minutes Report"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={
              donorMedicalReportsDetails?.basicMedicalInformation?.secondApneaAfterTenMinutesReport
            }
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  secondApneaAfterTenMinutesReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Consent Obtained from Family / Relatives"
            name="consentObtained"
            value={donorMedicalReportsDetails?.basicMedicalInformation?.consentObtained}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  consentObtained: e.target.value === 'true',
                  consentReason: ''
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        {donorMedicalReportsDetails?.basicMedicalInformation?.consentObtained === false ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Reason</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.basicMedicalInformation?.consentReason}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    consentReason: e.target.value,
                  },
                });
              }}
            />
          </Grid>
        ) : null}

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Date of Onset of Illness</Typography>
          <ValidatedDate
            required
            fullWidth
            label=""
            value={donorMedicalReportsDetails?.basicMedicalInformation?.dateOfOnsetOfIllness}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  dateOfOnsetOfIllness: v,
                },
              });
            }}
          />
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
          <Grid item sm={4} xs={12}>
            <ValidatedRadio
              label="Abdominal / Chest Injuries"
              name="abdominalChestInjuries"
              value={donorMedicalReportsDetails?.basicMedicalInformation?.abdominalChestInjuries}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    abdominalChestInjuries: e.target.value === 'true',
                    abdominalChestInjuriesDetails: '',
                    abdominalOrChestInjuryReport: {
                      key: '',
                      name: '',
                      url: '',
                    },
                  },
                });
              }}
              options={[
                { value: true, label: 'YES' },
                { value: false, label: 'NO' },
              ]}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {donorMedicalReportsDetails?.basicMedicalInformation?.abdominalChestInjuries ? (
            <>
              <Grid item sm={4} xs={12}>
                <Typography variant="body1">Abdominal or Chest Injuries Details</Typography>
                <ValidatedInput
                  required
                  fullWidth
                  value={
                    donorMedicalReportsDetails?.basicMedicalInformation
                      ?.abdominalChestInjuriesDetails
                  }
                  label=""
                  type="test"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      basicMedicalInformation: {
                        ...donorMedicalReportsDetails.basicMedicalInformation,
                        abdominalChestInjuriesDetails: e.target.value,
                      },
                    });
                  }}
                  schema={Joi.string()}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                {/* <Typography variant="body1">
                  Abdominal or Chest Injuries Report Attachment
                </Typography> */}
                <ValidatedAttachment
                  // required
                  label="Abdominal or Chest Injuries Report"
                  acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                  value={
                    donorMedicalReportsDetails?.basicMedicalInformation
                      ?.abdominalOrChestInjuryReport
                  }
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      basicMedicalInformation: {
                        ...donorMedicalReportsDetails.basicMedicalInformation,
                        abdominalOrChestInjuryReport: {
                          file,
                          name: file.name,
                        },
                      },
                    });
                  }}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Operation on Donor"
            name="operationOnDonor"
            value={donorMedicalReportsDetails?.basicMedicalInformation?.operationOnDonor}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  operationOnDonor: e.target.value === 'true',
                  operationOnDonorDetails: '',
                  operationOnDonorReport: {
                    key: '',
                    name: '',
                    url: '',
                  },
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        {donorMedicalReportsDetails?.basicMedicalInformation?.operationOnDonor ? (
          <>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Operation on Donor (Nature) Details</Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorMedicalReportsDetails?.basicMedicalInformation?.operationOnDonorDetails}
                label=""
                type="test"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      operationOnDonorDetails: e.target.value,
                    },
                  });
                }}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {/* <Grid item sm={4} xs={12}>
              <Typography variant="body1">Date of Hospitalization/Admission</Typography>
              <ValidatedDate
                required
                fullWidth
                label=""
                value={donorMedicalReportsDetails?.basicMedicalInformation?.dateOfHospitalization}
                onChange={(v) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      dateOfHospitalization: v,
                    },
                  });
                }}
                validation={validation}
                onValidation={onValidation}
              />
            </Grid> */}
            <Grid item sm={12}>
              {/* <Typography variant="body1">Operation On Donor Report Attachment</Typography> */}
              <ValidatedAttachment
                // required
                label="Operation On Donor Report"
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                value={donorMedicalReportsDetails?.basicMedicalInformation?.operationOnDonorReport}
                onChange={(e) => {
                  const file = e.target.files[0];
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      operationOnDonorReport: {
                        file,
                        name: file.name,
                      },
                    },
                  });
                }}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
          </>
        ) : null}
        <Grid item sm={12} style={{ padding: 10 }}>
          <ValidatedRadio
            label="Is Covid Vaccination Done?"
            name="controlled-radio-buttons-group"
            value={donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.dosesDone}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  covidVaccination: {
                    ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination,
                    dosesDone: e.target.value,
                  },
                },
              });
              // setAllocRcptDetails({
              //   ...allocRcptDetails,
              //   covidVaccination: {
              //     ...covidVaccination,
              //     dosesDone: e.target.value,
              //   },
              // });
            }}
            options={[
              { value: 'FIRST', label: 'First Dose' },
              {
                value: 'SECOND',

                label: 'Both (First and Second Dose)',
              },
              { value: 'BOOSTER', label: 'Booster Dose' },
              { value: 'NOTDONE', label: 'Not Done' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        {donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.dosesDone ===
        'FIRST' ? (
          <Grid container spacing={3} style={{ margin: 20, padding: 10 }}>
            <Grid item sm={4}>
              <ValidatedDate
                required
                label="Date of First Dose (DD/MM/YYYY)"
                value={
                  donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.firstDose
                    ?.firstDoseDate
                }
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      covidVaccination: {
                        ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination,
                        firstDose: {
                          ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                            .firstDose,
                          firstDoseDate: e,
                        },
                      },
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="First Dose Certificate"
                name="First Dose Certificate"
                label="First Dose Certificate"
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                value={
                  donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.firstDose
                    ?.firstDoseAttachment
                }
                onChange={(e) => {
                  const file = e.target.files[0];
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      covidVaccination: {
                        ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination,
                        firstDose: {
                          ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                            .firstDose,
                          firstDoseAttachment: {
                            ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                              .firstDoseAttachment,
                            file,
                            name: file.name,
                          },
                        },
                      },
                    },
                  });
                }}
                schema={Joi.string()}
              />
            </Grid>
          </Grid>
        ) : null}
        {donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.dosesDone ===
        'SECOND' ? (
          <Grid container spacing={3} style={{ margin: 20, padding: 10 }}>
            <Grid item sm={4}>
              <ValidatedDate
                required
                label="Date of First Dose (DD/MM/YYYY)"
                value={
                  donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.bothDose
                    ?.firstDoseDate
                }
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      covidVaccination: {
                        ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination,
                        bothDose: {
                          ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                            .bothDose,
                          firstDoseDate: e,
                        },
                      },
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedDate
                required
                label="Date of Second Dose (DD/MM/YYYY)"
                value={
                  donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.bothDose
                    ?.secondDoseDate
                }
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      covidVaccination: {
                        ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination,
                        bothDose: {
                          ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                            .bothDose,
                          secondDoseDate: e,
                        },
                      },
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Fully Vaccinated Certificate"
                name="Fully Vaccinated Certificate"
                label="Fully Vaccinated Certificate"
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                value={
                  donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.bothDose
                    ?.bothDoseAttachment
                }
                onChange={(e) => {
                  const file = e.target.files[0];
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      covidVaccination: {
                        ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination,
                        bothDose: {
                          ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                            .bothDose,
                          bothDoseAttachment: {
                            ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                              .bothDoseAttachment,
                            file,
                            name: file.name,
                          },
                        },
                      },
                    },
                  });
                }}
                schema={Joi.string()}
              />
            </Grid>
          </Grid>
        ) : null}
        {donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.dosesDone ===
        'BOOSTER' ? (
          <Grid container spacing={3} style={{ margin: 20, padding: 10 }}>
            <Grid item sm={4}>
              <ValidatedDate
                required
                label="Date of First Dose (DD/MM/YYYY)"
                value={
                  donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.boosterDose
                    ?.firstDoseDate
                }
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      covidVaccination: {
                        ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination,
                        boosterDose: {
                          ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                            .boosterDose,
                          firstDoseDate: e,
                        },
                      },
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedDate
                required
                label="Date of Second Dose (DD/MM/YYYY)"
                value={
                  donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.boosterDose
                    ?.secondDoseDate
                }
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      covidVaccination: {
                        ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination,
                        boosterDose: {
                          ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                            .boosterDose,
                          secondDoseDate: e,
                        },
                      },
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedDate
                required
                label="Date of Booster Dose (DD/MM/YYYY)"
                value={
                  donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.boosterDose
                    ?.boosterDoseDate
                }
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      covidVaccination: {
                        ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination,
                        boosterDose: {
                          ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                            .boosterDose,
                          boosterDoseDate: e,
                        },
                      },
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <ValidatedAttachment
                required
                id="Fully Vaccinated Certificate"
                name="Fully Vaccinated Certificate"
                label="Fully Vaccinated Certificate"
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                value={
                  donorMedicalReportsDetails.basicMedicalInformation?.covidVaccination?.boosterDose
                    ?.doseAttachment
                }
                onChange={(e) => {
                  const file = e.target.files[0];
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      covidVaccination: {
                        ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination,
                        boosterDose: {
                          ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                            .boosterDose,
                          doseAttachment: {
                            ...donorMedicalReportsDetails.basicMedicalInformation.covidVaccination
                              .doseAttachment,
                            file,
                            name: file.name,
                          },
                        },
                      },
                    },
                  });
                }}
                schema={Joi.string()}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
      <Grid item sm={12} xs={12}>
  <ValidatedRadio
    label="Medico Legal Case"
    name="controlled-radio-buttons-group"
    value={donorMedicalReportsDetails?.basicMedicalInformation?.medicoLegalCaseYesNo}
    onChange={(e) => {
      const isMedicoLegalCase = e.target.value === 'true';
      let updatedDetails = {
        ...donorMedicalReportsDetails,
        basicMedicalInformation: {
          ...donorMedicalReportsDetails.basicMedicalInformation,
          medicoLegalCaseYesNo: isMedicoLegalCase,
          medicoLegalCase: isMedicoLegalCase ? donorMedicalReportsDetails.basicMedicalInformation?.medicoLegalCase : '',
          medicoLegalCaseNoc: isMedicoLegalCase ? donorMedicalReportsDetails.basicMedicalInformation?.medicoLegalCaseNoc : {
            name: '',
            key: '',
            url: '',
          },
          medicoLegalCaseNotRequiredReason: !isMedicoLegalCase ? '' : donorMedicalReportsDetails.basicMedicalInformation?.medicoLegalCaseNotRequiredReason,
        },
      };

      // Clear relevant fields based on the selected option
      if (!isMedicoLegalCase) {
        updatedDetails = {
          ...updatedDetails,
          basicMedicalInformation: {
            ...updatedDetails.basicMedicalInformation,
            medicoLegalCaseNoc: {
              name: '',
              key: '',
              url: '',
            },
          },
        };
      }

      setDonorMedicalReportsDetails(updatedDetails);
    }}
    options={[
      { value: true, label: 'YES' },
      { value: false, label: 'NO' },
    ]}
  />
</Grid>
{donorMedicalReportsDetails?.basicMedicalInformation?.medicoLegalCaseYesNo ? (
  <Grid item sm={12} xs={12} style={{ marginLeft: 10 }}>
    <ValidatedRadio
      label=""
      name="controlled-radio-buttons-group"
      value={donorMedicalReportsDetails?.basicMedicalInformation?.medicoLegalCase}
      onChange={(e) => {
        setDonorMedicalReportsDetails({
          ...donorMedicalReportsDetails,
          basicMedicalInformation: {
            ...donorMedicalReportsDetails.basicMedicalInformation,
            medicoLegalCase: e.target.value,
            medicoLegalCaseNoc: {
              name: '',
              key: '',
              url: '',
            },
          },
        });
      }}
      options={[
        { value: 'POLICENOCAPPROVED', label: 'Police NOC Approved' },
        { value: 'POLICENOCAWAITED', label: 'Police NOC Awaited' },
        { value: 'NOTREQUIRED', label: 'Not Required' },
      ]}
    />
    {donorMedicalReportsDetails?.basicMedicalInformation?.medicoLegalCase === 'POLICENOCAPPROVED' ? (
      <ValidatedAttachment
        id="NoC"
        name="NoC"
        label="Police NOC Attachment"
        acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
        value={donorMedicalReportsDetails?.basicMedicalInformation?.medicoLegalCaseNoc}
        onChange={(e) => {
          const file = e.target.files[0];
          setDonorMedicalReportsDetails({
            ...donorMedicalReportsDetails,
            basicMedicalInformation: {
              ...donorMedicalReportsDetails.basicMedicalInformation,
              medicoLegalCaseNoc: {
                ...donorMedicalReportsDetails.basicMedicalInformation.medicoLegalCaseNoc,
                file,
                name: file.name,
              },
            },
          });
        }}
      />
    ) : null}
  </Grid>
) : null}
{donorMedicalReportsDetails?.basicMedicalInformation?.medicoLegalCase === 'NOTREQUIRED' ? (
  <Grid container>
    <Grid item sm={4} xs={12} style={{ marginLeft: 20 }}>
      <Typography variant="body1">Reason</Typography>
      <ValidatedInput
        required
        fullWidth
        value={donorMedicalReportsDetails?.basicMedicalInformation?.medicoLegalCaseNotRequiredReason}
        label=""
        type="text"
        placeholder=""
        onChange={(e) => {
          setDonorMedicalReportsDetails({
            ...donorMedicalReportsDetails,
            basicMedicalInformation: {
              ...donorMedicalReportsDetails.basicMedicalInformation,
              medicoLegalCaseNotRequiredReason: e.target.value,
            },
          });
        }}
      />
    </Grid>
  </Grid>
) : null}

<Grid item sm={4} xs={12}>
  <ValidatedRadio
    label="Covid History"
    name="antiHcvRemark"
    value={donorMedicalReportsDetails?.basicMedicalInformation?.covidHistory}
    onChange={(e) =>
      setDonorMedicalReportsDetails({
        ...donorMedicalReportsDetails,
        basicMedicalInformation: {
          ...donorMedicalReportsDetails.basicMedicalInformation,
          covidHistory: e.target.value === 'true',
          antiHcvReport: e.target.value === 'true' ? donorMedicalReportsDetails.basicMedicalInformation?.antiHcvReport : {
            name: '',
            key: '',
            url: '',
          },
          antiHcvReportDate: e.target.value === 'true' ? donorMedicalReportsDetails.basicMedicalInformation?.antiHcvReportDate : null,
          covidPositiveDate: e.target.value === 'true' ? donorMedicalReportsDetails.basicMedicalInformation?.covidPositiveDate : null,
          covidCureDate: e.target.value === 'true' ? donorMedicalReportsDetails.basicMedicalInformation?.covidCureDate : null,
          covidSymptoms: e.target.value === 'true' ? donorMedicalReportsDetails.basicMedicalInformation?.covidSymptoms : '',
        },
      })
    }
    options={[
      { value: true, label: 'YES' },
      { value: false, label: 'NO' },
    ]}
  />
</Grid>
{donorMedicalReportsDetails?.basicMedicalInformation?.covidHistory ? (
  <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
    <Grid item sm={4} xs={12}>
      <Typography variant="body1">Last Covid Positive Date</Typography>
      <ValidatedDate
        required
        fullWidth
        label=""
        value={donorMedicalReportsDetails?.basicMedicalInformation?.covidPositiveDate}
        onChange={(v) => {
          setDonorMedicalReportsDetails({
            ...donorMedicalReportsDetails,
            basicMedicalInformation: {
              ...donorMedicalReportsDetails.basicMedicalInformation,
              covidPositiveDate: v,
            },
          });
        }}
      />
    </Grid>
    <Grid item sm={4} xs={12}>
      <Typography variant="body1">Last Covid Cure Date</Typography>
      <ValidatedDate
        required
        fullWidth
        label=""
        value={donorMedicalReportsDetails?.basicMedicalInformation?.covidCureDate}
        onChange={(v) => {
          setDonorMedicalReportsDetails({
            ...donorMedicalReportsDetails,
            basicMedicalInformation: {
              ...donorMedicalReportsDetails.basicMedicalInformation,
              covidCureDate: v,
            },
          });
        }}
      />
    </Grid>
    <Grid item sm={4} xs={12}>
      <Typography variant="body1">Last Covid Clinical Symptoms</Typography>
      <ValidatedInput
        required
        fullWidth
        label=""
        type="text"
        value={donorMedicalReportsDetails?.basicMedicalInformation?.covidSymptoms}
        onChange={(v) => {
          setDonorMedicalReportsDetails({
            ...donorMedicalReportsDetails,
            basicMedicalInformation: {
              ...donorMedicalReportsDetails.basicMedicalInformation,
              covidSymptoms: v.target.value,
            },
          });
        }}
      />
    </Grid>
  </Grid>
) : null}

        <Grid item sm={6} xs={12}>
          {/* <Typography variant="body1">Latest RTPCR Report</Typography> */}
          <ValidatedAttachment
            // required
            label="Latest RTPCR Report"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.basicMedicalInformation?.latestRtpct}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  latestRtpct: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          {/* <Typography variant="body1">Latest Medications Information </Typography> */}
          <ValidatedAttachment
            // required
            label="Latest Medications Information"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.basicMedicalInformation?.anyOtherMedications}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  anyOtherMedications: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Any Episode of Cardiac Arrest"
            name="controlled-radio-buttons-group"
            value={donorMedicalReportsDetails?.basicMedicalInformation?.episodeOfCardiacArrest}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                basicMedicalInformation: {
                  ...donorMedicalReportsDetails.basicMedicalInformation,
                  episodeOfCardiacArrest: e.target.value === 'true',
                  durationOfCardiacArrest: '',
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        {donorMedicalReportsDetails?.basicMedicalInformation?.episodeOfCardiacArrest ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">How many times Cardiac Arrest occurred?</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.basicMedicalInformation?.durationOfCardiacArrest}
              label=""
              type="number"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    durationOfCardiacArrest: e.target.value,
                  },
                });
              }}
            />
          </Grid>
        ) : null}
        <Grid container style={{ padding: 10 }}>
          <Grid item sm={4} xs={12}>
            <ValidatedRadio
              label="H/O Alcoholism"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.basicMedicalInformation?.alcoholism}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    alcoholism: e.target.value === 'true',
                    alcoholismHistory: ''
                    // alcoholismReport: {
                    //   key: '',
                    //   name: '',
                    //   url: '',
                    // },
                  },
                });
              }}
              options={[
                { value: true, label: 'YES' },
                { value: false, label: 'NO' },
              ]}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>

          {donorMedicalReportsDetails?.basicMedicalInformation?.alcoholism ? (
            <Grid item sm={4} xs={12} style={{ marginLeft: 10 }}>
              <ValidatedRadio
                label="Alcoholism History"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.basicMedicalInformation?.alcoholismHistory}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      alcoholismHistory: e.target.value,
                    },
                  });
                }}
                options={[
                  { value: 'REGULAR', label: 'Regular' },
                  { value: 'OCCASIONAL', label: 'Occasional' },
                ]}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
          ) : null}
        </Grid>

        <Grid container style={{ padding: 10 }}>
          <Grid item sm={4} xs={12}>
            <ValidatedRadio
              label="History of Smoking"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.basicMedicalInformation?.smokingHistory}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    smokingHistory: e.target.value,
                    smokingInfo: ''
                  },
                });
              }}
              options={[
                { value: 'YES', label: 'Yes' },
                { value: 'NO', label: 'No' },
                { value: 'DONTKNOW', label: "Don't Know" },
              ]}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {donorMedicalReportsDetails?.basicMedicalInformation?.smokingHistory === 'YES' ? (
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Further Info if any (Not Mandatory)</Typography>
              <ValidatedInput
                fullWidth
                value={donorMedicalReportsDetails?.basicMedicalInformation?.smokingInfo}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      smokingInfo: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid container style={{ padding: 10 }}>
          <Grid item sm={4} xs={12}>
            <ValidatedRadio
              label="Diabetes"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.basicMedicalInformation?.diabetics}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    diabetics: e.target.value,
                    diabeticsInfo:''
                  },
                });
              }}
              options={[
                { value: 'YES', label: 'Yes' },
                { value: 'NO', label: 'No' },
                { value: 'DONTKNOW', label: "Don't Know" },
              ]}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {donorMedicalReportsDetails?.basicMedicalInformation?.diabetics === 'YES' ? (
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Further Info if any (Not Mandatory)</Typography>
              <ValidatedInput
                fullWidth
                value={donorMedicalReportsDetails?.basicMedicalInformation?.diabeticsInfo}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      diabeticsInfo: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
          ) : null}
        </Grid>
        {/* {donorMedicalReportsDetails?.basicMedicalInformation?.diabetics ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Diabetes Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.basicMedicalInformation?.diabeticReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    diabeticReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null} */}
        <Grid container style={{ padding: 10 }}>
          <Grid item sm={4} xs={12}>
            <ValidatedRadio
              label="Hypertension"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.basicMedicalInformation?.htn}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    htn: e.target.value,
                    htnInfo: ''
                  },
                });
              }}
              options={[
                { value: 'YES', label: 'Yes' },
                { value: 'NO', label: 'No' },
                { value: 'DONTKNOW', label: "Don't Know" },
              ]}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {donorMedicalReportsDetails?.basicMedicalInformation?.htn === 'YES' ? (
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Further Info if any (Not Mandatory)</Typography>
              <ValidatedInput
                fullWidth
                value={donorMedicalReportsDetails?.basicMedicalInformation?.htnInfo}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      htnInfo: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
          ) : null}
        </Grid>

        {/* {donorMedicalReportsDetails?.basicMedicalInformation?.htn ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Hypertension Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.basicMedicalInformation?.htnReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    htnReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null} */}
        <Grid container style={{ padding: 10 }}>
          <Grid item sm={4} xs={12}>
            <ValidatedRadio
              label="Ischemic Heart Disease"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.basicMedicalInformation?.ihd}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    ihd: e.target.value,
                    ihdInfo:''
                  },
                });
              }}
              options={[
                { value: 'YES', label: 'Yes' },
                { value: 'NO', label: 'No' },
                { value: 'DONTKNOW', label: "Don't Know" },
              ]}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {donorMedicalReportsDetails?.basicMedicalInformation?.ihd === 'YES' ? (
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Further Info if any (Not Mandatory)</Typography>
              <ValidatedInput
                fullWidth
                value={donorMedicalReportsDetails?.basicMedicalInformation?.ihdInfo}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      ihdInfo: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
          ) : null}
        </Grid>

        {/* {donorMedicalReportsDetails?.basicMedicalInformation?.ihd ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Ischemic Heart Disease Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.basicMedicalInformation?.ihdReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    ihdReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null} */}
        <Grid container style={{ padding: 10 }}>
          <Grid item sm={4} xs={12}>
            <ValidatedRadio
              label="Asthma"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.basicMedicalInformation?.asthma}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    asthma: e.target.value,
                    asthmaInfo:'',
                  },
                });
              }}
              options={[
                { value: 'YES', label: 'Yes' },
                { value: 'NO', label: 'No' },
                { value: 'DONTKNOW', label: "Don't Know" },
              ]}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {donorMedicalReportsDetails?.basicMedicalInformation?.asthma === 'YES' ? (
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Further Info if any (Not Mandatory)</Typography>
              <ValidatedInput
                fullWidth
                value={donorMedicalReportsDetails?.basicMedicalInformation?.asthmaInfo}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      asthmaInfo: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
          ) : null}
        </Grid>

        {/* {donorMedicalReportsDetails?.basicMedicalInformation?.asthma ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Asthma Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.basicMedicalInformation?.asthmaReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    asthmaReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null} */}
        <Grid container style={{ padding: 10 }}>
          <Grid item sm={4} xs={12}>
            <ValidatedRadio
              label="Lung Disease"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.basicMedicalInformation?.lungDisease}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    lungDisease: e.target.value,
                    lungDiseaseInfo:''
                  },
                });
              }}
              options={[
                { value: 'YES', label: 'Yes' },
                { value: 'NO', label: 'No' },
                { value: 'DONTKNOW', label: "Don't Know" },
              ]}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {donorMedicalReportsDetails?.basicMedicalInformation?.lungDisease === 'YES' ? (
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Further Info if any (Not Mandatory)</Typography>
              <ValidatedInput
                fullWidth
                value={donorMedicalReportsDetails?.basicMedicalInformation?.lungDiseaseInfo}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    basicMedicalInformation: {
                      ...donorMedicalReportsDetails.basicMedicalInformation,
                      lungDiseaseInfo: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
          ) : null}
        </Grid>

        {/* {donorMedicalReportsDetails?.basicMedicalInformation?.lungDisease ? (
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Lung Disease Report</Typography>
            <ValidatedAttachment
              required
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorMedicalReportsDetails?.basicMedicalInformation?.lungDiseaseReport}
              onChange={(e) =>
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  basicMedicalInformation: {
                    ...donorMedicalReportsDetails.basicMedicalInformation,
                    lungDiseaseReport: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  },
                })
              }
            />
          </Grid>
        ) : null} */}
        <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
          {/* <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Does the donor have any kind of Tumor?</FormLabel>
              <RadioGroup
                row
                aria-label="Does the donor have any kind of Tumor?"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.medicalHistory?.tumor}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    medicalHistory: {
                      ...donorMedicalReportsDetails.medicalHistory,
                      tumor: e.target.value === 'true',
                    },
                  });
                  if (e.target.value === 'true') {
                    donorPages.splice(3, 0, 'TUMOR');
                    setDonorPages([...donorPages]);
                  } else if (donorPages[3] === 'TUMOR') {
                    donorPages.splice(3, 1);
                    setDonorPages([...donorPages]);
                  }
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <Grid container style={{ padding: 10 }}>
            <Grid item sm={6}>
              <ValidatedRadio
                label="Infections in the current / recent admission"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.medicalHistory?.infections}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    medicalHistory: {
                      ...donorMedicalReportsDetails.medicalHistory,
                      infections: e.target.value,
                      infectionsReport: {
                        key: '',
                        name: '',
                        url: '',
                      },
                    },
                  });
                }}
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                  { value: 'DONTKNOW', label: "Don't Know" },
                ]}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {donorMedicalReportsDetails?.medicalHistory?.infections === 'YES' ? (
              <Grid item sm={6}>
                {/* <Typography variant="body1">Infections Report</Typography> */}
                <ValidatedAttachment
                  // required
                  label="Infections Report"
                  acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                  value={donorMedicalReportsDetails?.medicalHistory?.infectionsReport}
                  onChange={(e) =>
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      medicalHistory: {
                        ...donorMedicalReportsDetails.medicalHistory,
                        infectionsReport: {
                          key: '',
                          name: e.target.files[0]?.name,
                          file: e.target.files[0],
                        },
                      },
                    })
                  }
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid container style={{ padding: 10 }}>
            <Grid item sm={6}>
              <ValidatedRadio
                label="Use of live vaccines (especially in paediatrics)"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.medicalHistory?.liveVaccines}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    medicalHistory: {
                      ...donorMedicalReportsDetails.medicalHistory,
                      liveVaccines: e.target.value === 'true',
                      liveVaccinesReport: {
                        key: '',
                        name: '',
                        url: '',
                      },
                    },
                  });
                }}
                options={[
                  { value: true, label: 'YES' },
                  { value: false, label: 'NO' },
                ]}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {donorMedicalReportsDetails?.medicalHistory?.liveVaccines ? (
              <Grid item sm={6}>
                {/* <Typography variant="body1">Live Vaccine Report</Typography> */}
                <ValidatedAttachment
                  // required
                  label="Live Vaccine Report"
                  acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                  value={donorMedicalReportsDetails?.medicalHistory?.liveVaccinesReport}
                  onChange={(e) =>
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      medicalHistory: {
                        ...donorMedicalReportsDetails.medicalHistory,
                        liveVaccinesReport: {
                          key: '',
                          name: e.target.files[0]?.name,
                          file: e.target.files[0],
                        },
                      },
                    })
                  }
                  // disabled={disabled}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid container style={{ padding: 10 }}>
            <Grid item sm={6}>
              <ValidatedRadio
                label="Occupational Exposures"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.medicalHistory?.occupationalExposure}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    medicalHistory: {
                      ...donorMedicalReportsDetails.medicalHistory,
                      occupationalExposure: e.target.value,
                      occupationalExposureDetails:''
                    },
                  });
                }}
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                  { value: 'DONTKNOW', label: "Don't Know" },
                ]}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {donorMedicalReportsDetails?.medicalHistory?.occupationalExposure === 'YES' ? (
              <Grid item sm={4} xs={12}>
                <Typography variant="body1">Details</Typography>
                <ValidatedInput
                  fullWidth
                  value={donorMedicalReportsDetails?.medicalHistory?.occupationalExposureDetails}
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      medicalHistory: {
                        ...donorMedicalReportsDetails.medicalHistory,
                        occupationalExposureDetails: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
            ) : // <Grid item sm={6}>
            //   <Typography variant="body1">Occupational Exposures Report</Typography>
            //   <ValidatedAttachment
            //     required
            //     label="Attachment"
            //     acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            //     value={donorMedicalReportsDetails?.medicalHistory?.occupationalExposureReport}
            //     onChange={(e) =>
            //       setDonorMedicalReportsDetails({
            //         ...donorMedicalReportsDetails,
            //         medicalHistory: {
            //           ...donorMedicalReportsDetails.medicalHistory,
            //           occupationalExposureReport: {
            //             key: '',
            //             name: e.target.files[0]?.name,
            //             file: e.target.files[0],
            //           },
            //         },
            //       })
            //     }
            //   />
            // </Grid>
            null}
          </Grid>

          <Grid container style={{ padding: 10 }}>
            <Grid item sm={6}>
              <ValidatedRadio
                label="Recent Internation or Domestic Travel History (within 4 weeks)"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.medicalHistory?.travelHistory}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    medicalHistory: {
                      ...donorMedicalReportsDetails.medicalHistory,
                      travelHistory: e.target.value,
                      travelHistoryReport: '',
                    },
                  });
                }}
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                  { value: 'DONTKNOW', label: "Don't Know" },
                ]}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {donorMedicalReportsDetails?.medicalHistory?.travelHistory === 'YES' ? (
              <Grid item sm={6} xs={12}>
                <Typography variant="body1">Travel History Details</Typography>
                <ValidatedInput
                  required
                  fullWidth
                  value={donorMedicalReportsDetails?.medicalHistory?.travelHistoryReport}
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      medicalHistory: {
                        ...donorMedicalReportsDetails.medicalHistory,
                        travelHistoryReport: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid container style={{ padding: 10 }}>
            <Grid item sm={6}>
              <ValidatedRadio
                label="Recent Transfusions with blood or blood products"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.medicalHistory?.bloodTransfusion}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    medicalHistory: {
                      ...donorMedicalReportsDetails.medicalHistory,
                      bloodTransfusion: e.target.value,
                      bloodTransfusionDetails:''
                    },
                  });
                }}
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                  { value: 'DONTKNOW', label: "Don't Know" },
                ]}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {donorMedicalReportsDetails?.medicalHistory?.bloodTransfusion === 'YES' ? (
              <Grid item sm={6} xs={12}>
                <Typography variant="body1">Details</Typography>
                <ValidatedInput
                  required
                  fullWidth
                  value={donorMedicalReportsDetails?.medicalHistory?.bloodTransfusionDetails}
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      medicalHistory: {
                        ...donorMedicalReportsDetails.medicalHistory,
                        bloodTransfusionDetails: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
            ) : // <Grid item sm={6}>
            //   <Typography variant="body1">Blood Transfusion Report</Typography>
            //   <ValidatedAttachment
            //     required
            //     label="Attachment"
            //     acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            //     value={donorMedicalReportsDetails?.medicalHistory?.bloodTransfusionReport}
            //     onChange={(e) =>
            //       setDonorMedicalReportsDetails({
            //         ...donorMedicalReportsDetails,
            //         medicalHistory: {
            //           ...donorMedicalReportsDetails.medicalHistory,
            //           bloodTransfusionReport: {
            //             key: '',
            //             name: e.target.files[0]?.name,
            //             file: e.target.files[0],
            //           },
            //         },
            //       })
            //     }
            //   />
            // </Grid>
            null}
          </Grid>

          <Grid container style={{ padding: 10 }}>
            <Grid item sm={6}>
              <ValidatedRadio
                label="Any exposure with people with HIV, HBV, HCV or other transmissible diseases"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.medicalHistory?.contactWithTransmissibleDiseases}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    medicalHistory: {
                      ...donorMedicalReportsDetails.medicalHistory,
                      contactWithTransmissibleDiseases: e.target.value,
                      contactWithTransmissibleDiseasesDetails:''
                      // contactWithTransmissibleDiseasesReport: {
                      //   key: '',
                      //   name: '',
                      //   url: '',
                      // },
                    },
                  });
                }}
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                  { value: 'DONTKNOW', label: "Don't Know" },
                ]}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {donorMedicalReportsDetails?.medicalHistory?.contactWithTransmissibleDiseases ===
            'YES' ? (
              <Grid item sm={6} xs={12}>
                <Typography variant="body1">Details</Typography>
                <ValidatedInput
                  required
                  fullWidth
                  value={
                    donorMedicalReportsDetails?.medicalHistory
                      ?.contactWithTransmissibleDiseasesDetails
                  }
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      medicalHistory: {
                        ...donorMedicalReportsDetails.medicalHistory,
                        contactWithTransmissibleDiseasesDetails: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
            ) : // <Grid item sm={6}>
            //   <Typography variant="body1">Contact With Transmissible Diseases Report</Typography>
            //   <ValidatedAttachment
            //     required
            //     label="Attachment"
            //     acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            //     value={
            //       donorMedicalReportsDetails?.medicalHistory?.contactWithTransmissibleDiseasesReport
            //     }
            //     onChange={(e) =>
            //       setDonorMedicalReportsDetails({
            //         ...donorMedicalReportsDetails,
            //         medicalHistory: {
            //           ...donorMedicalReportsDetails.medicalHistory,
            //           contactWithTransmissibleDiseasesReport: {
            //             key: '',
            //             name: e.target.files[0]?.name,
            //             file: e.target.files[0],
            //           },
            //         },
            //       })
            //     }
            //   />
            // </Grid>
            null}
          </Grid>

          <Grid container style={{ padding: 10 }}>
            <Grid item sm={6}>
              <ValidatedRadio
                label="Use of illicit drugs"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.medicalHistory?.illicitDrugs}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    medicalHistory: {
                      ...donorMedicalReportsDetails.medicalHistory,
                      illicitDrugs: e.target.value,
                      illicitDrugsDetails:''
                      // illicitDrugsReport: {
                      //   key: '',
                      //   name: '',
                      //   url: '',
                      // },
                    },
                  });
                }}
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                  { value: 'DONTKNOW', label: "Don't Know" },
                ]}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {donorMedicalReportsDetails?.medicalHistory?.illicitDrugs === 'YES' ? (
              <Grid item sm={6} xs={12}>
                <Typography variant="body1">Details</Typography>
                <ValidatedInput
                  required
                  fullWidth
                  value={donorMedicalReportsDetails?.medicalHistory?.illicitDrugsDetails}
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      medicalHistory: {
                        ...donorMedicalReportsDetails.medicalHistory,
                        illicitDrugsDetails: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
            ) : // <Grid item sm={6}>
            //   <Typography variant="body1">Infections Report</Typography>
            //   <ValidatedAttachment
            //     required
            //     label="Attachment"
            //     acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            //     value={donorMedicalReportsDetails?.medicalHistory?.illicitDrugsReport}
            //     onChange={(e) =>
            //       setDonorMedicalReportsDetails({
            //         ...donorMedicalReportsDetails,
            //         medicalHistory: {
            //           ...donorMedicalReportsDetails.medicalHistory,
            //           illicitDrugsReport: {
            //             key: '',
            //             name: e.target.files[0]?.name,
            //             file: e.target.files[0],
            //           },
            //         },
            //       })
            //     }
            //   />
            // </Grid>
            null}
          </Grid>
          <Grid container style={{ padding: 10 }}>
            <Grid item sm={6}>
              <ValidatedRadio
                label="Tattooing, Ear Piercing or Body Piercing"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.medicalHistory?.tattoEarOrBodyPiercing}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    medicalHistory: {
                      ...donorMedicalReportsDetails.medicalHistory,
                      tattoEarOrBodyPiercing: e.target.value,
                      tattoEarOrBodyPiercingDetails:''
                      // tattoEarOrBodyPiercingReport: {
                      //   key: '',
                      //   name: '',
                      //   url: '',
                      // },
                    },
                  });
                }}
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                  { value: 'DONTKNOW', label: "Don't Know" },
                ]}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {donorMedicalReportsDetails?.medicalHistory?.tattoEarOrBodyPiercing === 'YES' ? (
              <Grid item sm={6} xs={12}>
                <Typography variant="body1">Details</Typography>
                <ValidatedInput
                  required
                  fullWidth
                  value={donorMedicalReportsDetails?.medicalHistory?.tattoEarOrBodyPiercingDetails}
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      medicalHistory: {
                        ...donorMedicalReportsDetails.medicalHistory,
                        tattoEarOrBodyPiercingDetails: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
            ) : // <Grid item sm={6}>
            //   <Typography variant="body1">Tatto, Ear Or Body Piercing Report</Typography>
            //   <ValidatedAttachment
            //     required
            //     label="Attachment"
            //     acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            //     value={donorMedicalReportsDetails?.medicalHistory?.tattoEarOrBodyPiercingReport}
            //     onChange={(e) =>
            //       setDonorMedicalReportsDetails({
            //         ...donorMedicalReportsDetails,
            //         medicalHistory: {
            //           ...donorMedicalReportsDetails.medicalHistory,
            //           tattoEarOrBodyPiercingReport: {
            //             key: '',
            //             name: e.target.files[0]?.name,
            //             file: e.target.files[0],
            //           },
            //         },
            //       })
            //     }
            //   />
            // </Grid>
            null}
          </Grid>
          <Grid container style={{ padding: 10 }}>
            <Grid item sm={6}>
              <ValidatedRadio
                label="Unsafe sexual practices"
                name="controlled-radio-buttons-group"
                value={donorMedicalReportsDetails?.medicalHistory?.unsafeSexPratices}
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    medicalHistory: {
                      ...donorMedicalReportsDetails.medicalHistory,
                      unsafeSexPratices: e.target.value,
                      unsafeSexPraticesDetails:''
                      // unsafeSexPraticesReport: {
                      //   key: '',
                      //   name: '',
                      //   url: '',
                      // },
                    },
                  });
                }}
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                  { value: 'DONTKNOW', label: "Don't Know" },
                ]}
                // validation={validation}
                // onValidation={onValidation}
              />
            </Grid>
            {donorMedicalReportsDetails?.medicalHistory?.unsafeSexPratices === 'YES' ? (
              <Grid item sm={6} xs={12}>
                <Typography variant="body1">Details</Typography>
                <ValidatedInput
                  required
                  fullWidth
                  value={donorMedicalReportsDetails?.medicalHistory?.unsafeSexPraticesDetails}
                  label=""
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setDonorMedicalReportsDetails({
                      ...donorMedicalReportsDetails,
                      medicalHistory: {
                        ...donorMedicalReportsDetails.medicalHistory,
                        unsafeSexPraticesDetails: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
            ) : // <Grid item sm={6}>
            //   <Typography variant="body1">Unsafe Sex Pratices Report</Typography>
            //   <ValidatedAttachment
            //     required
            //     label="Attachment"
            //     acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            //     value={donorMedicalReportsDetails?.medicalHistory?.unsafeSexPraticesReport}
            //     onChange={(e) =>
            //       setDonorMedicalReportsDetails({
            //         ...donorMedicalReportsDetails,
            //         medicalHistory: {
            //           ...donorMedicalReportsDetails.medicalHistory,
            //           unsafeSexPraticesReport: {
            //             key: '',
            //             name: e.target.files[0]?.name,
            //             file: e.target.files[0],
            //           },
            //         },
            //       })
            //     }
            //   />
            // </Grid>
            null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
MedicalReportsBasicInfo.propTypes = {
  donorMedicalReportsDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  setDonorMedicalReportsDetails: PropTypes.func.isRequired,

  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
};
MedicalReportsBasicInfo.defaultProps = {};

export default MedicalReportsBasicInfo;
