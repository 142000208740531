import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import util from 'util';
import { makeStyles, Paper, Box, Button, Typography, Grid } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PropTypes from 'prop-types';
import { withRouter, useRouteMatch } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import mlasOnChange from './mlasOnChange';
import mlasFields from './mlasFields';
import { useCancellableApiCall } from '../../hooks';
import { ReactComponent as GovernmentSvg } from '../svgs/Government.svg';
import { withValidation } from '../../hoc';
import FieldsToInput from '../common/FieldsToInput';
import {
  PATH_EXP_GETALLOCATIONRECIPIENTMLASDETAILS,
  PATH_EXP_UPDATEMLASREPORTAPPROVAL,
} from '../../appConstants';
import LoadableButton from '../Custom/LoadableButton';

function MlasScoring() {
  const [mlasDetails, setMlasDetails] = useState(cloneDeep(mlasFields));

  const _isDisabled = () => {
    return mlasDetails?.formStatus === 'SUBMITTED';
  };

  const onChange = (fieldName, value) => {
    const newMlasDetails = mlasOnChange(mlasDetails, fieldName, value);
    setMlasDetails({ ...newMlasDetails });
  };

  const { apiGet, apiPost, apiCallStatuses } = useCancellableApiCall();

  const recordMatch = useRouteMatch('/mlas/:_id');
  if (recordMatch) {
    // setNewAllocDialog(false);
  }

  useEffect(() => {
    let extractedRecordId;
    if (recordMatch) {
      const {
        params: { _id: id },
      } = recordMatch;
      extractedRecordId = id;
    }
    if (recordMatch) {
      apiGet(util.format(PATH_EXP_GETALLOCATIONRECIPIENTMLASDETAILS, extractedRecordId), {})
        .then((mlas) => {
          setMlasDetails(mlas);
        })
        .catch(() => {});
    }
  }, [apiGet]);

  const useStyles = makeStyles(() => ({
    paperTransplantInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 20,
      marginBottom: 10,
    },
    gridTransplantDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperTransplantSections: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px 0px 0px 4px',
      height: '100%',
    },
    transplantSectionsStyle: {
      fontWeight: 300,
      lineHeight: '15px',
      marginTop: 20,
      marginLeft: 20,
    },
    transplantSectionsHeadingStyle: {
      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    transplantDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#34495E',
      marginTop: '5px',
    },
    form12BoxStyle: {
      direction: 'row',
      marginTop: 90,
      ...(_isDisabled() ? { marginTop: 90 } : {}),
    },
  }));

  const classes = useStyles();

  const _renderForm7FooterButtons = () => {
    if (_isDisabled()) {
      return null;
    }

    return (
      <Box>
        <Grid container justify="center" style={{ paddingBottom: 20 }} spacing={1}>
          <Grid item>
            <Button color="primary" variant="contained" onClick={() => {}}>
              Preview and Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const [submitRottoApprovalApiId, setSubmitRottoApprovalApiId] = useState('APIID');
  const _submitRottoApproval = (approval, reportType) => {
    let approvalStatus;
    if (approval) {
      approvalStatus = 'APPROVED';
    } else {
      approvalStatus = 'REJECTED';
    }
    apiPost(
      PATH_EXP_UPDATEMLASREPORTAPPROVAL,
      {
        mlasId: mlasDetails._id,
        approvalStatus,
        reportType,
      },
      {
        apiIdCb: (apiId) => {
          setSubmitRottoApprovalApiId(apiId);
        },
      }
    )
      .then(() => {})
      .catch(() => {});
  };

  const _renderMlasForm = () => {
    return (
      <Grid item sm={10}>
        <Paper>
          <Grid container>
            <Grid container item xs={12} direction="row" className={classes.gridTransplantDetails}>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    MLAS Scoring{' '}
                  </Typography>
                </Grid>
                {/* <Grid item>
                  <GovernmentSvg />
                  <Typography style={{ alignContent: 'center', fontWeight: 800 }}>
                    HOTA act
                  </Typography>
                </Grid> */}
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={mlasFields().mlas}
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Complication Score
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={mlasFields().complicationScore}
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    MELD Exception Criteria
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Hps (MELD Exception Criteria)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={mlasFields().meldExceptionCriteria.hps}
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
                <Grid item>
                  <LoadableButton
                    variant="contained"
                    color="primary"
                    apiCallStatuses={apiCallStatuses}
                    apiCallId={submitRottoApprovalApiId}
                    loadingText="Approving..."
                    onClick={() => {
                      _submitRottoApproval(true, 'hps');
                    }}
                  >
                    Approve
                  </LoadableButton>
                  <LoadableButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      _submitRottoApproval(false, 'hps');
                    }}
                  >
                    Reject
                  </LoadableButton>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Pah (MELD Exception Criteria)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={mlasFields().meldExceptionCriteria.pah}
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
                <Grid item>
                  <LoadableButton
                    variant="contained"
                    color="primary"
                    apiCallStatuses={apiCallStatuses}
                    apiCallId={submitRottoApprovalApiId}
                    loadingText="Approving..."
                    onClick={() => {
                      _submitRottoApproval(true, 'pah');
                    }}
                  >
                    Approve
                  </LoadableButton>
                  <LoadableButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      _submitRottoApproval(false, 'pah');
                    }}
                  >
                    Reject
                  </LoadableButton>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Hcc (MELD Exception Criteria)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={mlasFields().meldExceptionCriteria.hcc}
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
                <Grid item>
                  <LoadableButton
                    variant="contained"
                    color="primary"
                    apiCallStatuses={apiCallStatuses}
                    apiCallId={submitRottoApprovalApiId}
                    loadingText="Approving..."
                    onClick={() => {
                      _submitRottoApproval(true, 'hcc');
                    }}
                  >
                    Approve
                  </LoadableButton>
                  <LoadableButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      _submitRottoApproval(false, 'hcc');
                    }}
                  >
                    Reject
                  </LoadableButton>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Psc (MELD Exception Criteria)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={mlasFields().meldExceptionCriteria.psc}
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
                <Grid item>
                  <LoadableButton
                    variant="contained"
                    color="primary"
                    apiCallStatuses={apiCallStatuses}
                    apiCallId={submitRottoApprovalApiId}
                    loadingText="Approving..."
                    onClick={() => {
                      _submitRottoApproval(true, 'psc');
                    }}
                  >
                    Approve
                  </LoadableButton>
                  <LoadableButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      _submitRottoApproval(false, 'psc');
                    }}
                  >
                    Reject
                  </LoadableButton>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Pbc (MELD Exception Criteria)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={mlasFields().meldExceptionCriteria.pbc}
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
                <Grid item>
                  <LoadableButton
                    variant="contained"
                    color="primary"
                    apiCallStatuses={apiCallStatuses}
                    apiCallId={submitRottoApprovalApiId}
                    loadingText="Approving..."
                    onClick={() => {
                      _submitRottoApproval(true, 'pbc');
                    }}
                  >
                    Approve
                  </LoadableButton>
                  <LoadableButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      _submitRottoApproval(false, 'pbc');
                    }}
                  >
                    Reject
                  </LoadableButton>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Post Transplant Patient Requiring Retransplant (MELD Exception Criteria)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={
                    mlasFields().meldExceptionCriteria.postTransplantPatientRequiringRetransplant
                  }
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
                <Grid item>
                  <LoadableButton
                    variant="contained"
                    color="primary"
                    apiCallStatuses={apiCallStatuses}
                    apiCallId={submitRottoApprovalApiId}
                    loadingText="Approving..."
                    onClick={() => {
                      _submitRottoApproval(true, 'postTransplantPatientRequiringRetransplant');
                    }}
                  >
                    Approve
                  </LoadableButton>
                  <LoadableButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      _submitRottoApproval(false, 'postTransplantPatientRequiringRetransplant');
                    }}
                  >
                    Reject
                  </LoadableButton>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Mld (MELD Exception Criteria)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={mlasFields().meldExceptionCriteria.mld}
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
                <Grid item>
                  <LoadableButton
                    variant="contained"
                    color="primary"
                    apiCallStatuses={apiCallStatuses}
                    apiCallId={submitRottoApprovalApiId}
                    loadingText="Approving..."
                    onClick={() => {
                      _submitRottoApproval(true, 'mld');
                    }}
                  >
                    Approve
                  </LoadableButton>
                  <LoadableButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      _submitRottoApproval(false, 'mld');
                    }}
                  >
                    Reject
                  </LoadableButton>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Mld Otw (MELD Exception Criteria)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={mlasFields().meldExceptionCriteria.mldOtw}
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
                <Grid item>
                  <LoadableButton
                    variant="contained"
                    color="primary"
                    apiCallStatuses={apiCallStatuses}
                    apiCallId={submitRottoApprovalApiId}
                    loadingText="Approving..."
                    onClick={() => {
                      _submitRottoApproval(true, 'mldOtw');
                    }}
                  >
                    Approve
                  </LoadableButton>
                  <LoadableButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      _submitRottoApproval(false, 'mldOtw');
                    }}
                  >
                    Reject
                  </LoadableButton>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 10 }}>
                <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Non-Hcc (MELD Exception Criteria)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <FieldsToInput
                  fieldGridProps={{ item: true, sm: 4, xs: 12 }}
                  fields={mlasFields().meldExceptionCriteria.nonHcc}
                  disabled={false}
                  state={mlasDetails}
                  onChange={onChange}
                />
                <Grid item>
                  <LoadableButton
                    variant="contained"
                    color="primary"
                    apiCallStatuses={apiCallStatuses}
                    apiCallId={submitRottoApprovalApiId}
                    loadingText="Approving..."
                    onClick={() => {
                      _submitRottoApproval(true, 'nonHcc');
                    }}
                  >
                    Approve
                  </LoadableButton>
                  <LoadableButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      _submitRottoApproval(false, 'nonHcc');
                    }}
                  >
                    Reject
                  </LoadableButton>
                </Grid>
              </Grid>
              <Grid>
                <hr />
              </Grid>
            </Grid>
          </Grid>
          {_renderForm7FooterButtons()}
        </Paper>
      </Grid>
    );
  };

  return (
    <Box className={classes.form12BoxStyle} marginLeft="15px" marginRight="15px">
      {mlasDetails.formStatus === 'NEW' ? (
        <Paper className={classes.paperTransplantInfo}>
          <Box justify="space-between" display="flex" flexDirection="row">
            <Grid container style={{ padding: 15, flexDirection: 'row' }}>
              <Grid item>
                <Typography
                  variant="h3"
                  style={{ fontSize: '16px', marginBottom: '10px', padding: 10 }}
                >
                  MLAS Scoring
                </Typography>
                <Typography
                  style={{
                    fontWeight: 300,
                    fontSize: '14px',
                    lineHeight: '15px',
                  }}
                >
                  MLAS scoring for organ allocation.
                </Typography>
              </Grid>
            </Grid>

            {!_isDisabled() ? (
              <Grid container style={{ padding: 15, flexDirection: 'row-reverse' }} spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      height: '36px',
                    }}
                    startIcon={<KeyboardBackspaceIcon />}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Paper>
      ) : null}
      <Box display="flex" flexDirection="row" marginTop="20">
        <Grid container>
          <Grid item sm={2}>
            <Paper className={classes.paperTransplantSections}>
              <Grid container style={{ padding: 20 }}>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.transplantSectionsHeadingStyle}>
                    MLAS Scoring
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.transplantSectionsStyle}>
                    Complication Score
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.transplantSectionsStyle}>
                    Complication Points
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {_renderMlasForm()}
        </Grid>
      </Box>
    </Box>
  );
}

MlasScoring.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      draftId: PropTypes.string,
    }),
  }),
  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
};
MlasScoring.defaultProps = {
  match: {
    params: {
      draftId: undefined,
    },
  },
};

export default compose(withRouter, withValidation)(MlasScoring);
