export const DISTRICTS_AS = [
  {
    label: 'Baksa',
    value: 'BAKSA'
  },
  {
    label: 'Barpeta',
    value: 'BARPETA'
  },
  {
    label: 'Biswanath',
    value: 'BISWANATH'
  },
  {
    label: 'Bongaigaon',
    value: 'BONGAIGOAN'
  },
  {
    label: 'Cachar',
    value: 'CACHAR'
  },
  {
    label: 'Charaideo',
    value: 'CHARAIDEO'
  },
  {
    label: 'Chirang',
    value: 'CHIRANG'
  },
  {
    label: 'Darrang',
    value: 'DARRANG'
  },
  {
    label: 'Dhemaji',
    value: 'DHEMAJI'
  },
  {
    label: 'Dhubri',
    value: 'DHUBRI'
  },
  {
    label: 'Dibrugarh',
    value: 'DIBRUGARH'
  },
  {
    label: 'Dima Hasao (North Cachar Hills)',
    value: 'DIMA_HASAO'
  },
  {
    label: 'Goalpara',
    value: 'GOALPARA'
  },
  {
    label: 'Golaghat',
    value: 'GOLAGHAT'
  },
  {
    label: 'Hailakandi',
    value: 'HAILAKANDI'
  },
  {
    label: 'Hojai',
    value: 'HOJAI'
  },
  {
    label: 'Jorhat',
    value: 'JORHAT'
  },
  {
    label: 'Kamrup',
    value: 'KAMRUP'
  },
  {
    label: 'Kamrup Metropolitan',
    value: 'KAMRUP_METROPOLITAN'
  },
  {
    label: 'Karbi Anglong',
    value: 'KARBI_ANGLONG'
  },
  {
    label: 'Karimganj',
    value: 'KARIMGANJ'
  },
  {
    label: 'Kokrajhar',
    value: 'KOKRAJHAR'
  },
  {
    label: 'Lakhimpur',
    value: 'LAKHIMPUR'
  },
  {
    label: 'Majuli',
    value: 'MAJULI'
  },
  {
    label: 'Morigaon',
    value: 'MORIGOAN'
  },
  {
    label: 'Nagaon',
    value: 'NAGOAN'
  },
  {
    label: 'Nalbari',
    value: 'NALBARI'
  },
  {
    label: 'Sivasagar',
    value: 'SIVASAGAR'
  },
  {
    label: 'Sonitpur',
    value: 'SONITPUR'
  },
  {
    label: 'South Salamara-Mankachar',
    value: 'SOUTH_SALAMARA'
  },
  {
    label: 'Tinsukia',
    value: 'TINSUKIA'
  },
  {
    label: 'Udalguri',
    value: 'UDALGURI'
  },
  {
    label: 'West Karbi Anglong',
    value: 'WEST_KARBI_ANGLONG'
  }
];
