import { Storage } from 'aws-amplify';

export const uploadFile = async (file, fileName) => {
  try {
    await Storage.put(fileName, file);
    const objectUrl = await Storage.get(fileName);
    return objectUrl;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getFileUrl = async (fileName) => {
  try {
    const objectUrl = await Storage.get(fileName);
    return objectUrl;
  } catch (err) {
    throw err;
  }
};

export const getFile = async (fileKey) => {
  try {
    const object = await Storage.get(fileKey);
    return object;
  } catch (err) {
    throw err;
  }
};
