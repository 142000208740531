import { isDhsUserType, isHospitalIcUserType } from '../../../constants';
import { PATH_EXP_GETSPECIALISTS, PATH_EXP_GETSPECIALISTBYID } from '../../../appConstants';
import { apiExpressGet } from '../../../utils';

export const getSpecialistsList = async (hospitalId, userType) => {
  const query = { hospitalId, userType };
  let url;
  if (isHospitalIcUserType(userType) || isDhsUserType(userType)) {
    url = PATH_EXP_GETSPECIALISTS;
  } else {
    throw new Error('Invalid user login');
  }

  try {
    return await apiExpressGet(url, query);
  } catch (err) {
    throw err;
  }
};

export const handleSpecialistsChange = (specialist, checked, component) => {
  const { trainedPersons, setOtherSupportiveSpecialists } = component.props;
  let updatedOtherSupportiveSpecialists = [];
  if (checked) {
    const trainedPerson = {
      userId: specialist.userId,
      user: specialist,
    };
    updatedOtherSupportiveSpecialists = [trainedPerson, ...trainedPersons];
  } else {
    updatedOtherSupportiveSpecialists = trainedPersons.filter(
      (s) => s.user.userId !== specialist.userId
    );
  }

  const dedupedUpdatedOtherSupportiveSpecialists = updatedOtherSupportiveSpecialists.filter(
    (s, idx, self) => self.findIndex((s1) => s1.user.userId === s.user.userId) === idx
  );

  setOtherSupportiveSpecialists(dedupedUpdatedOtherSupportiveSpecialists);
};

export const getSpecialistById = async (hospitalId, userType, trainedPersonType, userId) => {
  const query = {
    trainedPersonType,
    userId,
  };
  let url;
  if (isHospitalIcUserType(userType)) {
    url = PATH_EXP_GETSPECIALISTBYID;
  } else {
    throw new Error('Invalid user login');
  }

  try {
    return await apiExpressGet(url, query);
  } catch (err) {
    throw err;
  }
};

export const handleSearchText = (name, value, component) => {
  component.setState({
    searchText: value.trim(),
  });
};
