import React from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatedAttachment, ValidatedInput, ValidatedRadio } from '../../Custom';
import { FILETYPES } from '../../Custom/ValidatedAttachment';

function MedicalReportsHemodynamics({ donorMedicalReportsDetails, setDonorMedicalReportsDetails }) {

  const useStyles = makeStyles(() => ({
    paperTransplantInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 20,
      marginBottom: 10,
    },
    gridTransplantDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperTransplantSections: {
      height: '100%',
      marginTop: 5,
    },
    transplantSectionsStyle: {
      fontWeight: 300,
      lineHeight: '15px',
      marginTop: 20,
      marginLeft: 20,
    },
    transplantSectionsHeadingStyle: {
      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    transplantDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#34495E',
      marginTop: '5px',
    },
    form12BoxStyle: {
      direction: 'row',
      // marginTop: 90,
      // ...(_isDisabled() ? { marginTop: 120 } : {}),
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">2D-ECHO</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.heartFunction?.twoDEcho}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                heartFunction: {
                  ...donorMedicalReportsDetails.heartFunction,
                  twoDEcho: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">2D-ECHO Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            value={donorMedicalReportsDetails?.heartFunction?.twoDEchoReport}
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                heartFunction: {
                  ...donorMedicalReportsDetails.heartFunction,
                  twoDEchoReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Chest X-ray</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.heartFunction?.chestXRay}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                heartFunction: {
                  ...donorMedicalReportsDetails.heartFunction,
                  chestXRay: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Chest X-ray Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            value={donorMedicalReportsDetails?.heartFunction?.chestXRayReport}
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                heartFunction: {
                  ...donorMedicalReportsDetails.heartFunction,
                  chestXRayReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Angiography"
            name="Angiography"
            value={donorMedicalReportsDetails?.heartFunction?.angiographyYesNo}
            onChange={(e) => {
              const isYes = e.target.value === 'true';
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                heartFunction: {
                  ...donorMedicalReportsDetails.heartFunction,
                  angiographyYesNo: isYes,
                  angiography: isYes ? donorMedicalReportsDetails.heartFunction.angiography : '',
                  angiographyReport: isYes ? donorMedicalReportsDetails.heartFunction.angiographyReport : { key: '', name: '', file: null },
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        {donorMedicalReportsDetails?.heartFunction?.angiographyYesNo ? (
          <>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Angiography Details</Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorMedicalReportsDetails?.heartFunction?.angiography}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    heartFunction: {
                      ...donorMedicalReportsDetails.heartFunction,
                      angiography: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Angiography Report</Typography>
              <ValidatedAttachment
                required
                label="Attachment"
                value={donorMedicalReportsDetails?.heartFunction?.angiographyReport}
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                onChange={(e) =>
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    heartFunction: {
                      ...donorMedicalReportsDetails.heartFunction,
                      angiographyReport: {
                        key: '',
                        name: e.target.files[0]?.name,
                        file: e.target.files[0],
                      },
                    },
                  })
                }
              />
            </Grid>
          </>
        ) : (
          <Grid item sm={8} xs={12} />
        )}

        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Donor Blood Sample for cross match minimum 10-12 Heparin tubes of 5ml each"
            name="AngiographyDetails"
            value={donorMedicalReportsDetails?.heartFunction?.bloodSampleForCrossMatchYesNo}
            onChange={(e) => {
              const isYes = e.target.value === 'true';
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                heartFunction: {
                  ...donorMedicalReportsDetails.heartFunction,
                  bloodSampleForCrossMatchYesNo: isYes,
                  bloodSampleForCrossMatch: isYes ? donorMedicalReportsDetails.heartFunction.bloodSampleForCrossMatch : '',
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        {donorMedicalReportsDetails?.heartFunction?.bloodSampleForCrossMatchYesNo ? (
          <>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                Donor Blood Sample for cross match minimum 10-12 Heparin tubes of 5ml each Details
              </Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorMedicalReportsDetails?.heartFunction?.bloodSampleForCrossMatch}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    heartFunction: {
                      ...donorMedicalReportsDetails.heartFunction,
                      bloodSampleForCrossMatch: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12} />
          </>
        ) : (
          <Grid item sm={8} xs={12} />
        )}

        {/* <Grid item sm={6} xs={12}> */}
        {/* <Typography variant="body1">
            Donor Blood Sample for cross match minimum 10-12 Heparin tubes of 5ml each Report
          </Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            value={donorMedicalReportsDetails?.heartFunction?.bloodSampleForCrossMatchReport}
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                heartFunction: {
                  ...donorMedicalReportsDetails.heartFunction,
                  bloodSampleForCrossMatchReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid> */}
        {/* <Grid item sm={4} xs={12} /> */}
        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Blood Sample of Heart Cross Matching"
            name="Blood Sample of Heart Cross Matching"
            value={donorMedicalReportsDetails?.heartFunction?.bloodSampleForHeartCrossMatchingYesNo}
            onChange={(e) => {
              const isYes = e.target.value === 'true';
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                heartFunction: {
                  ...donorMedicalReportsDetails.heartFunction,
                  bloodSampleForHeartCrossMatchingYesNo: isYes,
                  bloodSampleForHeartCrossMatching: isYes ? donorMedicalReportsDetails.heartFunction.bloodSampleForHeartCrossMatching : '',
                  bloodSampleForHeartCrossMatchingReport: isYes ? donorMedicalReportsDetails.heartFunction.bloodSampleForHeartCrossMatchingReport : { key: '', name: '', file: null },
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        {donorMedicalReportsDetails?.heartFunction?.bloodSampleForHeartCrossMatchingYesNo ? (
          <>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Blood Sample of Heart Cross Matching Details</Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorMedicalReportsDetails?.heartFunction?.bloodSampleForHeartCrossMatching}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    heartFunction: {
                      ...donorMedicalReportsDetails.heartFunction,
                      bloodSampleForHeartCrossMatching: e.target.value,
                    },
                  });
                }}
              />
            </Grid>

            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Blood Sample of Heart Cross Matching Report</Typography>
              <ValidatedAttachment
                required
                label="Attachment"
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                value={
                  donorMedicalReportsDetails?.heartFunction?.bloodSampleForHeartCrossMatchingReport
                }
                onChange={(e) =>
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    heartFunction: {
                      ...donorMedicalReportsDetails.heartFunction,
                      bloodSampleForHeartCrossMatchingReport: {
                        key: '',
                        name: e.target.files[0]?.name,
                        file: e.target.files[0],
                      },
                    },
                  })
                }
              />
            </Grid>
          </>
        ) : (
          <Grid item sm={8} xs={12} />
        )}

        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="CT Thorax for Lung"
            name="CT Thorax for Lung"
            value={donorMedicalReportsDetails?.heartFunction?.ctThoraxYesNo}
            onChange={(e) => {
              const isYes = e.target.value === 'true';
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                heartFunction: {
                  ...donorMedicalReportsDetails.heartFunction,
                  ctThoraxYesNo: isYes,
                  ctThorax: isYes ? donorMedicalReportsDetails.heartFunction.ctThorax : '',
                  ctThoraxReport: isYes ? donorMedicalReportsDetails.heartFunction.ctThoraxReport : { key: '', name: '', file: null },
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        {donorMedicalReportsDetails?.heartFunction?.ctThoraxYesNo ? (
          <>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">CT Thorax for Lung Details</Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorMedicalReportsDetails?.heartFunction?.ctThorax}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    heartFunction: {
                      ...donorMedicalReportsDetails.heartFunction,
                      ctThorax: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">CT Thorax for Lung Report</Typography>
              <ValidatedAttachment
                required
                label="Attachment"
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                value={donorMedicalReportsDetails?.heartFunction?.ctThoraxReport}
                onChange={(e) =>
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    heartFunction: {
                      ...donorMedicalReportsDetails.heartFunction,
                      ctThoraxReport: {
                        key: '',
                        name: e.target.files[0]?.name,
                        file: e.target.files[0],
                      },
                    },
                  })
                }
              />
            </Grid>
          </>
        ) : (
          <Grid item sm={8} xs={12} />
        )}

        <Grid item sm={4} xs={12}>
          <ValidatedRadio
            label="Trop T (Troponin)"
            name="rop T (Troponin)"
            value={donorMedicalReportsDetails?.heartFunction?.tropTYesNo}
            onChange={(e) => {
              const isYes = e.target.value === 'true';
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                heartFunction: {
                  ...donorMedicalReportsDetails.heartFunction,
                  tropTYesNo: isYes,
                  tropT: isYes ? donorMedicalReportsDetails.heartFunction.tropT : '',
                  tropTReport: isYes ? donorMedicalReportsDetails.heartFunction.tropTReport : { key: '', name: '', file: null },
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        {donorMedicalReportsDetails?.heartFunction?.tropTYesNo ? (
          <>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Trop T (Troponin) Details</Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorMedicalReportsDetails?.heartFunction?.tropT}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    heartFunction: {
                      ...donorMedicalReportsDetails.heartFunction,
                      tropT: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Trop T (Troponin) Report</Typography>
              <ValidatedAttachment
                required
                label="Attachment"
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                value={donorMedicalReportsDetails?.heartFunction?.tropTReport}
                onChange={(e) =>
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    heartFunction: {
                      ...donorMedicalReportsDetails.heartFunction,
                      tropTReport: {
                        key: '',
                        name: e.target.files[0]?.name,
                        file: e.target.files[0],
                      },
                    },
                  })
                }
              />
            </Grid>
          </>
        ) : (
          <Grid item sm={8} xs={12} />
        )}
      </Grid>
    </>
  );
}

MedicalReportsHemodynamics.propTypes = {
  donorMedicalReportsDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  setDonorMedicalReportsDetails: PropTypes.func.isRequired,
};
MedicalReportsHemodynamics.defaultProps = {};

export default MedicalReportsHemodynamics;
