export const DISTRICTS_WB = [
  {
    label: 'Alipurduar',
    value: 'ALIPURDUAR',
  },
  {
    label: 'Bankura',
    value: 'BANKURA',
  },
  {
    label: 'Birbhum',
    value: 'BIRBHUM',
  },
  {
    label: 'Cooch Behar',
    value: 'COOCH_BEHAR',
  },
  {
    label: 'Dakshin Dinajpur (South Dinajpur)',
    value: 'DAKSHIN_DINAJPUR',
  },
  {
    label: 'Darjeeling',
    value: 'DARJEELING',
  },
  {
    label: 'Hooghly',
    value: 'HOOGHLY',
  },
  {
    label: 'Howrah',
    value: 'HOWRAH',
  },
  {
    label: 'Jalpaiguri',
    value: 'JALPAIGURI',
  },
  {
    label: 'Jhargram',
    value: 'JHARGRAM',
  },
  {
    label: 'Kalimpong',
    value: 'KALIMPONG',
  },
  {
    label: 'Kolkata',
    value: 'KOLKATA',
  },
  {
    label: 'Malda',
    value: 'MALDA',
  },
  {
    label: 'Murshidabad',
    value: 'MURSHIDABAD',
  },
  {
    label: 'Nadia',
    value: 'NADIA',
  },
  {
    label: 'North Twenty Four Parganas',
    value: 'NORTH_PARGANAS',
  },
  {
    label: 'Paschim Medinipur (West Medinipur)',
    value: 'PASCHIM_MEDINIPUR',
  },
  {
    label: 'Paschim (West) Burdwan (Bardhaman)',
    value: 'PASCHIM_BURDWAN',
  },
  {
    label: 'Purba Burdwan (Bardhaman)',
    value: 'PURBA_BURDWAN',
  },
  {
    label: 'Purba Medinipur (East Medinipur)',
    value: 'PURBA_MEDINIPUR',
  },
  {
    label: 'Purulia',
    value: 'PURULIA',
  },
  {
    label: 'South Twenty Four Parganas',
    value: 'SOUTH_PARGANAS',
  },
  {
    label: 'Uttar Dinajpur (North Dinajpur)',
    value: 'UTTAR_DINAJPUR',
  },
];
