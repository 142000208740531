import set from 'lodash.set';

export const handleDonorUpdate = (name, value, component) => {
  const { eyeDonor } = component.state;
  set(eyeDonor, name, value);
  component.setState({
    eyeDonor
  });
};

export const handleDonorDocument = (name, file, component) => {
  const { eyeDonor } = component.state;
  if (!file) {
    set(eyeDonor, name, { name: '', file: null });
  } else {
    set(eyeDonor, name, { name: file.name, file });
  }

  component.setState({
    eyeDonor
  });
};

export const handleEyeBankChange = (hospitalDetails, hospitalDetailsValue, hospital, component) => {
  const { eyeDonor } = component.state;
  set(eyeDonor, hospitalDetails, hospitalDetailsValue);
  if (hospitalDetailsValue) {
    set(eyeDonor, hospital, hospitalDetailsValue.hospitalId);
  }
  component.setState({
    eyeDonor
  });
};
