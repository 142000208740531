import React, { Component } from 'react';
// import { Row, Col } from 'reactstrap';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { ValidatedSelect, ValidatedInput } from '.';

// eslint-disable-next-line react/prefer-stateless-function
class ValidatedSelectOther extends Component {
  render() {
    const {
      id,
      name,
      value,
      validation,
      onValidation,
      validateOtherInput,
      onSelectChange,
      onOtherInputChange,
      disabled,
      ...otherProps
    } = this.props;
    // let otherInputValidationProps = {};
    // if (validateOtherInput) {
    //   otherInputValidationProps = {
    //     validation,
    //     onValidation
    //   };
    // }
    const otherInputName = `${name}.other`;
    const otherInputId = `${id}.other`;
    return (
      <Grid container>
        <Grid item sm={6}>
          <ValidatedSelect
            id={`${id}.value`}
            value={value.value}
            onChange={(v) => {
              if (v.value !== 'OTHER') {
                onOtherInputChange(otherInputName, '');
              }
              onSelectChange(`${name}.value`, v.value);
            }}
            disabled={disabled}
            autosize
            validation={validation}
            onValidation={onValidation}
            {...otherProps}
          />
        </Grid>
        <Grid sm={6}>
          <ValidatedInput
            type="text"
            id={otherInputId}
            name={otherInputName}
            value={value.other}
            disabled={disabled || value.value !== 'OTHER'}
            onChange={(e) => onOtherInputChange(e.target.name, e.target.value)}
            placeholder="Other"
            schema={Joi.string()}
            validation={validation}
            onValidation={onValidation}
            validationDisabled={value.value !== 'OTHER'}
          />
        </Grid>
      </Grid>
    );
  }
}
ValidatedSelectOther.propTypes = {
  value: PropTypes.objectOf(PropTypes.any),
  onSelectChange: PropTypes.func,
  onOtherInputChange: PropTypes.func,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onValidation: PropTypes.func,
  validateOtherInput: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
ValidatedSelectOther.defaultProps = {
  value: undefined,
  onSelectChange: () => 'onSelectChange STUB',
  onOtherInputChange: () => 'onOtherInputChange STUB',
  validation: false,
  onValidation: () => 'onValidation STUB',
  validateOtherInput: false,
  id: undefined,
  name: undefined,
  disabled: false,
};

export default ValidatedSelectOther;
