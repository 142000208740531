export const DISTRICTS_NL = [
  {
    label: 'Dimapur',
    value: 'DIMAPUR'
  },
  {
    label: 'Kiphire',
    value: 'KIPHIRE'
  },
  {
    label: 'Kohima',
    value: 'KOHIMA'
  },
  {
    label: 'Longleng',
    value: 'LONGLENG'
  },
  {
    label: 'Mokokchung',
    value: 'MOKOKCHUNG'
  },
  {
    label: 'Mon',
    value: 'MON'
  },
  {
    label: 'Peren',
    value: 'PEREN'
  },
  {
    label: 'Phek',
    value: 'PHEK'
  },
  {
    label: 'Tuensang',
    value: 'TUENSANG'
  },
  {
    label: 'Wokha',
    value: 'WOKHA'
  },
  {
    label: 'Zunheboto',
    value: 'ZUNHEBOTO'
  }
];
