import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { useCancellableApiCall } from '../../../hooks';
import { ReactTable } from '../../Custom';
import { PATH_EXP_GETHOSPITALS } from '../../../appConstants';
import { districts } from '../../../districts/districts';
import { useSelector } from 'react-redux';

export default function TransplantSpecialistPicker({
  selectHospital,
  selectedHospital: propSelectedHospital,
}) {
  const [selectedHospital, setSelectedHospital] = useState('');
  const [hospitals, setHospitals] = useState([]);
  const app = useSelector((state) => state);

  const { apiGet, apiCallStatuses } = useCancellableApiCall();
  const [getHospitalsApiId, setGetHospitalsApiId] = useState('APIID');
  const {
    hospital: { name, _id },
  } = app;
  useEffect(() => {
    setSelectedHospital(propSelectedHospital);
    apiGet(
      PATH_EXP_GETHOSPITALS,
      {
        hospitalTypes: [],
      },
      {
        apiIdCb: (apiId) => {
          setGetHospitalsApiId(apiId);
        },
      }
    )
      .then((hosps) => {
        const filteredHospitals = hosps.filter((h) => h._id !== _id);
        setHospitals([...filteredHospitals]);
        // setHospitals([...hosps]);
      })
      .catch(() => {});
  }, []);

  const _selectHospitalFormatter = (hospital) => {
    const { hospitalId } = hospital;
    return selectedHospital && selectedHospital === hospitalId ? (
      <Button
        onClick={() => {
          setSelectedHospital('');
          selectHospital({});
        }}
        color="secondary"
        variant="contained"
      >
        Remove
      </Button>
    ) : (
      <Button
        onClick={() => {
          setSelectedHospital(hospital.hospitalId);
          selectHospital(hospital);
        }}
        color="primary"
        variant="contained"
      >
        Select
      </Button>
    );
  };

  const hospitalsColumns = [
    {
      Header: 'Hospital Name',
      accessor: 'name',
      key: 'name',
      // ...this._getColumnSearchProps('name'),
    },
    {
      Header: 'HospitalId',
      accessor: 'hospitalId',
      key: 'hospitalId',
    },
    {
      Header: 'District',
      accessor: 'address.district',
      key: 'district',
      Cell: ({ row }) => <Typography>{row?.original?.address.district
        ? districts(row?.original?.address.state).find(
            (district) => district.value === row?.original?.address.district
          )?.label
        : ''}</Typography>
    },
    {
      Header: 'Select',
      accessor: 'hospital',
      key: 'selectUser',
      Cell: ({ row }) => _selectHospitalFormatter(row.original),
      disableFilters: true,
      // render: (hospitalId, hospital) => this._selectHospitalFormatter(hospital),
    },
  ];

  return (
    <Grid sm={12}>
      <ReactTable
        apiCallId={getHospitalsApiId}
        apiCallStatuses={apiCallStatuses}
        columns={hospitalsColumns}
        data={hospitals}
      />
    </Grid>
  );
}
