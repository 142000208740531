const CONTEXT_DHS = '/dhs';
const CONTEXT_HOSPITAL = '/hospital';

const CONTEXT_ORGAN = '/eyebank';

const CONTEXT_REGISTRATION = `${CONTEXT_ORGAN}/regn`;

const CONTEXT_LEGACYFORM15EB = `${CONTEXT_REGISTRATION}/legacyForm15Eb`;

export const PATH_LEGACYFORM15EB_GETISSUES = `${CONTEXT_LEGACYFORM15EB}/%s/issues`;
export const PATH_LEGACYFORM15EB_DHS_UPDATEISSUESTATUS = `${CONTEXT_LEGACYFORM15EB}/%s${CONTEXT_DHS}/issue`;
export const PATH_LEGACYFORM15EB_DHS_ADDISSUE = `${CONTEXT_LEGACYFORM15EB}/%s${CONTEXT_DHS}/issue`;


export const PATH_LEGACYFORM15EB_DHS_GETSUMMARIES = `${CONTEXT_LEGACYFORM15EB + CONTEXT_DHS}/legacyForm15Ebsummaries`;
export const PATH_LEGACYFORM15EB_HOSPITAL_GETLEGACYFORM15EBSUMMARIES = `${CONTEXT_LEGACYFORM15EB + CONTEXT_HOSPITAL}/%s/legacyForm15Ebsummaries`;

export const PATH_LEGACYFORM15EB_DHS_GETLEGACYFORM15EB = `${CONTEXT_LEGACYFORM15EB}/%s${CONTEXT_DHS}`;
export const PATH_LEGACYFORM15EB_DHS_SIGNLEGACYFORM15EB = `${CONTEXT_LEGACYFORM15EB}/%s${CONTEXT_DHS}/signature`;
export const PATH_LEGACYFORM15EB_DHS_APPROVELEGACYFORM15EB = `${CONTEXT_LEGACYFORM15EB}/%s${CONTEXT_DHS}/approval`;
export const PATH_LEGACYFORM15EB_DHS_REVERTFORM15EB = `${CONTEXT_LEGACYFORM15EB}/%s${CONTEXT_DHS}/revert`;
export const PATH_LEGACYFORM15EB_DHS_ADDCERTIFICATE = `${CONTEXT_LEGACYFORM15EB}/%s${CONTEXT_DHS}/certificate`;
export const PATH_LEGACYFORM15EB_DHS_GETAPPROVAL = `${CONTEXT_LEGACYFORM15EB}/%s${CONTEXT_DHS}/approval`;

export const PATH_LEGACYFORM15EB_HOSPITAL_SUBMITDRAFTLEGACYFORM15EB = `${CONTEXT_LEGACYFORM15EB + CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15EB_HOSPITAL_SUBMITLEGACYFORM15EBEDITS = `${CONTEXT_LEGACYFORM15EB + CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15EB_HOSPITAL_UPDATELEGACYFORM15EBSTATUS = `${CONTEXT_LEGACYFORM15EB + CONTEXT_HOSPITAL}/%s/status`;
export const PATH_LEGACYFORM15EB_HOSPITAL_SUBMITUNDERTAKING = `${CONTEXT_LEGACYFORM15EB + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_LEGACYFORM15EB_HOSPITAL_UPDATEUNDERTAKING = `${CONTEXT_LEGACYFORM15EB + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_LEGACYFORM15EB_HOSPITAL_GETDRAFTLEGACYFORM15EB = `${CONTEXT_LEGACYFORM15EB + CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15EB_HOSPITAL_GETLEGACYFORM15EB = `${CONTEXT_LEGACYFORM15EB}/%s${CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15EB_HOSPITAL_GETAPPROVAL = `${CONTEXT_LEGACYFORM15EB}/%s${CONTEXT_HOSPITAL}/%s/approval`;

export const OPTIONS_LEGACYFORM15EBSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  REVERTED: 'REVERTED',
  ATTESTED: 'ATTESTED',
  REATTESTED: 'REATTESTED',
  INITIATED: 'INITIATED',
  APPROVED: 'APPROVED'
};
