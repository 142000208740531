import bip39 from 'bip39';
import hdkey from 'hdkey';
import ethUtil from 'ethereumjs-util';
import util from 'util';
import { API } from 'aws-amplify';
import * as log from 'loglevel';
import get from 'lodash.get';
import moment from 'moment';
import { uploadFile } from './storage';
import {
  isDhsUserType,
  SIGNINGROLE_REGN_DHS,
  API_AYUDAAN,
  FORMAT_FILENAME,
  FILESUFFIX_FORMAT_DATE,
  ID_ADMIN,
  ID_DHS,
  ROLE_ADMIN,
  USERTYPE_DHS,
  ID_INSPECTOR,
  USERTYPE_INSPECTOR,
  USERTYPE_HSPTLIC,
  ID_DMER,
  USERTYPE_DMERINCHARGE,
  API_ORGAN,
  API_EYEBANK,
  API_TISSUE,
  API_TRANSPLANT,
  API_RESOURCES,
  API_AYUDAANEXPRESS,
  API_ALLOCATION,
} from './constants';

const logger = log.getLogger('Utils');

export const generateSeedWordsAddress = () => {
  const seedWords = bip39.generateMnemonic();

  const seed = bip39.mnemonicToSeed(seedWords);
  const root = hdkey.fromMasterSeed(seed);
  const addrNode = root.derive("m/44'/60'/0'/0/0");
  const pubKey = ethUtil.privateToPublic(addrNode._privateKey);
  const addr = ethUtil.publicToAddress(pubKey).toString('hex');
  const address = ethUtil.toChecksumAddress(addr);

  return { seedWords, address };
};

export const getEventArg = (txResult, eventName, eventField) => {
  if (!txResult || !txResult.logs || !Array.isArray(txResult.logs)) {
    return null;
  }
  const event = txResult.logs.filter((l) => l.event === eventName)[0];
  return get(event, ['args', eventField], null);
};

export const uploadDocument = async (
  document,
  docPrefix = 'DOC',
  hospitalName = 'HOSP',
  fileSuffix = moment().format(FILESUFFIX_FORMAT_DATE)
) => {
  if (!document || !document.file) {
    return '';
  }
  if (document.url) {
    // eslint-disable-next-line no-param-reassign
    delete document.file;
    return document.url;
  }
  const { file } = document;
  const origFileName = file.name.split('.');
  const fileFormat = origFileName.pop();
  const origFile = origFileName.join('.');
  const hospitalCode = hospitalName.substr(0, 5).toUpperCase();
  const fileName = util.format(
    FORMAT_FILENAME,
    hospitalCode,
    docPrefix,
    origFile,
    fileSuffix,
    fileFormat
  );

  const fileUrl = await uploadFile(file, fileName);
  if (fileUrl) {
    // eslint-disable-next-line no-param-reassign
    delete document.file;
    // eslint-disable-next-line no-param-reassign
    document.url = fileUrl;
    // eslint-disable-next-line no-param-reassign
    document.key = fileName;
  }
  return document;
};

export const fetchSession = async () => {
  const url = `${API._api._options.endpoints[0].endpoint}/session`;
  fetch(url, {
    method: 'get',
    credentials: 'include',
  })
    .then(() => {})
    .catch(() => {});
};

// Core API Calls

export const apiGet = async (url, query) => {
  const initParam = query
    ? {
        queryStringParameters: query,
      }
    : undefined;
  logger.debug(`GET request URL: ${url}`);
  let response;
  try {
    response = await API.get(API_AYUDAAN, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiPost = async (url, postBody) => {
  const initParam = postBody
    ? {
        body: postBody,
      }
    : undefined;
  logger.debug(`POST request URL: ${url}`);
  let response;
  try {
    response = await API.post(API_AYUDAAN, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiPatch = async (url, patchBody) => {
  const initParam = patchBody
    ? {
        body: patchBody,
      }
    : undefined;
  logger.debug(`PATCH request URL: ${url}`);
  let response;
  try {
    response = await API.patch(API_AYUDAAN, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiPut = async (url, putBody) => {
  const initParam = putBody
    ? {
        body: putBody,
      }
    : undefined;
  logger.debug(`PUT request URL: ${url}`);

  let response;
  try {
    response = await API.put(API_AYUDAAN, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiExpressGet = async (url, query) => {
  const initParam = query
    ? {
        queryStringParameters: query,
      }
    : undefined;
  logger.debug(`GET request URL: ${url}`);
  let response;
  try {
    response = await API.get(API_AYUDAANEXPRESS, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiExpressPost = async (url, postBody) => {
  const initParam = postBody
    ? {
        body: postBody,
      }
    : undefined;
  logger.debug(`POST request URL: ${url}`);
  let response;
  try {
    response = await API.post(API_AYUDAANEXPRESS, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiExpressPut = async (url, putBody) => {
  const initParam = putBody
    ? {
        body: putBody,
      }
    : undefined;
  logger.debug(`PUT request URL: ${url}`);

  let response;
  try {
    response = await API.put(API_AYUDAANEXPRESS, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiExpressPatch = async (url, patchBody) => {
  const initParam = patchBody
    ? {
        body: patchBody,
      }
    : undefined;
  logger.debug(`PATCH request URL: ${url}`);

  let response;
  try {
    response = await API.patch(API_AYUDAANEXPRESS, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

// Organ API Calls

export const apiOrganGet = async (url, query) => {
  const initParam = query
    ? {
        queryStringParameters: query,
      }
    : undefined;
  logger.debug(`GET request URL: ${url}`);
  let response;
  try {
    response = await API.get(API_ORGAN, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiOrganPost = async (url, postBody) => {
  const initParam = postBody
    ? {
        body: postBody,
      }
    : undefined;
  logger.debug(`POST request URL: ${url}`);
  let response;
  try {
    response = await API.post(API_ORGAN, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiOrganPatch = async (url, patchBody) => {
  const initParam = patchBody
    ? {
        body: patchBody,
      }
    : undefined;
  logger.debug(`PATCH request URL: ${url}`);
  let response;
  try {
    response = await API.patch(API_ORGAN, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiOrganPut = async (url, putBody) => {
  const initParam = putBody
    ? {
        body: putBody,
      }
    : undefined;
  logger.debug(`PUT request URL: ${url}`);

  let response;
  try {
    response = await API.put(API_ORGAN, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

// Transplant API Calls

export const apiTransplantGet = async (url, query) => {
  const initParam = query
    ? {
        queryStringParameters: query,
      }
    : undefined;
  logger.debug(`GET request URL: ${url}`);
  let response;
  try {
    response = await API.get(API_TRANSPLANT, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiTransplantPost = async (url, postBody) => {
  const initParam = postBody
    ? {
        body: postBody,
      }
    : undefined;
  logger.debug(`POST request URL: ${url}`);
  let response;
  try {
    response = await API.post(API_TRANSPLANT, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiTransplantPatch = async (url, patchBody) => {
  const initParam = patchBody
    ? {
        body: patchBody,
      }
    : undefined;
  logger.debug(`PATCH request URL: ${url}`);
  let response;
  try {
    response = await API.patch(API_TRANSPLANT, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiTransplantPut = async (url, putBody) => {
  const initParam = putBody
    ? {
        body: putBody,
      }
    : undefined;
  logger.debug(`PUT request URL: ${url}`);

  let response;
  try {
    response = await API.put(API_TRANSPLANT, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

// EyeBank API Calls

export const apiEyebankGet = async (url, query) => {
  const initParam = query
    ? {
        queryStringParameters: query,
      }
    : undefined;
  logger.debug(`GET request URL: ${url}`);
  let response;
  try {
    response = await API.get(API_EYEBANK, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiEyebankPost = async (url, postBody) => {
  const initParam = postBody
    ? {
        body: postBody,
      }
    : undefined;
  logger.debug(`POST request URL: ${url}`);
  let response;
  try {
    response = await API.post(API_EYEBANK, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiEyebankPatch = async (url, patchBody) => {
  const initParam = patchBody
    ? {
        body: patchBody,
      }
    : undefined;
  logger.debug(`PATCH request URL: ${url}`);
  let response;
  try {
    response = await API.patch(API_EYEBANK, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiEyebankPut = async (url, putBody) => {
  const initParam = putBody
    ? {
        body: putBody,
      }
    : undefined;
  logger.debug(`PUT request URL: ${url}`);

  let response;
  try {
    response = await API.put(API_EYEBANK, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

// Tissue API Calls

export const apiTissueGet = async (url, query) => {
  const initParam = query
    ? {
        queryStringParameters: query,
      }
    : undefined;
  logger.debug(`GET request URL: ${url}`);
  let response;
  try {
    response = await API.get(API_TISSUE, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiTissuePost = async (url, postBody) => {
  const initParam = postBody
    ? {
        body: postBody,
      }
    : undefined;
  logger.debug(`POST request URL: ${url}`);
  let response;
  try {
    response = await API.post(API_TISSUE, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiTissuePatch = async (url, patchBody) => {
  const initParam = patchBody
    ? {
        body: patchBody,
      }
    : undefined;
  logger.debug(`PATCH request URL: ${url}`);
  let response;
  try {
    response = await API.patch(API_TISSUE, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiTissuePut = async (url, putBody) => {
  const initParam = putBody
    ? {
        body: putBody,
      }
    : undefined;
  logger.debug(`PUT request URL: ${url}`);

  let response;
  try {
    response = await API.put(API_TISSUE, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

// Resource API Calls

export const apiResourceGet = async (url, query) => {
  const initParam = query
    ? {
        queryStringParameters: query,
      }
    : undefined;
  logger.debug(`GET request URL: ${url}`);
  let response;
  try {
    response = await API.get(API_RESOURCES, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiResourcePost = async (url, postBody) => {
  const initParam = postBody
    ? {
        body: postBody,
      }
    : undefined;
  logger.debug(`POST request URL: ${url}`);
  let response;
  try {
    response = await API.post(API_RESOURCES, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiResourcePatch = async (url, patchBody) => {
  const initParam = patchBody
    ? {
        body: patchBody,
      }
    : undefined;
  logger.debug(`PATCH request URL: ${url}`);
  let response;
  try {
    response = await API.patch(API_RESOURCES, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiResourcePut = async (url, putBody) => {
  const initParam = putBody
    ? {
        body: putBody,
      }
    : undefined;
  logger.debug(`PUT request URL: ${url}`);

  let response;
  try {
    response = await API.put(API_RESOURCES, url, initParam);
  } catch (err) {
    let errMessage = err.message;
    let errObj = err;
    if (err.response && err.response.data) {
      errMessage = err.response.data.message;
      const { request, ...others } = err.response;
      errObj = others;
    }
    logger.error(errObj);
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

// Allocation API Calls

export const apiAllocationGet = async (url, query) => {
  const initParam = query
    ? {
        queryStringParameters: query,
      }
    : undefined;
  logger.debug(`GET request URL: ${url}`);
  let response;
  try {
    response = await API.get(API_ALLOCATION, url, initParam);
  } catch (err) {
    logger.error(err.response);
    let errMessage = 'Service Error';
    errMessage = err.response && err.response.data ? err.response.data.message : err.message;
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiAllocationPost = async (url, postBody) => {
  const initParam = postBody
    ? {
        body: postBody,
      }
    : undefined;
  logger.debug(`POST request URL: ${url}`);
  let response;
  try {
    response = await API.post(API_ALLOCATION, url, initParam);
  } catch (err) {
    logger.error(err.response);
    logger.error(err);
    let errMessage = 'Service Error';
    errMessage = err.response && err.response.data ? err.response.data.message : err.message;
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiAllocationPatch = async (url, patchBody) => {
  const initParam = patchBody
    ? {
        body: patchBody,
      }
    : undefined;
  logger.debug(`PATCH request URL: ${url}`);
  let response;
  try {
    response = await API.patch(API_ALLOCATION, url, initParam);
  } catch (err) {
    logger.error(err.response);
    let errMessage = 'Service Error';
    errMessage = err.response && err.response.data ? err.response.data.message : err.message;
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const apiAllocationPut = async (url, putBody) => {
  const initParam = putBody
    ? {
        body: putBody,
      }
    : undefined;
  logger.debug(`PUT request URL: ${url}`);

  let response;
  try {
    response = await API.put(API_ALLOCATION, url, initParam);
  } catch (err) {
    logger.error(err.response);
    let errMessage = 'Service Error';
    errMessage = err.response && err.response.data ? err.response.data.message : err.message;
    throw new Error(errMessage);
  }
  logger.debug(response);
  return response.result;
};

export const getUserTypeLabel = (hospitalId, userType) => {
  if (hospitalId === ID_ADMIN) {
    return Object.values(ROLE_ADMIN).filter((u) => u.value === userType)[0].label;
  }
  if (hospitalId === ID_DHS) {
    return Object.values(USERTYPE_DHS).filter((u) => u.value === userType)[0].label;
  }
  if (hospitalId === ID_DMER) {
    return Object.values(USERTYPE_DMERINCHARGE).filter((u) => u.value === userType)[0].label;
  }
  if (hospitalId === ID_INSPECTOR) {
    return Object.values(USERTYPE_INSPECTOR).filter((u) => u.value === userType)[0].label;
  }
  return Object.values(USERTYPE_HSPTLIC).filter((u) => u.value === userType)[0].label;
};

export const isNextSigner = (form12SigningRole, signatures) => {
  if (!isDhsUserType(form12SigningRole)) {
    return false;
  }
  if (!Array.isArray(signatures)) {
    return false;
  }
  const activeSignatures = signatures.filter((s) => s.active);
  const roleId = Object.values(SIGNINGROLE_REGN_DHS).filter((v) => v.value === form12SigningRole)[0]
    .id;
  if (roleId === SIGNINGROLE_REGN_DHS.SUPERINTENDENT.id) {
    return true;
  }

  return activeSignatures.filter((s) => parseInt(s.signingRoleIdx, 10) === roleId + 1).length > 0;
};

export const makeCancellable = (promise) => {
  let hasCancelled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCancelled_ ? reject({ isCancelled: true }) : resolve(val)),
      (error) => (hasCancelled_ ? reject({ isCancelled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCancelled_ = true;
    },
    hasCancelled_,
  };
};
