import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import classnames from 'classnames';
import Joi from 'joi';
import { VALIDATION } from '../../constants';

class ValidatedTelInput extends Component {
  render() {
    const {
      value,
      onValidation,
      placeholder,
      showValid,
      id,
      name,
      onChange,
      ...other
    } = this.props;
    // eslint-disable-next-line prefer-const
    let { validation, schema, ...otherProps } = other;
    let valid = true;
    let messages = [];
    let joiError = null;
    if (validation === true) {
      validation = VALIDATION.ALL;
    } else if (validation === false) {
      validation = VALIDATION.OFF;
    }
    if (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && this.isDirty)) {
      if (!schema || !schema.isJoi) {
        schema = Joi.string().regex(new RegExp('\\b(\\+91(-)?|91(-)?|0(-)?)?(9|8|7)[0-9]{9}\\b'));
      }
      if (!schema._getLabel()) {
        schema = schema.label(placeholder);
      }
      const { error } = schema.validate(value);
      if (error) {
        valid = false;
        messages = error.details.map(d => d.message);
        joiError = error;
      }
      onValidation(valid, messages, joiError);
    } else {
      valid = true;
      messages = [];
    }

    return (
      <React.Fragment>
        <IntlTelInput
          {...otherProps}
          fieldName={name}
          fieldId={id}
          value={value}
          onPhoneNumberChange={(status, ...params) => {
            if (!this.isDirty) {
              this.isDirty = true;
            }
            onChange(...params);
          }}
          defaultCountry="in"
          allowDropdown={false}
          inputClassName={classnames('form-control', {
            'is-invalid': validation && !valid,
            'is-valid': validation && showValid && valid
          })}
          placeholder={placeholder}
        />
        <p
          className={classnames({
            'invalid-feedback': validation && !valid,
            'valid-feedback': validation && showValid && valid
          })}
          style={
            (validation && !valid) || (validation && showValid && valid)
              ? { display: 'block' }
              : { display: 'none' }
          }
        >
          {'Should be a valid number'}
        </p>
      </React.Fragment>
    );
  }
}
ValidatedTelInput.propTypes = {
  value: PropTypes.string,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  schema: PropTypes.instanceOf(Joi.constructor),
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  showValid: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string
};
ValidatedTelInput.defaultProps = {
  value: undefined,
  validation: false,
  schema: undefined,
  onChange: () => 'onChange STUB',
  onValidation: () => 'onValidation STUB',
  showValid: false,
  placeholder: 'Value',
  id: undefined,
  name: undefined
};

export default ValidatedTelInput;
