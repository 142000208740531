import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, Typography, Button, Grid } from '@material-ui/core';
import Viewer from 'react-viewer';
import isEqual from 'lodash.isequal';
import { FILETYPES } from './ValidatedAttachment';
import AdobeViewSdkClient from './adobeViewSdkClient';

function AttachmentViewer({ fetchedFile, showPreview, setShowPreview, fetchFile }) {
  const [imgViewerOpen, setImgViewerOpen] = useState(true);
  const viewSdkClient = useRef(new AdobeViewSdkClient());
  const previewPdfCalled = useRef(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Close img viewer then stop
    // preview
    if (!imgViewerOpen) {
      setShowPreview(false);
    }
  }, [imgViewerOpen]);
  useEffect(() => {
    if (!showPreview) {
      setImgViewerOpen(true);
    }
  }, [showPreview]);

  if (!showPreview) {
    return null;
  }

  //

  if (fetchedFile.loadingStatus === 'LOADING') {
    return (
      <Backdrop open={true} onClick={() => setShowPreview(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (fetchedFile.loadingStatus === 'ERROR') {
    return (
      <Backdrop open={true} onClick={() => setShowPreview(false)}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="h6" style={{ color: '#FFFFFF' }}>
            Error Loading File!
          </Typography>
          <Button variant="outlined" style={{ color: '#FFFFFF' }} onClick={() => fetchFile()}>
            Please click here to retry
          </Button>
        </Grid>
      </Backdrop>
    );
  }

  if (fetchedFile.mimeType === FILETYPES.PDF.mimeType) {
    const fileBlob = fetchedFile.blob;
    if (!fileBlob) {
      return (
        <Backdrop open={true} onClick={() => setShowPreview(false)}>
          <Grid container direction="column" justify="center" alignItems="center">
            <Typography variant="h6" style={{ color: '#FFFFFF' }}>
              Error Previewing File!
            </Typography>
            <Button variant="outlined" style={{ color: '#FFFFFF' }} onClick={() => fetchFile()}>
              Please click here to retry
            </Button>
          </Grid>
        </Backdrop>
      );
    }
    viewSdkClient.current.ready().then(() => {
      if (previewPdfCalled.current) {
        return;
      }
      previewPdfCalled.current = true;
      viewSdkClient.current.previewFileUsingFilePromise(
        'ayudaan-pdf-preview',
        fileBlob.arrayBuffer(),
        fetchedFile.fileName || 'Upload.pdf',
        {
          /* Pass the embed mode option here */
          embedMode: 'LIGHT_BOX',
        }
      );
      viewSdkClient.current.registerEventsHandler((event) => {
        if (event.type === 'PDF_VIEWER_CLOSE') {
          previewPdfCalled.current = false;
          setTimeout(() => {
            setShowPreview(false);
          }, 1000);
        }
      });
    });
    return <div id="ayudaan-pdf-preview" />;
  }

  if ([FILETYPES.PNG.mimeType, FILETYPES.JPEG.mimeType].includes(fetchedFile.mimeType)) {
    const fileDownloadUrl = fetchedFile.downloadUrl;
    if (!fileDownloadUrl) {
      return (
        <Backdrop open={true} onClick={() => setShowPreview(false)}>
          <Grid container direction="column" justify="center" alignItems="center">
            <Typography variant="h6" style={{ color: '#FFFFFF' }}>
              Error Previewing File!
            </Typography>
            <Button variant="outlined" style={{ color: '#FFFFFF' }} onClick={() => fetchFile()}>
              Please click here to retry
            </Button>
          </Grid>
        </Backdrop>
      );
    }
    return (
      <Viewer
        visible={imgViewerOpen}
        onClose={() => {
          setImgViewerOpen(false);
        }}
        noNavbar
        zIndex={1202}
        images={[{ src: fileDownloadUrl, alt: fetchedFile.fileName }]}
      />
    );
  }

  return (
    <Backdrop style={{ zIndex: 1202 }} open={open} onClick={handleClose}>
      <iframe
        title="docViewer"
        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
          fetchedFile.downloadUrl
        )}&embedded=true`}
        style={{ width: '100%', height: '780px' }}
      />{' '}
    </Backdrop>
  );
}
AttachmentViewer.propTypes = {
  fetchedFile: PropTypes.shape({
    loadingStatus: PropTypes.string,
    downloadUrl: PropTypes.string,
    fileName: PropTypes.string,
    mimeType: PropTypes.string,
    blob: PropTypes.string,
    blobUrl: PropTypes.string,
  }).isRequired,
  setShowPreview: PropTypes.func.isRequired,
  showPreview: PropTypes.bool.isRequired,
  fetchFile: PropTypes.func.isRequired,
};
export default React.memo(AttachmentViewer, function areEqual(prevProps, nextProps) {
  if (
    !isEqual(prevProps?.fetchedFile, nextProps?.fetchedFile) ||
    prevProps?.showPreview !== nextProps?.showPreview
  ) {
    return false;
  }
  return true;
});
