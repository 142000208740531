import React, { Component } from 'react';
// import { Col, Row, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';

class SecurityProof extends Component {
  constructor() {
    super();
    this.state = {
      proofPopover: false,
    };
  }

  _toggleProofPopover(show) {
    return this.setState({ proofPopover: show });
  }

  render() {
    const { proofPopover } = this.state;
    const { tx } = this.props;
    return null;
    // return (
    //   <React.Fragment>
    //     {tx ? (
    //       <React.Fragment>
    //         <i
    //           id={`sign-${tx}`}
    //           className="fa fa-lock fa-lg"
    //           onClick={() => this._toggleProofPopover(true)}
    //           style={{
    //             color: 'green'
    //           }}
    //           role="button"
    //           tabIndex="0"
    //         />
    //         <Popover
    //           placement="bottom"
    //           target={`sign-${tx}`}
    //           isOpen={proofPopover}
    //           toggle={() => this._toggleProofPopover(false)}
    //         >
    //           <PopoverHeader>Info</PopoverHeader>
    //           <PopoverBody>
    //             <Row>
    //               <Col className="mx-2">
    //                 <QRCode value={tx} />
    //               </Col>
    //             </Row>
    //             {/* <Row>
    //               <Col lg="12" className="text-center mt-3">
    //                 <a target="_blank" rel="noopener noreferrer" href={URL_ETHERSCAN}>
    //                   Transaction Details
    //                 </a>
    //               </Col>
    //             </Row> */}
    //           </PopoverBody>
    //         </Popover>
    //       </React.Fragment>
    //     ) : (
    //       <i
    //         id="insecure"
    //         className="fa fa-unlock fa-lg"
    //         style={{
    //           color: 'red'
    //         }}
    //         role="button"
    //         tabIndex="0"
    //       />
    //     )}
    //   </React.Fragment>
    // );
  }
}
SecurityProof.propTypes = {
  tx: PropTypes.string,
};
SecurityProof.defaultProps = {
  tx: null,
};

export default SecurityProof;
