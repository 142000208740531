import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { BLOODGROUP, FORMAT_DATE_UNIXTS } from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class SuperUrgentHeartFormPdf {
  formData = {};

  constructor(formData) {
    const {
      basicDetails,
      heartDetails,
      contactEmailIds,
      heartDetails: {
        superUrgentInfo,
        generalInfo,

        superUrgentInfo: {
          mechanicalCirculatorySupportLessThan30Days,
          mechanicalCirculatorySupportMoreThan30Days,
          mechanicalCirculatoryWithHighInotropeSupport,
          dateOfVentilation,
          sepsisReport,
          cultureReport,
          coagulationProfileReport,
          patientAdmissionfile,
          treatingDoctorClinicalSummaryReport,
          liverFunctionalReport,
          kidneyFucntionalReport,
          infectionProfileReport,
        },
      },

      contactNos,

      hospitalName,
      ztccZone,
    } = formData;
    this.formData = formData;

    const _yesNoAndEmptyFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };

    const calculateCurrentAge = () => {
      if (basicDetails.dateOfBirth) {
        const duration = moment.duration(
          moment().diff(moment(basicDetails.dateOfBirth, FORMAT_DATE_UNIXTS))
        );
        return `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`;
      }
      if (basicDetails.currentAge) {
        return basicDetails.currentAge;
      }
      return '-';
    };

    const _yesNoAndEmptyBooleanFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };

    const _nationalityFormatter = (value) => {
      if (value === 'INDIAN_NATIONAL') {
        return 'Indian';
      }
      if (value === 'OCI_CARDHOLDER') {
        return 'Overseas Citizen of India (OCI) card holder';
      }
      if (value === 'FOREIGN_NATIONAL') {
        return 'Foreign national';
      }
      return '-';
    };

    const _getMcsDate = (value) => {
      if (value) {
        return moment(value, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY');
      }

      return '-';
    };

    const _getMcsCannulaeSites = (value) => {
      if (value) {
        return value;
      }
      return '-';
    };
    const _getZtccZoneLabelById = (zone) => {
      if (zone === '7') {
        return 'ZTCC MUMBAI';
      }
      if (zone === '8') {
        return 'ZTCC PUNE';
      }
      if (zone === '9') {
        return 'ZTCC NAGPUR';
      }
      if (zone === '10') {
        return 'ZTCC AURANGABAD';
      }
      if (zone) {
        return zone;
      }

      return '';
    };

    const reportDocDefinition = {
      content: [
        {
          text: '',
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              10,
              20,
              'auto',
              'auto',
              'auto',
              'auto',
              10,
              'auto',
              'auto',
              'auto',
              40,
              40,
              'auto',
              'auto',
              40,
              40,
              'auto',
            ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'State Appropriate Authority of Maharashtra',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: 'SUPER URGENT HEART REGISTRATION',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `ZTCC Zone: ${_getZtccZoneLabelById(ztccZone)}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date of Registration : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.dateOfRegistration
                      ? moment(basicDetails?.dateOfRegistration, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Name:`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.name ? basicDetails?.name : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `NOTTO ID : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.nottoId}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Birth: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.dateOfBirth
                      ? moment(basicDetails?.dateOfBirth, FORMAT_DATE_UNIXTS).format('DD MMMM YYYY')
                      : '-'
                  } `,

                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Age: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${calculateCurrentAge()}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Gender: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.sex ? basicDetails?.sex : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Group: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Weight (Kg): `,
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.weightKg}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in cm):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightCm}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in inches):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightIn ? basicDetails?.heightIn : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${hospitalName || '-'}`, colSpan: 2 },
                {},
              ],

              [
                {
                  text: `Address & Telephone No: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.presentAddress ? basicDetails?.presentAddress : '-'} - ${
                    contactNos.length > 0 ? contactNos[0] : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Patient contact emails:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${contactEmailIds.length > 0 ? contactEmailIds[0] : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Economic Status: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.economicStatus ? basicDetails?.economicStatus : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Occupation',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.occupation}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_nationalityFormatter(basicDetails?.nationality)}`,
                  colSpan: 2,
                },
                {},
              ],
              ...(basicDetails?.nationality !== 'INDIAN_NATIONAL'
                ? [
                    [
                      {
                        text: `Indian Contact Number : `,
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${
                          basicDetails?.indianContactNumber
                            ? basicDetails?.indianContactNumber
                            : '-'
                        }`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              [
                {
                  text: `Country of Residence: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.countryOfResidence ? basicDetails?.countryOfResidence : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              

              [
                {
                  text: 'UHID',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.uhid}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Present Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.presentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Permanent Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.permanentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Date of Admission / OPD',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.dateOfAdmission
                      ? moment(basicDetails?.dateOfAdmission, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Primary physician Name:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.primaryPhysicianName}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Primary physician contact numbers:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.primaryPhysicianContactNo
                      ? basicDetails?.primaryPhysicianContactNo
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary physician contact email:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.primaryPhysicianEmail ? basicDetails?.primaryPhysicianEmail : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary Questions Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient have active / metastatic malignancy?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMalignancy
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active MDR infection with sepsis or septic shock?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMdrInfection
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have severe irreversible systemic disease (cardiac, pulmonary, neurological or others with very short longevity)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noSevereIrreversibleSystemicDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active or recent major unresolved psychiatric illness?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noMajorUnresolvedPsychiatricIllness
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have high risk of non-compliance with long-term immunosuppression?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noHighRiskOfNonCompliance
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have adequate social or family support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(basicDetails?.prerequisites?.familySupport)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Identity Proof Document Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Document Type',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentType}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identify Proof Document Number',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentNumber}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identity Proof Document Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
             
              
            ],
          },
        },
        {
          text: 'Family Member Details',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Family Members Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Relation With Patient', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Number', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Email', style: 'tableHeader', alignment: 'left' },
              ],
              ...basicDetails.familyMembers.map((a) => [
                { text: `${a.name ? a.name : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.relation ? a.relation : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.contact ? a.contact : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.email ? a.email : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
        },
        {
          text: 'General Information',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'General Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'Is Patient Willing to Accept ECD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'ECD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Is Patient Willing to Accept DCD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'DCD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Has the patient been registered in Maharashtra in Past:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.pastZtccListingInfo?.pastZtccListing
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Status',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastListingStatus}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Further Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastZtccDetails}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'HEART FORM REGISTRATION',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: '2D Echo Report:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${generalInfo?.echo2d || '-'} -
                  ${generalInfo?.echo2dReport?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Patient Admission File',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${patientAdmissionfile?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Treating Doctor Clinical Summary Report ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${treatingDoctorClinicalSummaryReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have Severe Cardiac Cachexia: Nephropathy, Neuropathy etc?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    heartDetails?.generalInfo?.severeCardiacCachexia
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Does the patient have Diabetes with end organ diseases?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    heartDetails?.generalInfo?.diabetesWithEndOrganDiseases
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Baselin GFR (ml/min)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    heartDetails?.generalInfo?.baselineGfr
                      ? heartDetails?.generalInfo?.baselineGfr
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Baseline serum creatinine (mg/dl)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    heartDetails?.generalInfo?.baselineSerumCreatinine
                      ? heartDetails?.generalInfo?.baselineSerumCreatinine
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Does the patient have Severe cerebrovascular disease?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    heartDetails?.generalInfo?.severeCerebrovascularDisease
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Sereve Obesity BMI',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    heartDetails?.generalInfo?.severeObesity
                      ? heartDetails?.generalInfo?.severeObesity
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Does the patient have Severe primary pulmonary disease?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    heartDetails?.generalInfo?.severePrimaryPulmonaryDisease
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Does the patient have Pulmonary Hypertension PASP > 50 mmHg unresponsive to vasodilator challenge?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    heartDetails?.generalInfo?.pulmonaryHypertension
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `HBs AG Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    heartDetails?.generalInfo?.viralMarkerStatus?.hbsagRemark
                      ? heartDetails?.generalInfo?.viralMarkerStatus?.hbsagRemark
                      : '-'
                  }
                  ${
                    heartDetails?.generalInfo?.viralMarkerStatus?.hbsagReportDate
                      ? moment(
                          heartDetails?.generalInfo?.viralMarkerStatus?.hbsagReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD MMMM YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Anti HCV Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    heartDetails?.generalInfo?.viralMarkerStatus?.antiHcvRemark
                      ? heartDetails?.generalInfo?.viralMarkerStatus?.antiHcvRemark
                      : '-'
                  }
                  ${
                    heartDetails?.generalInfo?.viralMarkerStatus?.antiHcvReportDate
                      ? moment(
                          heartDetails?.generalInfo?.viralMarkerStatus?.antiHcvReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD MMMM YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE' ? 'NOT DONE' : (heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark || '-')}
                  ${
                    heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                      ? moment(
                          heartDetails?.generalInfo?.viralMarkerStatus.hcvRnaQuantitativeReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD MMMM YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE' ? 'NOT DONE' : (heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark || '-')}
                  ${
                    heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaReportDate
                      ? moment(
                          heartDetails?.generalInfo?.viralMarkerStatus.hcvDnaReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD MMMM YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HIV Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    heartDetails?.generalInfo?.viralMarkerStatus?.hivRemark
                      ? heartDetails?.generalInfo?.viralMarkerStatus?.hivRemark
                      : '-'
                  }
                  ${
                    heartDetails?.generalInfo?.viralMarkerStatus?.hivReportDate
                      ? moment(
                          heartDetails?.generalInfo?.viralMarkerStatus?.hivReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD MMMM YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Super Urgent ',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],


              [
                {
                  text: 'Is the Recipient requires mechanical circulatory support (less than 30 days)? ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    mechanicalCirculatorySupportLessThan30Days?.mechanicalCirculatorySupportLessThan30DaysYesNo
                  )} - ${
                    mechanicalCirculatorySupportLessThan30Days?.mechanicalCirculatorySupportLessThan30DaysDetails
                      ? mechanicalCirculatorySupportLessThan30Days?.mechanicalCirculatorySupportLessThan30DaysDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Date of MCS Initiated',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_getMcsDate(
                    mechanicalCirculatorySupportLessThan30Days.dateOfMcsInitiated
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Cannulae Sites',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_getMcsCannulaeSites(
                    mechanicalCirculatorySupportLessThan30Days?.cannulaeSites
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Is the Recipient requires mechanical circulatory support ( for more than 30 days)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    mechanicalCirculatorySupportMoreThan30Days?.mechanicalCirculatorySupportMoreThan30DaysYesNo
                  )} - ${
                    mechanicalCirculatorySupportMoreThan30Days.mechanicalCirculatorySupportMoreThan30DaysDetails
                      ? mechanicalCirculatorySupportMoreThan30Days.mechanicalCirculatorySupportMoreThan30DaysDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Date of MCS Initiated',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_getMcsDate(
                    mechanicalCirculatorySupportMoreThan30Days?.dateOfMcsInitiated
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Cannulae Sites',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_getMcsCannulaeSites(
                    mechanicalCirculatorySupportMoreThan30Days?.cannulaeSites
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the Recipient require mechanical ventilation with high inotropic support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    mechanicalCirculatoryWithHighInotropeSupport
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Date of Ventilation',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    dateOfVentilation
                      ? moment(dateOfVentilation, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the Recipient have any evidence of Sepsis?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(superUrgentInfo.sepsis)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Sepsis Report',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${sepsisReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Date of Sepsis Report',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    superUrgentInfo?.sepsisReportDate
                      ? moment(superUrgentInfo?.sepsisReportDate, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the Recipient have any evidence of Culture?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(superUrgentInfo?.culture)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Culture Report',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${cultureReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Date of Culture Report',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    superUrgentInfo?.cultureReportDate
                      ? moment(superUrgentInfo?.cultureReportDate, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Inotropes details with doses(mcg/kg/min)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    superUrgentInfo?.inotropeDetails ? superUrgentInfo?.inotropeDetails : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Reports Coagulation Profile',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${coagulationProfileReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
             
              [
                {
                  text: 'Other Reports: \n Reports Kidney Functional Test \n Reports Liver Functional Test \n Infection Profile Report ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `\n ${liverFunctionalReport?.name ? 'ATTACHED' : '-'} - 
                ${kidneyFucntionalReport?.name ? 'ATTACHED' : '-'} - 
                ${infectionProfileReport?.name ? 'ATTACHED' : '-'}`,

                  colSpan: 2,
                },
                {},
              ],
              
              
            ],
          },
        },
        {
          text: 'Any Other Attachments',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...heartDetails?.otherAttachments?.map((e) => [
                {
                  text: `${e.attachmentName ? e.attachmentName : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Etiology of ESKD', style: 'tableHeader', alignment: 'left' },
                { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...heartDetails.etiologies.map((e) => [
                {
                  text: `${e.etiology ? e.etiology : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Cardiologist', style: 'tableHeader', alignment: 'left' },

                { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
              ],
              [
                (heartDetails?.cardiologistTeam || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
                (heartDetails?.transplantSurgeon || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
              ],
            ],
          },
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150],
            heights: [0, 50, 50, 50, 50, 50],
            body: [
              [
                {
                  text: 'All verification of the clinical details / reports / criteria eligibility to justify above criteria is the responsibility of the transplant team signing this form. \n1. All the reports (Lab reports + imaging report) should be within 1 month at the time of registration. The patients with older reports should not be considered for registration.\n2. All lab reports should be within 1 month and imaging should be within 3 months for allocation, patients with older reports should not be considered for allocation.',
                  style: 'tableHeader',
                  colSpan: 3,
                  // alignment: 'center',
                  // bold: true,
                  fontSize: 11,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Patient Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Surgeon Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Physician Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Transplant Coordinator Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Signature of Hospital Head:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Super Urgent Heart Form`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default SuperUrgentHeartFormPdf;
