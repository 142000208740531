export const DISTRICTS_TN = [
  {
    label: 'Ariyalur',
    value: 'ARIYALUR'
  },
  {
    label: 'Chengalpattu',
    value: 'CHENGALPATTU'
  },
  {
    label: 'Chennai',
    value: 'CHENNAI'
  },
  {
    label: 'Coimbatore',
    value: 'COIMBATORE'
  },
  {
    label: 'Cuddalore',
    value: 'CUDDALORE'
  },
  {
    label: 'Dharmapuri',
    value: 'DHARMAPURI'
  },
  {
    label: 'Dindigul',
    value: 'DINDIGUL'
  },
  {
    label: 'Erode',
    value: 'ERODE'
  },
  {
    label: 'Kallakurichi',
    value: 'KALLAKURICHI'
  },
  {
    label: 'Kanchipuram',
    value: 'KANCHIPURAM'
  },
  {
    label: 'Kanyakumari',
    value: 'KANYAKUMARI'
  },
  {
    label: 'Karur',
    value: 'KARUR'
  },
  {
    label: 'Krishnagiri',
    value: 'KRISHNAGIRI'
  },
  {
    label: 'Madurai',
    value: 'MADURAI'
  },
  {
    label: 'Nagapattinam',
    value: 'NAGAPATTINAM'
  },
  {
    label: 'Namakkal',
    value: 'NAMAKKAL'
  },
  {
    label: 'Nilgiris',
    value: 'NILGIRIS'
  },
  {
    label: 'Perambalur',
    value: 'PERAMBALUR'
  },
  {
    label: 'Pudukkottai',
    value: 'PUDUKKOTTAI'
  },
  {
    label: 'Ramanathapuram',
    value: 'RAMANATHAPURAM'
  },
  {
    label: 'Ranipet',
    value: 'RANIPET'
  },
  {
    label: 'Salem',
    value: 'SALEM'
  },
  {
    label: 'Sivaganga',
    value: 'SIVAGANGA'
  },
  {
    label: 'Tenkasi',
    value: 'TENKASI'
  },
  {
    label: 'Thanjavur',
    value: 'THANJAVUR'
  },
  {
    label: 'Theni',
    value: 'THENI'
  },
  {
    label: 'Thoothukudi (Tuticorin)',
    value: 'THOOTHUKUDI'
  },
  {
    label: 'Tiruchirappalli',
    value: 'TIRUCHIRAPPALLI'
  },
  {
    label: 'Tirunelveli',
    value: 'TIRUNELVELI'
  },
  {
    label: 'Tirupathur',
    value: 'TIRUPATHUR'
  },
  {
    label: 'Tiruppur',
    value: 'TIRUPPUR'
  },
  {
    label: 'Tiruvallur',
    value: 'TIRUVALLUR'
  },
  {
    label: 'Tiruvannamalai',
    value: 'TIRUVANNAMALAI'
  },
  {
    label: 'Tiruvarur',
    value: 'TIRUVARUR'
  },
  {
    label: 'Vellore',
    value: 'VELLORE'
  },
  {
    label: 'Viluppuram',
    value: 'VILUPPURAM'
  },
  {
    label: 'Virudhunagar',
    value: 'VIRUDHUNAGAR'
  }
];
