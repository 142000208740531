import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  Backdrop,
  IconButton,
  Paper,
  Box,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { SignIn } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import LightningBoltIcon from 'mdi-react/LightningBoltIcon';
import * as log from 'loglevel';
import CloseIcon from 'mdi-react/CloseIcon';
import cloneDeep from 'lodash.clonedeep';
import arogyaLogo from './arogyalogo_min.png';
import rottoLogo from './rottologo_min.jpg';
import hospitalLogo from './hospitallogo.png';
import dmerLogo from './DMER-logo.png';
import ztccLogo from './ztcclogo.png';
import maharashtraLogo from './maharashtraemblem_min.png';
import uddhavthackarey from './keypersons/uddhavthackarey.jpg';
import ajitpawar from './keypersons/ajitpawar.jpg';
import rajeshtope from './keypersons/rajeshtope.jpeg';
import rajendrapatilyadravkar from './keypersons/rajendrapatilyadravkar.jpg';
import pradeepvyas from './keypersons/pradeepvyas.jpg';
import kerketta from './keypersons/kerketta.jpeg';
import nramaswami from './keypersons/nramaswami.jpg';
import { login, logout } from './loginScripts';
import donorFormImage from './heartinhands.jpg';
import { makeCancellable } from '../../utils';
import { REGEX_ILLEGALCHARS, isTestEnv, isDevEnv, isProdEnv, BLOODGROUP } from '../../constants';
import { ValidatedSelect } from '../Custom';
import AreaSelector from './AreaSelector';

// import ValidatedHospitalInput from '../../components/BsdDonor/ValidatedHospitalInput';
import {
  PATH_EXP_GETHOSPITALS,
  PATH_GETHOSPITALS,
  PATH_GETWAITINGLISTRANK,
  PATH_OTP_REQUESTOTP,
  PATH_TRANSFER_OUT_REGISTRATION_REQUEST,
  PATH_DISTRICTWISE_DATA,
} from '../../appConstants';
import DonorProfile from '../../components/DonorProfile/DonorProfile';
import Form7 from '../../components/Form7/Form7';
import StyledDialogTitle from '../../components/common/StyledDialogTitle';
import MlasScoring from '../../components/MlasScoring/MlasScoring';
import AllocationDonor from '../../components/Allocation/Donor/AllocationDonor';
import ValidatedHospitalInput from '../../components/BsdDonor/ValidatedHospitalInput/ValidatedHospitalInput';
import { ReactTable } from '../../components/Custom';
import StatisticsData from '../../components/StatisticsData/StatisticsData';

const EMPTY_OTPDATA = {
  contact: '',
  hash: '',
  otp: '',
};

const logger = log.getLogger('Login');

const useStyles = (theme) => ({
  listScroll: {
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: '#F1F1F1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'grey',
      borderRadius: 5,
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  leftPadding: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 35,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
});

class Login extends SignIn {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      registerDialogOpen: false,
      cancellablePromises: [],
      spinner: false,
      visibleBackdrop: {},
      waitingListSearch: false,
      waitingListData: {
        nottoId: '',
        contactNo: '',
        bloodGroup: '',
      },
      transferRegistrationData: {
        nottoId: '',
        contactNo: '',
        bloodGroup: '',
        aadharNo: '',
        fromHospital: '',
        toHospital: '',
      },
      getWaitingListRank: 'APIID',
      waitingListDialogOpen: false,
      transferRegistrationListDialogOpen: false,
      waitingListMsg: '',
      otpData: cloneDeep(EMPTY_OTPDATA),
      getOtpApiId: 'APIID',
      hospitals: [],
      transferOutRequestSubmitted: false,
      transferOutRequestAcknowledgementNumber: '',
      selectedDistict: ' ',

      districtData: {
        totalTransplantCenters: 0,
        totalRetrievalCenters: 0,
        totalRecipientOnWaitingList: 0,
        totalDonorsTillDate: 0,
        organwiseRecipient: '',
      },
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.setSelectedDistrict = this.setSelectedDistrict.bind(this);
    this.getAllHospitals();
  }

  setSelectedDistrict(district) {
    const { selectedDistict } = this.state;
    this.setState({
      selectedDistict: district,
    });
  }

  toggleDropdown() {
    const { isDropdownOpen } = this.state;
    this.setState({ isDropdownOpen: !isDropdownOpen });
  }

  _renderKeyPerson(src, name, position) {
    const { visibleBackdrop } = this.state;
    return (
      // <Grid item container justify="center" xs={12}>
      <Grid
        key={src}
        item
        container
        justify="center"
        xs={12}
        style={{ position: 'relative' }}
        onMouseOver={() => {
          visibleBackdrop[name] = true;
          this.setState({
            visibleBackdrop: { ...visibleBackdrop },
          });
        }}
        onFocus={() => {
          visibleBackdrop[name] = true;
          this.setState({
            visibleBackdrop: { ...visibleBackdrop },
          });
        }}
        onMouseLeave={() => {
          visibleBackdrop[name] = false;
          this.setState({
            visibleBackdrop: { ...visibleBackdrop },
          });
        }}
        onBlur={() => {
          visibleBackdrop[name] = false;
          this.setState({
            visibleBackdrop: { ...visibleBackdrop },
          });
        }}
      >
        <Backdrop
          style={{ position: 'absolute', zIndex: 10, display: 'flex', flexDirection: 'column' }}
          open={!!visibleBackdrop[name]}
        >
          <Typography variant="subtitle2" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
            {name}
          </Typography>
          <Typography variant="caption" style={{ color: '#FFFFFF' }}>
            {position}
          </Typography>
        </Backdrop>
        <img src={src} width="150" alt={name} />
      </Grid>
      // </Grid>
    );
  }

  getAllHospitals() {
    fetch(PATH_GETHOSPITALS, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        // if (response.status === 200) {
        this.setState({
          hospitals: [...response.result],
        });
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getDistrictWiseData() {
    fetch(`${PATH_DISTRICTWISE_DATA}?district=${encodeURIComponent(this.state.selectedDistict)}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          districtData: {
            ...response.result,
          },
        });
        // console.log(response.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _renderRegisterDialog() {
    const { registerDialogOpen } = this.state;
    return (
      <Dialog
        open={registerDialogOpen}
        onClose={() => {
          this.setState({
            registerDialogOpen: false,
          });
        }}
        maxWidth="md"
        fullWidth
      >
        {/* <StyledDialogTitle>Request MahaAyudaan Access</StyledDialogTitle> */}
        <StyledDialogTitle>
          <Grid container justify="space-between" alignItems="center">
            Request MahaAyudaan Access
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                this.setState({
                  registerDialogOpen: false,
                });
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </StyledDialogTitle>
        <DialogContent style={{ padding: 24 }}>
          <Grid container item sm={12} style={{ marginBottom: 20 }}>
            <Typography variant="h6">
              To request access to the platform, please choose one of the options below.
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid
              container
              item
              sm={3}
              xs={12}
              style={{
                borderRight: 'solid',
                borderRightColor: '#C5C5C5',
                borderRightWidth: 1,
                padding: 25,
              }}
            >
              <Card>
                <CardActionArea onClick={() => this.changeState('signUp', { signupType: 'ROTTO' })}>
                  <CardMedia
                    image={rottoLogo}
                    component="img"
                    title="ROTTO SOTTO Users"
                    style={{ height: 130 }}
                  />
                  <CardContent>
                    <Typography variant="body1" component="p">
                      ROTTO/SOTTO users, Click Here
                    </Typography>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ backgroundColor: '#EC9552', color: '#FFFFFF' }}
                      onClick={() => this.changeState('signUp', { signupType: 'ROTTO' })}
                    >
                      Request Access
                    </Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              container
              item
              sm={3}
              xs={12}
              style={{
                borderRight: 'solid',
                borderRightColor: '#C5C5C5',
                borderRightWidth: 1,
                padding: 25,
              }}
            >
              <Card>
                <CardActionArea
                  onClick={() => this.changeState('signUp', { signupType: 'HOSPITAL' })}
                >
                  <CardMedia
                    image={hospitalLogo}
                    component="img"
                    title="Hospital Registration"
                    style={{ height: 130 }}
                  />
                  <CardContent>
                    <Typography variant="body1" component="p">
                      New Hospital Regn, Click Here
                    </Typography>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ backgroundColor: '#5097D6', color: '#FFFFFF' }}
                      onClick={() => this.changeState('signUp', { signupType: 'HOSPITAL' })}
                    >
                      Request Access
                    </Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              container
              item
              sm={3}
              xs={12}
              style={{
                borderRight: 'solid',
                borderRightColor: '#C5C5C5',
                borderRightWidth: 1,
                padding: 25,
              }}
            >
              <Card>
                <CardActionArea onClick={() => this.changeState('signUp', { signupType: 'ZTCC' })}>
                  <CardMedia
                    image={ztccLogo}
                    component="img"
                    title="ZTCC Users"
                    style={{ height: 130 }}
                  />
                  <CardContent>
                    <Typography variant="body1" component="p">
                      ZTCC Users, Click Here
                    </Typography>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ backgroundColor: '#25B67D', color: '#FFFFFF' }}
                      disabled={true}
                      onClick={() => this.changeState('signUp', { signupType: 'ZTCC' })}
                    >
                      Request Access
                    </Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              container
              item
              sm={3}
              xs={12}
              style={{
                // borderRight: 'solid',
                // borderRightColor: '#C5C5C5',
                // borderRightWidth: 1,
                padding: 25,
              }}
            >
              <Card>
                <CardActionArea onClick={() => this.changeState('signUp', { signupType: 'ROTTO' })}>
                  <CardMedia
                    image={dmerLogo}
                    component="img"
                    title="DMER Users"
                    style={{ height: 130 }}
                  />
                  <CardContent>
                    <Typography variant="body1" component="p">
                      DMER users, Click Here
                    </Typography>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ backgroundColor: '#EC9552', color: '#FFFFFF' }}
                      onClick={() => this.changeState('signUp', { signupType: 'ROTTO' })}
                    >
                      Request Access
                    </Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  _renderWaitingListDialog() {
    const { waitingListDialogOpen } = this.state;
    return (
      <Dialog
        open={waitingListDialogOpen}
        onClose={() => {
          this.setState({
            waitingListDialogOpen: false,
          });
        }}
        fullWidth={true}
        maxWidth="xs"
      >
        {/* <StyledDialogTitle>Request MahaAyudaan Access</StyledDialogTitle> */}
        <StyledDialogTitle>
          <Grid container justify="space-between" alignItems="center">
            Waiting List Number
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                this.setState({
                  waitingListDialogOpen: false,
                });
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </StyledDialogTitle>
        <DialogContent style={{ padding: 24 }}>
          {/* <Grid container item sm={12} style={{ marginBottom: 20 }}>
            <Typography variant="h6">{this.state.waitingListMsg}</Typography>
          </Grid> */}
          <Grid item justifyContent="center">
            {this.state.waitingListMsg !== '' ? (
              <Typography variant="h5">
                <strong>{this.state.waitingListMsg}</strong>
              </Typography>
            ) : (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
              >
                <Grid container item xs={12}>
                  <TextField
                    id="NOTTO ID"
                    key="NOTTO ID"
                    name="NOTTO ID"
                    onChange={(e) =>
                      this.setState({
                        waitingListData: { ...this.state.waitingListData, nottoId: e.target.value },
                      })
                    }
                    // style={{ width: '120%' }}
                    type="text"
                    placeholder="NottoId"
                    variant="outlined"
                    label="NOTTO ID"
                    fullWidth
                  />
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 10 }}>
                  <TextField
                    id="Patient Registered Mobile Number"
                    key="Patient Registered Mobile Number"
                    name="Patient Registered Mobile Number"
                    onChange={(e) =>
                      this.setState({
                        waitingListData: {
                          ...this.state.waitingListData,
                          contactNo: e.target.value,
                        },
                      })
                    }
                    // style={{ width: '120%' }}
                    type="text"
                    placeholder="Patient Registered Mobile Number"
                    variant="outlined"
                    label="Patient Registered Mobile Number"
                    fullWidth
                  />
                </Grid>

                <Grid container item xs={12} style={{ marginTop: 10 }}>
                  <ValidatedSelect
                    label="Blood Group"
                    value={this.state.bloodGroup}
                    options={Object.values(BLOODGROUP)}
                    // style={{ width: '120%' }}
                    onChange={(e) =>
                      this.setState({
                        waitingListData: {
                          ...this.state.waitingListData,
                          bloodGroup: e.value,
                        },
                      })
                    }
                    fullWidth
                  />{' '}
                </Grid>
                <Grid container item sm={12} style={{ marginBottom: 10 }}>
                  <Typography component="h6" style={{ color: 'red' }}>
                    <br /> The current City Rank is dynamic and is subject to change based on your
                    health and the health of other patients.
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {this.state.waitingListMsg === '' ? (
                <Button
                  variant="contained"
                  onClick={() => this.waitingList()}
                  style={{
                    backgroundColor: '#5097D6',
                    color: '#FFFFFF',
                    width: '45%',
                    marginTop: '20px',
                  }}
                >
                  Search
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  waitingList() {
    fetch(PATH_GETWAITINGLISTRANK, {
      method: 'POST',
      headers: {
        'x-rapidapi-host': 'fairestdb.p.rapidapi.com',
        'x-rapidapi-key': 'apikey',
        'content-type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify({
        nottoId: this.state.waitingListData.nottoId,
        contactNo: this.state.waitingListData.contactNo,
        bloodGroup: this.state.waitingListData.bloodGroup,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            waitingListMsg: `Your Waiting List Number is ${response.result}`,
          });
          this.setState({
            waitingListDialogOpen: true,
          });
        } else if (response.status === 400) {
          this.setState({
            waitingListMsg: `${response.message}`,
          });
          this.setState({
            waitingListDialogOpen: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          waitingListMsg: `${err.message}`,
        });
        this.setState({
          waitingListDialogOpen: true,
        });
      });
  }

  _getHospitalNames() {
    const hospitalNames = [];
    this.state.hospitals.forEach((h) => {
      hospitalNames.push({ label: h.name, value: h._id });
    });
    return hospitalNames;
  }

  _getToHospitalNames() {
    const hospitalNames = [];
    this.state.hospitals.forEach((h) => {
      hospitalNames.push({ label: h.name, value: h._id });
    });
    const hospitals = hospitalNames.filter(
      (h) => h.value !== this.state?.transferRegistrationData?.fromHospital
    );
    return hospitals;
  }

  _transferRegistrationListDialogDialog() {
    const { transferRegistrationListDialogOpen } = this.state;
    // console.log('this.state?.hospitals: ', this.state?.hospitals);

    return (
      <Dialog
        open={transferRegistrationListDialogOpen}
        onClose={() => {
          this.setState({
            transferRegistrationListDialogOpen: false,
          });
        }}
        onEnter={() => {
          this.getAllHospitals();
        }}
        fullWidth={true}
        maxWidth="xs"
      >
        {/* <StyledDialogTitle>Request MahaAyudaan Access</StyledDialogTitle> */}
        <StyledDialogTitle>
          <Grid container justify="space-between" alignItems="center">
            Transfer Registration
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                this.setState({
                  transferRegistrationListDialogOpen: false,
                });
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </StyledDialogTitle>
        <DialogContent style={{ padding: 24 }}>
          {/* <Grid container item sm={12} style={{ marginBottom: 20 }}>
            <Typography variant="h6">{this.state.waitingListMsg}</Typography>
          </Grid> */}
          {!this.state.transferOutRequestSubmitted ? (
            <Grid item justifyContent="center">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
                spacing={2}
              >
                <Grid container item xs={12}>
                  <TextField
                    id="NOTTO ID"
                    key="NOTTO ID"
                    name="NOTTO ID"
                    onChange={(e) =>
                      this.setState({
                        transferRegistrationData: {
                          ...this.state.transferRegistrationData,
                          nottoId: e.target.value,
                        },
                      })
                    }
                    // style={{ width: '120%' }}
                    type="text"
                    placeholder="NOTTO ID"
                    variant="outlined"
                    label="NOTTO ID"
                    fullWidth
                  />
                </Grid>
                <Grid container item xs={12}>
                  <TextField
                    id="Adhaar No"
                    key="Adhaar No"
                    name="Adhaar No"
                    onChange={(e) =>
                      this.setState({
                        transferRegistrationData: {
                          ...this.state.transferRegistrationData,
                          aadharNo: e.target.value,
                        },
                      })
                    }
                    // style={{ width: '120%' }}
                    type="text"
                    placeholder="Adhaar No"
                    variant="outlined"
                    label="Adhaar No"
                    fullWidth
                  />
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 10 }}>
                  <TextField
                    id="Patient Registered Mobile Number"
                    key="Patient Registered Mobile Number"
                    name="Patient Registered Mobile Number"
                    onChange={(e) =>
                      this.setState({
                        transferRegistrationData: {
                          ...this.state.transferRegistrationData,
                          contactNo: e.target.value,
                        },
                      })
                    }
                    // style={{ width: '120%' }}
                    type="text"
                    placeholder="Patient Registered Mobile Number"
                    variant="outlined"
                    label="Patient Registered Mobile Number"
                    fullWidth
                  />
                </Grid>

                <Grid container item xs={12} style={{ marginTop: 10 }}>
                  <ValidatedSelect
                    label="Blood Group"
                    value={this.state.transferRegistrationData.bloodGroup}
                    options={Object.values(BLOODGROUP)}
                    // style={{ width: '120%' }}
                    onChange={(e) =>
                      this.setState({
                        transferRegistrationData: {
                          ...this.state.transferRegistrationData,
                          bloodGroup: e.value,
                        },
                      })
                    }
                    fullWidth
                  />{' '}
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 10 }}>
                  <ValidatedSelect
                    label="From Hospital"
                    value={this.state?.transferRegistrationData?.fromHospital}
                    options={this._getHospitalNames()}
                    // style={{ width: '120%' }}
                    onChange={(e) =>
                      this.setState({
                        transferRegistrationData: {
                          ...this.state.transferRegistrationData,
                          fromHospital: e.value,
                        },
                      })
                    }
                    fullWidth
                  />{' '}
                </Grid>

                <Grid container item xs={12} style={{ marginTop: 10 }}>
                  <ValidatedSelect
                    label="To Hospital"
                    value={this.state?.transferRegistrationData?.toHospital}
                    options={this._getToHospitalNames()}
                    // style={{ width: '120%' }}
                    onChange={(e) =>
                      this.setState({
                        transferRegistrationData: {
                          ...this.state.transferRegistrationData,
                          toHospital: e.value,
                        },
                      })
                    }
                    fullWidth
                  />{' '}
                </Grid>
              </Grid>
              {this.state.otpData?.hash ? (
                <>
                  <Grid container item xs={12} style={{ marginTop: 10 }}>
                    <TextField
                      id="OTP"
                      key="OTP"
                      name="OTP"
                      onChange={(e) =>
                        this.setState({
                          otpData: {
                            ...this.state.otpData,
                            otp: e.target.value,
                          },
                        })
                      }
                      // style={{ width: '120%' }}
                      type="text"
                      placeholder="Please enter OTP here"
                      variant="outlined"
                      label="Please enter OTP here"
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row-reverse"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Button
                      variant="contained"
                      onClick={() => this.submitTransferRequest()}
                      style={{
                        backgroundColor: '#5097D6',
                        color: '#FFFFFF',
                        width: '45%',
                        marginTop: '20px',
                      }}
                    >
                      Verify OTP and Submit Request
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid
                  container
                  direction="row-reverse"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Button
                    variant="contained"
                    onClick={() => this.generateOTP()}
                    style={{
                      backgroundColor: '#5097D6',
                      color: '#FFFFFF',
                      width: '45%',
                      marginTop: '20px',
                    }}
                  >
                    Generate OTP
                  </Button>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid item sm={12}>
              <Typography variant="h5">
                Your transfer registration request has been successfully submitted. You can use
                acknowledgementId - {this.state.transferOutRequestAcknowledgementNumber} for further
                process.
              </Typography>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    );
  }

  generateOTP() {
    fetch(
      `${PATH_OTP_REQUESTOTP}?mobileNo=${encodeURIComponent(
        this.state.transferRegistrationData.contactNo
      )}`,
      {
        method: 'GET',
        headers: {
          'x-rapidapi-host': 'fairestdb.p.rapidapi.com',
          'x-rapidapi-key': 'apikey',
          'content-type': 'application/json',
          accept: 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            otpData: {
              ...this.state.otpData,
              hash: response.result,
              contact: this.state.transferRegistrationData?.contactNo,
            },
          });
        } else if (response.status === 400) {
          this.setState({
            waitingListMsg: `${response.message}`,
          });
        }
      })
      .catch((err) => {
        this.setState({
          waitingListMsg: `${err.message}`,
        });
        this.setState({
          waitingListDialogOpen: true,
        });
      });
  }

  submitTransferRequest() {
    fetch(PATH_TRANSFER_OUT_REGISTRATION_REQUEST, {
      method: 'POST',
      headers: {
        'x-rapidapi-host': 'fairestdb.p.rapidapi.com',
        'x-rapidapi-key': 'apikey',
        'content-type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify({
        formData: this.state.transferRegistrationData,
        otpData: this.state.otpData,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response.status);
        if (response.status === 200) {
          this.setState({
            transferRegistrationData: {
              nottoId: '',
              contactNo: '',
              bloodGroup: '',
              aadharNo: '',
              fromHospital: '',
              toHospital: '',
            },
            otpData: cloneDeep(EMPTY_OTPDATA),
            transferOutRequestSubmitted: true,
            transferOutRequestAcknowledgementNumber: response.result.acknowledgementId,
          });
        } else if (response.status === 400) {
        }
      })
      .catch((err) => {
        this.setState({
          waitingListMsg: `${err.message}`,
        });
        this.setState({
          waitingListDialogOpen: true,
        });
      });
  }

  showComponent() {
    if (window.location.pathname === '/donorprofile') {
      return <DonorProfile />;
    }
    if (window.location.pathname === '/newdonor') {
      return <Form7 />;
    }
    if (window.location.pathname === '/mlas') {
      return <MlasScoring />;
    }
    if (window.location.pathname === '/dev') {
      return <AllocationDonor />;
    }

    const { classes } = this.props;
    // console.log(this.state.waitingList);

    const columns = [
      {
        Header: 'Name',
        key: 'name',
        accessor: 'name',
      },
      {
        Header: 'Address',
        key: 'address',
        accessor: 'address',
      },
      {
        Header: 'Transplant Mobile Number',
        key: 'mobileNo',
        accessor: 'mobileNo',
      },
      {
        Header: 'Status',
        key: 'status',
        accessor: 'status',
      },
    ];

    const { username } = this.inputs;

    return (
      <Grid container style={{ maxHeight: '100vh', width: '100vw' }}>
        {isTestEnv() || isDevEnv ? (
          <Grid container item xs={12} justify="space-around" alignItems="center">
            <img src={arogyaLogo} alt="arogyabhawan" height={75} />
            <img src={maharashtraLogo} alt="maharashtragovt" height={75} />
            <img src={rottoLogo} alt="rottosotto" height={60} />
          </Grid>
        ) : null}
        <Grid
          container
          item
          xs={12}
          justify="center"
          alignItems="center"
          style={{ marginBottom: 10, marginTop: 10 }}
        >
          <Typography variant="h4">
            <strong>MAHA-AYUDAAN</strong>
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="center"
          alignItems="center"
          style={{ marginBottom: 10, marginTop: 10 }}
        >
          <Typography variant="h6">
            <strong>Digital Health Mission</strong>
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid
            container
            item
            xs={12}
            sm={6}
            style={{
              paddingTop: 20,
              borderRight: 'solid',
              borderRightColor: '#C5C5C5',
              borderRightWidth: 1,
            }}
            className={classes.leftPadding}
          >
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="h5">
                <strong>The gift of life, let&apos;s pass it on</strong>
              </Typography>
              <Typography variant="body1" style={{ marginTop: 10 }}>
                We are born with an ability to change someone’s life, let us not waste it. With your
                help in Organ transplantation process, help change the ending of someone’s story
              </Typography>
            </Grid>
            <Grid item container xs={12} justify="center" alignItems="center">
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="h6">
                  <strong>Donor Registration Form</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardActionArea
                    onClick={() => this.changeState('signUp', { signupType: 'DONOR_PLEDGE' })}
                  >
                    <CardMedia image={donorFormImage} component="img" title="Donor Pledge" />
                    <CardContent>
                      <Typography variant="body1" component="p" style={{ textAlign: 'center' }}>
                        Click here become an organ donor and <br />
                        <span style={{ fontWeight: 'bold', color: '#5097D6' }}>
                          LEAVE A LEGACY OF LIFE
                        </span>
                      </Typography>
                      <Button
                        component="span"
                        variant="contained"
                        fullWidth
                        style={{ backgroundColor: '#5097D6', color: '#FFFFFF' }}
                        onClick={() => this.changeState('signUp', { signupType: 'DONOR_PLEDGE' })}
                      >
                        Donor Pledge Form
                      </Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ position: 'fixed', bottom: 0, paddingTop: 10, paddingBottom: 10 }}
            >
              <Typography variant="body1">
                <LightningBoltIcon style={{ color: 'orange' }} />
                <strong>
                  Powered by{' '}
                  <a rel="noopener noreferrer" href="https://chainworks.io" target="_blank">
                    chainworks.io
                  </a>
                </strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            style={{
              paddingTop: 20,
              paddingBottom: 0,
              height: '100%',
            }}
            justify="center"
            className={classes.leftPadding}
          >
            <Grid
              item
              container
              style={{ marginTop: 10 }}
              sm={6}
              xs={12}
              direction="row"
              spacing={3}
            >
              <Grid container item xs={12}>
                <Typography variant="h6">
                  <strong>Administrators Login</strong>
                </Typography>
                <Typography variant="subtitle2">
                  Login with your registered credentials to access your Hospital / Administrative
                  profile
                </Typography>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        id="username"
                        key="username"
                        name="username"
                        onChange={this.handleInputChange}
                        type="text"
                        placeholder="Username"
                        variant="outlined"
                        label="Username"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <TextField
                        id="password"
                        key="password"
                        name="password"
                        onChange={this.handleInputChange}
                        type="password"
                        placeholder="******************"
                        variant="outlined"
                        label="Password"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      {this._renderLoginButton()}
                    </Grid>
                    <Grid item sm={12}>
                      <Button
                        color="primary"
                        style={{ border: 'none', textTransform: 'none' }}
                        onClick={() => {
                          this.props.onStateChange('forgotPassword', {
                            username,
                          });
                        }}
                      >
                        Never received or Forgot Password ? Reset password
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12} style={{ marginTop: -30 }}>
                  <Grid container item xs={12}>
                    <Grid container style={{ marginTop: 10 }} justify="space-around">
                      <Grid item sm={12} style={{ paddingTop: 0 }}>
                        <Typography variant="h6">
                          <strong>Know your City Rank</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            this.setState({
                              waitingListDialogOpen: true,
                            });
                          }}
                          style={{ backgroundColor: '#5097D6', color: '#FFFFFF' }}
                        >
                          City Rank
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12}>
                    <Grid container style={{ marginTop: 10 }} justify="space-around">
                      <Grid item sm={12} style={{ paddingTop: 0 }}>
                        <Typography variant="h6">
                          <strong>Transfer Registration</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            this.setState({
                              transferRegistrationListDialogOpen: true,
                            });
                          }}
                          style={{ backgroundColor: '#5097D6', color: '#FFFFFF' }}
                        >
                          Transfer Registration
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12}>
                    <Grid container style={{ marginTop: 10 }} justify="space-around">
                      <Grid item sm={12} style={{ paddingTop: 0 }}>
                        <Typography variant="h6">
                          <strong>Request Access</strong>
                        </Typography>
                        <Typography variant="subtitle2">
                          ROTTO / SOTTO / ZTCC / Hospital User ? Request for access to get
                          credentials
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            this.setState({
                              registerDialogOpen: true,
                            });
                          }}
                          style={{ backgroundColor: '#5097D6', color: '#FFFFFF' }}
                        >
                          Request Login
                        </Button>
                      </Grid>
                      {/* <Grid item sm={4} xs={12}>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ backgroundColor: '#5097D6', color: '#FFFFFF' }}
                      onClick={() => this.changeState('signUp', { signupType: 'ROTTO' })}
                    >
                      ROTTO/SOTTO/DMER Login
                    </Button>
                  </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid container item xs={12}>
                <Grid container style={{ marginTop: 10 }} justify="space-around">
                  <Grid item sm={12} style={{ paddingTop: 0 }}>
                    <Typography variant="h6">
                      <strong>Know your City Rank</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        this.setState({
                          waitingListDialogOpen: true,
                        });
                      }}
                      style={{ backgroundColor: '#5097D6', color: '#FFFFFF' }}
                    >
                      City Rank
                    </Button>
                  </Grid>
                </Grid>
              </Grid> */}

              {/* <Grid container item xs={12}>
                <Grid container style={{ marginTop: 10 }} justify="space-around">
                  <Grid item sm={12} style={{ paddingTop: 0 }}>
                    <Typography variant="h6">
                      <strong>Transfer Registration</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        this.setState({
                          transferRegistrationListDialogOpen: true,
                        });
                      }}
                      style={{ backgroundColor: '#5097D6', color: '#FFFFFF' }}
                    >
                      Transfer Registration
                    </Button>
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid container item xs={12}>
                <Grid container style={{ marginTop: 10 }} justify="space-around">
                  {/* <Grid item sm={12} style={{ paddingTop: 0 }}>
                    <Typography variant="h6">
                      <strong>Request Access</strong>
                    </Typography>
                    <Typography variant="subtitle2">
                      ROTTO / SOTTO / ZTCC / Hospital User ? Request for access to get credentials
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        this.setState({
                          registerDialogOpen: true,
                        });
                      }}
                      style={{ backgroundColor: '#5097D6', color: '#FFFFFF' }}
                    >
                      Request Login
                    </Button>
                  </Grid> */}
                  {/* <Grid item sm={4} xs={12}>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ backgroundColor: '#5097D6', color: '#FFFFFF' }}
                      onClick={() => this.changeState('signUp', { signupType: 'ROTTO' })}
                    >
                      ROTTO/SOTTO/DMER Login
                    </Button>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            {/* {!isProdEnv() ? (
              <Grid
                item
                container
                style={{ marginTop: 10 }}
                sm={6}
                xs={12}
                direction="column"
                justify="center"
                alignItems="flex-start"
                // spacing={3}
              >
                <Grid container item xs={12} justify="center" className={classes.leftPadding}>
                  <Grid container item xs={12} justify="center" style={{ flexGrow: 0 }}>
                    <Typography variant="h6">
                      <strong>KEY PERSONS</strong>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    justify="center"
                    spacing={2}
                    className={classes.listScroll}
                    style={{ flexGrow: 1, height: '65vh', overflowY: 'scroll' }}
                  >
                    {[
                      {
                        src: uddhavthackarey,
                        name: 'Shri. Uddhav Thackeray',
                        position: "Hon'ble Chief Minister",
                      },
                      {
                        src: ajitpawar,
                        name: 'Shri. Ajit Pawar',
                        position: "Hon'ble Deputy Chief Minister",
                      },
                      {
                        src: rajeshtope,
                        name: 'Shri. Rajesh Tope',
                        position: "Hon'ble Health Minister,Public Health Department",
                      },
                      {
                        src: rajendrapatilyadravkar,
                        name: 'Shri. Rajendra Patil Yadravkar',
                        position: "Hon'ble State Minister,Public Health Department",
                      },
                      {
                        src: pradeepvyas,
                        name: 'Dr. Pradeep Kumar Vyas',
                        position: 'Addl. Chief Secretary, Public Health Department',
                      },
                      {
                        src: kerketta,
                        name: 'Dr. M .N Kerketta',
                        position: 'Principal Secretary-2, Public Health Department',
                      },
                      {
                        src: nramaswami,
                        name: 'Dr. Ramaswami. N',
                        position: 'Commissioner (Health Services) & MD(NHM)',
                      },
                    ].map((k) => this._renderKeyPerson(k.src, k.name, k.position))}
                  </Grid>
                </Grid>
              </Grid>
            ) : null} */}
          </Grid>
        </Grid>
        {/* <Grid container item xs={12}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            style={{
              paddingTop: 20,
              // borderRight: 'solid',
              // borderRightColor: '#C5C5C5',
              // borderRightWidth: 1,
            }}
            className={classes.leftPadding}
          >
            <Grid item sm={12} xs={12} style={{ margin: 10 }}>
              <hr />
            </Grid>
            <Grid container sm={12} style={{ marginTop: '8em' }}>
              <Grid item sm={6} xs={12} style={{ padding: 15, marginBottom: 20, marginLeft: -5 }}>
                <AreaSelector state="Maharashtra" setDistrict={this.setSelectedDistrict} />
              </Grid>
              <Grid item sm={6}>
                {this.state.selectedDistict ? (
                  <Grid item sm={12} xs={12}>
                    <Paper
                      elevation={50}
                      variant="outlined"
                      style={{
                        borderWidth: 2,
                        margin: 15,
                        padding: 10,
                      }}
                    >
                      <Typography variant="h5">
                        <strong>
                          SOTTO (Maharashtra) Organs and Tissues Transplant,Retrieval & Registration
                          Statistics
                        </strong>
                      </Typography>
                      <Grid item sm={12} style={{ margin: 2 }}>
                        <hr />
                      </Grid>
                      {this.state.selectedDistict ? (
                        <Typography>
                          Selected District: <strong>{this.state.selectedDistict}</strong>
                        </Typography>
                      ) : null}
                    </Paper>
                  </Grid>
                ) : null}
                <Grid item sm={12}>
                  <Paper
                    elevation={50}
                    variant="outlined"
                    style={{
                      borderWidth: 2,
                      margin: 15,
                      padding: 10,
                    }}
                  >
                    <Typography>
                      Number of Registered Transplant Center:{' '}
                      <strong>{this.state.districtData?.totalTransplantCenters}</strong>
                    </Typography>
                    <Grid item sm={12} style={{ margin: 2 }}>
                      <hr />
                    </Grid>
                    <Typography>
                      Number of Registered Retrieval Center:{' '}
                      <strong>{this.state.districtData?.totalRetrievalCenters}</strong>
                    </Typography>
                    <Grid item sm={12} style={{ margin: 2 }}>
                      <hr />
                    </Grid>
                    <Typography>
                      Total Number of patient Waiting List:{' '}
                      <strong>{this.state.districtData?.totalRecipientOnWaitingList}</strong>
                    </Typography>
                    <Grid item sm={12} style={{ margin: 2 }}>
                      <hr />
                    </Grid>
                    <Typography>
                      Total Number of Donor till Today:{' '}
                      <strong>{this.state.districtData?.totalDonorsTillDate}</strong>
                    </Typography>
                    <Grid item sm={12} style={{ margin: 2 }}>
                      <hr />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item sm={12} xs={12} style={{ padding: 20 }}>
          <hr />
        </Grid>
        <Grid container item sm={12} xs={12}>
          <StatisticsData />
        </Grid>
        {this._renderRegisterDialog()}
        {this._renderWaitingListDialog()}
        {this._transferRegistrationListDialogDialog()}
      </Grid>
    );
  }

  _renderLoginButton() {
    const { spinner } = this.state;
    return (
      // <Grid container>
      //   <Grid sm={12}>
      <Button
        id="loginButton"
        color="primary"
        variant="contained"
        onClick={() => this._toggleSpinnerAndLogin()}
        className="mt-3 px-4"
        fullWidth
        style={{ height: '44px' }}
        endIcon={spinner ? <CircularProgress size="20px" style={{ color: '#FFFFFF' }} /> : null}
      >
        Login
      </Button>
      //   </Grid>
      // </Grid>
    );
  }

  _toggleSpinnerAndLogin() {
    this.setState(
      {
        spinner: true,
      },
      () => {
        this._signIn();
      }
    );
  }

  _onKeyPressSignIn(event) {
    if (event.keyCode === 13) {
      this._toggleSpinnerAndLogin();
    }
  }

  _logout = () => {
    logout()
      .then(() => {
        window.location.replace('/');
      })
      .catch((err) => {
        Login._showError(err.message);
      });
  };

  _signIn() {
    const { cancellablePromises } = this.state;
    // const username = 'chinmay.irock@gmail.com';
    // const password = 'Password@123';

    const { username, password } = this.inputs;

    if (username && username.search(REGEX_ILLEGALCHARS) >= 0) {
      this.error('Illegal characters not allowed');
      return;
    }

    if (!Auth || typeof Auth.signIn !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    const cLogin = makeCancellable(login(username, password));
    cancellablePromises.push(cLogin);
    cLogin.promise
      .then((user) => {
        this.setState({ loading: false });
        logger.debug(user);
        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          logger.debug(`confirm user with ${user.challengeName}`);
          this.changeState('confirmSignIn', user);
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          logger.debug('require new password', user.challengeParam);
          this.changeState('requireNewPassword', user);
        } else if (user.challengeName === 'MFA_SETUP') {
          logger.debug('TOTP setup', user.challengeParam);
          this.changeState('TOTPSetup', user);
        } else {
          this.checkContact(user);
        }
      })
      .catch((err) => {
        this.setState({ loading: false, spinner: false });
        logger.debug(err);
        if (err.code === 'UserNotConfirmedException') {
          logger.debug('the user is not confirmed');
          this.changeState('confirmSignUp', { username });
        } else if (err.code === 'PasswordResetRequiredException') {
          logger.debug('the user requires a new password');
          this.changeState('forgotPassword', { username });
        } else {
          this.error(err);
        }
      });
  }

  static _showError(msg) {
    // toast(msg, {
    //   autoClose: false,
    //   type: toast.TYPE.ERROR,
    //   position: toast.POSITION.TOP_CENTER,
    //   className: 'toastify-container-error',
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedDistict !== this.state.selectedDistict) {
      this.getDistrictWiseData(this.selectedDistict);
    }
  }

  componentWillUnmount() {
    const { cancellablePromises } = this.state;
    cancellablePromises.forEach((c) => c.cancel());
  }
}
Login.propTypes = {};

export default withStyles(useStyles)(Login);
