export const DISTRICTS_HP = [
  {
    label: 'Bilaspur',
    value: 'BILASPUR'
  },
  {
    label: 'Chamba',
    value: 'CHAMBA'
  },
  {
    label: 'Hamirpur',
    value: 'HAMIRPUR'
  },
  {
    label: 'Kangra',
    value: 'KANGRA'
  },
  {
    label: 'Kinnaur',
    value: 'KINNAUR'
  },
  {
    label: 'Kullu',
    value: 'KULLU'
  },
  {
    label: 'Lahaul & Spiti',
    value: 'LAHUAL_AND_SPITI'
  },
  {
    label: 'Mandi',
    value: 'MANDI'
  },
  {
    label: 'Shimla',
    value: 'SHIMLA'
  },
  {
    label: 'Sirmaur (Sirmour)',
    value: 'SIRMAUR'
  },
  {
    label: 'Solan',
    value: 'SOLAN'
  },
  {
    label: 'Una',
    value: 'UNA'
  }
];
