import { diff } from 'rus-diff';
import moment from 'moment';
import * as log from 'loglevel';
import { isDhsUserType, isHospitalIcUserType, FILESUFFIX_FORMAT_DATE } from '../../../../constants';
import { uploadDocument, apiExpressGet, apiExpressPost, apiExpressPatch } from '../../../../utils';
import { EYEDONOR_FILEPREFIX } from '../../../../appConstants/eyebank';
import {
  PATH_EXP_EYETRANSPLANT_GETDRAFTEYEDONOR,
  PATH_EXP_EYETRANSPLANT_GETEYEDONOR,
  PATH_EXP_EYETRANSPLANT_SUBMITDRAFTEYEDONOR,
  PATH_EXP_EYETRANSPLANT_SUBMITEYEDONOR,
  PATH_EXP_EYETRANSPLANT_SUBMITEYEDONOREDITS
} from '../../../../appConstants';

const logger = log.getLogger('EyeDonorScripts');

export const uploadFormDocuments = async (eyeDonor, hospitalName) => {
  const { deathCertificate, consent } = eyeDonor;
  const fileSuffix = moment().format(FILESUFFIX_FORMAT_DATE);

  const uploadPromises = [];

  if (deathCertificate) {
    uploadPromises.push(
      uploadDocument(
        deathCertificate,
        EYEDONOR_FILEPREFIX.DEATHCERTIFICATE,
        hospitalName,
        fileSuffix
      )
    );
  }

  if (consent) {
    uploadPromises.push(
      uploadDocument(consent, EYEDONOR_FILEPREFIX.CONSENT, hospitalName, fileSuffix)
    );
  }
  return Promise.all(uploadPromises);
};

export const submitDraftEyeDonor = async (eyeDonor, hospitalId, hospitalName) => {
  try {
    await uploadFormDocuments(eyeDonor, hospitalName);
  } catch (err) {
    logger.error(err);
    throw new Error('Error uploading documents');
  }
  const url = PATH_EXP_EYETRANSPLANT_SUBMITDRAFTEYEDONOR;
  const postBody = { eyeDonor, hospitalId, hospitalName };
  try {
    return await apiExpressPost(url, postBody);
  } catch (err) {
    throw err;
  }
};

export const submitEyeDonorEdits = async (oldEyeDonor, newEyeDonor, hospitalId, hospitalName) => {
  try {
    // eslint-disable-next-line no-undef
    await uploadFormDocuments(newEyeDonor, hospitalName);
  } catch (err) {
    logger.error(err);
    throw new Error('Error uploading documents');
  }
  const eyeDonorEdits = diff(oldEyeDonor, newEyeDonor);
  if (eyeDonorEdits.length === 0) {
    return oldEyeDonor;
  }

  const { draftId } = oldEyeDonor;

  const url = PATH_EXP_EYETRANSPLANT_SUBMITEYEDONOREDITS;
  const patchBody = { draftId, eyeDonorEdits, hospitalId };
  try {
    return await apiExpressPatch(url, patchBody);
  } catch (err) {
    throw err;
  }
};

export const submitEyeDonor = async (eyeDonor, hospitalId) => {
  const { draftId } = eyeDonor;

  const url = PATH_EXP_EYETRANSPLANT_SUBMITEYEDONOR;
  const postBody = { draftId, hospitalId };
  try {
    return await apiExpressPost(url, postBody);
  } catch (err) {
    throw err;
  }
};

export const getEyeDonorDraft = async (draftId, hospitalId) => {
  const query = { draftId, hospitalId };

  const url = PATH_EXP_EYETRANSPLANT_GETDRAFTEYEDONOR;
  try {
    return await apiExpressGet(url, query);
  } catch (err) {
    throw err;
  }
};

export const getEyeDonor = async (eyeDonorId, hospitalId, userType) => {
  let url;
  const query = { hospitalId, eyeDonorId, userType };
  if (isDhsUserType(userType)) {
    url = PATH_EXP_EYETRANSPLANT_GETEYEDONOR;
  } else if (isHospitalIcUserType(userType)) {
    url = PATH_EXP_EYETRANSPLANT_GETEYEDONOR;
  } else {
    throw new Error('Invalid user login');
  }

  try {
    return await apiExpressGet(url, query);
  } catch (err) {
    throw err;
  }
};
