import set from 'lodash.set';
import { Auth } from 'aws-amplify';

export const handleLoginForm = (name, value, component) => {
  const { state } = component;
  set(state, name, value);
  component.setState(state);
};

export const login = async (email, password) => {
  let user;
  try {
    user = await Auth.signIn(email, password);
  } catch (err) {
    throw err;
  }
  return user;
};

export const logout = async () => {
  try {
    await Auth.signOut();
  } catch (err) {
    throw new Error('Network Error');
  }
};
