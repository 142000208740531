import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { makeCancellable } from '../utils';
import { MSG_LOADING_DEFAULT } from '../constants';

function withCancellableResolver(WrappedComponent) {
  class ComponentWithCancellableResolver extends Component {
    constructor(props) {
      super(props);
      this.state = {
        cancellablePromises: [],
      };
      this._resolveWithCancellable = this._resolveWithCancellable.bind(this);
    }

    DEFAULT_OPTS = {
      throwError: true,
      showLoadingStatus: true,
      loadingMsg: MSG_LOADING_DEFAULT,
      showSuccess: false,
      toastOpts: {},
      successMsg: 'OK',
      showError: true,
      showThrownErrorMsg: false,
      errorMsg: 'Please try again',
    };

    render() {
      const { enqueueSnackbar, ...otherProps } = this.props;
      return (
        <WrappedComponent resolveWithCancellable={this._resolveWithCancellable} {...otherProps} />
      );
    }

    _resolveWithCancellable(promise, opts) {
      const options = Object.assign(this.DEFAULT_OPTS, opts);
      const { enqueueSnackbar } = this.props;
      const { cancellablePromises } = this.state;
      const cFcn = makeCancellable(promise);
      cancellablePromises.push(cFcn);
      return cFcn.promise
        .then((result) => {
          if (options.showSuccess) {
            enqueueSnackbar(options.successMsg, { variant: 'success' });
          }
          return result;
        })
        .catch((err) => {
          if (err.isCancelled) {
            return;
          }
          if (options.showError) {
            let { errorMsg } = options;
            const { showThrownErrorMsg } = options;
            if (showThrownErrorMsg) {
              errorMsg = err.message;
            }
            enqueueSnackbar(errorMsg, { variant: 'error' });
          }
          if (options.throwError) {
            throw err;
          }
        });
    }

    componentWillUnmount() {
      const { cancellablePromises } = this.state;
      cancellablePromises.forEach((c) => c.cancel());
    }
  }
  ComponentWithCancellableResolver.propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
  };
  ComponentWithCancellableResolver.defaultProps = {};

  const mapStateToProps = (state) => ({
    hospital: state.hospital,
  });
  return compose(connect(mapStateToProps), withSnackbar)(ComponentWithCancellableResolver);
}
export default withCancellableResolver;
