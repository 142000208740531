export const DISTRICTS_JH = [
  {
    label: 'Bokaro',
    value: 'BOKARO'
  },
  {
    label: 'Chatra',
    value: 'CHATRA'
  },
  {
    label: 'Deoghar',
    value: 'DEOGHAR'
  },
  {
    label: 'Dhanbad',
    value: 'DHANBAD'
  },
  {
    label: 'Dumka',
    value: 'DUMKA'
  },
  {
    label: 'East Singhbhum',
    value: 'EAST_SINGHBHUM'
  },
  {
    label: 'Garhwa',
    value: 'GARHWA'
  },
  {
    label: 'Giridih',
    value: 'GIRIDIH'
  },
  {
    label: 'Godda',
    value: 'GODDA'
  },
  {
    label: 'Gumla',
    value: 'GUMLA'
  },
  {
    label: 'Hazaribag',
    value: 'HAZARIBAG'
  },
  {
    label: 'Jamtara',
    value: 'JAMTARA'
  },
  {
    label: 'Khunti',
    value: 'KHUNTI'
  },
  {
    label: 'Koderma',
    value: 'KODERMA'
  },
  {
    label: 'Latehar',
    value: 'LATEHAR'
  },
  {
    label: 'Lohardaga',
    value: 'LOHARDAGA'
  },
  {
    label: 'Pakur',
    value: 'PAKUR'
  },
  {
    label: 'Palamu',
    value: 'PALAMU'
  },
  {
    label: 'Ramgarh',
    value: 'RAMGARH'
  },
  {
    label: 'Ranchi',
    value: 'RANCHI'
  },
  {
    label: 'Sahibganj',
    value: 'SAHIBGANJ'
  },
  {
    label: 'Seraikela-Kharsawan',
    value: 'SERAIKELA_KHARSAWAN'
  },
  {
    label: 'Simdega',
    value: 'SIMDEGA'
  },
  {
    label: 'West Singhbhum',
    value: 'WEST_SINGHBHUM'
  }
];
