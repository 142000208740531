import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { BLOODGROUP, FORMAT_DATE_UNIXTS, isHospitalIcUserType } from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class KidneyRegistrationFormPdf {
  formData = {};

  constructor(formData) {
    const {
      kidneyDetails,
      basicDetails,
      contactNos,
      contactEmailIds,
      hospitalName,
      ztccZone,
      organs,
    } = formData;
    this.formData = formData;

    const _yesNoAndEmptyFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      if (value === 'true') {
        return 'YES';
      }
      if (value === 'false') {
        return 'NO';
      }
      return '-';
    };
    const _getZtccZoneLabelById = (zone) => {
      if (zone === '7') {
        return 'ZTCC MUMBAI';
      }
      if (zone === '8') {
        return 'ZTCC PUNE';
      }
      if (zone === '9') {
        return 'ZTCC NAGPUR';
      }
      if (zone === '10') {
        return 'ZTCC AURANGABAD';
      }
      if (zone) {
        return zone;
      }

      return '';
    };

    const calculateCurrentAge = () => {
      if (basicDetails.dateOfBirth) {
        const duration = moment.duration(
          moment().diff(moment(basicDetails.dateOfBirth, FORMAT_DATE_UNIXTS))
        );
        return `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`;
      }
      if (basicDetails.currentAge) {
        return basicDetails.currentAge;
      }
      return '-';
    };

    const _nationalityFormatter = (value) => {
      if (value === 'INDIAN_NATIONAL') {
        return 'Indian';
      }
      if (value === 'OCI_CARDHOLDER') {
        return 'Overseas Citizen of India (OCI) card holder';
      }
      if (value === 'FOREIGN_NATIONAL') {
        return 'Foreign national';
      }
      return '-';
    };

    const _yesNoAndEmptyBooleanFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };

    const _praFormatter = (value) => {
      if (value === 'CLASSONE') {
        return 'Class 1';
      }
      if (value === 'CLASSTWO') {
        return 'Class 2';
      }
      if (value === 'BOTHCLASSES') {
        return 'Both Class 1 and Class 2';
      }
      if (value === 'NOTDONE') {
        return 'Not Done';
      }
      return '-';
    };

    const _viralMrakerRemarkAndDateFormatter = (remark, date) => {
      // if (remark && date) {
        if (remark === 'NOTDONE') {
          return 'NOT DONE';
        }
      return `${remark} - ${date ? moment(date, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY') : '-'}`;
      // }
      return '-';
    };
    const reportDocDefinition = {
      content: [
        {
          text: '',
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  fontSize: 12,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'State Appropriate Authority of Maharashtra',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: 'KIDNEY REGISTRATION',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],

              [
                {
                  text: `ZTCC Zone: ${_getZtccZoneLabelById(ztccZone)}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date of Registration : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.dateOfRegistration
                      ? moment(basicDetails?.dateOfRegistration, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Name:`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.name ? basicDetails?.name : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `NOTTO ID : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.nottoId}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Birth: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.dateOfBirth
                      ? moment(basicDetails?.dateOfBirth, FORMAT_DATE_UNIXTS).format('DD MMMM YYYY')
                      : '-'
                  } `,

                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Age: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${calculateCurrentAge()}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Gender: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.sex ? basicDetails?.sex : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Group: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Weight (Kg): `,
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.weightKg}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in cm):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightCm}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in inches):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightIn ? basicDetails?.heightIn : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${hospitalName || '-'}`, colSpan: 2 },
                {},
              ],

              [
                {
                  text: `Address & Telephone No: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.presentAddress ? basicDetails?.presentAddress : '-'} - ${
                    contactNos.length > 0 ? contactNos[0] : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Patient contact emails:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${contactEmailIds.length > 0 ? contactEmailIds[0] : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Economic Status: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.economicStatus ? basicDetails?.economicStatus : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Occupation',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.occupation}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_nationalityFormatter(basicDetails?.nationality)}`,
                  colSpan: 2,
                },
                {},
              ],
              ...(basicDetails?.nationality !== 'INDIAN_NATIONAL'
                ? [
                    [
                      {
                        text: `Indian Contact Number : `,
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${
                          basicDetails?.indianContactNumber
                            ? basicDetails?.indianContactNumber
                            : '-'
                        }`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              [
                {
                  text: `Country of Residence: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.countryOfResidence ? basicDetails?.countryOfResidence : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              // [
              //   {
              //     text: 'Date of RT PCR',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${
              //       basicDetails.rtpcrdate
              //         ? moment(basicDetails.rtpcrdate, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
              //         : '-'
              //     }`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],
              // [
              //   {
              //     text: 'Is Covid Vaccination Done?',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${_covidVaccinationFormatter(basicDetails?.covidVaccination?.dosesDone)}`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],

              [
                {
                  text: 'UHID',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.uhid}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Present Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.presentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Permanent Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.permanentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Date of Admission / OPD',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.dateOfAdmission
                      ? moment(basicDetails?.dateOfAdmission, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Primary physician Name:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.primaryPhysicianName}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Primary physician contact numbers:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.primaryPhysicianContactNo
                      ? basicDetails?.primaryPhysicianContactNo
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary physician contact email:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.primaryPhysicianEmail ? basicDetails?.primaryPhysicianEmail : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary Questions Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient have active / metastatic malignancy?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMalignancy
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active MDR infection with sepsis or septic shock?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMdrInfection
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have severe irreversible systemic disease (cardiac, pulmonary, neurological or others with very short longevity)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noSevereIrreversibleSystemicDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active or recent major unresolved psychiatric illness?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noMajorUnresolvedPsychiatricIllness
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have high risk of non-compliance with long-term immunosuppression?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noHighRiskOfNonCompliance
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have adequate social or family support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(basicDetails?.prerequisites?.familySupport)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Identity Proof Document Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Document Type',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentType}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identify Proof Document Number',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentNumber}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identity Proof Document Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              // [
              //   {
              //     pageBreak: 'before',
              //     text: 'Family Member Details',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 3,
              //     bold: true,
              //   },

              //   {
              //     text: ``,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
            ],
          },
        },
        {
          text: 'Family Member Details',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Family Members Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Relation With Patient', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Number', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Email', style: 'tableHeader', alignment: 'left' },
              ],
              ...basicDetails.familyMembers.map((a) => [
                { text: `${a.name ? a.name : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.relation ? a.relation : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.contact ? a.contact : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.email ? a.email : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
          // layout: 'lightHorizontalLines',
        },
        {
          text: 'General Information',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Requested Organs', style: 'tableHeader', alignment: 'left' },
                { text: 'Requested Organs Listing Type', style: 'tableHeader', alignment: 'left' },
              ],
              ...organs.map((o) => [
                {
                  text: `${o.organType ? o.organType : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                { text: `${o.category ? o.category : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'General Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'Is Patient Willing to Accept ECD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'ECD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Is Patient Willing to Accept DCD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'DCD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Has the patient been registered in Maharashtra in Past:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.pastZtccListingInfo?.pastZtccListing
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Status',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastListingStatus}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Further Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastZtccDetails}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'KIDNEY FORM DETAILS ',
                  style: 'tableHeader',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date of Initiation of Dialysis:  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    kidneyDetails?.dateOfDialysis
                      ? moment(kidneyDetails?.dateOfDialysis, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 3,
                },
                {},
              ],

              [
                {
                  text: `Report of Initiation of Dialysis:  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails?.dialysisInitiationReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 3,
                },
                {},
              ],

              [
                {
                  text: `Failed Previous Transplant \n (if yes give details) : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(kidneyDetails?.previousFailedGraft)}`,
                  colSpan: 3,
                },
                {},
              ],

              [
                {
                  text: `If Yes, proof of kidney Transplant  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails?.proofOfKidneyTransplant?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 3,
                },
                {},
              ],

              [
                {
                  text: `Is the patient past kidney donor?  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(kidneyDetails?.livingDonorNeedingTransplant)}`,
                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: `If Yes, proof of Living Donation:  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails?.livingDonationReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 3,
                },
                {},
              ],

              [
                {
                  text: 'HLA Typing : ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'A',
                  style: 'tableHeader',
                  alignment: 'left',
                },
                {
                  text: kidneyDetails?.hlaTyping?.a,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'B',
                  style: 'tableHeader',
                  alignment: 'left',
                },
                {
                  text: kidneyDetails?.hlaTyping?.b,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'C',
                  style: 'tableHeader',
                  alignment: 'left',
                },
                {
                  text: kidneyDetails?.hlaTyping?.c,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'DQ',
                  style: 'tableHeader',
                  alignment: 'left',
                },
                {
                  text: kidneyDetails?.hlaTyping?.dq,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'DR',
                  style: 'tableHeader',
                  alignment: 'left',
                },
                {
                  text: kidneyDetails?.hlaTyping?.dr,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'DM',
                  style: 'tableHeader',
                  alignment: 'left',
                },
                {
                  text: kidneyDetails?.hlaTyping?.dm,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'DP',
                  style: 'tableHeader',
                  alignment: 'left',
                },
                {
                  text: kidneyDetails?.hlaTyping?.dp,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'TAP',
                  style: 'tableHeader',
                  alignment: 'left',
                },
                {
                  text: kidneyDetails?.hlaTyping?.tap,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'TNF',
                  style: 'tableHeader',
                  alignment: 'left',
                },
                {
                  text: kidneyDetails?.hlaTyping?.tnf,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `VAF AV Graft \n (if yes give details): `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(kidneyDetails.vafGraft.vafGraft)} `,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `If Yes, Vascular Access Failure Remarks`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails.vafGraft.vafGraftRemarks} `,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Report of Vascular Access Failure`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails?.vafGraft?.vafGraftReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Number of VAF Failed`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    kidneyDetails.vafGraft.vafGraftNumber
                      ? kidneyDetails.vafGraft.vafGraftNumber
                      : ' -'
                  } `,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Date of Failure`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   kidneyDetails.vafGraft.vafGraftFailure.map((e) => [
              //     {
              //       text: `${
              //         e.dateOfFailure
              //           ? moment(e.dateOfFailure, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
              //           : '-'
              //       }`,
              //     },
              //   ]),

              //   {
              //     text: '',
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   kidneyDetails.vafGraft.vafGraftFailure.map((a) => [
              //     {
              //       text: `${a.report?.name ? 'ATTACHED' : '-'}`,
              //     },
              //   ]),

              //   {
              //     text: '',
              //     colSpan: 2,
              //   },
              //   {},
              // ],
            ],
          },
        },

        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Date of Failure', style: 'tableHeader', alignment: 'left' },
                { text: 'Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...kidneyDetails.vafGraft.vafGraftFailure.map((a) => [
                {
                  text: `${
                    a.dateOfFailure
                      ? moment(a.dateOfFailure, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${a.report?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: `PD Access Failure \n (if yes give details): `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyFormatter(kidneyDetails.vafFitsula.vafFitsula)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `If Yes, PD Access Failure Remarks`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails.vafFitsula.vafFitsulaRemarks} `,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Report of PD Access Failure`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails?.vafFitsula?.vafFitsulaReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Number of PDAF Failed`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    kidneyDetails.vafFitsula.vafFitsulaNumber
                      ? kidneyDetails.vafFitsula.vafFitsulaNumber
                      : '-'
                  } `,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },

        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Date of Failure', style: 'tableHeader', alignment: 'left' },
                { text: 'Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...kidneyDetails.vafFitsula.vafFitsulaFailure.map((b) => [
                {
                  text: `${
                    b.dateOfFailure
                      ? moment(b.dateOfFailure, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${b.report?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              // [
              //   {
              //     text: `Date of Failure`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   kidneyDetails.vafFitsula.vafFitsulaFailure.map((b) => [
              //     {
              //       text: `${
              //         b.dateOfFailure
              //           ? moment(b.dateOfFailure, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
              //           : '-'
              //       }`,
              //     },
              //   ]),

              //   {
              //     text: '',
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   kidneyDetails.vafFitsula.vafFitsulaFailure.map((v) => [
              //     {
              //       text: `${v.report?.name ? 'ATTACHED' : '-'}`,
              //     },
              //   ]),

              //   {
              //     text: '',
              //     colSpan: 2,
              //   },
              //   {},
              // ],

              [
                {
                  text: 'PRA Positivity : ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `PRA \n a) For Positivity to Class 1 or 2 \n b) For Positivity to both Classes `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${_praFormatter(kidneyDetails?.pra?.praYesNo)}`, colSpan: 2 },
                {},
              ],

              [
                {
                  text: 'Viral Marker Status : ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `HBs AG Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMrakerRemarkAndDateFormatter(
                    kidneyDetails?.viralMarkerStatus?.hbsagRemark,
                    kidneyDetails?.viralMarkerStatus?.hbsagReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Anti HCV Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMrakerRemarkAndDateFormatter(
                    kidneyDetails?.viralMarkerStatus?.antiHcvRemark,
                    kidneyDetails?.viralMarkerStatus?.antiHcvReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                kidneyDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                  ? {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        kidneyDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                      )}`,
                      colSpan: 2,
                    }
                  : {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        kidneyDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark,
                        kidneyDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                      )}`,
                      colSpan: 2,
                    },
                {},
              ],
              [
                {
                  text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                kidneyDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                  ? {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        kidneyDetails?.viralMarkerStatus?.hcvDnaRemark
                      )}`,
                      colSpan: 2,
                    }
                  : {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        kidneyDetails?.viralMarkerStatus?.hcvDnaRemark,
                        kidneyDetails?.viralMarkerStatus?.hcvDnaReportDate
                      )}`,
                      colSpan: 2,
                    },
                {},
              ],
              [
                {
                  text: `HIV Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMrakerRemarkAndDateFormatter(
                    kidneyDetails?.viralMarkerStatus?.hivRemark,
                    kidneyDetails?.viralMarkerStatus?.hivReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Cardiology Fitness Certificate',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails?.cardiacFitnessCertificate?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Pulmonary Fitness Certificate',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    kidneyDetails?.pulmonaryFitnessCertificateYesNo
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'If Yes, Pulmonary Fitness Certificate',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    kidneyDetails?.pulmonaryFitnessCertificate?.name ? 'ATTACHED' : 'NOT ATTACHED'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'If No, Details ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    kidneyDetails?.pulmonaryFitnessCertificateDetails
                      ? kidneyDetails?.pulmonaryFitnessCertificateDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Psychiatry Fitness Certificate',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails?.psychiatricFitnessCertificate?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Urology Fitness Certificate',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails?.urologyFitnessCertificate?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: 'Any Other Attachment',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${kidneyDetails?.otherAttachments?.length > 0 ? 'ATTACHED' : '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
            ],
          },
        },

        {
          text: 'Any Other Attachments',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...kidneyDetails?.otherAttachments?.map((e) => [
                {
                  text: `${e.attachmentName ? e.attachmentName : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Etiology of ESKD', style: 'tableHeader', alignment: 'left' },
                { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...kidneyDetails.etiologies.map((e) => [
                {
                  text: `${e.etiology ? e.etiology : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              // [
              //   {
              //     text: `Etiology of ESKD`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   kidneyDetails.etiologies.map((e) => [{ text: `${e.etiology ? e.etiology : '-'}` }]),

              //   {},
              // ],
              // [
              //   {
              //     text: `Etiology Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   kidneyDetails.etiologies.map((et) => [
              //     {
              //       text: `${et.attachment?.name ? 'ATTACHED' : '-'}`,
              //     },
              //   ]),

              //   {
              //     text: '',
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: 'Etiology Details ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${kidneyDetails?.etiologyDetails ? kidneyDetails?.etiologyDetails : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'MKAS Score ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.recipientMkasScore ? basicDetails?.recipientMkasScore : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: 'Nephrologist',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   kidneyDetails?.nephrologistTeam?.map((e) => [{ text: `${e.user.name || '-'}` }]),

              //   {},
              // ],
              // [
              //   {
              //     text: 'Transplant Surgeon',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   kidneyDetails?.transplantSurgeon?.map((e) => [{ text: `${e.user.name || '-'}` }]),

              //   {},
              // ],
            ],
          },
        },

        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Nephrologist', style: 'tableHeader', alignment: 'left' },

                { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
              ],
              [
                (kidneyDetails?.nephrologistTeam || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
                (kidneyDetails?.transplantSurgeon || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
              ],
            ],
          },
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto'],
            heights: [0, 50, 50, 50, 50, 50],
            body: [
              [
                {
                  text: 'All verification of the clinical details / reports / criteria eligibility to justify above criteria is the responsibility of the transplant team signing this form. \n1. All the reports (Lab reports + imaging report) should be within 1 month at the time of registration. The patients with older reports should not be considered for registration.\n2. All lab reports should be within 1 month and imaging should be within 3 months for allocation, patients with older reports should not be considered for allocation.',
                  style: 'tableHeader',
                  colSpan: 3,
                  // alignment: 'center',
                  // bold: true,
                  fontSize: 11,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Patient Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Surgeon Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Physician Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Transplant Coordinator Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Signature of Hospital Head:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
        subHeader: {
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 5],
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Kidney Registration Form`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default KidneyRegistrationFormPdf;
