export const DISTRICTS_HR = [
  {
    label: 'Ambala',
    value: 'AMBALA'
  },
  {
    label: 'Bhiwani',
    value: 'BHIWANI'
  },
  {
    label: 'Charkhi Dadri',
    value: 'CHARKHI_DADRI'
  },
  {
    label: 'Faridabad',
    value: 'FARIDABAD'
  },
  {
    label: 'Fatehabad',
    value: 'FATEHABAD'
  },
  {
    label: 'Gurugram (Gurgaon)',
    value: 'GURUGRAM'
  },
  {
    label: 'Hisar',
    value: 'HISAR'
  },
  {
    label: 'Jhajjar',
    value: 'JHAJJAR'
  },
  {
    label: 'Jind',
    value: 'JIND'
  },
  {
    label: 'Kaithal',
    value: 'KAITHAL'
  },
  {
    label: 'Karnal',
    value: 'KARNAL'
  },
  {
    label: 'Kurukshetra',
    value: 'KURUKSHETRA'
  },
  {
    label: 'Mahendragarh',
    value: 'MAHENDRAGARH'
  },
  {
    label: 'Nuh',
    value: 'NUH'
  },
  {
    label: 'Palwal',
    value: 'PALWAL'
  },
  {
    label: 'Panchkula',
    value: 'PANCHKULA'
  },
  {
    label: 'Panipat',
    value: 'PANIPAT'
  },
  {
    label: 'Rewari',
    value: 'REWARI'
  },
  {
    label: 'Rohtak',
    value: 'ROHTAK'
  },
  {
    label: 'Sirsa',
    value: 'SIRSA'
  },
  {
    label: 'Sonipat',
    value: 'SONIPAT'
  },
  {
    label: 'Yamunanagar',
    value: 'YAMUNANAGAR'
  }
];
