export const DISTRICTS_JK = [
  {
    label: 'Anantnag',
    value: 'ANANTNAG'
  },
  {
    label: 'Bandipore',
    value: 'BANDIPORE'
  },
  {
    label: 'Baramulla',
    value: 'BARAMULLA'
  },
  {
    label: 'Budgam',
    value: 'BUDGAM'
  },
  {
    label: 'Doda',
    value: 'DODA'
  },
  {
    label: 'Ganderbal',
    value: 'GANDERBAL'
  },
  {
    label: 'Jammu',
    value: 'JAMMU'
  },
  {
    label: 'Kathua',
    value: 'KATHUA'
  },
  {
    label: 'Kishtwar',
    value: 'KISHTWAR'
  },
  {
    label: 'Kulgam',
    value: 'KULGAM'
  },
  {
    label: 'Kupwara',
    value: 'KUPWARA'
  },
  {
    label: 'Poonch',
    value: 'POONCH'
  },
  {
    label: 'Pulwama',
    value: 'PULWAMA'
  },
  {
    label: 'Rajouri',
    value: 'RAJOURI'
  },
  {
    label: 'Ramban',
    value: 'RAMBAN'
  },
  {
    label: 'Reasi',
    value: 'REASI'
  },
  {
    label: 'Samba',
    value: 'SAMBA'
  },
  {
    label: 'Shopian',
    value: 'SHOPIAN'
  },
  {
    label: 'Srinagar',
    value: 'SRINAGAR'
  },
  {
    label: 'Udhampur',
    value: 'UDHAMPUR'
  }
];
