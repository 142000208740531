import { DISTRICTS_AN } from './districtsAn';
import { DISTRICTS_AP } from './districtsAp';
import { DISTRICTS_AR } from './districtsAr';
import { DISTRICTS_AS } from './districtsAs';
import { DISTRICTS_BR } from './districtsBr';
import { DISTRICTS_CG } from './districtsCg';
import { DISTRICTS_CH } from './districtsCh';
import { DISTRICTS_DD } from './districtsDd';
import { DISTRICTS_DN } from './districtsDn';
import { DISTRICTS_DL } from './districtsDl';
import { DISTRICTS_GA } from './districtsGa';
import { DISTRICTS_GJ } from './districtsGj';
import { DISTRICTS_HR } from './districtsHr';
import { DISTRICTS_HP } from './districtsHp';
import { DISTRICTS_JK } from './districtsJk';
import { DISTRICTS_JH } from './districtsJh';
import { DISTRICTS_LD } from './districtsLd';
import { DISTRICTS_MP } from './districtsMp';
import { DISTRICTS_MH } from './districtsMh';
import { DISTRICTS_MN } from './districtsMn';
import { DISTRICTS_ML } from './districtsMl';
import { DISTRICTS_MZ } from './districtsMz';
import { DISTRICTS_NL } from './districtsNl';
import { DISTRICTS_OR } from './districtsOr';
import { DISTRICTS_PY } from './districtsPy';
import { DISTRICTS_PB } from './districtsPb';
import { DISTRICTS_KA } from './districtsKa';
import { DISTRICTS_KL } from './districtskl';
import { DISTRICTS_RJ } from './districtsRj';
import { DISTRICTS_SK } from './districtsSk';
import { DISTRICTS_TN } from './districtsTn';
import { DISTRICTS_TG } from './districtsTg';
import { DISTRICTS_TR } from './districtsTr';
import { DISTRICTS_UK } from './districtsUk';
import { DISTRICTS_UP } from './districtsUp';
import { DISTRICTS_WB } from './districtsWb';
import { STATE_IN } from '../statesIndia';

export const districts = (state) => {
  switch (state) {
    case STATE_IN[0].value:
      return DISTRICTS_AN;
    case STATE_IN[1].value:
      return DISTRICTS_AP;
    case STATE_IN[2].value:
      return DISTRICTS_AR;
    case STATE_IN[3].value:
      return DISTRICTS_AS;
    case STATE_IN[4].value:
      return DISTRICTS_BR;
    case STATE_IN[5].value:
      return DISTRICTS_CH;
    case STATE_IN[6].value:
      return DISTRICTS_CG;
    case STATE_IN[7].value:
      return DISTRICTS_DN;
    case STATE_IN[8].value:
      return DISTRICTS_DD;
    case STATE_IN[9].value:
      return DISTRICTS_DL;
    case STATE_IN[10].value:
      return DISTRICTS_GA;
    case STATE_IN[11].value:
      return DISTRICTS_GJ;
    case STATE_IN[12].value:
      return DISTRICTS_HR;
    case STATE_IN[13].value:
      return DISTRICTS_HP;
    case STATE_IN[14].value:
      return DISTRICTS_JK;
    case STATE_IN[15].value:
      return DISTRICTS_JH;
    case STATE_IN[16].value:
      return DISTRICTS_KA;
    case STATE_IN[17].value:
      return DISTRICTS_KL;
    case STATE_IN[18].value:
      return DISTRICTS_LD;
    case STATE_IN[19].value:
      return DISTRICTS_MP;
    case STATE_IN[20].value:
      return DISTRICTS_MH;
    case STATE_IN[21].value:
      return DISTRICTS_MN;
    case STATE_IN[22].value:
      return DISTRICTS_ML;
    case STATE_IN[23].value:
      return DISTRICTS_MZ;
    case STATE_IN[24].value:
      return DISTRICTS_NL;
    case STATE_IN[25].value:
      return DISTRICTS_OR;
    case STATE_IN[26].value:
      return DISTRICTS_PY;
    case STATE_IN[27].value:
      return DISTRICTS_PB;
    case STATE_IN[28].value:
      return DISTRICTS_RJ;
    case STATE_IN[29].value:
      return DISTRICTS_SK;
    case STATE_IN[30].value:
      return DISTRICTS_TN;
    case STATE_IN[31].value:
      return DISTRICTS_TG;
    case STATE_IN[32].value:
      return DISTRICTS_TR;
    case STATE_IN[33].value:
      return DISTRICTS_UK;
    case STATE_IN[34].value:
      return DISTRICTS_UP;
    case STATE_IN[35].value:
      return DISTRICTS_WB;
    default:
      return [];
  }
};
