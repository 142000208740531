import React from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import { VALIDATION } from '../../constants';

export default function ValidatedDate({
  value,
  onChange,
  onValidation,
  required,
  allowEmpty,
  showValid,
  validationDisabled,
  type,
  ...other
}) {
  let isDirty = false;

  const FORMAT_DATE_UNIXTS = 'X';
  const FORMAT_DATE_YYYYMMDD = 'YYYY-MM-DD';
  // eslint-disable-next-line prefer-const
  let { validation, schema, ...otherProps } = other;
  let valid = true;
  let messages = [];
  let joiError = null;
  if (validation === true) {
    validation = VALIDATION.ALL;
  } else if (validation === false) {
    validation = VALIDATION.OFF;
  }

  if (
    !validationDisabled &&
    (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && isDirty))
  ) {
    if (allowEmpty && Object.is(value, NaN)) {
      valid = true;
      messages = [];
    } else if (
      ((typeof value === 'string' && !value?.includes('T')) || typeof value === 'number') &&
      !moment(value, FORMAT_DATE_UNIXTS, true).isValid()
    ) {
      valid = false;
      messages = ['Should be a valid date'];
      joiError = new Error('Should be a valid date');
    } else if (typeof value === 'string' && value?.includes('T')) {
      if (!moment(new Date(value).getTime(), FORMAT_DATE_UNIXTS, true).isValid()) {
        valid = false;
        messages = ['Should be a valid date'];
        joiError = new Error('Should be a valid date');
      }
    } else {
      if (!schema || !Joi.isSchema(schema)) {
        schema = Joi.date();
      }
      schema = schema.messages({
        'any.required': 'Cannot be empty',
        'string.empty': 'Cannot be empty',
        'date.base': 'Should be a valid date',
        'date.greater': 'Cannot be greater than {#limit}',
        // 'date.greater': 'Cannot be older than past 30 days',
        'date.less': 'Cannot be less than {#limit}',
        'date.max': 'Cannot be future date than today',
        'date.min': 'Cannot be past date than today',
      });
      if (required) {
        schema = schema.required();
      }
      if (validation & VALIDATION.INVALIDATE_EMPTY) {
        schema = schema.required();
      }
      if (schema._flags.presence === 'required' && (!value || value === '')) {
        // TO HANDLE value === 0
        valid = false;
        messages = ['Cannot be empty'];
        joiError = new Error('Cannot be empty');
      } else {
        const dateValue = moment(value, FORMAT_DATE_UNIXTS).format(FORMAT_DATE_YYYYMMDD);
        const { error } = schema.validate(dateValue);
        if (error) {
          valid = false;
          messages = error.details.map((d) => d.message);
          joiError = error;
        }
      }
    }
    onValidation(valid, messages, joiError);
  } else {
    valid = true;
    messages = [];
  }

  return (
    <>
      <TextField
        error={!!validation && !valid}
        helperText={messages[0]}
        type={type || 'date'}
        {...otherProps}
        onChange={(e, ...params) => {
          if (!isDirty) {
            isDirty = true;
          }
          onChange(
            type === 'datetime-local'
              ? e.target.value
              : Math.floor(new Date(e.target.value).getTime() / 1000),
            ...params
          );
        }}
        InputProps={{ inputProps: { min: '1940-01-01' } }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        required={required}
        value={
          // eslint-disable-next-line no-nested-ternary
          value === 0 || !value
            ? null
            : type === 'datetime-local'
            ? value
            : moment(value, FORMAT_DATE_UNIXTS).format(FORMAT_DATE_YYYYMMDD)
        }
        size="small"
      />
    </>
  );
}
ValidatedDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  schema: PropTypes.instanceOf(Joi.constructor),
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  validationDisabled: PropTypes.bool,
  showValid: PropTypes.bool,
  required: PropTypes.bool,
  allowEmpty: PropTypes.bool,
};
ValidatedDate.defaultProps = {
  value: null,
  validation: false,
  schema: undefined,
  onChange: () => 'onChange STUB',
  onValidation: () => 'onValidation STUB',
  validationDisabled: false,
  showValid: false,
  required: false,
  allowEmpty: false,
};
