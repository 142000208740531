import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { Card, CardBody } from 'reactstrap';
import classNames from 'classnames';
// import { mapToCssModules } from 'reactstrap/lib/utils';

class HospitalUserWidget extends PureComponent {
  render() {
    const { className, cssModule, header, mainText, icon, color, ...attributes } = this.props;

    const padding = { card: 'p-3', icon: 'p-3', lead: 'mt-2' };

    const card = { style: 'clearfix', color, icon, classes: '' };
    // card.classes = mapToCssModules(classNames(className, card.style, padding.card), cssModule);

    const lead = { style: 'h5 mb-0', color, classes: '' };
    lead.classes = classNames(lead.style, `text-${card.color}`, padding.lead);

    return (
      // <Card>
      //   <CardBody className={card.classes} {...attributes}>
      //     {blockIcon(card.icon)}
      //     <div className={lead.classes}>{header}</div>
      //     <div className="text-muted text-uppercase font-weight-bold font-xs">{mainText}</div>
      //   </CardBody>
      // </Card>
      null
    );
  }
}
HospitalUserWidget.propTypes = {
  header: PropTypes.string,
  mainText: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.objectOf(PropTypes.any),
};

HospitalUserWidget.defaultProps = {
  header: '$1,999.50',
  mainText: 'Income',
  icon: 'fa fa-cogs',
  color: 'primary',
  className: undefined,
  cssModule: undefined,
};

export default HospitalUserWidget;
