import React, { Component } from 'react';
// import {
//   InputGroup,
//   InputGroupAddon,
//   Row,
//   Col,
//   Button,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
// } from 'reactstrap';
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { VALIDATION } from '../../../constants';
import { ValidatedInput } from '../../Custom';
import RecipientHospitalPicker from './recipientHospitalPicker';
import StyledDialogTitle from '../../common/StyledDialogTitle';

class ValidatedHospitalInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectHospitalModal: false,
    };
  }

  _toggleSelectHospitalModal(show) {
    this.setState({
      selectHospitalModal: show,
    });
  }

  render() {
    return (
      <>
        {this._renderSpecialistInput()}
        {this._renderSelectHospitalModal()}
      </>
    );
  }

  _renderSpecialistInput() {
    const { hospitalDetails, disabled, showValid, onValidation, formStatus } = this.props;
    let { validation } = this.props;
    let valid = true;
    let messages = [];
    let joiError = null;
    const style = {};
    if (validation === true) {
      validation = VALIDATION.ALL;
    } else if (validation === false) {
      validation = VALIDATION.OFF;
    }
    if (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && this.isDirty)) {
      if (!hospitalDetails || !hospitalDetails.hospitalId) {
        valid = false;
        messages = ['Cannot be empty'];
        joiError = new Error('Cannot be empty');
        style.borderColor = 'red';
      } else if (showValid) {
        style.borderColor = 'green';
      }
      onValidation(valid, messages, joiError);
    } else {
      valid = true;
      messages = [];
    }

    return (
      <>
        {hospitalDetails && hospitalDetails.hospitalId ? (
          // <InputGroup>
          //   <ValidatedInput
          //     type="text"
          //     value={hospitalDetails.name}
          //     disabled={true}
          //     placeholder="hospital"
          //     validation={VALIDATION.OFF}
          //   />
          //   {!disabled ? (
          //     <InputGroupAddon addonType="append">
          //       <Button
          //         type="button"
          //         color="primary"
          //         onClick={() => {
          //           this._toggleSelectHospitalModal(true);
          //         }}
          //       >
          //         Change
          //       </Button>
          //     </InputGroupAddon>
          //   ) : null}
          // </InputGroup>
          <Box>
            <ValidatedInput
              type="text"
              value={hospitalDetails.name}
              disabled={true}
              placeholder="hospital"
              validation={VALIDATION.OFF}
            />
            {!disabled && formStatus !== 'SUBMITTED' ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this._toggleSelectHospitalModal(true);
                }}
              >
                Change
              </Button>
            ) : null}
          </Box>
        ) : (
          <>
            {!disabled ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this._toggleSelectHospitalModal(true);
                  }}
                  style={style}
                >
                  Select
                </Button>
                <p
                  className={classnames({
                    'invalid-feedback': validation && !valid,
                    'valid-feedback': validation && showValid && valid,
                  })}
                  style={
                    (validation && !valid) || (validation && showValid && valid)
                      ? { display: 'block', color: 'red' }
                      : { display: 'none' }
                  }
                >
                  {messages[0]}
                </p>
              </>
            ) : null}
          </>
        )}
      </>
    );
  }

  _renderSelectHospitalModal() {
    const { selectHospitalModal } = this.state;
    const { hospital, onChange } = this.props;
    return (
      <Dialog
        open={selectHospitalModal}
        onClose={() => {
          this._toggleSelectHospitalModal(false);
        }}
        maxWidth="lg"
        fullWidth
        disableEnforceFocus
      >
        <StyledDialogTitle>
          <Grid container justify="space-between" alignItems="center">
            Add Hospital
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                this._toggleSelectHospitalModal(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </StyledDialogTitle>
        <DialogContent style={{ margin: 20 }}>
          <Grid container sm={12} xs={12} spacing={2}>
            <RecipientHospitalPicker selectedHospital={hospital} selectHospital={onChange} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this._toggleSelectHospitalModal(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
ValidatedHospitalInput.propTypes = {
  hospitalDetails: PropTypes.objectOf(PropTypes.any),
  hospital: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onValidation: PropTypes.func,
  showValid: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};
ValidatedHospitalInput.defaultProps = {
  hospitalDetails: undefined,
  hospital: undefined,
  validation: false,
  onValidation: () => 'onValidation STUB',
  showValid: false,
};

export default ValidatedHospitalInput;
