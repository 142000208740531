import React, { useState, useEffect } from 'react';

import cloneDeep from 'lodash.clonedeep';
import { makeStyles, Paper, Box, Typography, Grid } from '@material-ui/core';

import { ResponsiveBar } from '@nivo/bar';

import { useSelector } from 'react-redux';

import { PATH_GETLOGINSTATISTICSDATA } from '../../appConstants';
import { useCancellableApiCall } from '../../hooks';

export default function StatisticsData() {
  const [loginContents, setLoginContents] = useState(
    cloneDeep({
      districtWiseTxData: {},
      countryWiseTxData: {},
      monthWiseGenderRatio: {
        male: {},
        female: {},
      },
      liveAndCadaverTransplantsTotal: {
        liveMonthly: {},
        cadaverMonthly: {},
      },
      transplantCoordinators: [],
      ztccZoneWiseData: [],
      organWiseData: [],
      cadaverOrLivingWiseOrganData: [],
      genderWiseOrganData: [],
    })
  );

  const app = useSelector((state) => state);
  const { apiGet } = useCancellableApiCall();
  const {
    user: {},
  } = app;

  const [setGetLoginApiId] = useState('APIID');
  useEffect(() => {
    apiGet(
      PATH_GETLOGINSTATISTICSDATA,
      {},
      { unAuthenticated: true },
      {
        apiIdCb: (apiId) => {
          setGetLoginApiId(apiId);
        },
      }
    )
      .then((dashboard) => {
        setLoginContents(cloneDeep({ ...dashboard }));
      })
      .catch(() => {});
  }, [apiGet]);

  const useStyles = makeStyles((theme) => ({
    paper: {
      width: '100%',
      maxWidth: '100%',
      height: '500px',
      maxHeight: '500px',
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    paperRecentActivites: {
      // CSS added for responsiveness

      // overflow: 'auto',
      // marginright: 'auto',
      // marginleft: 'auto',
      // margintop: '50px',
      // padding: '30px',
      // margin: '10px',

      // Earlier CSS

      width: '100%',
      maxWidth: '100%',
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(2),
      // marginLeft: theme.spacing(1),
      marginLeft: '0px',
      marginRight: theme.spacing(1),
      padding: theme.spacing(2),
      paddingLeft: '10px',
      paddingRight: '30px',

      // [theme.breakpoints.up('sm')]: {
      //   paddingLeft: theme.spacing(4),
      //   paddingRight: theme.spacing(4),
      // },
      // [theme.breakpoints.down('sm')]: {
      //   paddingLeft: theme.spacing(4),
      //   paddingRight: theme.spacing(4),
      // },
    },
    paperSelectedActivity: {
      width: '100%',
      maxWidth: '100%',
      height: '500px',
      maxHeight: '500px',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      // marginLeft: theme.spacing(1),
      marginLeft: '0px',

      marginRight: theme.spacing(1),
      paddingLeft: '25px',
      paddingRight: '32px',
      padding: '10px',
      // [theme.breakpoints.up('sm')]: {
      //   paddingLeft: theme.spacing(4),
      //   paddingRight: theme.spacing(4),
      // },
      // [theme.breakpoints.down('sm')]: {
      //   paddingLeft: theme.spacing(4),
      //   paddingRight: theme.spacing(4),
      // },
    },
    paperTxReports: {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      // marginLeft: theme.spacing(1),
      marginLeft: '0px',
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(4),
      paddingLeft: '0px',
      paddingRight: '40px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    recentActivitiesButton: {
      margin: '4px',
      padding: '15px',
      fontSize: '14px',
    },
    dashboardCtaButton: {
      padding: '10px',
      marginTop: '5px',
      marginBottom: '5px',
      marginLeft: '10px',
    },
  }));

  const classes = useStyles();

  return (
    <Box style={{ marginLeft: 10, marginRight: 10 }}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12} style={{ margin: 10 }}>
          <Paper className="container" style={{ marginTop: 5, marginBottom: 0 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography
                variant="h4"
                style={{
                  fontWeight: 800,
                  fontSize: '14px',
                  paddingTop: '20px',
                  paddingBottom: '30px',
                  marginLeft: '20px',
                }}
              >
                ZTCC Zone Wise Transplants
              </Typography>
            </Box>
            <Grid
              item
              sm={15}
              xs={10}
              lg={30}
              md={20}
              style={{
                textAlign: 'center',
                height: '350px',
                marginLeft: '15px',
                fontWeight: 900,
              }}
              id="districtBar"
            >
              <ResponsiveBar
                data={loginContents.ztccZoneWiseData}
                keys={loginContents.ztccZoneWiseData.map((z) => z.region)}
                indexBy="region"
                margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'category10' }}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'ZTCC Zone',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'No. of Transplants',
                  legendPosition: 'middle',
                  legendOffset: -50,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'row',
                    justify: false,
                    translateX: 50,
                    translateY: -270,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'top-to-bottom',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={function (e) {
                  return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`;
                }}
              />
            </Grid>
          </Paper>
        </Grid>

        <Grid item sm={12} xs={12} style={{ margin: 10 }}>
          <Paper className="container" style={{ marginTop: 5, marginBottom: 0 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography
                variant="h4"
                style={{
                  fontWeight: 800,
                  fontSize: '14px',
                  paddingTop: '20px',
                  paddingBottom: '30px',
                  marginLeft: '20px',
                }}
              >
                Organs Wise Transplants
              </Typography>
            </Box>
            <Grid
              item
              sm={15}
              xs={10}
              lg={30}
              md={20}
              style={{
                textAlign: 'justify',
                height: '350px',
                marginLeft: '15px',
                fontWeight: 900,
              }}
              id="districtBar"
            >
              <ResponsiveBar
                data={loginContents.organWiseData}
                keys={['HEART', 'LIVER', 'LUNGS', 'KIDNEY', 'INTESTINE', 'PANCREAS', 'HAND']}
                indexBy="organ"
                margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                padding={0.2}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'category10' }}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Organ',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'No.of Transplants',
                  legendPosition: 'middle',
                  legendOffset: -50,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'row',
                    justify: false,
                    translateX: 60,
                    translateY: -270,
                    itemsSpacing: 0,
                    itemWidth: 85,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 15,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={function (e) {
                  return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`;
                }}
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={12} xs={12} style={{ margin: 10 }}>
          <Paper className="container" style={{ marginTop: 5, marginBottom: 0 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography
                variant="h4"
                style={{
                  fontWeight: 800,
                  fontSize: '14px',
                  paddingTop: '20px',
                  paddingBottom: '30px',
                  marginLeft: '20px',
                }}
              >
                Gender Wise Organ Transplants
              </Typography>
            </Box>
            <Grid
              item
              sm={15}
              xs={10}
              lg={30}
              md={20}
              style={{
                textAlign: 'center',
                height: '350px',
                marginLeft: '15px',
                fontWeight: 800,
              }}
              id="districtBar"
            >
              <ResponsiveBar
                data={loginContents.genderWiseOrganData}
                keys={['MALE', 'FEMALE', 'OTHER']}
                indexBy="organ"
                margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                padding={0.2}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'category10' }}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Organ',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'No.of Transplants',
                  legendPosition: 'middle',
                  legendOffset: -50,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'row',
                    justify: false,
                    translateX: 80,
                    translateY: -280,
                    itemsSpacing: 2,
                    itemWidth: 90,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={function (e) {
                  return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`;
                }}
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={12} xs={12} style={{ margin: 10 }}>
          <Paper className="container" style={{ marginTop: 5, marginBottom: 0 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography
                variant="h4"
                style={{
                  fontWeight: 800,
                  fontSize: '14px',
                  paddingTop: '20px',
                  paddingBottom: '30px',
                  marginLeft: '20px',
                }}
              >
                Cadaver/Living Wise Organ Transplants
              </Typography>
            </Box>
            <Grid
              item
              sm={15}
              xs={10}
              lg={30}
              md={20}
              style={{
                textAlign: 'center',
                height: '350px',
                marginLeft: '15px',
                fontWeight: 800,
              }}
              id="districtBar"
            >
              <ResponsiveBar
                data={loginContents.cadaverOrLivingWiseOrganData}
                keys={['CADAVER', 'LIVING']}
                indexBy="organ"
                margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                padding={0.2}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'category10' }}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Organ',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'No.of Transplants',
                  legendPosition: 'middle',
                  legendOffset: -50,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'row',
                    justify: false,
                    translateX: 80,
                    translateY: -280,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={function (e) {
                  return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`;
                }}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
