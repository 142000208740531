import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import { EYE_DONOR } from '../../../../emptyModels/eyebank';
import { withCancellableResolver, withValidation } from '../../../../hoc';
import { isHospitalIcUserType, VALIDATION, isDhsUserType, isEyeBank } from '../../../../constants';
import { OPTIONS_EYEDONORSTATUS } from '../../../../appConstants/eyebank';
import EyeDonorUi from './EyeDonorUi';
import {
  submitDraftEyeDonor,
  submitEyeDonorEdits,
  submitEyeDonor,
  getEyeDonorDraft,
  getEyeDonor
} from './eyeDonorScripts';

class EyeDonor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialEyeDonor: cloneDeep(EYE_DONOR),
      eyeDonor: cloneDeep(EYE_DONOR),
      eyeDonorId: '',
      donorEvaluationDropdown: false,
      editMode: false,
      draftId: '',
      redirectToSaved: false,
      isFormFilled: false,
      redirectToSubmittedEyeDonor: false,
      redirectToNew: false,
      eyeSide: 'BOTHEYES'
    };
  }

  _isDisabled() {
    const { eyeDonor, editMode } = this.state;
    return !(
      eyeDonor.formStatus === OPTIONS_EYEDONORSTATUS.NEW ||
      eyeDonor.formStatus === OPTIONS_EYEDONORSTATUS.DRAFT ||
      editMode
    );
  }

  _toggleEdit(editMode) {
    this.setState({
      editMode
    });
  }

  _toggleDonorEvaluationDropdown() {
    const { donorEvaluationDropdown } = this.state;
    this.setState({
      donorEvaluationDropdown: !donorEvaluationDropdown
    });
  }

  render() {
    const {
      eyeDonor,
      isFormFilled,
      draftId,
      eyeDonorId,
      donorEvaluationDropdown,
      redirectToSaved,
      editMode,
      redirectToNew,
      redirectToSubmittedEyeDonor,
      eyeSide
    } = this.state;

    if (redirectToNew || !eyeDonor) {
      return <Redirect to="/eyebank/transplant/hospital/eyedonor/new" />;
    }
    if (redirectToSaved) {
      return <Redirect to={`/eyebank/transplant/hospital/eyedonor/saved/${draftId}`} />;
    }
    if (redirectToSubmittedEyeDonor) {
      return <Redirect to={`/eyebank/transplant/hospital/eyedonor/${eyeDonorId}`} />;
    }
    const { formStatus, corneas } = eyeDonor;
    const {
      validation,
      onValidation,
      userName,
      userContact,
      userType,
      hospitalTypes,
      hospitalName,
      hospitalId
    } = this.props;
    let rightCorneaId;
    let leftCorneaId;

    corneas.forEach(cornea => {
      if (cornea.corneaType === 'Left') {
        leftCorneaId = cornea.corneaId;
      } else if (cornea.corneaType === 'Right') {
        rightCorneaId = cornea.corneaId;
      }
    });

    let path;
    if (isDhsUserType(userType)) {
      path = `/eyebank/transplant/dhs/eyedonor/donorevaluation/${eyeDonorId}/cornea/`;
    } else {
      path = `/eyebank/transplant/hospital/eyedonor/donorevaluation/${eyeDonorId}/cornea/`;
    }

    return (
      <div className="animated fadeIn" style={{ marginTop: '100px' }}>
        <Card>
          <CardHeader>
            <Row>
              <Col lg="3" className="mt-1">
                <i className="fa fa-hospital-o" /> Eye Donor Submission
              </Col>
              {formStatus === OPTIONS_EYEDONORSTATUS.SUBMITTED ? (
                <React.Fragment>
                  <Col lg="2" className="offset-lg-2">
                    <Button color="primary" onClick={() => this._toggleEdit(true)}>
                      <i className="fa fa-edit" /> Edit
                    </Button>
                    {editMode && (
                      <Button type="button" color="danger" onClick={() => this._toggleEdit(false)}>
                        <i className="fa fa-window-close" />
                      </Button>
                    )}
                  </Col>
                  {isEyeBank(hospitalTypes) ? (
                    <Col className="offset-lg-3" lg="2">
                      <ButtonDropdown
                        isOpen={donorEvaluationDropdown}
                        toggle={() => {
                          this._toggleDonorEvaluationDropdown();
                        }}
                        color="primary"
                      >
                        <DropdownToggle caret color="primary">
                          Donor Evaluation
                        </DropdownToggle>
                        <DropdownMenu>
                          {eyeSide === 'BOTHEYES' || eyeSide === 'RIGHTEYE' ? (
                            <Link to={`${path}${rightCorneaId}`}>
                              <DropdownItem>Right Cornea</DropdownItem>
                            </Link>
                          ) : null}
                          {eyeSide === 'BOTHEYES' || eyeSide === 'LEFTEYE' ? (
                            <Link to={`${path}${leftCorneaId}`}>
                              <DropdownItem>Left Cornea</DropdownItem>
                            </Link>
                          ) : null}
                        </DropdownMenu>
                      </ButtonDropdown>
                    </Col>
                  ) : null}
                </React.Fragment>
              ) : null}
            </Row>
          </CardHeader>
          <CardBody>
            <EyeDonorUi
              eyeDonor={eyeDonor}
              hospitalId={hospitalId}
              hospitalTypes={hospitalTypes}
              hospitalName={hospitalName}
              user={{ userName, userContact }}
              validation={validation}
              onValidation={onValidation}
              component={this}
              disabled={this._isDisabled()}
            />
          </CardBody>
          <CardFooter>
            <Row>
              <Col lg="2" className="offset-lg-4">
                {this._renderFooterMiddleButton()}
              </Col>
              <Col lg="2">
                {formStatus === OPTIONS_EYEDONORSTATUS.DRAFT ? (
                  <Button
                    id="submitButton"
                    block
                    onClick={() => {
                      this._submitEyeDonor();
                    }}
                    disabled={!isFormFilled}
                    color="primary"
                  >
                    <i className="fa fa-paper-plane" /> Submit
                  </Button>
                ) : null}
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </div>
    );
  }

  _renderFooterMiddleButton() {
    const {
      editMode,
      eyeDonor: { formStatus }
    } = this.state;
    const { userType } = this.props;
    if (isHospitalIcUserType(userType) && formStatus === OPTIONS_EYEDONORSTATUS.NEW) {
      return (
        <Button block color="primary" onClick={() => this._submitEyeDraftDonor()}>
          Save
        </Button>
      );
    }
    if (
      formStatus === OPTIONS_EYEDONORSTATUS.DRAFT ||
      formStatus === OPTIONS_EYEDONORSTATUS.SUBMITTED
    ) {
      return (
        <Button
          block
          color="primary"
          disabled={formStatus === OPTIONS_EYEDONORSTATUS.SUBMITTED && !editMode}
          onClick={() => this._submitEyeDonorEdits()}
        >
          Save
        </Button>
      );
    }
    return '';
  }

  _submitEyeDraftDonor() {
    const { validate } = this.props;
    validate(VALIDATION.DIRTY, valid => {
      if (!valid) {
        return;
      }
      const { eyeDonor } = this.state;
      eyeDonor.created = Math.floor(Date.now() / 1000);
      const { resolveWithCancellable, hospitalId, hospitalName } = this.props;
      eyeDonor.hospitalId = hospitalId;
      eyeDonor.hospitalName = hospitalName;
      eyeDonor.formStatus = OPTIONS_EYEDONORSTATUS.DRAFT;

      resolveWithCancellable(submitDraftEyeDonor(eyeDonor, hospitalId, hospitalName))
        .then(newEyeDonor => {
          const { draftId } = newEyeDonor;
          this.setState({
            draftId,
            redirectToSaved: true
          });
        })
        .catch(() => { });
    });
  }

  _submitEyeDonorEdits() {
    const { validate } = this.props;
    validate(VALIDATION.DIRTY, valid => {
      if (!valid) {
        return;
      }
      const { initialEyeDonor, eyeDonor } = this.state;
      const { resolveWithCancellable, hospitalId, hospitalName } = this.props;
      resolveWithCancellable(
        submitEyeDonorEdits(initialEyeDonor, eyeDonor, hospitalId, hospitalName)
      )
        .then(editedEyeDonor => {
          this.valid = true;
          this.setState(
            {
              initialEyeDonor: cloneDeep(editedEyeDonor),
              eyeDonor: editedEyeDonor
            },
            () => {
              validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, isValid => {
                this.setState({
                  isFormFilled: isValid
                });
              });
            }
          );
        })
        .catch(() => { });
    });
  }

  _submitEyeDonor() {
    const { validate } = this.props;
    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, valid => {
      if (!valid) {
        return;
      }
      const { eyeDonor } = this.state;
      const { resolveWithCancellable, hospitalId, userId } = this.props;
      resolveWithCancellable(submitEyeDonor(eyeDonor, hospitalId, userId))
        .then(updatedEyeDonor => {
          validate(VALIDATION.OFF, () => {
            this.setState({
              initialEyeDonor: cloneDeep(updatedEyeDonor),
              eyeDonor: updatedEyeDonor,
              eyeDonorId: updatedEyeDonor.eyeDonorId,
              redirectToSubmittedEyeDonor: true
            });
          });
        })
        .catch(() => { });
    });
  }

  _getEyeDonorDraft(draftId) {
    const { resolveWithCancellable, hospitalId, validate } = this.props;
    resolveWithCancellable(getEyeDonorDraft(draftId, hospitalId))
      .then(eyeDonor => {
        this.setState(
          {
            redirectToNew: !eyeDonor,
            initialEyeDonor: cloneDeep(eyeDonor),
            eyeDonor
          },
          () => {
            validate(
              VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY,
              valid => {
                this.setState({
                  isFormFilled: valid
                });
              },
              false
            );
          }
        );
      })
      .catch(() => { });
  }

  _getEyeDonor(eyeDonorId) {
    const { resolveWithCancellable, hospitalId, validate, userType } = this.props;
    resolveWithCancellable(getEyeDonor(eyeDonorId, hospitalId, userType))
      .then(eyeDonor => {
        this.valid = true;
        this.setState(
          {
            redirectToNew: !eyeDonor,
            initialEyeDonor: cloneDeep(eyeDonor),
            eyeDonor
          },
          () => {
            validate(
              VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY,
              valid => {
                this.setState({
                  isFormFilled: valid
                });
              },
              false
            );
          }
        );
      })
      .catch(() => { });
  }

  componentDidMount() {
    const { isDemo } = this.props;
    const {
      match: {
        params: { draftId, eyeDonorId, tissueNumber }
      }
    } = this.props;

    this.setState({
      eyeDonorId,
      eyeSide: tissueNumber || 'BOTHEYES'
    });
    if (draftId) {
      this._getEyeDonorDraft(draftId);
    } else if (eyeDonorId) {
      this._getEyeDonor(eyeDonorId);
    } else if (isDemo) {
      this.setState({ eyeDonor: '' });
    }
  }
}
EyeDonor.propTypes = {
  resolveWithCancellable: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userContact: PropTypes.string.isRequired,
  hospitalId: PropTypes.string.isRequired,
  hospitalName: PropTypes.string.isRequired,
  isDemo: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eyeDonorId: PropTypes.string,
      draftId: PropTypes.string
    }).isRequired
  }).isRequired,
  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
  hospitalTypes: PropTypes.arrayOf(PropTypes.any).isRequired
};

const mapStateToProps = state => {
  const { isDemo } = state;
  return {
    isDemo,
    hospitalId: state.hospital.hospitalId,
    hospitalName: state.hospital.name,
    userId: state.user.userId,
    userType: state.user.userType,
    userName: state.user.name,
    userContact: state.user.contact,
    quorumNode: state.hospital.blockchain.quorumNode,
    hospitalTypes: state.hospital.hospitalTypes
  };
};

export default compose(
  withRouter,
  withCancellableResolver,
  withValidation,
  connect(mapStateToProps)
)(EyeDonor);
