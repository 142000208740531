import util from 'util';
import { isHospitalIcUserType } from '../../../../../constants';
import { apiEyebankGet } from '../../../../../utils';
import { PATH_HOSPITAL_GETEYERETRIEVALCENTERS } from '../../../../../appConstants/eyebank';

export const getHospitalsList = async (hospitalId, userType) => {
  let url;
  const query = {};
  if (isHospitalIcUserType(userType)) {
    url = util.format(PATH_HOSPITAL_GETEYERETRIEVALCENTERS, hospitalId);
  } else {
    throw new Error('Invalid user login');
  }
  try {
    return await apiEyebankGet(url, query);
  } catch (err) {
    throw err;
  }
};
