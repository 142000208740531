import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Form7Pdf {
  constructor(data) {
    const docDefnData = [];

    data.forEach((entry, idx) => {
      docDefnData.push([
        idx + 1,
        entry.recipient.nationality,
        entry.donor.nationality,
        entry.organ.organType,
      ]);
    });
    const reportDocDefinition = {
      content: [
        {
          text: 'Living Donor Transplants',
          style: 'header',
        },
        {
          table: {
            widths: ['*', '*', '*', '*'],
            body: [
              [
                {
                  style: 'textStyle',
                  text: 'Living Donor Transplants',
                  colSpan: 4,
                },
                ...new Array(3).fill({}),
              ],
              [
                {
                  style: 'textStyle',
                  text: 'Were Donor and Recipient are from different Country',
                  colSpan: 4,
                },
                ...new Array(3).fill({}),
              ],
              [
                {
                  style: 'textStyle',
                  text: 'SrNo',
                },
                {
                  style: 'textStyle',
                  text: 'Name of Country of origin of Recipient',
                },
                {
                  style: 'textStyle',
                  text: 'Name of Country of origin of Donor',
                },
                {
                  style: 'textStyle',
                  text: 'Organ Transplanted',
                },
              ],
              ...docDefnData,
            ],
          },
        },
      ],
      pageOrientation: 'portrait',
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
        },
        textStyle: {
          alignment: 'center',
        },
      },
    };
    this.reportDocDefinition = reportDocDefinition;
  }

  download(defaultFileName = `Donor Pledge`) {
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(defaultFileName);
  }
}

export default Form7Pdf;
