export const DISTRICTS_MZ = [
  {
    label: 'Aizawl',
    value: 'AIZAWL'
  },
  {
    label: 'Champhai',
    value: 'CHAMPHAI'
  },
  {
    label: 'Kolasib',
    value: 'KOLASIB'
  },
  {
    label: 'Lawngtlai',
    value: 'LAWNGTLAI'
  },
  {
    label: 'Lunglei',
    value: 'LUNGLEI'
  },
  {
    label: 'Mamit',
    value: 'MAMIT'
  },
  {
    label: 'Saiha',
    value: 'SAIHA'
  },
  {
    label: 'Serchhip',
    value: 'SERCHHIP'
  }
];
