import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { diff } from 'rus-diff';
import {
  makeStyles,
  Paper,
  Box,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import cloneDeep from 'lodash.clonedeep';
import Joi from 'joi';
import util from 'util';
import PropTypes from 'prop-types';
import { Redirect, withRouter, useRouteMatch, Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import StyledDialogTitle from '../common/StyledDialogTitle';
import { uploadDocument } from '../../utils';
import ValidatedInput from '../Custom/ValidatedInput';
import LoadableButton from '../Custom/LoadableButton';
import { ReactTable, ValidatedAttachment, ValidatedDate } from '../Custom';
import ValidatedSelect from '../Custom/ValidatedSelect';
import ValidatedRadio from '../Custom/ValidatedRadio';
import { ReactComponent as GovernmentSvg } from '../svgs/Government.svg';
import ValidatedOtherSupportiveSpecialists from '../Transplant/RecipientValidatedOtherSupportiveSpecialists/ValidatedOtherSupportiveSpecialists';
import ValidatedHospitalInput from './ValidatedHospitalInput/ValidatedHospitalInput';
import OtherHospitalValidatedOtherSupportiveSpecialists from '../Transplant/DonorValidatedOtherSupportiveSpecialists/ValidatedOtherSupportiveSpecialists';
import OtherHospitalValidatedSpecialistInput from './DonorValidatedSpecialistInput/ValidatedSpecialistInput';
import { withValidation } from '../../hoc';
import { useCancellableApiCall } from '../../hooks';
import {
  BLOODGROUP,
  SEX,
  VALIDATION,
  DONOR_FORMSTATUS,
  BSDORGAN,
  TISSUE,
  OPTIONS_ALLOCATIONSTATUS,
  OPTIONS_YESNO,
  OPTIONS_MEDICOLEGALCASE,
  OPTIONS_TXFORMTYPE,
  ORGANS,
  RECIPIENT_HOSPITAL,
  REGEXP_ONLYCHARS,
  FILESUFFIX_FORMAT_DATE,
  FILEPREFIX,
  isHospitalIcUserType,
  RECIPIENTHEART_FORMSTATUS,
  RECIPIENT_HOSPITALTYPE,
  isZtccUserType,
  OPTIONS_REASON_FOR_NOT_DONATING_ORGANS,
  REGEXEXP_NOWHITESPACEATENDANDBEGIN,
  REGEXEXP_ALPHANUMERIC,
  REGEXP_ONLYNUMBERS,
} from '../../constants';
import { ORGAN_DONOR as EMPTY_DONOR } from '../../emptyModels';
import { OPTIONS_NATIONALITY } from '../../nationalities';
import { FILETYPES } from '../Custom/ValidatedAttachment';
import { STATE_IN } from '../../statesIndia';
import { districts } from '../../districts/districts';

import {
  PATH_EXP_GETDONORHOSPITALTOPRECIPIENTHEARTSUMMARIES,
  PATH_EXP_GETDONORHOSPITALTOPRECIPIENTKIDNEYSUMMARIES,
  PATH_EXP_GETDONORHOSPITALTOPRECIPIENTLIVERSUMMARIES,
  PATH_EXP_GETDONORHOSPITALTOPRECIPIENTLUNGSSUMMARIES,
  PATH_EXP_GETDONORHOSPITALTOPRECIPIENTPANCREASSUMMARIES,
  PATH_EXP_GETDRAFTORGANDONOR,
  PATH_EXP_GETORGANCALLSUMMARIESBYDONORID,
  PATH_EXP_GETORGANDONOR,
  PATH_EXP_GETTOPRECIPIENTHEARTSUMMARIES,
  PATH_EXP_GETTOPRECIPIENTKIDNEYSUMMARIES,
  PATH_EXP_GETTOPRECIPIENTLIVERSUMMARIES,
  PATH_EXP_GETTOPRECIPIENTLUNGSSUMMARIES,
  PATH_EXP_GETTOPRECIPIENTPANCREASSUMMARIES,
  PATH_EXP_SUBMITDRAFTORGANDONOR,
  PATH_EXP_SUBMITORGANCALL,
  PATH_EXP_SUBMITORGANDONOREDITS,
  PATH_EXP_UPDATEORGANDONORSTATUS,
} from '../../appConstants';
import GreenTagSectionHeader from '../common/GreenTagSectionHeader';
import OrganDetails from './OrganDetails';
import TissueDetails from './TissueDetails';
import DataLoadingScreen from '../Custom/DataLoadingScreen';

function BsdDonor(props) {
  // const [initialBsdDonor, setInitialBsdDonor] = useState(cloneDeep(EMPTY_DONOR));
  const bsdDonorRef = useRef({ ...EMPTY_DONOR });
  const [bsdDonor, setBsdDonor] = useState({ ...EMPTY_DONOR });
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [draftId, setDraftId] = useState('');
  const [redirectToSaved, setRedirectToSaved] = useState(false);
  const [redirectToRecord, setRedirectToRecord] = useState(false);
  const [currentOrganRecipients, setCurrentOrganRecipients] = useState([]);
  const [selectedOrganRecipients, setSelectedOrganRecipients] = useState([]);
  const [recipientHospitalType, setRecipientHospitalType] = useState('INHOUSE');
  const [currentSelectedOrgan, setCurrentSelectedOrgan] = useState('');
  const [editMode] = useState(false);
  const [bsdDonorOrganCalls, setBsdDonorOrganCalls] = useState([]);
  const [selectedOrgans, setSelectedOrgans] = useState([]);
  const [selectedTissues, setSelectedTissues] = useState([]);
  const { apiPost, apiGet, apiPatch, apiCallStatuses } = useCancellableApiCall();
  const app = useSelector((state) => state);
  const {
    user: { hospitalId, userId, userType },
    hospital: { name: hospitalName },
  } = app;
  const match = useRouteMatch('/bsdDonor/saved/:draftId');
  const recordMatch = useRouteMatch('/bsdDonor/record/:donorId');
  const [isLoading, setIsLoading] = useState(false);

  // const { donorOrgans,donorTissues } = bsdDonor;

  const _getRecipientHospitalQuery = () => {
    switch (currentSelectedOrgan) {
      case 'HEART':
        return recipientHospitalType === 'INHOUSE'
          ? PATH_EXP_GETDONORHOSPITALTOPRECIPIENTHEARTSUMMARIES
          : PATH_EXP_GETTOPRECIPIENTHEARTSUMMARIES;
      case 'LEFTKIDNEY':
        return recipientHospitalType === 'INHOUSE'
          ? PATH_EXP_GETDONORHOSPITALTOPRECIPIENTKIDNEYSUMMARIES
          : PATH_EXP_GETTOPRECIPIENTKIDNEYSUMMARIES;

      case 'RIGHTKIDNEY':
        return recipientHospitalType === 'INHOUSE'
          ? PATH_EXP_GETDONORHOSPITALTOPRECIPIENTKIDNEYSUMMARIES
          : PATH_EXP_GETTOPRECIPIENTKIDNEYSUMMARIES;

      case 'LIVER':
        return recipientHospitalType === 'INHOUSE'
          ? PATH_EXP_GETDONORHOSPITALTOPRECIPIENTLIVERSUMMARIES
          : PATH_EXP_GETTOPRECIPIENTLIVERSUMMARIES;

      case 'LUNGS':
        return recipientHospitalType === 'INHOUSE'
          ? PATH_EXP_GETDONORHOSPITALTOPRECIPIENTLUNGSSUMMARIES
          : PATH_EXP_GETTOPRECIPIENTLUNGSSUMMARIES;

      case 'PANCREAS':
        return recipientHospitalType === 'INHOUSE'
          ? PATH_EXP_GETDONORHOSPITALTOPRECIPIENTPANCREASSUMMARIES
          : PATH_EXP_GETTOPRECIPIENTPANCREASSUMMARIES;
      default:
        break;
    }
  };

  useEffect(() => {
    let extractedDraftId;
    let extractedRecordId;
    const { validate } = props;
    if (match) {
      const {
        params: { draftId: id },
      } = match;
      extractedDraftId = id;
    }

    if (recordMatch) {
      const {
        params: { donorId: id },
      } = recordMatch;
      extractedRecordId = id;
    }

    if (extractedDraftId) {
      apiGet(PATH_EXP_GETDRAFTORGANDONOR, { hospitalId, draftId: extractedDraftId })
        .then((draftBsdDonor) => {
          setBsdDonor({ ...draftBsdDonor });
          // setInitialBsdDonor({ ...draftBsdDonor });
          bsdDonorRef.current = { ...draftBsdDonor };
          // eslint-disable-next-line no-unused-expressions
          const organsArray = [];
          // eslint-disable-next-line no-unused-expressions
          draftBsdDonor?.organs?.forEach((organ) => {
            organsArray.push(organ.organType);
          });
          setSelectedOrgans([...organsArray]);

          const tissuesArray = [];
          // eslint-disable-next-line no-unused-expressions
          draftBsdDonor?.tissues?.forEach((tissue) => {
            tissuesArray.push(tissue.tissueType);
          });
          setSelectedTissues([...tissuesArray]);
          validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (isValid) => {
            setIsFormFilled(isValid);
          });
        })
        .catch(() => {});
    }
    if (extractedRecordId) {
      apiGet(util.format(PATH_EXP_GETORGANDONOR, extractedRecordId), {})
        .then((bsdDonorRecord) => {
          setBsdDonor({ ...bsdDonorRecord });
          // setInitialBsdDonor({ ...bsdDonorRecord });
          bsdDonorRef.current = { ...bsdDonorRecord };
          const organsArray = [];
          // eslint-disable-next-line no-unused-expressions
          bsdDonorRecord?.organs?.forEach((organ) => {
            organsArray.push(organ.organType);
          });

          setSelectedOrgans([...organsArray]);
          const tissuesArray = [];
          // eslint-disable-next-line no-unused-expressions
          bsdDonorRecord?.tissues?.forEach((tissue) => {
            tissuesArray.push(tissue.tissueType);
          });
          setSelectedTissues([...tissuesArray]);
          setCurrentSelectedOrgan(bsdDonorRecord?.organs[0]?.organType);
          apiGet(_getRecipientHospitalQuery(), {
            formStatus: RECIPIENTHEART_FORMSTATUS.SUBMITTED,
            bloodGroup: bsdDonorRecord.bloodGroup,
            hospitalId: bsdDonorRecord.hospitalId,
          })
            .then((recipients) => {
              setCurrentOrganRecipients([...recipients]);
              setSelectedOrganRecipients([...recipients]);
            })
            .catch(() => {});
          apiGet(PATH_EXP_GETORGANCALLSUMMARIESBYDONORID, {
            donorId: extractedRecordId,
            hospitalId: bsdDonorRecord.hospitalId,
          })
            .then((organCalls) => {
              setBsdDonorOrganCalls(organCalls);
            })
            .catch(() => {});
        })
        .catch(() => {});
    }
  }, [apiGet, redirectToRecord, recipientHospitalType, currentSelectedOrgan]);

  const uploadFormDocuments = async () => {
    const {
      form8,
      form10,
      policeReport: { attachment: policeReport },
      relatedForms,
      firstApneaBaselineReport,
      secondApneaBaselineReport,
      firstApneaAfterTenMinutesReport,
      secondApneaAfterTenMinutesReport,
      photo,
      nottoIdAttachment,
    } = bsdDonor;
    const fileSuffix = moment().format(FILESUFFIX_FORMAT_DATE);

    const uploadPromises = [];

    if (photo) {
      uploadPromises.push(uploadDocument(photo, FILEPREFIX.PHOTO, hospitalName, fileSuffix));
    }
    if (form8) {
      uploadPromises.push(uploadDocument(form8, FILEPREFIX.FORM8, hospitalName, fileSuffix));
    }
    uploadPromises.push(
      uploadDocument(nottoIdAttachment, FILEPREFIX.DONORPHOTO, hospitalName, fileSuffix)
    );
    if (form10) {
      uploadPromises.push(uploadDocument(form10, FILEPREFIX.FORM10, hospitalName, fileSuffix));
    }
    if (policeReport) {
      uploadPromises.push(
        uploadDocument(policeReport, FILEPREFIX.POLICEREPORT, hospitalName, fileSuffix)
      );
    }

    if (firstApneaBaselineReport) {
      uploadPromises.push(
        uploadDocument(firstApneaBaselineReport, FILEPREFIX.FIRSTAPNEA, hospitalName, fileSuffix)
      );
    }

    if (secondApneaBaselineReport) {
      uploadPromises.push(
        uploadDocument(secondApneaBaselineReport, FILEPREFIX.SECONDAPNEA, hospitalName, fileSuffix)
      );
    }

    if (firstApneaAfterTenMinutesReport) {
      uploadPromises.push(
        uploadDocument(
          firstApneaAfterTenMinutesReport,
          FILEPREFIX.FIRSTAPNEA10,
          hospitalName,
          fileSuffix
        )
      );
    }

    if (secondApneaAfterTenMinutesReport) {
      uploadPromises.push(
        uploadDocument(
          secondApneaAfterTenMinutesReport,
          FILEPREFIX.SECONDAPNEA10,
          hospitalName,
          fileSuffix
        )
      );
    }

    for (let j = 0; j < relatedForms.length; j += 1) {
      const { attachment } = relatedForms[j];
      uploadPromises.push(
        uploadDocument(attachment, FILEPREFIX.DONORFORMS, hospitalName, fileSuffix)
      );
    }
    return Promise.all(uploadPromises);
  };

  const useStyles = makeStyles(() => ({
    paperBsdInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 80,
      marginBottom: 10,
    },
    gridBsdDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperBsdSections: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px 0px 0px 4px',
      height: '100%',
    },
    bsdSectionsStyle: {
      // fontFamily: 'Mulish',
      fontWeight: 300,
      marginTop: 20,
      marginLeft: 20,
    },
    noteStyle: {
      fontWeight: 600,
      lineHeight: '17px',
      color: 'red',
    },
    bsdSectionsHeadingStyle: {
      // fontFamily: 'Mulish',

      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    bsdDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      marginTop: '5px',
    },
    bsdBoxStyle: {
      direction: 'row',
    },
    transplantListHeading: {
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '25px',
      color: '#34495E',
      marginBottom: 10,
    },
  }));

  const classes = useStyles();

  const [selectNottoIdDialogStatus, setSelectNottoIdDialogStatus] = useState(false);
  const _renderNoNottoIdDialog = () => {
    return (
      <Dialog
        open={selectNottoIdDialogStatus}
        onClose={() => {
          setSelectNottoIdDialogStatus(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ backgroundColor: 'red', color: '#F0FEF9' }}>
          <Grid container justify="space-between" alignItems="center">
            NOTTO ID not entered
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                setSelectNottoIdDialogStatus(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ margin: 20 }}>
          <Grid container spacing={2}>
            <Typography>Please enter NOTTO ID to proceed further</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadableButton
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectNottoIdDialogStatus(false);
            }}
            disabledButton
          >
            Okay
          </LoadableButton>
        </DialogActions>
      </Dialog>
    );
  };

  const [selectOrganDialogStatus, setSelectOrganDialogStatus] = useState(false);

  const _renderNoOrganDialog = () => {
    return (
      <Dialog
        open={selectOrganDialogStatus}
        onClose={() => {
          setSelectOrganDialogStatus(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ backgroundColor: 'red', color: '#F0FEF9' }}>
          <Grid container justify="space-between" alignItems="center">
            Organs Donated not selected
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                setSelectOrganDialogStatus(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ margin: 20 }}>
          <Grid container spacing={2}>
            <Typography>Please select organs donated to proceed further</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadableButton
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectOrganDialogStatus(false);
            }}
            disabledButton
          >
            Okay
          </LoadableButton>
        </DialogActions>
      </Dialog>
    );
  };

  const [saveBsdDonorApiId, setSaveBsdDonorApiId] = useState('APIID');
  const _submitDraftDonor = async () => {
    const { validate } = props;
    setIsLoading(true);
    await uploadFormDocuments();

    validate(VALIDATION.DIRTY, (valid) => {
      if (!valid) {
        return;
      }

      bsdDonor.createdBy = userId;
      bsdDonor.hospitalId = hospitalId;
      bsdDonor.hospitalName = hospitalName;
      let donatedOrgan = false;
      bsdDonor.organs.forEach((o) => {
        if (o.organType.includes('NONE')) {
          donatedOrgan = true;
        }
      });
      if (
        (bsdDonor.organs.length !== 0 && bsdDonor.nottoId !== '' && donatedOrgan === false) ||
        (bsdDonor.organs.length !== 0 && bsdDonor.nottoId === '' && donatedOrgan === true)
      ) {
        apiPost(
          PATH_EXP_SUBMITDRAFTORGANDONOR,
          {
            donor: bsdDonor,
          },
          {
            apiIdCb: (apiId) => {
              setSaveBsdDonorApiId(apiId);
            },
          }
        )
          .then((newBsdDonor) => {
            apiGet(PATH_EXP_GETDRAFTORGANDONOR, { hospitalId, draftId: newBsdDonor.draftId })
              .then((draftBsdDonor) => {
                setBsdDonor({ ...draftBsdDonor });
                // setInitialBsdDonor({ ...draftBsdDonor });
                bsdDonorRef.current = { ...draftBsdDonor };
                setDraftId(newBsdDonor.draftId);
                setRedirectToSaved(true);
                validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (isValid) => {
                  setIsLoading(false);
                  setIsFormFilled(isValid);
                });
              })
              .catch(() => {
                setIsLoading(false);
              });
          })
          .catch(() => {
            setIsLoading(false);
          });
      } else if (bsdDonor.organs.length === 0) {
        setSelectOrganDialogStatus(true);
        setIsLoading(false);
      } else if (bsdDonor.nottoId === '' && donatedOrgan === false) {
        setSelectNottoIdDialogStatus(true);
        setIsLoading(false);
      }
    });
  };

  const _submitOrganDonorEdits = async () => {
    const { validate } = props;
    setIsLoading(true);
    await uploadFormDocuments();

    // validate(VALIDATION.DIRTY, (valid) => {
    //   if (!valid) {
    //     return;
    //   }

    const bsdDonorEdits = diff(bsdDonorRef.current, bsdDonor);
    if (!bsdDonorEdits) {
      setIsLoading(false);
      return bsdDonorRef.current;
    }
    if (bsdDonorEdits) {
      const { draftId: currentDraftId } = bsdDonor;
      let donatedOrgan = false;
      bsdDonor.organs.forEach((o) => {
        if (o.organType.includes('NONE')) {
          donatedOrgan = true;
        }
      });
      if (
        (bsdDonor.organs.length !== 0 && bsdDonor.nottoId !== '' && donatedOrgan === false) ||
        (bsdDonor.organs.length !== 0 && bsdDonor.nottoId === '' && donatedOrgan === true)
      ) {
        apiPatch(
          PATH_EXP_SUBMITORGANDONOREDITS,
          {
            donorEdits: bsdDonorEdits,
            draftId: currentDraftId,
            hospitalId,
            userType,
          },
          {
            apiIdCb: (apiId) => {
              setSaveBsdDonorApiId(apiId);
            },
          }
        )
          .then((editedBsdDonor) => {
            // this.valid = true;

            setBsdDonor({ ...editedBsdDonor });
            // setInitialBsdDonor({ ...editedBsdDonor });
            bsdDonorRef.current = { ...editedBsdDonor };

            validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (isValid) => {
              setIsLoading(false);
              setIsFormFilled(isValid);
            });
          })
          .catch(() => {
            setIsLoading(false);
          });
      } else if (bsdDonor.organs.length === 0) {
        setIsLoading(false);
        setSelectOrganDialogStatus(true);
      } else if (bsdDonor.nottoId === '' && donatedOrgan === false) {
        setIsLoading(false);
        setSelectNottoIdDialogStatus(true);
      }
    }
    // });
  };

  const [submitBsdDonorApiId, setSubmitBsdDonorApiId] = useState('APIID');

  const _submitDonor = () => {
    const { validate } = props;
    setIsLoading(true);

    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (valid) => {
      if (!valid) {
        setIsLoading(false);
        return;
      }

      const { donorId } = bsdDonor;
      const formStatus = DONOR_FORMSTATUS.SUBMITTED;

      apiPatch(
        PATH_EXP_UPDATEORGANDONORSTATUS,
        { draftId: bsdDonor.draftId, donorId, formStatus },
        {
          apiIdCb: (apiId) => {
            setSubmitBsdDonorApiId(apiId);
          },
        }
      )
        .then(() => {
          setRedirectToRecord(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    });
  };

  const _handleSave = () => {
    if (bsdDonor.formStatus === 'NEW') {
      _submitDraftDonor();
    } else {
      _submitOrganDonorEdits();
    }
  };
  const _isDisabled = () => {
    const { formStatus } = bsdDonor;
    return (
      (formStatus === DONOR_FORMSTATUS.SUBMITTED || formStatus === DONOR_FORMSTATUS.COMPLETED) &&
      !(formStatus === DONOR_FORMSTATUS.SUBMITTED && editMode)
    );
  };
  const [openLiverOrganDialog, setOpenLiverOrganDialog] = useState(false);

  const _renderLiverOrganDialog = () => {
    return (
      <Dialog
        open={openLiverOrganDialog}
        onClose={() => {
          setOpenLiverOrganDialog(false);
        }}
        disableBackdropClick
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ backgroundColor: 'red', color: '#F0FEF9' }}>
          <Grid container justify="space-between" alignItems="center">
            If Liver is selected
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                setOpenLiverOrganDialog(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Typography
                variant="body1"
                style={{ fontWeight: 600, fontSize: '16px', color: '#34495E', marginTop: '5px' }}
              >
                If the liver is donated in split, please select one of the liver split options from
                the organ donated list.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenLiverOrganDialog(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleDonorOrganSpecialistChange = (specialistsType, specialist, organ) => {
    const updatedDonorOrgans = [];
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = { ...o };
      if (o.organType === organ) {
        if (specialistsType === 'SURGEON') {
          if (specialist) {
            organDetails.retrievingSurgeonUser = { ...specialist };
            organDetails.retrievingSurgeonId = specialist.userId;
            updatedDonorOrgans.push(organDetails);
          } else {
            organDetails.retrievingSurgeonUser = { ...specialist };
            updatedDonorOrgans.push(organDetails);
          }
        } else if (specialistsType === 'ANAESTHETIST') {
          if (specialist) {
            organDetails.retrievingAnaesthetistUser = { ...specialist };
            organDetails.retrievingAnaesthetistId = specialist.userId;
            updatedDonorOrgans.push(organDetails);
          } else {
            organDetails.retrievingAnaesthetistUser = { ...specialist };
            updatedDonorOrgans.push(organDetails);
          }
        }
      } else {
        updatedDonorOrgans.push(organDetails);
      }
    });
    setBsdDonor({
      ...bsdDonor,
      donorOrgans: [...updatedDonorOrgans],
    });
  };

  const handleDonorTissueSpecialistChange = (specialistsType, specialist, tissue) => {
    const updatedDonorTissues = [];
    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = { ...t };
      if (t.tissueType === tissue) {
        if (specialistsType === 'SURGEON') {
          if (specialist) {
            tissueDetails.retrievingSurgeonUser = { ...specialist };
            tissueDetails.retrievingSurgeonId = specialist.userId;
            updatedDonorTissues.push(tissueDetails);
          } else {
            tissueDetails.retrievingSurgeonUser = { ...specialist };
            updatedDonorTissues.push(tissueDetails);
          }
        } else if (specialistsType === 'ANAESTHETIST') {
          if (specialist) {
            tissueDetails.retrievingAnaesthetistUser = { ...specialist };
            tissueDetails.retrievingAnaesthetistId = specialist.userId;
            updatedDonorTissues.push(tissueDetails);
          } else {
            tissueDetails.retrievingAnaesthetistUser = { ...specialist };
            updatedDonorTissues.push(tissueDetails);
          }
        }
      } else {
        updatedDonorTissues.push(tissueDetails);
      }
    });
    setBsdDonor({
      ...bsdDonor,
      donorTissues: [...updatedDonorTissues],
    });
  };

  const {
    donorHeart,
    donorRightKidney,
    donorLeftKidney,
    donorLiver,
    donorLungs,
    donorHeartLungs,
    donorPancreas,
    donorIntestine,
    donorLiverLeftLateralSegment,
    donorLiverRightExtendedLobe,
    donorHand,
    // donorTissue,
  } = bsdDonor;

  const handleRecipientHospitalChange = (name, value, organ) => {
    // let stateObject;
    const updatedDonorOrgans = [];
    bsdDonor.donorOrgans.forEach((o) => {
      if (o.organType === organ) {
        const organDetails = { ...o };
        organDetails.recipientHospital = value;
        if (value === 'INHOUSE') {
          organDetails.recipientHospital = 'INHOUSE';
          organDetails.recipientHospitalName = hospitalId;
        }
        updatedDonorOrgans.push(organDetails);
      } else {
        updatedDonorOrgans.push(o);
      }
    });
    setBsdDonor({
      ...bsdDonor,
      donorOrgans: updatedDonorOrgans,
    });
  };

  const handleRecipientTissueHospitalChange = (name, value, tissue) => {
    const updatedDonorTissues = [];
    bsdDonor.donorTissues.forEach((o) => {
      if (o.tissueType === tissue) {
        const tissueDetails = { ...o };
        tissueDetails.recipientHospital = value;
        if (value === 'INHOUSE') {
          tissueDetails.recipientHospital = 'INHOUSE';
          tissueDetails.recipientHospitalName = hospitalId;
        }
        updatedDonorTissues.push(tissueDetails);
      } else {
        updatedDonorTissues.push(o);
      }
    });
    setBsdDonor({
      ...bsdDonor,
      donorTissues: updatedDonorTissues,
    });
  };

  const handleDonorOrganDetailsChange = (organ, value) => {
    const updatedDonorOrgans = [];

    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (o.organType === organ) {
        if (value) {
          organDetails.recipientHospitalName = value.hospitalId;
          organDetails.hospitalDetails = value;
          updatedDonorOrgans.push(organDetails);
        } else {
          organDetails.hospitalDetails = value;
          updatedDonorOrgans.push(organDetails);
        }
      } else {
        updatedDonorOrgans.push(o);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorOrgans: [...updatedDonorOrgans],
    });
  };

  const handleDonorTissueDetailsChange = (tissue, value) => {
    const updatedDonorTissues = [];

    bsdDonor.donorTissues.forEach((o) => {
      const tissueDetails = o;
      if (o.tissueType === tissue) {
        if (value) {
          tissueDetails.recipientHospitalName = value.hospitalId;
          tissueDetails.hospitalDetails = value;
          updatedDonorTissues.push(tissueDetails);
        } else {
          tissueDetails.hospitalDetails = value;
          updatedDonorTissues.push(tissueDetails);
        }
      } else {
        updatedDonorTissues.push(o);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorTissues: [...updatedDonorTissues],
    });
  };

  const handleDonorOrganDetailsForOtherStateRecipientHospitalNameChange = (organ, value) => {
    const updatedDonorOrgans = [];

    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (o.organType === organ) {
        organDetails.otherStateRecipientHospitalName = value;
        updatedDonorOrgans.push(organDetails);
      } else {
        updatedDonorOrgans.push(o);
      }
    });
    setBsdDonor({
      ...bsdDonor,
      donorOrgans: [...updatedDonorOrgans],
    });
  };

  const handleDonorTissueDetailsForOtherStateRecipientHospitalNameChange = (tissue, value) => {
    const updatedDonorTissues = [];

    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (t.tissueType === tissue) {
        tissueDetails.otherStateRecipientHospitalName = value;
        updatedDonorTissues.push(tissueDetails);
      } else {
        updatedDonorTissues.push(t);
      }
    });
    setBsdDonor({
      ...bsdDonor,
      donorTissues: [...updatedDonorTissues],
    });
  };

  const handleDonorOrganDetailsForOtherStateRetrievingSurgeonNameChange = (organ, value) => {
    const updatedDonorOrgans = [];

    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (o.organType === organ) {
        organDetails.otherStateSurgeonName = value;
        updatedDonorOrgans.push(organDetails);
      } else {
        updatedDonorOrgans.push(o);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorOrgans: [...updatedDonorOrgans],
    });
  };

  const handleDonorTissueDetailsForOtherStateRetrievingSurgeonNameChange = (tissue, value) => {
    const updatedDonorTissues = [];

    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (t.tissueType === tissue) {
        tissueDetails.otherStateSurgeonName = value;
        updatedDonorTissues.push(tissueDetails);
      } else {
        updatedDonorTissues.push(t);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorTissues: [...updatedDonorTissues],
    });
  };

  const handleDonorOrganDetailsForOtherStateRetrievingSurgeonIMAIdChange = (organ, value) => {
    const updatedDonorOrgans = [];

    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (o.organType === organ) {
        organDetails.otherStateSurgeonIMAId = value;
        updatedDonorOrgans.push(organDetails);
      } else {
        updatedDonorOrgans.push(o);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorOrgans: [...updatedDonorOrgans],
    });
  };

  const handleDonorTissueDetailsForOtherStateRetrievingSurgeonIMAIdChange = (tissue, value) => {
    const updatedDonorTissues = [];

    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (t.tissueType === tissue) {
        tissueDetails.otherStateSurgeonIMAId = value;
        updatedDonorTissues.push(tissueDetails);
      } else {
        updatedDonorTissues.push(t);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorTissues: [...updatedDonorTissues],
    });
  };

  const handleDonorTissueDetailsForPreservedOrDiscardedChange = (tissue, value) => {
    const updatedDonorTissues = bsdDonor.donorTissues.map((o) => {
      if (o.tissueType === tissue) {
        const tissueDetails = { ...o };
        tissueDetails.preservedOrDiscarded = value;

        if (value === 'PRESERVED') {
          tissueDetails.preservedHospitalName = '';
        }

        return tissueDetails;
      }
      return o;
    });

    setBsdDonor({
      ...bsdDonor,
      donorTissues: updatedDonorTissues,
    });
  };

  const handleDonorTissueDetailsForViableNonViableChange = (tissue, value) => {
    const updatedDonorTissues = bsdDonor.donorTissues.map((o) => {
      if (o.tissueType === tissue) {
        const tissueDetails = { ...o };
        tissueDetails.viableNonviable = value;
        return tissueDetails;
      }
      return o;
    });

    setBsdDonor({
      ...bsdDonor,
      donorTissues: updatedDonorTissues,
    });
  };
  const handleDonorTissueDetailsForHospitalNameChange = (tissue, value) => {
    const updatedDonorTissues = [];

    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (t.tissueType === tissue) {
        tissueDetails.preservedHospitalName = value;
        updatedDonorTissues.push(tissueDetails);
      } else {
        updatedDonorTissues.push(t);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorTissues: [...updatedDonorTissues],
    });
  };

  const handleDonorOrganDetailsForOtherStateRetrievingAnaesthetistNameChange = (organ, value) => {
    const updatedDonorOrgans = [];

    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (o.organType === organ) {
        organDetails.otherStateAnaesthetistName = value;
        updatedDonorOrgans.push(organDetails);
      } else {
        updatedDonorOrgans.push(o);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorOrgans: [...updatedDonorOrgans],
    });
  };

  const handleDonorTissueDetailsForOtherStateRetrievingAnaesthetistNameChange = (tissue, value) => {
    const updatedDonorTissues = [];

    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (t.tissueType === tissue) {
        tissueDetails.otherStateAnaesthetistName = value;
        updatedDonorTissues.push(tissueDetails);
      } else {
        updatedDonorTissues.push(t);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorTissues: [...updatedDonorTissues],
    });
  };

  const handleDonorOrganDetailsForOtherStateRetrievingAnaesthetistIMAIdChange = (organ, value) => {
    const updatedDonorOrgans = [];

    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (o.organType === organ) {
        organDetails.otherStateAnaesthetistIMAId = value;
        updatedDonorOrgans.push(organDetails);
      } else {
        updatedDonorOrgans.push(o);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorOrgans: [...updatedDonorOrgans],
    });
  };

  const handleDonorTissueDetailsForOtherStateRetrievingAnaesthetistIMAIdChange = (
    tissue,
    value
  ) => {
    const updatedDonorTissues = [];

    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (t.tissueType === tissue) {
        tissueDetails.otherStateAnaesthetistIMAId = value;
        updatedDonorTissues.push(tissueDetails);
      } else {
        updatedDonorTissues.push(t);
      }
    });

    setBsdDonor({
      ...bsdDonor,
      donorTissues: [...updatedDonorTissues],
    });
  };

  const _setDonorCorneaOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (tissueDetails.tissueType === 'CORNEA') {
        tissueDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorSkinOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (tissueDetails.tissueType === 'SKIN') {
        tissueDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorBonesOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (tissueDetails.tissueType === 'BONES') {
        tissueDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorBloodVesselsOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (tissueDetails.tissueType === 'BLOODVESSELS') {
        tissueDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorHeartValvesOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorTissues.forEach((t) => {
      const tissueDetails = t;
      if (tissueDetails.tissueType === 'HEARTVALVES') {
        tissueDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorHeartOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'HEART') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLeftKidneyOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LEFTKIDNEY') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorRightKidneyOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'RIGHTKIDNEY') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLiverLeftLateralSegmentOtherSupportiveSpecialists = (
    otherSupportiveSpecialists
  ) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LIVERLEFTLATERALSEGMENT') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLiverRightExtendedLobeOtherSupportiveSpecialists = (
    otherSupportiveSpecialists
  ) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LIVERRIGHTEXTENDEDLOBE') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLeftLateralSectionectomyOtherSupportiveSpecialists = (
    otherSupportiveSpecialists
  ) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LEFTLATERALSECTIONECTOMY') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLiverHepatectomyOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LIVERHEPATECTOMY') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };
  const _setDonorLiverLeftExtendedHepatectomyOtherSupportiveSpecialists = (
    otherSupportiveSpecialists
  ) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LIVERLEFTEXTENDEDHEPATECTOMY') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLiverRightExtendedHepatectomyOtherSupportiveSpecialists = (
    otherSupportiveSpecialists
  ) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LIVERRIGHTEXTENDEDHEPATECTOMY') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLiverRightHepatectomyWithoutMHVOtherSupportiveSpecialists = (
    otherSupportiveSpecialists
  ) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LIVERRIGHTHEPATECTOMYWITHOUTMHV') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLiverRightHepatectomyWithMHVOtherSupportiveSpecialists = (
    otherSupportiveSpecialists
  ) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LIVERRIGHTHEPATECTOMYWITHMHV') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLiverRightPosteriorSectionectomyOtherSupportiveSpecialists = (
    otherSupportiveSpecialists
  ) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LIVERRIGHTPOSTERIORSECTIONECTOMY') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLiverOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LIVER') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorLungsOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'LUNGS') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorIntestineOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'INTESTINE') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorPancreasOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'PANCREAS') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorHeartLungsOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'HEARTLUNGS') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const _setDonorHandOtherSupportiveSpecialists = (otherSupportiveSpecialists) => {
    bsdDonor.donorOrgans.forEach((o) => {
      const organDetails = o;
      if (organDetails.organType === 'HAND') {
        organDetails.otherSupportiveSpecialists = [...otherSupportiveSpecialists];
      }
    });

    setBsdDonor({
      ...bsdDonor,
    });
  };

  const columns = [
    {
      Header: 'Rank',
      key: 'rank',
      accessor: 'ztccRank',
    },
    {
      Header: 'Recipient Name',
      key: 'recipientName',
      accessor: 'recipientDetails.firstName',
    },
    {
      Header: 'Hospital Name',
      key: 'hospitalName',
      accessor: 'recipientDetails.hospitalName',
    },
    {
      Header: 'Contact',
      key: 'contact',
      accessor: 'recipientDetails.contact',
    },
  ];

  const _submitOrganCall = () => {
    const recipients = [];
    selectedOrganRecipients.forEach((ele) => {
      const {
        recipientDetails: { firstName },
      } = ele;
      recipients.push({
        ...ele,
        firstName,
      });
    });
    apiPost(
      PATH_EXP_SUBMITORGANCALL,
      {
        organCallData: {
          ...bsdDonor,
          recipients,
          organType: 'HEART',
        },
      },
      {
        apiIdCb: (apiId) => {
          setSaveBsdDonorApiId(apiId);
        },
      }
    )
      .then((updatedBsdDonor) => {
        setBsdDonor(updatedBsdDonor);
      })
      .catch(() => {});
  };

  const _renderOrganCallProcess = () => {
    const currentOrganDetails = bsdDonor?.organs?.filter(
      (o) => o.organType === currentSelectedOrgan
    )[0];
    const organCall = bsdDonorOrganCalls.filter((o) => o.organType === currentSelectedOrgan)[0];
    return (
      <>
        <Grid container spacing={1} style={{ marginTop: 10, padding: 20 }}>
          {bsdDonor.organs.map((o) => {
            return (
              <Grid item sm={2}>
                <Button
                  variant={currentSelectedOrgan === o.organType ? 'contained' : 'outlined'}
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor: currentSelectedOrgan !== o.organType ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setCurrentSelectedOrgan(o.organType);
                  }}
                  fullWidth
                >
                  {o.organType}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Grid item>
          <hr />
        </Grid>
        <Grid
          container
          justify="space-between"
          style={{ padding: 20 }}
          alignContent="center"
          spacing={2}
        >
          <Grid item sm={4}>
            <Typography variant="body1" className={classes.transplantListHeading}>
              Organ Call Process
            </Typography>
          </Grid>
          {currentOrganDetails?.organDetails?.organStatus === 'NOTADDED' ? (
            <>
              <Grid container sm={6} style={{ alignContent: 'center' }} justify="space-between">
                <Grid item sm={6}>
                  <ValidatedSelect
                    label="Hospital"
                    value={recipientHospitalType}
                    options={RECIPIENT_HOSPITALTYPE}
                    onChange={(v) => {
                      setRecipientHospitalType(v.value);
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      _submitOrganCall();
                    }}
                  >
                    Notify
                  </Button>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <ReactTable columns={columns} data={currentOrganRecipients} />
              </Grid>
            </>
          ) : (
            <Grid item sm={12}>
              <Typography>
                Organ Call for this {currentSelectedOrgan} has already started, to check current
                status click on the button below
              </Typography>
              <Link to={`/organCall/${organCall?.organCallId}`} style={{ textDecoration: 'none' }}>
                <Button color="primary" variant="contained">
                  Click here
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <hr />
        </Grid>
      </>
    );
  };

  const _checkIfOrganObjectExists = (value) => {
    if (bsdDonor.donorOrgans.some((o) => o.organType === value)) {
      return false;
    }
    return true;
  };

  const _checkIfTissueObjectExists = (value) => {
    if (bsdDonor.donorTissues.some((t) => t.tissueType === value)) {
      return false;
    }
    return true;
  };

  const _bsdDonorOrganOnChangeHandler = (e) => {
    if (e.length > 0) {
      const newOrgans = [];
      const newDonorOrgans = [];
      const newSelectedOrgans = [];

      const isNoneSelected = e.some((o) => o.value === 'NONE');
      const wasNoneSelected = selectedOrgans?.includes('NONE');
      let resetNottoId = false;

      // If No organs donated option is selected, NONE organType is added to donorOrgans
      if (isNoneSelected && !wasNoneSelected) {
        newSelectedOrgans.push('NONE');
        if (_checkIfOrganObjectExists('NONE')) {
          const newOrgan = {
            organType: 'NONE',
          };
          newOrgans.push(newOrgan);
          newDonorOrgans.push({
            recipientHospital: '',
            recipientHospitalName: '',
            otherStateRecipientHospitalName: '',
            otherStateSurgeonName: '',
            otherStateSurgeonIMAId: '',
            otherStateAnaesthetistName: '',
            otherStateAnaesthetistIMAId: '',
            hospitalDetails: {
              hospitalId: '',
            },
            retrievingSurgeonId: '',
            retrievingSurgeonUser: {},
            retrievingAnaesthetistId: '',
            retrievingAnaesthetistUser: {},
            otherSupportiveSpecialists: [],
            organType: 'NONE',
          });
        }
        //  const filteredOrgan = e.filter((o) => o.value === 'NONE')
        setBsdDonor({
          ...bsdDonor,
          donorOrgans: [...newDonorOrgans],
          organs: [...newOrgans],
          nottoId: '',
        });
        setSelectedOrgans(newSelectedOrgans);
        return;
      } else if (wasNoneSelected) {
        e.forEach((o) => {
          if (o.value !== 'NONE') {
            newSelectedOrgans.push(o.value);

            if (_checkIfOrganObjectExists(o.value)) {
              const newOrgan = {
                organType: o.value,
              };
              newOrgans.push(newOrgan);
              newDonorOrgans.push({
                recipientHospital: '',
                recipientHospitalName: '',
                otherStateRecipientHospitalName: '',
                otherStateSurgeonName: '',
                otherStateSurgeonIMAId: '',
                otherStateAnaesthetistName: '',
                otherStateAnaesthetistIMAId: '',
                hospitalDetails: {
                  hospitalId: '',
                },
                retrievingSurgeonId: '',
                retrievingSurgeonUser: {},
                retrievingAnaesthetistId: '',
                retrievingAnaesthetistUser: {},
                otherSupportiveSpecialists: [],
                organType: o.value,
              });
            }

            setBsdDonor({
              ...bsdDonor,
              donorOrgans: [...newDonorOrgans],
              organs: [...newOrgans],
              nottoId: bsdDonor.nottoId,
            });
            setSelectedOrgans(newSelectedOrgans);
          }
        });
        return;
      }

      // checking if any organ is removed from the list selectedOrgans has past data of organs and e is event with current data of organs
      if (selectedOrgans?.length > e.length) {
        // handles the remove part
        // eslint-disable-next-line no-unused-expressions
        bsdDonor?.donorOrgans?.forEach((d) => {
          if (e.some((o) => o.value === d.organType)) {
            newDonorOrgans.push(d);
            newOrgans.push({
              organType: d.organType,
            });
            newSelectedOrgans.push(d.organType);
          }
        });
        setBsdDonor({
          ...bsdDonor,
          donorOrgans: [...newDonorOrgans],
          organs: [...newOrgans],
          nottoId: resetNottoId ? '' : bsdDonor.nottoId,
        });
        setSelectedOrgans(newSelectedOrgans);
      } else {
        e.forEach((o) => {
          newSelectedOrgans.push(o.value);
          if (_checkIfOrganObjectExists(o.value)) {
            const newOrgan = {
              organType: o.value,
            };
            newOrgans.push(newOrgan);
            newDonorOrgans.push({
              recipientHospital: '',
              recipientHospitalName: '',
              otherStateRecipientHospitalName: '',
              otherStateSurgeonName: '',
              otherStateSurgeonIMAId: '',
              otherStateAnaesthetistName: '',
              otherStateAnaesthetistIMAId: '',
              hospitalDetails: {
                hospitalId: '',
              },
              retrievingSurgeonId: '',
              retrievingSurgeonUser: {},
              retrievingAnaesthetistId: '',
              retrievingAnaesthetistUser: {},
              otherSupportiveSpecialists: [],
              organType: o.value,
            });
          }
        });
        setBsdDonor({
          ...bsdDonor,
          donorOrgans: [...bsdDonor?.donorOrgans, ...newDonorOrgans],
          organs: [...bsdDonor?.organs, ...newOrgans],
          nottoId: resetNottoId ? '' : bsdDonor.nottoId,
        });
        setSelectedOrgans(newSelectedOrgans);
      }

      // checking if No organs donated option is selected
      if (e.some((o) => o.value === 'NONE')) {
        resetNottoId = true;
      }
    } else {
      setBsdDonor({
        ...bsdDonor,
        donorOrgans: [],
        organs: [],
      });
      setSelectedOrgans([]);
    }
    const liverAlreadySelected = selectedOrgans.some((o) => o === 'LIVER');
    e.forEach((o) => {
      if (o.value === 'LIVER' && !liverAlreadySelected) {
        setOpenLiverOrganDialog(true);
      }
    });
  };

  const _renderBsdDonor = () => {
    const {
      formStatus,
      organs,
      relatedForms,
      policeReport,
      photo,
      firstApneaBaselineReport,
      firstApneaAfterTenMinutesReport,
      secondApneaBaselineReport,
      secondApneaAfterTenMinutesReport,
    } = bsdDonor;
    const { validation, onValidation } = props;

    return (
      <Grid item xs={12}>
        {redirectToRecord ? <Redirect to={`/bsdDonor/record/${bsdDonor.donorId}`} /> : null}
        <Paper>
          <DataLoadingScreen isLoading={isLoading}>
            <Grid container>
              <Grid container direction="column" className={classes.gridBsdDetails}>
                <Grid container justify="space-between">
                  <Grid item xs={10}>
                    <GreenTagSectionHeader headerText="Donor Details" />
                  </Grid>
                </Grid>
                <Grid item>
                  <hr />
                </Grid>
                {isZtccUserType(userType) ? <>{_renderOrganCallProcess()}</> : null}

                <Grid container spacing={5} style={{ marginBottom: 20, marginTop: 10 }}>
                  <Grid item sm={4}>
                    <Typography>Hospital Name</Typography>
                    <ValidatedInput
                      required
                      // label="Hospital Name"
                      value={bsdDonor.hospitalName || hospitalName}
                      disabled={true}
                      validation={validation}
                      onValidation={onValidation}
                      schema={Joi.string()}
                      fullWidth
                    />
                  </Grid>
                  {formStatus !== 'DRAFT' && formStatus !== 'NEW' ? (
                    <Grid item sm={4}>
                      <Typography>System Id</Typography>
                      <ValidatedInput
                        required
                        // label="System Id"
                        value={bsdDonor.donorId}
                        disabled={true}
                        validation={validation}
                        onValidation={onValidation}
                        schema={Joi.string()}
                        fullWidth
                      />
                    </Grid>
                  ) : null}

                  <Grid item>
                    <hr />
                  </Grid>
                </Grid>
                <Grid container spacing={5} style={{ marginBottom: 20 }}>
                  <Grid item sm={4}>
                    <Typography>First Name</Typography>
                    <ValidatedInput
                      required
                      // label="First Name"
                      value={bsdDonor.firstName}
                      onChange={(e) => {
                        setBsdDonor({
                          ...bsdDonor,
                          firstName: e.target.value,
                        });
                      }}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      schema={Joi.string().pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN).message({
                        'string.pattern.base': 'Cannot contain spaces at start and end',
                      })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>Middle Name</Typography>
                    <ValidatedInput
                      required
                      // label="Middle Name"
                      value={bsdDonor.middleName}
                      onChange={(e) => {
                        setBsdDonor({
                          ...bsdDonor,
                          middleName: e.target.value,
                        });
                      }}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      schema={Joi.string().pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN).message({
                        'string.pattern.base': 'Cannot contain spaces at start and end',
                      })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>Last Name</Typography>
                    <ValidatedInput
                      required
                      // label="Last Name"
                      value={bsdDonor.lastName}
                      onChange={(e) => {
                        setBsdDonor({
                          ...bsdDonor,
                          lastName: e.target.value,
                        });
                      }}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      schema={Joi.string().pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN).message({
                        'string.pattern.base': 'Cannot contain spaces at start and end',
                      })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>Donor Address</Typography>
                    <ValidatedInput
                      required
                      // label="Donor Address"
                      value={bsdDonor.address}
                      onChange={(e) => {
                        setBsdDonor({
                          ...bsdDonor,
                          address: e.target.value,
                        });
                      }}
                      schema={Joi.string().pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN).message({
                        'string.pattern.base': 'Cannot contain spaces at start and end',
                      })}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>Age</Typography>
                    <ValidatedInput
                      required
                      // label="Age"
                      value={bsdDonor.age}
                      onChange={(e) => {
                        const ageInt = e.target.value ? parseInt(e.target.value, 10) : 0;
                        setBsdDonor({
                          ...bsdDonor,
                          age: ageInt,
                        });
                      }}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      schema={Joi.number().min(0)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4} xs={10}>
                    <Typography>Gender</Typography>
                    <ValidatedSelect
                      required
                      // label="Gender"
                      value={bsdDonor.gender}
                      options={Object.values(SEX)}
                      onChange={(e) => {
                        setBsdDonor({
                          ...bsdDonor,
                          gender: e.value,
                        });
                      }}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>

                  <Grid item sm={4} xs={10}>
                    <Typography>BloodGroup</Typography>
                    <ValidatedSelect
                      required
                      // label="BloodGroup"
                      value={bsdDonor.bloodGroup}
                      options={Object.values(BLOODGROUP)}
                      onChange={(e) => {
                        setBsdDonor({
                          ...bsdDonor,
                          bloodGroup: e.value,
                        });
                      }}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>Weight(in kgs)</Typography>
                    <ValidatedInput
                      // label="Weight(in kgs)"
                      value={bsdDonor.weight}
                      onChange={(e) => {
                        setBsdDonor({
                          ...bsdDonor,
                          weight: e.target.value,
                        });
                      }}
                      disabled={_isDisabled()}
                      schema={Joi.string()}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>Height(in cm)</Typography>
                    <ValidatedInput
                      // label="Height(in cm)"
                      value={bsdDonor.height}
                      onChange={(e) => {
                        setBsdDonor({
                          ...bsdDonor,
                          height: e.target.value,
                        });
                      }}
                      disabled={_isDisabled()}
                      schema={Joi.string()}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={4} xs={10}>
                    <Typography>Country</Typography>
                    <ValidatedSelect
                      required
                      // label="Country"
                      value={bsdDonor.nationality}
                      options={OPTIONS_NATIONALITY}
                      disabled={_isDisabled()}
                      onChange={(e) => {
                        setBsdDonor({
                          ...bsdDonor,
                          nationality: e.value,
                        });
                      }}
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                  {bsdDonor.nationality === 'IN' ? (
                    <>
                      <Grid item sm={4} xs={10}>
                        <Typography>State</Typography>
                        <ValidatedSelect
                          required
                          // label="State"
                          value={bsdDonor.state}
                          options={Object.values(STATE_IN)}
                          onChange={(e) => {
                            setBsdDonor({
                              ...bsdDonor,
                              state: e.value,
                            });
                          }}
                          disabled={_isDisabled()}
                          validation={validation}
                          onValidation={onValidation}
                        />
                      </Grid>
                      <Grid item sm={4} xs={10}>
                        <Typography>District</Typography>
                        <ValidatedSelect
                          required
                          // label="District"
                          value={bsdDonor.district}
                          options={Object.values(districts(bsdDonor.state))}
                          onChange={(e) => {
                            setBsdDonor({
                              ...bsdDonor,
                              district: e.value,
                            });
                          }}
                          disabled={_isDisabled()}
                          validation={validation}
                          onValidation={onValidation}
                        />
                      </Grid>
                    </>
                  ) : null}

                  <Grid item sm={4} xs={10}>
                    <Typography>Religion</Typography>
                    <ValidatedSelect
                      required
                      // label="Religion"
                      value={bsdDonor.religion}
                      options={[
                        {
                          label: 'Buddhism',
                          value: 'BUDDHISM',
                        },
                        {
                          label: 'Christianity',
                          value: 'CHRISTIANITY',
                        },
                        {
                          label: 'Hinduism',
                          value: 'HINDUISM',
                        },
                        {
                          label: 'Islam',
                          value: 'ISLAM',
                        },
                        {
                          label: 'Jainism',
                          value: 'JAINISM',
                        },

                        {
                          label: 'Judaism',
                          value: 'JUDAISM',
                        },
                        {
                          label: 'Sikhism',
                          value: 'SIKHISM',
                        },
                        {
                          label: 'Other',
                          value: 'OTHER',
                        },
                        {
                          label: 'Not Specified',
                          value: 'NOT_SPECIFIED',
                        },
                      ]}
                      onChange={(v) => {
                        setBsdDonor({
                          ...bsdDonor,
                          religion: v.value,
                        });
                      }}
                      validation={validation}
                      onValidation={onValidation}
                      disabled={_isDisabled()}
                    />
                  </Grid>

                  <Grid item sm={4} xs={10}>
                    <Typography>UHID</Typography>
                    <ValidatedInput
                      required
                      // label="UHID"
                      value={bsdDonor.uhid}
                      onChange={(e) => {
                        setBsdDonor({
                          ...bsdDonor,
                          uhid: e.target.value,
                        });
                      }}
                      disabled={_isDisabled()}
                      schema={Joi.string().pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN).message({
                        'string.pattern.base': 'Cannot contain spaces at start and end',
                      })}
                      validation={validation}
                      onValidation={onValidation}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={4} xs={10}>
                    <Typography>Organ Donated</Typography>
                    <ValidatedSelect
                      required
                      id="organ"
                      // label="Organ Donated"
                      name="organ"
                      value={selectedOrgans}
                      options={Object.values(BSDORGAN)}
                      onChange={(e) => _bsdDonorOrganOnChangeHandler(e)}
                      disabled={
                        formStatus === DONOR_FORMSTATUS.SUBMITTED ||
                        formStatus === DONOR_FORMSTATUS.COMPLETED
                      }
                      multiple={true}
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                  {selectedOrgans.includes('OTHER') ? (
                    <Grid item sm={4} xs={10}>
                      <Typography>Other Donated Organ(s)</Typography>
                      <ValidatedInput
                        // label="Other Donated Organ(s)"
                        value={bsdDonor.otherOrgan}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            otherOrgan: e.target.value,
                          });
                        }}
                        disabled={_isDisabled()}
                        schema={Joi.string()}
                        fullWidth
                      />
                    </Grid>
                  ) : null}
                  {selectedOrgans.includes('NONE') ? null : (
                    <Grid item sm={4} xs={10}>
                      <Typography>Allocation Status</Typography>
                      <ValidatedSelect
                        // label="Allocation Status"
                        value={bsdDonor.allocationStatus}
                        options={OPTIONS_ALLOCATIONSTATUS}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            allocationStatus: e.value,
                          });
                        }}
                        disabled={_isDisabled()}
                        onValidation={onValidation}
                        validation={validation}
                      />
                    </Grid>
                  )}
                  <Grid item sm={4} xs={12}>
                    <Typography>Tissue Donated</Typography>
                    <ValidatedSelect
                      required
                      id="tissue"
                      name="tissue"
                      multiple={true}
                      value={selectedTissues}
                      options={Object.values(TISSUE)}
                      onChange={(e) => {
                        if (e.length > 0) {
                          const newTissues = [];
                          const newDonorTissues = [];
                          const newSelectedTissues = [];

                          const isNoneSelected = e.some((o) => o.value === 'NONE');
                          const wasNoneSelected = selectedTissues?.includes('NONE');

                          if (isNoneSelected && !wasNoneSelected) {
                            newSelectedTissues.push('NONE');
                            if (_checkIfTissueObjectExists('NONE')) {
                              const newTissue = {
                                tissueType: 'NONE',
                              };
                              newTissues.push(newTissue);
                              newDonorTissues.push({
                                recipientHospital: '',
                                recipientHospitalName: '',
                                otherStateRecipientHospitalName: '',
                                otherStateSurgeonName: '',
                                otherStateSurgeonIMAId: '',
                                otherStateAnaesthetistName: '',
                                otherStateAnaesthetistIMAId: '',
                                hospitalDetails: {
                                  hospitalId: '',
                                },
                                retrievingSurgeonId: '',
                                retrievingSurgeonUser: {},
                                retrievingAnaesthetistId: '',
                                retrievingAnaesthetistUser: {},
                                otherSupportiveSpecialists: [],
                                tissueType: 'NONE',
                                preservedOrDiscarded: '',
                                preserevedHospitalName: '',
                                viableNonviable: '',
                              });
                            }
                            setBsdDonor({
                              ...bsdDonor,
                              donorTissues: [...newDonorTissues],
                              tissues: [...newTissues],
                            });
                            setSelectedTissues(newSelectedTissues);
                            return;
                          } else if (wasNoneSelected) {
                            e.forEach((o) => {
                              if (o.value !== 'NONE') {
                                newSelectedTissues.push(o.value);

                                if (_checkIfTissueObjectExists(o.value)) {
                                  const newTissue = {
                                    tissueType: o.value,
                                  };
                                  newTissues.push(newTissue);
                                  newDonorTissues.push({
                                    recipientHospital: '',
                                    recipientHospitalName: '',
                                    otherStateRecipientHospitalName: '',
                                    otherStateSurgeonName: '',
                                    otherStateSurgeonIMAId: '',
                                    otherStateAnaesthetistName: '',
                                    otherStateAnaesthetistIMAId: '',
                                    hospitalDetails: {
                                      hospitalId: '',
                                    },
                                    retrievingSurgeonId: '',
                                    retrievingSurgeonUser: {},
                                    retrievingAnaesthetistId: '',
                                    retrievingAnaesthetistUser: {},
                                    otherSupportiveSpecialists: [],
                                    tissueType: o.value,
                                    preservedOrDiscarded: '',
                                    preserevedHospitalName: '',
                                    viableNonviable: '',
                                  });
                                }

                                setBsdDonor({
                                  ...bsdDonor,
                                  donorTissues: [...newDonorTissues],
                                  tissues: [...newTissues],
                                  nottoId: bsdDonor.nottoId,
                                });
                                setSelectedTissues(newSelectedTissues);
                              }
                            });
                            return;
                          }

                          if (selectedTissues?.length > e.length) {
                            // eslint-disable-next-line no-unused-expressions
                            bsdDonor?.donorTissues?.forEach((d) => {
                              if (e.some((o) => o.value === d.tissueType)) {
                                newDonorTissues.push(d);
                                newTissues.push({
                                  tissueType: d.tissueType,
                                });
                                newSelectedTissues.push(d.tissueType);
                              }
                            });
                          } else {
                            e.forEach((o) => {
                              newSelectedTissues.push(o.value);
                              if (_checkIfTissueObjectExists(o.value)) {
                                const newTissue = {
                                  tissueType: o.value,
                                };
                                newTissues.push(newTissue);
                                newDonorTissues.push({
                                  recipientHospital: '',
                                  recipientHospitalName: '',
                                  otherStateRecipientHospitalName: '',
                                  otherStateSurgeonName: '',
                                  otherStateSurgeonIMAId: '',
                                  otherStateAnaesthetistName: '',
                                  otherStateAnaesthetistIMAId: '',
                                  hospitalDetails: {
                                    hospitalId: '',
                                  },
                                  retrievingSurgeonId: '',
                                  retrievingSurgeonUser: {},
                                  retrievingAnaesthetistId: '',
                                  retrievingAnaesthetistUser: {},
                                  otherSupportiveSpecialists: [],
                                  tissueType: o.value,
                                  preservedOrDiscarded: '',
                                  preserevedHospitalName: '',
                                  viableNonviable: '',
                                });
                              }
                            });
                          }

                          setBsdDonor({
                            ...bsdDonor,
                            donorTissues: [...bsdDonor?.donorTissues, ...newDonorTissues],
                            tissues: [...bsdDonor?.tissues, ...newTissues],
                            nottoId: bsdDonor.nottoId,
                          });
                          setSelectedTissues(newSelectedTissues);
                        } else {
                          setBsdDonor({
                            ...bsdDonor,
                            donorTissues: [],
                            tissues: [],
                          });
                          setSelectedTissues([]);
                        }
                      }}
                      disabled={
                        formStatus === DONOR_FORMSTATUS.SUBMITTED ||
                        formStatus === DONOR_FORMSTATUS.COMPLETED
                      }
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                  {selectedOrgans.includes('NONE') ? (
                    <>
                      <Grid item sm={4} xs={10}>
                        <Typography>Reason for not donating organs</Typography>
                        <ValidatedSelect
                          // label="Reason for not donating organs"
                          value={bsdDonor.reasonForNotDonatingOrgan}
                          options={OPTIONS_REASON_FOR_NOT_DONATING_ORGANS}
                          onChange={(e) => {
                            setBsdDonor({
                              ...bsdDonor,
                              reasonForNotDonatingOrgan: e.value,
                            });
                          }}
                          disabled={_isDisabled()}
                          onValidation={onValidation}
                          validation={validation}
                        />
                      </Grid>
                      {bsdDonor.reasonForNotDonatingOrgan === 'OTHER' ? (
                        <Grid item sm={6}>
                          <Typography>Other Reason</Typography>
                          <ValidatedInput
                            // label="Other Reason"
                            value={bsdDonor.reasonForNotDonatingOrganOther}
                            onChange={(e) => {
                              setBsdDonor({
                                ...bsdDonor,
                                reasonForNotDonatingOrganOther: e.target.value,
                              });
                            }}
                            disabled={_isDisabled()}
                            schema={Joi.string()}
                            fullWidth
                          />
                        </Grid>
                      ) : null}
                    </>
                  ) : null}
                </Grid>
              </Grid>

              {!selectedOrgans.includes('NONE') || !selectedTissues.includes('NONE') ? (
                <>
                  {selectedOrgans.length > 0 &&
                  !selectedOrgans.includes('NONE') &&
                  bsdDonor.allocationStatus === OPTIONS_ALLOCATIONSTATUS[0].value ? (
                    <Grid container style={{ padding: 10 }}>
                      <Grid item sm={12}>
                        <GreenTagSectionHeader headerText="ORGAN DETAILS" />
                      </Grid>
                      <OrganDetails
                        bsdDonor={bsdDonor}
                        setBsdDonor={setBsdDonor}
                        hospitalId={hospitalId}
                        selectedOrgans={selectedOrgans}
                        userId={userId}
                        userType={userType}
                        _isDisabled={_isDisabled}
                        validation={validation}
                        onValidation={onValidation}
                        handleRecipientHospitalChange={handleRecipientHospitalChange}
                        handleDonorOrganSpecialistChange={handleDonorOrganSpecialistChange}
                        handleDonorOrganDetailsForOtherStateRecipientHospitalNameChange={
                          handleDonorOrganDetailsForOtherStateRecipientHospitalNameChange
                        }
                        handleDonorOrganDetailsForOtherStateRetrievingSurgeonNameChange={
                          handleDonorOrganDetailsForOtherStateRetrievingSurgeonNameChange
                        }
                        handleDonorOrganDetailsForOtherStateRetrievingSurgeonIMAIdChange={
                          handleDonorOrganDetailsForOtherStateRetrievingSurgeonIMAIdChange
                        }
                        handleDonorOrganDetailsForOtherStateRetrievingAnaesthetistNameChange={
                          handleDonorOrganDetailsForOtherStateRetrievingAnaesthetistNameChange
                        }
                        handleDonorOrganDetailsChange={handleDonorOrganDetailsChange}
                        _setDonorHeartOtherSupportiveSpecialists={
                          _setDonorHeartOtherSupportiveSpecialists
                        }
                        _setDonorLeftKidneyOtherSupportiveSpecialists={
                          _setDonorLeftKidneyOtherSupportiveSpecialists
                        }
                        _setDonorRightKidneyOtherSupportiveSpecialists={
                          _setDonorRightKidneyOtherSupportiveSpecialists
                        }
                        _setDonorLiverLeftLateralSegmentOtherSupportiveSpecialists={
                          _setDonorLiverLeftLateralSegmentOtherSupportiveSpecialists
                        }
                        _setDonorLiverRightExtendedLobeOtherSupportiveSpecialists={
                          _setDonorLiverRightExtendedLobeOtherSupportiveSpecialists
                        }
                        _setDonorLiverOtherSupportiveSpecialists={
                          _setDonorLiverOtherSupportiveSpecialists
                        }
                        _setDonorLeftLateralSectionectomyOtherSupportiveSpecialists={
                          _setDonorLeftLateralSectionectomyOtherSupportiveSpecialists
                        }
                        _setDonorLiverHepatectomyOtherSupportiveSpecialists={
                          _setDonorLiverHepatectomyOtherSupportiveSpecialists
                        }
                        _setDonorLiverRightExtendedHepatectomyOtherSupportiveSpecialists={
                          _setDonorLiverRightExtendedHepatectomyOtherSupportiveSpecialists
                        }
                        _setDonorLiverLeftExtendedHepatectomyOtherSupportiveSpecialists={
                          _setDonorLiverLeftExtendedHepatectomyOtherSupportiveSpecialists
                        }
                        _setDonorLiverRightHepatectomyWithoutMHVOtherSupportiveSpecialists={
                          _setDonorLiverRightHepatectomyWithoutMHVOtherSupportiveSpecialists
                        }
                        _setDonorLiverRightHepatectomyWithMHVOtherSupportiveSpecialists={
                          _setDonorLiverRightHepatectomyWithMHVOtherSupportiveSpecialists
                        }
                        _setDonorLiverRightPosteriorSectionectomyOtherSupportiveSpecialists={
                          _setDonorLiverRightPosteriorSectionectomyOtherSupportiveSpecialists
                        }
                        _setDonorLungsOtherSupportiveSpecialists={
                          _setDonorLungsOtherSupportiveSpecialists
                        }
                        _setDonorIntestineOtherSupportiveSpecialists={
                          _setDonorIntestineOtherSupportiveSpecialists
                        }
                        _setDonorPancreasOtherSupportiveSpecialists={
                          _setDonorPancreasOtherSupportiveSpecialists
                        }
                        _setDonorHeartLungsOtherSupportiveSpecialists={
                          _setDonorHeartLungsOtherSupportiveSpecialists
                        }
                        _setDonorHandOtherSupportiveSpecialists={
                          _setDonorHandOtherSupportiveSpecialists
                        }
                        handleDonorOrganDetailsForOtherStateRetrievingAnaesthetistIMAIdChange={
                          handleDonorOrganDetailsForOtherStateRetrievingAnaesthetistIMAIdChange
                        }
                      />
                    </Grid>
                  ) : null}
                  {selectedTissues.length > 0 && !selectedTissues.includes('NONE') ? (
                    <Grid container style={{ padding: 10 }}>
                      <Grid item sm={12}>
                        <GreenTagSectionHeader headerText="TISSUE DETAILS" />
                      </Grid>
                      <TissueDetails
                        bsdDonor={bsdDonor}
                        setBsdDonor={setBsdDonor}
                        hospitalId={hospitalId}
                        selectedTissues={selectedTissues}
                        userId={userId}
                        userType={userType}
                        _isDisabled={_isDisabled}
                        validation={validation}
                        onValidation={onValidation}
                        handleRecipientTissueHospitalChange={handleRecipientTissueHospitalChange}
                        handleDonorTissueSpecialistChange={handleDonorTissueSpecialistChange}
                        handleDonorTissueDetailsForOtherStateRecipientHospitalNameChange={
                          handleDonorTissueDetailsForOtherStateRecipientHospitalNameChange
                        }
                        handleDonorTissueDetailsForOtherStateRetrievingSurgeonNameChange={
                          handleDonorTissueDetailsForOtherStateRetrievingSurgeonNameChange
                        }
                        handleDonorTissueDetailsForOtherStateRetrievingSurgeonIMAIdChange={
                          handleDonorTissueDetailsForOtherStateRetrievingSurgeonIMAIdChange
                        }
                        handleDonorTissueDetailsForOtherStateRetrievingAnaesthetistNameChange={
                          handleDonorTissueDetailsForOtherStateRetrievingAnaesthetistNameChange
                        }
                        handleDonorTissueDetailsChange={handleDonorTissueDetailsChange}
                        _setDonorCorneaOtherSupportiveSpecialists={
                          _setDonorCorneaOtherSupportiveSpecialists
                        }
                        _setDonorSkinOtherSupportiveSpecialists={
                          _setDonorSkinOtherSupportiveSpecialists
                        }
                        _setDonorBonesOtherSupportiveSpecialists={
                          _setDonorBonesOtherSupportiveSpecialists
                        }
                        _setDonorHeartValvesOtherSupportiveSpecialists={
                          _setDonorHeartValvesOtherSupportiveSpecialists
                        }
                        _setDonorBloodVesselsOtherSupportiveSpecialists={
                          _setDonorBloodVesselsOtherSupportiveSpecialists
                        }
                        handleDonorTissueDetailsForOtherStateRetrievingAnaesthetistIMAIdChange={
                          handleDonorTissueDetailsForOtherStateRetrievingAnaesthetistIMAIdChange
                        }
                        handleDonorTissueDetailsForHospitalNameChange={
                          handleDonorTissueDetailsForHospitalNameChange
                        }
                        handleDonorTissueDetailsForPreservedOrDiscardedChange={
                          handleDonorTissueDetailsForPreservedOrDiscardedChange
                        }
                        handleDonorTissueDetailsForViableNonViableChange={
                          handleDonorTissueDetailsForViableNonViableChange
                        }
                      />
                    </Grid>
                  ) : null}
                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                    style={{ marginLeft: 20 }}
                  >
                    <GreenTagSectionHeader headerText="Donor Other Details" />
                  </Grid>
                  <Grid item style={{ marginLeft: 20 }}>
                    {/* <Typography>Donor other details</Typography> */}
                  </Grid>

                  <Grid
                    container
                    spacing={5}
                    style={{ marginLeft: 8, marginRight: 8, marginTop: 10, marginBottom: 20 }}
                  >
                    <Grid item sm={4}>
                      <Typography>NOTTO ID</Typography>
                      <ValidatedInput
                        required
                        value={bsdDonor.nottoId}
                        // label="NOTTO ID"
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            nottoId: e.target.value,
                          });
                        }}
                        onValidation={onValidation}
                        validation={validation}
                        schema={Joi.string()
                          .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                          .message({
                            'string.pattern.base': 'Cannot contain spaces at start and end',
                          })
                          .pattern(REGEXEXP_ALPHANUMERIC)
                          .message({
                            'string.pattern.base': 'Accept only alphanumeric characters',
                          })}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <ValidatedAttachment
                        id="nottoIdAttacment"
                        name="nottoIdAttacment"
                        label="NOTTO ID Proof"
                        value={bsdDonor.nottoIdAttachment}
                        acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                        schema={Joi.string()}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          const newDonor = {
                            ...bsdDonor,
                            nottoIdAttachment: {
                              ...bsdDonor.nottoIdAttachment,
                              name: file.name,
                              file,
                            },
                          };
                          setBsdDonor({
                            ...bsdDonor,

                            ...newDonor,
                          });
                        }}
                        validation={validation}
                        onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={4} xs={10}>
                      <Typography>Date of Registration</Typography>
                      <ValidatedDate
                        required
                        value={bsdDonor.registrationDate}
                        // label="Date of Registration"
                        disabled={_isDisabled()}
                        onChange={(v) => {
                          setBsdDonor({
                            ...bsdDonor,
                            registrationDate: v,
                          });
                        }}
                        schema={Joi.date().max('now')}
                        onValidation={onValidation}
                        validation={validation}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={4} xs={10}>
                      <Typography>Admission Date</Typography>
                      <ValidatedDate
                        required
                        value={bsdDonor.admissionDate}
                        // label="Admission Date"
                        disabled={_isDisabled()}
                        onChange={(v) => {
                          setBsdDonor({
                            ...bsdDonor,
                            admissionDate: v,
                          });
                        }}
                        schema={Joi.date().max('now')}
                        fullWidth
                        validation={validation}
                        onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={4} xs={10}>
                      <Typography>Retrieval Date</Typography>
                      <ValidatedDate
                        required
                        value={bsdDonor.retrievalDate}
                        // label="Retrieval Date"
                        disabled={_isDisabled()}
                        onChange={(v) => {
                          setBsdDonor({
                            ...bsdDonor,
                            retrievalDate: v,
                          });
                        }}
                        // schema={Joi.string()}
                        onValidation={onValidation}
                        validation={validation}
                        fullWidth
                        schema={Joi.date().max('now')}
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>Days of Ventilators Support</Typography>
                      <ValidatedInput
                        required
                        value={bsdDonor.ventilatorDays}
                        // label="Days of Ventilators Support"
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            ventilatorDays: e.target.value,
                          });
                        }}
                        onValidation={onValidation}
                        validation={validation}
                        schema={Joi.string()
                          .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                          .message({
                            'string.pattern.base': 'Cannot contain spaces at start and end',
                          })
                          .pattern(REGEXEXP_ALPHANUMERIC)
                          .message({
                            'string.pattern.base': 'Accept only alphanumeric characters',
                          })}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>Ventilator Support Details</Typography>
                      <ValidatedInput
                        required
                        value={bsdDonor.ventilatorSupport}
                        // label="Ventilator Support Details"
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            ventilatorSupport: e.target.value,
                          });
                        }}
                        onValidation={onValidation}
                        validation={validation}
                        schema={Joi.string().pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN).message({
                          'string.pattern.base': 'Cannot contain spaces at start and end',
                        })}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography variant="body1">First Apnea Date and Time</Typography>
                      <ValidatedDate
                        required
                        fullWidth
                        value={bsdDonor?.firstApneaTime}
                        label=""
                        type="datetime-local"
                        placeholder=""
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            firstApneaTime: e,
                          });
                        }}
                        disabled={_isDisabled()}
                        onValidation={onValidation}
                        validation={validation}
                        schema={Joi.date().max('now')}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography variant="body1">First Apnea baseline Report</Typography>
                      <ValidatedAttachment
                        required
                        acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                        value={bsdDonor?.firstApneaBaselineReport}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setBsdDonor({
                            ...bsdDonor,
                            firstApneaBaselineReport: {
                              ...firstApneaBaselineReport,
                              name: file.name,
                              file,
                            },
                          });
                        }}
                        onValidation={onValidation}
                        validation={validation}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography variant="body1">First Apnea after 10 minutes Report</Typography>
                      <ValidatedAttachment
                        required
                        acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                        value={bsdDonor.firstApneaAfterTenMinutesReport}
                        disabled={_isDisabled()}
                        onValidation={onValidation}
                        validation={validation}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setBsdDonor({
                            ...bsdDonor,
                            firstApneaAfterTenMinutesReport: {
                              ...firstApneaAfterTenMinutesReport,
                              name: file.name,
                              file,
                            },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography variant="body1">Second Apnea Date and Time</Typography>
                      <ValidatedDate
                        required
                        fullWidth
                        value={bsdDonor?.secondApneaTime}
                        label=""
                        type="datetime-local"
                        placeholder=""
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            secondApneaTime: e,
                          });
                        }}
                        disabled={_isDisabled()}
                        onValidation={onValidation}
                        validation={validation}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography variant="body1">Second Apnea baseline Report</Typography>
                      <ValidatedAttachment
                        required
                        acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                        value={bsdDonor?.secondApneaBaselineReport}
                        disabled={_isDisabled()}
                        onValidation={onValidation}
                        validation={validation}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setBsdDonor({
                            ...bsdDonor,
                            secondApneaBaselineReport: {
                              ...secondApneaBaselineReport,
                              name: file.name,
                              file,
                            },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography variant="body1">Second Apnea after 10 minutes Report</Typography>
                      <ValidatedAttachment
                        required
                        acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                        value={bsdDonor?.secondApneaAfterTenMinutesReport}
                        disabled={_isDisabled()}
                        onValidation={onValidation}
                        validation={validation}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setBsdDonor({
                            ...bsdDonor,
                            secondApneaAfterTenMinutesReport: {
                              ...secondApneaAfterTenMinutesReport,
                              name: file.name,
                              file,
                            },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>Treating Doctor Name</Typography>
                      <ValidatedInput
                        required
                        value={bsdDonor.treatingDoctor}
                        // label="Treating Doctor Name"
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            treatingDoctor: e.target.value,
                          });
                        }}
                        fullWidth
                        onValidation={onValidation}
                        validation={validation}
                        schema={Joi.string()
                          .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                          .message({
                            'string.pattern.base': 'Cannot contain spaces at start and end',
                          })
                          .pattern(REGEXP_ONLYCHARS)
                          .message({ 'string.pattern.base': 'Accept only alphabet characters' })}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <ValidatedAttachment
                        id="donorPhoto"
                        label="Donor Photo"
                        value={bsdDonor.photo}
                        schema={Joi.string()}
                        disabled={_isDisabled()}
                        acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setBsdDonor({
                            ...bsdDonor,
                            photo: {
                              name: file.name,
                              file,
                            },
                          });
                        }}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <Typography>Occupation</Typography>
                      <ValidatedInput
                        // required
                        value={bsdDonor?.occupation}
                        // onValidation={onValidation}
                        // validation={validation}
                        disabled={_isDisabled()}
                        schema={Joi.string()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            occupation: e.target.value,
                          });
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <ValidatedRadio
                        label="Smoking"
                        value={bsdDonor?.smoking}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            smoking: e.target.value === 'true',
                          });
                        }}
                        options={[
                          { value: true, label: 'YES' },
                          { value: false, label: 'NO' },
                        ]}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <ValidatedRadio
                        label="Alcohol"
                        value={bsdDonor?.alcohol}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            alcohol: e.target.value === 'true',
                          });
                        }}
                        options={[
                          { value: true, label: 'YES' },
                          { value: false, label: 'NO' },
                        ]}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <ValidatedRadio
                        label="Co-morbidities"
                        value={bsdDonor?.comorbidities}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            comorbidities: e.target.value === 'true',
                          });
                        }}
                        options={[
                          { value: true, label: 'YES' },
                          { value: false, label: 'NO' },
                        ]}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <ValidatedRadio
                        label="HT"
                        value={bsdDonor?.hypertension}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            hypertension: e.target.value === 'true',
                          });
                        }}
                        options={[
                          { value: true, label: 'YES' },
                          { value: false, label: 'NO' },
                        ]}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <ValidatedRadio
                        label="DM"
                        value={bsdDonor?.diabetes}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            diabetes: e.target.value === 'true',
                          });
                        }}
                        options={[
                          { value: true, label: 'YES' },
                          { value: false, label: 'NO' },
                        ]}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <ValidatedRadio
                        label="IHD"
                        value={bsdDonor?.ischemicHeartDisease}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            ischemicHeartDisease: e.target.value === 'true',
                          });
                        }}
                        options={[
                          { value: true, label: 'YES' },
                          { value: false, label: 'NO' },
                        ]}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <ValidatedRadio
                        label="Pulmonary"
                        value={bsdDonor?.pulmonary}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            pulmonary: e.target.value === 'true',
                          });
                        }}
                        options={[
                          { value: true, label: 'YES' },
                          { value: false, label: 'NO' },
                        ]}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <ValidatedRadio
                        label="Previous surgery"
                        value={bsdDonor?.previousSurgery}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            previousSurgery: e.target.value === 'true',
                          });
                        }}
                        options={[
                          { value: true, label: 'YES' },
                          { value: false, label: 'NO' },
                        ]}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                    style={{ marginLeft: 20 }}
                  >
                    <GreenTagSectionHeader headerText="Donor Diagnosis and Reports" />
                  </Grid>

                  <Grid
                    container
                    justify="flex-start"
                    spacing={3}
                    style={{ marginTop: 10, marginBottom: 10, marginLeft: 10 }}
                  >
                    <Grid item sm={4} xs={12}>
                      <Typography>Cause Of Death</Typography>
                      <ValidatedSelect
                        required
                        // label="Cause Of Death"
                        value={bsdDonor?.causeOfDeath}
                        options={[
                          {
                            label: 'Head injury / Trauma',
                            value: 'HEAD_INJURY',
                          },
                          {
                            label: 'Brain Haemorrhage',
                            value: 'BRAIN_HAEMORRHAGE',
                          },
                          {
                            label: 'Infections',
                            value: 'INFECTIONS',
                          },
                          {
                            label: 'Brain tumor',
                            value: 'BRAIN_TUMOR',
                          },
                          {
                            label: 'Ischemic Stroke',
                            value: 'ISCHEMIC_STROKE',
                          },
                          {
                            label: 'Hypoxia / anoxic Brain injury',
                            value: 'HYPOXIAANOXIC',
                          },
                          {
                            label: 'IC Bleed',
                            value: 'ICBLEED',
                          },
                          {
                            label: 'Other',
                            value: 'OTHER',
                          },
                        ]}
                        onValidation={onValidation}
                        validation={validation}
                        onChange={(v) => {
                          setBsdDonor({
                            ...bsdDonor,
                            causeOfDeath: v.value,
                          });
                        }}
                        disabled={_isDisabled()}
                      />
                    </Grid>
                    {bsdDonor?.causeOfDeath === 'OTHER' ? (
                      <Grid item sm={4} xs={12}>
                        <Typography>Cause of Death Other Reason</Typography>
                        <ValidatedInput
                          required
                          fullWidth
                          value={bsdDonor?.causeOfDeathOtherReason}
                          // label="Cause of Death Other Reason"
                          type="text"
                          placeholder="Cause of Death Other Reason"
                          onChange={(e) => {
                            setBsdDonor({
                              ...bsdDonor,
                              causeOfDeathOtherReason: e.target.value,
                            });
                          }}
                          disabled={_isDisabled()}
                          validation={validation}
                          onValidation={onValidation}
                          schema={Joi.string().pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN).message({
                            'string.pattern.base': 'Cannot contain spaces at start and end',
                          })}
                        />
                      </Grid>
                    ) : null}
                    <Grid item sm={4} xs={9}>
                      <Typography>Diagnosis</Typography>
                      <ValidatedInput
                        required
                        value={bsdDonor.diagnosis}
                        // label="Diagnosis"
                        onValidation={onValidation}
                        validation={validation}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            diagnosis: e.target.value,
                          });
                        }}
                        schema={Joi.string().pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN).message({
                          'string.pattern.base': 'Cannot contain spaces at start and end',
                        })}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <ValidatedAttachment
                        acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                        value={bsdDonor.form8}
                        label="Form 8"
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            form8: {
                              name: e.target.files[0].name,
                              file: e.target.files[0],
                            },
                          });
                        }}
                        disabled={_isDisabled()}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <ValidatedAttachment
                        acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                        value={bsdDonor.form10}
                        label="Form 10"
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            form10: {
                              name: e.target.files[0].name,
                              file: e.target.files[0],
                            },
                          });
                        }}
                        disabled={_isDisabled()}
                        // validation={validation}
                        // onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={4} xs={9}>
                      <Typography>Medico Legal Case ?</Typography>
                      <ValidatedSelect
                        // label="Medico Legal Case ?"
                        value={policeReport.value}
                        options={OPTIONS_MEDICOLEGALCASE}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            policeReport: { ...policeReport, value: e.value },
                          });
                        }}
                        disabled={_isDisabled()}
                        validation={validation}
                        onValidation={onValidation}
                      />
                    </Grid>
                    {policeReport.value === 'POLICENOCAPPROVED' ? (
                      <Grid item sm={12} xs={12}>
                        <ValidatedAttachment
                          acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                          value={policeReport.attachment}
                          disabled={_isDisabled()}
                          label="MLC Report"
                          onChange={(e) => {
                            setBsdDonor({
                              ...bsdDonor,
                              policeReport: {
                                ...policeReport,
                                attachment: {
                                  name: e.target.files[0].name,
                                  file: e.target.files[0],
                                },
                              },
                            });
                          }}
                          validation={validation}
                          onValidation={onValidation}
                        />
                      </Grid>
                    ) : null}
                  </Grid>

                  <Grid
                    container
                    justify="flex-start"
                    spacing={3}
                    style={{ marginTop: 10, marginBottom: 10, marginLeft: 20 }}
                  >
                    <Grid item>
                      <Typography className={classes.bsdDetailsHeading}>
                        Other Related forms
                      </Typography>
                    </Grid>
                  </Grid>
                  {relatedForms.map((r, idx) => {
                    return (
                      <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          marginLeft: 15,
                        }}
                      >
                        <Grid item sm={3}>
                          <ValidatedSelect
                            label="Form type"
                            value={r.formType}
                            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                            options={OPTIONS_TXFORMTYPE}
                            onChange={(v) => {
                              const newRelatedForms = [...relatedForms];

                              const newForm = {
                                formType: v.value,
                                attachment: r.attachment,
                              };
                              newRelatedForms[newRelatedForms.length - 1] = newForm;

                              setBsdDonor({
                                ...bsdDonor,
                                relatedForms: [...newRelatedForms],
                              });
                            }}
                            disabled={_isDisabled()}
                            validation={validation}
                            onValidation={onValidation}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <ValidatedAttachment
                            required
                            id="attachment"
                            name="attachment"
                            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                            value={r.attachment}
                            schema={Joi.string()}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const newRelatedForms = [...relatedForms];

                              const newForm = {
                                formType: r.formType,
                                attachment: {
                                  file,
                                  name: file.name,
                                },
                              };
                              newRelatedForms[newRelatedForms.length - 1] = newForm;

                              setBsdDonor({
                                ...bsdDonor,
                                relatedForms: [...newRelatedForms],
                              });
                            }}
                            disabled={_isDisabled()}
                            validation={validation}
                            onValidation={onValidation}
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                              const newRelatedForms = relatedForms.filter(
                                (nr, nrIndex) => nrIndex !== idx
                              );
                              setBsdDonor({
                                ...bsdDonor,
                                relatedForms: [...newRelatedForms],
                              });
                            }}
                            disabled={_isDisabled()}
                          >
                            Delete form
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid style={{ marginLeft: 30 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setBsdDonor({
                          ...bsdDonor,
                          relatedForms: [
                            ...relatedForms,
                            {
                              formType: '',
                              attachment: {
                                name: '',
                                key: '',
                                url: '',
                              },
                            },
                          ],
                        });
                      }}
                      disabled={_isDisabled()}
                    >
                      Add From
                    </Button>
                  </Grid>
                  {'  '}
                </>
              ) : null}

              {selectedOrgans.includes('NONE') && (
                <>
                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                    style={{ marginLeft: 20 }}
                  >
                    <GreenTagSectionHeader headerText="Donor Other Details" />
                  </Grid>
                  <Grid item style={{ marginLeft: 20 }}>
                    {/* <Typography>Donor other details</Typography> */}
                  </Grid>

                  <Grid
                    container
                    spacing={5}
                    style={{ marginLeft: 8, marginRight: 8, marginTop: 10, marginBottom: 20 }}
                  >
                    <Grid item sm={4} xs={10}>
                      <ValidatedDate
                        required
                        value={bsdDonor.admissionDate}
                        label="Admission Date"
                        disabled={_isDisabled()}
                        onChange={(v) => {
                          setBsdDonor({
                            ...bsdDonor,
                            admissionDate: v,
                          });
                        }}
                        schema={Joi.date().max('now')}
                        fullWidth
                        validation={validation}
                        onValidation={onValidation}
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <ValidatedInput
                        required
                        value={bsdDonor.treatingDoctor}
                        label="Treating Doctor Name"
                        disabled={_isDisabled()}
                        schema={Joi.string()}
                        onChange={(e) => {
                          setBsdDonor({
                            ...bsdDonor,
                            treatingDoctor: e.target.value,
                          });
                        }}
                        fullWidth
                        onValidation={onValidation}
                        validation={validation}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid
                container
                spacing={2}
                justify="center"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Grid item>
                  {bsdDonor.formStatus !== 'SUBMITTED' ? (
                    // <Button
                    //   variant="contained"
                    //   color="primary"
                    //   onClick={() => {
                    //     _handleSave();
                    //   }}
                    // >
                    //   Save
                    // </Button>
                    <LoadableButton
                      apiCallId={saveBsdDonorApiId}
                      apiCallStatuses={apiCallStatuses}
                      loadingText="Saving..."
                      onClick={() => {
                        _handleSave();
                      }}
                      style={{
                        fontWeight: 600,
                        fontSize: '16px',
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Save
                    </LoadableButton>
                  ) : null}
                  {'     '}
                  {bsdDonor.formStatus === 'DRAFT' ? (
                    // <Button
                    //   variant="contained"
                    //   color="primary"
                    //   disabled={!isFormFilled}
                    //   onClick={() => {
                    //     _submitDonor();
                    //   }}
                    // >
                    //   Submit
                    // </Button>
                    <LoadableButton
                      apiCallId={submitBsdDonorApiId}
                      apiCallStatuses={apiCallStatuses}
                      disabled={!isFormFilled}
                      loadingText="Submitting..."
                      onClick={() => {
                        _submitDonor();
                      }}
                      style={{
                        fontWeight: 600,
                        fontSize: '16px',
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Submit
                    </LoadableButton>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </DataLoadingScreen>
        </Paper>
      </Grid>
    );
  };

  return (
    <Box className={classes.bsdBoxStyle} marginLeft="15px" marginRight="15px">
      {redirectToSaved ? <Redirect to={`/bsdDonor/saved/${draftId}`} /> : null}
      <Paper className={classes.paperBsdInfo}>
        <Grid container style={{ marginTop: 20, marginBottom: 10 }}>
          <Grid item>
            <Typography
              variant="h3"
              style={{ fontSize: '16px', marginBottom: '10px', padding: 10 }}
            >
              BSD Donor
            </Typography>
            <ul>
              <li style={{ fontSize: '14px', marginBottom: '7px' }}>
                Application for registration of hospital to carry out organ or tissue
                transplantation other than cornea. It needs to be filled by head of the institution.
              </li>
              <li style={{ fontSize: '14px', marginBottom: '15px' }}>
                They are only valid for the next 5 years and the hospital will need to renew these
                approved form-12 on the completion of 5 years.
              </li>
            </ul>
          </Grid>
        </Grid>
      </Paper>
      <Box display="flex" flexDirection="row">
        <Grid container>
          <Grid item sm={2}>
            {/* <Paper className={classes.paperBsdSections}>
              <Grid container style={{ padding: 20 }}>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.bsdSectionsHeadingStyle}>
                    New BSD Donor
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.bsdSectionsStyle}>
                    Donor Details
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.bsdSectionsStyle}>
                    Donor Other Details
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.bsdSectionsStyle}>
                    Donor Diagnosis and Reports
                  </Typography>
                </Grid>
              </Grid>
            </Paper> */}
          </Grid>
          {_renderBsdDonor()}
          {_renderNoNottoIdDialog()}
          {_renderNoOrganDialog()}
          {_renderLiverOrganDialog()}
        </Grid>
      </Box>
    </Box>
  );
}

BsdDonor.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      draftId: PropTypes.string,
    }),
  }),
  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
};
BsdDonor.defaultProps = {
  match: {
    params: {
      draftId: undefined,
    },
  },
};

export default compose(withRouter, withValidation)(BsdDonor);
