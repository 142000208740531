import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { BLOODGROUP, FORMAT_DATE_UNIXTS } from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class LiverRoutineFormPdf {
  formData = {};

  constructor(formData) {
    const {
      basicDetails,
      contactNos,
      emails,
      hospitalName,
      ztccZone,
      organs,
      liverFormDetails,
      liverFormDetails: {
        routineInfo,
        meldExceptionCriteria,
        meldPeld,
        complicationScore,
      },
    } = formData;

    this.formData = formData;

    const _yesNoAndEmptyBooleanFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };

    const _yesNoAndEmptyFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      if (value === 'true') {
        return 'YES';
      }
      if (value === 'false') {
        return 'NO';
      }
      return '-';
    };

    const _nationalityFormatter = (value) => {
      if (value === 'INDIAN_NATIONAL') {
        return 'Indian';
      }
      if (value === 'OCI_CARDHOLDER') {
        return 'Overseas Citizen of India (OCI) card holder';
      }
      if (value === 'FOREIGN_NATIONAL') {
        return 'Foreign national';
      }
      return '-';
    };

    const calculateCurrentAge = () => {
      if (basicDetails.dateOfBirth) {
        const duration = moment.duration(
          moment().diff(moment(basicDetails.dateOfBirth, FORMAT_DATE_UNIXTS))
        );
        return `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`;
      }
      if (basicDetails.currentAge) {
        return basicDetails.currentAge;
      }
      return '-';
    };

    const _getMlasScore = () => {
      let scoreAbc = 0;
      let scoreCd = 0;
      if (meldPeld?.meld) {
        scoreAbc += meldPeld?.meld;
      }
      if (complicationScore?.complicationScoreResult?.complicationPoints) {
        scoreAbc += complicationScore?.complicationScoreResult?.complicationPoints;
      }
      if (meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPoints) {
        scoreCd += meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPoints;
      }
      return Math.max(scoreAbc, scoreCd);
    };

    const sexFormatter = (value) => {
      if (value === 'M') {
        return 'Male';
      }
      if (value === 'F') {
        return 'Female';
      }
      return '-';
    };

    const childPuchScoreCalculator = () => {
      let totalPoints = 0;
      if (routineInfo?.ascites) {
        if (routineInfo?.ascites === 'NONE') {
          totalPoints += 1;
        }
        if (routineInfo?.ascites === 'MILD') {
          totalPoints += 2;
        }
        if (routineInfo?.ascites === 'MODTOSEVERE') {
          totalPoints += 3;
        }
      }

      if (routineInfo?.encephalopathy) {
        if (routineInfo?.encephalopathy === 'NONE') {
          totalPoints += 1;
        }
        if (routineInfo?.encephalopathy === 'GRADEONETWO') {
          totalPoints += 2;
        }
        if (routineInfo?.encephalopathy === 'GRADETHREEFOUR') {
          totalPoints += 3;
        }
      }

      if (meldPeld?.listingBilirubin) {
        if (meldPeld?.listingBilirubin < 2) {
          totalPoints += 1;
        }
        if (meldPeld?.listingBilirubin >= 2 && meldPeld?.listingBilirubin <= 3) {
          totalPoints += 2;
        }
        if (meldPeld?.listingBilirubin > 3) {
          totalPoints += 3;
        }
      }

      if (meldPeld?.listingInr) {
        if (meldPeld?.listingInr < 1.7) {
          totalPoints += 1;
        }
        if (meldPeld?.listingInr >= 1.7 && meldPeld?.listingInr <= 2.3) {
          totalPoints += 2;
        }
        if (meldPeld?.listingInr > 2.3) {
          totalPoints += 3;
        }
      }

      if (meldPeld?.listingAlbumin) {
        if (meldPeld?.listingAlbumin > 3.5) {
          totalPoints += 1;
        }
        if (meldPeld?.listingAlbumin >= 2.8 && meldPeld?.listingAlbumin <= 3.5) {
          totalPoints += 2;
        }
        if (meldPeld?.listingAlbumin < 2.8) {
          totalPoints += 3;
        }
      }
      return totalPoints;
    };

    const _jaundiceFormatter = () => {
      if (routineInfo?.jaundice === true) {
        return 'YES';
      }
      if (routineInfo?.jaundice === false) {
        return 'NO';
      }
      return '-';
    };

    const _ascitesFormatter = () => {
      if (routineInfo?.ascites === 'MILD') {
        return 'Mild';
      }
      if (routineInfo?.ascites === 'NONE') {
        return 'None';
      }
      if (routineInfo?.ascites === 'MODTOSEVERE') {
        return 'Moderate to Severe';
      }
      return '-';
    };
    const _getZtccZoneLabelById = (zone) => {
      if (zone === '7') {
        return 'ZTCC MUMBAI';
      }
      if (zone === '8') {
        return 'ZTCC PUNE';
      }
      if (zone === '9') {
        return 'ZTCC NAGPUR';
      }
      if (zone === '10') {
        return 'ZTCC AURANGABAD';
      }
      if (zone) {
        return zone;
      }

      return '';
    };
    const _sbpFormatter = () => {
      if (complicationScore?.sbp?.sbpAnc > 250) {
        return 'YES';
      }
      if (complicationScore?.sbp?.sbpAnc < 250) {
        return 'NO';
      }
      return '-';
    };

    const encephalopathyFormatter = () => {
      if (routineInfo?.encephalopathy === 'GRADEONETWO') {
        return 'Grade I-II';
      }
      if (routineInfo?.encephalopathy === 'NONE') {
        return 'None';
      }
      if (routineInfo?.encephalopathy === 'GRADETHREEFOUR') {
        return 'Grade III-IV';
      }
      return '-';
    };

    const hrsFormatter = () => {
      if (routineInfo?.hrs === true) {
        return 'YES';
      }
      if (routineInfo?.hrs === false) {
        return 'NO';
      }
      return '-';
    };

    const pvtFormatter = () => {
      if (routineInfo?.pvt === true) {
        return 'YES';
      }
      if (routineInfo?.pvt === false) {
        return 'NO';
      }
      return '-';
    };

    const hpsFormatter = () => {
      if (meldExceptionCriteria?.hps?.hpsYesNo === true) {
        return 'YES';
      }
      if (meldExceptionCriteria?.hps?.hpsYesNo === false) {
        return 'NO';
      }
      return '-';
    };

    const hccFormatter = () => {
      if (meldExceptionCriteria?.hcc?.hccYesNo === true) {
        return 'YES';
      }
      if (meldExceptionCriteria?.hcc?.hccYesNo === false) {
        return 'NO';
      }
      return '-';
    };

    const hccTreatmentDoneFormatter = () => {
      let hccTreatments = '';
      if (routineInfo.hccTreatment) {
        if (routineInfo?.hccTreatment?.tace === true) {
          hccTreatments += 'Tace - ';
        }
        if (routineInfo?.hccTreatment?.tare === true) {
          hccTreatments += 'Tare - ';
        }
        if (routineInfo?.hccTreatment?.rfa === true) {
          hccTreatments += 'Rfa - ';
        }
        if (routineInfo?.hccTreatment?.resection === true) {
          hccTreatments += 'Resection - ';
        }
        if (routineInfo?.hccTreatment?.mwa === true) {
          hccTreatments += 'Mwa - ';
        }
        if (routineInfo?.hccTreatment?.other) {
          hccTreatments += routineInfo?.hccTreatment?.other;
        }
        return hccTreatments;
      }
      return '-';
    };

    const reportDocDefinition = {
      content: [
        // {
        //   text: 'SOTTO request for Routine Listing for Liver Transplant',
        //   alignment: 'center',
        //   style: 'title',
        //   bold: true,
        //   fontSize: 18,
        // },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'State Appropriate Authority of Maharashtra',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: 'ROUTINE LIVER REGISTRATION',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `ZTCC Zone: ${_getZtccZoneLabelById(ztccZone)}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date of Registration : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.dateOfRegistration
                      ? moment(basicDetails?.dateOfRegistration, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Name:`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.name ? basicDetails?.name : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `NOTTO ID : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.nottoId}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Birth: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.dateOfBirth
                      ? moment(basicDetails?.dateOfBirth, FORMAT_DATE_UNIXTS).format('DD MMMM YYYY')
                      : '-'
                  } `,

                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Age: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${calculateCurrentAge()}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Gender: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.sex ? basicDetails?.sex : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Group: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Weight (Kg): `,
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.weightKg}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in cm):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightCm}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in inches):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightIn ? basicDetails?.heightIn : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${hospitalName || '-'}`, colSpan: 2 },
                {},
              ],

              [
                {
                  text: `Address & Telephone No: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.presentAddress ? basicDetails?.presentAddress : '-'} - ${
                    contactNos.length > 0 ? contactNos[0] : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Patient contact emails:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${emails.length > 0 ? emails[0] : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Economic Status: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.economicStatus ? basicDetails?.economicStatus : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Occupation',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.occupation}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_nationalityFormatter(basicDetails?.nationality)}`,
                  colSpan: 2,
                },
                {},
              ],
              ...(basicDetails?.nationality !== 'INDIAN_NATIONAL'
                ? [
                    [
                      {
                        text: `Indian Contact Number : `,
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${
                          basicDetails?.indianContactNumber
                            ? basicDetails?.indianContactNumber
                            : '-'
                        }`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              [
                {
                  text: `Country of Residence: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.countryOfResidence ? basicDetails?.countryOfResidence : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              // [
              //   {
              //     text: 'Date of RT PCR',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${
              //       basicDetails.rtpcrdate
              //         ? moment(basicDetails.rtpcrdate, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
              //         : '-'
              //     }`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],
              // [
              //   {
              //     text: 'Is Covid Vaccination Done?',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${_covidVaccinationFormatter(basicDetails?.covidVaccination?.dosesDone)}`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],

              [
                {
                  text: 'UHID',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.uhid}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Present Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.presentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Permanent Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.permanentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Date of Admission / OPD',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.dateOfAdmission
                      ? moment(basicDetails?.dateOfAdmission, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Primary physician Name:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.primaryPhysicianName}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Primary physician contact numbers:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.primaryPhysicianContactNo
                      ? basicDetails?.primaryPhysicianContactNo
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary physician contact email:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.primaryPhysicianEmail ? basicDetails?.primaryPhysicianEmail : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary Questions Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient have active / metastatic malignancy?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMalignancy
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active MDR infection with sepsis or septic shock?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMdrInfection
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have severe irreversible systemic disease (cardiac, pulmonary, neurological or others with very short longevity)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noSevereIrreversibleSystemicDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active or recent major unresolved psychiatric illness?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noMajorUnresolvedPsychiatricIllness
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have high risk of non-compliance with long-term immunosuppression?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noHighRiskOfNonCompliance
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have adequate social or family support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(basicDetails?.prerequisites?.familySupport)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Identity Proof Document Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Document Type',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentType}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identify Proof Document Number',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentNumber}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identity Proof Document Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
            ],
          },
        },
        {
          text: 'Family Member Details',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Family Members Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Relation With Patient', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Number', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Email', style: 'tableHeader', alignment: 'left' },
              ],
              ...basicDetails.familyMembers.map((a) => [
                { text: `${a.name ? a.name : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.relation ? a.relation : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.contact ? a.contact : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.email ? a.email : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
          // layout: 'lightHorizontalLines',
        },
        {
          text: 'General Information',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Requested Organs', style: 'tableHeader', alignment: 'left' },
                { text: 'Requested Organs Listing Type', style: 'tableHeader', alignment: 'left' },
              ],
              ...organs.map((o) => [
                {
                  text: `${o.organType ? o.organType : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                { text: `${o.category ? o.category : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'General Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'Is Patient Willing to Accept ECD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'ECD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Is Patient Willing to Accept DCD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'DCD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Has the patient been registered in Maharashtra in Past:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.pastZtccListingInfo?.pastZtccListing
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Status',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastListingStatus}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Further Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastZtccDetails}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'MELD',
                  style: 'tableHeader',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Listing Bilirubin (mg/dl)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldPeld?.listingBilirubin}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Listing INR',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldPeld?.listingInr}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Listing Creatinine',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldPeld?.listingCreatinine}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Listing Albumin (g/dL)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldPeld?.listingAlbumin}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Listing Na',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldPeld?.listingNa}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Growth failure at Listing less than 2 standard deviation',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(meldPeld?.listingGrowthFailure)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'PELD',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldPeld?.listingOptnPeld}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'MLAS (A)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldPeld?.meld}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Viral Marker Status',
                  style: 'tableHeader',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'HBsAg',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${routineInfo?.viralMarkerStatus?.hbsagRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HBsAg Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    routineInfo?.viralMarkerStatus?.hbsagReportDate
                      ? moment(
                          routineInfo?.viralMarkerStatus?.hbsagReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HBsAg Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${routineInfo?.viralMarkerStatus?.hbsagReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Anti HCV',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${routineInfo?.viralMarkerStatus?.antiHcvRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Anti HCV Report Date `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    routineInfo?.viralMarkerStatus?.antiHcvReportDate
                      ? moment(
                          routineInfo?.viralMarkerStatus?.antiHcvReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Anti HCV Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${routineInfo?.viralMarkerStatus?.antiHcvReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'HCV RNA Quantitative',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                      ? routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HCV RNA Quantitative Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                          ? moment(
                              routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeReportDate,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: `HCV RNA Quantitative Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeReport?.name
                          ? 'ATTACHED'
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],
              [
                {
                  text: 'HCV DNA ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    routineInfo?.viralMarkerStatus?.hcvDnaRemark
                      ? routineInfo?.viralMarkerStatus?.hcvDnaRemark
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HCV DNA Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                routineInfo?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        routineInfo?.viralMarkerStatus?.hcvDnaReportDate
                          ? moment(
                              routineInfo?.viralMarkerStatus?.hcvDnaReportDate,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: `HCV DNA Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                routineInfo?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        routineInfo?.viralMarkerStatus?.hcvDnaReport?.name ? 'ATTACHED' : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: 'HIV',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${routineInfo?.viralMarkerStatus?.hivRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HIV Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    routineInfo?.viralMarkerStatus?.hivReportDate
                      ? moment(
                          routineInfo?.viralMarkerStatus?.hivReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HIV Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${routineInfo?.viralMarkerStatus?.hivReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },

        // [
        //   {
        //     text: `Report Type `,
        //     style: 'tableHeader',
        //     alignment: 'left',
        //     colSpan: 1,
        //   },

        //   meldPeld.reports.map((e) => [{ text: `${e.reportType ? e.reportType : '-'}` }]),

        //   {},
        // ],

        // [
        //   {
        //     text: `Date Of Report `,
        //     style: 'tableHeader',
        //     alignment: 'left',
        //     colSpan: 1,
        //   },
        //   meldPeld.reports.map((e) => [
        //     {
        //       text: `${moment(e.dateOfReport, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')}`,
        //     },
        //   ]),

        //   {},
        // ],

        // [
        //   {
        //     text: `Lab Report`,
        //     style: 'tableHeader',
        //     alignment: 'left',
        //     colSpan: 1,
        //   },
        //   meldPeld.reports.map((e) => [
        //     {
        //       text: `${e.attachment.name}`,
        //     },
        //   ]),
        //   {},
        // ],
        // [
        //   {
        //     text: `Syndromes `,
        //     style: 'tableHeader',
        //     alignment: 'left',
        //     colSpan: 1,
        //   },

        //   meldPeld.syndromes.map((e) => [{ text: `${e.syndrome ? e.syndrome : '-'}` }]),

        //   {},
        // ],
        // [
        //   {
        //     text: `Syndrome Report`,
        //     style: 'tableHeader',
        //     alignment: 'left',
        //     colSpan: 1,
        //   },
        //   meldPeld.syndromes.map((e) => [
        //     {
        //       text: `${e.attachment.name}`,
        //     },
        //   ]),
        //   {},
        // ],

        // [
        //   {
        //     text: `Etiologies `,
        //     style: 'tableHeader',
        //     alignment: 'left',
        //     colSpan: 1,
        //   },

        //   meldPeld.etiologies.map((e) => [{ text: `${e.etiology ? e.etiology : '-'}` }]),

        //   {},
        // ],
        // [
        //   {
        //     text: `Etiology Report`,
        //     style: 'tableHeader',
        //     alignment: 'left',
        //     colSpan: 1,
        //   },
        //   meldPeld.etiologies.map((e) => [
        //     {
        //       text: `${e.attachment.name}`,
        //     },
        //   ]),
        //   {},
        // ],

        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*'],
            body: [
              [
                { text: 'Report Type', style: 'tableHeader', alignment: 'left' },
                { text: 'Date of Report', style: 'tableHeader', alignment: 'left' },
                { text: 'Lab Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...meldPeld?.reports?.map((e) => [
                {
                  text: `${e.reportType ? e.reportType : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${
                    e.dateOfReport
                      ? moment(e.dateOfReport, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Syndrome', style: 'tableHeader', alignment: 'left' },
                { text: 'Syndrome Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...meldPeld?.syndromes?.map((e) => [
                {
                  text: `${e.syndrome ? e.syndrome : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Etiology', style: 'tableHeader', alignment: 'left' },
                { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...meldPeld?.etiologies?.map((e) => [
                {
                  text: `${e.etiology ? e.etiology : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'Etiology Details ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldPeld?.etiologyDetails ? meldPeld?.etiologyDetails : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Complication Score',
                  style: 'tableHeader',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient have Ascites?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.ascites?.ascitesYesNo
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have moderate to severe Refractory ascites?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.ascites?.refractoryAscitesYesNo
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'DRefractory ascites (≥ 2 LVPs (≥ 5L) / month)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.hepaticHydrothoraxModToSevere?.refractoryAscites
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Refractory ascites (diuretic resistance)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.hepaticHydrothoraxModToSevere?.refractoryAscitesDiuretic
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Refractory ascites (needing noradrenaline / midodrine / terlipressin)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.hepaticHydrothoraxModToSevere?.refractoryAscitesNoradrenaline
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    complicationScore?.ascites?.refractoryAscitesAttachment?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have moderate to severe Hepatic Hydrothorax',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.ascites?.hepaticHydrothoraxYesNo
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Hepatic Hydrothorax (moderate to severe on HRCT/Chest Xray/USG Thorax)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.hepaticHydrothoraxModToSevere
                      ?.hepaticHydrothoraxModerateOnHrctChestXrayUsgThorax
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Hepatic hydrothorax (≥ 2 therapeutic taps)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.hepaticHydrothoraxModToSevere
                      ?.hepaticHydrothoraxTherapeuticTaps
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    complicationScore?.ascites?.hepaticHydrothoraxAttachment?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have Spontaneous Bacterial Peritonitis (SBP)?)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.hepaticHydrothoraxModToSevere
                      ?.hepaticHydrothoraxTherapeuticTaps
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Ascites Fluid Absolute Neutrophil count',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${complicationScore?.sbp?.sbpAnc}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `SBP Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${complicationScore?.sbp?.sbpAncAttachment?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SBP Reports Date `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    complicationScore?.sbp?.sbpAncAttachmentDate
                      ? moment(
                          complicationScore?.sbp?.sbpAncAttachmentDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Eligible for SBP points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${complicationScore?.sbp?.spbPointsEligible}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'SBP points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${complicationScore?.sbp?.spbPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Does the patient have HRS?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(complicationScore?.ascites?.hrsYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${complicationScore?.ascites?.hrsAttachment?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have Hepatic Encephalopathy (HE) needing ICU?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.heNeedingHIcu?.heNeedingHIcuYesNo
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'HE (Past / Current) (≥ grade 3 requiring hospitalization (ICU))',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.heNeedingHIcu?.heNeedingGreaterThanEquals3
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Structural neurological diseases',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.heNeedingHIcu?.structuralNeurologicalDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    complicationScore?.heNeedingHIcu?.heNeedingHIcuAttachment?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Eligible for HE points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${complicationScore?.heNeedingHIcu?.hePointsEligible}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'HE points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${complicationScore?.heNeedingHIcu?.hePoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Does the patient have Variceal Bleed?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.varicealBleedNoEndo?.varicealBleedNoEndoYesNo
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Recurrent variceal bleed (not amenable to endotherapy and / or TIPSS)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    complicationScore?.varicealBleedNoEndo?.recurrentVaricealBleedNotAmenable
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    complicationScore?.varicealBleedNoEndo?.varicealBleedNoEndoAttachment?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Eligible for Recurrent variceal bleed points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${complicationScore?.varicealBleedNoEndo?.recurrentVaricealEligible}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Recurrent variceal bleed points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${complicationScore?.varicealBleedNoEndo?.recurrentVaricealPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Does the patient have Jaundice?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(complicationScore?.jaundiceYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${complicationScore?.jaundiceAttachment?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have PVT?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(complicationScore?.pvtYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${complicationScore?.pvtAttachment?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'MELD Exception Criteria',
                  style: 'tableHeader',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient have Hepatopulmonary Syndrome (HPS)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(meldExceptionCriteria?.hps?.hpsYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.hps?.hpsYesNoAttachment?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'PaO2 less than 60 mmHg on room air',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(meldExceptionCriteria?.hps?.hpsPao2)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Alveolar arterial oxygen gradient greater 15 mmHg /greater 20 mmHg if greater than 64 yrs age',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.hps?.hpsAlveolarGradient
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Bubble ECHO / MAA scan positive for Shunt',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(meldExceptionCriteria?.hps?.hpsShunt)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Clinical e/o liver disease and portal hypertension',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.hps?.hpsLiverDiseaseAndHyperTension
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'E/O underlying primary pulmonary disease',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.hps?.hpsPulmonaryDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Eligible for HPS exception point',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.hps?.eligibleForHpsExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'HPS exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.hps?.hpsExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Does the patient have Pulmonary Arterial Hypertension (PAH)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(meldExceptionCriteria?.pah?.pahYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.pah?.pahYesNoAttachment?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Post-treatment Mean pulmonary arterial pressure (MPAP) 25 to 35 mmHg',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.pah?.pahPostTreatmentMeanPulmonaryArterialPressure
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Post-treatment pulmonary vascular resistance (PVR) 120 to 400 dynes/sec/cm',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.pah?.pahPostTreatmentPulmonaryVascularResistance
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Pulmonary Capillary Wedge Pressure (PCWP) less than 15 mmg',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.pah?.pahPulmonaryCapillaryWedgePressure
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Right Heart Catheterization Reports`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.pah?.pahHeartCathReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Heart Cath Report Date `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.pah?.pahHeartCathReportDate
                      ? moment(
                          meldExceptionCriteria?.pah?.pahHeartCathReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Eligible for PAH exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.pah?.eligibleForPahExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'PAH exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.pah?.pahExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Does the patient have Hepatocellular Carcinoma (HCC)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(meldExceptionCriteria?.hcc?.hccYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.hcc?.hccYesNoAttachment?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Single tumor less than equals 6.5cm',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.hcc?.hccSingleTumorLessThan6AndHalfCm
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'less than equals 3 tumors with largest tumor less than equals 4.5cm',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.hcc?.hccLessThan3TumorWithLargestLessThan4AndHalfcm
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'less than equals 3 tumors with total tumor diameter less than equals 8cm',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.hcc?.hccLessThan3TumorDiameterLessThan8cm
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Within UCSF criteria',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.hcc?.hccWithinUcsfCriteria}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Largest tumor size (cm)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.hcc?.hccLargestTumorSize}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Active portal vein tumor thrombus (PVTT) (bland PVT is acceptable)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.hcc?.hccActivePvtt
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Extrahepatic disease',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.hcc?.hccExtrahepaticDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'At least one lesion should be greater than 2 cms',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.hcc?.hccAtleastOneLesionGreaterThan2cm
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'AFP value',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.hcc?.hccAfp}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `AFP Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldExceptionCriteria?.hcc?.hccAfpReports?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `AFP Report Date`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.hcc?.hccAfpReportsDate
                      ? moment(
                          meldExceptionCriteria?.hcc?.hccAfpReportsDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of last Triphasic CT / MRI`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.hcc?.hccDateOfLastTriphasicCt
                      ? moment(
                          meldExceptionCriteria?.hcc?.hccDateOfLastTriphasicCt,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Duration from last Triphasic CT/ MRI',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldExceptionCriteria?.hcc?.hccDurationFromLastTriphasicCt}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Triphasic CT Scan`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.hcc?.hccTriphasisCtScan?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Date of last bone scan`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.hcc?.hccDateOfLastBoneScan
                      ? moment(
                          meldExceptionCriteria?.hcc?.hccDateOfLastBoneScan,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Duration from last bone scan',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldExceptionCriteria?.hcc?.hccDurationFromlastBoneScan}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Bone Scan`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldExceptionCriteria?.hcc?.hccBoneScan?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Date of last HRCT chest`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.hcc?.hccDateOfLastHrctChest
                      ? moment(
                          meldExceptionCriteria?.hcc?.hccDateOfLastHrctChest,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Duration from last  HRCT chest',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldExceptionCriteria?.hcc?.hccDurationFromLastHrctChest}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HRCT Chest Scan`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldExceptionCriteria?.hcc?.hccHrctChestScan?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Date of last PET CT `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.hcc?.hccDateOfLastPetCt
                      ? moment(
                          meldExceptionCriteria?.hcc?.hccDateOfLastPetCt,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Duration from last PET CT',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldExceptionCriteria?.hcc?.hccDurationFromLastPetCt}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `PET CT Scan`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldExceptionCriteria?.hcc?.hccPetCtScan?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Eligible for HCC exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldExceptionCriteria?.hcc?.eligibleForHccExceptionPoints}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'HCC exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${meldExceptionCriteria?.hcc?.hccExceptionPoints}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Treatment Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: 'TACE',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.tace)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'TARE',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.tare)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have done any treatment for HCC?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.rfa)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Resection',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.resection)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'MWA',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.mwa)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Other',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${routineInfo?.hccTreatment?.other}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${routineInfo?.hccTreatment?.attachment?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have Primary Sclerosing Cholangitis (PSC)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(meldExceptionCriteria?.psc?.pscYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.psc?.pscYesNoAttachment?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Proven on MRCP',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.psc?.pscProvenOnMrcp
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Recurrent cholangitis (>= 4 episodes in a year)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.psc?.pscRecurrentCholangitisLessThan4Episodes
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Refractory cholangitis',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.psc?.pscRefactoryCholangitis
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Severe Intractable itching after therapy',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.psc?.pscSevereIntractableItchingAfterTherapy
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Eligible for PSC exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.psc?.eligibleForPscExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'PSC exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.psc?.pscExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Does the patient have Primary Biliary Cholangitis (PBC)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(meldExceptionCriteria?.pbc?.pbcYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.pbc?.pbcYesNoAttachment?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'AMA positive ± Biopsy proven',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.pbc?.pbcAmaPositive
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Severe intractable pruritus after treatment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.pbc?.pbcSevereIntractablePruritusAfterTreatment
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Severe metabolic bone disease (non-traumatic fractures)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.pbc?.pbcSevereMetabolicBoneDiseaseNonTraumaticFractures
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Severe metabolic bone disease (Z score less than -4 on DEXA scan)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.pbc?.pbcSevereMetabolicBoneDiseaseZScore
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Eligible for PBC exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.pbc?.eligibleForPbcExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'PBC exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.pbc?.pbcExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Does the post transplant patient require Retransplant?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.postTransplantPatientRequiringRetransplant
                      ?.postTransplantPatientRequiringRetransplantYesNo
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.postTransplantPatientRequiringRetransplant
                      ?.postTransplantPatientRequiringRetransplantYesNoAttachment?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Eligible for super-urgent listing(rejection/late HAT/ITBL)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.postTransplantPatientRequiringRetransplant
                      ?.postTransplantPatientRequiringRetransplantEligibleForSuperUrgentListing
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Eligible for Post Transplant Patient Requiring Retransplant exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.postTransplantPatientRequiringRetransplant?.eligibleForPostTransplantPatientRequiringRetransplantExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Post Transplant Patient Requiring Retransplant exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.postTransplantPatientRequiringRetransplant?.postTransplantPatientRequiringRetransplantExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Does the patient have Metabolic Liver Diseases?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(meldExceptionCriteria?.mld?.mldYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.mld?.mldYesNoAttachment?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Familial amyloid polyneuropathy',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.mld?.mldFamilialAmyloidPolyneurpathy
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Maple syrup urine disease',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.mld?.mldMapleSyrupUrineDisease
                  )}`,
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Eligible for Metabolic liver diseases (Native liver offered for Domino liver transplant) exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.mld?.eligibleForMldExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Metabolic liver diseases (Native liver offered for Domino liver transplant) exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.mld?.mldExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Does the patient have Metabolic Liver Diseases (other than Wilsons)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.mldOtw?.mldOtwYesNo
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.mldOtw?.mldOtwYesNoAttachment?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Familial hypercholesterolemia',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.mldOtw?.mldOtwFamilialHypercholesterolemia
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Sickle cell hepatopathy',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.mldOtw?.mldOtwSickleCellHepatopathy
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Primary hyperoxaluria',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.mldOtw?.mldOtwPrimaryHyperoxaluria
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Polycystic liver disease',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.mldOtw?.mldOtwPolycysticLiverDisease
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Eligible for Metabolic liver diseases (other than Wilsons) exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.mldOtw?.eligibleForMldOtwExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Metabolic liver diseases (other than Wilsons) exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.mldOtw?.mldOtwExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Does the patient have Non Hepatocellular Carcinoma (Non-HCC)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.nonHcc?.nonHccYesNo
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    meldExceptionCriteria?.nonHcc?.nonHccYesNoAttachment?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Hepatic epithelioid hemangioendothelioma',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.nonHcc?.nonHccHepaticEpithelioid
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Bilobar NET (Neuro Endocrine Tumor) liver metastases: at least 6 months after resolution of primary tumor',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.nonHcc?.nonHccBilobarNetSixMonths
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Bilobar NET (Neuro Endocrine Tumor) liver metastases:confirmed metastasis restricted to liver by whole body special scans',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    meldExceptionCriteria?.nonHcc?.nonHccBilobarNetSpecialScans
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Eligible for Non HCC exception points ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.nonHcc?.eligibleForNonHccExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Non HCC exception points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${meldExceptionCriteria?.nonHcc?.nonHccExceptionPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Eligible for Meld Exception Criteria Points',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPointsEligible}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Meld Exception Criteria points (D)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPoints}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'MLAS Score',
                  style: 'tableHeader',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'MLAS (A)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${meldPeld?.meld ? meldPeld?.meld : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Complication Score (B)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${
                    complicationScore?.complicationScoreResult?.complicationPoints
                      ? complicationScore?.complicationScoreResult?.complicationPoints
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Waiting Time Score (C) ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${'0'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'MELD Exception Score (D)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${
                    meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPoints
                      ? meldExceptionCriteria?.meldExceptionCriteriaResult
                          ?.meldExceptionCriteriaPoints
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Listing MLAS Score',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_getMlasScore()}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Updated MLAS Score',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${'NA'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Current MLAS Score',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },
                {
                  text: `${_getMlasScore()}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
            ],
          },
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*'],
            body: [
              [
                { text: 'Gastroenterologist Team', style: 'tableHeader', alignment: 'left' },

                { text: 'Hepatologist Team', style: 'tableHeader', alignment: 'left' },

                { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
              ],
              [
                (liverFormDetails?.gastroenterologistTeam || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
                (liverFormDetails?.hepatologistTeam || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
                (liverFormDetails?.transplantSurgeon || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
              ],
            ],
          },
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150],
            heights: [0, 50, 50, 50, 50, 50],
            body: [
              [
                {
                  text: 'All verification of the clinical details / reports / criteria eligibility to justify above criteria is the responsibility of the transplant team signing this form. \n1. All the reports (Lab reports + imaging report) should be within 1 month at the time of registration. The patients with older reports should not be considered for registration.\n2. All lab reports should be within 1 month and imaging should be within 3 months for allocation, patients with older reports should not be considered for allocation.',
                  style: 'tableHeader',
                  colSpan: 3,
                  // alignment: 'center',
                  // bold: true,
                  fontSize: 11,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Patient Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Surgeon Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Physician Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Transplant Coordinator Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Signature of Hospital Head:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Routine Liver Listing Form`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default LiverRoutineFormPdf;
