import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle } from '@material-ui/core';

export default function StyledDialogTitle({ children }) {
  return (
    <DialogTitle style={{ backgroundColor: '#4EA95E', color: '#F0FEF9' }}>
      {children || 'Dialog'}
    </DialogTitle>
  );
}
StyledDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
};
