import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  gridDetails: {
    paddingLeft: 20,
    paddingTop: 0,
    paddingRight: 20,
    paddingBottom: 20,
  },
  tcDetailsHeading: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#34495E',
    marginTop: '-45px',
    marginBottom: '10px',
    marginLeft: '-8px',
  },
  paperTransplantInfo: {
    background: '#F0FEF9',
    border: '1px solid #B1E7D3',
    borderRadius: '4px',
    marginTop: 20,
    marginBottom: 10,
  },
}));

export default useStyles;
