export const DISTRICTS_CG = [
  {
    label: 'Balod',
    value: 'BALOD'
  },
  {
    label: 'Baloda Bazar',
    value: 'BALODA_BAZAR'
  },
  {
    label: 'Balrampur',
    value: 'BALRAMPUR'
  },
  {
    label: 'Bastar',
    value: 'BASTAR'
  },
  {
    label: 'Bemetara',
    value: 'BEMETARA'
  },
  {
    label: 'Bijapur',
    value: 'BHIJAPUR'
  },
  {
    label: 'Bilaspur',
    value: 'BILASPUR'
  },
  {
    label: 'Dantewada (South Bastar)',
    value: 'DANTEWADA'
  },
  {
    label: 'Dhamtari',
    value: 'DHAMTARI'
  },
  {
    label: 'Durg',
    value: 'DURG'
  },
  {
    label: 'Gariyaband',
    value: 'GARIYABAND'
  },
  {
    label: 'Janjgir-Champa',
    value: 'JANJGIR_CHAMPA'
  },
  {
    label: 'Jashpur',
    value: 'JASHPUR'
  },
  {
    label: 'Kabirdham (Kawardha)',
    value: 'KABIRDHAM'
  },
  {
    label: 'Kanker (North Bastar)',
    value: 'KANKER'
  },
  {
    label: 'Kondagaon',
    value: 'KONDAGOAN'
  },
  {
    label: 'Korba',
    value: 'KORBA'
  },
  {
    label: 'Korea (Koriya)',
    value: 'KOREA'
  },
  {
    label: 'Mahasamund',
    value: 'MAHASAMUND'
  },
  {
    label: 'Mungeli',
    value: 'MUNGELI'
  },
  {
    label: 'Narayanpur',
    value: 'NARAYANPUR'
  },
  {
    label: 'Raigarh',
    value: 'RAIGARH'
  },
  {
    label: 'Raipur',
    value: 'RAIPUR'
  },
  {
    label: 'Rajnandgaon',
    value: 'RAJNANDGOAN'
  },
  {
    label: 'Sukma',
    value: 'SUKMA'
  },
  {
    label: 'Surajpur',
    value: 'SURAJPUR'
  },
  {
    label: 'Surguja',
    value: 'SURGUJA'
  }
];
