import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Form5Pdf {
  constructor(data) {
    const docDefnData = [];

    data.forEach((entry, idx) => {
      docDefnData.push([idx + 1, entry.recipient.nationality, entry.organ.organType]);
    });
    const reportDocDefinition = {
      content: [
        {
          text: 'Foreigner Transplanted From deceased donor in India',
          style: 'header'
        },
        {
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
                {
                  style: 'textStyle',
                  text: 'Foreigner Transplanted From deceased donor in India',
                  colSpan: 3
                },
                ...new Array(2).fill({})
              ],
              [
                {
                  style: 'textStyle',
                  text: 'SrNo'
                },
                {
                  style: 'textStyle',
                  text: 'Name of Country of origin'
                },
                {
                  style: 'textStyle',
                  text: 'Organ Transplanted'
                }
              ],
              ...docDefnData
            ]
          }
        }
      ],
      pageOrientation: 'landscape',
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center'
        },
        table: {
          margin: [0, 5, 0, 15]
        },
        textStyle: {
          alignment: 'center'
        }
      }
    };
    this.reportDocDefinition = reportDocDefinition;
  }

  download(defaultFileName = `Foreigner Transplanted From deceased donor in India`) {
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(defaultFileName);
  }
}

export default Form5Pdf;
