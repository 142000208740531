import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  ButtonGroup,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import EditIcon from 'mdi-react/EditIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import detectFileType from 'file-type';
import Joi from 'joi';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import cloneDeep from 'lodash.clonedeep';
import { VALIDATION } from '../../constants';
import { getFileUrl } from '../../storage';
import { withCancellableResolver } from '../../hoc';
import AttachmentViewer from './AttachmentViewer';

export const FILETYPES = {
  // https://stackoverflow.com/a/29672957/2503940
  // https://gist.github.com/Qti3e/6341245314bf3513abb080677cd1c93b
  // xxd $FILE | head
  PDF: {
    label: 'PDF',
    signatures: ['25504446'],
    mimeType: 'application/pdf',
  },
  PNG: {
    label: 'PNG',
    signatures: ['89504e47'],
    mimeType: 'image/png',
  },
  GIF: {
    label: 'GIF',
    signatures: ['47494638'],
    mimeType: 'image/gif',
  },
  JPEG: {
    label: 'JPEG',
    signatures: [
      'ffd8ffdb',
      'ffd8ffee',
      'ffd8ffe0',
      'ffd8ffe1',
      'ffd8ffe2',
      'ffd8ffe3',
      'ffd8ffe8',
    ],
    mimeType: 'image/jpeg',
  },
  XLSX: {
    label: 'XLSX',
    signatures: ['504b0304'],
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
};

const EMPTY_FETCHEDFILE = {
  loadingStatus: 'UNSET',
  fileName: '',
  fileKey: '',
  downloadUrl: '',
  mimeType: '',
  blob: '',
  blobUrl: '',
};
function ValidatedAttachment({
  value,
  name,
  disabled,
  key,
  acceptFileTypes,
  maxSizeMb,
  placeholder,
  onValidation,
  required,
  label,
  validationDisabled,
  showValid,
  onChange,
  resolveWithCancellable,
  ...other
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [fetchedFile, setFetchedFile] = useState({
    ...cloneDeep(EMPTY_FETCHEDFILE),
    fileKey: value?.key || '',
  });
  const [showPreview, setShowPreview] = useState(false);
  const [attachmentId] = useState(Math.random());
  const [fontSize, setFontSize] = useState(16); // Initial font size
  const labelRef = useRef();

  const buttonWidth = 150; // Fixed button width
  const minFontSize = 10; // Minimum font size to ensure readability

  useEffect(() => {
    const adjustFontSize = () => {
      if (labelRef.current) {
        let currentFontSize = fontSize;
        const labelWidth = labelRef.current.scrollWidth; // Measure actual label width

        // If the label width exceeds the button width, reduce the font size
        while (labelWidth > buttonWidth && currentFontSize > minFontSize) {
          currentFontSize -= 1; // Reduce font size gradually
          setFontSize(currentFontSize);
        }
      }
    };

    adjustFontSize();
    if (!value?.key) {
      return;
    }
    setFetchedFile({
      ...fetchedFile,
      fileName: value.name || 'Upload',
      fileKey: value?.key,
    });
  }, [value?.key, label, fontSize]);

  let isDirty = false;
  // eslint-disable-next-line prefer-const
  let { validation } = other;
  let valid = true;
  let messages = [];
  const joiError = null;
  if (validation === true) {
    validation = VALIDATION.ALL;
  } else if (validation === false) {
    validation = VALIDATION.OFF;
  }
  if (
    !validationDisabled &&
    (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && isDirty))
  ) {
    if (validation & VALIDATION.INVALIDATE_EMPTY) {
      const { error: emptyFileErr } = Joi.any()
        .required()
        .validate(value?.key || value?.file);
      if (emptyFileErr) {
        valid = false;
        messages.push('Cannot be empty');
      }
    }

    onValidation(valid, messages, joiError);
  } else {
    valid = true;
    messages = [];
  }

  const _downloadFile = () => {
    resolveWithCancellable(getFileUrl(value?.key))
      .then((fileUrl) => window.open(fileUrl))
      .catch(() => {
        enqueueSnackbar('Please try again', { variant: 'error' });
      });
  };

  const _fetchFile = () => {
    if (!value?.key) {
      return;
    }
    if (fetchedFile.loadingStatus === 'SUCCESS' && fetchedFile.downloadUrl === value?.key) {
      return;
    }
    setFetchedFile({
      ...cloneDeep(EMPTY_FETCHEDFILE),
      loadingStatus: 'LOADING',
    });
    resolveWithCancellable(getFileUrl(value?.key))
      .then((url) => {
        return fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
              const arrayBuffer = event.target.result;
              const downloadedFileType = detectFileType(arrayBuffer);
              const blobUrl = URL.createObjectURL(blob);
              setFetchedFile({
                fileName: value.name || 'Upload',
                loadingStatus: 'SUCCESS',
                downloadUrl: url,
                mimeType: downloadedFileType ? downloadedFileType.mime : '',
                blob,
                blobUrl,
              });
            };
            fileReader.readAsArrayBuffer(blob);
          });
      })
      .catch(() => {
        setFetchedFile({
          ...fetchedFile,
          loadingStatus: 'ERROR',
        });
      });
  };

  const _getInput = () => {
    return (
      <input
        style={{ display: 'none' }}
        id={`attachment-${attachmentId}`}
        multiple
        type="file"
        onChange={(...params) => {
          const [e] = params;
          const file = e.target.files[0];
          if (!file) {
            return;
          }
          if (maxSizeMb) {
            const fileSize = file.size / 1024 / 1024; // in MiB
            if (fileSize > maxSizeMb) {
              enqueueSnackbar(`File size exceeds ${maxSizeMb} MB`, {
                variant: 'error',
              });
              return;
            }
          }

          const reader = new FileReader();
          reader.onload = (ev) => {
            const arr = new Uint8Array(ev.target.result).subarray(0, 4);
            let header = '';
            for (let i = 0; i < arr.length; i += 1) {
              header += arr[i].toString(16);
            }
            if (Array.isArray(acceptFileTypes)) {
              const isNotAcceptableFile = acceptFileTypes.reduce(
                (t, f) => t && !(f?.signatures || []).includes(header),
                true
              );
              if (isNotAcceptableFile) {
                const allowedFileTypes = acceptFileTypes.map((a) => a.label).join(',');
                enqueueSnackbar(`Only ${allowedFileTypes} file types allowed`, {
                  variant: 'error',
                });
                return;
              }
            }
            if (!isDirty) {
              isDirty = true;
            }
            // I have no idea why, but 'e' is
            // changing here, so sending a simulated e
            onChange({
              target: { files: [file] },
            });
          };
          reader.readAsArrayBuffer(file);
        }}
      />
    );
  };

  const _renderAttachmentControls = () => {
    if (!value?.file && !value?.key) {
      /* Not selected, not uploaded */
      return (
        <Grid container item xs={12} direction="column">
          <FormControl required={required} focused={true}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <InputLabel disabled={disabled} error={!!validation && !valid}>
                  <Typography style={{ fontSize: '15px', color: 'black', fontStyle: 'bold' }}>
                    {label || 'Attachment'}
                  </Typography>
                </InputLabel>
                <br />
              </Grid>
              <Grid item sm={12} xs={12}>
                <label
                  htmlFor={`attachment-${attachmentId}`}
                  style={{ margin: 6, padding: 6, marginTop: 20 }}
                >
                  {_getInput()}
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    disabled={disabled}
                    width="150px"
                    height="40px"
                    style={{ padding: 5, margin: 5 }}
                  >
                    Browse
                  </Button>
                </label>
              </Grid>
            </Grid>

            {!!validation && !valid && (
              <FormHelperText disabled={disabled} error={!!validation && !valid}>
                {messages[0]}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        // <Grid container item xs={12} direction="column">
        //   <FormControl focused={true}>
        //     <Grid container spacing={2}>
        //       <Grid item sm={12}>
        //         <InputLabel disabled={disabled} error={!!validation && !valid}>
        //           <Typography
        //             style={{
        //               fontSize: '15px',
        //               color: 'black',
        //               fontStyle: 'bold',
        //               display: 'inline',
        //             }}
        //           >
        //             {label || 'Attachment'}
        //             {required && <span style={{ color: 'red' }}> *</span>}{' '}
        //             {/* Asterisk at the end */}
        //           </Typography>
        //         </InputLabel>
        //         <br />
        //       </Grid>

        //       <Grid item sm={12} xs={12}>
        //         <label htmlFor={`attachment-id`} style={{ margin: 6, padding: 6, marginTop: 20 }}>
        //           <input type="file" id="attachment-id" style={{ display: 'none' }} />
        //           <Button
        //             variant="contained"
        //             color="primary"
        //             component="span"
        //             disabled={disabled}
        //             style={{
        //               width: `${buttonWidth}px`, // Fixed width for button
        //               height: '40px', // Fixed height for button
        //               overflow: 'hidden',
        //               whiteSpace: 'nowrap',
        //               display: 'flex',
        //               justifyContent: 'center', // Center text horizontally
        //               alignItems: 'center', // Center text vertically
        //               fontSize: `${fontSize}px`, // Dynamic font size
        //             }}
        //           >
        //             <span ref={labelRef}>{label}</span> {/* Label inside the button */}
        //           </Button>
        //         </label>
        //       </Grid>
        //     </Grid>

        //     {!!validation && !valid && (
        //       <FormHelperText disabled={disabled} error={!!validation && !valid}>
        //         {messages[0]}
        //       </FormHelperText>
        //     )}
        //   </FormControl>
        // </Grid>
      );
    }
    if (value?.file && (!value?.key || value?.key)) {
      /* Both uploaded and not-uploaded and selected */
      return (
        <TextField
          error={!!validation && !valid}
          helperText={messages[0] ? messages[0] : other.helperText || ''}
          disabled={true}
          required={required}
          variant="outlined"
          fullWidth
          value={value.name ? value.name : ''}
          label={
            <Typography variant="caption" style={{ padding: 5 }}>
              {label || 'Attachment'}
            </Typography>
          }
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <label htmlFor={`attachment-${attachmentId}`} style={{ margin: 0, padding: 0 }}>
                    {_getInput()}
                    <IconButton component="span" aria-label="edit" edge="end" disabled={disabled}>
                      <EditIcon size={16} />
                    </IconButton>
                  </label>
                </InputAdornment>
                {/*
                //TODO Enable this after handling 
                // empty file in all places
                <InputAdornment position="end">
                  <IconButton
                    aria-label="remove"
                    edge="end"
                    disabled={disabled}
                    onClick={() =>
                      onChange({
                        target: { files: [] },
                      })
                    }
                  >
                    <CloseIcon size={16} />
                  </IconButton>
                </InputAdornment> 
                */}
              </>
            ),
          }}
          size="small"
        />
      );
    }
    if (!value?.file && value?.key) {
      /* Not selected, but uploaded */
      return (
        <Grid container item xs={12} direction="column">
          <FormControl required={required} focused={true}>
            <InputLabel disabled={disabled} error={!!validation && !valid}>
              {label || 'Attachment'}
            </InputLabel>
            <br />
            <ButtonGroup style={{ marginBottom: 20 }}>
              <Button
                fullWidth
                component="span"
                variant="contained"
                color="primary"
                disabled={disabled}
              >
                <label
                  htmlFor={`attachment-${attachmentId}`}
                  style={{ margin: 0, padding: 0, marginTop: 10 }}
                >
                  {_getInput()}
                  <EditIcon size={14} /> Change
                </label>
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowPreview(true);
                  _fetchFile();
                }}
                disabled={fetchedFile.loadingStatus === 'LOADING'}
              >
                <EyeIcon size={14} /> View
              </Button>
              <Button
                fullWidth={false}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (fetchedFile.fileUrl) {
                    window.open(fetchedFile.fileUrl);
                  } else {
                    _downloadFile();
                  }
                }}
                disabled={fetchedFile.loadingStatus === 'LOADING'}
              >
                <DownloadIcon />
              </Button>
            </ButtonGroup>
            {!!validation && !valid && (
              <FormHelperText disabled={disabled} error={!!validation && !valid}>
                {messages[0]}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      );
    }

    return null;
  };

  return (
    <>
      <Grid container item xs={12}>
        {_renderAttachmentControls()}
        <AttachmentViewer
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          fetchedFile={fetchedFile}
          fetchFile={_fetchFile}
        />
      </Grid>
    </>
  );
}
ValidatedAttachment.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  key: PropTypes.string,
  acceptFileTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      signatures: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  maxSizeMb: PropTypes.number,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  validationDisabled: PropTypes.bool,
  showValid: PropTypes.bool,
  placeholder: PropTypes.string,
  resolveWithCancellable: PropTypes.func.isRequired,
};
ValidatedAttachment.defaultProps = {
  disabled: false,
  required: false,
  key: undefined,
  acceptFileTypes: undefined,
  maxSizeMb: undefined,
  onChange: () => 'onChange STUB',
  onValidation: () => 'onValidation STUB',
  validationDisabled: false,
  showValid: false,
  placeholder: 'Value',
};

export default withCancellableResolver(ValidatedAttachment);

// https://github.com/adobe/pdf-embed-api-samples/blob/master/More%20Samples/React%20Samples/README.md full window and sized container
