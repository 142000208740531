import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Paper, Box, Button, Typography, Grid } from '@material-ui/core';
import { ORGANS, RECIPIENT_HOSPITAL, REGEXEXP_NOWHITESPACEATENDANDBEGIN, REGEXP_ONLYCHARS } from '../../constants';
import { ValidatedInput, ValidatedSelect } from '../Custom';
import ValidatedHospitalInput from './ValidatedHospitalInput/ValidatedHospitalInput';
import ValidatedOtherSupportiveSpecialists from '../Transplant/RecipientValidatedOtherSupportiveSpecialists';
import OtherHospitalValidatedOtherSupportiveSpecialists from '../Transplant/DonorValidatedOtherSupportiveSpecialists';
import OtherHospitalValidatedSpecialistInput from './DonorValidatedSpecialistInput';
import Joi from 'joi';

function OrganDetails({
  bsdDonor,
  setBsdDonor,
  hospitalId,
  selectedOrgans,
  handleRecipientHospitalChange,
  handleDonorOrganSpecialistChange,
  handleDonorOrganDetailsForOtherStateRecipientHospitalNameChange,
  handleDonorOrganDetailsForOtherStateRetrievingSurgeonNameChange,
  handleDonorOrganDetailsForOtherStateRetrievingSurgeonIMAIdChange,
  handleDonorOrganDetailsForOtherStateRetrievingAnaesthetistNameChange,
  handleDonorOrganDetailsChange,
  _setDonorHeartOtherSupportiveSpecialists,
  _setDonorLeftKidneyOtherSupportiveSpecialists,
  _setDonorRightKidneyOtherSupportiveSpecialists,
  _setDonorLiverLeftLateralSegmentOtherSupportiveSpecialists,
  _setDonorLeftLateralSectionectomyOtherSupportiveSpecialists,
  _setDonorLiverHepatectomyOtherSupportiveSpecialists,
  _setDonorLiverRightExtendedLobeOtherSupportiveSpecialists,
  _setDonorLiverRightExtendedHepatectomyOtherSupportiveSpecialists,
  _setDonorLiverLeftExtendedHepatectomyOtherSupportiveSpecialists,
  _setDonorLiverRightHepatectomyWithoutMHVOtherSupportiveSpecialists,
  _setDonorLiverRightHepatectomyWithMHVOtherSupportiveSpecialists,
  _setDonorLiverRightPosteriorSectionectomyOtherSupportiveSpecialists,
  _setDonorLiverOtherSupportiveSpecialists,
  _setDonorLungsOtherSupportiveSpecialists,
  _setDonorIntestineOtherSupportiveSpecialists,
  _setDonorPancreasOtherSupportiveSpecialists,
  _setDonorHeartLungsOtherSupportiveSpecialists,
  _setDonorHandOtherSupportiveSpecialists,
  handleDonorOrganDetailsForOtherStateRetrievingAnaesthetistIMAIdChange,
  userId,
  userType,
  _isDisabled,
  validation,
  onValidation,
}) {
  const _renderOrganDetailsCard = (organ) => {
    // const { validation, onValidation } = props;
    // let organObjectName;
    let donorOrgan;
    let setOtherSupportiveSpecialistFunction;
    let handleSpecialist;
    switch (organ) {
      case ORGANS.HEART:
        donorOrgan = bsdDonor.donorOrgans?.filter((o) => o?.organType === ORGANS.HEART)[0];
        // organObjectName = 'donorHeart';
        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorHeartOtherSupportiveSpecialists;
        break;

      case 'LEFTKIDNEY':
        donorOrgan = bsdDonor.donorOrgans?.filter((o) => o?.organType === 'LEFTKIDNEY')[0];
        // organObjectName = 'donorLeftKidney';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorLeftKidneyOtherSupportiveSpecialists;
        break;

      case 'RIGHTKIDNEY':
        donorOrgan = bsdDonor.donorOrgans?.filter((o) => o?.organType === 'RIGHTKIDNEY')[0];
        // organObjectName = 'donorRightKidney';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorRightKidneyOtherSupportiveSpecialists;
        break;

      case ORGANS.LIVER:
        donorOrgan = bsdDonor.donorOrgans?.filter((o) => o?.organType === ORGANS.LIVER)[0];
        // organObjectName = 'donorLiver';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorLiverOtherSupportiveSpecialists;
        break;

      case ORGANS.LIVERRIGHTEXTENDEDLOBE:
        donorOrgan = bsdDonor.donorOrgans?.filter(
          (o) => o?.organType === ORGANS.LIVERRIGHTEXTENDEDLOBE
        )[0];
        // organObjectName = 'donorLiverRightExtendedLobe';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction =
          _setDonorLiverRightExtendedLobeOtherSupportiveSpecialists;
        break;

      case ORGANS.LIVERLEFTLATERALSEGMENT:
        donorOrgan = bsdDonor.donorOrgans?.filter(
          (o) => o?.organType === ORGANS.LIVERLEFTLATERALSEGMENT
        )[0];
        // organObjectName = 'donorLiverLeftLateralSegment';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction =
          _setDonorLiverLeftLateralSegmentOtherSupportiveSpecialists;
        break;

      case ORGANS.LIVERHEPATECTOMY:
        donorOrgan = bsdDonor.donorOrgans?.filter(
          (o) => o?.organType === ORGANS.LIVERHEPATECTOMY
        )[0];
        // organObjectName = 'donorLiverRightExtendedLobe';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorLiverHepatectomyOtherSupportiveSpecialists;
        break;

      case ORGANS.LEFTLATERALSECTIONECTOMY:
        donorOrgan = bsdDonor.donorOrgans?.filter(
          (o) => o?.organType === ORGANS.LEFTLATERALSECTIONECTOMY
        )[0];
        // organObjectName = 'donorLiverRightExtendedLobe';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction =
          _setDonorLeftLateralSectionectomyOtherSupportiveSpecialists;
        break;

      case ORGANS.LIVERLEFTEXTENDEDHEPATECTOMY:
        donorOrgan = bsdDonor.donorOrgans?.filter(
          (o) => o?.organType === ORGANS.LIVERLEFTEXTENDEDHEPATECTOMY
        )[0];
        // organObjectName = 'donorLiverRightExtendedLobe';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction =
          _setDonorLiverLeftExtendedHepatectomyOtherSupportiveSpecialists;
        break;

      case ORGANS.LIVERRIGHTEXTENDEDHEPATECTOMY:
        donorOrgan = bsdDonor.donorOrgans?.filter(
          (o) => o?.organType === ORGANS.LIVERRIGHTEXTENDEDHEPATECTOMY
        )[0];
        // organObjectName = 'donorLiverRightExtendedLobe';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction =
          _setDonorLiverRightExtendedHepatectomyOtherSupportiveSpecialists;
        break;

      case ORGANS.LIVERRIGHTHEPATECTOMYWITHOUTMHV:
        donorOrgan = bsdDonor.donorOrgans?.filter(
          (o) => o?.organType === ORGANS.LIVERRIGHTHEPATECTOMYWITHOUTMHV
        )[0];
        // organObjectName = 'donorLiverRightExtendedLobe';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction =
          _setDonorLiverRightHepatectomyWithoutMHVOtherSupportiveSpecialists;
        break;

      case ORGANS.LIVERRIGHTHEPATECTOMYWITHMHV:
        donorOrgan = bsdDonor.donorOrgans?.filter(
          (o) => o?.organType === ORGANS.LIVERRIGHTHEPATECTOMYWITHMHV
        )[0];
        // organObjectName = 'donorLiverRightExtendedLobe';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction =
          _setDonorLiverRightHepatectomyWithMHVOtherSupportiveSpecialists;
        break;

      case ORGANS.LIVERRIGHTPOSTERIORSECTIONECTOMY:
        donorOrgan = bsdDonor.donorOrgans?.filter(
          (o) => o?.organType === ORGANS.LIVERRIGHTPOSTERIORSECTIONECTOMY
        )[0];
        // organObjectName = 'donorLiverRightExtendedLobe';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction =
          _setDonorLiverRightPosteriorSectionectomyOtherSupportiveSpecialists;
        break;

      case ORGANS.LUNGS:
        donorOrgan = bsdDonor.donorOrgans?.filter((o) => o?.organType === ORGANS.LUNGS)[0];
        // organObjectName = 'donorLungs';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorLungsOtherSupportiveSpecialists;
        break;

      case ORGANS.PANCREAS:
        donorOrgan = bsdDonor.donorOrgans?.filter((o) => o?.organType === ORGANS.PANCREAS)[0];
        // organObjectName = 'donorPancreas';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorPancreasOtherSupportiveSpecialists;
        break;

      case ORGANS.INTESTINE:
        donorOrgan = bsdDonor.donorOrgans?.filter((o) => o?.organType === ORGANS.INTESTINE)[0];
        // organObjectName = 'donorIntestine';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorIntestineOtherSupportiveSpecialists;
        break;

      case ORGANS.HEARTLUNGS:
        donorOrgan = bsdDonor.donorOrgans?.filter((o) => o?.organType === ORGANS.HEARTLUNGS)[0];
        // organObjectName = 'donorHeartLungs';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorHeartLungsOtherSupportiveSpecialists;
        break;

      case ORGANS.HAND:
        donorOrgan = bsdDonor.donorOrgans?.filter((o) => o?.organType === ORGANS.HAND)[0];
        // organObjectName = 'donorHand';

        handleSpecialist = handleDonorOrganSpecialistChange;
        setOtherSupportiveSpecialistFunction = _setDonorHandOtherSupportiveSpecialists;
        break;

      default:
        return '';
    }
    const _renderSelectedOrgan = (o) => {
      if (o === 'LIVERRIGHTEXTENDEDLOBE') {
        return 'Liver Right Extended Lobe';
      }
      if (o === 'LIVERLEFTLATERALSEGMENT') {
        return 'Liver Left Lateral Segment';
      }
      if (o === 'LEFTLATERALSECTIONECTOMY') {
        return 'Left Lateral Sectionectomy';
      }
      if (o === 'LIVERHEPATECTOMY') {
        return 'Liver Left Hepatectomy';
      }
      if (o === 'LIVERLEFTEXTENDEDHEPATECTOMY') {
        return 'Liver Left Extended Hepatectomy';
      }
      if (o === 'LIVERRIGHTEXTENDEDHEPATECTOMY') {
        return 'Liver Right Extended Hepatectomy';
      }
      if (o === 'LIVERRIGHTHEPATECTOMYWITHOUTMHV') {
        return 'Liver Right Hepatectomy without MHV';
      }
      if (o === 'LIVERRIGHTHEPATECTOMYWITHMHV') {
        return 'Liver Right Hepatectomy with MHV';
      }
      if (o === 'LIVERRIGHTPOSTERIORSECTIONECTOMY') {
        return 'Liver Right Posterior Sectionectomy';
      }
      if (o === 'RIGHTKIDNEY') {
        return 'Right Kidney';
      }
      if (o === 'LEFTKIDNEY') {
        return 'Left Kidney';
      }
      if (o === 'HEARTLUNGS') {
        return 'Heart Lungs';
      }
      if (o) {
        return o;
      }

      return '';
    };
    return (
      <Grid
        container
        spacing={2}
        style={{ border: '1px solid #B1E7D3', padding: 20, marginTop: 10, marginBottom: 10 }}
      >
        <Grid item sm={10}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {_renderSelectedOrgan(organ)}
          </Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography>Recipient Hospital</Typography>
          <ValidatedSelect
            id="recipientHospital"
            name="recipientHospital"
            value={donorOrgan?.recipientHospital}
            options={Object.values(RECIPIENT_HOSPITAL)}
            onChange={(v) => handleRecipientHospitalChange('recipientHospital', v.value, organ)}
            disabled={_isDisabled()}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        {donorOrgan?.recipientHospital === 'OTHER' ? (
          <Grid item sm={3}>
            <Typography>Recipient Hospital Name</Typography>
            <ValidatedHospitalInput
              id="recipientHospitalName"
              name="recipientHospitalName"
              hospitalDetails={donorOrgan?.hospitalDetails}
              hospital={donorOrgan?.hospital}
              formStatus={bsdDonor.formStatus}
              onChange={(hospital) => handleDonorOrganDetailsChange(organ, hospital)}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
        ) : null}
        {donorOrgan?.recipientHospital === 'OUT_OF_MAHARASHTRA' ? (
          <Grid item sm={3}>
            <Typography>Recipient Hospital Name</Typography>
            <ValidatedInput
              id="otherStateRecipientHospitalName"
              name="otherStateRecipientHospitalName"
              value={donorOrgan?.otherStateRecipientHospitalName}
              onChange={(e) =>
                handleDonorOrganDetailsForOtherStateRecipientHospitalNameChange(
                  organ,
                  e.target.value
                )
              }
              placeholder="Other State Recipient Hospital Name"
              disabled={_isDisabled()}
              validation={validation}
              onValidation={onValidation}
              schema={Joi.string()
                .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })
              }
            />
          </Grid>
        ) : null}
        {donorOrgan?.recipientHospital === 'OTHER' ? (
          <Grid item sm={3}>
            <Typography>Retrieving Surgeon</Typography>
            <OtherHospitalValidatedSpecialistInput
              id="donor_retrievingSurgeon"
              name="retrievingSurgeon"
              specialistUser={donorOrgan?.retrievingSurgeonUser}
              specialistType="SURGEON"
              formStatus={bsdDonor.formStatus}
              specialist={donorOrgan?.retrievingSurgeonId}
              onChange={(specialist) => handleSpecialist('SURGEON', specialist, organ)}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
        ) : null}
        {donorOrgan?.recipientHospital === 'INHOUSE' ? (
          <Grid item sm={3}>
            <Typography>Retrieving Surgeon</Typography>
            <OtherHospitalValidatedSpecialistInput
              id="donor_retrievingSurgeon"
              name="retrievingSurgeon"
              specialistUser={donorOrgan?.retrievingSurgeonUser}
              formStatus={bsdDonor.formStatus}
              specialistType="SURGEON"
              specialist={donorOrgan?.retrievingSurgeonId}
              onChange={(specialist) => handleSpecialist('SURGEON', specialist, organ)}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
        ) : null}
        {donorOrgan?.recipientHospital === 'OUT_OF_MAHARASHTRA' ? (
          <>
            <Grid item sm={3}>
              <Typography>Retrieving Surgeon Name</Typography>
              <ValidatedInput
                id="otherStateSurgeonName"
                name="otherStateSurgeonName"
                value={donorOrgan?.otherStateSurgeonName}
                onChange={(e) =>
                  handleDonorOrganDetailsForOtherStateRetrievingSurgeonNameChange(
                    organ,
                    e.target.value
                  )
                }
                placeholder="Retrieving Surgeon Name"
                disabled={_isDisabled()}
                validation={validation}
                onValidation={onValidation}
                schema={Joi.string()
                  .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                  .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })
                  .pattern(REGEXP_ONLYCHARS)
                  .message({ 'string.pattern.base': 'Accept only alphabet characters' })}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography>Retrieving Surgeon IMA Id</Typography>
              <ValidatedInput
                id="otherStateSurgeonIMAId"
                name="otherStateSurgeonIMAId"
                value={donorOrgan?.otherStateSurgeonIMAId}
                onChange={(e) =>
                  handleDonorOrganDetailsForOtherStateRetrievingSurgeonIMAIdChange(
                    organ,
                    e.target.value
                  )
                }
                placeholder="Retrieving Surgeon IMA ID"
                disabled={_isDisabled()}
              // validation={validation}
              // onValidation={onValidation}
              />
            </Grid>
          </>
        ) : null}
        {donorOrgan?.recipientHospital === 'OTHER' ? (
          <Grid item sm={3}>
            <Typography>Retrieving Anaesthetist</Typography>
            <OtherHospitalValidatedSpecialistInput
              id="donor_retrievingAnaesthetist"
              name="retrievingAnaesthetist"
              specialistUser={donorOrgan?.retrievingAnaesthetistUser}
              specialistType="ANAESTHETIST"
              formStatus={bsdDonor.formStatus}
              specialist={donorOrgan?.retrievingAnaesthetistId}
              onChange={(specialist) => handleSpecialist('ANAESTHETIST', specialist, organ)}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
        ) : null}
        {donorOrgan?.recipientHospital === 'INHOUSE' ? (
          <Grid item sm={3}>
            <Typography>Retrieving Anaesthetist</Typography>
            <OtherHospitalValidatedSpecialistInput
              id="donor_retrievingAnaesthetist"
              name="retrievingAnaesthetist"
              specialistUser={donorOrgan?.retrievingAnaesthetistUser}
              specialistType="ANAESTHETIST"
              formStatus={bsdDonor.formStatus}
              specialist={donorOrgan?.retrievingAnaesthetistId}
              onChange={(specialist) => handleSpecialist('ANAESTHETIST', specialist, organ)}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
        ) : null}
        {donorOrgan?.recipientHospital === 'OUT_OF_MAHARASHTRA' ? (
          <>
            <Grid item sm={3}>
              <Typography>Retrieving Anaesthetist Name</Typography>
              <ValidatedInput
                id="otherStateAnaesthetistName"
                name="otherStateAnaesthetistName"
                value={donorOrgan?.otherStateAnaesthetistName}
                onChange={(e) =>
                  handleDonorOrganDetailsForOtherStateRetrievingAnaesthetistNameChange(
                    organ,
                    e.target.value
                  )
                }
                placeholder="Retrieving Anaesthetist Name"
                disabled={_isDisabled()}
                validation={validation}
                onValidation={onValidation}
                schema={Joi.string()
                  .pattern(REGEXEXP_NOWHITESPACEATENDANDBEGIN)
                  .message({ 'string.pattern.base': 'Cannot contain spaces at start and end' })
                  .pattern(REGEXP_ONLYCHARS)
                  .message({ 'string.pattern.base': 'Accept only alphabet characters' })}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography>Retrieving Anaesthetist IMA Id</Typography>
              <ValidatedInput
                id="otherStateAnaesthetistIMAId"
                name="otherStateAnaesthetistIMAId"
                value={donorOrgan?.otherStateAnaesthetistIMAId}
                onChange={(e) =>
                  handleDonorOrganDetailsForOtherStateRetrievingAnaesthetistIMAIdChange(
                    organ,
                    e.target.value
                  )
                }
                placeholder="Retrieving Anaesthetist IMA ID"
                disabled={_isDisabled()}
              // validation={validation}
              // onValidation={onValidation}
              />
            </Grid>
          </>
        ) : null}
        {donorOrgan?.recipientHospital === 'OTHER' ? (
          <Grid item sm={12}>
            <OtherHospitalValidatedOtherSupportiveSpecialists
              currentSelectedOtherSupportiveSpecialists={
                donorOrgan?.otherSupportiveSpecialists || []
              }
              allSelectedOtherSupportiveSpecialists={donorOrgan?.otherSupportiveSpecialists || []}
              userType={userType}
              organ={organ.organType}
              setOtherSupportiveSpecialists={setOtherSupportiveSpecialistFunction}
              disabled={_isDisabled()}
              editMode={false}
              userId={userId}
            // validation={validation}
            // onValidation={onValidation}
            />
          </Grid>
        ) : (
          <Grid item sm={12}>
            <ValidatedOtherSupportiveSpecialists
              currentSelectedOtherSupportiveSpecialists={
                donorOrgan?.otherSupportiveSpecialists || []
              }
              allSelectedOtherSupportiveSpecialists={donorOrgan?.otherSupportiveSpecialists || []}
              userType={userType}
              organ={organ.organType}
              setOtherSupportiveSpecialists={setOtherSupportiveSpecialistFunction}
              disabled={_isDisabled()}
              editMode={false}
              userId={userId}
            // validation={validation}
            // onValidation={onValidation}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Grid item sm={12} style={{ padding: 20 }}>
      {selectedOrgans.map((element) => _renderOrganDetailsCard(element))}
    </Grid>
  );
}

export default OrganDetails;
