import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import moment from 'moment';
import { Row, Col, CardBody, FormGroup, Label } from 'reactstrap';
import {
  ValidatedInput,
  ValidatedSelect,
  ValidatedDate,
  ValidatedAttachment,
  // ValidatedCheckButton
} from '../../../Custom';
import ValidatedHospitalInput from './ValidatedHospitalInput';
import { SEX, FORMAT_DATE_UNIXTS, isEyeBank } from '../../../../constants';
import { OPTIONS_NATIONALITY } from '../../../../nationalities';
import { STATE_IN } from '../../../../statesIndia';
import {
  OPTS_DONOR_MEDICOLEGAL,
  OPTS_DONOR_MOTIVATEDBY,
  OPTS_DONOR_REMOVALPLACE,
  OPTS_DONOR_TISSUETYPE,
  OPTS_DONOR_TYPEOFPRESERVATION,
  OPTS_DONOR_TISSUENUMBER,
  OPTIONS_EYEDONORSTATUS,
  BLOODCOLLECTED,
} from '../../../../appConstants/eyebank';
import { handleDonorUpdate, handleDonorDocument, handleEyeBankChange } from './eyeDonorUiScripts';
import { DISTRICTS_MH } from '../../../../districts/districtsMh';
import { FILETYPES } from '../../../../components/Custom/ValidatedAttachment';

class EyeDonorUi extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      disabled,
      validation,
      onValidation,
      eyeDonor,
      component,
      hospitalTypes,
      hospitalName,
      hospitalId,
    } = this.props;
    const hospitalDetails = {
      hospitalId,
      name: hospitalName,
    };
    return (
      <CardBody>
        <Row className="my-2">
          <Col sm="6">
            <span style={{ fontSize: 12 }}>
              <strong>
                Note:
                <br /> 1. All{' '}
                <Label style={{ color: 'red' }}>
                  <strong>( * )</strong>
                </Label>{' '}
                Fields Are Compulsory
                <br />
                2. Photos must be in JPEG/JPG/PNG Format and below 256 kbs
              </strong>
            </span>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="firstName">First Name</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={eyeDonor.firstName}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="First Name"
                  schema={Joi.string()}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="lastName">Last Name</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={eyeDonor.lastName}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Last Name"
                  schema={Joi.string()}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="fatherName">Father&apos;s Name</Label>
                {/* <span style={{ color: 'red' }}>*</span> */}
              </Col>
              <Col>
                <ValidatedInput
                  type="text"
                  id="fatherName"
                  name="fatherName"
                  value={eyeDonor.fatherName}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Father's Name"
                  schema={Joi.string()}
                // validation={validation}
                // onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="gender">Gender</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="sex"
                  name="sex"
                  options={Object.values(SEX)}
                  value={eyeDonor.sex}
                  onChange={(v) => handleDonorUpdate('sex', v.value, component)}
                  disabled={disabled}
                  placeholder="Sex"
                  autosize
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="age">Age</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  type="number"
                  id="age"
                  name="age"
                  min={0}
                  max={120}
                  value={eyeDonor.age}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Age"
                  schema={Joi.number().min(0)}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="nationality">Nationality</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="nationality"
                  name="nationality"
                  options={Object.values(OPTIONS_NATIONALITY)}
                  value={eyeDonor.nationality}
                  onChange={(v) => handleDonorUpdate('nationality', v.value, component)}
                  disabled={disabled}
                  placeholder="Nationality"
                  autosize
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="address">Contact Address</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  type="textarea"
                  rows="3"
                  id="address"
                  name="address"
                  value={eyeDonor.address}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Contact Address"
                  schema={Joi.string()}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="state">State</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="state"
                  name="state"
                  options={Object.values(STATE_IN)}
                  value={eyeDonor.state}
                  onChange={(v) => handleDonorUpdate('state', v.value, component)}
                  disabled={disabled}
                  placeholder="State"
                  autosize
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="district">District</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="district"
                  name="district"
                  options={Object.values(DISTRICTS_MH)}
                  value={eyeDonor.district}
                  onChange={(v) => handleDonorUpdate('district', v.value, component)}
                  disabled={disabled}
                  placeholder="District"
                  autosize
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="pincode">Pincode</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  id="pincode"
                  name="pinCode"
                  value={eyeDonor.pinCode}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Pincode"
                  schema={Joi.string()}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <hr className="my-4" />
        {isEyeBank(hospitalTypes) ? (
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="keratoplastyCenter">Eye Bank</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedHospitalInput
                  id="Hospital"
                  name="eyeBank"
                  hospitalDetails={hospitalDetails}
                  hospital={eyeDonor.hospital}
                  onChange={(hospital) =>
                    handleEyeBankChange('hospitalDetails', hospital, 'eyeBank', component)
                  }
                  disabled={true}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
        ) : (
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="keratoplastyCenter">Eye Bank</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedHospitalInput
                  id="Hospital"
                  name="eyeBank"
                  hospitalDetails={eyeDonor.hospitalDetails}
                  hospital={eyeDonor.hospital}
                  onChange={(hospital) =>
                    handleEyeBankChange('hospitalDetails', hospital, 'eyeBank', component)
                  }
                  disabled={disabled}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
        )}
        <hr className="my-4" />
        <Row>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="deathCertifyingPhysician">Physician Certifying death</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  id="deathCertifyingPhysician"
                  name="deathCertifyingPhysician"
                  value={eyeDonor.deathCertifyingPhysician}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Physician"
                  schema={Joi.string()}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="drRegnNo">Dr. Registration number</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  id="drRegnNo"
                  name="drRegnNo"
                  value={eyeDonor.drRegnNo}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Registration Number"
                  schema={Joi.string()}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="deathCertificatNo">Death Certificate Number</Label>
              </Col>
              <Col>
                <ValidatedInput
                  id="deathCertificatNo"
                  name="deathCertificatNo"
                  value={eyeDonor.deathCertificatNo}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Certificate Number"
                  schema={Joi.string()}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="medicoLegal">Medico Legal</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="medicoLegal"
                  name="medicoLegal"
                  options={Object.values(OPTS_DONOR_MEDICOLEGAL)}
                  value={eyeDonor.medicoLegal}
                  onChange={(v) => handleDonorUpdate('medicoLegal', v.value, component)}
                  disabled={disabled}
                  placeholder="Medico Legal"
                  autosize
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="harvestingPerson">Harvested by</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  id="harvestingPerson"
                  name="harvestingPerson"
                  value={eyeDonor.harvestingPerson}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Harvested by"
                  schema={Joi.string()}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="motivatedBy">Motivated By</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="motivatedBy"
                  name="motivatedBy"
                  options={Object.values(OPTS_DONOR_MOTIVATEDBY)}
                  value={eyeDonor.motivatedBy}
                  onChange={(v) => handleDonorUpdate('motivatedBy', v.value, component)}
                  disabled={disabled}
                  placeholder="Motivated By"
                  autosize
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="removalPlace">Place of Removal</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="removalPlace"
                  name="removalPlace"
                  options={Object.values(OPTS_DONOR_REMOVALPLACE)}
                  value={eyeDonor.removalPlace}
                  onChange={(v) => handleDonorUpdate('removalPlace', v.value, component)}
                  disabled={disabled}
                  placeholder="Place of Removal"
                  autosize
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="causeOfDeath">Cause of Death</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  type="textarea"
                  rows="3"
                  id="causeOfDeath"
                  name="causeOfDeath"
                  value={eyeDonor.causeOfDeath}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Cause of Death"
                  schema={Joi.string()}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="caseHistory">Medical/Case History</Label>
              </Col>
              <Col>
                <ValidatedInput
                  type="textarea"
                  rows="3"
                  id="caseHistory"
                  name="caseHistory"
                  value={eyeDonor.caseHistory}
                  disabled={disabled}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Medical/Case History"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="tissueType">Type of Tissue</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="tissueType"
                  name="tissueType"
                  options={Object.values(OPTS_DONOR_TISSUETYPE)}
                  value={eyeDonor.tissueType}
                  onChange={(v) => handleDonorUpdate('tissueType', v.value, component)}
                  disabled={disabled}
                  placeholder="Type of Tissue"
                  autosize
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="tissueNumber">Tissue Number</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="tissueNumber"
                  name="tissueNumber"
                  options={Object.values(OPTS_DONOR_TISSUENUMBER)}
                  value={eyeDonor.tissueNumber}
                  onChange={(v) => handleDonorUpdate('tissueNumber', v.value, component)}
                  disabled={disabled || eyeDonor.formStatus === OPTIONS_EYEDONORSTATUS.SUBMITTED}
                  placeholder="Tissue Number"
                  autosize
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="preservationType">Type of Preservation</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="preservationType"
                  name="preservationType"
                  options={Object.values(OPTS_DONOR_TYPEOFPRESERVATION)}
                  value={eyeDonor.preservationType}
                  onChange={(v) => handleDonorUpdate('preservationType', v.value, component)}
                  disabled={disabled}
                  placeholder="Type of Preservation"
                  autosize
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="preservationMedia">Preservation Media</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  id="preservationMedia"
                  name="preservationMedia"
                  value={eyeDonor.preservationMedia}
                  disabled={disabled || eyeDonor.preservationType !== 'OTHERS'}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Preservation Media"
                  schema={Joi.string()}
                  validation={validation}
                  onValidation={onValidation}
                  validationDisabled={disabled || eyeDonor.preservationType !== 'OTHERS'}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="lotNoRe">Lot No RE</Label>
              </Col>
              <Col>
                <ValidatedInput
                  id="lotNoRe"
                  name="lotNoRe"
                  value={eyeDonor.lotNoRe}
                  disabled={disabled || eyeDonor.tissueNumber === 'LEFTEYE'}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Lot No RE"
                // schema={Joi.string()}
                // validation={validation}
                // onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="lotNoLe">Lot No LE</Label>
              </Col>
              <Col>
                <ValidatedInput
                  id="lotNoLe"
                  name="lotNoLe"
                  value={eyeDonor.lotNoLe}
                  disabled={disabled || eyeDonor.tissueNumber === 'RIGHTEYE'}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Lot No LE"
                  schema={Joi.string()}
                // validation={validation}
                // onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="deathDateTime">Date and Time of Death</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedDate
                  id="deathDateTime"
                  name="deathDateTime"
                  value={moment(eyeDonor.deathDateTime, FORMAT_DATE_UNIXTS)}
                  onChange={(v) =>
                    handleDonorUpdate(
                      'deathDateTime',
                      parseInt(v.format(FORMAT_DATE_UNIXTS), 10),
                      component
                    )
                  }
                  disabled={disabled}
                  timeFormat="HH:mm"
                  placeholder="Date and Time of Death"
                  schema={Joi.date().max('now')}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="retrievalDateTime">Date and Time of ENUC/Retrieval</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedDate
                  id="retrievalDateTime"
                  name="retrievalDateTime"
                  value={moment(eyeDonor.retrievalDateTime, FORMAT_DATE_UNIXTS)}
                  onChange={(v) =>
                    handleDonorUpdate(
                      'retrievalDateTime',
                      parseInt(v.format(FORMAT_DATE_UNIXTS), 10),
                      component
                    )
                  }
                  disabled={disabled || eyeDonor.tissueType === 'CORNEAWITHSCLERALRIM'}
                  timeFormat="HH:mm"
                  placeholder="Date and Time of Retrieval"
                  schema={Joi.date().max('now')}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="excisionDateTime">Date and Time of In-Situ Excision</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedDate
                  id="excisionDateTime"
                  name="excisionDateTime"
                  value={moment(eyeDonor.excisionDateTime, FORMAT_DATE_UNIXTS)}
                  onChange={(v) =>
                    handleDonorUpdate(
                      'excisionDateTime',
                      parseInt(v.format(FORMAT_DATE_UNIXTS), 10),
                      component
                    )
                  }
                  disabled={disabled || eyeDonor.tissueType === 'WHOLEEYEBALL'}
                  timeFormat="HH:mm"
                  placeholder="Date and Time of In-Situ Excision"
                  schema={Joi.date().max('now')}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="corneaPreservationDateTime">
                  Date and Time of Cornea Preservation
                </Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedDate
                  id="corneaPreservationDateTime"
                  name="corneaPreservationDateTime"
                  value={moment(eyeDonor.corneaPreservationDateTime, FORMAT_DATE_UNIXTS)}
                  onChange={(v) =>
                    handleDonorUpdate(
                      'corneaPreservationDateTime',
                      parseInt(v.format(FORMAT_DATE_UNIXTS), 10),
                      component
                    )
                  }
                  disabled={disabled}
                  timeFormat="HH:mm"
                  placeholder="Date and Time of Cornea Preservation"
                  schema={Joi.date().max('now')}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="deathPreservationTime">Death to Preservation Time</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  id="deathPreservationTime"
                  name="deathPreservationTime"
                  value={eyeDonor.deathPreservationTime}
                  disabled={true}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Death to Preservation Time"
                  schema={Joi.string()}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="preservationMediaDeath">Death to Preservation Media</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedInput
                  id="preservationMediaDeath"
                  name="preservationMediaDeath"
                  value={eyeDonor.preservationMediaDeath}
                  disabled={true}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.target.value, component)}
                  placeholder="Death to Preservation Media"
                  schema={Joi.string()}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="bloodCollected">Blood Collected</Label>
                <span style={{ color: 'red' }}>*</span>
              </Col>
              <Col>
                <ValidatedSelect
                  id="bloodCollected"
                  name="bloodCollected"
                  options={Object.values(BLOODCOLLECTED)}
                  onChange={(e) => handleDonorUpdate(e.target.name, e.value, component)}
                  value={eyeDonor.bloodCollected}
                  disabled={disabled}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup>
              <Col>
                <Label htmlFor="deathCertificate">
                  Death Certificate / Cause of Death Document
                </Label>
              </Col>
              <Col>
                <ValidatedAttachment
                  id="deathCertificate"
                  name="deathCertificate"
                  acceptFileTypes={[FILETYPES.PNG, FILETYPES.PDF]}
                  value={eyeDonor.deathCertificate}
                  disabled={disabled}
                  onChange={(e) => handleDonorDocument(e.target.name, e.target.files[0], component)}
                  placeholder="Death Certificate"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col>
            <Label htmlFor="consent">Donor Consent Document</Label>
            <span style={{ color: 'red' }}>*</span>

            <ValidatedAttachment
              id="consent"
              name="consent"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.PDF]}
              value={eyeDonor.consent}
              disabled={disabled}
              onChange={(e) => handleDonorDocument(e.target.name, e.target.files[0], component)}
              placeholder="Consent"
              validation={validation}
              onValidation={onValidation}
            />
          </Col>
        </Row>
      </CardBody>
    );
  }

  componentDidMount() {
    const { hospitalId, hospitalName, component, hospitalTypes } = this.props;
    const hospital = {
      hospitalId,
      name: hospitalName,
    };
    if (isEyeBank(hospitalTypes)) {
      handleEyeBankChange('hospitalDetails', hospital, 'eyeBank', component);
    }
  }
}
EyeDonorUi.propTypes = {
  component: PropTypes.objectOf(PropTypes.any).isRequired,
  eyeDonor: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
  hospitalId: PropTypes.string.isRequired,
  hospitalTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  hospitalName: PropTypes.string.isRequired,
};

export default EyeDonorUi;
