export const DISTRICTS_AP = [
  {
    label: 'Anantapur',
    value: 'ANANTAPUR'
  },
  {
    label: 'Chittoor',
    value: 'CHITTOOR'
  },
  {
    label: 'East Godavari',
    value: 'EAST_GODAVARI'
  },
  {
    label: 'Guntur',
    value: 'GUNTUR'
  },
  {
    label: 'Krishna',
    value: 'KRISHNA'
  },
  {
    label: 'Kurnool',
    value: 'KURNOOL'
  },
  {
    label: 'Prakasam',
    value: 'PRAKASAM'
  },
  {
    label: 'Srikakulam',
    value: 'SRIKAKULAM'
  },
  {
    label: 'Sri Potti Sriramulu Nellore',
    value: 'SRI_POTTI_SRIRAMULU_NELLORE'
  },
  {
    label: 'Visakhapatnam',
    value: 'VISAKHAPATNAM'
  },
  {
    label: 'Vizianagaram',
    value: 'VIZIANAGARAM'
  },
  {
    label: 'Devbhoomi Dwarka',
    value: 'DEVBHOOMI_DWARKA'
  },
  {
    label: 'West Godavari',
    value: 'WEST_GODAWARI'
  },
  {
    label: 'YSR District, Kadapa (Cuddapah)',
    value: 'KADAPA'
  }
];
