import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { pdfjs } from 'react-pdf';
import detectFileType from 'file-type';
import PropTypes from 'prop-types';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getFileUrl } from '../../storage';
import { withCancellableResolver } from '../../hoc';
import { OPTIONS_SPECIALIST_DOCTYPE } from '../../constants';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DownloadAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { documents } = this.props;
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => {
            const docData = [];
            documents.forEach((doc) => {
              if (doc?.attachment?.key || doc?.key) {
                if (doc?.key) {
                  docData.push({ key: doc.key, name: doc.name });
                } else {
                  const {
                    documentType,
                    attachment: { key },
                  } = doc;
                  let docType;
                  OPTIONS_SPECIALIST_DOCTYPE.map((type) => {
                    if (type.value === documentType) {
                      docType = type.label;
                    }
                  });
                  docData.push({ key, name: docType });
                }
              }
            });
            this._addFileToZip(docData);
          }}
        >
          Download All Attachments
        </Button>
      </>
    );
  }

  _addFileToZip(docData) {
    const { resolveWithCancellable, name } = this.props;

    const zip = new JSZip();
    let count = 0;
    const fileName = `${name}.zip`;

    docData.forEach((data) =>
      resolveWithCancellable(getFileUrl(data.key))
        .then((fileUrl) => {
          fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const fileReader = new FileReader();
              fileReader.onload = (event) => {
                const arrayBuffer = event.target.result;
                const fileType = detectFileType(arrayBuffer);
                zip.file(
                  data.name.includes(`.${fileType.ext}`)
                    ? data.key
                    : `${data.name}.${fileType.ext}`,
                  arrayBuffer,
                  { binary: true }
                );
                count += 1;
                if (count === docData.length) {
                  zip.generateAsync({ type: 'blob' }).then((content) => {
                    saveAs(content, fileName);
                  });
                }
              };
              fileReader.readAsArrayBuffer(blob);
            });
        })
        .catch(() => {})
    );
  }
}
DownloadAttachments.propTypes = {
  resolveWithCancellable: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
};

export default withCancellableResolver(DownloadAttachments);
