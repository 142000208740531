import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useCancellableApiCall } from '../../../hooks';
import { ReactTable, ValidatedInput, ValidatedRadio, ValidatedSelect } from '../../Custom';
import {
  PATH_EXP_GETSPECIALISTS,
  PATH_EXP_GETSPECIALISTBYID,
  PATH_GETHOSPITALS,
  PATH_EXP_GETSPECIALISTBYPARTIALNAME,
} from '../../../appConstants';
import { withValidation } from '../../../newHoc';
import { VALIDATION } from '../../../constants';
import LoadableButton from '../../Custom/LoadableButton';

function TransplantSpecialistPicker({
  specialistsType,
  selectSpecialistUser,
  selectedSpecialist,
  component,
  validation,
  onValidation,
  validate,
}) {
  const [specialists, setSpecialists] = useState([]);
  const [otherHospitalSpecialist, setOtherHospitalSpecialist] = useState([]);
  const [activeTab, setActiveTab] = useState('INHOUSE');
  const [searchText, setSearchText] = useState('');
  const [searchId, setSearchID] = useState('');
  const [hospitals, setHospitals] = useState([]);
  const [specialistHospital, setSpecialistHospital] = useState();
  const [searchBy, setSearchBy] = useState();
  const [notFoundMessage, setNotFoundMessage] = useState('');
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);

  const { apiGet, apiCallStatuses } = useCancellableApiCall();
  const app = useSelector((state) => state);
  const {
    user: { hospitalId, userType },
  } = app;

  const [getSpecialistsApiId, setGetSpecialistsApiId] = useState('APIID');
  useEffect(() => {
    apiGet(
      PATH_EXP_GETSPECIALISTS,
      {
        hospitalId,
        userType,
        trainedPersonType: specialistsType,
      },
      {
        apiIdCb: (apiId) => {
          setGetSpecialistsApiId(apiId);
        },
      }
    )
      .then((splsts) => {
        const filteredSpecialists = splsts.filter((f) => {
          return f?.approval?.status !== 'REJECTED';
        });
        setSpecialists([...filteredSpecialists]);
      })
      .catch(() => {});
    apiGet(PATH_GETHOSPITALS, {}, {})
      .then((hospitals) => {
        setHospitals([...hospitals]);
      })
      .catch(() => {});
  }, []);

  const [searchByIdApiId, setSearchByIdApiId] = useState('APIID');

  const _getSpecialistById = () => {
    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (isValid) => {
      if (isValid) {
        apiGet(
          PATH_EXP_GETSPECIALISTBYID,
          {
            userId: searchId,
            trainedPersonType: specialistsType,
          },
          {
            apiIdCb: (apiId) => {
              setSearchByIdApiId(apiId);
            },
          }
        )
          .then((otherHospSpcl) => {
            if (otherHospSpcl.length > 0) {
              setOtherHospitalSpecialist([...otherHospSpcl]);
            } else {
              setNotFoundMessage(
                `The specialist with ID ${searchId} was not found. Please check the ID you have enetered. You can either search by other Name or ID else contact Specialst Hospital / Ayudaan Team.`
              );
              setShowNotFoundMessage(true);
            }
          })
          .catch(() => {});
      }
    });
  };

  const [searchByNameApiId, setSearchByNameApiId] = useState('APIID');

  const _getSpecialistByPartialName = () => {
    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (isValid) => {
      if (isValid) {
        apiGet(
          PATH_EXP_GETSPECIALISTBYPARTIALNAME,
          {
            searchText,
            trainedPersonType: specialistsType,
            specialistHospital,
          },
          {
            apiIdCb: (apiId) => {
              setSearchByNameApiId(apiId);
            },
          }
        )
          .then((otherHospSpcl) => {
            if (otherHospSpcl.length > 0) {
              setOtherHospitalSpecialist([...otherHospSpcl]);
            } else {
              setNotFoundMessage(
                `The specialist with name ${searchText} was not found. Please check the name you have enetered. You can either search by other Name or ID else contact Ayudaan Team.`
              );
              setShowNotFoundMessage(true);
            }
          })
          .catch(() => {});
      }
    });
  };

  const _selectSpecialistFormatter = (specialist) => {
    const { userId } = specialist;
    return selectedSpecialist && selectedSpecialist === userId ? (
      <Button
        onClick={() => {
          component.setState({ selectedSpecialist: '' });
          selectSpecialistUser({});
        }}
        variant="contained"
        color="secondary"
      >
        Remove
      </Button>
    ) : (
      <Button
        onClick={() => {
          component.setState({ selectedSpecialist: specialist.userId });
          selectSpecialistUser(specialist);
        }}
        variant="contained"
        color="primary"
      >
        Select
      </Button>
    );
  };

  const columns = [
    {
      Header: 'Name',
      key: 'name',
      accessor: 'name',
    },
    {
      Header: 'Specialist Id',
      key: 'userId',
      accessor: 'userId',
    },
    {
      Header: 'Specialisation',
      key: 'Specialisation',
      accessor: 'specialisation',
      disableFilters: true,
      Cell: ({ value }) => {
        const specialisationJoin = (value || []).join(' , ');
        return <Typography>{specialisationJoin}</Typography>;
      },
    },
    {
      Header: 'Select',
      key: 'select',
      accessor: 'select',
      disableFilters: true,
      Cell: ({ row }) => _selectSpecialistFormatter(row.original),
    },
  ];

  const _getHospitalNames = () => {
    const hospitalNames = [];
    hospitals.forEach((h) => {
      hospitalNames.push({ label: h.name, value: h.hospitalId });
    });
    return hospitalNames;
  };

  return (
    <Grid container sm={12} spacing={1}>
      <Grid item sm={6}>
        <Button
          variant="outlined"
          fullWidth
          style={{
            marginLeft: 10,
            marginRight: 10,
            border: '1px solid #25B67D',
            borderBottom: 'none',
            borderRadius: '2px',
            fontSize: '16px',
            height: '52px',
            backgroundColor: activeTab === 'INHOUSE' ? '#25B67D' : '',
            color: activeTab === 'INHOUSE' ? '#F0FEF9' : '',
          }}
          onClick={() => {
            setActiveTab('INHOUSE');
            setSearchBy('');
            setShowNotFoundMessage(false);
            setNotFoundMessage('');
          }}
        >
          Inhouse Specialist
        </Button>
      </Grid>
      <Grid item sm={6}>
        <Button
          variant="outlined"
          fullWidth
          style={{
            marginLeft: 10,
            marginRight: 10,
            border: '1px solid #25B67D',
            borderBottom: 'none',
            borderRadius: '2px',
            fontSize: '16px',
            height: '52px',
            backgroundColor: activeTab === 'OTHERHOSPITAL' ? '#25B67D' : '',
            color: activeTab === 'OTHERHOSPITAL' ? '#F0FEF9' : '',
          }}
          onClick={() => {
            setActiveTab('OTHERHOSPITAL');
            setSearchBy('');
            setShowNotFoundMessage(false);
            setNotFoundMessage('');
          }}
        >
          Other Hospital Specialist
        </Button>
      </Grid>
      {activeTab === 'OTHERHOSPITAL' ? (
        <Grid container sm={12} style={{ margin: 10 }}>
          <Grid item sm={12} xs={12}>
            <ValidatedRadio
              label="Search by Specialist Name or Specialist ID?"
              value={searchBy}
              onChange={(e) => {
                setSearchBy(e.target.value);
                setSearchID('');
                setSearchText('');
                setShowNotFoundMessage(false);
                setNotFoundMessage('');
                setSpecialistHospital('');
              }}
              options={[
                { value: 'NAME', label: 'Specialist Name' },
                { value: 'ID', label: 'Specialist ID' },
              ]}
            />
          </Grid>
          {searchBy === 'NAME' ? (
            <Grid container sm={12} spacing={1} justifyContent="space-between">
              <Grid item sm={4} style={{ marginTop: 5, width: '100%' }}>
                <ValidatedSelect
                  label="Specialist Hospital"
                  value={specialistHospital}
                  options={_getHospitalNames()}
                  onChange={(e) => setSpecialistHospital(e.value)}
                  validation={validation}
                  onValidation={onValidation}
                />{' '}
              </Grid>
              <Grid item sm={4} style={{ marginTop: 5 }}>
                <ValidatedInput
                  required
                  id="specialistName"
                  name="specialistName"
                  label="Specialist Name"
                  fullWidth
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Grid>
              <Grid item sm={4} style={{ marginTop: 5 }}>
                <LoadableButton
                  apiCallId={searchByNameApiId}
                  apiCallStatuses={apiCallStatuses}
                  loadingText="Searching..."
                  variant="contained"
                  color="primary"
                  style={{
                    fontWeight: 600,
                    height: 38,
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                  onClick={() => {
                    setShowNotFoundMessage(false);
                    setNotFoundMessage('');
                    setOtherHospitalSpecialist([]);
                    _getSpecialistByPartialName();
                  }}
                >
                  Search
                </LoadableButton>
                {/*
                 */}
              </Grid>
            </Grid>
          ) : null}

          {searchBy === 'ID' ? (
            <Grid container sm={12}>
              <Grid item sm={4}>
                <ValidatedInput
                  required
                  id="specialistId"
                  name="specialistId"
                  label="Specialist ID"
                  value={searchId}
                  onChange={(e) => {
                    setSearchID(e.target.value);
                  }}
                  validation={validation}
                  onValidation={onValidation}
                  fullWidth
                />

                {/* <Button
                variant="contained"
                color="primary"
                style={{
                  fontWeight: 600,
                  width: '64px',
                  height: 39,
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
                onClick={() => {
                  setShowNotFoundMessage(false);
                  setNotFoundMessage('');
                  setOtherHospitalSpecialist([]);
                  _getSpecialistById();
                }}
              >
                Search
              </Button> */}
              </Grid>
              <Grid item sm={4}>
                <LoadableButton
                  apiCallId={searchByIdApiId}
                  apiCallStatuses={apiCallStatuses}
                  loadingText="Searching..."
                  variant="contained"
                  color="primary"
                  style={{
                    fontWeight: 600,
                    height: 38,
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                  onClick={() => {
                    setShowNotFoundMessage(false);
                    setNotFoundMessage('');
                    setOtherHospitalSpecialist([]);
                    _getSpecialistById();
                  }}
                >
                  Search
                </LoadableButton>
              </Grid>
            </Grid>
          ) : null}
          {showNotFoundMessage ? (
            <Grid item sm={12}>
              <Typography color="secondary">{notFoundMessage}</Typography>
            </Grid>
          ) : null}
        </Grid>
      ) : null}

      <Grid sm={12}>
        <ReactTable
          apiCallId={getSpecialistsApiId}
          apiCallStatuses={apiCallStatuses}
          columns={columns}
          data={activeTab === 'INHOUSE' ? specialists : otherHospitalSpecialist}
        />
      </Grid>
    </Grid>
  );
}

export default withValidation(TransplantSpecialistPicker);
