export const DISTRICTS_RJ = [
  {
    label: 'Ajmer',
    value: 'AJMER'
  },
  {
    label: 'Alwar',
    value: 'ALWAR'
  },
  {
    label: 'Banswara',
    value: 'BANSWARA'
  },
  {
    label: 'Baran',
    value: 'BARAN'
  },
  {
    label: 'Barmer',
    value: 'BARMER'
  },
  {
    label: 'Bharatpur',
    value: 'BHARATPUR'
  },
  {
    label: 'Bhilwara',
    value: 'BHILWARA'
  },
  {
    label: 'Bikaner',
    value: 'BIKANER'
  },
  {
    label: 'Bundi',
    value: 'BUNDI'
  },
  {
    label: 'Chittorgarh',
    value: 'CHITTORGARH'
  },
  {
    label: 'Churu',
    value: 'CHURU'
  },
  {
    label: 'Dausa',
    value: 'DAUSA'
  },
  {
    label: 'Dholpur',
    value: 'DHOLPUR'
  },
  {
    label: 'Dungarpur',
    value: 'DUNGARPUR'
  },
  {
    label: 'Hanumangarh',
    value: 'HANUMANGARH'
  },
  {
    label: 'Jaipur',
    value: 'JAIPUR'
  },
  {
    label: 'Jaisalmer',
    value: 'JAISALMER'
  },
  {
    label: 'Jalore',
    value: 'JALORE'
  },
  {
    label: 'Jhalawar',
    value: 'JHALAWAR'
  },
  {
    label: 'Jhunjhunu',
    value: 'JHUNJHUNU'
  },
  {
    label: 'Jodhpur',
    value: 'JODHPUR'
  },
  {
    label: 'Karauli',
    value: 'KARAULI'
  },
  {
    label: 'Kota',
    value: 'KOTA'
  },
  {
    label: 'Nagaur',
    value: 'NAGAUR'
  },
  {
    label: 'Pali',
    value: 'PALI'
  },
  {
    label: 'Pratapgarh',
    value: 'PRATAPGARH'
  },
  {
    label: 'Rajsamand',
    value: 'RAJSAMAND'
  },
  {
    label: 'Sawai Madhopur',
    value: 'SAWAI_MADHOPUR'
  },
  {
    label: 'Sikar',
    value: 'SIKAR'
  },
  {
    label: 'Sirohi',
    value: 'SIROHI'
  },
  {
    label: 'Sri Ganganagar',
    value: 'SRI_GANGANAGAR'
  },
  {
    label: 'Tonk',
    value: 'TONK'
  },
  {
    label: 'Udaipur',
    value: 'UDAIPUR'
  }
];
