import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { FORMAT_DATE_UNIXTS, BLOODGROUP } from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class DonorPledge {
  formData = {};

  constructor(formData) {
    const {
      donorDetails,
      witness1Details,
      witness2Details,
      organs,
      donorDetails: { signature: donorSignature },
      witness1Details: { signature: witnessOneSignature },
      witness2Details: { signature: witnessTwoSignature },
    } = formData;

    this.formData = formData;

    const donorAge =
      moment().format('YYYY') - moment(donorDetails.dateOfBirth, FORMAT_DATE_UNIXTS).format('YYYY');

    const witnessOneAge =
      moment().format('YYYY') -
      moment(witness1Details.dateOfBirth, FORMAT_DATE_UNIXTS).format('YYYY');

    const witnessTwoAge =
      moment().format('YYYY') -
      moment(witness2Details.dateOfBirth, FORMAT_DATE_UNIXTS).format('YYYY');

    const _renderNearRelativeDetails = (data) => {
      let result = '';
      switch (data?.nearRelationType) {
        case 'FN':
          result = `S/o ${data?.nearRelativeName}`;
          break;

        case 'MN':
          result = `S/o ${data?.nearRelativeName}`;
          break;

        case 'HN':
          result = `S/o ${data?.nearRelativeName}`;
          break;

        case 'WN':
          result = `S/o ${data?.nearRelativeName}`;
          break;

        case 'SN':
          result = `F/o ${data?.nearRelativeName}`;
          break;

        case 'DN':
          result = `F/o ${data?.nearRelativeName}`;
          break;

        default:
          break;
      }
      return result;
    };

    const _renderCheck = (organ) => {
      if (organs.includes(organ)) {
        return '√';
      }
      return {
        canvas: [
          {
            type: 'polyline',
            lineWidth: 1,
            closePath: true,
            points: [
              {
                x: 0,
                y: 0,
              },
              {
                x: 24,
                y: 0,
              },
              {
                x: 24,
                y: 12,
              },
              {
                x: 0,
                y: 12,
              },
            ],
          },
        ],
      };
    };

    const _renderAllOrgansCheck = () => {
      if (
        organs.includes('HEART') &&
        organs.includes('KIDNEY') &&
        organs.includes('LIVER') &&
        organs.includes('LUNGS') &&
        organs.includes('PANCREA')
      ) {
        return '√';
      }
      return {
        canvas: [
          {
            type: 'polyline',
            lineWidth: 1,
            closePath: true,
            points: [
              {
                x: 0,
                y: 0,
              },
              {
                x: 24,
                y: 0,
              },
              {
                x: 24,
                y: 12,
              },
              {
                x: 0,
                y: 12,
              },
            ],
          },
        ],
      };
    };

    const _renderAllTiisuesCheck = () => {
      if (
        organs.includes('CORNEA') &&
        organs.includes('SKIN') &&
        organs.includes('BONES') &&
        organs.includes('HEARTVALVES') &&
        organs.includes('BLOODVESSELS')
      ) {
        return '√';
      }
      return {
        canvas: [
          {
            type: 'polyline',
            lineWidth: 1,
            closePath: true,
            points: [
              {
                x: 0,
                y: 0,
              },
              {
                x: 24,
                y: 0,
              },
              {
                x: 24,
                y: 12,
              },
              {
                x: 0,
                y: 12,
              },
            ],
          },
        ],
      };
    };

    const reportDocDefinition = {
      content: [
        {
          text: 'FORM 7',
          alignment: 'center',
          bold: true,
        },
        {
          text: 'FOR ORGAN OR TISSUE PLEDGING',
          alignment: 'center',
        },
        {
          text: '(To be filled by individual of age 18 year or above)',
          alignment: 'center',
          bold: true,
          italics: true,
        },
        {
          text: '[Refer rule 5(4)(a)]',
          alignment: 'center',
          italics: true,
        },
        {
          text: 'ORGAN(S) AND TISSUE(S) DONOR FORM',
          alignment: 'center',
        },
        {
          text: '(To be filled in triplicate)',
          alignment: 'center',
          bold: true,
          italics: true,
          margin: [0, 0, 0, 10],
        },
        {
          text: `Registration Number: ${formData?.form7Id || 'RegnNo'}`,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        {
          text: [
            {
              text: `I ${donorDetails.firstName} ${donorDetails.middleName} ${
                donorDetails.lastName
              } ${_renderNearRelativeDetails(
                donorDetails
              )} aged ${donorAge} and date of birth ${moment(
                donorDetails.dateOfBirth,
                FORMAT_DATE_UNIXTS
              ).format('DD MMMM YYYY')} resident of ${
                donorDetails.address.line1
              } in the presence of persons mentioned below hereby unequivocally authorise the removal of following organ(s) and/or tissue(s), from my body after being declared brain stem dead by the board of medical experts and consent to donate the same for therapeutic purposes.`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            [
              {
                text: 'Please tick as applicable',
                margin: [0, 0, 0, 30],
              },
              {
                margin: [50, 0, 50, 0],
                layout: 'noBorders',
                table: {
                  widths: ['*', 'auto'],
                  body: [
                    [
                      {
                        text: 'Heart',
                      },
                      _renderCheck('HEART'),
                    ],
                    [
                      {
                        text: 'Lungs',
                      },
                      _renderCheck('LUNGS'),
                    ],
                    [
                      {
                        text: 'Kidneys',
                      },
                      _renderCheck('KIDNEY'),
                    ],
                    [
                      {
                        text: 'Liver',
                      },
                      _renderCheck('LIVER'),
                    ],
                    [
                      {
                        text: 'Pancreas',
                      },
                      _renderCheck('PANCREA'),
                    ],
                    [
                      {
                        text: 'All Organs',
                      },
                      _renderAllOrgansCheck(),
                      // {
                      //   canvas: [
                      //     {
                      //       type: 'polyline',
                      //       lineWidth: 1,
                      //       closePath: true,
                      //       points: [
                      //         {
                      //           x: 0,
                      //           y: 0,
                      //         },
                      //         {
                      //           x: 24,
                      //           y: 0,
                      //         },
                      //         {
                      //           x: 24,
                      //           y: 12,
                      //         },
                      //         {
                      //           x: 0,
                      //           y: 12,
                      //         },
                      //       ],
                      //     },
                      //   ],
                      // },
                    ],
                  ],
                },
              },
              {
                text: `My blood group is ${BLOODGROUP[donorDetails.bloodGroup].label}`,
                margin: [50, 10, 0, 0],
              },
            ],
            [
              {
                text: '(Following tissues can also be donated after brain stem death as well as cardiac death)',
                alignment: 'center',
                margin: [0, 0, 0, 30],
              },
              {
                layout: 'noBorders',
                margin: [50, 0, 0, 0],
                table: {
                  widths: ['*', 'auto'],
                  body: [
                    [
                      {
                        text: 'Corneas/Eye Balls',
                      },
                      _renderCheck('CORNEA'),
                    ],
                    [
                      {
                        text: 'Skin',
                      },
                      _renderCheck('SKIN'),
                    ],
                    [
                      {
                        text: 'Bones',
                      },
                      _renderCheck('BONES'),
                    ],
                    [
                      {
                        text: 'Heart Valves',
                      },
                      _renderCheck('HEARTVALVES'),
                    ],
                    [
                      {
                        text: 'Blood Vessels',
                      },
                      _renderCheck('BLOODVESSELS'),
                      // {
                      //   canvas: [
                      //     {
                      //       type: 'polyline',
                      //       lineWidth: 1,
                      //       closePath: true,
                      //       points: [
                      //         {
                      //           x: 0,
                      //           y: 0,
                      //         },
                      //         {
                      //           x: 24,
                      //           y: 0,
                      //         },
                      //         {
                      //           x: 24,
                      //           y: 12,
                      //         },
                      //         {
                      //           x: 0,
                      //           y: 12,
                      //         },
                      //       ],
                      //     },
                      //   ],
                      // },
                    ],
                    [
                      {
                        text: 'All Tissues',
                      },
                      _renderAllTiisuesCheck(),
                      // {
                      //   canvas: [
                      //     {
                      //       type: 'polyline',
                      //       lineWidth: 1,
                      //       closePath: true,
                      //       points: [
                      //         {
                      //           x: 0,
                      //           y: 0,
                      //         },
                      //         {
                      //           x: 24,
                      //           y: 0,
                      //         },
                      //         {
                      //           x: 24,
                      //           y: 12,
                      //         },
                      //         {
                      //           x: 0,
                      //           y: 12,
                      //         },
                      //       ],
                      //     },
                      //   ],
                      // },
                    ],
                  ],
                },
              },
            ],
          ],
        },
        {
          margin: [0, 10, 0, 0],
          text: [
            {
              text: '(',
            },
            {
              text: 'Note: ',
              bold: true,
            },
            {
              text: 'In case of online registration of pledge, one copy of the pledge will be retained by pledger, one by the institution where pledge is made and a hard copy signed by pledger and two witnesses shall be sent to the nodal networking organisation.)',
            },
          ],
        },
        {
          margin: [0, 20, 0, 0],
          columns: [
            [
              {
                text: [
                  {
                    text: 'Address of donor pledger: ',
                    bold: true,
                  },
                  {
                    text: `${donorDetails?.address?.line1}`,
                  },
                ],
                alignment: 'left',
              },
              {
                margin: [0, 5, 0, 0],
                text: [
                  {
                    text: 'Telephone of donor pledger: ',
                    bold: true,
                  },
                  {
                    text: `${donorDetails.telephone}`,
                  },
                ],
                alignment: 'left',
              },
              {
                margin: [0, 5, 0, 0],
                text: [
                  {
                    text: 'Email of donor pledger: ',
                    bold: true,
                  },
                  {
                    text: `${donorDetails.email}`,
                  },
                ],
                alignment: 'left',
              },
            ],
            [
              {
                image: donorSignature ? 'DONORSIGN' : 'PLACEHOLDERSIGN',
                alignment: 'right',
                width: 90,
                height: 54,
              },
              {
                margin: [0, 5, 0, 0],
                text: '(Signature of Donor Pledger)',
                alignment: 'right',
              },
            ],
          ],
        },
        {
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: `1. Shri/Smt./Km. ${witness1Details.firstName} ${
                witness1Details.middleName
              } ${_renderNearRelativeDetails(witness1Details)} aged ${witnessOneAge} resident of ${
                witness1Details.address.line1
              } Telephone No ${witness1Details.telephone} Email: ${witness1Details.email}`,
              width: 'auto',
            },
            {
              stack: [
                {
                  image: witnessOneSignature ? 'WITNESSONESIGN' : 'PLACEHOLDERSIGN',
                  alignment: 'right',
                  width: 90,
                  height: 54,
                },
                {
                  text: '(Signature of Witness 1)',
                },
              ],
              alignment: 'right',
              width: 130,
            },
          ],
        },
        {
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: `2. Shri/Smt./Km. ${witness2Details.firstName} ${
                witness2Details.middleName
              } ${_renderNearRelativeDetails(witness2Details)} aged ${witnessTwoAge}  resident of ${
                witness2Details.address.line1
              } Telephone No ${witness2Details.telephone} Email: ${witness2Details.email}`,
            },
            {
              stack: [
                {
                  image: witnessTwoSignature ? 'WITNESSTWOSIGN' : 'PLACEHOLDERSIGN',
                  alignment: 'right',
                  width: 90,
                  height: 54,
                },
                {
                  text: '(Signature of Witness 2)',
                },
              ],
              alignment: 'right',
              width: 130,
            },
          ],
        },
        {
          text: `Dated: ${moment().format('DD/MM/YYYY')}`,
          margin: [0, 10, 0, 0],
        },
        {
          text: `Place: ${donorDetails.address.district}`,
        },
        {
          text: 'Note:',
          bold: true,
          margin: [0, 10, 0, 0],
        },
        {
          text: '(i) Organ donation is a family decision. Therefore, it is important that you discuss your decision with family members and loved ones so that it will be easier for them to follow through with your wishes.',
          margin: [32, 5, 0, 10],
        },
        {
          text: '(ii) One copy of the pledge form/pledge card to be with respective networking organisation, one copy to be retained by institution where the pledge is made and one copy to be handed over to the pledger ',
          margin: [32, 5, 0, 10],
        },
        {
          text: '(iii) The person making the pledge has the option to withdraw the pledge',
          margin: [32, 5, 0, 0],
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 18,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
        },
        textStyle: {
          alignment: 'center',
        },
      },
      images: {
        DONORSIGN: `data:image/jpeg;base64,${donorSignature}`,
        WITNESSONESIGN: `data:image/jpeg;base64,${witnessOneSignature}`,
        WITNESSTWOSIGN: `data:image/jpeg;base64,${witnessTwoSignature}`,
        PLACEHOLDERSIGN:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAA8BAMAAABiEQL9AAAAG1BMVEXMzMyWlpbFxcWjo6OcnJy3t7exsbGqqqq+vr5XjmAEAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAp0lEQVQYGe3Buw6CQBQFwHNBlPIgPtr1gbE0htgbTGixMLYLRGqJfACfbmFnAdKaOwOllFL/xIkTQwziZruDwYfgJyODbBrni+Z2sXkOSpmih5cWCLPr3L+sKQLKa9yiR9TYACuCd4qA8vAtuk3gcGaECAuKgFIHT3RzrXdYZldiFlFkT6n8Ft2cODHTuCYW6cmtTpRNYtDPxxYDTWyJgZzzEUop9eUNWAkUcAfBtUcAAAAASUVORK5CYII=',
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Form 7 - ${this.formData?.form7Id}`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default DonorPledge;
