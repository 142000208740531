import React from 'react';
import { SignUp } from 'aws-amplify-react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withCancellableResolver } from '../../hoc';
// import HospitalSignup from './HospitalSignup';
import NewHospitalRequest from './NewHospitalRequestUi/NewHospitalRequestUi';
// import RottoSottoSignup from './RottoSottoSignup';
import NewRottoSottoRequestUi from './NewRottoSottoRequestUi/NewRottoSottoRequestUi';
import NewZtccRequestUi from './NewZtccRequestUi/NewZtccRequestUi';
import NewDmerRequestUi from './NewDmerRequestUi/NewDmerRequestUi';
import Form7 from '../../components/Form7/Form7';

class AyudaanSignup extends SignUp {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showComponent() {
    const {
      authData: { signupType },
    } = this.props;
    if (signupType === 'HOSPITAL') {
      return <NewHospitalRequest component={this} />;
    }
    if (signupType === 'ROTTO') {
      return <NewRottoSottoRequestUi component={this} />;
    }
    if (signupType === 'ZTCC') {
      return <NewZtccRequestUi component={this} />;
    }
    if (signupType === 'DMER') {
      return <NewDmerRequestUi component={this} />;
    }
    if (signupType === 'DONOR_PLEDGE') {
      return <Form7 component={this} />;
    }
    return null;
  }
}

AyudaanSignup.propTypes = {
  hospital: PropTypes.objectOf(PropTypes.any),
  resolveWithCancellable: PropTypes.func,
};

export default compose(withCancellableResolver)(AyudaanSignup);
