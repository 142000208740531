import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { ReactComponent as HeartSvg } from '../svgs/Heart.svg';
import { ReactComponent as KidneySvg } from '../svgs/Kidney.svg';
import { ReactComponent as IntestineSvg } from '../svgs/Intestine.svg';
import { ReactComponent as HandSvg } from '../svgs/Hand.svg';
import { ReactComponent as LiverSvg } from '../svgs/Liver.svg';
import { ReactComponent as LungsSvg } from '../svgs/Lungs.svg';
import { ReactComponent as HeartLungsSvg } from '../svgs/HeartLungs.svg';
import { ReactComponent as PancreasSvg } from '../svgs/Pancreas.svg';
import { ReactComponent as UterusSvg } from '../svgs/Uterus.svg';
import { ReactComponent as TracheaSvg } from '../svgs/Trachea.svg';
import { ReactComponent as BoneSvg } from '../svgs/Bone.svg';
import { VALIDATION } from '../../constants';

export default function CustomOrganSelector({
  organsList,
  multi,
  onChange,
  onValidation,
  required,
  allowEmpty,
  value,
  disabled,
  organButtonSize,
  ...other
}) {
  const isDirty = false;

  // eslint-disable-next-line prefer-const
  let { schema, validation } = other;
  let valid = true;
  let messages = [];
  let joiError = null;
  if (validation === true) {
    validation = VALIDATION.ALL;
  } else if (validation === false) {
    validation = VALIDATION.OFF;
  }
  if (validation & VALIDATION.ALL || (validation & VALIDATION.DIRTY && isDirty)) {
    schema = schema.messages({
      'alternatives.any': 'Please check',
      'any.required': 'Cannot be empty',
      'string.empty': 'Cannot be empty',
      'string.base': 'Should be a valid string',
      'string.email': 'Should be a valid email',
      'string.pattern.base': 'Cannot contain illegal characters',
      'string.pattern.invert.base': 'Cannot contain illegal characters',
      'string.lowercase': 'Cannot contain uppercase letters',
      'string.uri': 'Should be a valid URI',
      'string.length': 'Should be of length {#limit}',
      'string.max': 'Cannot exceed a length of {#limit}',
      'string.min': 'Cannot have length smaller than {#limit}',
      'string.domain': 'Should be a valid domain',
      'string.hostname': 'Should be a valid hostname',
      'string.ip': 'Should be a valid IP address',
      'string.hex': 'Should be a valid hex number',
      'boolean.base': 'Should be valid selection',
      'number.base': 'Should be a number',
      'number.integer': 'Should be an integer',
      'number.greater': 'Cannot be greater than {#limit}',
      'number.less': 'Cannot be less than {#limit}',
      'number.max': 'Cannot be greater than {#limit}',
      'number.min': 'Cannot be less than {#limit}',
      'number.positive': 'Should be a positive number',
      'number.negative': 'Should be a negative number',
    });
    if (required) {
      schema = schema.required();
    } else if (allowEmpty) {
      schema = schema.allow('').allow(null);
    }
    if (validation & VALIDATION.INVALIDATE_EMPTY) {
      schema = schema.required();
    }

    const { error } = schema.validate(value);
    if (error) {
      valid = false;
      messages = error.details.map((d) => d.message);
      joiError = error;
    }
    onValidation(valid, messages, joiError);
  } else {
    valid = true;
    messages = [];
  }

  let currentOrgans = [value];
  if (multi) {
    currentOrgans = value;
  }

  const _renderButtonText = (organ) => {
    if (organ === 'LIVERANDPANCREAS') {
      return 'LIVER-PANCREAS';
    }
    if (organ === 'KIDNEYANDPANCREAS') {
      return 'KIDNEY-PANCREAS';
    }
    if (organ === 'KIDNEYANDLIVER') {
      return 'KIDNEY-LIVER';
    }
    if (organ === 'PANCREASANDINTESTINE') {
      return 'PANCREAS-INTESTINE';
    }
    if (organ === 'LIVERANDOTHERABDOMINAL') {
      return 'LIVER AND OTHER ABDOMINAL';
    }
    return organ;
  };

  return (
    <Grid
      container
      spacing={3}
      style={{
        marginTop: 10,
        marginBottom: 10,
        marginLeft: -10,
        marginRight: -10,
        border: valid ? '' : '2px solid #FF0000',
        padding: 10,
      }}
    >
      {!valid ? (
        <Grid item sm={12} xs={12}>
          <Typography style={{ color: '#FF0000' }}>Cannot be empty</Typography>
        </Grid>
      ) : null}
      {organsList?.map((organ) => {
        let organIcon;
        switch (organ) {
          case 'HEART':
            organIcon = <HeartSvg />;
            break;

          case 'LIVER':
            organIcon = <LiverSvg />;
            break;

          case 'LUNGS':
            organIcon = <LungsSvg />;
            break;

          case 'KIDNEY':
            organIcon = <KidneySvg />;
            break;

          case 'HAND':
            organIcon = <HandSvg />;
            break;

          case 'LEFT HAND':
            organIcon = <HandSvg />;
            break;

          case 'RIGHT HAND':
            organIcon = <HandSvg />;
            break;

          case 'HEARTLUNGS':
            organIcon = <HeartLungsSvg />;
            break;

          case 'PANCREAS':
            organIcon = <PancreasSvg />;
            break;

          case 'INTESTINE':
            organIcon = <IntestineSvg />;
            break;

          case 'UTERUS':
            organIcon = <UterusSvg />;
            break;

          case 'TRACHEA':
            organIcon = <TracheaSvg />;
            break;

          case 'BONES':
            organIcon = <BoneSvg />;
            break;

          case 'KIDNEY ONE':
            organIcon = <KidneySvg />;
            break;

          case 'KIDNEY TWO':
            organIcon = <KidneySvg />;
            break;

          case 'LEFTKIDNEY':
            organIcon = <KidneySvg />;
            break;

          case 'RIGHTKIDNEY':
            organIcon = <KidneySvg />;
            break;

          case 'KIDNEY':
            organIcon = <KidneySvg />;
            break;

          case 'LIVERANDPANCREAS':
            organIcon = (
              <>
                <LiverSvg />
                <PancreasSvg />
              </>
            );
            break;

          case 'KIDNEYANDPANCREAS':
            organIcon = (
              <>
                <KidneySvg />
                <PancreasSvg />
              </>
            );
            break;

          case 'KIDNEYANDLIVER':
            organIcon = (
              <>
                <KidneySvg />
                <LiverSvg />
              </>
            );
            break;

          case 'PANCREASANDINTESTINE':
            organIcon = (
              <>
                <PancreasSvg />
                <IntestineSvg />
              </>
            );
            break;

          case 'LIVERANDOTHERABDOMINAL':
            organIcon = (
              <>
                <LiverSvg />
              </>
            );
            break;

          default:
            break;
        }
        return (
          <Grid item sm={organButtonSize || 2} xs={6}>
            <Button
              startIcon={organIcon}
              color="primary"
              variant={currentOrgans.includes(organ) ? 'contained' : 'outlined'}
              fullWidth
              style={{ height: 40 }}
              disabled={disabled}
              onClick={() => {
                if (multi) {
                  if (currentOrgans.includes(organ)) {
                    const updatedOrgans = currentOrgans.filter((o) => o !== organ);
                    onChange([...updatedOrgans]);
                  } else {
                    onChange([...currentOrgans, organ]);
                  }
                } else if (currentOrgans.includes(organ)) {
                  onChange('');
                } else {
                  onChange(organ);
                }
              }}
            >
              {_renderButtonText(organ)}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
}
CustomOrganSelector.propTypes = {
  organsList: PropTypes.arrayOf(PropTypes.any).isRequired,
  multi: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.any),
  onValidation: PropTypes.func,
  required: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  organButtonSize: PropTypes.number,
};
CustomOrganSelector.defaultProps = {
  value: undefined,
  onChange: () => 'onChange STUB',
  onValidation: () => 'onValidation STUB',
  required: false,
  allowEmpty: false,
  disabled: false,
};
