import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  Grid,
  makeStyles,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Box,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ValidatedAttachment, ValidatedInput } from '../../Custom';
import { FILETYPES } from '../../Custom/ValidatedAttachment';

function MedicalReportsHemodynamics({
  donorMedicalReportsDetails,
  setDonorMedicalReportsDetails,
  validation,
  onValidation,
}) {
  const app = useSelector((state) => state);
  const {
    user: { userType, userId },
  } = app;

  const useStyles = makeStyles(() => ({
    paperTransplantInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 20,
      marginBottom: 10,
    },
    gridTransplantDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperTransplantSections: {
      height: '100%',
      marginTop: 5,
    },
    transplantSectionsStyle: {
      fontWeight: 300,
      lineHeight: '15px',
      marginTop: 20,
      marginLeft: 20,
    },
    transplantSectionsHeadingStyle: {
      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    transplantDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#34495E',
      marginTop: '5px',
    },
    form12BoxStyle: {
      direction: 'row',
      // marginTop: 90,
      // ...(_isDisabled() ? { marginTop: 120 } : {}),
    },
  }));

  const classes = useStyles();

  const _renderLabReports = (r, idx) => {
    return (
      <>
        <Grid container spacing={8} style={{ padding: 10 }}>
          <Grid item sm={3} xs={12}>
            <ValidatedAttachment
              required
              id="Lab Report"
              name="Lab Report"
              label="Lab Report"
              value={r}
              // disabled={disabled && updateFormMode}
              onChange={(e) => {
                const file = e.target.files[0];
                const newReports = [];
                newReports.push(
                  {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                  ...donorMedicalReportsDetails.imaging.reports
                );
                newReports.pop();
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  imaging: {
                    ...donorMedicalReportsDetails.imaging,
                    reports: newReports,
                  },
                });
              }}
              // schema={Joi.string()}
              // disabled={disabled && updateFormMode}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {idx >= 0 ? (
            <Grid item sm={2} xs={2}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  const newReports = donorMedicalReportsDetails.imaging.reports.filter(
                    (nr, nrIndex) => nrIndex !== idx
                  );
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    imaging: {
                      ...donorMedicalReportsDetails.imaging,
                      reports: newReports,
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E', margin: 5 }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Imaging Reports/Videos
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <ValidatedAttachment
            required
            id="CT Head Report"
            name="CT Head Report"
            label="CT Head Report"
            value={donorMedicalReportsDetails?.imaging?.ctHead?.ctHeadReport}
            // disabled={isDisabled}
            onChange={(e) => {
              const file = e.target.files[0];
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctHead: {
                    ...donorMedicalReportsDetails.imaging.ctHead,
                    ctHeadReport: {
                      ...donorMedicalReportsDetails.imaging.ctHead.ctHeadReport,
                      file,
                      name: file.name,
                    },
                  },
                },
              });
            }}
            // schema={Joi.string()}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4}>
          <ValidatedAttachment
            required
            id="MRI Head Report"
            name="MRI Head Report"
            label="MRI Head Report"
            value={donorMedicalReportsDetails?.imaging?.mriHead?.mriHeadReport}
            // disabled={isDisabled}
            onChange={(e) => {
              const file = e.target.files[0];
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriHead: {
                    ...donorMedicalReportsDetails.imaging.mriHead,
                    mriHeadReport: {
                      ...donorMedicalReportsDetails.imaging.mriHead.mriHeadReport,
                      file,
                      name: file.name,
                    },
                  },
                },
              });
            }}
            // schema={Joi.string()}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4}>
          <ValidatedAttachment
            required
            id="Upload Film"
            name="Upload Film"
            label="X-Ray Chest Film"
            value={donorMedicalReportsDetails?.imaging?.xRayChest?.xRayChestReport}
            // disabled={isDisabled}
            onChange={(e) => {
              const file = e.target.files[0];
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  xRayChest: {
                    ...donorMedicalReportsDetails.imaging.xRayChest,
                    xRayChestReport: {
                      ...donorMedicalReportsDetails.imaging.xRayChest.xRayChestReport,
                      file,
                      name: file.name,
                    },
                  },
                },
              });
            }}
            // schema={Joi.string()}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4}>
          <ValidatedAttachment
            required
            id="Upload Film"
            name="Upload Film"
            label="X-Ray Abdomen Film"
            value={donorMedicalReportsDetails?.imaging?.xRayAbdomen?.xRayAbdomenReport}
            // disabled={isDisabled}
            onChange={(e) => {
              const file = e.target.files[0];
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  xRayAbdomen: {
                    ...donorMedicalReportsDetails.imaging.xRayAbdomen,
                    xRayAbdomenReport: {
                      ...donorMedicalReportsDetails.imaging.xRayAbdomen.xRayAbdomenReport,
                      file,
                      name: file.name,
                    },
                  },
                },
              });
            }}
            // schema={Joi.string()}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4}>
          <ValidatedAttachment
            required
            id="CT Chest Report"
            name="CT Chest Report"
            label="CT Chest Report"
            value={donorMedicalReportsDetails?.imaging?.ctChest?.ctChestReport}
            // disabled={isDisabled}
            onChange={(e) => {
              const file = e.target.files[0];
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctChest: {
                    ...donorMedicalReportsDetails.imaging.ctChest,
                    ctChestReport: {
                      ...donorMedicalReportsDetails.imaging.ctChest.ctChestReport,
                      file,
                      name: file.name,
                    },
                  },
                },
              });
            }}
            // schema={Joi.string()}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4}>
          <ValidatedAttachment
            required
            id="USG Abdomen and Pelvis Report Attachment"
            name="USG Abdomen and Pelvis Report Attachment"
            label="USG Abdomen and Pelvis Report Attachment"
            value={
              donorMedicalReportsDetails?.imaging?.usgAbdomenAndPelvis?.usgAbdomenAndPelvisReport
            }
            // disabled={isDisabled}
            onChange={(e) => {
              const file = e.target.files[0];
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  usgAbdomenAndPelvis: {
                    ...donorMedicalReportsDetails.imaging.usgAbdomenAndPelvis,
                    usgAbdomenAndPelvisReport: {
                      ...donorMedicalReportsDetails.imaging.usgAbdomenAndPelvis
                        .usgAbdomenAndPelvisReport,
                      file,
                      name: file.name,
                    },
                  },
                },
              });
            }}
            // schema={Joi.string()}
            validation={validation}
            onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E', margin: 5 }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            CT Head
          </Typography>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography variant="body1">SDH</Typography>
          <RadioGroup
            row={true}
            aria-label="SDH"
            name="SDH"
            value={donorMedicalReportsDetails?.imaging?.ctHead?.ctHeadSdh}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctHead: {
                    ...donorMedicalReportsDetails.imaging.ctHead,
                    ctHeadSdh: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">EDH</Typography>
          <RadioGroup
            row={true}
            aria-label="EDH"
            name="EDH"
            value={donorMedicalReportsDetails?.imaging?.ctHead?.ctHeadEdh}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctHead: {
                    ...donorMedicalReportsDetails.imaging.ctHead,
                    ctHeadEdh: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Intra Ventricular Bleed</Typography>
          <RadioGroup
            row={true}
            aria-label="Intra Ventricular Bleed"
            name="Intra Ventricular Bleed"
            value={donorMedicalReportsDetails?.imaging?.ctHead?.ctHeadIntraVentricularBleed}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctHead: {
                    ...donorMedicalReportsDetails.imaging.ctHead,
                    ctHeadIntraVentricularBleed: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Mid Line Shift</Typography>
          <RadioGroup
            row={true}
            aria-label="Mid Line Shift"
            name="Mid Line Shift"
            value={donorMedicalReportsDetails?.imaging?.ctHead?.ctHeadMidLineShift}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctHead: {
                    ...donorMedicalReportsDetails.imaging.ctHead,
                    ctHeadMidLineShift: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E', margin: 5 }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            MRI Head
          </Typography>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography variant="body1">SDH</Typography>
          <RadioGroup
            row={true}
            aria-label="SDH"
            name="SDH"
            value={donorMedicalReportsDetails?.imaging?.mriHead?.mriHeadSdh}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriHead: {
                    ...donorMedicalReportsDetails.imaging.mriHead,
                    mriHeadSdh: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">EDH</Typography>
          <RadioGroup
            row={true}
            aria-label="EDH"
            name="EDH"
            value={donorMedicalReportsDetails?.imaging?.mriHead?.mriHeadEdh}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriHead: {
                    ...donorMedicalReportsDetails.imaging.mriHead,
                    mriHeadEdh: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Intra Ventricular Bleed</Typography>
          <RadioGroup
            row={true}
            aria-label="Intra Ventricular Bleed"
            name="Intra Ventricular Bleed"
            value={donorMedicalReportsDetails?.imaging?.mriHead?.mriHeadIntraVentricularBleed}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriHead: {
                    ...donorMedicalReportsDetails.imaging.mriHead,
                    mriHeadIntraVentricularBleed: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Mid Line Shift</Typography>
          <RadioGroup
            row={true}
            aria-label="Mid Line Shift"
            name="Mid Line Shift"
            value={donorMedicalReportsDetails?.imaging?.mriHead?.mriHeadMidLineShift}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriHead: {
                    ...donorMedicalReportsDetails.imaging.mriHead,
                    mriHeadMidLineShift: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {/* <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E', margin: 5 }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            X-ray Chest
          </Typography>
        </Grid> */}

        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E', margin: 5 }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            X-ray Abdomen
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">X-ray Abdomen</Typography>
          <RadioGroup
            row={true}
            aria-label="X-ray Abdomen"
            name="X-ray Abdomen"
            value={donorMedicalReportsDetails?.imaging?.xRayAbdomen?.xRayAbdomenYesNo}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  xRayAbdomen: {
                    ...donorMedicalReportsDetails.imaging.xRayAbdomen,
                    xRayAbdomenYesNo: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>

        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E', margin: 5 }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            CT Chest
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">CT Chest</Typography>
          <RadioGroup
            row={true}
            aria-label="CT Chest"
            name="CT Chest"
            value={donorMedicalReportsDetails?.imaging?.ctChest?.ctChestYesNo}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctChest: {
                    ...donorMedicalReportsDetails.imaging.ctChest,
                    ctChestYesNo: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Evidence of Pneumonia </Typography>
          <RadioGroup
            row={true}
            aria-label="Evidence of Pneumonia"
            name="Evidence of Pneumonia"
            value={donorMedicalReportsDetails?.imaging?.ctChest?.evidenceOfPneumonia}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctChest: {
                    ...donorMedicalReportsDetails.imaging.ctChest,
                    evidenceOfPneumonia: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Evidence of Lungs Fibrosis </Typography>
          <RadioGroup
            row={true}
            aria-label="Evidence of Lungs Fibrosis"
            name="Evidence of Lungs Fibrosis"
            value={donorMedicalReportsDetails?.imaging?.ctChest?.evidenceOfLungsFibrosis}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctChest: {
                    ...donorMedicalReportsDetails.imaging.ctChest,
                    evidenceOfLungsFibrosis: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Evidence of COVID</Typography>
          <RadioGroup
            row={true}
            aria-label="Evidence of COVID"
            name="Evidence of COVID"
            value={donorMedicalReportsDetails?.imaging?.ctChest?.evidenceOfCovid}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctChest: {
                    ...donorMedicalReportsDetails.imaging.ctChest,
                    evidenceOfCovid: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Evidence of any malignancy</Typography>
          <RadioGroup
            row={true}
            aria-label="Evidence of any malignancy"
            name="Evidence of any malignancy"
            value={donorMedicalReportsDetails?.imaging?.ctChest?.evidenceOfAnyMalignancy}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctChest: {
                    ...donorMedicalReportsDetails.imaging.ctChest,
                    evidenceOfAnyMalignancy: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {/* <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E', margin: 5 }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            USG Abdomen and Pelvis
          </Typography>
        </Grid> */}

        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E', margin: 5 }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            CT Abdomen
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">CT Abdomen</Typography>
          <RadioGroup
            row={true}
            aria-label="CT Abdomen"
            name="CT Abdomen"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.ctAbdomenYesNo}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    ctAbdomenYesNo: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Liver Size</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.liverSize}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    liverSize: e.target.value,
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">PV Size</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.pvSize}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    pvSize: e.target.value,
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">PVT</Typography>
          <RadioGroup
            row={true}
            aria-label="PVT"
            name="PVT"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.pvt}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    pvt: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Echotexture</Typography>
          <RadioGroup
            row={true}
            aria-label="Ecotexture"
            name="Ecotexture"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.ecotexture}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    ecotexture: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Fatty Liver</Typography>
          <RadioGroup
            row={true}
            aria-label="Fatty Liver"
            name="Fatty Liver"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.fattyLiver}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    fattyLiver: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Evidence of portal hypertension</Typography>
          <RadioGroup
            row={true}
            aria-label="Evidence of portal hypertension"
            name="Evidence of portal hypertension"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.evidenceOfPortalHypertension}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    evidenceOfPortalHypertension: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Splenomegaly</Typography>
          <RadioGroup
            row={true}
            aria-label="Splenomegaly"
            name="Splenomegaly"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.splenomegaly}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    splenomegaly: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Collaterals</Typography>
          <RadioGroup
            row={true}
            aria-label="Collaterals"
            name="Collaterals"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.collaterals}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    collaterals: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Ascites</Typography>
          <RadioGroup
            row={true}
            aria-label="Ascites"
            name="Ascites"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.ascites}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    ascites: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">SOL in Liver</Typography>
          <RadioGroup
            row={true}
            aria-label="Sol in Liver"
            name="Sol in Liver"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.solInLiver}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    solInLiver: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {donorMedicalReportsDetails?.imaging?.ctAbdomen?.solInLiver === true ? (
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">SOL in liver details</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.solInLiverDetails}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  imaging: {
                    ...donorMedicalReportsDetails.imaging,
                    ctAbdomen: {
                      ...donorMedicalReportsDetails.imaging.ctAbdomen,
                      solInLiverDetails: e.target.value,
                    },
                  },
                });
              }}
            />
          </Grid>
        ) : (
          <Grid item sm={6} xs={12}></Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Loss of CMD</Typography>
          <RadioGroup
            row={true}
            aria-label="Loss of CMD"
            name="loss of CMD"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.lossOfCmd}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    lossOfCmd: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {donorMedicalReportsDetails?.imaging?.ctAbdomen?.lossOfCmd === true ? (
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">Loss of CMD details</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.lossOfCmdDetails}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  imaging: {
                    ...donorMedicalReportsDetails.imaging,
                    ctAbdomen: {
                      ...donorMedicalReportsDetails.imaging.ctAbdomen,
                      lossOfCmdDetails: e.target.value,
                    },
                  },
                });
              }}
            />
          </Grid>
        ) : (
          <Grid item sm={6} xs={12}></Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Any lesion in Kidney </Typography>
          <RadioGroup
            row={true}
            aria-label="Any lesion in kidney"
            name="Any lesion in kidney"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.anyLesionInKidney}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    anyLesionInKidney: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {donorMedicalReportsDetails?.imaging?.ctAbdomen?.anyLesionInKidney === true ? (
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">Any lesion in kidney details</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.anyLesionInKidneyDetails}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  imaging: {
                    ...donorMedicalReportsDetails.imaging,
                    ctAbdomen: {
                      ...donorMedicalReportsDetails.imaging.ctAbdomen,
                      anyLesionInKidneyDetails: e.target.value,
                    },
                  },
                });
              }}
            />
          </Grid>
        ) : (
          <Grid item sm={6} xs={12}></Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Any other abnormal finding </Typography>
          <RadioGroup
            row={true}
            aria-label="Any other abnormal finding"
            name="Any other abnormal finding"
            value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.anyOtherAbnormalFinding}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  ctAbdomen: {
                    ...donorMedicalReportsDetails.imaging.ctAbdomen,
                    anyOtherAbnormalFinding: e.target.value === 'true',
                    anyOtherAbnormalFindingDetails: ''
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {donorMedicalReportsDetails?.imaging?.ctAbdomen?.anyOtherAbnormalFinding === true ? (
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">Any other abnormal finding details</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.imaging?.ctAbdomen?.anyOtherAbnormalFindingDetails}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  imaging: {
                    ...donorMedicalReportsDetails.imaging,
                    ctAbdomen: {
                      ...donorMedicalReportsDetails.imaging.ctAbdomen,
                      anyOtherAbnormalFindingDetails: e.target.value,
                    },
                  },
                });
              }}
            />
          </Grid>
        ) : (
          <Grid item sm={6} xs={12}></Grid>
        )}
        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E', margin: 5 }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            MRI Abdomen
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">MRI Abdomen</Typography>
          <RadioGroup
            row={true}
            aria-label="MRI Abdomen"
            name="MRI Abdomen"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.mriAbdomenYesNo}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    mriAbdomenYesNo: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Liver Size</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.liverSize}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    liverSize: e.target.value,
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">PV Size</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.pvSize}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    pvSize: e.target.value,
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">PVT</Typography>
          <RadioGroup
            row={true}
            aria-label="PVT"
            name="PVT"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.pvt}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    pvt: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Echotexture</Typography>
          <RadioGroup
            row={true}
            aria-label="Ecotexture"
            name="Ecotexture"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.ecotexture}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    ecotexture: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Fatty Liver</Typography>
          <RadioGroup
            row={true}
            aria-label="Fatty Liver"
            name="Fatty Liver"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.fattyLiver}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    fattyLiver: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Evidence of portal hypertension</Typography>
          <RadioGroup
            row={true}
            aria-label="Evidence of portal hypertension"
            name="Evidence of portal hypertension"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.evidenceOfPortalHypertension}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    evidenceOfPortalHypertension: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Splenomegaly</Typography>
          <RadioGroup
            row={true}
            aria-label="Splenomegaly"
            name="Splenomegaly"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.splenomegaly}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    splenomegaly: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Collaterals</Typography>
          <RadioGroup
            row={true}
            aria-label="Collaterals"
            name="Collaterals"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.collaterals}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    collaterals: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Ascites</Typography>
          <RadioGroup
            row={true}
            aria-label="Ascites"
            name="Ascites"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.ascites}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    ascites: e.target.value === 'true',
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">SOL in Liver</Typography>
          <RadioGroup
            row={true}
            aria-label="Sol in Liver"
            name="Sol in Liver"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.solInLiver}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    solInLiver: e.target.value === 'true',
                    solInLiverDetails:''
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {donorMedicalReportsDetails?.imaging?.mriAbdomen?.solInLiver === true ? (
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">SOL in liver details</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.solInLiverDetails}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  imaging: {
                    ...donorMedicalReportsDetails.imaging,
                    mriAbdomen: {
                      ...donorMedicalReportsDetails.imaging.mriAbdomen,
                      solInLiverDetails: e.target.value,
                    },
                  },
                });
              }}
            />
          </Grid>
        ) : (
          <Grid item sm={6} xs={12}></Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Loss of CMD</Typography>
          <RadioGroup
            row={true}
            aria-label="Loss of CMD"
            name="loss of CMD"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.lossOfCmd}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    lossOfCmd: e.target.value === 'true',
                    lossOfCmdDetails:''
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {donorMedicalReportsDetails?.imaging?.mriAbdomen?.lossOfCmd === true ? (
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">Loss of CMD details</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.lossOfCmdDetails}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  imaging: {
                    ...donorMedicalReportsDetails.imaging,
                    mriAbdomen: {
                      ...donorMedicalReportsDetails.imaging.mriAbdomen,
                      lossOfCmdDetails: e.target.value,
                    },
                  },
                });
              }}
            />
          </Grid>
        ) : (
          <Grid item sm={6} xs={12}></Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Any lesion in Kidney </Typography>
          <RadioGroup
            row={true}
            aria-label="Any lesion in kidney"
            name="Any lesion in kidney"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.anyLesionInKidney}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    anyLesionInKidney: e.target.value === 'true',
                    anyLesionInKidneyDetails:''
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {donorMedicalReportsDetails?.imaging?.mriAbdomen?.anyLesionInKidney === true ? (
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">Any lesion in kidney details</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.anyLesionInKidneyDetails}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  imaging: {
                    ...donorMedicalReportsDetails.imaging,
                    mriAbdomen: {
                      ...donorMedicalReportsDetails.imaging.mriAbdomen,
                      anyLesionInKidneyDetails: e.target.value,
                    },
                  },
                });
              }}
            />
          </Grid>
        ) : (
          <Grid item sm={6} xs={12}></Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Typography variant="body1">Any other abnormal finding </Typography>
          <RadioGroup
            row={true}
            aria-label="Any other abnormal finding"
            name="Any other abnormal finding"
            value={donorMedicalReportsDetails?.imaging?.mriAbdomen?.anyOtherAbnormalFinding}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                imaging: {
                  ...donorMedicalReportsDetails.imaging,
                  mriAbdomen: {
                    ...donorMedicalReportsDetails.imaging.mriAbdomen,
                    anyOtherAbnormalFinding: e.target.value === 'true',
                    anyOtherAbnormalFindingDetails:''
                  },
                },
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {donorMedicalReportsDetails?.imaging?.mriAbdomen?.anyOtherAbnormalFinding === true ? (
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">Any other abnormal finding details</Typography>
            <ValidatedInput
              required
              fullWidth
              value={
                donorMedicalReportsDetails?.imaging?.mriAbdomen?.anyOtherAbnormalFindingDetails
              }
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  imaging: {
                    ...donorMedicalReportsDetails.imaging,
                    mriAbdomen: {
                      ...donorMedicalReportsDetails.imaging.mriAbdomen,
                      anyOtherAbnormalFindingDetails: e.target.value,
                    },
                  },
                });
              }}
            />
          </Grid>
        ) : (
          <Grid item sm={6} xs={12}></Grid>
        )}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Creatinine (During Admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.serumCreatinineAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  serumCreatinineAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Creatinine (At Present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.serumCreatinine}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  serumCreatinine: e.target.value,
                },
              });
            }}
          />
        </Grid>
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Serum Electrolytes</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.electrolytes}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  electrolytes: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Sodium (Na)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.na}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  na: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Potassium (K)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.k}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  k: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Chloride (Cl)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.cl}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  cl: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">BUN (Blood Urea Nitrogen)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.bun}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  bun: e.target.value,
                },
              });
            }}
          />
        // </Grid> */}
        <Grid item sm={12} xs={12}>
          <Typography variant="body1">Any Report Attachments</Typography>
        </Grid>
        {donorMedicalReportsDetails?.imaging?.reports.map((r, idx) => _renderLabReports(r, idx))}
        <Grid container style={{ marginRight: 10 }}>
          <Grid item style={{ marginLeft: 20 }}>
            <Button
              color="primary"
              variant="contained"
              // disabled={disabled && updateFormMode}
              onClick={() => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  imaging: {
                    ...donorMedicalReportsDetails.imaging,
                    reports: [
                      ...donorMedicalReportsDetails.imaging.reports,
                      {
                        name: '',
                        key: '',
                      },
                    ],
                  },
                });
              }}
            >
              + New Report
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

MedicalReportsHemodynamics.propTypes = {
  donorMedicalReportsDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  setDonorMedicalReportsDetails: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
};
MedicalReportsHemodynamics.defaultProps = {};

export default MedicalReportsHemodynamics;
