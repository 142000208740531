import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { diff } from 'rus-diff';
import {
  makeStyles,
  Paper,
  Box,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import cloneDeep from 'lodash.clonedeep';
import Joi from 'joi';
import util from 'util';
import PropTypes from 'prop-types';
import { Redirect, withRouter, useRouteMatch, Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import StyledDialogTitle from '../../../../components/common/StyledDialogTitle';
import { uploadDocument } from '../../../../utils';
import ValidatedInput from '../../../../components/Custom/ValidatedInput';
import LoadableButton from '../../../../components/Custom/LoadableButton';
import {
  ReactTable,
  ValidatedAttachment,
  ValidatedDate,
  ValidatedSelectOther,
} from '../../../../components/Custom';
import ValidatedSelect from '../../../../components/Custom/ValidatedSelect';
import { ValidatedRadio } from '../../../../components/Custom';
import { ReactComponent as GovernmentSvg } from '../../../../components/svgs/Government.svg';
import { withValidation } from '../../../../hoc';
import { useCancellableApiCall } from '../../../../hooks';
import {
  BLOODGROUP,
  SEX,
  REGEXEXP_NOWHITESPACEATENDANDBEGIN,
  isHospitalIcUserType,
  VALIDATION,
  isDhsUserType,
  isEyeBank,
  FILESUFFIX_FORMAT_DATE,
  DONOR_FORMSTATUS,
  FORMAT_DATE_UNIXTS,
} from '../../../../constants';
import {
  PATH_EXP_EYETRANSPLANT_GETDRAFTEYEDONOR,
  PATH_HOSPITAL_GETDRAFTEYEDONOR,
  PATH_EXP_EYETRANSPLANT_GETEYEDONOR,
  PATH_EXP_EYETRANSPLANT_SUBMITDRAFTEYEDONOR,
  PATH_EXP_EYETRANSPLANT_SUBMITEYEDONOR,
  PATH_EXP_EYETRANSPLANT_SUBMITEYEDONOREDITS,
} from '../../../../appConstants';
import { ORGAN_DONOR as EMPTY_DONOR } from '../../../../emptyModels';
import { OPTIONS_NATIONALITY } from '../../../../nationalities';
import { FILETYPES } from '../../../../components/Custom/ValidatedAttachment';
import { STATE_IN } from '../../../../statesIndia';
import { districts } from '../../../../districts/districts';
import {
  OPTS_DONOR_MEDICOLEGAL,
  OPTS_DONOR_RELIGIAN,
  OPTS_DONOR_MOTIVATEDBY,
  OPTS_DONOR_REMOVALPLACE,
  OPTS_DONOR_TISSUETYPE,
  OPTS_DONOR_TYPEOFPRESERVATION,
  OPTS_DONOR_TISSUENUMBER,
  OPTIONS_EYEDONORSTATUS,
  OPTS_DONOR_CAUSEOFDEATH,
  BLOODCOLLECTED,
  EYEDONOR_FILEPREFIX,
} from '../../../../appConstants/eyebank';
import { EYE_DONOR } from '../../../../emptyModels/eyebank';
import GreenTagSectionHeader from '../../../../components/common/GreenTagSectionHeader';
import DataLoadingScreen from '../../../../components/Custom/DataLoadingScreen';
import BsdDonor from '../../../../components/BsdDonor/BsdDonor_bk';
import { match } from 'assert';

function EyeDonor(props) {
  // const [initialEyeDonor, setInitialEyeDonor] = useState(cloneDeep(EYE_DONOR));
  const EyeDonorRef = useRef({ ...EYE_DONOR });
  const [eyeDonor, setEyeDonor] = useState({ ...EYE_DONOR });
  const [eyeDonorId, setEyeDonorId] = useState('');
  const [editMode] = useState(false);
  const [draftId, setDraftId] = useState('');
  const [redirectToSaved, setRedirectToSaved] = useState(false);
  const [redirectToRecord, setRedirectToRecord] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [redirectToSubmittedEyeDonor, setRedirectToSubmittedEyeDonor] = useState(false);
  const [redirectToNew, steRedirectToNew] = useState(false);
  const { apiPost, apiGet, apiPatch, apiCallStatuses } = useCancellableApiCall();

  const app = useSelector((state) => state);
  const {
    user: { hospitalId, userId, userType },
    hospital: { name: hospitalName },
  } = app;

  const draftMatch = useRouteMatch(`/eyeDonor/saved/:draftId`);
  const recordMatch = useRouteMatch(`/eyeDonor/record/:eyeDonorId`);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //setIsLoading(true)
    let extractedDraftId;
    const { validate } = props;
    if (draftMatch) {
      const {
        params: { draftId: id },
      } = draftMatch;
      extractedDraftId = id;
      console.log('The draftId Is :', extractedDraftId);
    }
    if (extractedDraftId) {
      apiGet(PATH_EXP_EYETRANSPLANT_GETDRAFTEYEDONOR, { draftId: extractedDraftId })
        .then((draftEyeDonor) => {
          setEyeDonor({ ...draftEyeDonor });
          console.log('The Draft Eye Donor Details are : ', eyeDonor);
          EyeDonorRef.current = { ...draftEyeDonor };
          validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (isValid) => {
            setIsFormFilled(isValid);
          });
          setIsLoading(false);
        })
        .catch(() => {});
    }
    let extractedRecordId;
    if (recordMatch) {
      const {
        params: { eyeDonorId: id },
      } = recordMatch;
      extractedRecordId = id;
    }

    if (extractedRecordId) {
      apiGet(util.format(PATH_EXP_EYETRANSPLANT_GETDRAFTEYEDONOR, extractedRecordId), {})
        .then((eyeDonorRecord) => {
          setEyeDonor({ ...eyeDonorRecord });
          EyeDonorRef.current = { ...eyeDonorRecord };
          validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (isValid) => {
            setIsFormFilled(isValid);
          });
        })
        .catch(() => {});
    }
  }, [apiGet, redirectToRecord]);

  const uploadFormDocuments = async () => {
    const { deathCertificate, consent } = eyeDonor;
    const fileSuffix = moment().format(FILESUFFIX_FORMAT_DATE);

    const uploadPromises = [];

    if (deathCertificate) {
      uploadPromises.push(
        uploadDocument(
          deathCertificate,
          EYEDONOR_FILEPREFIX.DEATHCERTIFICATE,
          hospitalName,
          fileSuffix
        )
      );
    }

    if (consent) {
      uploadPromises.push(
        uploadDocument(consent, EYEDONOR_FILEPREFIX.CONSENT, hospitalName, fileSuffix)
      );
    }
    return Promise.all(uploadPromises);
  };

  const [saveEyeDonorApiId, setSaveEyeDonorApiId] = useState('APIID');
  //
  const submitDraftEyeDonor = async () => {
    const { validate } = props;
    setIsLoading(true);
    await uploadFormDocuments();

    validate(VALIDATION.DIRTY, (valid) => {
      if (!valid) {
        return;
      }
      eyeDonor.createdBy = userId;
      eyeDonor.hospitalId = hospitalId;
      eyeDonor.hospitalName = hospitalName;

      apiPost(
        PATH_EXP_EYETRANSPLANT_SUBMITDRAFTEYEDONOR,
        {
          hospitalId,
          hospitalName,
          eyeDonor,
        },
        {
          apiIdCb: (apiId) => {
            setSaveEyeDonorApiId(apiId);
          },
        }
      )
        .then((newEyeDonor) => {
          apiGet(PATH_EXP_EYETRANSPLANT_GETDRAFTEYEDONOR, { draftId: newEyeDonor.draftId }).then(
            (draftEyeDonor) => {
              setEyeDonor({ ...draftEyeDonor });
              EyeDonorRef.current = { ...draftEyeDonor };
              setDraftId(newEyeDonor.draftId);
              setRedirectToSaved(true);
              validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (isValid) => {
                setIsLoading(false);
                setIsFormFilled(isValid);
              });
            }
          );
        })
        .catch(() => {
          setIsLoading(false);
        });
    }).catch(() => {
      setIsLoading(false);
    });
  };

  const submitEyeDonorEdits = async () => {
    const { validate } = props;
    setIsLoading(true);
    await uploadFormDocuments();

    const eyeDonorEdits = diff(EyeDonorRef.current, eyeDonor);
    if (!eyeDonorEdits) {
      setIsLoading(false);
      return EyeDonorRef.current;
    }
    if (eyeDonorEdits) {
      const { draftId: currentDraftId } = eyeDonor;
      //console.log('The Current Draft IT is: ', currentDraftId);
      //console.log('The Edited Donor Details are: ', eyeDonorEdits);
      apiPatch(
        PATH_EXP_EYETRANSPLANT_SUBMITEYEDONOREDITS,
        {
          draftId: currentDraftId,
          eyeDonorEdits: eyeDonorEdits,
          hospitalId,
        },
        {
          apiIdCb: (apiId) => {
            setSaveEyeDonorApiId(apiId);
          },
        }
      )
        .then((editedEyeDonor) => {
          setEyeDonor({ ...editedEyeDonor });
          EyeDonorRef.current = { ...editedEyeDonor };
          validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (isValid) => {
            setIsLoading(false);
            setIsFormFilled(isValid);
          });
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const [submitEyeDonorApiId, setSubmitEyeDonorApiId] = useState('APIID');

  const submitEyeDonor = async () => {
    const { validate } = props;
    setIsLoading(true);
    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (valid) => {
      if (!valid) {
        setIsLoading(false);
        return;
      }

      //const { donorId } = eyeDonor;
      const formStatus = DONOR_FORMSTATUS.SUBMITTED;
      const draftId = eyeDonor.draftId;

      console.log('The Draft Id is: ', eyeDonor.draftId);
      apiPost(
        PATH_EXP_EYETRANSPLANT_SUBMITEYEDONOR,
        { draftId, hospitalId }, //donorId, formStatus
        {
          apiIdCb: (apiId) => {
            setSubmitEyeDonorApiId(apiId);
          },
        }
      )
        .then(() => {
          setRedirectToRecord(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    });
  };

  const _handleSave = () => {
    if (eyeDonor.formStatus === 'NEW') {
      submitDraftEyeDonor();
    } else {
      submitEyeDonorEdits();
    }
  };
  const _isDisabled = () => {
    const { formStatus, editMode } = eyeDonor;
    return !(
      eyeDonor.formStatus === OPTIONS_EYEDONORSTATUS.NEW ||
      eyeDonor.formStatus === OPTIONS_EYEDONORSTATUS.DRAFT ||
      editMode
    );
  };

  const useStyles = makeStyles(() => ({
    paperBsdInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 80,
      marginBottom: 10,
    },
    gridBsdDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperBsdSections: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px 0px 0px 4px',
      height: '100%',
    },
    bsdSectionsStyle: {
      // fontFamily: 'Mulish',
      fontWeight: 300,
      marginTop: 20,
      marginLeft: 20,
    },
    noteStyle: {
      fontWeight: 600,
      lineHeight: '17px',
      color: 'red',
    },
    bsdSectionsHeadingStyle: {
      // fontFamily: 'Mulish',

      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    bsdDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      marginTop: '5px',
    },
    bsdBoxStyle: {
      direction: 'row',
    },
    transplantListHeading: {
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '25px',
      color: '#34495E',
      marginBottom: 10,
    },
  }));

  const classes = useStyles();

  const RenderEyeDonorUi = () => {
    const { formStatus } = eyeDonor;
    const { validation, onValidation } = props;

    return (
      <Grid item xs={12}>
        {redirectToRecord ? <Redirect to={`/eyeDonor/record/${eyeDonorId}`} /> : null}
        <Paper>
          <DataLoadingScreen isLoading={isLoading}>
            <Grid container>
              <Grid container direction="column" className={classes.gridBsdDetails}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={10}>
                    <GreenTagSectionHeader headerText="Donor Details" />
                  </Grid>
                </Grid>
                <Grid item>
                  <hr />
                </Grid>

                <Grid container spacing={5} style={{ marginBottom: 20, marginTop: 10 }}>
                  <Grid item sm={4}>
                    <Typography>Hospital Name</Typography>
                    <ValidatedInput
                      required
                      // label="Hospital Name"
                      value={eyeDonor.hospitalName || hospitalName}
                      disabled={true}
                      validation={validation}
                      onValidation={onValidation}
                      schema={Joi.string()}
                      fullWidth
                    />
                  </Grid>
                  {formStatus !== 'DRAFT' && formStatus !== 'NEW' ? (
                    <Grid item sm={4}>
                      <Typography>System Id</Typography>
                      <ValidatedInput
                        required
                        // label="System Id"
                        value={eyeDonor.donorId}
                        disabled={true}
                        validation={validation}
                        onValidation={onValidation}
                        schema={Joi.string()}
                        fullWidth
                      />
                    </Grid>
                  ) : null}

                  <Grid item>
                    <hr />
                  </Grid>
                </Grid>
                <Grid container spacing={5} style={{ marginBottom: 20 }}>
                  <Grid item sm={4}>
                    <Typography>
                      First Name<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      label="First Name"
                      disabled={_isDisabled()}
                      value={eyeDonor.firstName}
                      validation={validation}
                      onValidation={onValidation}
                      schema={Joi.string()}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          firstName: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Middle Name<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      label="Middle Name"
                      value={eyeDonor.fatherName}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      schema={Joi.string()}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          fatherName: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Last Name<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      label="Last Name"
                      value={eyeDonor.lastName}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      schema={Joi.string()}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          lastName: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Donor Address<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      label="Donor Address"
                      value={eyeDonor.address}
                      schema={Joi.string()}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          address: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Age<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      label="Age"
                      value={eyeDonor.age}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      schema={Joi.number().min(0)}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          age: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4} xs={10}>
                    <Typography>
                      Gender<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      label="Gender"
                      value={eyeDonor.sex}
                      options={Object.values(SEX)}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          sex: e.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  {/*
                  <Grid item sm={4} xs={10}>
                    <Typography>BloodGroup<span style={{ color: 'red' }}>*</span></Typography>
                    <ValidatedSelect
                      required
                      label="BloodGroup"
                      value={eyeDonor.bloodGroup}
                      options={Object.values(BLOODGROUP)}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          bloodGroup: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                        */}
                  <Grid item sm={4}>
                    <Typography>
                      Weight(in kgs)<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      label="Weight(in kgs)"
                      value={eyeDonor.weight}
                      disabled={_isDisabled()}
                      schema={Joi.string()}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          weight: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Height(in cm)<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      label="Height(in cm)"
                      value={eyeDonor.height}
                      disabled={_isDisabled()}
                      schema={Joi.string()}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          height: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={4} xs={10}>
                    <Typography>
                      Country<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      label="Country"
                      value={eyeDonor.nationality}
                      options={OPTIONS_NATIONALITY}
                      disabled={_isDisabled()}
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          nationality: e.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  {eyeDonor.nationality === 'IN' ? (
                    <>
                      <Grid item sm={4} xs={10}>
                        <Typography>
                          State<span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <ValidatedSelect
                          required
                          label="State"
                          options={Object.values(STATE_IN)}
                          value={eyeDonor.state}
                          disabled={_isDisabled()}
                          validation={validation}
                          onValidation={onValidation}
                          onChange={(e) => {
                            setEyeDonor({
                              ...eyeDonor,
                              state: e.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <Typography>
                          District<span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <ValidatedSelect
                          required
                          label="District"
                          options={Object.values(districts(eyeDonor.state))}
                          value={eyeDonor.district}
                          disabled={_isDisabled()}
                          validation={validation}
                          onValidation={onValidation}
                          onChange={(e) => {
                            setEyeDonor({
                              ...eyeDonor,
                              district: e.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <Typography>
                          Pin Code<span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <ValidatedInput
                          label="Pin Code"
                          value={eyeDonor.pinCode}
                          disabled={_isDisabled()}
                          schema={Joi.string()}
                          onChange={(e) => {
                            setEyeDonor({
                              ...eyeDonor,
                              pinCode: e.target.value,
                            });
                          }}
                          fullWidth
                        />
                      </Grid>
                    </>
                  ) : null}

                  <Grid item sm={4} xs={10}>
                    <Typography>
                      Religion<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      label="Religion"
                      options={Object.values(OPTS_DONOR_RELIGIAN)}
                      value={eyeDonor.religion}
                      validation={validation}
                      onValidation={onValidation}
                      disabled={_isDisabled()}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          religion: e.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={4} xs={10}>
                    <Typography>
                      UHID<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      label="UHID"
                      value={eyeDonor.uhid}
                      disabled={_isDisabled()}
                      schema={Joi.string()}
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          uhid: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>

              <>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  style={{ marginLeft: 20 }}
                >
                  <GreenTagSectionHeader headerText="Donor's Other Details" />
                  <Grid item>
                    <hr />
                  </Grid>
                </Grid>
                <Grid item style={{ marginLeft: 20 }}>
                  {/* <Typography>Donor other details<span style={{ color: 'red' }}>*</span></Typography> */}
                </Grid>

                <Grid
                  container
                  spacing={5}
                  style={{ marginLeft: 8, marginRight: 8, marginTop: 10, marginBottom: 20 }}
                >
                  <Grid item sm={4}>
                    <Typography>
                      Physician Certifying death<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      id="deathCertifyingPhysician"
                      name="deathCertifyingPhysician"
                      value={eyeDonor.deathCertifyingPhysician}
                      disabled={_isDisabled()}
                      placeholder="Physician"
                      schema={Joi.string()}
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          deathCertifyingPhysician: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>

                  {/*
                     Attachemnt 
                    <Grid item sm={4}>
                      <ValidatedAttachment
                        id="nottoIdAttacment"
                        name="nottoIdAttacment"
                        label="NOTTO ID Proof"
                        value={bsdDonor.nottoIdAttachment}
                        acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                        schema={Joi.string()}
                        disabled={_isDisabled()}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          const newDonor = {
                            ...bsdDonor,
                            nottoIdAttachment: {
                              ...bsdDonor.nottoIdAttachment,
                              name: file.name,
                              file,
                            },
                          };
                          setBsdDonor({
                            ...bsdDonor,

                            ...newDonor,
                          });
                        }}
                        validation={validation}
                        onValidation={onValidation}
                      />
                    </Grid>

                  */}
                  <Grid item sm={4} xs={10}>
                    <Typography>
                      Dr. Registration number<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      id="drRegnNo"
                      name="drRegnNo"
                      value={eyeDonor.drRegnNo}
                      disabled={_isDisabled()}
                      placeholder="Registration Number"
                      schema={Joi.string()}
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          drRegnNo: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4} xs={10}>
                    <Typography>
                      Death Certificate Number<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      id="deathCertificatNo"
                      name="deathCertificatNo"
                      value={eyeDonor.deathCertificatNo}
                      disabled={_isDisabled()}
                      placeholder="Certificate Number"
                      schema={Joi.string()}
                      fullWidth
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          deathCertificatNo: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={10}>
                    <Typography>
                      Medico Legal<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      id="medicoLegal"
                      name="medicoLegal"
                      options={Object.values(OPTS_DONOR_MEDICOLEGAL)}
                      value={eyeDonor.medicoLegal}
                      disabled={_isDisabled()}
                      placeholder="Medico Legal"
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          medicoLegal: e.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Harvested by<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      id="harvestingPerson"
                      name="harvestingPerson"
                      value={eyeDonor.harvestingPerson}
                      disabled={_isDisabled()}
                      placeholder="Harvested by"
                      schema={Joi.string()}
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          harvestingPerson: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Motivated By<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      id="motivatedBy"
                      name="motivatedBy"
                      options={Object.values(OPTS_DONOR_MOTIVATEDBY)}
                      value={eyeDonor.motivatedBy}
                      disabled={_isDisabled()}
                      placeholder="Motivated By"
                      autosize
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          motivatedBy: e.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <Typography variant="body1">
                      Place of Removal<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      fullWidth
                      id="removalPlace"
                      name="removalPlace"
                      options={Object.values(OPTS_DONOR_REMOVALPLACE)}
                      value={eyeDonor.removalPlace}
                      disabled={_isDisabled()}
                      placeholder="Place of Removal"
                      autosize
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          removalPlace: e.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <Typography>
                      Cause Of Death<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      label="Cause Of Death"
                      value={eyeDonor.causeOfDeath}
                      options={Object.values(OPTS_DONOR_CAUSEOFDEATH)}
                      disabled={_isDisabled()}
                      placeholder="Cause of Death"
                      schema={Joi.string()}
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          causeOfDeath: e.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography variant="body1">
                      Madical / Case History<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      fullWidth
                      label="Madical / Case History"
                      value={eyeDonor.caseHistory}
                      disabled={_isDisabled()}
                      placeholder="Madical / Case History"
                      schema={Joi.string()}
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          caseHistory: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography variant="body1">
                      Type of Tissue<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      fullWidth
                      id="tissueType"
                      name="tissueType"
                      options={Object.values(OPTS_DONOR_TISSUETYPE)}
                      value={eyeDonor.tissueType}
                      disabled={_isDisabled()}
                      placeholder="Type of Tissue"
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          tissueType: e.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography variant="body1">
                      Tissue Number<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      options={Object.values(OPTS_DONOR_TISSUENUMBER)}
                      value={eyeDonor.tissueNumber}
                      disabled={_isDisabled()}
                      placeholder="Tissue Number"
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          tissueNumber: e.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography variant="body1">
                      Type of Preservation<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      fullWidth
                      id="preservationType"
                      name="preservationType"
                      options={Object.values(OPTS_DONOR_TYPEOFPRESERVATION)}
                      value={eyeDonor.preservationType}
                      placeholder="Preservation Type"
                      validation={validation}
                      onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          preservationType: e.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Preservation Media<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      value={eyeDonor.preservationMedia}
                      disabled={eyeDonor.preservationType !== 'OTHERS'}
                      placeholder="Preservation Media"
                      schema={Joi.string()}
                      validation={validation}
                      onValidation={onValidation}
                      validationDisabled={eyeDonor.preservationType !== 'OTHERS'}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          preservationMedia: e.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Lot No RE<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      id="lotNoRe"
                      name="lotNoRe"
                      value={eyeDonor.lotNoRe}
                      disabled={eyeDonor.tissueNumber === 'LEFTEYE'}
                      placeholder="Lot No RE"
                      schema={Joi.string()}
                      //validation={validation}
                      //onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          lotNoRe: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Lot No LE<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      id="lotNoLe"
                      name="lotNoLe"
                      value={eyeDonor.lotNoLe}
                      disabled={eyeDonor.tissueNumber === 'RIGHTEYE'}
                      placeholder="Lot No LE"
                      schema={Joi.string()}
                      //validation={validation}
                      //onValidation={onValidation}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          lotNoLe: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  style={{ marginLeft: 20 }}
                >
                  <GreenTagSectionHeader headerText="Date & Time of Death" />
                </Grid>

                <Grid
                  container
                  spacing={5}
                  style={{ marginLeft: 8, marginRight: 8, marginTop: 10, marginBottom: 20 }}
                >
                  <Grid item sm={4}>
                    <Typography>
                      Date & time of Death<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedDate
                      required
                      value={eyeDonor.deathDateTime}
                      label="Date & time of Death"
                      disabled={_isDisabled()}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          deathDateTime: e,
                        });
                      }}
                      schema={Joi.date().max('now')}
                      fullWidth
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Date & time ENUC / Retreival<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedDate
                      required
                      value={eyeDonor.retrievalDateTime}
                      label="Date & time ENUC / Retreival"
                      disabled={_isDisabled()}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          retrievalDateTime: e,
                        });
                      }}
                      schema={Joi.date().max('now')}
                      fullWidth
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Date & time of In-situ Exicution<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedDate
                      required
                      value={eyeDonor.excisionDateTime}
                      label="Date & time of In-situ Exicution"
                      disabled={_isDisabled()}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          excisionDateTime: e,
                        });
                      }}
                      schema={Joi.date().max('now')}
                      fullWidth
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Date & time of Cornea Preservation<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedDate
                      required
                      value={eyeDonor.corneaPreservationDateTime}
                      label="Date & time of Cornea Preservation"
                      disabled={_isDisabled()}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          corneaPreservationDateTime: e,
                        });
                      }}
                      schema={Joi.date().max('now')}
                      fullWidth
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Death to preservation Time
                      <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      id="deathPreservationTime"
                      name="deathPreservationTime"
                      placeholder="Death to Preservation Time"
                      disabled={_isDisabled(true)}
                      value={'Date'} //{eyeDonor.deathPreservationTime}
                      onValidation={onValidation}
                      validation={validation}
                      fullWidth
                      schema={Joi.string()}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Death to preservation Media
                      <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedInput
                      required
                      id="preservationMediaDeath"
                      name="preservationMediaDeath"
                      placeholder="Death to Preservation Media"
                      disabled={_isDisabled(true)}
                      value={'Date'} //{eyeDonor.preservationMediaDeath}
                      onValidation={onValidation}
                      validation={validation}
                      fullWidth
                      schema={Joi.string()}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Blood Collected<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedSelect
                      required
                      id="bloodCollected"
                      name="bloodCollected"
                      options={Object.values(BLOODCOLLECTED)}
                      value={eyeDonor.bloodCollected}
                      onChange={(e) => {
                        setEyeDonor({
                          ...eyeDonor,
                          bloodCollected: e.value,
                        });
                      }}
                      placeholder="Yes / NO"
                      schema={Joi.string()}
                      // validation={validation}
                      // onValidation={onValidation}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Death Certificate/ Cause of Death Document
                      <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedAttachment
                      id="deathCertificate"
                      label="Death Certificate"
                      value={eyeDonor.deathCertificate}
                      schema={Joi.string()}
                      disabled={_isDisabled()}
                      acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setEyeDonor({
                          ...eyeDonor,
                          deathCertificate: {
                            name: file.name,
                            file,
                          },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Typography>
                      Donor Concent Document<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <ValidatedAttachment
                      id="deathCertificate"
                      label="Death Certificate"
                      value={eyeDonor.consent}
                      schema={Joi.string()}
                      disabled={_isDisabled()}
                      acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setEyeDonor({
                          ...eyeDonor,
                          consent: {
                            name: file.name,
                            file,
                          },
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </>

              <Grid
                container
                spacing={2}
                justifyContent="center"
                style={{ marginTop: 5, marginBottom: 10 }}
              >
                <Grid item>
                  {eyeDonor.formStatus !== 'SUBMITTED' ? (
                    <LoadableButton
                      apiCallId={saveEyeDonorApiId}
                      apiCallStatuses={apiCallStatuses}
                      loadingText="Saving..."
                      onClick={() => {
                        _handleSave();
                      }}
                      style={{
                        fontWeight: 600,
                        fontSize: '16px',
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Save
                    </LoadableButton>
                  ) : null}
                  {'     '}
                  {eyeDonor.formStatus === 'DRAFT' ? (
                    <LoadableButton
                      apiCallId={submitEyeDonorApiId}
                      apiCallStatuses={apiCallStatuses}
                      disabled={!isFormFilled}
                      loadingText="Submitting..."
                      onClick={() => {
                        submitEyeDonor();
                      }}
                      style={{
                        fontWeight: 600,
                        fontSize: '16px',
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Submit
                    </LoadableButton>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </DataLoadingScreen>
        </Paper>
      </Grid>
    );
  };

  return (
    <Box className={classes.bsdBoxStyle} marginLeft="15px" marginRight="15px">
      {redirectToSaved ? <Redirect to={`/eyeDonor/saved/${draftId}`} /> : null}
      <Paper className={classes.paperBsdInfo}>
        <Grid container style={{ marginTop: 20, marginBottom: 10 }}>
          <Grid item>
            <Typography
              variant="h3"
              style={{ fontSize: '16px', marginBottom: '10px', padding: 10 }}
            >
              New Eye Donor
            </Typography>
            <ul>
              <li style={{ fontSize: '14px', marginBottom: '7px' }}>
                Application for registration of new eye donor. It needs to be filled by head of the
                institution.
              </li>
              <li style={{ fontSize: '14px', marginBottom: '15px' }}>
                All ( * ) Fields Are Compulsory
              </li>
            </ul>
          </Grid>
        </Grid>
      </Paper>
      <Box display="flex" flexDirection="row">
        <Grid container>
          <Grid item sm={2}></Grid>
          {RenderEyeDonorUi()}
        </Grid>
      </Box>
    </Box>
  );
}

EyeDonor.propTypes = {
  draftMatch: PropTypes.shape({
    params: PropTypes.shape({
      draftId: PropTypes.string,
    }),
  }),
  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
};
EyeDonor.defaultProps = {
  draftMatch: {
    params: {
      draftId: undefined,
    },
  },
};

export default compose(withRouter, withValidation)(EyeDonor);

/*
Eye Donor Submission
Note:
1. All ( * ) Fields Are Compulsory
2. Photos must be in JPEG/JPG/PNG Format and below 256 kbs
*/
