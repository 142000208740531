import React from 'react';
import Loader from 'react-loader-advanced';
import PropTypes from 'prop-types';
import Spinner from './LoadingSpinner';

const DataLoadingScreen = ({ children, zIndexValue, isLoading }) => {
  return (
    <Loader
      show={isLoading}
      message={<Spinner />}
      backgroundStyle={{ zIndex: zIndexValue || '501' }}
    >
      {children}
    </Loader>
  );
};

DataLoadingScreen.propTypes = {
  zIndexValue: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};
DataLoadingScreen.defaultProps = {
  zIndexValue: '501',
};

export default DataLoadingScreen;
