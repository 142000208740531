export const DISTRICTS_AN = [
  {
    label: 'Nicobar',
    value: 'NICOBAR'
  },
  {
    label: 'North and Middle Andaman',
    value: 'NORTH_AND_MIDDLE_ANDAMAN'
  },
  {
    label: 'South Andaman',
    value: 'SOUTH_ANDAMAN'
  }
];
