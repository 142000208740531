import * as log from 'loglevel';

import 'offline-js';
import './offlinejs.css';

import { API } from 'aws-amplify';
import { API_AYUDAANEXPRESS } from './constants';
import { PATH_EXP_RESOURCE_POSTREACTFEEDBACK } from './appConstants';
import getPlatformInfo from './platformInfo';

window.Offline.options.requests = false;

export const submitReactFeedback = async postBody => {
  const url = PATH_EXP_RESOURCE_POSTREACTFEEDBACK;
  const initParam = postBody
    ? {
        body: postBody
      }
    : undefined;

  try {
    await API.post(API_AYUDAANEXPRESS, url, initParam);
  } catch (err) {
    // SWALLOW
  }
};

const originalFactory = log.methodFactory;
log.methodFactory = function methodFactory(methodName, logLevel, loggerName) {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);

  return function processMessage(message) {
    if (methodName === 'error') {
      const build = process.env.REACT_APP_GIT_SHA;
      const platformInfo = getPlatformInfo();
      let info;
      if (message instanceof Error) {
        info = message.stack;
      } else {
        info = message;
      }
      const errorType = 'Logger Error';
      const postBody = {
        info,
        build,
        platformInfo,
        errorType
      };

      submitReactFeedback(postBody);
    }

    rawMethod(message);
  };
};

if (process.env.NODE_ENV === 'development') {
  log.enableAll();
} else {
  log.enableAll();
}
log.setLevel(log.getLevel());
