import React from 'react';
import Loadable from 'react-loadable';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingComponent } from './components/Custom';
import './App.css';
import { withUserContext } from './newHoc';
import routes from './routes';

function App() {
  const { userContext = {}, isLoggedIn } = useSelector((state) => state);
  const app = useSelector((state) => state);

  const {
    user: { userType },
  } = app;

  if (!isLoggedIn) {
    // return <Login />;
    // return (
    //   <Container>
    //     <BrowserRouter>
    //       <Switch>
    //         <Route path="/university/list" component={UniversityList} />
    //       </Switch>
    //     </BrowserRouter>
    //   </Container>
    // );
  }

  const Container = Loadable({
    loader: () => import('./components/Container'),
    loading: LoadingComponent,
  });
  const { hospitalType } = userContext;

  const userRoutes = routes(hospitalType, userType);
  return (
    <Container>
      <Switch>
        {userRoutes.map((r) => {
          if (r.redirect) {
            return <Redirect to={r.path} />;
          }
          return <Route key={r.path} path={r.path} exact={!!r.exact} component={r.component} />;
        })}
      </Switch>
    </Container>
  );
}

export default withUserContext(App);
