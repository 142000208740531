import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  Grid,
  makeStyles,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Box,
  Paper,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ValidatedAttachment, ValidatedInput, ValidatedSelect } from '../../Custom';
import { FILETYPES } from '../../Custom/ValidatedAttachment';

function MedicalReportsHemodynamics({ donorMedicalReportsDetails, setDonorMedicalReportsDetails }) {
  const app = useSelector((state) => state);
  const {
    user: { userType, userId },
  } = app;

  // onChange Handlers

  const useStyles = makeStyles(() => ({
    paperTransplantInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 20,
      marginBottom: 10,
    },
    gridTransplantDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperTransplantSections: {
      height: '100%',
      marginTop: 5,
    },
    transplantSectionsStyle: {
      fontWeight: 300,
      lineHeight: '15px',
      marginTop: 20,
      marginLeft: 20,
    },
    transplantSectionsHeadingStyle: {
      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    transplantDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#34495E',
      marginTop: '5px',
    },
    form12BoxStyle: {
      direction: 'row',
      // marginTop: 90,
      // ...(_isDisabled() ? { marginTop: 120 } : {}),
    },
  }));

  const classes = useStyles();

  const _renderLabReports = (r, idx) => {
    return (
      <>
        <Grid container spacing={8} style={{ padding: 10 }}>
          <Grid item sm={3} xs={12}>
            <ValidatedAttachment
              required
              id="Lab Report"
              name="Lab Report"
              label="Lab Report"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={r}
              // disabled={disabled && updateFormMode}
              onChange={(e) => {
                const file = e.target.files[0];
                const newReports = [];
                newReports.push(
                  {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                  ...donorMedicalReportsDetails.liverFunction.reports
                );
                newReports.pop();
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    reports: newReports,
                  },
                });
              }}

              // schema={Joi.string()}
              // disabled={disabled && updateFormMode}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {idx >= 0 ? (
            <Grid item sm={2} xs={2}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  const newReports = donorMedicalReportsDetails.liverFunction.reports.filter(
                    (nr, nrIndex) => nrIndex !== idx
                  );
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    liverFunction: {
                      ...donorMedicalReportsDetails.liverFunction,
                      reports: newReports,
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Liver Fuction Test (LFT)
          </Typography>
        </Grid>
        <Grid container style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
          <Grid item sm={12} xs={12}>
            <Box style={{ border: '2px solid #B1E7D3' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Paper
                    style={{
                      backgroundColor: '#F6FFFC',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-around"
                      padding="10px"
                    >
                      <Typography>Parameter</Typography>
                      <Typography>During Admission</Typography>
                      <Typography>At Present</Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item sm={12} xs={12} style={{ margin: 10 }}>
                  <Grid
                    container
                    justify="space-between"
                    direction="row"
                    spacing={5}
                    style={{
                      padding: 10,
                    }}
                  >
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      spacing={5}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item sm={4} style={{ textAlign: 'center', paddingTop: '30px' }} xs={12}>
                        <Typography>Total Bilirubin (mg/dL)</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={
                            donorMedicalReportsDetails?.liverFunction?.totalBilirubinDuringAdmission
                          }
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                totalBilirubinDuringAdmission: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={donorMedicalReportsDetails?.liverFunction?.totalBilirubinAtPresent}
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                totalBilirubinAtPresent: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      spacing={5}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item sm={4} style={{ textAlign: 'center', paddingTop: '30px' }} xs={12}>
                        <Typography>Direct Bilirubin (mg/dL)</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={
                            donorMedicalReportsDetails?.liverFunction
                              ?.directBilirubinDuringAdmission
                          }
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                directBilirubinDuringAdmission: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={
                            donorMedicalReportsDetails?.liverFunction?.directBilirubinAtPresent
                          }
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                directBilirubinAtPresent: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      spacing={5}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item sm={4} style={{ textAlign: 'center', paddingTop: '30px' }} xs={12}>
                        <Typography>Indirect Bilirubin (mg/dL)</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={
                            donorMedicalReportsDetails?.liverFunction
                              ?.indirectBilirubinDuringAdmission
                          }
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                indirectBilirubinDuringAdmission: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={
                            donorMedicalReportsDetails?.liverFunction?.indirectBilirubinAtPresent
                          }
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                indirectBilirubinAtPresent: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      spacing={5}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item sm={4} style={{ textAlign: 'center', paddingTop: '30px' }} xs={12}>
                        <Typography>SGOT (U/L)</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={donorMedicalReportsDetails?.liverFunction?.sgotDuringAdmission}
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                sgotDuringAdmission: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={donorMedicalReportsDetails?.liverFunction?.sgotAtPresent}
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                sgotAtPresent: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      spacing={5}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item sm={4} style={{ textAlign: 'center', paddingTop: '30px' }} xs={12}>
                        <Typography>SGPT (U/L)</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={donorMedicalReportsDetails?.liverFunction?.sgptDuringAdmission}
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                sgptDuringAdmission: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={donorMedicalReportsDetails?.liverFunction?.sgptPresent}
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                sgptPresent: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      spacing={5}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item sm={4} style={{ textAlign: 'center', paddingTop: '30px' }} xs={12}>
                        <Typography>Serum Alkaline Phosphatase (U/L)</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={
                            donorMedicalReportsDetails?.liverFunction
                              ?.alkalinePhosphataseProthrombinDuringAdmission
                          }
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                alkalinePhosphataseProthrombinDuringAdmission: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={
                            donorMedicalReportsDetails?.liverFunction
                              ?.alkalinePhosphataseProthrombinAtPresent
                          }
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                alkalinePhosphataseProthrombinAtPresent: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      spacing={5}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item sm={4} style={{ textAlign: 'center', paddingTop: '30px' }} xs={12}>
                        <Typography>Total Protein (gm/dL)</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={
                            donorMedicalReportsDetails?.liverFunction?.totalProteinDuringAdmission
                          }
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                totalProteinDuringAdmission: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={donorMedicalReportsDetails?.liverFunction?.totalProteinAtPresent}
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                totalProteinAtPresent: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      spacing={5}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item sm={4} style={{ textAlign: 'center', paddingTop: '30px' }} xs={12}>
                        <Typography>Serum Albumin (gm/dL)</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={donorMedicalReportsDetails?.liverFunction?.albuminDuringAdmission}
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                albuminDuringAdmission: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={donorMedicalReportsDetails?.liverFunction?.albuminAtPresent}
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              liverFunction: {
                                ...donorMedicalReportsDetails.liverFunction,
                                albuminAtPresent: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid />
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Total Bilirubin (during admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.totalBilirubinDuringAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  totalBilirubinDuringAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Direct Bilirubin (during admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.directBilirubinDuringAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  directBilirubinDuringAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Indirect Bilirubin (during admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.indirectBilirubinDuringAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  indirectBilirubinDuringAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">SGOT (during admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.sgotDuringAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  sgotDuringAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">SGPT (during admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.sgptDuringAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  sgptDuringAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">S Alkaline Phosphatase (during admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={
              donorMedicalReportsDetails?.liverFunction
                ?.alkalinePhosphataseProthrombinDuringAdmission
            }
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  alkalinePhosphataseProthrombinDuringAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Total Protein (during admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.totalProteinDuringAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  totalProteinDuringAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Serum Albumin (during admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.albuminDuringAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  albuminDuringAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Total Bilirubin (at present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.totalBilirubinAtPresent}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  totalBilirubinAtPresent: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Direct Bilirubin (at present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.directBilirubinAtPresent}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  directBilirubinAtPresent: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Indirect Bilirubin (at present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.indirectBilirubinAtPresent}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  indirectBilirubinAtPresent: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">SGOT (at present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.sgotAtPresent}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  sgotAtPresent: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">SGPT (at present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.sgptPresent}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  sgptPresent: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">S Alkaline Phosphatase (at present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={
              donorMedicalReportsDetails?.liverFunction?.alkalinePhosphataseProthrombinAtPresent
            }
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  alkalinePhosphataseProthrombinAtPresent: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Total Protein (at present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.totalProteinAtPresent}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  totalProteinAtPresent: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Serum Albumin (at present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.albuminAtPresent}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  albuminAtPresent: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Prothrombin Time</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.prothrombinTime}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  prothrombinTime: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">INR</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.inr}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  inr: e.target.value,
                },
              });
            }}
          />
        </Grid>
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Total Protein</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.totalProtein}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  totalProtein: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Serum Albumin</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.albumin}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  albumin: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Serum Globulin</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.globulin}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  globulin: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Albumin/Globulin Ratio</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.albuminGlobulinRatio}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  albuminGlobulinRatio: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">
            Any abnormality of RFT and LFT till that time to be notified
          </Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.rftLft}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  rftLft: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">USG Liver for Fatty Liver or any other abnormlity</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.usgLiver}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  usgLiver: e.target.value,
                },
              });
            }}
          />
        </Grid>
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">USG Kidney for any other abnormality</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.usgKidney}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  usgKidney: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">USG Abdomen and Pelvis for any other abnormality</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.usgAbdomin}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  usgAbdomin: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={8} xs={12} />
        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">USG Pelvis for any other abnormality</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.usgPelvis}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  usgPelvis: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        <Grid item xs={12} sm={4}>
          <Typography variant="body1">Evidence of Sepsis</Typography>
          <ValidatedSelect
            // label="Evidence of Sepsis"
            value={donorMedicalReportsDetails?.liverFunction?.sepsis}
            // disabled={!organ || updateForm || isDisabled}
            options={[
              {
                label: 'CRP (C-reactive Protein)',
                value: 'CRPT',
              },
              {
                label: 'PCT (Procalcitonin)',
                value: 'PCT',
              },
            ]}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  sepsis: v.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Size Liver Span (cm)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.sizeLiverSpan}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  sizeLiverSpan: e.target.value,
                },
              });
            }}
          />
        </Grid>{' '}
        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Liver Ultrasound (LFT)
          </Typography>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Lingula Lobe</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Does the patient have cirrhosis on biopsy?"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.lingularLobe}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    lingularLobe: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={12} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Fat (%)</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Fat"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.fat}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    fat: e.target.value,
                  },
                });
              }}
            >
              <FormControlLabel value="GRADEI" control={<Radio />} label="GRADE I" />
              <FormControlLabel value="GRADEII" control={<Radio />} label="GRADE II" />

              <FormControlLabel value="GRADEIII" control={<Radio />} label="GRADE III" />

              <FormControlLabel value="GRADEIV" control={<Radio />} label="GRADE IV" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>SO2</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="SO2"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.so2}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    so2: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Hemangioma</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Hacmangioma"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.hacmangioma}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    hacmangioma: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>HCC</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="HCC"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.noOneOrMoreHcc}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    noOneOrMoreHcc: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Surface Nodularity</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Surface Nodularity"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.surfaceNodularity}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    surfaceNodularity: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Rounding of Liver Margins</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Rounding of Liver Margins"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.roudingOfLiverMargins}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    roudingOfLiverMargins: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Parenchymal Nodules</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Parenchymal Nodules"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.parenchymalNodules}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    parenchymalNodules: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Altered Echotexture</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Altered Echotexlere"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.alteredEchotexlere}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    alteredEchotexlere: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Volume Redistribution</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Volume Redistribution"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.volumeRedistribution}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    volumeRedistribution: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Caudate Hypertrophy</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Caudate Hypertrophy"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.caudateHypertorphy}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    caudateHypertorphy: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Obvious Cirrhotic Liver</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Obvious Cirrhotic Liver"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.obviousCirrhoticLiver}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    obviousCirrhoticLiver: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">PV Diameter(mm)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.pvDiameter}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  pvDiameter: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1">PV Flow</Typography>
          <ValidatedSelect
            // label="PV Flow"
            value={donorMedicalReportsDetails?.liverFunction?.pvFlow}
            // disabled={!organ || updateForm || isDisabled}
            options={[
              {
                label: 'Centripetal',
                value: 'CENTRIPETAL',
              },
              {
                label: 'Centrifugal',
                value: 'CENTRIFUGAL',
              },
            ]}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  pvFlow: v.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">SV Diameter(mm)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.liverFunction?.svDiameter}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  svDiameter: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Collaterals</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Collaterals"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.collaterals}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    collaterals: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Splenomegaly</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Splenomegaly"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.splenomegaly}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    splenomegaly: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography style={{ color: 'black' }}>Ascites</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="Ascites"
              name="controlled-radio-buttons-group"
              value={donorMedicalReportsDetails?.liverFunction?.ascites}
              onChange={(e) => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    ascites: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Liver Ultrasound Report</Typography>
          <ValidatedAttachment
            required
            label="Attachment"
            acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
            value={donorMedicalReportsDetails?.liverFunction?.liverUltraSoundReport}
            onChange={(e) =>
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                liverFunction: {
                  ...donorMedicalReportsDetails.liverFunction,
                  liverUltraSoundReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item sm={12}>
          <Typography variant="body1">Any Report Attachment</Typography>
        </Grid>
        {donorMedicalReportsDetails?.liverFunction?.reports.map((r, idx) =>
          _renderLabReports(r, idx)
        )}
        <Grid container style={{ marginRight: 10 }}>
          <Grid item style={{ marginLeft: 20 }}>
            <Button
              color="primary"
              variant="contained"
              // disabled={disabled && updateFormMode}
              onClick={() => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  liverFunction: {
                    ...donorMedicalReportsDetails.liverFunction,
                    reports: [
                      ...donorMedicalReportsDetails.liverFunction.reports,
                      {
                        name: '',
                        key: '',
                      },
                    ],
                  },
                });
              }}
            >
              + New Report
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

MedicalReportsHemodynamics.propTypes = {
  donorMedicalReportsDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  setDonorMedicalReportsDetails: PropTypes.func.isRequired,
};
MedicalReportsHemodynamics.defaultProps = {};

export default MedicalReportsHemodynamics;
