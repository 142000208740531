import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  Grid,
  makeStyles,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Box,
  Paper,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ValidatedAttachment, ValidatedInput, ValidatedSelect, ValidatedRadio } from '../../Custom';
import { FILETYPES } from '../../Custom/ValidatedAttachment';

function MedicalReportsHemodynamics({ donorMedicalReportsDetails, setDonorMedicalReportsDetails }) {
  const app = useSelector((state) => state);
  const {
    user: { userType, userId },
  } = app;

  // onChange Handlers

  const useStyles = makeStyles(() => ({
    paperTransplantInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 20,
      marginBottom: 10,
    },
    gridTransplantDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperTransplantSections: {
      height: '100%',
      marginTop: 5,
    },
    transplantSectionsStyle: {
      fontWeight: 300,
      lineHeight: '15px',
      marginTop: 20,
      marginLeft: 20,
    },
    transplantSectionsHeadingStyle: {
      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    transplantDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#34495E',
      marginTop: '5px',
    },
    form12BoxStyle: {
      direction: 'row',
      // marginTop: 90,
      // ...(_isDisabled() ? { marginTop: 120 } : {}),
    },
  }));

  const classes = useStyles();

  const _renderLabReports = (d, idx) => {
    return (
      <>
        <Grid container spacing={8} style={{ padding: 10 }}>
          <Grid item sm={3} xs={12}>
            <ValidatedAttachment
              required
              id="Lab Report"
              name="Lab Report"
              label="Lab Report"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={d}
              // disabled={disabled && updateFormMode}
              onChange={(e) => {
                const file = e.target.files[0];
                const newReports = [];
                newReports.push(
                  {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                  ...donorMedicalReportsDetails.renalFunction.reports
                );
                newReports.pop();
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  renalFunction: {
                    ...donorMedicalReportsDetails.renalFunction,
                    reports: newReports,
                  },
                });
              }}
              // schema={Joi.string()}
              // disabled={disabled && updateFormMode}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {idx >= 0 ? (
            <Grid item sm={2} xs={2}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  const newReports = donorMedicalReportsDetails.renalFunction.reports.filter(
                    (nr, nrIndex) => nrIndex !== idx
                  );
                  setDonorMedicalReportsDetails({
                    ...donorMedicalReportsDetails,
                    renalFunction: {
                      ...donorMedicalReportsDetails.renalFunction,
                      reports: newReports,
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
        <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Renal Function Test (RFT)
          </Typography>
        </Grid>

        <Grid container style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
          <Grid item sm={12} xs={12}>
            <Box style={{ border: '2px solid #B1E7D3' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Paper
                    style={{
                      backgroundColor: '#F6FFFC',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-around"
                      padding="10px"
                    >
                      <Typography />
                      <Typography>During Admission</Typography>
                      <Typography>At Present</Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item sm={12} xs={12} style={{ margin: 10 }}>
                  <Grid
                    container
                    justify="space-between"
                    direction="row"
                    spacing={5}
                    style={{
                      padding: 10,
                    }}
                  >
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      spacing={5}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item sm={4} style={{ textAlign: 'center', paddingTop: '30px' }} xs={12}>
                        <Typography>Creatinine</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={
                            donorMedicalReportsDetails?.renalFunction
                              ?.serumCreatinineDuringAdmission
                          }
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              renalFunction: {
                                ...donorMedicalReportsDetails.renalFunction,
                                serumCreatinineDuringAdmission: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={
                            donorMedicalReportsDetails?.renalFunction?.serumCreatinineAtPresent
                          }
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              renalFunction: {
                                ...donorMedicalReportsDetails.renalFunction,
                                serumCreatinineAtPresent: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      spacing={5}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid item sm={4} style={{ textAlign: 'center', paddingTop: '30px' }} xs={12}>
                        <Typography>Sodium (Na)</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={donorMedicalReportsDetails?.renalFunction?.naDuringAdmission}
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              renalFunction: {
                                ...donorMedicalReportsDetails.renalFunction,
                                naDuringAdmission: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ValidatedInput
                          required
                          fullWidth
                          value={donorMedicalReportsDetails?.renalFunction?.naAtPresent}
                          label=""
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            setDonorMedicalReportsDetails({
                              ...donorMedicalReportsDetails,
                              renalFunction: {
                                ...donorMedicalReportsDetails.renalFunction,
                                naAtPresent: e.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid />
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {/* <Grid item sm={4} xs={12}>
          <Typography variant="body1">Creatinine (During Admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.serumCreatinineDuringAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  serumCreatinineDuringAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Sodium (Na) (during admission)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.naDuringAdmission}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  naDuringAdmission: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Creatinine (At Present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.serumCreatinineAtPresent}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  serumCreatinineAtPresent: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Sodium (Na) (at present)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.naAtPresent}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  naAtPresent: e.target.value,
                },
              });
            }}
          />
        </Grid> */}
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Potassium (K)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.potassium}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  potassium: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Chloride (Cl)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.chloride}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  chloride: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">BUN (Blood Urea Nitrogen)</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.bun}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  bun: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">USG Kidney for any other abnormality</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.usgKidneyForAbnormality}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  usgKidneyForAbnormality: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1">Evidence of Sepsis</Typography>
          <ValidatedSelect
            // label="Evidence of Sepsis"
            value={donorMedicalReportsDetails?.renalFunction?.sepsis}
            // disabled={!organ || updateForm || isDisabled}
            options={[
              {
                label: 'CRP (C-reactive Protein)',
                value: 'CRPT',
              },
              {
                label: 'PCT (Procalcitonin)',
                value: 'PCT',
              },
            ]}
            onChange={(v) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  sepsis: v.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="body1">Kidney Size</Typography>
          <ValidatedInput
            required
            fullWidth
            value={donorMedicalReportsDetails?.renalFunction?.kidneySize}
            label=""
            type="text"
            placeholder=""
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  kidneySize: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <ValidatedRadio
            label="Evidence of RCC"
            name="controlled-radio-buttons-group"
            value={donorMedicalReportsDetails?.renalFunction?.evidenceOfRcc}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  evidenceOfRcc: e.target.value === 'true',
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <ValidatedRadio
            label="Any other cyst"
            name="controlled-radio-buttons-group"
            value={donorMedicalReportsDetails?.renalFunction?.anyOtherCyst}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  anyOtherCyst: e.target.value === 'true',
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={4} style={{ padding: 10 }}>
          <ValidatedRadio
            label="Loss of CMD"
            name="controlled-radio-buttons-group"
            value={donorMedicalReportsDetails?.renalFunction?.lossOfCmd}
            onChange={(e) => {
              setDonorMedicalReportsDetails({
                ...donorMedicalReportsDetails,
                renalFunction: {
                  ...donorMedicalReportsDetails.renalFunction,
                  lossOfCmd: e.target.value === 'true',
                },
              });
            }}
            options={[
              { value: true, label: 'YES' },
              { value: false, label: 'NO' },
            ]}
            // validation={validation}
            // onValidation={onValidation}
          />
        </Grid>
        <Grid item sm={12}>
          <Typography variant="body1">Any Report Attachment</Typography>
        </Grid>
        {donorMedicalReportsDetails?.renalFunction?.reports.map((r, idx) =>
          _renderLabReports(r, idx)
        )}
        <Grid container style={{ marginRight: 10 }}>
          <Grid item style={{ marginLeft: 20 }}>
            <Button
              color="primary"
              variant="contained"
              // disabled={disabled && updateFormMode}
              onClick={() => {
                setDonorMedicalReportsDetails({
                  ...donorMedicalReportsDetails,
                  renalFunction: {
                    ...donorMedicalReportsDetails.renalFunction,
                    reports: [
                      ...donorMedicalReportsDetails.renalFunction.reports,
                      {
                        name: '',
                        key: '',
                      },
                    ],
                  },
                });
              }}
            >
              + New Report
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

MedicalReportsHemodynamics.propTypes = {
  donorMedicalReportsDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  setDonorMedicalReportsDetails: PropTypes.func.isRequired,
};
MedicalReportsHemodynamics.defaultProps = {};

export default MedicalReportsHemodynamics;
