import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export default function CustomNote({ noteText }) {
  return (
    <Typography
      variant="body1"
      style={{
        color: '#DD392E',
      }}
    >
      {noteText}
    </Typography>
  );
}
CustomNote.propTypes = {
  noteText: PropTypes.string.isRequired,
};
