import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { FORMAT_DATE_UNIXTS, FORMAT_DATE_REPORT, FORMAT_DATE_MMMYYYY } from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class AnnexureBFormDocument {
  constructor(data, transplantId) {
    const {
      recipient: {
        transplantDate,
        name: recipientName,
        transplantSurgeon,
        transplantAnaesthetist,
        discharged: recipientDischarged,
        dischargeDate: recipientDischargeDate,
      },
      hospitalName,
      donor: {
        name: donorName,
        retrievingSurgeon,
        retrievingAnaesthetist,
        discharged: donorDischarged,
        dischargeDate: donorDischargedDate,
      },
      relationWithRecipient,
      approvalDate,
      resultOfTransplant,
    } = data;
    this.annexBFormData = {
      month: moment(transplantDate, FORMAT_DATE_UNIXTS).format(FORMAT_DATE_MMMYYYY),
      recipient: {
        transplantDate: moment(transplantDate, FORMAT_DATE_UNIXTS).format(FORMAT_DATE_REPORT),
        name: recipientName,
        transplantSurgeon,
        transplantAnaesthetist,
        discharged: recipientDischarged,
        dischargeDate: moment(recipientDischargeDate, FORMAT_DATE_UNIXTS).format(
          FORMAT_DATE_REPORT
        ),
      },
      hospitalName: hospitalName.toUpperCase(),
      donor: {
        name: donorName,
        retrievingSurgeon,
        retrievingAnaesthetist,
        discharged: donorDischarged,
        dischargeDate: moment(donorDischargedDate, FORMAT_DATE_UNIXTS).format(FORMAT_DATE_REPORT),
      },
      relationWithRecipient,
      approvalDate: moment(approvalDate, FORMAT_DATE_UNIXTS).format(FORMAT_DATE_REPORT),
      resultOfTransplant,
    };
    this.transplantId = transplantId;
  }

  getDocumentDefinition = () => ({
    content: [
      {
        table: {
          widths: [80, '*', 10, '*'],
          body: [
            [
              {
                text: "ANNEXURE 'B'",
                style: 'header',
                colSpan: 4,
              },
              ...new Array(3).fill({}),
            ],
            [
              {
                text: 'DETAIL INFORMATION OF ORGAN TRANSPLANTATION UNDER THE PROVISION OF TRANSPLANTATION OF HUMAN ORGAN ACT 1994',
                style: 'header',
                colSpan: 4,
              },
              ...new Array(3).fill({}),
            ],
            [
              {
                text: 'MONTH',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.month}`,
                style: 'textStyle',
                colSpan: 3,
              },
            ],
            [{ text: '', colSpan: 4 }, ...new Array(3).fill({})],
            [
              {
                text: 'NAME OF HOSPITAL',
                style: 'textStyle',
                colSpan: 2,
              },
              ...new Array(1).fill({}),
              {
                text: ':',
                style: 'textStyle',
              },
              { text: `${this.annexBFormData.hospitalName}`, style: 'textStyle' },
            ],
            [
              {
                text: '1.',
                style: 'textStyle',
              },
              {
                text: 'Name of the Donor',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.donor.name}`,
                style: 'textStyle',
              },
            ],
            [
              {
                text: '2.',
                style: 'textStyle',
              },
              {
                text: 'Name of the Recipient',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.recipient.name}`,
                style: 'textStyle',
              },
            ],
            [
              {
                text: '3.',
                style: 'textStyle',
              },
              {
                text: 'Relationship',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.relationWithRecipient}`,
                style: 'textStyle',
              },
            ],
            [
              {
                text: '4.',
                style: 'textStyle',
              },
              {
                text: 'Date of approval by authorisation committee and date of validity',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.approvalDate}`,
                style: 'textStyle',
              },
            ],
            [
              {
                text: '5.',
                style: 'textStyle',
              },
              {
                text: 'Date of Transplant',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.recipient.transplantDate}`,
                style: 'textStyle',
              },
            ],
            [
              {
                text: '6.',
                style: 'textStyle',
              },
              {
                text: 'Name of the Surgeon who removed the organ',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.donor.retrievingSurgeon}`,
                style: 'textStyle',
              },
            ],
            [
              {
                text: '7.',
                style: 'textStyle',
              },
              {
                text: 'Name of the Anaesthetist who assisted the surgeon for removing the Organ',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.donor.retrievingAnaesthetist}`,
                style: 'textStyle',
              },
            ],
            [
              {
                text: '8.',
                style: 'textStyle',
              },
              {
                text: 'Name of the Surgeon who transplant the organ',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.recipient.transplantSurgeon}`,
                style: 'textStyle',
              },
            ],
            [
              {
                text: '9.',
                style: 'textStyle',
              },
              {
                text: 'Name of the Anaesthetist who assisted the surgeon for Transplantation',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.recipient.transplantAnaesthetist}`,
                style: 'textStyle',
              },
            ],
            [
              {
                text: '10.',
                style: 'textStyle',
              },
              {
                text: 'Result of the Transplant',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                text: `${this.annexBFormData.resultOfTransplant}`,
                style: 'textStyle',
              },
            ],
            [
              {
                text: '11.',
                style: 'textStyle',
              },
              {
                text: 'Any other information like date of discharge, mortality, postmortem, etc.',
                style: 'textStyle',
              },
              {
                text: ':',
                style: 'textStyle',
              },
              {
                style: 'textStyle',
                stack: [
                  {
                    text: `Donor: ${
                      this.annexBFormData.donor.discharged
                        ? this.annexBFormData.donor.dischargeDate
                        : 'Not Discharged'
                    }`,
                  },
                  {
                    text: `Recipient: ${
                      this.annexBFormData.recipient.discharged
                        ? this.annexBFormData.recipient.dischargeDate
                        : 'Not Discharged'
                    }`,
                  },
                ],
              },
            ],
          ],
        },
      },
    ],
    pageOrientation: 'potrait',
    styles: {
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
        margin: [0, 5, 0, 5],
      },
      table: {
        margin: [0, 5, 0, 15],
      },
      textStyle: {
        alignment: 'center',
        margin: [0, 10, 0, 10],
      },
    },
  });

  download(defaultFileName = `${this.transplantId}`) {
    const documentDefinition = this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).download(defaultFileName);
  }
}

export default AnnexureBFormDocument;
