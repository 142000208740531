import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { BLOODGROUP, FORMAT_DATE_UNIXTS, isHospitalIcUserType } from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class SuperUrgentLungRegistrationFormPdf {
  formData = {};

  constructor(formData) {
    const {
      lungDetails,
      basicDetails,
      contactNos,
      contactEmailIds,
      hospitalName,
      ztccZone,
      organs,
    } = formData;

    const _yesNoAndEmptyFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };
    const _viralMrakerRemarkAndDateFormatter = (remark, date) => {
      // if (remark && date) {
        if (remark === 'NOTDONE') {
          return 'NOT DONE';
        }
        return `${remark} - ${date ? moment(date, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY') : '-'}`;
      // }
      return '-';
    };
    const calculateCurrentAge = () => {
      if (basicDetails.dateOfBirth) {
        const duration = moment.duration(
          moment().diff(moment(basicDetails.dateOfBirth, FORMAT_DATE_UNIXTS))
        );
        return `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`;
      }
      if (basicDetails.currentAge) {
        return basicDetails.currentAge;
      }
      return '-';
    };

    const _nationalityFormatter = (value) => {
      if (value === 'INDIAN_NATIONAL') {
        return 'Indian';
      }
      if (value === 'OCI_CARDHOLDER') {
        return 'Overseas Citizen of India (OCI) card holder';
      }
      if (value === 'FOREIGN_NATIONAL') {
        return 'Foreign national';
      }
      return '-';
    };
    const _covidVaccinationFormatter = (value) => {
      if (value === 'FIRST') {
        return `First Dose Completed -  Date: ${
          basicDetails?.covidVaccination?.firstDose?.firstDoseDate
            ? moment(
                basicDetails?.covidVaccination?.firstDose?.firstDoseDate,
                FORMAT_DATE_UNIXTS
              ).format('DD/MM/YYYY')
            : '-'
        }`;
      }
      if (value === 'SECOND') {
        return `Second Dose Completed - First Date: ${
          basicDetails?.covidVaccination?.bothDose?.firstDoseDate
            ? moment(
                basicDetails?.covidVaccination?.bothDose?.firstDoseDate,
                FORMAT_DATE_UNIXTS
              ).format('DD/MM/YYYY')
            : '-'
        }, Second Date: ${
          basicDetails?.covidVaccination?.bothDose?.secondDoseDate
            ? moment(
                basicDetails?.covidVaccination?.bothDose?.secondDoseDate,
                FORMAT_DATE_UNIXTS
              ).format('DD/MM/YYYY')
            : '-'
        }`;
      }
      if (value === 'NOTDONE') {
        return 'Not Done';
      }
      return '-';
    };

    const _yesNoAndEmptyBooleanFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };
    const _getZtccZoneLabelById = (zone) => {
      if (zone === '7') {
        return 'ZTCC MUMBAI';
      }
      if (zone === '8') {
        return 'ZTCC PUNE';
      }
      if (zone === '9') {
        return 'ZTCC NAGPUR';
      }
      if (zone === '10') {
        return 'ZTCC AURANGABAD';
      }
      if (zone) {
        return zone;
      }

      return '';
    };
    this.formData = formData;
    const reportDocDefinition = {
      content: [
        {
          text: '',
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'State Appropriate Authority of Maharashtra',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: 'SUPERURGENT LUNG REGISTRATION',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],

              [
                {
                  text: `ZTCC Zone: ${_getZtccZoneLabelById(ztccZone)}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date of Registration : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.dateOfRegistration
                      ? moment(basicDetails?.dateOfRegistration, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Name:`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.name ? basicDetails?.name : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `NOTTO ID : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.nottoId}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Birth: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.dateOfBirth
                      ? moment(basicDetails?.dateOfBirth, FORMAT_DATE_UNIXTS).format('DD MMMM YYYY')
                      : '-'
                  } `,

                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Age: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${calculateCurrentAge()}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Gender: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.sex ? basicDetails?.sex : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Group: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Weight (Kg): `,
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.weightKg}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in cm):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightCm}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in inches):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightIn ? basicDetails?.heightIn : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${hospitalName || '-'}`, colSpan: 2 },
                {},
              ],

              [
                {
                  text: `Address & Telephone No: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.presentAddress ? basicDetails?.presentAddress : '-'} - ${
                    contactNos.length > 0 ? contactNos[0] : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Patient contact emails:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${contactEmailIds.length > 0 ? contactEmailIds[0] : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Economic Status: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.economicStatus ? basicDetails?.economicStatus : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Occupation',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.occupation}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_nationalityFormatter(basicDetails?.nationality)}`,
                  colSpan: 2,
                },
                {},
              ],
              ...(basicDetails?.nationality !== 'INDIAN_NATIONAL'
                ? [
                    [
                      {
                        text: `Indian Contact Number : `,
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${
                          basicDetails?.indianContactNumber
                            ? basicDetails?.indianContactNumber
                            : '-'
                        }`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              [
                {
                  text: `Country of Residence: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.countryOfResidence ? basicDetails?.countryOfResidence : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              // [
              //   {
              //     text: 'Date of RT PCR',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${
              //       basicDetails.rtpcrdate
              //         ? moment(basicDetails.rtpcrdate, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
              //         : '-'
              //     }`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],
              // [
              //   {
              //     text: 'Is Covid Vaccination Done?',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${_covidVaccinationFormatter(basicDetails?.covidVaccination?.dosesDone)}`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],

              [
                {
                  text: 'UHID',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.uhid}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Present Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.presentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Permanent Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.permanentAddress}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Date of Admission / OPD',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.dateOfAdmission
                      ? moment(basicDetails?.dateOfAdmission, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Primary physician Name:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.primaryPhysicianName}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Primary physician contact numbers:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.primaryPhysicianContactNo
                      ? basicDetails?.primaryPhysicianContactNo
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary physician contact email:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.primaryPhysicianEmail ? basicDetails?.primaryPhysicianEmail : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: 'Covid Details',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 3,
              //     bold: true,
              //   },
              //   {},
              //   {},
              // ],
              // [
              //   {
              //     text: 'Date of RT PCR',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${
              //       basicDetails.rtpcrDate
              //         ? moment(basicDetails.rtpcrDate, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
              //         : '-'
              //     }`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],
              // [
              //   {
              //     text: 'RT PCR Attachment',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${basicDetails?.rtpcrReport?.name ? 'ATTACHED' : '-'}`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],
              // [
              //   {
              //     text: 'Any history of Covid in the past?',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${_yesNoAndEmptyFormatter(basicDetails?.covidHistory)}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: 'If Yes, Date',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${
              //       basicDetails.covidHistoryDate
              //         ? moment(basicDetails.covidHistoryDate, FORMAT_DATE_UNIXTS).format(
              //             'DD/MM/YYYY'
              //           )
              //         : '-'
              //     }`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],
              // [
              //   {
              //     text: 'Is Covid Vaccination Done?',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: `${_covidVaccinationFormatter(basicDetails?.covidVaccination?.dosesDone)}`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],
              [
                {
                  text: 'Primary Questions Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient have active / metastatic malignancy?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMalignancy
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active MDR infection with sepsis or septic shock?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMdrInfection
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have severe irreversible systemic disease (cardiac, pulmonary, neurological or others with very short longevity)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noSevereIrreversibleSystemicDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active or recent major unresolved psychiatric illness?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noMajorUnresolvedPsychiatricIllness
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have high risk of non-compliance with long-term immunosuppression?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noHighRiskOfNonCompliance
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have adequate social or family support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(basicDetails?.prerequisites?.familySupport)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Identity Proof Document Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Document Type',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentType}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identify Proof Document Number',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentNumber}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identity Proof Document Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              // [
              //   {
              //     pageBreak: 'before',
              //     text: 'Family Member Details',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 3,
              //     bold: true,
              //   },

              //   {
              //     text: ``,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
            ],
          },
        },
        {
          text: 'Family Member Details',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Family Members Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Relation With Patient', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Number', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Email', style: 'tableHeader', alignment: 'left' },
              ],
              ...basicDetails.familyMembers.map((a) => [
                { text: `${a.name ? a.name : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.relation ? a.relation : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.contact ? a.contact : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.email ? a.email : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
          // layout: 'lightHorizontalLines',
        },
        {
          text: 'General Information',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Requested Organs', style: 'tableHeader', alignment: 'left' },
                { text: 'Requested Organs Listing Type', style: 'tableHeader', alignment: 'left' },
              ],
              ...organs.map((o) => [
                {
                  text: `${o.organType ? o.organType : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                { text: `${o.category ? o.category : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',

              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'General Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'Is Patient Willing to Accept ECD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'ECD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Is Patient Willing to Accept DCD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'DCD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Has the patient been registered in Maharashtra in Past:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.pastZtccListingInfo?.pastZtccListing
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Status',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastListingStatus}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Further Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastZtccDetails}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'SUPERURGENT LUNGS FORM DETAILS',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'Patient Admission File',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.patientAdmissionfile?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Treating Doctor Clinical Summary report',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.treatingDoctorClinicalSummaryReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: '2D Echo Report',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.echo2dReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'ABG Report',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.abgReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'PFT Report',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.pftReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'HRCT Report',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.hrctReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have the End Stage Lung Disease? ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `
                  ${_yesNoAndEmptyFormatter(
                    lungDetails?.endStageLungDisease
                  )}`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Inotrope Details ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${lungDetails?.inotropeDetails ? lungDetails?.inotropeDetails : '-'}`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'If positive, provide details ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.inotropePositiveDetails
                      ? lungDetails?.inotropePositiveDetails
                      : '-'
                  }`,

                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have the ECMO Support? ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    lungDetails?.ecmoSupport 
                  )}`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Types of ECMO ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${lungDetails?.typesOfEcmo ? lungDetails?.typesOfEcmo : '-'}`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Date of ECMO Initiated ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.dateOfEcmo
                      ? moment(lungDetails?.dateOfEcmo, FORMAT_DATE_UNIXTS).format('DD MMMM YYYY')
                      : '-'
                  }`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'ECMO Blood Flow Rate(L/Min) ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${lungDetails?.irv ? lungDetails?.irv : '-'}`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'ECMO RPM ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.ecmoRpm ? lungDetails?.ecmoRpm : '-'}`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'ECMO Cannulation Site ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.ecmoCannulation ? lungDetails?.ecmoCannulation : '-'}`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'ECMO Weaning ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    lungDetails?.ecmoWeaning 
                  )}`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'ECMO Complication ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.ecmoComplication ? lungDetails?.ecmoComplication : '-'}`,

                  colSpan: 2,
                },
                {},
              ],

              // [
              //   {
              //     text: 'COVID status (RT PCR)',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       covidVaccination?.firstDose?.firstDoseDate
              //         ? covidVaccination?.firstDose?.firstDoseDate
              //         : '-'
              //     } -
              //   ${
              //     covidVaccination?.bothDose?.firstDoseDate
              //       ? covidVaccination?.bothDose?.firstDoseDate
              //       : '-'
              //   } -
              //   ${
              //     covidVaccination?.bothDose?.secondDoseDate
              //       ? covidVaccination?.bothDose?.secondDoseDate
              //       : '-'
              //   }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: 'Date of RT PCR',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       superUrgentInfo?.covid?.dateOfRtpcr ? superUrgentInfo?.covid?.dateOfRtpcr : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: 'Is the patient is NOVA lung? ',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       superUrgentInfo?.covid?.novaLung ? superUrgentInfo?.covid?.novaLung : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: 'Is Covid Vaccination Done?',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       covidVaccination?.firstDose?.firstDoseDate
              //         ? covidVaccination?.firstDose?.firstDoseDate
              //         : '-'
              //     } -
              //     ${
              //       covidVaccination?.bothDose?.firstDoseDate
              //         ? covidVaccination?.bothDose?.firstDoseDate
              //         : '-'
              //     } -
              //     ${
              //       covidVaccination?.bothDose?.secondDoseDate
              //         ? covidVaccination?.bothDose?.secondDoseDate
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: ' Does the patient with respiratory failure dependent on Mechanical Ventilation (not weanable)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `
                  ${_yesNoAndEmptyBooleanFormatter(
                    lungDetails?.respiratoryFialureDependent?.dependentOnMechanicalVentilation
                  )}
                  
                  `,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Duration on Ventilators(in Days)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.respiratoryFialureDependent?.durationOnVentilator
                      ? lungDetails?.respiratoryFialureDependent?.durationOnVentilator
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Is a patient ready for weaning off ventilation support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `
                  ${_yesNoAndEmptyFormatter(
                    lungDetails?.respiratoryFialureDependent?.ventilationSupport                     
                  )}`,

                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Ventilator Setting',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.respiratoryFialureDependent?.ventilatorSetting
                      ? lungDetails?.respiratoryFialureDependent?.ventilatorSetting
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ' Lung Complication Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.respiratoryFialureDependent?.lungComplicationDetails
                      ? lungDetails?.respiratoryFialureDependent?.lungComplicationDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Tidal Volume(TV)(in mL): ${
              //       superUrgentInfo?.bottom?.tidalVolume
              //         ? superUrgentInfo?.bottom?.tidalVolume
              //         : '-'
              //     }`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       superUrgentInfo?.bottom?.tidalVolume
              //         ? superUrgentInfo?.bottom?.tidalVolume
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: 'Inspiratory Reserve Volume(IRV)(in mL)',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       superUrgentInfo?.bottom?.inspiratoryReserveVolume
              //         ? superUrgentInfo?.bottom?.inspiratoryReserveVolume
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: 'Expiratory Reserve Volume(ERV)(in mL)',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       superUrgentInfo?.bottom?.expiratoryReserveVolume
              //         ? superUrgentInfo?.bottom?.expiratoryReserveVolume
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: 'Residual Volume(RV)(in mL)',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       superUrgentInfo?.bottom?.residualVolume
              //         ? superUrgentInfo?.bottom?.residualVolume
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: 'Total Lung Capacity(in mL)',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       superUrgentInfo?.bottom?.totalLungCapacity
              //         ? superUrgentInfo?.bottom?.totalLungCapacity
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: 'Viral Marker Status',
                  style: 'tableHeader',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'HBsAg',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.viralMarkerStatus?.hbsagRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HBsAg Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.viralMarkerStatus?.hbsagReportDate
                      ? moment(
                          lungDetails?.viralMarkerStatus?.hbsagReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HBsAg Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.viralMarkerStatus?.hbsagReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Anti HCV',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.viralMarkerStatus?.antiHcvRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Anti HCV Report Date `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.viralMarkerStatus?.antiHcvReportDate
                      ? moment(
                          lungDetails?.viralMarkerStatus?.antiHcvReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Anti HCV Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.viralMarkerStatus?.antiHcvReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'HCV RNA Quantitative',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HCV RNA Quantitative Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                          ? moment(
                              lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: `HCV RNA Quantitative Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeReport?.name
                          ? 'ATTACHED'
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: 'HCV DNA',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.viralMarkerStatus?.hcvDnaRemark}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HCV DNA Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                lungDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        lungDetails?.viralMarkerStatus?.hcvDnaReportDate
                          ? moment(
                              lungDetails?.viralMarkerStatus?.hcvDnaReportDate,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: `HCV DNA Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                lungDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                  ? {
                      text: `-`,
                      colSpan: 2,
                    }
                  : {
                      text: `${
                        lungDetails?.viralMarkerStatus?.hcvDnaReport?.name ? 'ATTACHED' : '-'
                      }`,
                      colSpan: 2,
                    },
                {},
              ],

              [
                {
                  text: 'HIV',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.viralMarkerStatus?.hivRemark}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HIV Report Date: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.viralMarkerStatus?.hivReportDate
                      ? moment(
                          lungDetails?.viralMarkerStatus?.hivReportDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HIV Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.viralMarkerStatus?.hivReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Any Other Complications',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    lungDetails?.otherComplications ? lungDetails?.otherComplications : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Etiology of Lung Failure', style: 'tableHeader', alignment: 'left' },
                { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...lungDetails.etiologies.map((e) => [
                {
                  text: `${e.etiology ? e.etiology : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: ['auto', 'auto', 'auto', 'auto'],
            body: [
              [
                {
                  text: 'Etiology Details ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${lungDetails?.etiologyDetails ? lungDetails?.etiologyDetails : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },
        {
          text: 'Any Other Attachments',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...lungDetails?.otherAttachments?.map((e) => [
                {
                  text: `${e.attachmentName ? e.attachmentName : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*'],
            body: [
              [
                { text: 'intensivist Team', style: 'tableHeader', alignment: 'left' },

                { text: 'Pulmonologist Team', style: 'tableHeader', alignment: 'left' },

                { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
              ],
              [
                (lungDetails?.intensivistTeam || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
                (lungDetails?.pulmonologistTeam || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
                (lungDetails?.transplantSurgeon || [])?.map((e) => [
                  {
                    text: `${e.user.name ? e.user.name : '-'}`,
                    style: 'tableCell',
                    alignment: 'left',
                  },
                ]),
              ],
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150],
            heights: [0, 10, 20, 20, 20, 20, 20],
            body: [
              [
                {
                  text: 'All verification of the clinical details / reports / criteria eligibility to justify above criteria is the responsibility of the transplant team signing this form. \n1. All the reports (Lab reports + imaging report) should be within 1 month at the time of registration. The patients with older reports should not be considered for registration.\n2. All lab reports should be within 1 month and imaging should be within 3 months for allocation, patients with older reports should not be considered for allocation.',
                  style: 'tableHeader',
                  colSpan: 3,
                  // alignment: 'center',
                  // bold: true,
                  fontSize: 11,
                },
                {},
                {},
              ],
              [
                { text: '', style: 'tableHeader' },
                { text: 'Name', style: 'tableHeader' },
                { text: 'Signature', style: 'tableHeader' },
              ],
              ['Patient/Relative', '', ''],
              ['Transplant Surgeon', '', ''],
              ['Transplant pulmonologist', '', ''],
              ['Intensivist', '', ''],
              ['Hospital Head', '', ''],
              ['Transplant Co-ordinator', '', ''],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Super Urgent Lung Registration Form`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default SuperUrgentLungRegistrationFormPdf;
