import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import {
  WhatsappShareButton,
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  TumblrShareButton,
  TumblrIcon,
} from 'react-share';
import cloneDeep from 'lodash.clonedeep';
import { useSnackbar } from 'notistack';
import LoadableButton from '../Custom/LoadableButton';
import { useCancellableApiCall } from '../../hooks';
import { ReactComponent as HeartSvg } from '../svgs/Heart.svg';
import { ReactComponent as KidneySvg } from '../svgs/Kidney.svg';
import { ReactComponent as IntestineSvg } from '../svgs/Intestine.svg';
import { ReactComponent as HandSvg } from '../svgs/Hand.svg';
import { ReactComponent as SkinSvg } from '../svgs/Skin.svg';
import { ReactComponent as LiverSvg } from '../svgs/Liver.svg';
import { ReactComponent as LungsSvg } from '../svgs/Lungs.svg';
import { ReactComponent as HeartLungsSvg } from '../svgs/HeartLungs.svg';
import { ReactComponent as PancreasSvg } from '../svgs/Pancreas.svg';
import { ReactComponent as BoneSvg } from '../svgs/Bone.svg';
import { ReactComponent as CorneaSvg } from '../svgs/Cornea.svg';
import arogyaLogo from './arogyalogo_min.png';
import rottoLogo from './rottologo_min.jpg';
import maharashtraLogo from './maharashtraemblem_min.png';
import DonorCard from './donorCardDocDefn';

import { DonorPledgePdf } from '../Reports/DocDefinition';
import organdonation from './organdonation.jpg';
import profilepic from './profilepic.png';
import {
  PATH_PUBLIC_GETDONORPROFILE,
  PATH_OTP_REQUESTOTP,
  PATH_PUBLIC_DOWNLOADDONORCARD,
} from '../../appConstants';

const EMPTY_OTPDATA = {
  contact: '',
  hash: '',
  otp: '',
};
const EMPTY_DONORPLEDGE = {
  organs: [],
  donorDetails: {
    firstName: '',
    middleName: '',
    lastName: '',
    telephone: '',
    email: '',
    photo: '',
  },
  form7Id: '',
};

export default function DonorProfile() {
  const [donorPledgeDetails, setDonorPledgeDetails] = useState(cloneDeep(EMPTY_DONORPLEDGE));
  const { enqueueSnackbar } = useSnackbar();
  const [getOtpDialog, setGetOtpDialog] = useState(false);
  const [otpData, setOtpData] = useState(cloneDeep(EMPTY_OTPDATA));
  const DOWNLOADINGFILES = {
    DONORCARD: 'DONORCARD',
    FORM7: 'FORM7',
  };
  const [downloadingFile, setDownloadingFile] = useState('');
  const { organs, donorDetails } = donorPledgeDetails;

  const { apiGet, apiCallStatuses } = useCancellableApiCall();

  useEffect(() => {
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    const donorPledgeId = params.get('form7Id');
    if (donorPledgeId) {
      apiGet(
        PATH_PUBLIC_GETDONORPROFILE,
        {
          form7Id: donorPledgeId,
        },
        { unAuthenticated: true }
      )
        .then((donor) => {
          if (!donor) {
            enqueueSnackbar('Details not found!', { variant: 'error' });
            return;
          }
          setDonorPledgeDetails(donor);
        })
        .catch(() => {});
    }
  }, [apiGet, enqueueSnackbar]);

  const [getOtpApiId, setGetOtpApiId] = useState('APIID');
  const getOtp = () => {
    return apiGet(
      PATH_OTP_REQUESTOTP,
      { mobileNo: donorDetails.telephone },
      {
        unAuthenticated: true,
        apiIdCb: (apiId) => {
          setGetOtpApiId(apiId);
        },
      }
    )
      .then((fullHash) => {
        setOtpData({
          ...otpData,
          hash: fullHash,
          contact: donorDetails.telephone,
        });
        setGetOtpDialog(true);
      })
      .catch(() => {});
  };

  const [getForm7ApiId, setGetForm7ApiId] = useState('APIID');
  const getForm7Data = () => {
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    const donorPledgeId = params.get('form7Id');
    if (!donorPledgeId) {
      return;
    }
    apiGet(
      PATH_PUBLIC_DOWNLOADDONORCARD,
      { form7Id: donorPledgeId, otpData },
      {
        unAuthenticated: true,
        apiIdCb: (apiId) => {
          setGetForm7ApiId(apiId);
        },
      }
    )
      .then((res) => {
        setGetOtpDialog(false);
        if (downloadingFile === DOWNLOADINGFILES.DONORCARD) {
          new DonorCard(res).getPdf().download();
        } else if (downloadingFile === DOWNLOADINGFILES.FORM7) {
          new DonorPledgePdf(res).docDefinition().download();
        }
        setOtpData(cloneDeep(EMPTY_OTPDATA));
      })
      .catch(() => {});
  };

  const _renderGetOtpDialog = () => {
    return (
      <Dialog
        open={getOtpDialog}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => {
          setGetOtpDialog(false);
          setOtpData(cloneDeep(EMPTY_OTPDATA));
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">OTP</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <TextField
                required
                id="otp"
                name="otp"
                label="OTP received on donor mobile"
                helperText="Please enter OTP to download DonorCard"
                fullWidth
                value={otpData.otp}
                onChange={(e) => {
                  setOtpData({
                    ...otpData,
                    otp: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ marginTop: 40 }}>
          <LoadableButton
            apiCallId={getForm7ApiId}
            apiCallStatuses={apiCallStatuses}
            onClick={() => setGetOtpDialog(false)}
            disabledButton
          >
            Cancel
          </LoadableButton>
          <LoadableButton
            apiCallId={getForm7ApiId}
            apiCallStatuses={apiCallStatuses}
            loadingText="Submitting..."
            onClick={() => {
              getForm7Data();
            }}
          >
            Submit
          </LoadableButton>
        </DialogActions>
      </Dialog>
    );
  };

  const _renderSvg = (o) => {
    let svg;
    switch (o) {
      case 'HEART':
        svg = <HeartSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'KIDNEY':
        svg = <KidneySvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'LIVER':
        svg = <LiverSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'LUNGS':
        svg = <LungsSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'HEARTLUNGS':
        svg = <HeartLungsSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'PANCREA':
        svg = <PancreasSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'HAND':
        svg = <HandSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'INTESTINE':
        svg = <IntestineSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'CORNEA':
        svg = <CorneaSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'SKIN':
        svg = <SkinSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'BONES':
        svg = <BoneSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'HEARTVALVES':
        svg = <IntestineSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      case 'BLOODVESSELS':
        svg = <IntestineSvg width={30} height={30} style={{ marginRight: 10 }} />;
        break;

      default:
        break;
    }
    return svg;
  };

  const _renderDonorPic = () => {
    if (donorDetails?.photo) {
      return <img src={`data:image/*;base64,${donorDetails?.photo}`} alt="donor" height={125} />;
    }
    return <img src={profilepic} alt="donor" height={125} />;
  };
  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid container item xs={12} justify="space-around" alignItems="center">
        <img src={arogyaLogo} alt="arogyabhawan" height={75} />
        <img src={maharashtraLogo} alt="maharashtragovt" height={75} />
        <img src={rottoLogo} alt="rottosotto" height={60} />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} sm={5} style={{ backgroundImage: `url(${organdonation})` }} />
        <Grid container item xs={12} sm={7}>
          <Grid item xs={12} style={{ padding: 50, paddingTop: 40 }}>
            <Typography variant="h4" style={{ textAlign: 'center', fontWeight: 'bolder' }}>
              I AM A PROUD ORGAN DONOR
            </Typography>
            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
              The only gift is a portion of thyself
            </Typography>
            <Grid container item xs={12} style={{ marginTop: 40, marginBottom: 40 }}>
              <Grid item xs={12} sm={8}>
                <Typography variant="h6">
                  I, <strong>{donorDetails?.firstName}</strong>, hereby pledge to donate the
                  following Organ(s) and/or Tissues from my body for Therapeutic purpose after my
                  death(Brainstem / Cardiac).
                </Typography>
              </Grid>
              <Grid container item justify="center" xs={12} sm={4}>
                {_renderDonorPic()}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {organs.map((organ) => {
                return (
                  <Grid container item xs={12} sm={4} key={organ} style={{ padding: 5 }}>
                    {_renderSvg(organ)}
                    <Typography
                      variant="h6"
                      style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                    >
                      {organ}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 40 }} justify="center">
              <ButtonGroup>
                <LoadableButton
                  apiCallId={getOtpApiId}
                  apiCallStatuses={apiCallStatuses}
                  loadingText="Requesting..."
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    setDownloadingFile(DOWNLOADINGFILES.DONORCARD);
                    getOtp();
                  }}
                >
                  Download Donor Card
                </LoadableButton>
                <LoadableButton
                  apiCallId={getOtpApiId}
                  apiCallStatuses={apiCallStatuses}
                  loadingText="Requesting..."
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setDownloadingFile(DOWNLOADINGFILES.FORM7);
                    getOtp();
                  }}
                >
                  Download Pledge Form
                </LoadableButton>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: 40, alignSelf: 'flex-end' }}>
            <Grid item xs={12} container justify="center">
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Share your profile
              </Typography>
            </Grid>
            <Grid container item xs={12} justify="center">
              <Grid item>
                <FacebookShareButton
                  url={window.location.href}
                  quote="Join my pledge to donate organ and live on after death!"
                >
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
              </Grid>
              <Grid item>
                <TwitterShareButton
                  url={window.location.href}
                  title="Join my pledge to donate organ and live on after death!"
                  beforeOnClick={() => {}}
                >
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
              </Grid>
              <Grid item>
                <WhatsappShareButton
                  url={window.location.href}
                  title="Join my pledge to donate organ and live on after death!"
                  beforeOnClick={() => {}}
                >
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
              </Grid>
              <Grid item>
                <TumblrShareButton
                  url={window.location.href}
                  title="Join my pledge to donate organ and live on after death!"
                  tags={['organdonation']}
                  beforeOnClick={() => {}}
                >
                  <TumblrIcon size={40} round={true} />
                </TumblrShareButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {_renderGetOtpDialog()}
    </Grid>
  );
}
