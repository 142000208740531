import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { VALIDATION } from '../constants';

const DEFAULT_VALID = true;
let valid = DEFAULT_VALID;

const ComponentWithValidation = ({ wrappedComponent: WrappedComponent, ...props }) => {
  const [state, setState] = useState({
    validation: VALIDATION.OFF,
    cb: () => 'validateSTUB',
    sticky: true
  });

  useEffect(
    () => {
      // useEffect is always called after the
      // render phase of the component
      // Therefore all components' onValidation are executed
      // and the 'valid' value finalized
      // by the time the code gets inside this
      state.cb(valid);
      if (!state.sticky && state.validation !== VALIDATION.OFF) {
        setState({
          validation: VALIDATION.OFF,
          cb: () => 'validateSTUB',
          sticky: state.sticky
        });
      }
    },
    [state]
  );

  function _onValidation(v) {
    valid = valid && v;
  }

  const _validate = useCallback(
    (validationMode = VALIDATION.ALL, callback = () => 'validateSTUB', isSticky = true) => {
      valid = true;
      setState({
        validation: validationMode,
        cb: callback,
        sticky: isSticky
      });
    },
    [setState]
  );

  const _disableValidation = useCallback(
    () => {
      setState({
        validation: VALIDATION.OFF,
        cb: () => 'validateSTUB',
        sticky: true
      });
    },
    [setState]
  );

  return (
    <WrappedComponent
      validation={state.validation}
      validate={_validate}
      onValidation={_onValidation}
      disableValidation={_disableValidation}
      {...props}
    />
  );
};
ComponentWithValidation.propTypes = {
  wrappedComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ]).isRequired
};

export default function withValidation(WrappedComponent) {
  function FnComponentWithValidation(props) {
    return <ComponentWithValidation wrappedComponent={WrappedComponent} {...props} />;
  }

  return FnComponentWithValidation;
}
