export const DISTRICTS_MH = [
  { label: 'Ahmednagar', value: 'AHMEDNAGAR' },
  { label: 'Akola', value: 'AKOLA' },
  { label: 'Amravati', value: 'AMRAVATI' },
  { label: 'Aurangabad', value: 'AURANGABAD' },
  { label: 'Beed', value: 'BEED' },
  { label: 'Bhandara', value: 'BHANDARA' },
  { label: 'Buldhana', value: 'BULDHANA' },
  { label: 'Chandrapur', value: 'CHANDRAPUR' },
  { label: 'Dhule', value: 'DHULE' },
  { label: 'Gadchiroli', value: 'GADCHIROLI' },
  { label: 'Gondia', value: 'GONDIA' },
  { label: 'Hingoli', value: 'HINGOLI' },
  { label: 'Jalgaon', value: 'JALGAON' },
  { label: 'Jalna', value: 'JALNA' },
  { label: 'Kolhapur', value: 'KOLHAPUR' },
  { label: 'Latur', value: 'LATUR' },
  { label: 'Mumbai City', value: 'MUMBAI_CITY' },
  { label: 'Mumbai Suburban', value: 'MUMBAI_SUBURBAN' },
  { label: 'Nagpur', value: 'NAGPUR' },
  { label: 'Nanded', value: 'NANDED' },
  { label: 'Nandurbar', value: 'NANDURBAR' },
  { label: 'Nashik', value: 'NASHIK' },
  { label: 'Osmanabad', value: 'OSMANABAD' },
  { label: 'Palghar', value: 'PALGHAR' },
  { label: 'Parbhani', value: 'PARBHANI' },
  { label: 'Pune', value: 'PUNE' },
  { label: 'Raigad', value: 'RAIGAD' },
  { label: 'Ratnagiri', value: 'RATNAGIRI' },
  { label: 'Sangli', value: 'SANGLI' },
  { label: 'Satara', value: 'SATARA' },
  { label: 'Sindhudurg', value: 'SINDHUDURG' },
  { label: 'Solapur', value: 'SOLAPUR' },
  { label: 'Thane', value: 'THANE' },
  { label: 'Wardha', value: 'WARDHA' },
  { label: 'Washim', value: 'WASHIM' },
  { label: 'Yavatmal', value: 'YAVATMAL' },
];
