import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class AllocationRecipientEmptyFormPdf {
  constructor(selectedOrgan) {
    const organ = selectedOrgan.map((e) => {
      return e.selected;
    });
    const organs = organ.flat();
    this.organs = organs;

    const reportDocDefinition = {
      content: [
        {
          text: '',
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            // heights: [
            //   10,
            //   20,
            //   'auto',
            //   'auto',
            //   'auto',
            //   'auto',
            //   'auto',
            //   'auto',
            //   10,
            //   'auto',
            //   'auto',
            //   'auto',
            //   40,
            //   40,
            //   'auto',
            //   'auto',
            //   40,
            //   40,
            //   'auto',
            // ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                  fontSize: 12,
                },
                {},
                {},
              ],
              [
                {
                  text: 'State Appropriate Authority of Maharashtra',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                  fontSize: 12,
                },
                {},
                {},
              ],
              [
                {
                  text: 'RECIPIENT TRANSPLANT REGISTRATION FORM',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                  fontSize: 12,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date: ${moment().format('DD MMMM YYYY')}`,
                  style: 'textStyle',
                  alignment: 'left',
                  colSpan: 3,
                },
                {
                  text: `Registration Date: `,
                  style: 'textStyle',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: `ZTCC Zone: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `NOTTO ID : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date of Birth: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ` DD/MM/YYYY`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Age:  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Gender: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `Female/Male/Other `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Weight (kg): `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (cm): `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `Blood Group: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `O+ve/O-ve/A+ve/A-ve/B+ve/B-ve/AB+ve/AB-ve`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `Date of Admission:`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `DD/MM/YYYY`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `Patient UHID:`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `Indian National/Overseas Citizen of India (OCI) card holder/Foreign National`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `Country of Residence`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `Present Address:\n\n `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `Permanent Adress:\n\n `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              // [
              //   {
              //     text: `Address & Telephone No`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },
              //   {
              //     text: ``,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 2,
              //   },
              // ],
              [
                {
                  text: `Economic Status: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `APL/BPL`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `Occupation: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Patient contact numbers:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Patient contact email:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Primary physician contact numbers:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Primary physician contact email:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Does the patient have active / metastatic malignancy?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `  o YES      o NO`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active MDR infection with sepsis or septic shock?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `  o YES      o NO`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have severe irreversible systemic disease (cardiac, pulmonary, neurological or others with very short longevity)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `  o YES      o NO`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active or recent major unresolved psychiatric illness?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `  o YES      o NO`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have high risk of non-compliance with long-term immunosuppression?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `  o YES      o NO`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have adequate social or family support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `  o YES      o NO`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Identity Proof Document Type',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `PAN Card/Driving Licence/Aadhar/Bank Passbook/Ration Card/Voter Id/Birth Certificate`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },

                {},
              ],
              [
                {
                  text: 'Identity Proof Document Number',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },

                {},
              ],

              [
                {
                  text: 'Family Member Name',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `" "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },

                {},
              ],
              [
                {
                  text: 'Relation With Patient',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `Father/Mother/Brother/Sister/Aunty/Uncle/Son/Daughter/Nephew/Niece/Husband/Wife/Grand-Father/Grand-Mother/Grand-son/Grand-daughter/Father-inlaw/Mother-inlaw/Spouse/Parent/Cousin`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },

                {},
              ],
              [
                {
                  text: 'Family Contact Number',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `"  "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },

                {},
              ],
              [
                {
                  text: 'Family Email Id',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `"  "`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },

                {},
              ],
              [
                {
                  text: 'Requested Organs:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `Heart/Pancreas/Kidney/Lungs/Hands/Liver/Intestine`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Requested Organs Listing Type:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `Super Urgent/Semi Urgent/Routine`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Is Patient Willing to Accept ECD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `  o YES      o NO`,
                  style: 'tableHeader',
                  alignment: 'justify',
                  colSpan: 2,
                  // type: 'circle',
                  // ul: [{ text: 'YES' }, { text: 'NO' }],
                },
              ],
              [
                {
                  text: 'Is Patient Willing to Accept DCD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `  o YES      o NO`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Has the patient been registered in Maharashtra in Past:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `  o YES      o NO`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Date of RT PCR',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `DD/MM/YYYY`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Is Covid Vaccination Done?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `  o YES      o NO      o NOT DONE`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
            ],
          },
        },
        // ..._renderOrganWiseDetails(),
        organs.includes('HEART_SUPERURGENT')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                // heights: [
                //   10,
                //   20,
                //   'auto',
                //   'auto',
                //   'auto',
                //   'auto',
                //   10,
                //   'auto',
                //   'auto',
                //   'auto',
                //   40,
                //   40,
                //   'auto',
                //   'auto',
                //   40,
                //   40,
                //   'auto',
                // ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'SUPER URGENT HEART REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: `Date: ${moment().format('DD MMMM YYYY')}`,
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 3,
                    },
                    {
                      text: `Registration Date: `,
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 3,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Patient Admission File',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Treating Doctor Clinical Summary Report ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: '2D Echo Details:',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `"  "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: '2D Echo Report:',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Severe Cardiac Cachexia: Nephropathy, Neuropathy etc?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Diabetes with end organ diseases?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baselin GFR (ml/min)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `"  "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baseline serum creatinine (mg/dl)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe cerebrovascular disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Sereve Obesity BMI',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe primary pulmonary disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Pulmonary Hypertension PASP > 50 mmHg unresponsive to vacodilator challenge?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,

                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,
        organs.includes('HEART_SUPERURGENT')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  20,
                  'auto',
                  'auto',
                  10,
                  'auto',
                  10,
                  'auto',
                  'auto',
                  10,
                  20,
                  'auto',
                  'auto',
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'Super Urgent ',
                      pageBreak: 'before',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Recipient requires mechanical circulatory support (less than 30 days)? \n a)IABP (Infra-Aortic Ballon Pump) \n b)ECMO (Extra Corporeal Membrane Oxygenation) \n c)LVAD / RVAD / Bi-VAD (Left Ventricular Assist Device or Right Ventricular Assist Device or Bi Ventricular Assist Device)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of MCS Initiated',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Cannulae Sites',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Recipient requires mechanical circulatory support ( for more than 30 days) with device related complications? \n a)IABP (Infra-Aortic Ballon Pump) \n b)ECMO (Extra Corporeal Membrane Oxygenation) \n c)LVAD / RVAD / Bi-VAD (Left Ventricular Assist Device or Right Ventricular Assist Device or Bi Ventricular Assist Device)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of MCS Initiated',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Cannulae Sites',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the Recipient require mechanical ventilation with high ionotropic support?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Ventilation',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the Recipient have any evidence of Sepsis?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Sepsis Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the Recipient have any evidence of Culture?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Culture Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Inotropes details with doses(mcg/kg/min)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Reports Coagulation Profile',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Other Reports: \n Reports Kidney Functional Test \n Reports Liver Functional Test \n Infection Profile Report ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,

                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Etiology of Heart Failure',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `Dilated Cardiomyopathy/Ischemic Cardiomyopathy/Severe Left Ventricular Failure/Severe Heart Failure Global hypokinesia/
                    Large Inflow VSD & AP Window/Trauncus Arteriosus R L Sheet VSD Severe Pulmonary Hypertension/
                    HD - S/P PTCA - Ischemic Cardiomyopathy/Ischemic Heart Disease - Triple Vessel Disease With Dilated Cardiomypathy In A Known Case of Type II Diabetis Mellitus/
                    Arrhythmogenic Right Ventricular Dysfunction/Type II Aorto-Pulmonary Window With Eisenmenger Syndrome/
                    Heart Failure/Severe Pulmonary Hypertension/Dilated Cardiomyopathy LVEF 15%/Tricuspid Atresia VSD/Sev Pah With Sev. RV Dysfunction/
                    Idiopathic Dilated Cardiomyopathy/Restrictive Cardiomyopathy/Hypertrophic Obstructive Cardiomyopathy/
                    Infitrative  Cardiomyopathy/Others`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },

                    {},
                  ],
                  [
                    {
                      text: '+ Any Attachments',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ` `,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,
        organs.includes('HEART_SEMIURGENT')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                // heights: [
                //   10,
                //   20,
                //   'auto',
                //   'auto',
                //   'auto',
                //   'auto',
                //   10,
                //   'auto',
                //   'auto',
                //   'auto',
                //   40,
                //   40,
                //   'auto',
                //   'auto',
                //   40,
                //   40,
                //   'auto',
                // ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'SEMI URGENT HEART REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: `Date: ${moment().format('DD MMMM YYYY')}`,
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 3,
                    },
                    {
                      text: `Registration Date: `,
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 3,
                    },
                    {},
                  ],
                  // [
                  //   {
                  //     text: `ZTCC Zone: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 3,
                  //   },
                  //   {},
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `Name: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 3,
                  //   },
                  //   {},
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `NOTTO ID : `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 3,
                  //   },
                  //   {},
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `Age: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `Sex: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],

                  // [
                  //   {
                  //     text: `Weight (kg): `,
                  //     colSpan: 1,
                  //   },

                  //   {
                  //     text: `Height (cm): `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],

                  // [
                  //   {
                  //     text: `Hospital Name: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Blood Group: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],

                  // [
                  //   {
                  //     text: `Nationality: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Economic Status: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Address & Telephone No`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: 'Patient contact numbers:',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],

                  // [
                  //   {
                  //     text: 'Primary physician contact numbers:',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: 'Primary physician contact email:',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  [
                    {
                      text: '2D Echo Details:',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: '2D Echo Report:',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Severe Cardiac Cachexia: Nephropathy, Neuropathy etc?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Diabetes with end organ diseases?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baselin GFR (ml/min)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baseline serum creatinine (mg/dl)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe cerebrovascular disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Sereve Obesity BMI',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe primary pulmonary disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Pulmonary Hypertension PASP > 50 mmHg unresponsive to vacodilator challenge?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,

        organs.includes('HEART_SEMIURGENT')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  20,
                  'auto',
                  'auto',
                  10,
                  'auto',
                  10,
                  'auto',
                  'auto',
                  10,
                  20,
                  'auto',
                  'auto',
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'Semi Urgent ',
                      pageBreak: 'before',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Recipient requires mechanical circulatory support (less than 30 days)? \n a)IABP (Infra-Aortic Ballon Pump) \n b)ECMO (Extra Corporeal Membrane Oxygenation) \n c)LVAD / RVAD / Bi-VAD (Left Ventricular Assist Device or Right Ventricular Assist Device or Bi Ventricular Assist Device)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of MCS Initiated',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Cannulae Sites',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Recipient requires mechanical circulatory support ( for more than 30 days) with device related complications? \n a)IABP (Infra-Aortic Ballon Pump) \n b)ECMO (Extra Corporeal Membrane Oxygenation) \n c)LVAD / RVAD / Bi-VAD (Left Ventricular Assist Device or Right Ventricular Assist Device or Bi Ventricular Assist Device)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of MCS Initiated',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Cannulae Sites',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the Recipient require mechanical ventilation with high ionotropic support?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Inotropes details with doses(mcg/kg/min)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Etiology of Heart Failure',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `Dilated Cardiomyopathy/Ischemic Cardiomyopathy/Severe Left Ventricular Failure/Severe Heart Failure Global hypokinesia/
                    Large Inflow VSD & AP Window/Trauncus Arteriosus R L Sheet VSD Severe Pulmonary Hypertension/
                    HD - S/P PTCA - Ischemic Cardiomyopathy/Ischemic Heart Disease - Triple Vessel Disease With Dilated Cardiomypathy In A Known Case of Type II Diabetis Mellitus/
                    Arrhythmogenic Right Ventricular Dysfunction/Type II Aorto-Pulmonary Window With Eisenmenger Syndrome/
                    Heart Failure/Severe Pulmonary Hypertension/Dilated Cardiomyopathy LVEF 15%/Tricuspid Atresia VSD/Sev Pah With Sev. RV Dysfunction/
                    Idiopathic Dilated Cardiomyopathy/Restrictive Cardiomyopathy/Hypertrophic Obstructive Cardiomyopathy/
                    Infitrative  Cardiomyopathy/Others`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },

                    {},
                  ],
                  [
                    {
                      text: '+ Any Attachments',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ` `,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,
        organs.includes('HEART_ROUTINE')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  10,
                  20,
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  10,
                  'auto',
                  'auto',
                  'auto',
                  40,
                  40,
                  'auto',
                  'auto',
                  40,
                  40,
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'ROUTINE HEART REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: `Date: ${moment().format('DD MMMM YYYY')}`,
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  // [
                  //   {
                  //     text: 'ZTCC Zone:',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   { text: '', colSpan: 2 },
                  //   { text: '' },
                  //   { text: '' },
                  // ],

                  // [
                  //   {
                  //     text: `Name: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 3,
                  //   },
                  //   {},
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `Age: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `Sex: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `NOTTO ID :`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `Weight: `,
                  //     colSpan: 1,
                  //   },

                  //   {
                  //     text: `Height: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  //   {
                  //     text: '',
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Hospital`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Blood Group `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],

                  // [
                  //   {
                  //     text: `Nationality:`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Economic Status`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Address & Telephone No`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: 'Patient contact numbers',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],

                  // [
                  //   {
                  //     text: 'Primary physician contact numbers:',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: 'Primary physician contact email:',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: ``,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  [
                    {
                      text: '2D Echo Details:',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" '`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: '2D Echo Report:',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have Severe Cardiac Cachexia: Nephropathy, Neuropathy etc?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Diabetes with end organ diseases?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baselin GFR (ml/min)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baseline serum creatinine (mg/dl)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe cerebrovascular disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Sereve Obesity BMI',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe primary pulmonary disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Pulmonary Hypertension PASP > 50 mmHg unresponsive to vacodilator challenge?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,

        organs.includes('HEART_ROUTINE')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  10,
                  20,
                  'auto',
                  'auto',
                  10,
                  'auto',
                  10,
                  'auto',
                  'auto',
                  10,
                  20,
                  'auto',
                  'auto',
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'Routine Registration',
                      pageBreak: 'before',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Stable with symptomatic in NYHA class III / IV on maximal medical treatment with oral medicince',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Stable With Symptomatic Nyha Class Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'On Ionotropes, optimally stale at home',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Ionotropes Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Severe Heart Failure (EF < 20%) despite maximal medical therapy',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Severe Heart Failure Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Ichemic Cardiomyopathy',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Non-ischemic Cardiomyopathy: Dilated, Hypertropic, Restrictive',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Non Ischemic Cardiomyopathy Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Irreversible Myocarditis',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Intractable Arrhythmias',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Intractable Arrhythmias Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Complex Congential Heart disease no amenale to corrections',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Complex Congential Heart Disease Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Complicated Valvular Heart Disease',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Complicated Valvular Heart Disease Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Significant functional limitation despite maximal drug therapy: NYHA III-IV',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Significant Functional Limitation Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Congenital / Valvular heart diseases not amenable to interventional or surgincal procedure',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Congential Valvular Heart Disease Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Life threatening arrhythmia despite maximal medical / device therapie',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Life Threatening Arrhythmia Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `" "`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Acceptance of CEPT Criteria',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Etiology of Heart',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `Dilated Cardiomyopathy/Ischemic Cardiomyopathy/Severe Left Ventricular Failure/Severe Heart Failure Global hypokinesia/
                    Large Inflow VSD & AP Window/Trauncus Arteriosus R L Sheet VSD Severe Pulmonary Hypertension/
                    HD - S/P PTCA - Ischemic Cardiomyopathy/Ischemic Heart Disease - Triple Vessel Disease With Dilated Cardiomypathy In A Known Case of Type II Diabetis Mellitus/
                    Arrhythmogenic Right Ventricular Dysfunction/Type II Aorto-Pulmonary Window With Eisenmenger Syndrome/
                    Heart Failure/Severe Pulmonary Hypertension/Dilated Cardiomyopathy LVEF 15%/Tricuspid Atresia VSD/Sev Pah With Sev. RV Dysfunction/
                    Idiopathic Dilated Cardiomyopathy/Restrictive Cardiomyopathy/Hypertrophic Obstructive Cardiomyopathy/
                    Infitrative  Cardiomyopathy/Others`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: '+ Any Attachments',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,

        organs.includes('HAND')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'HAND REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: `Date: ${moment().format('DD MMMM YYYY')}`,
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 3,
                    },
                    {
                      text: `Registration Date:`,
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 3,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Etiology: \n (Diagnosis)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `Road Traffic Accident/Train Accident/Factory Accident/Machinary Accident/
                  Electrical Burns/Flame Burns/Congenital/Others`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  // [
                  //   {
                  //     text: `Blood Group: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },

                  //   {
                  //     text: ``,
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],

                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have with amputated upper limb unilateral or bilateral at levels distal to mid- arm',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o Unilateral      o Bilateral`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have the significant functional limitation despite using prosthesis',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Loss of hand or part of upper limb not suitable to any other surgical procedure for near complete hand function for activities of daily living.',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have the congenital absence of limb (relative contraindication)?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have the severe cachexia: Nephropathy, Neuropathy etc. ?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any diabetes with end organ diseases?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Baseline GFR (mL/min)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have the severe cerebrovascular disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have the Malignancies with expected life span < 10 yrs',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'BMI (Kg/m^2)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    { text: `" "`, colSpan: 2 },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have the severe primary pulmonary disease',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Pulmonary hypertension PASP > 50 mmHg unresponsive to vasodilator challenge',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any past history of hematological events ?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Other Certificates : \n a) Pulmonary Fitness Certificate \n b) Psychiatry Fitness Certificate \n c) Nephrology Fitness Certificate \n d) Cardiology Fitness Certificate \n e) X-Ray Attachment\n f) Color Dropplers Attachment \n g) Angiography Attachment (NC) \n h) EMG/MCV (NC) \n i) Photograph of Ampulaed Limb \n j) MRI Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,

        organs.includes('KIDNEY')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  20,
                  20,
                  10,
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'KIDNEY REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  // [
                  //   {
                  //     text: `Name: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 3,
                  //   },
                  //   {},
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `ZTCC Zone: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 3,
                  //   },
                  //   {},
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `NOTTO ID : `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   { text: ``, colSpan: 2 },
                  //   { text: '' },
                  //   { text: '' },
                  // ],
                  // [
                  //   {
                  //     text: `Date of Registration : `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },

                  //   {
                  //     text: ``,
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `Address & Telephone No: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },

                  //   {
                  //     text: ``,
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `Nationality: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },

                  //   {
                  //     text: ``,
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `Hospital Name: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },

                  //   { text: ``, colSpan: 2 },
                  //   {},
                  // ],

                  // [
                  //   {
                  //     text: `Age: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `Sex:`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `Economic Status: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },

                  //   {
                  //     text: ``,
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `Blood Group: `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },

                  //   {
                  //     text: ``,
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],
                  [
                    {
                      text: `Date of Initiation of Dialysis:  `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 3,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Failed Previous Transplant \n (if yes give details) : `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `Is the patient past kidney donor ? \n (if yes give details) : `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HLA Typing : ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'A',
                      style: 'tableHeader',
                      alignment: 'left',
                    },
                    {
                      text: '" "',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'B',
                      style: 'tableHeader',
                      alignment: 'left',
                    },
                    {
                      text: '" "',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'C',
                      style: 'tableHeader',
                      alignment: 'left',
                    },
                    {
                      text: '" "',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'DQ',
                      style: 'tableHeader',
                      alignment: 'left',
                    },
                    {
                      text: '" "',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'DR',
                      style: 'tableHeader',
                      alignment: 'left',
                    },
                    {
                      text: '" "',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'DM',
                      style: 'tableHeader',
                      alignment: 'left',
                    },
                    {
                      text: '" "',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'DP',
                      style: 'tableHeader',
                      alignment: 'left',
                    },
                    {
                      text: '" "',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'TAP',
                      style: 'tableHeader',
                      alignment: 'left',
                    },
                    {
                      text: '" "',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'TNF',
                      style: 'tableHeader',
                      alignment: 'left',
                    },
                    {
                      text: '" "',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `VAF AV Graft \n (if yes give details): `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `Number of VAF Failed: `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `PD Access Failure \n (if yes give details): `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `Number of PDAF Failed: `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'PRA Positivity : ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o Class 1 \n o Class 2 \n o  both Class 1 and Class 2 \n o Not Done `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },

                    {},
                  ],

                  [
                    {
                      text: 'Viral Marker Status : ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Cardiology Fitness Certificate',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Pulmonary Fitness Certificate',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Psychiatry Fitness Certificate',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Urology Fitness Certificate',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Any Other Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `Etiology of ESKD `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `A/B/C/D/Others`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,
        // organs.includes('LIVER') && basicDetails?.waitingStatus === 'ROUTINE' ?  : null
        organs.includes('LIVER_SUPERURGENT')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'LIVER SUPER URGENT REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'Liver Super Urgent Medical Information',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'Patient Admission File',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Treating Doctor Clinical Summary Report ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Grade of Hepatic Encephalopathy',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o None      o Grade I-II (or supressed with medication)  o Grade III-IV (or refactory)`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Bilirubin (mg/dL)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Aspartate Aminotransferase(AST (IU/L)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Alkaline Phosphatase (IU/L)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Prothrombin Time(seconds)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'INR',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Arterial pH',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `"  "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Venous pH',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Arterial Lactate (mmol/L) on admission',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Arterial Lactate(mmol/L) after fluid resuscitation',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Creatinine (mg/dL)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Albumin (g/L)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'WBC / TLC (giga(10[object Object])/L)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'ALF Phenotype',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o Acute hepatic liver injury AST / ALT > 5 – 10x ULN\no Baseline unknown liver disease\no Acute worsening of liver function leading to jaundice and hepatic encephalopathy within 4 weeks`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Liver Status',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Appearance of Liver',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o Normal o Uniformly Nodular`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Size of Spleen (in Cm)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have ascites?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o None      o Mild   o Gross`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have cirrhosis on biopsy?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Collaterals / Varices +',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Previous hospitalization for decompensation of cirrhosis',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Living Donor - Liver Failure',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Living Donor Hepatectomy',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment Report - Living Donor Hepatectomy',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Living Donor with Liver failure',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Primary Non-Function',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient the DDLT(Deceased Donor Liver Transplantation) graft dysfunction?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Deceased Donor Liver Transplantion?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment Report - Liver Transplantation',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Vascular Complications',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Sepsis',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Primary Non-Function',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" " `,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Anhepetic Patient',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have anhepatic after total hepatectomy e.g. for trauma etc?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Anhepatic Patient',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Paracetamol Poisoning',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Date of Deceased Donor Liver Transplantion?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have pH less than 7.25 more than 24 hours after overdose and after fluid resuscitation?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any significant liver injury and coagulopathy after adequate fluid resuscitation ?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have any causes of hyperlactatemia( e.g. pancreatitis, intestinal ischemia) ?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have anuria?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any absence of clinical sepsis?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any clinical evidence of deterioration?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Intracranial Pressure ICP (mmHg)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'FiO2 (in %)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Inotrope',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o Positive   o Negative    o Not Applicable`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'NHS Criteria',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Paracetamol Poissioning',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Favourable Etiologies',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Select list of favourable etiologies',
                      style: 'textstyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o HAV    o HEV   o Ecstacy   o Cocaine Toxicity    o Other`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have jaundice? ',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of onset of Jaundice',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of onset of Encephalopathy',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'NHS Criteria',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Favourable Etiologiesn',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Unfavourable Etiologies',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Select list of unfavourable etiologies',
                      style: 'textstyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o Sernegative Hepatitis    o Idiosyncratic Drug Reactions\no Drug Incuded Liver injury (DILI)    o Other`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have jaundice? ',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of onset of Jaundice',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of onset of Encephalopathy',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient any vitamin K repletion?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'NHS Criteria',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Unfavourable Etiologiesn',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Ratol Poisoning',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient any vitamin K repletion?',
                      style: 'textstyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Is the patient undergoing any plasmapheresis? ',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Has the patient undergone through Second set of Plasmapheresis?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any Active Cardiac dysfunction (EF < 50%)?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Unfavourable Etiologie',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Hepatic Artery Thrombosis',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any failure of revascularization(Radiologi or Surgical)?',
                      style: 'textstyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Liver Transplantation ',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Hepatic Artery Thrombosis(HAT)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Acute Wilsons Disease',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Coombs-negative hemolytic anemia',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o Present      o Absent`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Kayser-Fleischer rings',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o Present      o Absent`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Neurologic symptoms',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o Absent      o Mild     o Severe`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Serum ceruloplasmin(g/L)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" '`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Liver copper (in the absence of cholestasis)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o > 5 x ULN (> 4 μmol/g)     o 0.8 - 4 μmol/g\no Normal (< 0.8 μmol/g)     o Rhodanine-positive granules`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Urinary copper (in the absence of acute hepatitis)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o Normal     o 1 -2 x ULN      o > 2 x ULN     o Normal, but >5 x ULN after d-penicillamine`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Mutation Analysis',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o No Mutation      o On 1 Chromosome detected    o On Both Chromosomes Detected`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'History of Wilson’s Disease in a Family members',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `o Family member’s death from liver Disease/neurological disease suggestive of Wilson’s Disease\no Family member’s with liver Disease/neurological disease suggestive of Wilson’s Disease\no None`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Wilsons Disease Scoring System (Leipzig Score)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Kings College Wilson Disease Prognostic Index',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Bilirubin / Alkaline Phosphatase Ratio',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Evidence of Hemolysis',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Are there any etiologies found?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Acute Wilson’s Disease',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Acute Budd-Chiari Syndrome',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Does the cross sectional imaging showing Chronic Hepatic Vein Thrombus?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the cross sectional imaging showing Atrophy Hypertrophy Complex?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Cross - Section (CT / MRI) Imaging Date',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Cross - Section (CT / MRI) Imaging Attachment',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Is the TIPSS Contradicted or Not Feasible?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Acute Budd-Chiari Syndrome',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Acute AutoImmune Hepatitis',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Is the Liver Biopsys Done?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Confluent or massive hepatic necrosis (AIH / DILI)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Marked microvesicular steatosis (DILI)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Cholestatic hepatitis with ductopenia (DILI)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'MELD Score',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have improved in Bilirubin / INR with steroids for 5 days?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Reports Date',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Reports Attachment',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Acute Auto-immune Hepatitis ',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HBV Reactivation',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'lgM Core AntiBody Total',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o Positive      o Negative`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Any other causes',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under HBV reactivation',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Pediatric Patient',
                      style: 'textStyle',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have multisystem disorder causing severe acute impairment of liver function?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have hepatocellular necrosis?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have any leukaemia, lymphoma, haemophagocytosis and disseminated intra-vascular coagulopath?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Pediatric Patients',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Hepatoblastoma',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have any extra hepatic disease?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have the complete response to chemotherapy / R0 resection of any extra-hepatic disease ?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have pretext III/IV disease with good response to 6 cycles of chemotherapy (total Hepatoctomy is the only surgical measure to achieve RO)?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Hepatoblastoma',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,

        organs.includes('LIVER_ROUTINE')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'LIVER ROUTINE REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'MELD',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'Listing Bilirubin (mg/dl)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Listing INR',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Listing Creatinine',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Listing Albumin (g/dL)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Listing Na',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Growth failure at Listing less than 2 standard deviation',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'PELD',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'MLAS (A)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Viral Marker Status',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'HBsAg',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HBsAg Report Date',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HBsAg Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Anti HCV',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o POSITIVE      o NEGATIVE',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Anti HCV Report Date',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Anti HCV Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HCV RNA Quantitative',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ` o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HCV RNA Quantitative Report Date',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HCV RNA Quantitative Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HCV DNA',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o POSITIVE     o NEGATIVE    o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HCV DNA Report Date',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HCV DNA Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HIV',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o POSITIVE      o NEGATIVE',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HIV Report Date:',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HIV Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Report Type',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: 'Bilirubin Report / Creatinine Report / INR Report /\n Albumin Report / Na Report',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Date Of Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },

                    {},
                  ],

                  [
                    {
                      text: 'Lab Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },

                    {},
                  ],
                  [
                    {
                      text: 'Syndromes',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      colSpan: 2,
                    },

                    {},
                  ],
                  [
                    {
                      text: 'Syndrome Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },

                    {},
                  ],

                  [
                    {
                      text: 'Etiologies',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      colSpan: 2,
                    },

                    {},
                  ],
                  [
                    {
                      text: 'Etiology Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },

                    {},
                  ],
                  [
                    {
                      text: 'Complication Score',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have Ascites?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have moderate to severe Refractory ascites?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Refractory ascites (≥ 2 LVPs (≥ 5L) / month)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Refractory ascites (diuretic resistance)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Refractory ascites (needing noradrenaline / midodrine / terlipressin)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have moderate to severe Hepatic Hydrothorax',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Hepatic Hydrothorax (moderate to severe on HRCT/Chest Xray/USG Thorax)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Hepatic hydrothorax (≥ 2 therapeutic taps)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have Spontaneous Bacterial Peritonitis (SBP)?)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Ascities Fluid Absolute Neutrophil count',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'SBP Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'SBP Reports Date ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Eligible for SBP points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'SBP points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have HRS?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have Hepatic Encephalopathy (HE) needing ICU?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'HE (Past / Current) (≥ grade 3 requiring hospitalization (ICU))',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Structural neurological diseases',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Eligible for HE points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'HE points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Variceal Bleed?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Recurrent variceal bleed (not amenable to endotherapy and / or TIPSS)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Eligible for Recurrent variceal bleed points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Recurrent variceal bleed points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Jaudice?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have PVT?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'MELD Exception Criteria',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have Hepatopulmonary Syndrome (HPS)?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'PaO2 less than 60 mmHg on room air',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Alveolar arterial oxygen gradient greater 15 mmHg /greater 20 mmHg if greater than 64 yrs age',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Bubble ECHO / MAA scan positive for Shunt',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Clinical e/o liver disease and portal hypertension',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'E/O underlying primary pulmonary disease',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Eligible for HPS exception point',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'HPS exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Pulmonary Arterial Hypertension (PAH)?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Post-treatment Mean pulmonary arterial pressure (MPAP) 25 to 35 mmHg',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Post-treatment pulmonary vascular resistance (PVR) 120 to 400 dynes/sec/cm',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Pulmonary Capillary Wedge Pressure (PCWP) less than 15 mmg',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Right Heart Catheterization Reports',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Heart Cath Report Date',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Eligible for PAH exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'PAH exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Hepatocellular Carcinoma (HCC)?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Single tumor less than equals 6.5cm',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'less than equals 3 tumors with largest tumor less than equals 4.5cm',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'less than equals 3 tumors with total tumor diameter less than equals 8cm',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Within UCSF criteria',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Largest tumor size (cm)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Active portal vein tumor thrombus (PVTT) (bland PVT is acceptable)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Extrahepatic disease',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'At least one lesion should be greater than 2 cms',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'AFP value',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'AFP Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'AFP Report Date',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of last Triphasic CT / MRI',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Duration from last Triphasic CT/ MRI',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Triphasic CT Scan',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Date of last bone scan',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Duration from last bone scan',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Bone Scan',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Date of last HRCT chest',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Duration from last  HRCT chest',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HRCT Chest Scan',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Date of last PET CT ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Duration from last PET CT',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'PET CT Scan',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for HCC exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'HCC exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Treatment Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'TACE',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'TARE',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'RFA',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Resection',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'MWA',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Other',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have Primary Sclerosing Cholangitis (PSC)?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Proven on MRCP',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Recurrent cholangitis (>= 4 episodes in a year)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Refractory cholangitis',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Severe Intractable itching after therapy',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for PSC exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'PSC exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Primary Biliary Cholangitis (PBC)?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'AMA positive ± Biopsy proven',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Severe intractable pruritus after treatment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Severe metabolic bone disease (non-traumatic fractures)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Severe metabolic bone disease (Z score less than -4 on DEXA scan)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for PBC exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'PBC exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the post transplant patient require Retransplant?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for super-urgent listing(rejection/late HAT/ITBL)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Post Transplant Patient Requiring Retransplant exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Post Transplant Patient Requiring Retransplant exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Metabolic Liver Diseases?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Familial amyloid polyneuropathy',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Maple syrup urine disease',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Eligible for Metabolic liver diseases (Native liver offered for Domino liver transplant) exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Metabolic Liver Diseases (other than Wilsons)?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Familial hypercholesterolemia',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Sickle cell hepatopathy',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Primary hyperoxaluria',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Polycystic liver disease',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Eligible for Metabolic liver diseases (other than Wilsons) exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Metabolic liver diseases (other than Wilsons) exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Non Hepatocellular Carcinoma (Non-HCC)?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Hepatic epithelioid haemangioendothelioma',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Bilobar NET (Neuro Endocrine Tumor) liver metastases: at least 6 months after resolution of primary tumor',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Bilobar NET (Neuro Endocrine Tumor) liver metastases:confirmed metastasis restricted to liver by whole body special scans',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '  o YES      o NO',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Eligible for Non HCC exception points ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Non HCC exception points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Eligible for Meld Exception Criteria Points',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Meld Exception Criteria points (D)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'MLAS Score',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'MLAS (A)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Complication Score (B)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Waiting Time Score (C) ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'MELD Exception Score (D)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Listing MLAS Score',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Updated MLAS Score',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Current MLAS Score',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                      bold: true,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                ],
              },
            }
          : null,

        organs.includes('PANCREAS')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  20,
                  40,
                  40,
                  'auto',
                  'auto',
                  40,
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'PANCREAS REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'Serum Creatinine(mg/dL)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: '" "',
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' C-Peptide(ng/mL)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have Frequent episodes of Hypoglycemia unawareness?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Does the patient have Brittle Diabetes? ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Does the patient have the pancreatic exocrine insufficiency?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Does the patient have episode of Diabetic Ketoacidosis?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Does the patient have the poor quality of life with insuline?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Insuline requirement per day (UNITS/KILOGRAMS):',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Date of FBS Report and Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Date of HbA1c Report and Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Anti-Insulin Antibody\n If Yes Provide Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Anti-Insulin Antibodies Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Anti-GAD Antibody\n If Yes Provide Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Anti-GAD Antibodies Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have diagnosed with diabetes for Type I DM? \n a)Secondary Complications of diabetes \n b) Poor quality of life with Insulin',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Treatment Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have diagnosed with diabetes for Type II DM? \n a)Does the patient have Insulin dependency? \n b) Does the patient have any minimal coronary risk or with corrected coronary disease and therefore low cardiac risk?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Treatment Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Any Other Attachment',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `Etiology  `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `A/B/C/D/Other`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,

        organs.includes('LUNGS_ROUTINE')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  20,
                  40,
                  40,
                  'auto',
                  'auto',
                  40,
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'ROUTINE LUNGS REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: '2D Echo Report:\n\n ABG Report \n\n PFT Report \n HRCT Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient statisfy the ISHLT Criteria?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Etiology of Lung Failure',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `Eisenmenger Syndrome/Large Inflow VSD & AP Window/ASD With Eisenmenger/Trauncus Arteriosus R L Sheet VSD Severe Pulmonary Hypertension/Type II Aorto-Pulmonary Window With Eisenmenger Syndrome/Lung Fibrosis With In A Stage Respiratory_Failure/Severe Pulmonary Hypertension/Sev Pah With Sev. RV Dysfunction/Interstitial Lung Disease (ILD)/Other`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Any Other Complications',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: '+ Any Attachments',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,
        organs.includes('LUNGS_SUPERURGENT')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  20,
                  30,
                  30,
                  'auto',
                  'auto',
                  40,
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'SUPERURGENT LUNGS REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'Patient Admission File',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Treating Doctor Clinical Summary Report ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ``,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: '2D Echo Report:\n ABG Report \n PFT Report \n HRCT Report',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have the End Stage Lung Disease? ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Intrope Details ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'If Positive, Provide Details ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have the ECMO Support?\n Types of ECMO:- \n Date of ECMO Initiated \n ECMO Blood Flow Rate(L/Min) \n ECMO RPM \n ECMO Cannulation Sites \n ECMO Weaning \n ECMO Complication',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: ` type of ECMO:  o VV      o VA\n Ecmo commulation sites:   o Central      o Peripheral `,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Does the patient with respiratory failure dependent on Mechanical Ventilation (not weanable)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Duration on Ventilator(in Days)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Is a patient ready for weaning off ventilation support?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Ventilator Setting',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Lung Complication Details',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Etiology of Lung Failure',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `Eisenmenger Syndrome/Large Inflow VSD & AP Window/ASD With Eisenmenger/Trauncus Arteriosus R L Sheet VSD Severe Pulmonary Hypertension/Type II Aorto-Pulmonary Window With Eisenmenger Syndrome/Lung Fibrosis With In A Stage Respiratory_Failure/Severe Pulmonary Hypertension/Sev Pah With Sev. RV Dysfunction/Interstitial Lung Disease (ILD)/Other`,

                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Any Other Attachments',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,
        // organs.includes('PANCREAS')
        //   ? {
        //       style: 'table',
        //       table: {
        //         widths: [250, 100, 150, 'auto', 'auto'],
        //         heights: [
        //           'auto',
        //           20,
        //           'auto',
        //           'auto',
        //           'auto',
        //           'auto',
        //           'auto',
        //           'auto',
        //           'auto',
        //           20,
        //           40,
        //           40,
        //           'auto',
        //           'auto',
        //           40,
        //         ],
        //         body: [
        //           [
        //             {
        //               text: 'Serum Creatinine(mg/dL)',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: ``,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: ' C-Peptide(ng/mL)',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: ``,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],

        //           [
        //             {
        //               text: 'Does the patient have Frequent episodes of Hypoglycemia unawareness?',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: ``,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: ' Does the patient have Brittle Diabetes? ',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: ``,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: ' Does the patient have the pancreatic exocrine insufficiency?',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: ``,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],

        //           [
        //             {
        //               text: 'Does the patient require the procurement or transplantation of a pancreas as part of a multiple organ transplant for technical reasons',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: ``,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: 'Does the patient have diagnosed with diabetes for Type I DM? \n a)Secondary Complications of diabetes \n b) Poor quality of life with Insulin',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: ``,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: 'Treatment Details',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: ``,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: 'Does the patient have diagnosed with diabetes for Type II DM? \n a)Does the patient have Insulin dependency? \n b) Does the patient have any minimal coronary risk or with corrected coronary disease and therefore low cardiac risk?',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: ``,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: 'Treatment Details',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: ``,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],

        //           // ...(basicDetails.waitingStatus === 'SPK' ? [] : []),
        //         ],
        //       },
        //     }
        //   : null,
        organs.includes('INTESTINE')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                      fontSize: 12,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'INTESTINE REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have Primary Intestinal failure? \n \n Massive bowel resection \n Crohn’s disease \n Necrotizing enterocolitis \n Malabsorption syndromes \n Tumors such as massive mesenteric desmoids \n Visceral myopathy \n Pseudo obstruction',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Does the patient have the Failure of Parenteral Nutrition? \n \n Impending or overt liver failure due to TPN-induced liver injury \n Thrombosis of two or more central veins \n Two or more episodes per year of catheter-related systemic sepsis that requires hospitalization \n A single episode of line-related fungemia, septic shock, or acute respiratory distress syndrome \n Frequent episodes of severe dehydration despite intravenous fluid supplementation in addition to TPN',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Impending or overt liver failure due to TPN-induced liver injury',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO\n Date: `,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Thrombosis of two or more central veins',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO\n Date: `,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Two or more episodes per year of catheter-related systemic sepsis that requires hospitalization',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO\n Date: `,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' A single episode of line-related fungemia, septic shock, or acute respiratory distress syndrome',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO\n Date: `,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: ' Frequent episodes of severe dehydration despite intravenous fluid supplementation in addition to TPN',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO\n Date: `,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the Patient required Abdominal Wall?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO `,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Massive bowel resection',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO\n Date: `,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Length of Bowl Remaining (in cm)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `" "`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have the additional liver dysfunction or failure due to long term total parenteral nutrition?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have the Extensive thrombosis of porto-mesenteric axis with or without liver failure?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `  o YES      o NO`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE     o NOT DONE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `DD/MM/YYYY\n  o POSITIVE      o NEGATIVE`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Etiology',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `Crohn’s disease/Necrotizing enterocolitis/Malabsorption syndromes/Tumors such as massive mesenteric desmoids/Visceral myopathy/Pseudo obstruction/Massive Gut Resection`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,

        {
          style: 'table',
          table: {
            widths: [250, 100, 150],
            heights: [0, 50, 50, 50, 50, 50],
            body: [
              [
                {
                  text: 'All verification of the clinical details / reports / criteria eligibility to justify above criteria is the responsibility of the transplant team signing this form. \n1. All the reports (Lab reports + imaging report) should be within 1 month at the time of registration. The patients with older reports should not be considered for registration.\n2. All lab reports should be within 1 month and imaging should be within 3 months for allocation, patients with older reports should not be considered for allocation.',
                  style: 'tableHeader',
                  colSpan: 3,
                  // alignment: 'center',
                  // bold: true,
                  fontSize: 11,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Patient Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Surgeon Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Physician Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Transplant Coordinator Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Signature of Hospital Head:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    if (this.organs.length === 1) {
      if (this.organs.includes('HEART_SUPERURGENT')) {
        const fileName = file || `Heart SuperUrgent Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
      if (this.organs.includes('HEART_SEMIURGENT')) {
        const fileName = file || `Heart SemiUrgent Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
      if (this.organs.includes('HEART_ROUTINE')) {
        const fileName = file || `Heart Routine Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
      if (this.organs.includes('HAND')) {
        const fileName = file || `Hand Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
      if (this.organs.includes('KIDNEY')) {
        const fileName = file || `Kidney Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
      if (this.organs.includes('LIVER_SUPERURGENT')) {
        const fileName = file || `Liver SuperUrgent Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
      if (this.organs.includes('LIVER_ROUTINE')) {
        const fileName = file || `Liver Routine Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
      if (this.organs.includes('PANCREAS')) {
        const fileName = file || `Pancreas Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
      if (this.organs.includes('LUNGS_SUPERURGENT')) {
        const fileName = file || `Lungs SuperUrgent Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
      if (this.organs.includes('LUNGS_ROUTINE')) {
        const fileName = file || `Lungs Routine Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
      if (this.organs.includes('INTESTINE')) {
        const fileName = file || `Intestine Registration Form`;
        const documentDefinition = this.reportDocDefinition;
        pdfMake.createPdf(documentDefinition).download(fileName);
      }
    } else {
      const fileName = file || `Recipient Registration Form`;
      const documentDefinition = this.reportDocDefinition;
      pdfMake.createPdf(documentDefinition).download(fileName);
    }
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default AllocationRecipientEmptyFormPdf;
