export const DISTRICTS_KL = [
  {
    label: 'Alappuzha',
    value: 'ALAPPUZHA'
  },
  {
    label: 'Ernakulam',
    value: 'ERNAKULAM'
  },
  {
    label: 'Idukki',
    value: 'IDUKKI'
  },
  {
    label: 'Kannur',
    value: 'KANNUR'
  },
  {
    label: 'Kasaragod',
    value: 'KASARAGOD'
  },
  {
    label: 'Kollam',
    value: 'KOLLAM'
  },
  {
    label: 'Kottayam',
    value: 'KOTTAYAM'
  },
  {
    label: 'Kozhikode',
    value: 'KOZHIKODE'
  },
  {
    label: 'Malappuram',
    value: 'MALAPPURAM'
  },
  {
    label: 'Palakkad',
    value: 'PALAKKAD'
  },
  {
    label: 'Pathanamthitta',
    value: 'PATHANAMTHITTA'
  },
  {
    label: 'Thiruvananthapuram',
    value: 'THIRUVANANTHAPURAM'
  },
  {
    label: 'Thrissur',
    value: 'THRISSUR'
  },
  {
    label: 'Wayanad',
    value: 'WAYANAD'
  }
];
