export const OPTS_DONOR_MEDICOLEGAL = [
  { label: 'Yes', value: 'YES', slctId: 'yes' },
  { label: 'No', value: 'NO', slctId: 'no' }
];

export const OPTS_DONOR_MOTIVATEDBY = [
  {
    label: 'Hospital Cornea Retrieval Program',
    value: 'HOSPCORNEARETRPROG',
    slctId: 'hospCorneaRetrProg'
  },
  { label: 'Voluntary', value: 'VOLUNTARY', slctId: 'voluntary' }
];

export const OPTS_DONOR_REMOVALPLACE = [
  { label: 'Hospital', value: 'HOSPITAL', slctId: 'hospital' },
  { label: 'Home', value: 'HOME', slctId: 'home' },
  { label: 'Others', value: 'OTHERS', slctId: 'home' }
];

export const OPTS_DONOR_CAUSEOFDEATH = [
  {
    label: 'Head injury / Trauma',
    value: 'HEAD_INJURY',
  },
  {
    label: 'Brain Haemorrhage',
    value: 'BRAIN_HAEMORRHAGE',
  },
  {
    label: 'Infections',
    value: 'INFECTIONS',
  },
  {
    label: 'Brain tumor',
    value: 'BRAIN_TUMOR',
  },
  {
    label: 'Ischemic Stroke',
    value: 'ISCHEMIC_STROKE',
  },
  {
    label: 'Hypoxia / anoxic Brain injury',
    value: 'HYPOXIAANOXIC',
  },
  {
    label: 'IC Bleed',
    value: 'ICBLEED',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
]

export const OPTS_DONOR_RELIGIAN = [
  {
    label: 'Buddhism',
    value: 'BUDDHISM',
  },
  {
    label: 'Christianity',
    value: 'CHRISTIANITY',
  },
  {
    label: 'Hinduism',
    value: 'HINDUISM',
  },
  {
    label: 'Islam',
    value: 'ISLAM',
  },
  {
    label: 'Jainism',
    value: 'JAINISM',
  },

  {
    label: 'Judaism',
    value: 'JUDAISM',
  },
  {
    label: 'Sikhism',
    value: 'SIKHISM',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
  {
    label: 'Not Specified',
    value: 'NOT_SPECIFIED',
  },
]

export const OPTS_DONOR_TISSUETYPE = [
  {
    label: 'Cornea with Scleral Rim',
    value: 'CORNEAWITHSCLERALRIM',
    slctId: 'corneaWithScleralRim'
  },
  { label: 'Whole Eye Ball', value: 'WHOLEEYEBALL', slctId: 'wholeEyeBall' }
];

export const OPTS_DONOR_TYPEOFPRESERVATION = [
  { label: 'MK Medium', value: 'MKMEDIUM', slctId: 'mdMedium' },
  { label: 'Moist Chamber', value: 'MOISTCHAMBER', slctId: 'moistChamber' },
  { label: 'Cornisol', value: 'CORNISOL', slctId: 'cornisol' },
  { label: 'Others', value: 'OTHERS', slctId: 'others' }
];

export const OPTS_DONOR_TISSUENUMBER = [
  { label: 'Both Eyes', value: 'BOTHEYES', slctId: 'bothEyes' },
  { label: 'Left Eye', value: 'LEFTEYE', slctId: 'leftEye' },
  { label: 'Right Eye', value: 'RIGHTEYE', slctId: 'rightEye' }
];

export const OPTS_DONOREVAL_EPTHLM_HAZE = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Mild', value: 'MILD', slctId: 'mild' },
];

export const OPTS_DONOREVAL_BLOODCOLLECTED_ADEQUACY = [
  { label: 'ADEQUATE', value: 'ADEQUATE', slctId: 'adequate' },
  { label: 'INADEQUATE', value: 'INADEQUATE', slctId: 'inadequate' },
];

export const BLOODCOLLECTED = {
  YES: {
    label: 'Yes',
    value: true
  },
  NO: {
    label: 'No',
    value: false
  }
}

export const COMPLICATIONATSURGERY = {
  YES: {
    label: 'Yes',
    value: true
  },
  NO: {
    label: 'No',
    value: false
  }
}

export const OPTS_DONOREVAL_EPTHLM_EXPKRTSDEGREE = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Mild', value: 'MILD', slctId: 'mild' },
  { label: 'Mild-Moderate', value: 'MILD_MODERATE', slctId: 'mildModerate' },
  { label: 'Moderate-Severe', value: 'MODERATE_SEVERE', slctId: 'moderateSevere' },
  { label: 'Severe', value: 'SEVERE', slctId: 'severe' }
];

export const OPTS_DONOREVAL_EPTHLM_EXPKRTSLOC = [
  { label: 'Central', value: 'CENTRAL', slctId: 'central' },
  { label: 'Para central', value: 'PARACENTRAL', slctId: 'paracentral' },
  { label: 'Peripheral', value: 'PERIPHERAL', slctId: 'peripheral' },
  { label: 'Mid-Peripheral', value: 'MIDPERIPHERAL', slctId: 'midPeripheral' },
  { label: 'Diffuse', value: 'DIFFUSE', slctId: 'diffuse' },
  { label: 'Band', value: 'BAND', slctId: 'band' },
];

export const OPTS_DONOREVAL_EPTHLM_SLGHLOC = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Central', value: 'CENTRAL', slctId: 'central' },
  { label: 'Para central', value: 'PARACENTRAL', slctId: 'paracentral' },
  { label: 'Peripheral', value: 'PERIPHERAL', slctId: 'peripheral' },
  { label: 'Mid-Peripheral', value: 'MIDPERIPHERAL', slctId: 'midPeripheral' },
  { label: 'Diffuse', value: 'DIFFUSE', slctId: 'diffuse' },
];

export const OPTS_DONOREVAL_STRMA_CLRCMPCT = [
  { label: 'Clear Only', value: 'CLEARONLY', slctId: 'clearOnly' },
  { label: 'Compact Only', value: 'COMPACTONLY', slctId: 'compactOnly' },
  { label: 'Clear and Compact', value: 'CLEARANDCOMPACT', slctId: 'clearAndCompact' },
  { label: 'Neither', value: 'NEITHER', slctId: 'neither' }
];

export const OPTS_DONOREVAL_STRMA_EDEMA = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Mild', value: 'MILD', slctId: 'mild' },
  { label: 'Mild-Moderate', value: 'MILD_MODERATE', slctId: 'mildModerate' },
  { label: 'Moderate', value: 'MODERATE', slctId: 'moderate' },
  { label: 'Moderate-Severe', value: 'MODERATE_SEVERE', slctId: 'moderateSevere' },
  { label: 'Severe', value: 'SEVERE', slctId: 'severe' }
];

export const OPTS_DONOREVAL_STRMA_LOC = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Central', value: 'CENTRAL', slctId: 'central' },
  { label: 'Para central', value: 'PARACENTRAL', slctId: 'paracentral' },
  { label: 'Peripheral', value: 'PERIPHERAL', slctId: 'peripheral' },
  { label: 'Mid-Peripheral', value: 'MIDPERIPHERAL', slctId: 'midPeripheral' },
  { label: 'Diffuse', value: 'DIFFUSE', slctId: 'diffuse' },
];

export const OPTS_DONOREVAL_STRMA_ARCS = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Mild', value: 'MILD', slctId: 'mild' },
  { label: 'Mild-Moderate', value: 'MILD_MODERATE', slctId: 'mildModerate' },
  { label: 'Moderate', value: 'MODERATE', slctId: 'moderate' },
  { label: 'Moderate-Severe', value: 'MODERATE_SEVERE', slctId: 'moderateSevere' },
  { label: 'Severe', value: 'SEVERE', slctId: 'severe' }
];

export const OPTS_DONOREVAL_STRMA_OPCTTYPE = [
  { label: 'Surgical(IOL)', value: 'SURGICALIOL', slctId: 'surgicalIol' },
  { label: 'Lasik', value: 'LASIK', slctId: 'lasik' },
  { label: 'Other', value: 'OTHER', slctId: 'other' },
  { label: 'None', value: 'NONE', slctId: 'none' }
];

export const OPTS_DONOREVAL_STRMA_OPCTREF = [
  { label: 'On button', value: 'ONBUTTON', slctId: 'onButton' },
  { label: 'From Limbus', value: 'FROMLIMBUS', slctId: 'fromLimbus' },
];

export const OPTS_DONOREVAL_STRMA_STRIAELOC = [
  { label: 'Central', value: 'CENTRAL', slctId: 'central' },
  { label: 'Para central', value: 'PARACENTRAL', slctId: 'paracentral' },
  { label: 'Peripheral', value: 'PERIPHERAL', slctId: 'peripheral' },
  { label: 'Mid-Peripheral', value: 'MIDPERIPHERAL', slctId: 'midPeripheral' },
  { label: 'Diffuse', value: 'DIFFUSE', slctId: 'diffuse' },
];

export const OPTS_DONOREVAL_STRMA_STRIAERELNUM = [
  { label: 'Few', value: 'FEW', slctId: 'few' },
  { label: 'Few-Several', value: 'FEW_SEVERAL', slctId: 'fewSeveral' },
  { label: 'Several', value: 'SEVERAL', slctId: 'several' },
  { label: 'Several-Numerous', value: 'SEVERAL_NUMEROUS', slctId: 'severalNumerous' },
  { label: 'Numerous', value: 'NUMEROUS', slctId: 'numerous' },
];

export const OPTS_DONOREVAL_DESCMEM_DFCTSTYPE = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Descemet\'s Tears', value: 'DESCEMETSTEARS', slctId: 'descemetsTears' },
  { label: 'Defect at IOL Scar', value: 'DEFECTIOLSCAR', slctId: 'defectIolScar' }
];

export const OPTS_DONOREVAL_DESCMEM_FLDSAMNT = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Mild', value: 'MILD', slctId: 'mild' },
  { label: 'Mild-Moderate', value: 'MILD_MODERATE', slctId: 'mildModerate' },
  { label: 'Moderate', value: 'MODERATE', slctId: 'moderate' },
  { label: 'Moderate-Severe', value: 'MODERATE_SEVERE', slctId: 'moderateSevere' },
  { label: 'Severe', value: 'SEVERE', slctId: 'severe' }
];

export const OPTS_DONOREVAL_DESCMEM_FLDSRELNUM = [
  { label: 'Few', value: 'FEW', slctId: 'few' },
  { label: 'Few-Several', value: 'FEW_SEVERAL', slctId: 'fewSeveral' },
  { label: 'Several', value: 'SEVERAL', slctId: 'several' },
  { label: 'Several-Numerous', value: 'SEVERAL_NUMEROUS', slctId: 'severalNumerous' },
  { label: 'Numerous', value: 'NUMEROUS', slctId: 'numerous' },
];

export const OPTS_DONOREVAL_ENDLM_STRSLOC = [
  { label: 'Central', value: 'CENTRAL', slctId: 'central' },
  { label: 'Para central', value: 'PARACENTRAL', slctId: 'paracentral' },
  { label: 'Peripheral', value: 'PERIPHERAL', slctId: 'peripheral' },
  { label: 'Mid-Peripheral', value: 'MIDPERIPHERAL', slctId: 'midPeripheral' },
  { label: 'Diffuse', value: 'DIFFUSE', slctId: 'diffuse' },
];

export const OPTS_DONOREVAL_ENDLM_STRSLNRELNUM = [
  { label: 'Few', value: 'FEW', slctId: 'few' },
  { label: 'Few-Several', value: 'FEW_SEVERAL', slctId: 'fewSeveral' },
  { label: 'Several', value: 'SEVERAL', slctId: 'several' },
  { label: 'Several-Numerous', value: 'SEVERAL_NUMEROUS', slctId: 'severalNumerous' },
  { label: 'Numerous', value: 'NUMEROUS', slctId: 'numerous' },
];

export const OPTS_DONOREVAL_ENDLM_CLDPUTAMNT = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Mild', value: 'MILD', slctId: 'mild' },
  { label: 'Mild-Moderate', value: 'MILD_MODERATE', slctId: 'mildModerate' },
  { label: 'Moderate', value: 'MODERATE', slctId: 'moderate' },
  { label: 'Moderate-Severe', value: 'MODERATE_SEVERE', slctId: 'moderateSevere' },
  { label: 'Severe', value: 'SEVERE', slctId: 'severe' }
];

export const OPTS_DONOREVAL_ENDLM_CLDPUTLOC = [
  { label: 'Central', value: 'CENTRAL', slctId: 'central' },
  { label: 'Para central', value: 'PARACENTRAL', slctId: 'paracentral' },
  { label: 'Peripheral', value: 'PERIPHERAL', slctId: 'peripheral' },
  { label: 'Mid-Peripheral', value: 'MIDPERIPHERAL', slctId: 'midPeripheral' },
  { label: 'Diffuse', value: 'DIFFUSE', slctId: 'diffuse' }
];

export const OPTS_DONOREVAL_ENDLM_PLYMGTSM = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Mild', value: 'MILD', slctId: 'mild' },
  { label: 'Mild-Moderate', value: 'MILD_MODERATE', slctId: 'mildModerate' },
  { label: 'Moderate', value: 'MODERATE', slctId: 'moderate' },
  { label: 'Moderate-Severe', value: 'MODERATE_SEVERE', slctId: 'moderateSevere' },
  { label: 'Severe', value: 'SEVERE', slctId: 'severe' }
];

export const OPTS_DONOREVAL_ENDLM_PLEOMRPSHM = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Mild', value: 'MILD', slctId: 'mild' },
  { label: 'Mild-Moderate', value: 'MILD_MODERATE', slctId: 'mildModerate' },
  { label: 'Moderate', value: 'MODERATE', slctId: 'moderate' },
  { label: 'Moderate-Severe', value: 'MODERATE_SEVERE', slctId: 'moderateSevere' },
  { label: 'Severe', value: 'SEVERE', slctId: 'severe' }
];

export const OPTS_DONOREVAL_ADNLINFO_JAUNDICE = [
  { label: 'None', value: 'NONE', slctId: 'none' },
  { label: 'Mild', value: 'MILD', slctId: 'mild' },
  { label: 'Mild-Moderate', value: 'MILD_MODERATE', slctId: 'mildModerate' },
  { label: 'Moderate', value: 'MODERATE', slctId: 'moderate' },
  { label: 'Moderate-Severe', value: 'MODERATE_SEVERE', slctId: 'moderateSevere' },
  { label: 'Severe', value: 'SEVERE', slctId: 'severe' }
];

export const OPTS_DONOREVAL_ADNLINFO_LENS = [
  { label: 'Phakic', value: 'PHAKIC', slctId: 'phakic' },
  { label: 'Pseudophakic', value: 'PSEUDOPHAKIC', slctId: 'pseudopakic' },
  { label: 'Aphakic', value: 'APHAKIC', slctId: 'aphakic' },
];

export const OPTS_DONOREVAL_ADNLINFO_SCLRLRMEVAL = [
  { label: 'Regular', value: 'REGULAR', slctId: 'regular' },
  { label: 'Irregular', value: 'IRREGULAR', slctId: 'irregular' }
];

export const OPTS_DONOREVAL_APRVL_NTSTBLSTRMA = [
  { label: 'Prior refactive surgery', value: 'PRIORREFRACTIVESURGERY', slctId: 'priorRefractiveSurgery' },
  { label: 'Scar', value: 'SCAR', slctId: 'scar' },
  { label: 'Infiltrate', value: 'INFILTRATE', slctId: 'infiltrate' },
  { label: 'Foreign Body', value: 'FOREIGNBODY', slctId: 'foreignBody' }
];

export const OPTS_DONOREVAL_CRNASTATUS = [
  { label: 'Viable', value: 'VIABLE', slctId: 'viable' },
  { label: 'Non Viable', value: 'NON_VIABLE', slctId: 'nonViable' }
];

export const OPTS_DONOREVAL_SEROLOGY = [
  { label: 'Reactive', value: 'REACTIVE', slctId: 'reactive' },
  { label: 'Non Reactive', value: 'NON_REACTIVE', slctId: 'nonReactive' }
];

export const OPTS_DONOREVAL_HBSAG_HCV = [
  { label: 'Positive', value: 'POSITIVE', slctId: 'positive' },
  { label: 'Negative', value: 'NEGATIVE', slctId: 'negative' }
];

export const OPTS_DONOREVAL_NONVIABLEUSAGE = [
  { label: 'Training/Research', value: 'TRAININGRESEARCH', slctId: 'trainingResearch' },
  { label: 'Discarded', value: 'DISCARDED', slctId: 'discarded' }
];

export const OPTS_DONOREVAL_CONTRAINDICATION = [
  { label: 'Academic Training Research', value: 'ACADEMICRESEARCH', slctId: 'academicResearch' },
  { label: 'Discarded', value: 'DISCARDED', slctId: 'discarded' }
];

export const OPTS_KRTPLSTYRPT_SURGICALTECHQ = [
  { label: 'Full Thickness', value: 'FULLTHICKNESS', slctId: 'fullThickness' },
  { label: 'Lameller', value: 'LAMELLER', slctId: 'lameller' },
  { label: 'Other', value: 'OTHER', slctId: 'other' }
];

export const OPTS_KRTPLSTYRPT_CATARACT = [
  { label: 'ECCE Extra Capsular Catract Extraction', value: 'ECCE', slctId: 'ecce' },
  { label: 'ICCE Intra Capsular Catract Extraction', value: 'ICCE', slctId: 'icce' },
  { label: 'IOL Intra Ocular Lens Iris-fixed', value: 'IOL_IRISFIXED', slctId: 'iolIrisFixed' },
  { label: 'IOL(AC) Intra Ocular Lens (Anterior Chamber)', value: 'IOL_AC', slctId: 'iocAc' },
  { label: 'IOL(PC) Intra Ocular Lens (Posterior Chamber)', value: 'IOL_PC', slctId: 'iocPc' },
  { label: 'No', value: 'NO', slctId: 'no' },
  { label: 'Other', value: 'OTHER', slctId: 'other' }
];

export const OPTS_KRTPLSTYRPT_IOL = [
  { label: 'Anterior Chamber', value: 'AC', slctId: 'ac' },
  { label: 'Posterior Chamber', value: 'PC', slctId: 'pc' },
  { label: 'Iris-fixed', value: 'IRISFIXED', slctId: 'irisFixed' },
  { label: 'No', value: 'NO', slctId: 'no' },
  { label: 'Other', value: 'OTHER', slctId: 'other' }
];

export const OPTS_KRTPLSTYRPT_VITRECTOMY = [
  { label: 'Antsrlo', value: 'ANTSRLO', slctId: 'antsrlo' },
  { label: 'Complete', value: 'COMPLETE', slctId: 'complete' },
  { label: 'Pars Plana', value: 'PARS_PLANA', slctId: 'parsPlana' },
  { label: 'Other', value: 'OTHER', slctId: 'other' },
  { label: 'No', value: 'NO', slctId: 'no' },
  { label: 'Other', value: 'OTHER', slctId: 'other' }
];

export const OPTS_KRTPLSTYRPT_SUTURESIZETYPE = [
  { label: '10-0 Nylon', value: '10_0_NYLON', slctId: '100Nylon' },
  { label: 'Other', value: 'OTHER', slctId: 'other' }
];

export const OPTS_KRTPLSTYRPT_SUTURETECHQ = [
  { label: 'Interrupted', value: 'INTERRUPTED', slctId: 'interrupted' },
  { label: 'Running', value: 'RUNNING', slctId: 'running' },
  { label: 'Other', value: 'OTHER', slctId: 'other' }
];

export const OPTS_KRTPLSTYRPT_OPERATEDEYES = [
  { label: 'Left', value: 'Left', slctId: 'left' },
  { label: 'Right', value: 'Right', slctId: 'right' }
];

export const OPTS_KRTPLSTYRPT_PREOPDIAG = [
  { label: 'Aphakic', value: 'APHAKIC', slctId: 'aphakic' },
  { label: 'Ohakic', value: 'OHAKIC', slctId: 'ohakic' },
  { label: 'Pseudophakic(AC)', value: 'PSEUDOPHAKIC_AC', slctId: 'pseudophakicAc' },
  { label: 'Pseudophakic(PC)', value: 'PSEUDOPHAKIC_PC', slctId: 'pseudophakicPc' },
  {
    label: 'Pseudophakic(ric-fixed)',
    value: 'PSEUDOPHAKIC_RICFIXED',
    slctId: 'pseudophakicRicfixed'
  },
  { label: 'Other', value: 'OTHER', slctId: 'other' }
];

export const OPTS_RECIPIENT_INDICOFKRTPLSTY = [
  { label: 'Optical', value: 'OPTICAL', slctId: 'optical' },
  { label: 'Penetrating Keratoplasty', value: 'PKP', slctId: 'pkp' },
  { label: 'Endothelial Keratoplasty', value: 'EK', slctId: 'ek' },
  { label: 'Anterior Lamellar Keratoplasty', value: 'ALK', slctId: 'alk' },
  { label: 'Keratolimbal Allograft', value: 'KLA', slctId: 'kla' },
  { label: 'Keratoprosthesis', value: 'K_PRO', slctId: 'kPro' },
  { label: 'Therapeutic', value: 'THERAPEUTIC', slctId: 'therapeutic' },
  { label: 'Other', value: 'OTHER', slctId: 'other' }
];

export const OPTS_RECIPIENT_PRIORITIZINGCATG = [
  { label: 'Perforation / Emergency (1)', value: '1', slctId: 'emergency' },
  { label: 'One Eyed Corneal Blind (2)', value: '2', slctId: 'oneEyed' },
  { label: 'Bilateral Corneal Blind (3)', value: '3', slctId: 'bilateral' },
  { label: 'Corneal Blind Child below 7 years (4)', value: '4', slctId: 'cornealBlind' },
  { label: 'Other Corneal Blind (5)', value: '5', slctId: 'other' }
];

export const OPTS_RECIPIENT_TISSUETYPE = [
  {
    label: 'Cornea with Scleral Rim',
    value: 'COREA_WITH_SCLERAL_RIM',
    slctId: 'coreaWithScleralRim'
  },
  { label: 'Whole Eyeball', value: 'WHOLE_EYEBALL', slctId: 'wholeEyeball' },
  { label: 'Sclera', value: 'SCLERA', slctId: 'sclera' },
  { label: 'Pre-Cut', value: 'PRE_CUT', slctId: 'preCut' }
];

export const OPTS_RECIPIENT_TISSUENUMBER = [
  // { label: 'Both Eyes', value: 'BOTHEYES', slctId: 'bothEyes' },
  { label: 'Left Eye', value: 'LEFTEYE', slctId: 'leftEye' },
  { label: 'Right Eye', value: 'RIGHTEYE', slctId: 'rightEye' }
];

export const OPTS_RECIPIENT_IDPROOF = [
  { label: 'Aadhar Card', value: 'AADHAR', slctId: 'adhaar' },
  { label: 'PAN Card', value: 'PAN', slctId: 'pan' },
  { label: 'Passport', value: 'PASSPORT', slctId: 'passport' },
  { label: 'Driving Licence,', value: 'DRIVING_LICENCE,', slctId: 'drivinglicence' },
  { label: 'Voter Id Card', value: 'VOTER_ID', slctId: 'voterid' },
  { label: 'MGNREGA job card', value: 'MGNREGA', slctId: 'mgnrega' },
  { label: 'Health Insurance Card issued by Ministry of Labour', value: 'INCURANCE_CARD', slctId: 'insurancecard' },
];

export const MATCHING_FORMSTATUS = {
  NEW: 'NEW',
  SUBMITTED: 'SUBMITTED'
};

export const OPTIONS_MATCHINGSTATUS = {
  ADDED: 'ADDED',
  OPERATED: 'OPERATED',
  RETURNED: 'RETURNED',
  RECALLED: 'RECALLED',
};

export const OPTIONS_EYEDONORSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED'
};

export const EYE_SIDE = {
  LEFTEYE: { label: 'Left', value: OPTS_DONOR_TISSUENUMBER[1].value },
  RIGHTEYE: { label: 'Right', value: OPTS_DONOR_TISSUENUMBER[2].value }
};

export const OPTIONS_EYEDONORLISTTABS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  EVALUATED: 'EVALUATED',
  MATCHED: 'MATCHED',
};

export const OPTIONS_EYERECIPIENTLISTTABS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  MATCHED: 'MATCHED',
};

export const OPTIONS_EYEDONORCORNEASTATUS = {
  ADDED: 'ADDED',
  EVALUATED_VIABLE: 'EVALUATED_VIABLE',
  EVALUATED_NONVIABLE: 'EVALUATED_NONVIABLE',
  ALLOCATED: 'ALLOCATED',
  OPERATED: 'OPERATED',
};

export const OPTIONS_EYERECIPIENTCORNEASTATUS = {
  ADDED: 'ADDED',
  ALLOCATED: 'ALLOCATED',
  OPERATED: 'OPERATED',
};

export const OPTIONS_EYERECIPIENTMATCHEDSTATUS = {
  ADDED: 'ADDED',
  ALLOCATED: 'ALLOCATED',
  OPERATED: 'OPERATED',
};

export const OPTIONS_EYERECIPIENTSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED'
};

const CONTEXT_DHS = '/dhs';
const CONTEXT_DMER = '/dmer';
const CONTEXT_HOSPITAL = '/hospital';

const CONTEXT_EYEBANK = '/eyebank';
export const PATH_HOSPITAL_GETEYERETRIEVALCENTERS = `${CONTEXT_HOSPITAL}/%s/eyeRetrievalCenters`;

const CONTEXT_REGISTRATION = `${CONTEXT_EYEBANK}/regn`;

const CONTEXT_FORM15EB = `${CONTEXT_REGISTRATION}/form15Eb`;
export const PATH_FORM15EB_HOSPITAL_SUBMITDRAFTFORM5EB = `${CONTEXT_FORM15EB + CONTEXT_HOSPITAL}/%s`;
export const PATH_FORM15EB_HOSPITAL_GETFORM15EBSUMMARIES = `${CONTEXT_FORM15EB + CONTEXT_HOSPITAL}/%s/form15EbSummaries`;
export const PATH_FORM15EB_DHS_GETFORM15EBSUMMARIES = `${CONTEXT_FORM15EB + CONTEXT_DHS}/%s/form15EbSummaries`;
export const PATH_FORM15EB_HOSPITAL_GETDRAFTFORM15EB = `${CONTEXT_FORM15EB + CONTEXT_HOSPITAL}/%s`;
export const PATH_FORM15EB_DHS_GETINSPECTION = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/inspection`;
export const PATH_FORM15EB_DHS_ADDINSPECTIONDATE = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/inspectionDate`;
export const PATH_FORM15EB_DHS_ADDINSPECTIONREPORT = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/inspectionReport`;
export const PATH_FORM15EB_DHS_ADDINSPECTIONCOMMITTEE = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/inspectionCommittee`;
export const PATH_FORM15EB_GETISSUES = `${CONTEXT_FORM15EB}/%s/issues`;
export const PATH_FORM15EB_DHS_ADDISSUE = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/issue`;
export const PATH_FORM15EB_DHS_UPDATEISSUESTATUS = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/issue`;
export const PATH_FORM15EB_DHS_GETFORM15EB = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}`;
export const PATH_FORM15EB_HOSPITAL_GETFORM15EB = `${CONTEXT_FORM15EB}/%s${CONTEXT_HOSPITAL}/%s`;
export const PATH_FORM15EB_DHS_SIGNFORM15EB = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/signature`;
export const PATH_FORM15EB_DHS_APPROVEFORM15EB = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/approval`;
export const PATH_FORM15EB_DHS_GETAPPROVAL = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/approval`;
export const PATH_FORM15EB_HOSPITAL_GETAPPROVAL = `${CONTEXT_FORM15EB}/%s${CONTEXT_HOSPITAL}/%s/approval`;
export const PATH_FORM15EB_DHS_ADDCERTIFICATE = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/certificate`;
export const PATH_FORM15EB_DHS_REVERTFORM15EB = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/revert`;
export const PATH_FORM15EB_DHS_ADDNOTING = `${CONTEXT_FORM15EB}/%s${CONTEXT_DHS}/noting`;
export const PATH_FORM15EB_HOSPITAL_SUBMITUNDERTAKING = `${CONTEXT_FORM15EB + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_FORM15EB_HOSPITAL_UPDATEUNDERTAKING = `${CONTEXT_FORM15EB + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_FORM15EB_HOSPITAL_UPDATEFORM15EBSTATUS = `${CONTEXT_FORM15EB + CONTEXT_HOSPITAL}/%s/status`;
export const PATH_FORM15EB_HOSPITAL_SUBMITFORM15EBEDITS = `${CONTEXT_FORM15EB + CONTEXT_HOSPITAL}/%s`;

const CONTEXT_FORM15ERC = `${CONTEXT_REGISTRATION}/form15Erc`;
export const PATH_FORM15ERC_HOSPITAL_SUBMITDRAFTFORM15ERC = `${CONTEXT_FORM15ERC + CONTEXT_HOSPITAL}/%s`;
export const PATH_FORM15ERC_HOSPITAL_GETFORM15ERCSUMMARIES = `${CONTEXT_FORM15ERC + CONTEXT_HOSPITAL}/%s/form15ErcSummaries`;
export const PATH_FORM15ERC_DHS_GETFORM15ERCSUMMARIES = `${CONTEXT_FORM15ERC + CONTEXT_DHS}/%s/form15ErcSummaries`;
export const PATH_FORM15ERC_HOSPITAL_GETDRAFTFORM15ERC = `${CONTEXT_FORM15ERC + CONTEXT_HOSPITAL}/%s`;
export const PATH_FORM15ERC_DHS_GETINSPECTION = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/inspection`;
export const PATH_FORM15ERC_DHS_ADDINSPECTIONDATE = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/inspectionDate`;
export const PATH_FORM15ERC_DHS_ADDINSPECTIONREPORT = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/inspectionReport`;
export const PATH_FORM15ERC_DHS_ADDINSPECTIONCOMMITTEE = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/inspectionCommittee`;
export const PATH_FORM15ERC_GETISSUES = `${CONTEXT_FORM15ERC}/%s/issues`;
export const PATH_FORM15ERC_DHS_ADDISSUE = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/issue`;
export const PATH_FORM15ERC_DHS_UPDATEISSUESTATUS = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/issue`;
export const PATH_FORM15ERC_DHS_GETFORM15ERC = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}`;
export const PATH_FORM15ERC_HOSPITAL_GETFORM15ERC = `${CONTEXT_FORM15ERC}/%s${CONTEXT_HOSPITAL}/%s`;
export const PATH_FORM15ERC_DHS_SIGNFORM15ERC = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/signature`;
export const PATH_FORM15ERC_DHS_APPROVEFORM15ERC = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/approval`;
export const PATH_FORM15ERC_DHS_GETAPPROVAL = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/approval`;
export const PATH_FORM15ERC_HOSPITAL_GETAPPROVAL = `${CONTEXT_FORM15ERC}/%s${CONTEXT_HOSPITAL}/%s/approval`;
export const PATH_FORM15ERC_DHS_ADDCERTIFICATE = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/certificate`;
export const PATH_FORM15ERC_DHS_REVERTFORM15ERC = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/revert`;
export const PATH_FORM15ERC_DHS_ADDNOTING = `${CONTEXT_FORM15ERC}/%s${CONTEXT_DHS}/noting`;
export const PATH_FORM15ERC_HOSPITAL_SUBMITUNDERTAKING = `${CONTEXT_FORM15ERC + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_FORM15ERC_HOSPITAL_UPDATEUNDERTAKING = `${CONTEXT_FORM15ERC + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_FORM15ERC_HOSPITAL_UPDATEFORM15ERCSTATUS = `${CONTEXT_FORM15ERC + CONTEXT_HOSPITAL}/%s/status`;
export const PATH_FORM15ERC_HOSPITAL_SUBMITFORM15ERCEDITS = `${CONTEXT_FORM15ERC + CONTEXT_HOSPITAL}/%s`;

const CONTEXT_FORM15CTC = `${CONTEXT_REGISTRATION}/form15Ctc`;
export const PATH_FORM15CTC_HOSPITAL_SUBMITDRAFTFORM15CTC = `${CONTEXT_FORM15CTC + CONTEXT_HOSPITAL}/%s`;
export const PATH_FORM15CTC_HOSPITAL_GETFORM15CTCSUMMARIES = `${CONTEXT_FORM15CTC + CONTEXT_HOSPITAL}/%s/form15CtcSummaries`;
export const PATH_FORM15CTC_DHS_GETFORM15CTCSUMMARIES = `${CONTEXT_FORM15CTC + CONTEXT_DHS}/%s/form15CtcSummaries`;
export const PATH_FORM15CTC_HOSPITAL_GETDRAFTFORM15CTC = `${CONTEXT_FORM15CTC + CONTEXT_HOSPITAL}/%s`;
export const PATH_FORM15CTC_DHS_GETINSPECTION = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/inspection`;
export const PATH_FORM15CTC_DHS_ADDINSPECTIONDATE = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/inspectionDate`;
export const PATH_FORM15CTC_DHS_ADDINSPECTIONREPORT = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/inspectionReport`;
export const PATH_FORM15CTC_DHS_ADDINSPECTIONCOMMITTEE = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/inspectionCommittee`;
export const PATH_FORM15CTC_GETISSUES = `${CONTEXT_FORM15CTC}/%s/issues`;
export const PATH_FORM15CTC_DHS_ADDISSUE = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/issue`;
export const PATH_FORM15CTC_DHS_UPDATEISSUESTATUS = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/issue`;
export const PATH_FORM15CTC_DHS_GETFORM15CTC = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}`;
export const PATH_FORM15CTC_HOSPITAL_GETFORM15CTC = `${CONTEXT_FORM15CTC}/%s${CONTEXT_HOSPITAL}/%s`;
export const PATH_FORM15CTC_DHS_SIGNFORM15CTC = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/signature`;
export const PATH_FORM15CTC_DHS_APPROVEFORM15CTC = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/approval`;
export const PATH_FORM15CTC_DHS_GETAPPROVAL = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/approval`;
export const PATH_FORM15CTC_HOSPITAL_GETAPPROVAL = `${CONTEXT_FORM15CTC}/%s${CONTEXT_HOSPITAL}/%s/approval`;
export const PATH_FORM15CTC_DHS_ADDCERTIFICATE = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/certificate`;
export const PATH_FORM15CTC_DHS_REVERTFORM15CTC = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/revert`;
export const PATH_FORM15CTC_DHS_ADDNOTING = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/noting`;
export const PATH_FORM15CTC_HOSPITAL_SUBMITUNDERTAKING = `${CONTEXT_FORM15CTC + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_FORM15CTC_HOSPITAL_UPDATEUNDERTAKING = `${CONTEXT_FORM15CTC + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_FORM15CTC_HOSPITAL_UPDATEFORM15CTCSTATUS = `${CONTEXT_FORM15CTC + CONTEXT_HOSPITAL}/%s/status`;
export const PATH_FORM15CTC_HOSPITAL_SUBMITFORM15CTCEDITS = `${CONTEXT_FORM15CTC + CONTEXT_HOSPITAL}/%s`;
export const PATH_FORM15CTC_DHS_GETSPECIALISTCHANGEREQUESTS = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/specialistChangeRequests`;
export const PATH_FORM15CTC_HOSPITAL_GETSPECIALISTCHANGEREQUESTS = `${CONTEXT_FORM15CTC}/%s${CONTEXT_HOSPITAL}/specialistChangeRequests`;
export const PATH_FORM15CTC_HOSPITAL_SUBMITSPECIALISTCHANGEREQUESTS = `${CONTEXT_FORM15CTC}/%s${CONTEXT_HOSPITAL}/specialistChange`;
export const PATH_FORM15CTC_HOSPITAL_UPDATESPECIALISTCHANGEREQUEST = `${CONTEXT_FORM15CTC}/%s${CONTEXT_HOSPITAL}/specialistUpdate/%s`;
export const PATH_FORM15CTC_DHS_UPDATESPECIALISTCHANGEREQUEST = `${CONTEXT_FORM15CTC}/%s${CONTEXT_DHS}/specialistUpdate/%s`;

const CONTEXT_TRANSPLANT = `${CONTEXT_EYEBANK}/tx`;

const CONTEXT_EYEDONOR = 'eyeDonor';

export const PATH_HOSPITAL_SUBMITDRAFTEYEDONOR = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeDonor/draft`;
export const PATH_HOSPITAL_SUBMITEYEDONOREDITS = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeDonor/edits/%s`;
export const PATH_HOSPITAL_SUBMITEYEDONOR = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeDonor/draft/%s/status`;
export const PATH_HOSPITAL_GETDRAFTEYEDONOR = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeDonor`;
export const PATH_HOSPITAL_GETEYEDONOR = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/${CONTEXT_EYEDONOR}/%s`;
export const PATH_HOSPITAL_GETEYEDONORSUMMARIES = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeDonorSummaries`;
export const PATH_DHS_GETEYEDONORS = `${CONTEXT_TRANSPLANT + CONTEXT_DHS}/%s/eyeDonors`;
export const PATH_DHS_GETEYEDONOR = `${CONTEXT_TRANSPLANT + CONTEXT_DHS}/%s/eyeDonor/%s`;

const CONTEXT_EYERECIPIENT = 'eyeRecipient';

export const PATH_HOSPITAL_SUBMITDRAFTEYERECIPIENT = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeRecipient/draft`;
export const PATH_HOSPITAL_SUBMITEYERECIPIENTEDITS = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeRecipient/edits/%s`;
export const PATH_HOSPITAL_SUBMITEYERECIPIENT = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeRecipient/draft/%s/status`;
export const PATH_HOSPITAL_GETDRAFTEYERECIPIENT = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeRecipient`;
export const PATH_HOSPITAL_GETEYERECIPIENT = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/${CONTEXT_EYERECIPIENT}/%s`;
export const PATH_HOSPITAL_GETEYERECIPIENTSUMMARIES = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeRecipientSummaries`;
export const PATH_HOSPITAL_CHANGEEYERECIPIENTSTATUS = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeRecipient/recipientStatus`;
export const PATH_DHS_GETEYERECIPIENTS = `${CONTEXT_TRANSPLANT + CONTEXT_DHS}/%s/eyeRecipients`;
export const PATH_DHS_GETEYERECIPIENT = `${CONTEXT_TRANSPLANT + CONTEXT_DHS}/%s/eyeRecipient/%s`;

export const PATH_EYEBANK_DHS_GETMATCHING = `${CONTEXT_TRANSPLANT + CONTEXT_DHS}/matching/%s`;
export const PATH_EYEBANK_DHS_GETCORNEAMATCHINGSUMMARIES = `${CONTEXT_TRANSPLANT + CONTEXT_DHS}/summaries`;

export const PATH_EYEBANK_HOSP_SUBMITMATCHING = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/matching`;
export const PATH_EYEBANK_HOSP_CHANGEMATCHINGSTATUS = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/matching/%s`;
export const PATH_EYEBANK_HOSP_GETMATCHING = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/matching/%s`;

export const PATH_EYEBANK_HOSP_GETCORNEAMATCHINGSUMMARIES = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/summary/%s`;

export const PATH_KERATOPLASTY_SUBMITREPORT = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/keratoplastyReport`;
export const PATH_KERATOPLASTY_SUBMITEDITS = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/keratoplastyReport/%s/cornea/%s/edits`;
export const PATH_KERATOPLASTY_GETREPORT = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/keratoplastyReport/%s/cornea/%s`;

export const PATH_DONOREVALUATION_GETREPORT = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/donorEvaluation/%s/cornea/%s`;
export const PATH_DONOREVALUATION_SUBMITREPORT = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/donorEvaluation`;
export const PATH_DONOREVALUATION_SUBMITEDITS = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/donorEvaluation/%s/cornea/%s/edits`;

export const PATH_EYERECIPIENTANDKERATOPLASTY_GETREPORT = `${CONTEXT_TRANSPLANT + CONTEXT_HOSPITAL}/%s/eyeRecipientDetailsAndKeratoplastyReport`;

export const CONTEXT_TX_REPORTING = `${CONTEXT_TRANSPLANT}/report`;

export const PATH_REPORT_DHS_GETSURGERYREPORTKERATOPLASTYCENTERWISEDATA = `${CONTEXT_TX_REPORTING + CONTEXT_DHS}/surgeryReportKeratoplastyCenterwise`;
export const PATH_REPORT_DHS_GETINSTITUTEHOTAREGISTRATIONREPORTDATA = `${CONTEXT_TX_REPORTING + CONTEXT_DHS}/instituteHOTARegstrationReport`;

export const PATH_REPORT_HOSPITAL_GETSURGERYREPORTKERATOPLASTYCENTERWISEDATA = `${CONTEXT_TX_REPORTING + CONTEXT_HOSPITAL}/%s/surgeryReportKeratoplastyCenterwise`
export const PATH_REPORT_HOSPITAL_GETINSTITUTEHOTAREGISTRATIONREPORTDATA = `${CONTEXT_TX_REPORTING + CONTEXT_HOSPITAL}/%s/instituteHOTARegstrationReport`;
export const PATH_REPORT_HOSPITAL_GETSURPLUSUTILIZATIONREPORTDATA = `${CONTEXT_TX_REPORTING + CONTEXT_HOSPITAL}/%s/surplusUtilizationReport`;
export const PATH_REPORT_HOSPITAL_GETRECIPIENTWAITINGLISTDATA = `${CONTEXT_TX_REPORTING + CONTEXT_HOSPITAL}/%s/recipientWaitingList`;
export const PATH_REPORT_HOSPITAL_GETCORNEAUTILIZATIONREPORTDATA = `${CONTEXT_TX_REPORTING + CONTEXT_HOSPITAL}/%s/corneaUtilizationReport`;
export const PATH_REPORT_HOSPITAL_GETREFUSALFORTREATMENTDATA = `${CONTEXT_TX_REPORTING + CONTEXT_HOSPITAL}/%s/refusalForTreatment`;

export const PATH_REPORT_DMER_GETSURGERYREPORTKERATOPLASTYCENTERWISEDATA = `${CONTEXT_TX_REPORTING + CONTEXT_DMER}/surgeryReportKeratoplastyCenterwise`;
export const PATH_REPORT_DMER_GETINSTITUTEHOTAREGISTRATIONREPORTDATA = `${CONTEXT_TX_REPORTING + CONTEXT_DMER}/instituteHOTARegstrationReport`;


export const OPTIONS_FORM15CTCSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  ATTESTED: 'ATTESTED',
  INITIATED: 'INITIATED',
  APPROVED: 'APPROVED',
  REVERTED: 'REVERTED',
  RESUBMITTED: 'RESUBMITTED',
  REATTESTED: 'REATTESTED'
};

export const OPTIONS_FORM15EBSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  ATTESTED: 'ATTESTED',
  INITIATED: 'INITIATED',
  APPROVED: 'APPROVED',
  REVERTED: 'REVERTED',
  RESUBMITTED: 'RESUBMITTED',
  REATTESTED: 'REATTESTED'
};

export const OPTIONS_FORM15ERCSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  ATTESTED: 'ATTESTED',
  INITIATED: 'INITIATED',
  APPROVED: 'APPROVED',
  REVERTED: 'REVERTED',
  RESUBMITTED: 'RESUBMITTED',
  REATTESTED: 'REATTESTED'
};

export const OPTIONS_KERATOPLASTYREPORTFORM_STATUS = {
  NEW: 'NEW',
  SUBMITTED: 'SUBMITTED',
};

export const OPTIONS_DONOREVALUATIONFORM_STATUS = {
  NEW: 'NEW',
  SUBMITTED: 'SUBMITTED',
};

export const EYEBANK_REPORTS = {
  CORNEAUTILIZATIONREPORT: { label: 'Cornea Utilization Report', value: 'CorneaUtilizationReport', slctId: 'CorneaUtilizationReport' },
  RECIPIENTWAITINGLIST: { label: 'Recipient Waiting List', value: 'RecipientWaitingList', slctId: 'RecipientWaitingList' },
  SURGERYREPORTKERATOPLASTYCENTERWISE: { label: 'Surgery Report Keratoplasty Center wise', value: 'SurgeryReportKeratoplastyCenterwise', slctId: 'SurgeryReportKeratoplastyCenterwise' },
  INSTITUTEHOTAREGISTRATIONREPORT: { label: 'Institute HOTA Regstration Report', value: 'InstituteHOTARegstrationReport', slctId: 'InstituteHOTARegstrationReport' },
  REFUSALOFTREATMENT: { label: 'Refusal of Treatment', value: 'RefusalOfTreatment', slctId: 'RefusalOfTreatment' },
  SURPLUSUTILIZATIONREPORT: { label: 'Surplus Utilization Report', value: 'SurplusUtilizationReport', slctId: 'SurplusUtilizationReport' }
};

export const FORM15CTC_BLOCKCHAIN_AYUDAAN_EVENTS = {
  HOSPICADDED: { label: 'HospitalInchargeAdded', value: 'HospitalInchargeAdded' },
  USETADDED: { label: 'UserAdded', value: 'UserAdded' },
  REGULATORADDED: { label: 'RegulatorAdded', value: 'RegulatorAdded' },
  INSPECTORADDED: { label: 'InspectorAdded', value: 'InspectorAdded' },
  SPECIALISTADDED: { label: 'SpecialistAdded', value: 'SpecialistAdded' },
  SPECIALISTAPPROVED: { label: 'SpecialistApproved', value: 'SpecialistApproved' },
  HOSPITALREGISTERED: { label: 'HospitalRegistered', value: 'HospitalRegistered' },
  FORM15CTCADDED: { label: 'Form15CtcAdded', value: 'Form15CtcAdded' }
};

export const FORM15EB_BLOCKCHAIN_AYUDAAN_EVENTS = {
  HOSPICADDED: { label: 'HospitalInchargeAdded', value: 'HospitalInchargeAdded' },
  USETADDED: { label: 'UserAdded', value: 'UserAdded' },
  REGULATORADDED: { label: 'RegulatorAdded', value: 'RegulatorAdded' },
  INSPECTORADDED: { label: 'InspectorAdded', value: 'InspectorAdded' },
  SPECIALISTADDED: { label: 'SpecialistAdded', value: 'SpecialistAdded' },
  SPECIALISTAPPROVED: { label: 'SpecialistApproved', value: 'SpecialistApproved' },
  HOSPITALREGISTERED: { label: 'HospitalRegistered', value: 'HospitalRegistered' },
  FORM15EBADDED: { label: 'Form15EbAdded', value: 'Form15EbAdded' },
  TRANSPLANTADDED: { label: 'TransplantAdded', value: 'TransplantAdded' }
};

export const FORM15ERC_BLOCKCHAIN_AYUDAAN_EVENTS = {
  HOSPICADDED: { label: 'HospitalInchargeAdded', value: 'HospitalInchargeAdded' },
  USETADDED: { label: 'UserAdded', value: 'UserAdded' },
  REGULATORADDED: { label: 'RegulatorAdded', value: 'RegulatorAdded' },
  INSPECTORADDED: { label: 'InspectorAdded', value: 'InspectorAdded' },
  SPECIALISTADDED: { label: 'SpecialistAdded', value: 'SpecialistAdded' },
  SPECIALISTAPPROVED: { label: 'SpecialistApproved', value: 'SpecialistApproved' },
  HOSPITALREGISTERED: { label: 'HospitalRegistered', value: 'HospitalRegistered' },
  FORM15ERCADDED: { label: 'Form15ErcAdded', value: 'Form15ErcAdded' },
  TRANSPLANTADDED: { label: 'TransplantAdded', value: 'TransplantAdded' }
};

export const FORM15CTC_FILEPREFIX = {
  STAFFDETAILS_NOOFPERMANENTSTAFF: 'SDNPS',
  STAFFDETAILS_NOOFTEMPORARYSTAFF: 'SDNTS',
  STAFFDETAILS_TRAINEDPERSONNEL: 'SDTP',
  EQUIPMENT_EQUIPMENTS: 'EQPEP',
  OTFACILITIES: 'OTFCL',
  SAFESTORAGEFACILITIES: 'SFSTRF',
  RECORDS_REGISTRATION: 'RDRGS'
};

export const FORM15EB_FILEPREFIX = {
  EYEBALLREMOVAL_TRAINEDPERSONNEL: 'EBRTP',
  EYEBALLREMOVAL_DESIGNATEDSTAFF: 'EBRDS',
  EYEBALLREMOVAL_TISSUEREQUESTREGISTER: 'EBRTRR',
  EYEBALLREMOVAL_TRANSPORTFACILITY: 'EBRTF',
  EYEBALLREMOVAL_CORNEAREMOVALINSTRUMENT: 'EBRCRI',
  EYEBALLREMOVAL_CORNEAPRESERVATIONBOTTLES: 'EBRCPB',
  EYEBALLREMOVAL_PRESERVATIONMEDIA: 'EBRPM',
  EYEBALLREMOVAL_BIOMEDICALREGNCERT: 'EBRBMRC',
  EYEBALLREMOVAL_POWERSUPPLY: 'EBRPS',
  MANPOWER_INCHARGE: 'MPIN',
  MANPOWER_TECHNICIAN: 'MPTC',
  MANPOWER_EYEDONATIONCOUNSELORS: 'MPEDC',
  MANPOWER_MULTITASKSTAFF: 'MPMTS',
  SPACEREQUIREMENT: 'SPCRQ',
  RECORDS_MAINTENANCEARRANGEMENT: 'RDMA',
  RECORDS_PLEDGEREGNARRANGEMENT: 'RDPRA',
  RECORDS_COMPUTER: 'RDCMP',
  EQUIPMENT_BIOMICROSCOPE: 'EQPBMS',
  EQUIPMENT_SPECULARMICROSCOPE: 'EQPSMS',
  EQUIPMENT_LAMINARFLOW: 'EQPLF',
  EQUIPMENT_STERILIZATIONFACILITY: 'EQPSF',
  EQUIPMENT_REFRIGERATOR: 'EQPRF',
  REGNRENEWAL_FIVEYEARPERIOD: 'RRFYP',
  REGNRENEWAL_FIVEHUNDREDCORNEAS: 'RRFHC',
  REGNRENEWAL_EYEBANKSTANDARDS: 'RREBS'
};

export const FORM15ERC_FILEPREFIX = {
  EYEBANKMOU: 'EBMOU',
  EYEBANKENUCLEATIONDETAILS: 'EYEBANKENUCLEATIONDETAILS',
  EYEBALLREMOVAL_MANPOWER_INCHARGE: 'EBRMPIN',
  EYEBALLREMOVAL_MANPOWER_TECHNICIAN: 'EBRMPTC',
  EYEBALLREMOVAL_MANPOWER_MULTITASKSTAFF: 'EBRMPMTS',
  EYEBALLREMOVAL_TRANSPORTFACILITY: 'EBRTF',
  AMBULANCEFORCOLLECTINGEYEBALLS: 'AFCEB',
  CORNEAREMOVALINSTRUMENTS: 'CRIN',
  CORNEAPRESERVATIONBOTTLES: 'CPB',
  PRESERVATIONMEDIA: 'PRSVM',
  BIOMEDICALREGNCERT: 'BMRC',
  SPACEREQUIREMENT: 'SPCRQ',
  RECORDS_MAINTENANCEARRANGEMENT: 'RDMA',
  EQUIPMENT_STERILIZATIONFACILITY: 'EQPSF',
  EQUIPMENT_REFRIGERATOR: 'EQPRF'
};

export const EYERECIPIENT_FILEPREFIX = {
  FULLFACEPHOTO: 'FFPH',
  PHOTOID: 'PHID',
  AFFCTDEYEPH: 'EEPH',
};

export const EYEDONOR_FILEPREFIX = {
  DEATHCERTIFICATE: 'DTHCER',
  CONSENT: 'CNST',
};

export const STATUS_FORM15EB_ISSUES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  RESOLVED: 'RESOLVED'
};

export const STATUS_FORM15ERC_ISSUES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  RESOLVED: 'RESOLVED'
};

export const STATUS_FORM15CTC_ISSUES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  RESOLVED: 'RESOLVED'
};
