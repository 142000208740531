const CONTEXT_DHS = '/dhs';
const CONTEXT_HOSPITAL = '/hospital';

const CONTEXT_ORGAN = '/eyebank';

const CONTEXT_REGISTRATION = `${CONTEXT_ORGAN}/regn`;

const CONTEXT_LEGACYFORM15ERC = `${CONTEXT_REGISTRATION}/legacyForm15Erc`;

export const PATH_LEGACYFORM15ERC_GETISSUES = `${CONTEXT_LEGACYFORM15ERC}/%s/issues`;
export const PATH_LEGACYFORM15ERC_DHS_UPDATEISSUESTATUS = `${CONTEXT_LEGACYFORM15ERC}/%s${CONTEXT_DHS}/issue`;
export const PATH_LEGACYFORM15ERC_DHS_ADDISSUE = `${CONTEXT_LEGACYFORM15ERC}/%s${CONTEXT_DHS}/issue`;


export const PATH_LEGACYFORM15ERC_DHS_GETSUMMARIES = `${CONTEXT_LEGACYFORM15ERC + CONTEXT_DHS}/legacyForm15Ercsummaries`;
export const PATH_LEGACYFORM15ERC_HOSPITAL_GETLEGACYFORM15ERCSUMMARIES = `${CONTEXT_LEGACYFORM15ERC + CONTEXT_HOSPITAL}/%s/legacyForm15Ercsummaries`;

export const PATH_LEGACYFORM15ERC_DHS_GETLEGACYFORM15ERC = `${CONTEXT_LEGACYFORM15ERC}/%s${CONTEXT_DHS}`;
export const PATH_LEGACYFORM15ERC_DHS_SIGNLEGACYFORM15ERC = `${CONTEXT_LEGACYFORM15ERC}/%s${CONTEXT_DHS}/signature`;
export const PATH_LEGACYFORM15ERC_DHS_APPROVELEGACYFORM15ERC = `${CONTEXT_LEGACYFORM15ERC}/%s${CONTEXT_DHS}/approval`;
export const PATH_LEGACYFORM15ERC_DHS_ADDCERTIFICATE = `${CONTEXT_LEGACYFORM15ERC}/%s${CONTEXT_DHS}/certificate`;
export const PATH_LEGACYFORM15ERC_DHS_GETAPPROVAL = `${CONTEXT_LEGACYFORM15ERC}/%s${CONTEXT_DHS}/approval`;
export const PATH_LEGACYFORM15ERC_DHS_REVERTFORM15ERC = `${CONTEXT_LEGACYFORM15ERC}/%s${CONTEXT_DHS}/revert`;

export const PATH_LEGACYFORM15ERC_HOSPITAL_SUBMITDRAFTLEGACYFORM15ERC = `${CONTEXT_LEGACYFORM15ERC + CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15ERC_HOSPITAL_SUBMITLEGACYFORM15ERCEDITS = `${CONTEXT_LEGACYFORM15ERC + CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15ERC_HOSPITAL_UPDATELEGACYFORM15ERCSTATUS = `${CONTEXT_LEGACYFORM15ERC + CONTEXT_HOSPITAL}/%s/status`;
export const PATH_LEGACYFORM15ERC_HOSPITAL_SUBMITUNDERTAKING = `${CONTEXT_LEGACYFORM15ERC + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_LEGACYFORM15ERC_HOSPITAL_UPDATEUNDERTAKING = `${CONTEXT_LEGACYFORM15ERC + CONTEXT_HOSPITAL}/%s/undertaking`;
export const PATH_LEGACYFORM15ERC_HOSPITAL_GETDRAFTLEGACYFORM15ERC = `${CONTEXT_LEGACYFORM15ERC + CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15ERC_HOSPITAL_GETLEGACYFORM15ERC = `${CONTEXT_LEGACYFORM15ERC}/%s${CONTEXT_HOSPITAL}/%s`;
export const PATH_LEGACYFORM15ERC_HOSPITAL_GETAPPROVAL = `${CONTEXT_LEGACYFORM15ERC}/%s${CONTEXT_HOSPITAL}/%s/approval`;

export const OPTIONS_LEGACYFORM15ERCSTATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  REVERTED: 'REVERTED',
  ATTESTED: 'ATTESTED',
  REATTESTED: 'REATTESTED',
  INITIATED: 'INITIATED',
  APPROVED: 'APPROVED'
};

