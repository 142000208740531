export const DISTRICTS_AR = [
  {
    label: 'Anjaw',
    value: 'ANJAW'
  },
  {
    label: 'Changlang',
    value: 'CHANGLANG'
  },
  {
    label: 'Dibang Valley',
    value: 'DIBANG_VALLEY'
  },
  {
    label: 'East Kameng',
    value: 'EAST_KAMENG'
  },
  {
    label: 'East Siang',
    value: 'EAST_SIANG'
  },
  {
    label: 'Kamle',
    value: 'KAMLE'
  },
  {
    label: 'Kra Daadi',
    value: 'KRA_DAADI'
  },
  {
    label: 'Kurung Kumey',
    value: 'KURUNG_KUMEY'
  },
  {
    label: 'Lepa Rada',
    value: 'LEPA_RADA'
  },
  {
    label: 'Lohit',
    value: 'LOHIT'
  },
  {
    label: 'Longding',
    value: 'LONGDING'
  },
  {
    label: 'Lower Dibang Valley',
    value: 'LOWER_DIBANG_VALLEY'
  },
  {
    label: 'Lower Siang',
    value: 'LOWER_SIANG'
  },
  {
    label: 'Lower Subansiri',
    value: 'LOWER_SUBUNSIRI'
  },
  {
    label: 'Namsai',
    value: 'NAMSAI'
  },
  {
    label: 'Pakke Kessang',
    value: 'PAKKE_KESSANG'
  },
  {
    label: 'Papum Pare',
    value: 'PAPUM_PARE'
  },
  {
    label: 'Shi Yomi',
    value: 'SHI_YOMI'
  },
  {
    label: 'Siang',
    value: 'SIANG'
  },
  {
    label: 'Tawang',
    value: 'TAWANG'
  },
  {
    label: 'Tirap',
    value: 'TIRAP'
  },
  {
    label: 'Upper Siang',
    value: 'UPPER_SIANG'
  },
  {
    label: 'Upper Subansiri',
    value: 'UPPER_SUBANSIRI'
  },
  {
    label: 'West Kameng',
    value: 'WEST_KAMENG'
  },
  {
    label: 'West Siang',
    value: 'WEST_SIANG'
  }
];
